import React from "react";

export const Title = ({ title }) => {
  return (
    <div className="my-2">
      <div>
        <div className="LookMeTypeButton box-shadow py-0">
          <h6 className="fw-500 py-2 mb-0">{title}</h6>
        </div>
      </div>
    </div>
  );
};
