import { faSmile } from "@fortawesome/free-regular-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmojiPicker from "emoji-picker-react";
import React from "react";
import { useState } from "react";
import { Col } from "react-bootstrap";
import useTheme from "src/hooks/useTheme";
import styled from "styled-components";

export const CommentextArea = ({ data }) => {
  const {
    inputHandleChange,
    onkeyPressSubmit,
    placeHolder,
    BorderAreaClass,
    fieldName,
    inputValue,
    commentloading,
    handleEmojiClick,
  } = data;

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { currentTheme } = useTheme();

  return (
    <Wrapper>
      <div className={`commentinput ${BorderAreaClass}`}>
        <div className="avatar">
          <img src="/user.png" alt="user" />
        </div>
        <div className="grow-1 position-relative">
          <textarea
            type="text"
            name={fieldName}
            value={inputValue && inputValue}
            onChange={inputHandleChange}
            placeholder={placeHolder}
            onKeyPress={(e) => {
              onkeyPressSubmit(e);
              setShowEmojiPicker(false);
            }}
            className={`${
              commentloading ? "disabled" : ""
            } write-comment-input`}
            onFocus={() => setShowEmojiPicker(false)}
          />

          <span
            className="emoji__picker-btn"
            onClick={() => setShowEmojiPicker((prev) => !prev)}
          >
            <FontAwesomeIcon icon={faSmile} size="lg" />
          </span>
        </div>
        <div>
          <FontAwesomeIcon
            icon={faPaperPlane}
            className="pointer"
            onClick={(e) => {
              onkeyPressSubmit(e);
              setShowEmojiPicker(false);
            }}
          />
        </div>
      </div>

      {showEmojiPicker && (
        <div className="emoji__picker_comment">
          <EmojiPicker
            onEmojiClick={handleEmojiClick}
            skinTonesDisabled
            searchDisabled
            theme={currentTheme}
            height="350px"
            previewConfig={{ showPreview: false }}
          />
        </div>
      )}

      {data.formateCommentBox ? (
        <Col
          className="smallTextSize pointer"
          md={12}
          onClick={data.formateCommentBox}
        >
          Press To Cancel
        </Col>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  & ::placeholder {
    font-size: 0.9rem;
  }

  & > .commentinput {
    display: flex;
    gap: 0.8rem;
  }
  & .grow-1 {
    flex-grow: 1;
  }

  & .avatar > img {
    border-radius: 50%;
    height: 2.5rem;
  }
`;
