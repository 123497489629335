import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useLocation, useNavigate } from "react-router-dom";
import MessageFileInput from "src/Common/MessageFileInput";
import UserIcon from "src/Projects/View/Icons/UserIcon";
export const Editchannel = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="container-fluid mt-3  px-0 ">
      <div className="row p-3">
        <div className="col-sm-4 mx-auto text-center">
          <MessageFileInput>
            <UserIcon className="" size="200" />
          </MessageFileInput>
        </div>
      </div>
      <div className="row mx-auto">
        <div className="col-sm-12  createChannel">
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalEmail"
            >
              <Form.Label column sm={4}>
                Channel Name
              </Form.Label>
              <Col sm={8} className="pe-0">
                <Form.Control
                  type="email"
                  placeholder="Type Channel Name"
                  name="channel_name"
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalPassword"
            >
              <Form.Label column sm={4}>
                Channel Categories
              </Form.Label>
              <Col sm={8} className="pe-0">
                <Form.Select aria-label="Default select example">
                  <option>Select Channel Categories</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalEmail"
            >
              <Form.Label column sm={4}>
                Channel Description
              </Form.Label>
              <Col sm={8} className="pe-0">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Type Channel Description"
                  />
                </Form.Group>
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalPassword"
            >
              <Form.Label column sm={4}>
                Channel Status
              </Form.Label>
              <Col sm={8} className="pe-0">
                <Form.Select aria-label="Default select example">
                  <option>Select Channel Status</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <div className="col-sm-12 text-end">
                <div
                  className="btn view-shadow-border px-3 mx-3"
                  onClick={() => navigate(`${location.state.data}`)}
                >
                  Cancel
                </div>
                <div
                  className="btn channelButton px-3"
                  onClick={() => navigate(`${location.state.data}/channel`)}
                >
                  Edit
                </div>
              </div>
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
};
