import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import EditChannelModal from "./EditChannelModal";
import {
  destroyChannelDetails,
  getChannelDetails,
} from "src/features/view/viewDataSlice";
import ChannelVideos from "./ChannelVideos";
import { channelBannerImg } from "../../common/view_commonUrl";
import UserMeta from "./UserMeta";
import ChannelPlaylists from "./ChannelPlaylists";
import ChannelAbout from "./ChannelAbout";
import ChannelViewSkeleton from "../../common/ChannelViewSkeleton";
import { TopNavBar } from "../../Components/TopNavBar/TopNavBar";
import { useNavigate } from "react-router-dom";
import ViewIcon from "../../Icons/ViewIcon";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import OffCanvasNav from "../../Components/OffCanvasNav";

export const ChannelView = () => {
  const navigate = useNavigate();

  const [showChannelEditModal, setShowChannelEditModal] = useState(false);
  const [showNavOffcanvas, setShowNavOffcanvas] = useState(false);

  const { channelDetails } = useSelector((store) => store.viewData);

  const { userId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getChannelDetails(userId));

    return () => dispatch(destroyChannelDetails());
  }, [userId, dispatch]);

  const channelData = {
    channel_name: channelDetails?.channel_name || "",
    channel_title: channelDetails.channel_title || "",
    channel_category_id: channelDetails.channel_category?.id,
    channel_description: channelDetails.channel_description,
    privacy: channelDetails.privacy,
  };

  return (
    <>
      {showChannelEditModal && (
        <EditChannelModal
          coverImage={channelDetails.banner}
          channelProfile={channelDetails.photo}
          channelData={channelData}
          show={showChannelEditModal}
          handleClose={() => setShowChannelEditModal(false)}
        />
      )}

      {showNavOffcanvas && (
        <OffCanvasNav
          show={showNavOffcanvas}
          handleClose={() => setShowNavOffcanvas(false)}
        />
      )}

      <div className="stickyParts pointer z-index-2 py-2 px-3 mx-n2 rounded nav-bg-opacity d-lg-none mb-2">
        <div className="d-flex align-items-center justify-content-between ">
          <div className="d-flex align-items-center">
            <div
              onClick={() => navigate("/dashboard")}
              className="LookMeButtonTypeIcon"
            >
              <ViewIcon size="40" />
            </div>
            <div onClick={() => navigate("/view")}>
              <div className="type-writer-text-box text-nowrap">
                <h5 className="mb-0">
                  <span>UVIOM</span>
                  <span className="text-orange">View</span>
                </h5>
                <div className="lh-1 type-writer-text logo-slogan d-block">
                  <Typewriter
                    options={{
                      strings: "Explore Your New World",
                      autoStart: true,
                      loop: true,
                    }}
                  ></Typewriter>
                </div>
              </div>
            </div>
          </div>

          <div role="button" onClick={() => setShowNavOffcanvas(true)}>
            <ThreeDots className="topNavIcon" size="20" />
          </div>
        </div>
      </div>

      {!channelDetails.id ? (
        <ChannelViewSkeleton />
      ) : (
        <div className="container-fluid WatchVideoContainer ChannelView px-0">
          <div>
            <CoverImage
              src={
                channelDetails.banner
                  ? channelBannerImg + channelDetails.banner
                  : "https://i.ibb.co/dKqrzrX/played-Video-min.jpg"
              }
              alt=""
            />
          </div>
          {/* <TopNavBar /> */}

          <UserMeta
            channelName={channelDetails.channel_name}
            userId={channelDetails.user_id}
            channelProfile={channelDetails.photo}
            followers={channelDetails.total_followers}
            isFollowing={channelDetails.follows_status_count}
            handleEditChannel={() =>
              setShowChannelEditModal(!showChannelEditModal)
            }
          />
          <TabsWrapper>
            <Tabs defaultActiveKey="videos" className="mb-3">
              <Tab eventKey="videos" title="Videos">
                <ChannelVideos
                  channelCategory={channelDetails.channel_category.name}
                  videos={channelDetails.videos}
                  channelName={channelDetails.channel_name}
                  channelProfile={channelDetails.photo}
                />
              </Tab>
              <Tab eventKey="playlist" title="PlayList">
                <ChannelPlaylists
                  userId={channelDetails.user_id}
                  playlists={channelDetails.playlists}
                />
              </Tab>

              <Tab eventKey="about" title="About">
                <ChannelAbout
                  userId={channelDetails.user_id}
                  channelTitle={channelDetails.channel_title}
                  channelDesc={channelDetails.channel_description}
                  createdAt={channelDetails.created_at}
                  totalVideos={channelDetails.videos_count}
                  channelCategory={channelDetails.channel_category.name}
                  totalViews={channelDetails.total_views}
                />
              </Tab>
            </Tabs>
          </TabsWrapper>
        </div>
      )}
    </>
  );
};

const CoverImage = styled.img`
  height: clamp(8rem, 3.629rem + 13.5484vw, 13.25rem);
  width: 100%;
  object-fit: cover;

  @media (min-width: 93.75em) {
    height: calc((100vw - 240px) / 5 - 1px);
  }
`;

const TabsWrapper = styled.div`
  margin-bottom: 2rem;

  & .nav-tabs {
    border-bottom: 0;
  }
  & .nav-tabs .nav-link {
    outline: 0;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--text-primary);
    opacity: 0.9;
    padding-inline: 1rem;
    margin-inline: 1rem;
    border: 0;
    border-bottom: 2px solid transparent;

    @media (max-width: 48em) {
      margin-inline: 0rem;
      padding-inline: 0.8rem;
    }
  }

  & .nav-tabs .nav-link.active {
    background-color: transparent;
    border-color: 2px solid var(--text-info);
  }
`;
