import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export const InputField = ({
  textInputName,
  textInputValue,
  changeHandle,
  textInputType,
  inputPlaceholder,
}) => {
  return (
    <>
  
      <div className="container-fluid my-4">
        <Row>
          <Col>
            <Form.Control
              type={textInputType ? textInputType : "text"}
              name={textInputName}
              value={textInputValue}
              placeholder={inputPlaceholder ? inputPlaceholder : ""}
              className={`mainProfileInput`}
              onChange={changeHandle}
              required
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
