import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { companyProfilePhotoUrl } from "../../utilities/job_commonUrl";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import { useDeletePostedJobMutation } from "src/app/services/jobApi/jobPostApi";
import { useEffect } from "react";
import { deleteAlert } from "../../utilities/functions";

const PostedJobSingleItem = ({ data, dataReloadAfterDelete }) => {
  const { company_profile_section, job_title, range_start, range_end, id } =
    data;
  const [deletePost, { isLoading, isSuccess, error }] =
    useDeletePostedJobMutation();
  const imageUrl = company_profile_section?.profile_picture
    ? companyProfilePhotoUrl + company_profile_section.profile_picture
    : "/user.png";
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate("/Job/company/edit-job-post", {
      state: { data },
    });
  };
  const handleDelete = () => {
    deleteAlert().then((result) => {
      if (result.isConfirmed) {
        deletePost(id);
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      dataReloadAfterDelete();
    }
    if (error) {
      console.log(error.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, error]);

  return (
    <Col xs="12">
      {isLoading && <BubbleLoader />}
      <div className="justify-content-between posteditemContainer job_cart-hover  m-0 d-sm-flex ">
        <div className="postedJobsingleItem">
          <div className="postedJobsingleItemImg">
            <img
              className="img img-fluid"
              src={imageUrl}
              alt="company profile img"
            />
          </div>
          <div className="postedJobsingleItemText">
            <h6 className="fw-bold">{job_title || "N/A"}</h6>
            <span className="font-14 fw-500">
              {company_profile_section?.company_name || "N/A"}
            </span>

            <span className="font-14">
              {range_start} - {range_end}/month
            </span>
          </div>
        </div>

        <div className="postdjobsLinkBtn my-2 d-flex flex-sm-column align-items-center justify-content-evenly justify-content-sm-between">
          <div className="social-action-btn mb-sm-2">
            <button
              onClick={handleDelete}
              type="button"
              className="eco-btn me-2"
            >
              <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
            </button>
            <button className="eco-btn" type="button" onClick={handleEdit}>
              <FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon>
            </button>
          </div>
          <Link to={`/Job/company/applicant-list/${id}`}>Applicant List</Link>
        </div>
      </div>
    </Col>
  );
};

export default PostedJobSingleItem;
