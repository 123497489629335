import React from "react";

const AddPhotoIcon = ({ size = "40", className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M2.1 4.2H0V18.9C0.00166186 19.4564 0.223445 19.9896 0.616912 20.3831C1.01038 20.7766 1.54356 20.9983 2.1 21H16.8V18.9H2.1V4.2ZM18.9 0H6.3C5.74356 0.00166186 5.21038 0.223445 4.81691 0.616912C4.42344 1.01038 4.20166 1.54356 4.2 2.1V14.7C4.20166 15.2564 4.42344 15.7896 4.81691 16.1831C5.21038 16.5766 5.74356 16.7983 6.3 16.8H18.9C19.4564 16.7983 19.9896 16.5766 20.3831 16.1831C20.7766 15.7896 20.9983 15.2564 21 14.7V2.1C20.9983 1.54356 20.7766 1.01038 20.3831 0.616912C19.9896 0.223445 19.4564 0.00166186 18.9 0ZM10.5 13.125V3.675L16.8 8.4L10.5 13.125Z"
        fill="#6984A5"
      />
    </svg>
  );
};

export default AddPhotoIcon;
