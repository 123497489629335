import React from 'react';
import Navigation from './Shared/Navigation';
import Footer from './Footer/Footer';
import SignupBlock from './Login&SignupBlock/SignupBlock';

const SignupPage = () => {
    return (
      <div>
        <Navigation />
        <SignupBlock />
        <Footer />
      </div>
    );
};

export default SignupPage;