import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import SingleVideoCardSkeleton from "../../common/SingleVideoCardSkeleton";
import SingleVideoCard from "../SingleVideoCard";
import { HasMore } from "../../Pages/SaveVideo/SaveVideo";
import useQuery from "src/hooks/useQuery";
import { useGetHomePageVideosMutation } from "src/app/services/viewApi/viewGetApi";

export const CardsContainer = () => {
  const [currentPage, setCurrentPage] = useState(2);
  const query = useQuery();
  const categoryParams = query.get("category");
  const [getHomePageVideos, { isLoading }] = useGetHomePageVideosMutation();

  const {
    homePageVideos: { data: homePageVideos, has_more },
  } = useSelector((store) => store.viewData);

  // Filtering the videos
  let filteredVideos;
  if (!categoryParams || categoryParams === "all") {
    filteredVideos = homePageVideos;
  } else {
    filteredVideos = homePageVideos.filter(
      (video) => parseInt(video.video_category_id) === parseInt(categoryParams)
    );
  }

  return (
    <div className="mb-4 stickyParts">
      <CardWrapper>
        {filteredVideos.length >= 1
          ? filteredVideos.map((video) => (
              <SingleVideoCard
                key={video.id}
                id={video.id}
                userId={video.user_id}
                created_at={video.created_at}
                channelName={video.channel_profile_section.channel_name}
                channelCategory={
                  video.channel_profile_section.channel_category.name
                }
                videoTitle={video.title}
                thumbnail={video.thumbnail}
                isSaved={video.personal_save_status_count}
                channelProfile={video.channel_profile_section.photo}
                viewCount={video.video_views_count}
              />
            ))
          : Array.from({ length: 3 }).map((_, index) => (
              <SingleVideoCardSkeleton key={index} />
            ))}
      </CardWrapper>
      {!isLoading && has_more && (
        <HasMore
          onClick={() => {
            setCurrentPage((currentPage) => (currentPage += 1));
            getHomePageVideos(currentPage);
          }}
        >
          Load More
        </HasMore>
      )}
    </div>
  );
};

const CardWrapper = styled.div`
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;

  /* 820px */
  @media (max-width: 51.25em) {
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  }
`;
