import React from "react";
// import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MessengerChat } from "react-messenger-chat-plugin";
import { Route, Routes as Switch, useLocation } from "react-router-dom";
import useStore from "src/hooks/useStore";
import { PrivateRoute } from "src/Routes/PrivateRoute";
import CartModal from "./Components/Common/CartModal/CartModal";
import CorporateFooter from "./Components/Footer/CorporateFooter";
import NavBar from "./Components/NavBar/NavBar";
import ProductRequest from "./Components/ProductRequest/ProductRequest";
import { CartStoreProvider } from "./context/cart/CartStoreProvider";
import { UbizzStoreProvider } from "./context/UbizzStoreProvider";
import { AllBrand } from "./Pages/Brand/AllBrand/AllBrand";
import { BrandDetails } from "./Pages/Brand/BrandDetails";
import CategoriesProducts from "./Pages/Categories/CategoriesProducts";
import Contact from "./Pages/Contact/Contact";
import CreateStore from "./Pages/CreateStore/CreateStore";
import CorporateHome from "./Pages/Home/CorporateHome";
import MyCart from "./Pages/MyCart/MyCart";
import MyFavorite from "./Pages/MyFavorite/MyFavorite";
import MyProfile from "./Pages/MyProfile/MyProfile";
import Product from "./Pages/Product/Product";
import ProductDetails from "./Pages/ProductDetails/ProductDetails";
import SearchProduct from "./Pages/SearchResult/SearchProduct/SearchProduct";
import SearchShop from "./Pages/SearchResult/SearchShop/SearchShop";
import MyStore from "./Pages/SellerDashboard/MyStore/MyStore";
import Shop from "./Pages/Shop/Shop";
import StoreView from "./Pages/Store/StoreView/StoreView";
import "./Ubizz.css";

const Ubizz = () => {
  const { cartModalShow, showCartModal, closeCartModal } = useStore();
  const location = useLocation();
  return (
    <UbizzStoreProvider>
      <CartStoreProvider>
        <Container fluid>
          <Row>
            <Col className="p-0">
              <NavBar />
              <div
                className="container-xxl  p-0 "
                style={{ overflowX: "hidden", minHeight: "80vh" }}
              >
                <Switch>
                  <Route index element={<CorporateHome />} />
                  <Route path={`/my-favorite`} element={<MyFavorite />} />

                  <Route
                    path={`/shop/store-view/:storeId`}
                    element={<StoreView />}
                  />

                  <Route
                    path={`/search-product/:searchText`}
                    element={<SearchProduct />}
                  />

                  <Route
                    path={`/search-store/:searchText`}
                    element={<SearchShop />}
                  />

                  <Route
                    path={`/category/:categoryType/:categoryName`}
                    element={<CategoriesProducts />}
                  />

                  <Route
                    path={`/product-details/:productId`}
                    element={<ProductDetails />}
                  />

                  <Route
                    path={`/Brand-details/:BrandId`}
                    element={<BrandDetails />}
                  />

                  <Route
                    path="/my-cart"
                    element={
                      <PrivateRoute>
                        <MyCart />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/my-favorite"
                    element={
                      <PrivateRoute>
                        <MyFavorite />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/my-profile/*"
                    element={
                      <PrivateRoute>
                        <MyProfile />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path={`/seller-registration/*`}
                    element={<CreateStore />}
                  />

                  <Route path={`/my-store/*`} element={<MyStore />} />

                  <Route
                    path={`/product-request`}
                    element={<ProductRequest />}
                  />

                  <Route path={`/contact`} element={<Contact />} />

                  <Route path={`/product`} element={<Product />} />

                  <Route path={`/allBrand`} element={<AllBrand />} />
                  <Route path={`/shop`} element={<Shop />} />
                </Switch>

                {!location.pathname.includes("/my-cart") && (
                  <>
                    {window.location.hostname === "localhost" ||
                    window.location.hostname === "127.0.0.1" ||
                    window.location.hostname === "" ? (
                      <CartModal
                        show={cartModalShow}
                        handleClose={closeCartModal}
                        handleShow={showCartModal}
                      ></CartModal>
                    ) : (
                      <>
                        <CartModal
                          show={cartModalShow}
                          handleClose={closeCartModal}
                          handleShow={showCartModal}
                          extraClass="cartModalMove"
                        ></CartModal>
                        <MessengerChat pageId="107749171765300" />
                      </>
                    )}
                  </>
                )}
              </div>
              <CorporateFooter />
            </Col>
          </Row>
        </Container>
      </CartStoreProvider>
    </UbizzStoreProvider>
  );
};

export default Ubizz;
