import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Navbar, Nav, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import TrackingPopup from "./../TrackingPopup";

// Same as ride
import logo from "./../../../Ride/assets/logo.svg";
import humanAvatar from "./../../../Ride/assets/human-avatar.svg";
import bellIcon from "./../../../Ride/assets/bell-icon.svg";

import "./header.css";

function Header() {
  const [modalShow, setModalShow] = useState(false);
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dynamicPath = pathname.split("/")[1]

  return (
    <header className="header">
      <TrackingPopup show={modalShow} onHide={() => setModalShow(false)} />

      <Navbar collapseOnSelect expand="md">
        <Container>
          <Navbar.Brand>
            <div className="header__logo--container">
              <Link to="/dashboard">
                <img src={logo} alt="UVIOM Ride" />
              </Link>
              <Link to={`/${dynamicPath}`}>
                <div className="header__logo--text">
                  <p className="logo-uviom-ride">
                    <bdo>UVIOM</bdo>
                    <span>Express</span>
                  </p>
                  <h6 className="logo-slogan">
                    <Typewriter
                      className="logo-slogan"
                      options={{
                        strings: "Your Vision Is Our Mission",
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </h6>
                </div>
              </Link>
            </div>
          </Navbar.Brand>

          <Navbar.Collapse>
            <Nav className="mr-auto header__nav">
              <Nav.Link as={Link} className="header__nav--link" to={`/${dynamicPath}`}>
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                className="header__nav--link"
                to={`/${dynamicPath}/about`}
              >
                About
              </Nav.Link>
              <Nav.Link
                as={Link}
                className="header__nav--link"
                to={`/${dynamicPath}/privacy`}
              >
                Privacy
              </Nav.Link>
              <Nav.Link
                as={Link}
                className="header__nav--link"
                to={`/${dynamicPath}/contact`}
              >
                Contact
              </Nav.Link>
              <Nav.Link
                onClick={() => setModalShow(true)}
                className="header__nav--link"
              >
                Track
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>

          <div className="header__icon">
            <img src={bellIcon} alt="" />
            <Link to={`/${dynamicPath}/profile`}>
              <img src={humanAvatar} alt="" />
            </Link>
            <button onClick={handleShow} className="nav__burger false">
              <span className="burger-icon">&nbsp;</span>
            </button>
          </div>
        </Container>
      </Navbar>

      <Offcanvas
        className="ride__offcanvas"
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
          <img src={logo} alt="UVIOM Ride" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="ride__offcanvas--menu">
            <li>
              <Link onClick={handleClose} to={`/${dynamicPath}`}>
                Home
              </Link>
            </li>
            <li>
              <Link onClick={handleClose} to={`/${dynamicPath}/about`}>
                About
              </Link>
            </li>
            <li>
              <Link onClick={handleClose} to={`/${dynamicPath}/privacy`}>
                Privacy
              </Link>
            </li>
            <li>
              <Link onClick={handleClose} to={`/${dynamicPath}/contact`}>
                Contact
              </Link>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                role="button"
                onClick={() => {
                  handleClose();
                  setModalShow(true);
                }}
                tabIndex="3"
                href="#"
              >
                Track
              </a>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
}

export default Header;
