import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import "./PostedJobs.css";
import PostedJobSingleItem from "./PostedJobSingleItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { useGetPostedJobQuery } from "src/app/services/jobApi/jobGetApi";
import JobSkeleton from "../../Components/Placeholder/JobSkeleton";

const PostedJobs = () => {
  const [postList, setPostList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const { data: result, isFetching, error, refetch } = useGetPostedJobQuery();
  const { data, hasMore } = result || {};

  const dataReloadAfterDelete = (id) => {
    setPostList([]);
    refetch();
  };

  useEffect(() => {
    if (data) {
      setPostList((prevState) => [...prevState, ...data]);
    }
  }, [data]);

  return (
    <div className="secondary-bg p-2">
      <h6 className="postedJobTitle ">Posted Jobs</h6>

      {isFetching ? (
        <Row className="g-3" xxl="2">
          {[...new Array(6)].map((_, index) => (
            <JobSkeleton key={index} />
          ))}
        </Row>
      ) : postList && postList.length > 0 ? (
        <InfiniteScroll
          dataLength={postList.length} //This is important field to render the next data
          next={() => {
            setCurrentPage(currentPage + 1);
          }}
          hasMore={hasMore}
        >
          <Row className="g-3" xxl={2}>
            {postList.map((post) => (
              <PostedJobSingleItem
                dataReloadAfterDelete={dataReloadAfterDelete}
                key={post.id}
                data={post}
              />
            ))}
          </Row>
        </InfiniteScroll>
      ) : (
        <p>No Data Available</p>
      )}

      {error && <p className="text-danger">{error?.error}</p>}
      <br />
    </div>
  );
};

export default PostedJobs;
