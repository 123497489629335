import imageCompression from "browser-image-compression";

async function compress_image(imageFiles) {
  const options = {
    maxSizeMB: 0.1,
    useWebWorker: true,
    initialQuality: 0.25,
    alwaysKeepResolution: false,
    // onProgress: handleCompressProgress,
    maxWidthOrHeight: 1920,
    };

    const files = [];
    try {
        for await (const file of imageFiles) {
            if (file.size < 102400) {
                files.push(file)
                continue;
            } else if (file.size < 204800) {
                options.initialQuality = 0.75;
                const compress_file = await imageCompression(file, options);
                files.push(compress_file);
                continue
            }
            else if (file.size < 409600) {
                options.initialQuality = 0.50;
                const compress_file = await imageCompression(file, options);
                files.push(compress_file);
                continue
            } else {
                options.maxSizeMB = 0.175;
                const compress_file = await imageCompression(file, options);
                files.push(compress_file);
                continue
            }
        }
        return files
    } catch (err) {
        console.log(err)
        return imageFiles
    }

    

}

export default compress_image;
