/* eslint-disable eqeqeq */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import processToArray from "src/app/helper/processToArray";
import {
  deleteSinglePost,
  fineContentIndex,
  getCurrentUrl,
  getTimelineName,
} from "src/app/helper/rtkFunctions";

const initialState = {
  family_post: [],
  friends_post: [],
  professional_post: [],
  public_post: [],

  family_personal_post: [],
  friends_personal_post: [],
  professional_personal_post: [],

  family_current_page: 1,
  friends_current_page: 1,
  professional_current_page: 1,
  public_current_page: 1,

  family_personal_current_page: 1,
  friends_personal_current_page: 1,
  professional_personal_current_page: 1,

  family_hasMore: true,
  friends_hasMore: true,
  professional_hasMore: true,
  public_hasMore: true,

  family_personal_hasMore: true,
  friends_personal_hasMore: true,
  professional_personal_hasMore: true,

  family_temp_hasMore: false,
  friends_temp_hasMore: false,
  professional_temp_hasMore: false,
  public_temp_hasMore: false,

  family_personal_temp_hasMore: false,
  friends_personal_temp_hasMore: false,
  professional_personal_temp_hasMore: false,

  family_page_offset: 454,
  friends_page_offset: 454,
  professional_page_offset: 454,
  public_page_offset: 454,

  isLoading: true,
  error: "",
  changeData: false,
  changeData_personal: false,
};

export const getNewsFeedPost = createAsyncThunk(
  "life/news_feed_posts",
  async (timeline_name, thunkAPI) => {
    const state = thunkAPI.getState().newsFeedPost;
    const currentPage = state[`${timeline_name}_current_page`];
    const url = getCurrentUrl(timeline_name);
    const final_url = `${url}?page=${currentPage}`;

    try {
      const resp = await axios.get(final_url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

const newsFeedSlice = createSlice({
  name: "news_feed_post",
  initialState,

  reducers: {
    formateNewsFeedData: (state, action) => {
      state.family_post = [];
      state.friends_post = [];
      state.professional_post = [];
      state.family_personal_post = [];
      state.friends_personal_post = [];
      state.professional_personal_post = [];
    },

    setCurrentPage: (state, action) => {
      const timeline_name = action.payload;
      state[`${timeline_name}_current_page`] += 1;
    },

    setHasMoreFalse: (state, action) => {
      const timeline_name = action.payload;
      const prev_state = state[`${timeline_name}_hasMore`];
      state[`${timeline_name}_temp_hasMore`] = prev_state;
      state[`${timeline_name}_hasMore`] = false;
    },

    setTempHasMore: (state, action) => {
      const timeline_name = action.payload;
      state[`${timeline_name}_hasMore`] =
        state[`${timeline_name}_temp_hasMore`];
    },

    setPageOffset: (state, action) => {
      const { timeline_name, page_offset } = action.payload;
      state[`${timeline_name}_page_offset`] = page_offset;
    },

    // remove first 25 post after loading more than 50 post
    spliceData: (state, action) => {
      const timeline_name = action.payload;
      const splice_item_count = timeline_name.includes("personal") ? 10 : 25;
      const post_list = [...state[`${timeline_name}_post`]];
      state[`${timeline_name}_post`] = _.drop(post_list, splice_item_count);

      // after remove  data useEffect trigger state
      if (timeline_name?.includes("personal")) {
        state.changeData_personal = !state.changeData_personal;
      } else {
        state.changeData = !state.changeData;
      }
    },

    // delete post form all timeline
    deleteNewsFeedPost: (state, action) => {
      const post_id = action.payload;
      state.family_post = deleteSinglePost(state.family_post, post_id);
      state.friends_post = deleteSinglePost(state.friends_post, post_id);
      state.professional_post = deleteSinglePost(
        state.professional_post,
        post_id
      );
      state.family_personal_post = deleteSinglePost(
        state.family_personal_post,
        post_id,
        "personal"
      );
      state.friends_personal_post = deleteSinglePost(
        state.friends_personal_post,
        post_id,
        "personal"
      );
      state.professional_personal_post = deleteSinglePost(
        state.professional_personal_post,
        post_id,
        "personal"
      );
    },

    // update single post caption
    updatePostCaption: (state, action) => {
      const { post_id, post_details, timeline_name } = action.payload;
      const { timeline_one, timeline_two } = getTimelineName(timeline_name);
      const postList = state[`${timeline_one}_post`];
      const postList_personal = state[`${timeline_two}_post`];

      // update caption form dashboard post list
      if (postList.length > 0) {
        const itemIndex = _.findIndex(postList, { id: post_id });
        const post = postList[itemIndex];
        post.post_details = post_details;
        state[`${timeline_one}_post`].splice(itemIndex, 1, post);
      }

      // update caption form my post list
      if (postList_personal.length > 0) {
        const itemIndex = _.findIndex(postList_personal, { post_id: post_id });
        const post = postList_personal[itemIndex];
        post.full_post_details = post_details;
        state[`${timeline_two}_post`].splice(itemIndex, 1, post);
      }
    },

    // delete post single image
    deletePostContent: (state, action) => {
      const { post_id, post_content_id, timeline_name } = action.payload;
      const { timeline_one, timeline_two } = getTimelineName(timeline_name);
      const postList = state[`${timeline_one}_post`];
      const postList_personal = state[`${timeline_two}_post`];

      // delete image form dashboard post list
      if (postList.length > 0) {
        const { itemIndex, post } = fineContentIndex(
          postList,
          post_content_id,
          post_id
        );
        state[`${timeline_one}_post`].splice(itemIndex, 1, post);
      }

      // delete image form my post list
      if (postList_personal.length > 0) {
        const { itemIndex, post } = fineContentIndex(
          postList_personal,
          post_content_id,
          post_id,
          "personal"
        );
        state[`${timeline_two}_post`].splice(itemIndex, 1, post);
      }
    },
    hideNewsFeedPost: (state, action) => {
      const { post_id, timeline_name } = action.payload;
      const postList = state[`${timeline_name}_post`];
      const remainingPost = postList.filter((item) => item.id != post_id);
      state[`${timeline_name}_post`] = remainingPost;
    },
  },

  extraReducers: {
    [getNewsFeedPost.pending]: (state) => {
      state.isLoading = true;
    },

    [getNewsFeedPost.fulfilled]: (state, action) => {
      state.isLoading = false;
      const timeline_name = action.meta?.arg;
      let next_page = action.payload?.next_page_url ? true : false;
      if (timeline_name.includes("personal")) {
        next_page = action.payload?.links.next ? true : false;
      }
      const response_data = processToArray(action.payload);

      state[`${timeline_name}_hasMore`] = next_page;

      const previous_data = state[`${timeline_name}_post`];
      state[`${timeline_name}_post`] = [...previous_data, ...response_data];

      if (timeline_name?.includes("personal")) {
        state.changeData_personal = !state.changeData_personal;
      } else {
        state.changeData = !state.changeData;
      }
    },

    [getNewsFeedPost.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default newsFeedSlice.reducer;

export const {
  setCurrentPage,
  setHasMoreFalse,
  setTempHasMore,
  setPageOffset,
  spliceData,
  deleteNewsFeedPost,
  updatePostCaption,
  deletePostContent,
  formateNewsFeedData,
  hideNewsFeedPost,
} = newsFeedSlice.actions;
