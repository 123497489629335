import React, { useEffect, lazy, Suspense } from "react";
import { Routes, useLocation, Route, Navigate } from "react-router-dom";
import { PrivateRoute } from "src/Routes/PrivateRoute";
import { ChatListPopup } from "./LifePages/MainPage/NestedRouteOnDashBodr/Message/PopupMessage/ChatListPopup";
import { useDispatch, useSelector } from "react-redux";
import { formateNewsFeedData } from "src/features/life/newsFeedSlice";
import MediaQuery from "react-responsive";
import {
  useGetMainProfileImagesQuery,
  useGetProfileImagesQuery,
} from "src/app/services/lifeApi/lifeCommonApi";
import { fmPhotosUrl, fnPhotosUrl, pPhotosUrl } from "./Common/CommonUrl";
import {
  destroyMessengerData,
  getChatUserList,
} from "src/features/life/messengerSlice";
// can't lazyload, css file inside this components are are connected to other component
import UviomMainProfile from "./LifePages/UviomMainProfile/UviomMainProfile";

import DottedCircleSpinner from "src/Common/DottedCircleSpinner";
import { delete_unLink_data } from "src/utilities/utilities";
import LifeNavigateFooter from "./Components/LifeNavigateFooter";
import PublicLife from "./LifePages/MainPage/PublicLife/PublicLife";
import UpdateProfileBasic from "./LifePages/UpdateProfile/UpdateProfileBasic";
import ProfessionalLife from "./LifePages/MainPage/ProfessionalLife/ProfessionalLife";
import PublicPost from "./LifePages/MainPage/PublicPost/PublicPost";
import FamilyLife from "./LifePages/MainPage/FamilyLife/FamilyLife";
import FriendsLife from "./LifePages/MainPage/FrindsLife/FriendsLife";
import OwnProfile from "./LifePages/MainPage/OwnProfile/OwnProfile";
import PopupMessage from "./LifePages/MainPage/NestedRouteOnDashBodr/Message/PopupMessage/PopupMessage";
import PopupMessageBubble from "./LifePages/MainPage/NestedRouteOnDashBodr/Message/PopupMessage/PopupMessageBubble";

const SinglePostView = lazy(() =>
  import("./LifePages/MainPage/SinglaPostView/SinglePostView")
);
const UVIOMProfile = lazy(() => import("./UVIOMProfile/UVIOMProfile"));

const LifeLayout = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { chatUserList, hasMore, currentPage } = useSelector(
    (state) => state.messengerData
  );

  useGetProfileImagesQuery({ timeline_name: "family", url: fmPhotosUrl });
  useGetProfileImagesQuery({ timeline_name: "friends", url: fnPhotosUrl });
  useGetProfileImagesQuery({ timeline_name: "professional", url: pPhotosUrl });
  useGetMainProfileImagesQuery();

  useEffect(() => {
    if (chatUserList.length < 1 || hasMore) {
      dispatch(getChatUserList());
    }

    return () => {
      dispatch(formateNewsFeedData());
      dispatch(destroyMessengerData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    delete_unLink_data();
    window.addEventListener("online", delete_unLink_data);
  }, []);

  return (
    <div className="App d-flex flex-column min-vh-100">
      <a href="#navigation" className="skip-link">
        Skip to Navigation
      </a>
      <a href="#bestime" className="skip-link">
        Skip to Best Time
      </a>
      <a href="#mainpart" className="skip-link">
        Skip to Main
      </a>
      <a href="#timeline" className="skip-link">
        Skip to Timeline
      </a>
      <Suspense
        fallback={
          <div className="vh-100 d-flex align-items-center">
            <DottedCircleSpinner />
          </div>
        }
      >
        <Routes>
          <Route index element={<Navigate to="/life/public-post" />} />
          <Route
            path="/public-post/*"
            element={
              <PrivateRoute>
                <PublicPost />
              </PrivateRoute>
            }
          />

          <Route
            path="/family-life/*"
            element={
              <PrivateRoute>
                <FamilyLife />
              </PrivateRoute>
            }
          />

          <Route
            path="/friends-life/*"
            element={
              <PrivateRoute>
                <FriendsLife />
              </PrivateRoute>
            }
          />
          <Route
            path="/:dynamicLife/profile/*"
            element={
              <PrivateRoute>
                <OwnProfile />
              </PrivateRoute>
            }
          />

          <Route
            path="/professional-life/*"
            element={
              <PrivateRoute>
                <ProfessionalLife />
              </PrivateRoute>
            }
          />

          <Route
            path="/public-life/:user_id/*"
            element={
              <PrivateRoute>
                <PublicLife />
              </PrivateRoute>
            }
          />

          <Route
            path="/update-profile"
            element={
              <PrivateRoute>
                <UpdateProfileBasic />
              </PrivateRoute>
            }
          />

          <Route
            path="/uviom-profile"
            element={
              // <PublicRoute>
              <UviomMainProfile />
              // </PublicRoute>
            }
          />
          <Route
            path="/uviom-profile-old"
            element={
              // <PublicRoute>
              <UVIOMProfile />
              // </PublicRoute>
            }
          />
          <Route path="/post/:postId" element={<SinglePostView />} />

          {/* <Route path="*" element={<Navigate to={"public-post"} replace />} /> */}
        </Routes>
        {!pathname.includes("/message") ? (
          <MediaQuery minWidth={991}>
            <>
              <PopupMessageBubble />
              <PopupMessage />
              <ChatListPopup />
            </>
          </MediaQuery>
        ) : null}
        <MediaQuery maxWidth={991}>
          <LifeNavigateFooter />
        </MediaQuery>
      </Suspense>
    </div>
  );
};

export default LifeLayout;
