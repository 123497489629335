import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { applicantPhoto } from "../../utilities/job_commonUrl";

const ApplicantSingleItem = ({ data }) => {
  const {
    applicant_name,
    qualification,
    experiences,
    applicant_id,
    applicant_profile,
  } = data;

  const { user_section, education } = applicant_profile;
  const applicantImage = user_section?.photo
    ? applicantPhoto + user_section?.photo
    : "/user.png";
  return (
    <Col xs="12">
      <div className="applicantsinglseItem  d-sm-flex job_cart-hover">
        <div className="applicantsinglseItemLeft">
          <div className="postedJobsingleItemImg">
            <img className="img img-fluid" src={applicantImage} alt="" />
          </div>
          <div className="postedJobsingleItemText font-14">
            <Link
              to={`/Job/company/applicant-details/${applicant_id}`}
              state={{ data: applicant_profile, educations: education }}
            >
              <h6 className="fw-bold">{applicant_name}</h6>
            </Link>
            <span>{qualification || "N/A"}</span>
            <span> Experiences: {experiences.substr(0, 20) || "Fresher"}</span>
          </div>
        </div>
        <div className="applicantsinglseItemRight d-flex flex-sm-column align-items-center justify-content-evenly justify-content-sm-between mt-3 mb-2 mt-sm-0 mb-sm-0">
          <div className="applicantsinglseItemIcons ">
            <Link to="/" className="me-2">
              <FontAwesomeIcon icon={faEnvelope} />
            </Link>
            <Link to="/" className="me-1">
              <FontAwesomeIcon icon={faFacebookMessenger} />
            </Link>
          </div>

          <div className="applicantsinglseItemIcons">
            <Link
              to={`/Job/company/applicant-details/${applicant_id}`}
              state={{ data: applicant_profile, educations: education }}
            >
              See Details
            </Link>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ApplicantSingleItem;
