import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import "./PreviousWorkPreview.css";

function PreviousWorkPreview() {
  return (
    <div className="previousWorkPreview">
      <img
        className="previousWorkPreview__img"
        src="https://i.ibb.co/HYC9fBg/work1.jpg"
        alt=""
      />
      <div className="previousWorkPreview__content">
        <h6>Finance Website Design</h6>
        <div className="d-flex justify-content-between">
          <p>10 Screen</p>
          <p>
            <FontAwesomeIcon className="text-orange" icon={faStar} />
            <span className="fw-500"> 5.00 </span>
            <small> (30)</small>
          </p>
        </div>

        <p className="fw-500 mb-2">Work Process</p>
        <ul className="ps-4">
          <li>UX Research</li>
          <li>Market Problem Stateman</li>
          <li>Making Solution</li>
          <li>Wireframe</li>
          <li>Mockup</li>
        </ul>
      </div>
    </div>
  );
}
export default PreviousWorkPreview;
