import React from 'react';
import "./ViewPhotos"
import {  Col, Row } from "react-bootstrap";
import { useLocation } from 'react-router-dom';


const ShowPhotos = () => {
  const location = useLocation();
    return (
      <div className='container-fluid bg-footer py-4 rounded-4'>
        <Row>
        {!location.pathname.match("/public-life")? (
          <>
           <Col xs={6} md={3} className='mb-4 px-2'>
            <div className="w-100 text-center">
              <img
                className="img-fluid imgFolder"
                src="https://i.ibb.co/nDQ2Yy7/add-photo.png"
                alt="add to album"
              />
              <h6 className="mt-2"> Add Album</h6>
            </div>
          </Col>
          </>
          ):null }
          <Col xs={6} md={3} className='mb-4 px-2'>
          <img
                className="img-fluid imgFolder"
                src="/user.png"
                alt="user"
              />
          </Col>
          <Col xs={6} md={3} className='mb-4 px-2'>
          <img
                className="img-fluid imgFolder"
                src="/user.png"
                alt="user"
              />
          </Col>
          <Col xs={6} md={3} className='mb-4 px-2'>
          <img
                className="img-fluid imgFolder"
                src="/user.png"
                alt="user"
              />
          </Col>
          <Col xs={6} md={3} className='mb-4 px-2'>
          <img
                className="img-fluid imgFolder"
                src="/user.png"
                alt="user"
              />
          </Col>
          <Col xs={6} md={3} className='mb-4 px-2'>
          <img
                className="img-fluid imgFolder"
                src="/user.png"
                alt="user"
              />
          </Col>
          
        </Row>
      </div>
    );
};

export default ShowPhotos;