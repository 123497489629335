import React from "react";
import { Col } from "react-bootstrap";
import { MiddleColRoutes } from "./MiddleColRoutes";
import { TopNavBar } from "./Components/TopNavBar";
import LifeSearchBox from "../../../Components/LifeSearchBox";
export const MiddleCol = ({ middleColArg }) => {
  const { openSearchbox, searchBoxShow, setSearchBoxShow } = middleColArg;
  return (
    <Col sm={12} md={12} lg={6} className="stickyContainer ">
      <TopNavBar openSearchbox={openSearchbox} searchBoxShow={searchBoxShow} />
      {searchBoxShow && <LifeSearchBox setSearchBoxShow={setSearchBoxShow} />}

      <div onClick={() => setSearchBoxShow(false)}>
        <div id="mainpart" className="MiddlePartScrool">
          <MiddleColRoutes middleColArg={middleColArg} />
        </div>
      </div>
    </Col>
  );
};
