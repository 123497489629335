import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const IncomingAudioCall = () => {
  return (
    <div>
      <div
        className="csc__img-container"
        style={{ backgroundImage: "url(/ceo.png)" }}
      >
        <img className="img img-fluid csc-img" src="/ceo.png" alt="user" />
        <div className="csc__call-text text-center">
          <p className="mb-1">Incoming Call</p>
          <p>Kamrul Dolon</p>
        </div>
      </div>

      <div className="p-2 py-3 bg-gray call__screen__control csc-incoming">
        <button type="button" className="call__screen__control-btn csc-hangup">
          <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
        </button>
        <button type="button" className="call__screen__control-btn csc-receive">
          <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
        </button>
        <button type="button" className="call__screen__control-btn csc-message">
          <FontAwesomeIcon icon={faCommentDots}></FontAwesomeIcon>
        </button>
      </div>
    </div>
  );
};

export default IncomingAudioCall;
