import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./input_gender.css";

const InputGanderWithLabel = ({
  value,
  name,
  label,
  handler = () => {},
  className = ["", ""],
  checkoutInput = false,
  disabled = false
}) => {
  return (
    <Form.Group
      as={Row}
      className={`mb-3 ${checkoutInput && "mx-md-1"}`}
      controlId=""
    >
      <Form.Label
        className={`d-none d-md-block InputWithLabelLabel ${className[1]} ${
          checkoutInput && "text-start"
        }`}
        column
        sm="3"
      >
        {label}
      </Form.Label>
      <Col md="9">
        <Form.Label className={"d-block d-md-none "}>{label}</Form.Label>
        <Form.Select
         disabled={disabled}
          aria-label="gender select"
          id="gender"
          name={name}
          className={`${className[0]} InputWithLabelInput input-gander m-0 ${
            checkoutInput && "checkout-input"
          }`}
          value={value}
          onChange={handler}
        >
          <option className="selectPlaceholder">Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="others">Others</option>
        </Form.Select>
      </Col>
    </Form.Group>
  );
};

export default InputGanderWithLabel;
