import React from "react";

const SentIcon = ({ size="29" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 28 32"
      fill="none"
    >
      <path
        d="M27.593 17.481L0 31.634V27.534L23.431 15.878V15.759L0 4.10001V0L27.593 14.147V17.481Z"
        fill="#F1A350"
      />
    </svg>
  );
};

export default SentIcon;
