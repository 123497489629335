import React from 'react';

const MessageFileInput = ({ children, className }) => {
  return (
    <div className={className} style={{ cursor: "pointer" }}>
      <input
        type="file"
        id="file-input-driving"
        className="file-input"
      />
      <label
        htmlFor="file-input-driving"
        className="w-100"
        id=""
      >
        {/* <span className="file-text-btn chat__content-btn">  */}
          {children}
        {/* </span> */}
      </label>
    </div>
  );
};

export default MessageFileInput;