import axios from "axios";
import ToastFunc from "../ToastFunc";
export const SimplePostApiCalls = (formArg) => {
  const { formData, Url, setIsLoading, formateFormData } = formArg;
  setIsLoading(true);
  axios
    .post(`${Url}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      if (res.data.status === 1) {
        setIsLoading(false);
        ToastFunc("success", "Success");
      } else {
        console.log("err");
        setIsLoading(false);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  formateFormData();
  setIsLoading(false);
};
export const PostApiCalls = (formArg) => {
  const {
    formData,
    Url,
    setIsLoading,
    dataReload = () => {},
    setIsEditableFalse,
    itemId,
  } = formArg;
  setIsLoading(true);
  // post axios
  if (!itemId) {
    axios
      .post(`${Url}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === 1) {
          setIsLoading(false);
          dataReload();
          if (setIsEditableFalse) {
            setIsEditableFalse();
          }
        } else {
          console.log(res);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    axios
      .patch(`${Url}/${itemId}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === 1) {
          setIsLoading(false);
          dataReload();
          if (setIsEditableFalse) {
            setIsEditableFalse();
          }
        } else {
          console.log("err");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  setIsLoading(false);
};
export const DeleteCall = (formArg) => {
  const { formData, Url, setIsLoading, dataReload, setIsEditableFalse } =
    formArg;
  setIsLoading(true);
  axios
    .post(`${Url}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      if (res.data.status === 1) {
        setIsLoading(false);
        dataReload();
        if (setIsEditableFalse) {
          setIsEditableFalse();
        }
      } else {
        console.log("err");
        setIsLoading(false);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  setIsLoading(false);
};
export const paginationPostApiCall = (formArg, isAddPrevData) => {
  const {
    setIsLoading,
    postList,
    setPostList,
    setHasMore,
    Url,
    formData,
    setError,
  } = formArg;

  setIsLoading(true);

  const token = localStorage.getItem("access_token");
  localStorage.getItem("access_token");
  axios
    .post(`${Url}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      let next_page = res.data.next_page_url;
      let responseData = res.data.data;
      if (next_page === null) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      if (isAddPrevData) {
        if (Array.isArray(responseData)) {
          setPostList([...postList, ...responseData]);
        } else if (typeof responseData == "object" && responseData != null) {
          const resArray = [...postList];
          Object.entries(responseData).map(([key, value]) =>
            resArray.push(value)
          );
          setPostList(resArray);
        }
      } else {
        if (Array.isArray(responseData)) {
          setPostList([...responseData]);
        } else if (typeof responseData == "object" && responseData != null) {
          const resArray = [];
          Object.entries(responseData).map(([key, value]) =>
            resArray.push(value)
          );
          setPostList(resArray);
        }
      }
      setIsLoading(false);
    })
    .catch((err) => {
      console.log(err.message);
      setError(err.message);
      setIsLoading(false);
    });
};
