/* eslint-disable eqeqeq */

import Swal from "sweetalert2";

 export const sortA_Z = (a, b) => {
    let fa = a.name.toLowerCase(),
      fb = b.name.toLowerCase();
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
};
  

export const setData_to_sessionStorage = (data, key) => {
  const stringifiedData = JSON.stringify(data);
  sessionStorage.setItem(key, stringifiedData);
}
export const getData_from_sessionStorage = (key) => {
  const itemStr = sessionStorage.getItem(key);
  if (itemStr) {
    const items = JSON.parse(itemStr);
    return items;
  }else return null
}


// districts and division all function 
export const processDistricts = (data = []) => {
  const newData = data.map(item => {
    const newItem = {};
    if (item) {
      newItem.name = item.name;
      newItem.id = item.id;
      if (item.division_id) {
        newItem.division_id = item.division_id
      }
      return newItem;
    }
    return []
  })
  return newData;
}
export const getDivisionByDistricts = (id) => {
  const districts = getData_from_sessionStorage("districts");
  const divisions = districts.filter((item) => item.division_id == id);
  return divisions ? divisions : [];
}

export const getAllDivisions = () => {
  return getData_from_sessionStorage("divisions")
}


// category all function 
export const processCategories = (data = []) => {
  const categoriesList = [];
  const subCategoryList = [];
   data.forEach(category => {
    const { id, title, job_subcategory } = category;
    const result = { id, title };
    categoriesList.push(result)
    if (job_subcategory.length > 0) {
      job_subcategory.forEach(item => {
        const newSubCategory = {
          id: item.id,
          title: item.title,
          category_id: item.category_id
        }
        subCategoryList.push(newSubCategory);
      })
     }
    })
  return { categories: categoriesList, sub_categories: subCategoryList };
}

export const getAllCategories = () => {
  const data = getData_from_sessionStorage("category");
  return data.categories;
}

export const getCategoryBySubCategory = (category_id) => {
  const data = getData_from_sessionStorage("category").sub_categories;
  const subCategory = data.filter(item => item.category_id == category_id);
  return subCategory
}




export const deleteAlert = () => {
 return  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
   confirmButtonText: 'Yes, delete it!',
   customClass: {
     icon: "swl-alert-delete-icon",
     popup: "swl-custom-popup",
     title: "swal-uv-text",
     confirmButton: "swl-uv-confirm-btn",
     cancelButton:"swl-uv-cancel-btn"
    }
  })
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})


export const showToast = ({ title = "", icon = "success" }) => {
  Toast.fire({ icon: icon, title: title });
}