import { Container } from "react-bootstrap";
import { Route, Routes, useLocation } from "react-router-dom";
import PartnerRegistration from "../components/PartnerRegistration";
import PartnerDocument from "../components/PartnerDocument";
import RegistrationTimeline from "../components/RegistrationTimeline";

import overlay from "./../assets/overlay.png";
import waitVerification from "./../assets/wait-verification.jpg";
import welcome from "./../assets/welcome-uviom.jpg";

function JoinAsPartner() {
  return (
    <div
      id="wrapper"
      // style={{ backgroundImage: `url(${overlay})` }}
    >
      <Container>
        <div className="pt-0 pt-md-5 pt-lg-3" />
        <RegistrationTimeline />
        <PartnersRoutes />
      </Container>
    </div>
  );
}
export default JoinAsPartner;

const PartnersRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PartnerRegistration />} />
      <Route path="/documents" element={<PartnerDocument />} />
      <Route
        path="/documents/verification"
        element={
          <Container className="text-center py-5">
            <img className="img-fluid" src={waitVerification} alt="" />
          </Container>
        }
      />
      <Route
        path="/documents/verification/welcome"
        element={
          <Container className="text-center py-5">
            <img className="img-fluid" src={welcome} alt="" />
          </Container>
        }
      />
    </Routes>
  );
};
