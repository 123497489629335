import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const InputRangeWithLabel = ({
  startValue = "",
  endValue,
  startName = "",
  endName = "",
  label = "",
  handler = () => { },
  className = ["", ""],
  checkoutInput = false,
  
}) => {
  return (
    <Form.Group as={Row}  controlId="" className={` mb-3 ${checkoutInput && "mx-md-1"}`}>
      <Form.Label
        className={`d-none d-md-block jobApplyInputLabel  ${className[1]} ${checkoutInput && "text-start"}`}
        column
        sm="3"
      >
        {label}
      </Form.Label>
      <Form.Label className={"d-block d-md-none"}>
        {label}
        <br />
      </Form.Label>
      <Col sm="5" md="4">
        <Form.Control
          className={`jobApplyInput ${className[0]}`}
          placeholder="Start Range"
          type="number"
          name={startName}
          onChange={handler}
          value={startValue}
          required
        />
      </Col>
      <Col sm="2" md="1" className="d-flex align-items-center justify-content-center">
        <span> To </span>
      </Col>
      <Col sm="5" md="4">
        <Form.Control
          className={`jobApplyInput ${className[0]}`}
          placeholder="End Range"
          type="number"
          name={endName}
          onChange={handler}
          value={endValue}
          required
        />
      </Col>
    </Form.Group>
  );
};

export default InputRangeWithLabel;
