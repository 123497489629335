import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./app/store";

import "./cssvariable.css";
import "./index.css";
import "./utilityclass.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import AWS from "aws-sdk";
import {
  AWS_ACCESS_ID,
  AWS_SECRET_KEY,
} from "./Projects/Life/Common/CommonUrl";

AWS.config.update({
  accessKeyId: AWS_ACCESS_ID,
  secretAccessKey: AWS_SECRET_KEY,
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
