import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Request from "./pages/Request";
import DeliveryReceipt from "./pages/DeliveryReceipt";
import GoToReceive from "./pages/GoToReceive";
import GoToDelivery from "./pages/GoToDelivery";
import PackageInfo from "./pages/PackageInfo";
import DeliveryReceived from "./pages/DeliveryReceived";
import DeliveryPayment from "./pages/DeliveryPayment";
import TrackHistory from "./pages/TrackHistory";
import Profile from "./pages/Profile";
import Header from "./components/Header";
import Footer from "./../Ride/components/Footer";

import About from "./../Creative/pages/About";
import Privacy from "../Creative/pages/Privacy";
import Contact from "../Creative/pages/Contact";

import "./express.css";

function Express() {
  return (
    <>
      <Header />
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path="/trackhistory" element={<TrackHistory />} />
        <Route path="/request/*" element={<Request />} />
        <Route path="/delivery/payment" element={<DeliveryPayment />} />
        <Route path="/delivery/preview" element={<DeliveryReceipt />} />
        <Route path="/delivery/confirm" element={<DeliveryReceipt />} />
        {/* below pages are not linked yet. will be added after the api complete and decide where to put the link */}
        <Route path="/delivery/packageinfo" element={<PackageInfo />} />
        <Route path="/delivery/gotoreceive" element={<GoToReceive />} />
        <Route path="/delivery/received" element={<DeliveryReceived />} />
        <Route path="/delivery/gotodelivery" element={<GoToDelivery />} />

        <Route path="/profile/*" element={<Profile />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}
export default Express;
