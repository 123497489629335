import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { getAllDivisions } from "../../utilities/functions";

const LocationSelectInput = ({
  division_value = "",
  district_value = "",
  label = "",
  handler = () => {},
  className = ["", ""],
  checkoutInput = false,
  children,
  disabled = false,
}) => {
  const divisions = getAllDivisions() || [];

  return (
    <Form.Group
      as={Row}
      controlId=""
      className={` mb-3 ${checkoutInput && "mx-md-1"}`}
    >
      <Form.Label
        className={`d-none d-md-block jobApplyInputLabel  ${className[1]} ${
          checkoutInput && "text-start"
        }`}
        column
        sm="3"
      >
        {label}
      </Form.Label>
      <Form.Label className={"d-block d-md-none"}>
        {label}
        <br />
      </Form.Label>
      <Col sm="5" md="4">
        <Form.Select
          required
          onChange={handler}
          value={division_value}
          name="division_id"
          aria-label="payment method"
          className={`payment-method-input delivery-charge  ${className[0]} ${
            checkoutInput && "checkout-input"
          }`}
        >
          <option value="" disabled>
            Select Division
          </option>
          {divisions.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Select>
      </Col>
      <Col
        sm="2"
        md="1"
        className="d-flex align-items-center justify-content-center"
      >
        <span> </span>
      </Col>
      <Col sm="5" md="4" className="mt-3 mt-sm-0">
        <Form.Select
          required
          disabled={disabled}
          onChange={handler}
          value={district_value}
          name="district_id"
          aria-label="payment method"
          className={`payment-method-input delivery-charge  ${className[0]} ${
            checkoutInput && "checkout-input"
          }`}
        >
          {children}
        </Form.Select>
      </Col>
    </Form.Group>
  );
};

export default LocationSelectInput;
