import { Container } from "react-bootstrap";

import timelineIcon1 from "./../../assets/timeline-icon-1.svg";
import timelineIcon2 from "./../../assets/timeline-icon-2.svg";
import timelineIcon3 from "./../../assets/timeline-icon-3.svg";
import timelineIcon4 from "./../../assets/timeline-icon-4.svg";
import timelineIcon5 from "./../../assets/timeline-icon-5.svg";

import dropIcon from "./../../assets/drop.svg";
import pickupIcon from "./../../assets/pickup.svg";

import "./trackingHistory.css";

function TrackingHistory() {
  return (
    <div>
      <Container>
        <div className="trackingHistory">
          <div className="trackingHistory__cards">
            <div className="trackingHistory__card">
              <p className="m-0">
                <span className="fw-600">Tracking ID:</span> #220610001
              </p>
              <p className="m-0">
                <span className="fw-600">Order ID:</span> #220610001
              </p>
              <p className="m-0">
                <span className="fw-600">Delivery Type:</span> Same Day
                Express
              </p>
            </div>

            <div className="trackingHistory__card">
              <p className="m-0">
                <span className="fw-600">Tracking ID:</span> #220610001
              </p>
              <p className="m-0">
                <span className="fw-600">Order ID:</span> #220610001
              </p>
              <p className="m-0">
                <span className="fw-600">Delivery Type:</span> Same Day
                Express
              </p>
            </div>

            <div className="trackingHistory__card with-location">
              <div className="trackingHistory__card__location">
                <div>
                  <h6 className="fw-600">Pickup Location</h6>
                  <span>
                    {" "}
                    <img src={pickupIcon} className="dark-invert" alt="" /> Bronx, New York 1035
                  </span>
                </div>
                <div className="h2 m-0">{">"}</div>
                <div>
                  <h6 className="fw-600">Dropoff Location</h6>
                  <span>
                    {" "}
                    <img src={dropIcon} className="dark-invert" alt="" /> Bronx, New York 1035
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="trackingHistory__down">
            <div>
              <ul className="tracking__timeline">
                <li className="timeline__event">
                  <img src={timelineIcon1} alt="" />
                  <div>
                    <p className="fw-600 m-0">Order By</p>
                    <span>John Doe</span>
                    <br />
                    <span>Sep 7 - 2022,11.00 AM</span>
                  </div>
                </li>

                <li className="timeline__event">
                  <img src={timelineIcon2} alt="" />
                  <div>
                    <p className="fw-600 m-0">Order Confirm By</p>
                    <span>UVIOM Brand</span>
                    <br />
                    <span>Sep 7 - 2022,11.00 AM</span>
                  </div>
                </li>

                <li className="timeline__event">
                  <img src={timelineIcon3} alt="" />
                  <div>
                    <p className="fw-600 m-0">Order Pickup By</p>
                    <span>UVIOM Express Rider</span>
                    <br />
                    <span>Sep 7 - 2022,11.00 AM</span>
                  </div>
                </li>

                <li className="timeline__event">
                  <img src={timelineIcon4} alt="" />
                  <div>
                    <p className="fw-600 m-0">Order Shipped By</p>
                    <span>UVIOM Express</span>
                    <br />
                    <span>Sep 7 - 2022,11.00 AM</span>
                  </div>
                </li>

                <li className="timeline__event">
                  <img src={timelineIcon5} alt="" />
                  <div>
                    <p className="fw-600 m-0">Order Delivered</p>
                    <span>UVIOM Express</span>
                    <br />
                    <span>Sep 7 - 2022,11.00 AM</span>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <iframe
                className="rideMap"
                title="rideMap"
                src="https://maps.google.com/maps?output=embed&amp;ll=54.868705,-1.593018&amp;z=11"
                width="100%"
              ></iframe>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
export default TrackingHistory;
