import React, { useEffect } from "react";
import { useState } from "react";
import { Row } from "react-bootstrap";
import { useGetHomeCompaniesQuery } from "src/app/services/jobApi/jobGetApi";
import JobSkeleton from "src/Projects/Job/Components/Placeholder/JobSkeleton";
import CompanyCard from "./CompanyCard";

const HomeCompany = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const [companiesList, setCompaniesList] = useState([]);

  const {
    data: result,
    isFetching,
    error,
  } = useGetHomeCompaniesQuery(currentPage);
  const { data, hasMore } = result || {};

  useEffect(() => {
    if (data) {
      setCompaniesList((prevState) => [...prevState, ...data]);
    }
  }, [data]);

  const handleLoadMore = () => {
    if (hasMore) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  return (
    <div className="p-2 mt-4">
      <p className="text-orange">Company</p>

      {companiesList.length < 1 && !isFetching && (
        <p className="text-center mt-4"> 0 item Found </p>
      )}

      {isFetching && (
        <Row className="g-3"  xs="1" sm="2" lg="1" xl="2" xxl="3">
          {[...new Array(6)].map((_, index) => (
            <JobSkeleton key={index} />
          ))}
        </Row>
      )}
      <Row className="g-3" xs="1" sm="2" lg="1" xl="2" xxl="3">
        {companiesList.map((post) => (
          <CompanyCard key={post.id} data={post} />
        ))}
      </Row>
      {error && <p className="text-danger">{error.error}</p>}
      <div className="mt-4 mb-3 text-end">
        <button
          className="send-code-btn primary-text"
          disabled={!hasMore}
          onClick={handleLoadMore}
        >
          {" "}
          Load More
        </button>
      </div>
    </div>
  );
};

export default HomeCompany;
