import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { isEmpty } from "lodash";
import { updateForm } from "./common/apiCalls";
import { updateMainProfileMailingInfoUrl } from "src/Projects/Life/Common/CommonUrl";
const UviomMainProfileMailingAddress = ({ info, getData }) => {
  const [isEditable, setIsEditable] = useState(false);
  const setIsEditableFalse = () => setIsEditable(false);
  const setIsEditableTrue = () => setIsEditable(true);


  const [isLoading, setIsLoading] = useState(false);
  const [formData, setformData] = useState({
    "street_address": "",
    "house_no": "",
    "city": "",
    "state": "",
    "post_code": "",
    "country": "",
  })
  useEffect(() => {
    if (!isEmpty(info)) {
      setformData({
        "street_address": info && info.user_mailing_address && info.user_mailing_address.street_address ? info.user_mailing_address.street_address : "",
        "house_no": info && info.user_mailing_address && info.user_mailing_address.house_no ? info.user_mailing_address.house_no : "",
        "city": info && info.user_mailing_address && info.user_mailing_address.city ? info.user_mailing_address.city : "",
        "state": info && info.user_mailing_address && info.user_mailing_address.state ? info.user_mailing_address.state : "",
        "post_code": info && info.user_mailing_address && info.user_mailing_address.post_code ? info.user_mailing_address.post_code : "",
        "country": info && info.user_mailing_address && info.user_mailing_address.country ? info.user_mailing_address.country : "",
      })
    }
  }, [info])

  const inputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...formData };
    newData[name] = value;
    setformData(newData);
  };
  const updateFormFunc = (e) => {
    e.preventDefault();
    const updateFormArg = {
      "formData": formData,
      "updateMainProfileInfoUrl": updateMainProfileMailingInfoUrl,
      "setIsLoading": setIsLoading,
      "dataReload": getData,
      "setIsEditableFalse": setIsEditableFalse,
    }
    updateForm(updateFormArg)
  }
  return (
    <div>
      <div className="p-1 p-sm-2 p-lg-3 pt-3 mainProfileEditSection my-mt">
        <div className="d-flex justify-content-between">
          <p>Mailing Address</p>
          <FontAwesomeIcon onClick={setIsEditableTrue}
            className="mainProfilePenEditBtn" icon={faPencilAlt}></FontAwesomeIcon>
        </div>

        <form className="p-1 p-lg-3" onSubmit={updateFormFunc}>
          <Row>
            <Col>
              <Form.Control
                disabled={!isEditable}
                type="text"
                placeholder="Street Address"
                className="mainProfileInput"
                name="street_address"
                value={formData.street_address}
                onChange={inputHandleChange}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Control
                disabled={!isEditable}
                className="mainProfileInput"
                type="text"
                placeholder="House/Appartment Number"
                name="house_no"
                value={formData.house_no}
                onChange={inputHandleChange}
              />
            </Col>
          </Row>
          <br />
          <Row xs={1} lg={2} className="g-4 mainProfileSelect">
            <Col>
              <Form.Control
                disabled={!isEditable}
                className="mainProfileInput"
                type="text"
                placeholder="City"
                name="city"
                value={formData.city}
                onChange={inputHandleChange}
              />
            </Col>
            <Col>
              <Form.Control
                disabled={!isEditable}
                className="mainProfileInput"
                type="text"
                placeholder="State"
                name="state"
                value={formData.state}
                onChange={inputHandleChange}
              />
            </Col>
            <Col>
              <Form.Control
                disabled={!isEditable}
                className="mainProfileInput"
                type="text"
                placeholder="Post Code"
                name="post_code"
                value={formData.post_code}
                onChange={inputHandleChange}
              />
            </Col>
            <Col>
              <Form.Control
                disabled={!isEditable}
                className="mainProfileInput"
                type="text"
                placeholder="Country"
                name="country"
                value={formData.country}
                onChange={inputHandleChange}
              />
            </Col>
          </Row>
          {
            isEditable && <div className="py-3 mainProfileBtnDiv">
              <button onClick={setIsEditableFalse} className="mainProfileBtn btnCandel me-3">Cancel</button>
              {isLoading ? (
                <button className="mainProfileBtn btnSave disabled" disabled>save</button>
              ) : (
                <button type="submit" className="mainProfileBtn btnSave" >save</button>
              )}
            </div>
          }
        </form>
      </div>
    </div>
  );
};

export default UviomMainProfileMailingAddress;
