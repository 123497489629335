import React, { useRef, useState } from "react";
import ApplicantInformation from "../../Components/Common/ApplicantInformation";
import "./ApplicantDetails.css";
import handlePrint from "src/utilities/handlePrint";
import { useLocation } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import ApplicantAddress from "./ApplicantAddress";
import ApplicantEducation from "./ApplicantEducation";

const ApplicantDetails = () => {
  const printContent = useRef();
  const printContainer = useRef();
  const { state } = useLocation();
  const [key, setKey] = useState("info");
  return (
    <div>
      <h6 className="jobApplyTitle ms-2 mb-3">Applicant Details</h6>

      <div className="jobInformation px-0 px-md-2">
        <div ref={printContent} className="printInformationContainer">
          <Tabs
            id="food-order-history"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 mt-3 food-tab "
            transition={true}
          >
            <Tab eventKey="info" title="Info">
              <ApplicantInformation data={state.data} hideAddress />
            </Tab>
            <Tab eventKey="address" title="Address">
              <ApplicantAddress data={state.data} />
            </Tab>
            {state.educations && (
              <Tab eventKey="education" title="Education">
                <ApplicantEducation educations={state.educations} />
              </Tab>
            )}
          </Tabs>
        </div>
        <div className="d-flex justify-content-center">
          <div>
            <button
              className="ApplicantDetailsBtn "
              onClick={(e) => handlePrint(e, printContent, printContainer)}
            >
              Print
            </button>
            <button className="ApplicantDetailsBtn">Email</button>
            <button className="ApplicantDetailsBtn ">Message</button>
          </div>
        </div>
        <br />
      </div>

      <iframe
        ref={printContainer}
        title="print"
        style={{ opacity: "0" }}
      ></iframe>

      <br />
      <br />
      <br />
    </div>
  );
};

export default ApplicantDetails;
