


export const education_columns = [
  {
    name: "Institute Name",
    selector: (row) => row.institute,
       
        minWidth:"15rem"
    
  },
  {
    name: "Location",
      selector: (row) => row.location,
      minWidth:"15rem"
  },
  {
    name: "Board",
    selector: (row) => row.board,
  },
  {
    name: "Exam Name",
      selector: (row) => row.exam_name,
    minWidth:"8rem"
  },
  {
    name: "Year",
    selector: (row) => row.year,
  },
  {
    name: "Result",
    selector: (row) => row.result,
  },
  {
    name: "Subject Group",
    selector: (row) => row.subject_group,
  },
  {
    name: "Level",
    selector: (row) => row.level,
  },
//   {
//     name: "Action",
//     button: true,
//     selector: (row) => (
//         <>
//         <button className="eco-btn" onClick={() => handleEducationDelete(row.id)}>
//         <FontAwesomeIcon className="text-orange ms-2" icon={faTrashAlt} />
//       </button>
//         <button className="eco-btn" >
//         <FontAwesomeIcon className=" ms-2" icon={faPencilAlt} />
//       </button>
//         </>
//     ),
//   },
];
