import React from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";

const MainMenu = ({ menuitem, handleClose }) => {
  return (
    <>
      <Accordion.Item
        eventKey={menuitem.id}
        style={{ background: "transparent" }}
      >
        <Accordion.Header>
          <Link
            to={`/Job/job-market/categories/${menuitem.slug}/${menuitem.id}`}
          >
            {menuitem.title}
          </Link>
        </Accordion.Header>
        <Accordion.Body style={{ background: "transparent" }}>
          {menuitem.job_subcategory.map((submenuItem) => {
            return (
              <li
                key={submenuItem.id}
                onClick={handleClose}
                className="categoriesLink d-block"
              >
                <Link
                  to={`/Job/job-market/sub-categories/${submenuItem.slug}/${submenuItem.id}`}
                >
                  {submenuItem.title}
                </Link>
              </li>
            );
          })}
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default MainMenu;
