import React from "react";
import styled from "styled-components";

export const SingleNotif = () => {
  return (
    <Wrapper>
      <img className="img img-fluid notiImg" src="/View/Card.png" alt="" />

      <div>
        <div>
          <span className="fw-bold">Technical Baatein </span>
          <span> replied: "@Neha Mishra Drink Safi Syrup"</span>
        </div>
        <div className="mediumTextSize text-gray">3 weeks ago</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  box-shadow: var(--box-shadow);
  border-radius: 0.1rem;
  padding: 1rem;
  margin-bottom: 1rem;

  display: flex;
  gap: 1rem;
`;
