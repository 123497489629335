import { faFlag } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import styled from "styled-components";
import { RightColButtons } from "../Components/RightCol/RightColButtons";

function NavigationOffCanvas() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const [, dynamicPath, dynamicLife] = pathname.split("/");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setShow(false);
  }, [pathname]);

  return (
    <>
      <button
        className="d-flex d-lg-none topNavItem border-0"
        aria-label="navigation extra"
        onClick={handleShow}
      >
        <ThreeDots className="topNavIcon" size="25" />
      </button>
      <div className="">
        <Offcanvas
          className="navigationOffCanvas"
          show={show}
          onHide={handleClose}
        >
          <Cross onClick={handleClose}>&times;</Cross>
          <Offcanvas.Body>
            <RightColButtons />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
}

export default NavigationOffCanvas;

const Cross = styled.div`
  font-size: 1.8rem;
  color: var(--text-success);
  line-height: 0;
  position: relative;
  top: 1rem;
  left: 100%;

  & + .offcanvas-body {
    padding-block: 0;
  }
`;
