import React from 'react';
import { Col, Row } from 'react-bootstrap';

const PhotoGallery = () => {
    return (
        <div>
        <Row>
          <Col xs={6} md={3}>
            <div className="w-100 text-center">
              <img
                className="img-fluid imgFolder"
                src="https://i.ibb.co/nDQ2Yy7/add-photo.png"
                alt="add to album"
              />
              <h6 className="mt-2"> Add Album</h6>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <div className="w-100 text-center">
              <img
                className="img-fluid imgFolder"
                src="/user.png"
                alt="user"
              />
              <h6 className="mt-2"> Profile Photo</h6>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <div className="w-100 text-center">
              <img
                className="img-fluid imgFolder"
                src="/user.png"
                alt="user"
              />
              <h6 className="mt-2"> Cover Photo</h6>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <div className="w-100 text-center">
              <img
                className="img-fluid imgFolder"
                src="/user.png"
                alt="user"
              />
              <h6 className="mt-2">Mobile Upload</h6>
            </div>
          </Col>
        </Row>
      </div>
    );
};

export default PhotoGallery;