import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import useStore from "src/hooks/useStore";
import useCartStore from "src/Projects/Corporate/hooks/useCartStore";
import { productImgBase_m } from "src/Projects/Corporate/utilities/CommonUrl";
import { ProductImg } from "../ProductCard/ProductImg";
import "./CartModalItem.css";

const CartModalItem = ({ data }) => {
  const { id,  product_image, product_name, quantity,eachItemTotal ,slug} = data;
  const { removeItem, quantityAdd } = useCartStore();

  const location = useLocation();
  const [locationDisplay, setlocationDisplay] = useState(true);
  const { closeCartModal } = useStore();

  useEffect(() => {
    // console.log(location.pathname,locationDisplay)
    if (location.pathname === "/ubizz/my-cart" || location.pathname === "/ubizz/my-cart/") {
      setlocationDisplay(true);
    } else {

      setlocationDisplay(false);

    }
  }, []);
  const {  pathname } = useLocation();

  const navigate = useNavigate();
  const mainUrl = `${pathname.split("/")[1]}`;
  const handleClick = () => {
    closeCartModal()
    navigate(`/${mainUrl}/product-details/${slug}`)

  }
  return (
    <div
      className={`secondary-bg p-2 my-3 cart-modal-item ${locationDisplay ? "myCartPage" : "cartModal"
        }`}
    >
      <Row className="g-3">
        <Col xs="9" className="pointer" onClick={handleClick}>
          <div className="favorite-card  m-0">
            <div className="cart-item-img-box">
              {product_image ? (
                <ProductImg product_image={product_image} imgBase={productImgBase_m} />
              ) : (
                <div className='no_img'>No img</div>
              )}
            </div>
            <div
              style={{ height: "100%" }}
              className="cart-text-box align-items-start d-flex flex-column justify-content-between infoBody"
            >
              <p className="overme cartPropductTitle">
                {product_name}
              </p>

              <div style={{ fontSize: "0.7rem" }}>
                <div>Model : #524521</div>

                {locationDisplay ? (
                  <div className="d-flex flex-column justify-content-md-between align-items-start flex-md-row  gap-md-3 ">
                    <div>Brand : Fastrack </div>
                    <div className="">Shop: UVIOM </div>
                  </div>
                ) : null}
              </div>
              <h6 className="my-0" style={{ fontSize: "0.90rem" }}>
                Price: <span className="text-orange">{eachItemTotal}Tk</span>
              </h6>
            </div>
          </div>
        </Col>
        <Col xs="3 d-flex justify-content-between">
          {locationDisplay ? (
            <div className="btn-group quantity btn-group-sm" role="group" aria-label="First group">
              <button type="button" className="btn" onClick={() => {
                quantityAdd(
                  quantity > 0 && id,
                  quantity + 1
                );
              }}>+</button>
              <button type="button" className="btn" >{quantity}</button>
              <button type="button" className="btn"
                onClick={() => {
                  quantity > 1 &&
                    quantityAdd(id, quantity - 1);
                }}
              >-</button>
            </div>
          ) : null}

          <div className="d-flex flex-column  justify-content-between align-items-end me-2 h-100">
            {/* <h5 className="text-orange">4000</h5> */}

            {locationDisplay ? (
              <button className="cart-modal-remove-btn py-1 me-md-2 ">
                <FontAwesomeIcon className="" icon={faHeart} />
              </button>
            ) : null}

            <button className="btn-0 text-orange cart-modal-remove-btn py-1 me-md-1 " onClick={() => removeItem(id)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CartModalItem;
