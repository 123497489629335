import { fetchFile } from "@ffmpeg/ffmpeg";

export const compress_video = async (file, ffmpeg) => {
  const num = Math.floor(Math.random() * 100);
  const name = file.name + num;
  const compress_command = [
    "-i",
    name,
    "-vcodec",
    "libx264",
    "-crf",
    "28",
    `output${num}.mp4`,
  ];

  try {
    await ffmpeg.load();
    ffmpeg.FS("writeFile", name, await fetchFile(file));
    await ffmpeg.run(...compress_command);
    const data = ffmpeg.FS("readFile", `output${num}.mp4`);
    const blob = new Blob([data.buffer], { type: "video/mp4" });

    return new File([blob], name, { type: "video/mp4" });
  } catch (err) {
    console.log(err);
    return file;
  }
};

export const process_progress = (str, duration) => {
  try {
    if (str.includes("time=")) {
      const result_array = str.split(" time=")[1].split(" ")[0].split(":");
      const second = parseInt(result_array[2]);
      const minute = parseInt(result_array[1]);
      const hour = parseInt(result_array[0]);
      const total_second = second + minute * 60 + hour * 60 * 60;
      const per = Math.round((total_second / duration ) * 100);
      return per;
    } else {
      return 0;
    }
  } catch {
    return "--";
  }
};
