/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CoverArea } from "./Components/CoverArea";
export const AddOnPages = () => {
  const navigate = useNavigate();
  const [previosLife, setpreviosLife] = useState();
  let familyPath = "/life/family-life";
  let friendPath = "/life/friends-life";
  let professionalPath = "/life/professional-life";

// comment for router update issue 

  // useEffect(() => {
  //   if (history.location.state) {
  //     let path = history.location.state.from.pathname;
  //     if (path) {
  //       if (path.includes(familyPath)) {
  //         setpreviosLife(familyPath);
  //       } else if (path.includes(friendPath)) {
  //         setpreviosLife(friendPath);
  //       } else if (path.includes(professionalPath)) {
  //         setpreviosLife(professionalPath);
  //       } else {
  //         setpreviosLife(familyPath);
  //       }
  //     }
  //   } else {
  //     setpreviosLife(familyPath);
  //   }
  // }, [navigate]);

  const [formData, setformData] = useState({});
  const inputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...formData };
    newData[name] = value;
    setformData(newData);
  };
  return (
    <div className="container-fluid mainContentWithRoundedBg mt-3 mb-5 p-3">
      {/* {JSON.stringify(formData)} */}
      {/* cover ------------ */}
      <CoverArea />
      {/* inputs ------------ */}
      <div className="row mt-3">
        <div className="col-sm-6">
          <div>Page Name</div>
          <div className="InputFildDiv px-0 w-100">
            <input
              onChange={inputHandleChange}
              name="pageName"
              value={formData && formData.pageName ? formData.pageName : ""}
              type="text"
              placeholder="Write here"
              className="px-2 rounded-0"
              required
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div>Categories</div>
          <div className="InputFildDiv px-0 w-100">
            <input
              onChange={inputHandleChange}
              name="categories"
              value={formData && formData.categories ? formData.categories : ""}
              type="text"
              placeholder="Write here"
              className="px-2 rounded-0"
              required
            />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12">
          <div>Description</div>
          <div className="InputFildDiv px-0 w-100">
            <textarea
              name="description"
              className="px-2 rounded-0"
              onChange={inputHandleChange}
              rows="5"
              cols="50"
              placeholder="Write here ...!"
              value={
                formData && formData.description ? formData.description : ""
              }
              required
            ></textarea>
          </div>
        </div>
      </div>
      {/* buttons ------------ */}
      <div className="row mt-3">
        <div className="col-sm-12 d-flex">
          <button
            className="btn btn-secondary ms-auto me-3"
            aria-label="submit"
            onClick={() => {
              navigate(`${previosLife}/lifepageview`, {
                // from: history.location,
              });
            }}
            onKeyDown={e => {
              if(e.key==="Enter"){
                navigate(`${previosLife}/lifepageview`, {
                  // from: history.location,
                });
              }
            }}
          >
            <h6 className="mb-0 py-1">Submit</h6>
          </button>
          <button
            className="btn btn-secondary"
            aria-label="Cancel"
            onClick={() => {
              setformData({});
            }}
            onKeyDown={e => {
              if(e.key==="Enter"){
                setformData({});
              }
            }}
          >
            <h6 className="mb-0 py-1">Cancel</h6>
          </button>
        </div>
      </div>
    </div>
  );
};
