import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";

function OrderRequirementModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="creative__modal__header" closeButton>
        <h6 className="mb-0">Accept Agreement</h6>
      </Modal.Header>
      <Modal.Body className="creative__modal__body">
        <Row>
          <Col lg={5}>
            <div className="d-flex gap-3 align-items-center">
              <img
                src="https://i.ibb.co/Bfp9vX0/freelancer-3.jpg"
                alt=""
                className="creative__modal__freelancer__img"
              />
              <div>
                <h6>Client</h6>
                <p className="mb-0">Bangladesh</p>
                <p>
                  <FontAwesomeIcon className="text-orange" icon={faStar} /> 5.0
                  (12)
                </p>
              </div>
            </div>

            <h6 className="mt-3">Requirement Task</h6>

            <div className="creative__skills__badge mt-3">UX Research</div>
            <br />
            <div className="creative__skills__badge mt-2 mb-4">
              Information Architecture
            </div>

            <br />
            <div className="creative__skills__badge align-items-center d-inline-flex mt-2">
              Download
              <FontAwesomeIcon
                className="ms-2 text-orange"
                icon={faCloudDownloadAlt}
              />
            </div>
          </Col>
          <Col className="mt-4 mt-lg-0" lg={7}>
            <Form.Control
              defaultValue="Enim euismod in facilisis enim nisl urna."
              disabled
              className="input-reset"
              type="text"
              placeholder="Agreement Title"
            />
            <Row>
              <Col>
                <Form.Control
                  className="mt-3 input-reset"
                  type="text"
                  defaultValue="$200.00"
                  disabled
                  placeholder="Agreement Amount"
                />
              </Col>
              <Col>
                <Form.Control
                  className="mt-3 input-reset"
                  type="text"
                  defaultValue="3 Days"
                  disabled
                  placeholder="Complete in"
                />
              </Col>
            </Row>

            <Form.Control
              className="mt-3 shadow-0 input-reset"
              as="textarea"
              defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suscipit ac donec semper varius maecenas purus mattis. Id habitant mi imperdiet tristique auctor sagittis diam elementum tempor. Ac diam egestas ut eget laoreet purus. Molestie vitae tortor odio dictum. Ac viverra molestie praesent accumsan. Diam malesuada."
              disabled
              rows={8}
              placeholder="Agreement Details"
            />
          </Col>
        </Row>

        <div className="mt-3 py-3 text-lg-end">
          <Button className="btn__creative" onClick={props.onHide}>
            Accept
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default OrderRequirementModal;
