import React, { useState } from "react";
import "./shippingAndBilling.css";
import useStore from "src/hooks/useStore";
import DeliveryAddress from "../DeliveryAddress/DeliveryAddress";
import OnTable from "../OnTable/OnTable";
import Pickup from "../Pickup/Pickup";
import { Form } from "react-bootstrap";

const ShippingAndBilling = ({
  handleShippingAddressChange,
  handleAddressSubmit,
  errorMessage,
}) => {
  const { shippingAddress } = useStore();
  const [orderType, setOrderType] = useState("delivery");
  const handleOrderTypeChange = (type) => {
    setOrderType(type);
  };

  return (
    <div>
      <Form className="food__order-method p-2 text-center">
        <Form.Check
          inline
          label="On Table"
          name="order"
          type="radio"
          id="1"
          checked={orderType === "on_table"}
          onChange={() => handleOrderTypeChange("on_table")}
          required
        />
        <Form.Check
          inline
          label="Delivery"
          name="order"
          type="radio"
          id="2"
          checked={orderType === "delivery"}
          onChange={() => handleOrderTypeChange("delivery")}
          required
        />
        <Form.Check
          inline
          name="order"
          label="Pickup"
          type="radio"
          id="3"
          checked={orderType === "pickup"}
          onChange={() => handleOrderTypeChange("pickup")}
          required
        />
      </Form>

      {orderType === "delivery" && (
        <DeliveryAddress
          handleShippingAddressChange={handleShippingAddressChange}
          shippingAddress={shippingAddress}
        />
      )}
      {
        orderType === "on_table" && <OnTable/>
      }
      {
        orderType === "pickup" && <Pickup/>
      }

      <p className="text-center text-danger" style={{ fontSize: ".75rem" }}>
        {errorMessage}
      </p>
      <div className="text-center mt-2">
        <button className="me-2 send-code-btn">Cancel</button>
        <button
          onClick={handleAddressSubmit}
          type="submit"
          className=" send-code-btn cart-confirm-btn text-dark"
        >
          Save
        </button>
      </div>

      <br />
    </div>
  );
};

export default ShippingAndBilling;
