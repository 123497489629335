/* eslint-disable react-hooks/exhaustive-deps */
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useProductRequestMutation } from "src/app/services/ubizzApi/ubizzApi";
import styled from "styled-components";

const ProductRequest = () => {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [postProductRequest, { isLoading, isSuccess, error, data }] =
    useProductRequestMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    postProductRequest(formData);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const prevState = formData;
    prevState[name] = value;
    setFormData({ ...prevState });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/ubizz");
    }
  }, [isSuccess, data]);

  return (
    <div>
      <h4 className="m-4">Product Request </h4>
      <FormContainer>
        <div className="form-wrapper container secondary-bg mb-5">
          <Form className="product_form p-3" onSubmit={handleSubmit}>
            <h4 className="text-center my-4">
              One Request, Multiple Products!
            </h4>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.name || ""}
                    name="name"
                    className="checkout-input InputWithLabelInput"
                    onChange={handleChange}
                    placeholder="Full Name"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={formData.email || ""}
                    name="email"
                    className="checkout-input InputWithLabelInput"
                    onChange={handleChange}
                    placeholder="Email Address"
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="fname">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="number"
                    value={formData.phone || ""}
                    name="phone"
                    className="checkout-input InputWithLabelInput"
                    onChange={handleChange}
                    placeholder="Mobile No"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="subject">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.subject || ""}
                    name="subject"
                    className="checkout-input InputWithLabelInput"
                    onChange={handleChange}
                    placeholder="Subject"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="lName">
                  <Form.Label>Link</Form.Label>
                  <Form.Control
                    type="url"
                    value={formData.link || ""}
                    name="link"
                    className="checkout-input InputWithLabelInput"
                    onChange={handleChange}
                    placeholder="Link"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="message">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                className="contactTextarea checkout-input InputWithLabelInput"
                value={formData.message || ""}
                name="message"
                onChange={handleChange}
                placeholder="Write Here..."
                required
              />
            </Form.Group>

            {error && <p className="text-danger text-center">{error.error}</p>}

            <div className="text-start">
              <button
                className="send-code-btn cart-confirm-btn"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className="text-white">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="text-white me-2"
                      spin
                    />{" "}
                    Request for Products
                  </span>
                ) : (
                  "Request for Products"
                )}
              </button>
            </div>
          </Form>
        </div>
      </FormContainer>
    </div>
  );
};

export default ProductRequest;

const FormContainer = styled.div`
  .form-wrapper {
    display: flex;
    justify-content: center;
  }

  .product_form {
    background-color: var(--bg-secondary);
    width: 768px;
  }

  .send-code-btn.cart-confirm-btn:disabled {
    background-color: var(--text-success);
    color: black;
  }
`;
