import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  otherProfileImgUrl,
  postImagePath,
} from "src/Projects/Life/Common/CommonUrl";
import { getPhotos } from "../Components/ApiCalls";
import "./ViewPhotoAndVIdeos.css";
import { NextButtonRow } from "../../../../../Common/NextButtonRow";
import { Title } from "src/Projects/Life/LifePages/Common/Title";
import { PhotoSlider } from "react-photo-view";
import { SingleImage } from "./SingleImage";

const ViewPhotos = ({ middleColArg }) => {
  const { user_id } = middleColArg;

  const [title] = useState("Photos");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [hasMore, sethasMore] = useState(true);

  const [info, setinfo] = useState([]);
  const getPhotosArg = {
    setIsLoading: setIsLoading,
    Url: otherProfileImgUrl,
    user_id: user_id,
    info: info,
    setinfo: setinfo,
    currentPage: currentPage,
    sethasMore: sethasMore,
  };
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getPhotos(getPhotosArg);
  };
  useEffect(() => {
    if (currentPage > 1) {
      getData();
    }
  }, [currentPage]);
  const loadMore = () => {
    if (currentPage >= 0) {
      setcurrentPage((prev) => prev + 1);
    }
  };
  const [isGalleryActive, setIsGalleryActive] = useState(false);
  const [galleryIndex, setgalleryIndex] = useState();

  const setIsGalleryActiveFnc = (setIndexNumber) => {
    setgalleryIndex(setIndexNumber);
    setIsGalleryActive(true);
  };
  if (isLoading) {
    return null;
  }

  return (
    <div className="container-fluid my-3 lifePageView customContainerFluidPadding">
      <div className="mb-4">
        <div className="aboutBox py-4 mt-1 mb-4 ">
          <Title title={title} />
          <Row className="mt-2">
            {info &&
              info.map((item, index) => (
                <SingleImage
                  item={item}
                  key={index}
                  index={index}
                  setIsGalleryActiveFnc={setIsGalleryActiveFnc}
                />
              ))}
            <PhotoSlider
              images={info.map((item) => ({
                src: `${postImagePath}/${item.content_name}`,
                key: item.id,
              }))}
              visible={isGalleryActive}
              onClose={() => setIsGalleryActive(false)}
              index={galleryIndex}
              onIndexChange={setgalleryIndex}
            />
            {hasMore === true && <NextButtonRow loadMore={loadMore} />}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ViewPhotos;
