import icon_1  from "src/Projects/Food/assets/images/Placed order.png"
import icon_2  from "src/Projects/Food/assets/images/Order Confirmed.png"
import icon_3  from "src/Projects/Food/assets/images/Food Preparing.png"
import icon_4  from "src/Projects/Food/assets/images/On The Way.png"
import icon_5  from "src/Projects/Food/assets/images/delivery.png"

const order_steps = [
  {
    name: "Placed Order",
    count: 1,
    icon: icon_1,
    status: "place",
  },
  {
    name: "Confirmed",
    count: 2,
    icon: icon_2,
    status: "confirmed",
  },
  {
    name: "Food Preparing",
    count: 3,
    icon: icon_3,
    status: "preparing",
  },
  {
    name: "On The Way",
    count: 4,
    icon: icon_4,
    status: "on_way",
  },
  {
    name: "Delivered",
    count: 5,
    icon: icon_5,
    status: "delivered",
  },
];

export default order_steps