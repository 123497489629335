import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./foods.css";
import { Container, Form, InputGroup, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import FoodsCategorySliderItem from "./FoodsCategorySliderItem/FoodsCategorySliderItem";
import { NavLink } from "react-router-dom";
import FoodCard from "../../Components/FoodCard/FoodCard";
import useFoodStore from "../../hooks/useFoodStore";

const foods = ["All", "Beef Burgers", "Pizza", "Fast Food"];

const Foods = () => {
  const { products } = useFoodStore();
  return (
    <Container fluid style={{ minHeight: "65vh" }} className="mb-4">
      <div className="d-flex align-items-center justify-content-center my-4">
        <InputGroup className="food-search-box py-1 ">
          <Form.Control placeholder="Search Food" />
          <InputGroup.Text className="d-xs-hide d-md-inline">
            <FontAwesomeIcon icon={faSearch} className="text-gray" />
          </InputGroup.Text>
        </InputGroup>
        <button className="food-btn text-dark ms-2 banner-find-btn">
          <span className="d-none d-md-block">Search&nbsp;Food&nbsp;</span>

          <FontAwesomeIcon className="d-md-none" icon={faSearch} />
        </button>
      </div>

      <div>
        <Swiper
          slidesPerView={"auto"}
          centeredSlides={false}
          spaceBetween={15}
          autoplay={true}
          pagination={{
            clickable: true,
          }}
          modules={[Scrollbar, Autoplay]}
          className="mySwiper category-top-slider"
        >
          {[...new Array(15)].map((_, index) => (
            <SwiperSlide key={index}>
              <FoodsCategorySliderItem />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="my-4">
        <Swiper
          slidesPerView={"auto"}
          centeredSlides={false}
          spaceBetween={15}
          autoplay={true}
          pagination={{
            clickable: true,
          }}
          modules={[Scrollbar, Autoplay]}
          className="mySwiper category-top-slider"
        >
          {foods.map((item, index) => (
            <SwiperSlide key={index}>
              <NavLink
                to="/food/foods/all"
                className={`foods-nav-item mx-3 ${
                  index === 0 && "foods-nav-item-active"
                }`}
                activeClassName="foods-nav-item-active"
              >
                {item}
              </NavLink>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <Row className="home__Product-box g-2">
        {products.map((item) => (
          <FoodCard key={item.id} data={item} />
        ))}
      </Row>
    </Container>
  );
};

export default Foods;
