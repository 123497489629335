import { Container, Form } from "react-bootstrap";
import "./freelancerSort.css";

function FreelancerSort() {
  return (
    <Container>
      
      <div className="freelancerSort">
        <div className="freelancerSort__left">
          <h3 className="freelancerSort__title">Digital Marketing</h3>
          <p className="freelancerSort__sub">
            The printing and typesetting industry. Lorem Ipsum has
          </p>
        </div>
        <div className="freelancerSort__right">
          <Form.Select>
            <option value="">Service Type</option>
            <option value="">Service Type</option>
            <option value="">Service Type</option>
          </Form.Select>

          <Form.Select>
            <option value="">Seller Skills</option>
            <option value="">Seller Skills</option>
            <option value="">Seller Skills</option>
          </Form.Select>

          <Form.Select>
            <option value="">Seller Types</option>
            <option value="">Seller Types</option>
            <option value="">Seller Types</option>
          </Form.Select>

          <Form.Select>
            <option value="">Delivery Time</option>
            <option value="">Delivery Time</option>
            <option value="">Delivery Time</option>
          </Form.Select>
        </div>
      </div>
    </Container>
  );
}
export default FreelancerSort;
