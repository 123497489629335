import { Container, Row, Col } from "react-bootstrap";
import serviceBanner from "./../../assets/service_banner.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import "./freelanceService.css";

function FreelanceService() {
  return (
    <Container>
      <div className="freelanceService">
        <h2 className="FreelanceService__title">
          UVIOM &gt; Creative &gt; Hire me &gt; Service
        </h2>
        <Row className="gx-md-5">
          <Col lg={7}>
            <div>
              <h3 className="freelanceService__sub">
                <FontAwesomeIcon icon={faCheckCircle} /> The Best For Every
                Budget
              </h3>
              <p className="freelanceService__para">
                known printer took a galley of type and scrambled it to make a
                type specimen book. It has survived not only
              </p>
              <h3 className="freelanceService__sub">
                <FontAwesomeIcon icon={faCheckCircle} /> The Best For Every
                Budget
              </h3>
              <p className="freelanceService__para">
                known printer took a galley of type and scrambled it to make a
                type specimen book. It has survived not only
              </p>
              <h3 className="freelanceService__sub">
                <FontAwesomeIcon icon={faCheckCircle} /> The Best For Every
                Budget
              </h3>
              <p className="freelanceService__para">
                known printer took a galley of type and scrambled it to make a
                type specimen book. It has survived not only
              </p>
            </div>
          </Col>
          <Col lg={5}>
            <div className="freelanceService__img">
              <img className="img-fluid " src={serviceBanner} alt="" />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default FreelanceService;
