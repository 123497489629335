import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import useTheme from "src/hooks/useTheme";

import Avatar from "src/Projects/Life/Common/Avatar";
import "./profile.css";

const Profile = ({ handleClose = () => {}, boxShadow = false }) => {
  const { pathname } = useLocation();
  const currentPath = "/" + pathname?.split("/")[1];
  const { currentTheme } = useTheme();
  const profileStyle = {
    backgroundColor:
      window.innerWidth < 992 && currentTheme === "dark"
        ? "transparent"
        : "secondary-bg",
  };
  return (
    <div className={`p-3 food-my-profile rounded-2`} style={profileStyle}>
      <div className="profile-img-box d-flex justify-content-center mb-2">
        <Avatar imgUrl={"/user.png"} size="7rem"></Avatar>
      </div>
      <hr />
      <div>
        <NavLink
          to={`${currentPath}/my-profile/my-order`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
          onClick={handleClose}
        >
          My Order
        </NavLink>
        <NavLink
          to={`${currentPath}/my-profile/my-favorite`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
          onClick={handleClose}
        >
          My Favorite
        </NavLink>
        <NavLink
          to={`${currentPath}/my-profile/personal-info`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
          onClick={handleClose}
        >
          Personal Information
        </NavLink>
        <NavLink
          to={`${currentPath}/my-profile/manage-profile`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
          onClick={handleClose}
        >
          Manage Profile
        </NavLink>

        <NavLink
          to={`${currentPath}/my-profile/payment-history`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
          onClick={handleClose}
        >
          Payment History
        </NavLink>
        <NavLink
          to={`${currentPath}/my-profile/help-center`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
          onClick={handleClose}
        >
          Help Center
        </NavLink>

        {/* <NavLink
          to={`${currentPath}/seller-registration`}
         className={({isActive})=>`profile-nav-item ${isActive ? "profile-nav-item-active":""}`}
          onClick={handleClose}
        >
          Seller Registration
          
        </NavLink>
        <NavLink
          to={`${currentPath}/my-store`}
         className={({isActive})=>`profile-nav-item ${isActive ? "profile-nav-item-active":""}`}
          onClick={handleClose}
        >
          My Store
        </NavLink> */}
      </div>
    </div>
  );
};

export default Profile;
