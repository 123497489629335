import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import "./SmallVideoPlayer.css";

const SmallVideoCard = ({
  url,
  thumbnail = "",
  width = "100%",
  height = "100%",
  aspectRatio = "16/9",
}) => {
  return (
    <Wrapper
      style={{
        width: width,
        height: height,
        aspectRatio: aspectRatio,
      }}
    >
      <ReactPlayer
        width={"100%"}
        height={"100%"}
        url={url}
        style={{ zIndex: "-33" }}
      />
      {thumbnail ? (
        <div className={`smallVideoPlayerOverlay`}>
          <div className="videoPlayerThumbnail">
            <img src={thumbnail} alt="thumbnail" className=" img img-fluid" />
          </div>
          <div className="smallVideoCardIcon">
            <img
              style={{ width: "35px", height: "35px" }}
              src="/Life/icons/pause.png"
              className="border-0"
              alt="pause"
            />
          </div>
        </div>
      ) : (
        <div className="smallVideoCardIcon">
          <img
            style={{ width: "35px", height: "35px" }}
            src="/Life/icons/pause.png"
            className="border-0"
            alt="pause"
          />
        </div>
      )}
    </Wrapper>
  );
};

export default SmallVideoCard;

const Wrapper = styled.div`
  position: relative;
  z-index: 22;
`;
