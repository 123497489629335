import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  getGetImageInfo,
  getMetaDescription,
  getMetaImage,
  getMetaTitle,
} from "./link_preview_functions";

const LinkPreview = ({ url }) => {
  const [state, setState] = useState("");
  const [error, setError] = useState(null);
  const mounted = useRef(false);
  const { image, description, title, host, width } = state;

  useEffect(() => {
    mounted.current = true;
    try {
      const host = new URL(url).host;
      axios
        .get(`https://api.allorigins.win/get?url=${encodeURIComponent(url)}`)
        .then((res) => {
          const doc = res?.data.contents;
          var html = new DOMParser().parseFromString(doc, "text/html").head;
          const image = getMetaImage(html);
          const description = getMetaDescription(html);
          const title = getMetaTitle(html);
          const { width, height } = getGetImageInfo(html);
          if (mounted) {
            setState({ image, description, title, host, width, height });
          }
        })
        .catch((err) => {
          if (mounted) {
            setError(err.message);
          }
        });
    } catch (err) {
      setError(err);
    }

    return () => {
      mounted.current = false;
    };
  }, [url]);

  if (error || !description) {
    return null;
  }

  return (
    <div>
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <Wrapper>
          <div className={`card_container ${width && "container-flat"}`}>
            <div className="image_box">
              <img src={image} alt={title} />
            </div>
            <div className="text_box">
              <p className="link_header"> {title}</p>
              <p>{description}</p>
              <cite>{host}</cite>
            </div>
          </div>
          <FontAwesomeIcon
            className="link_icon_link"
            icon={faLink}
          ></FontAwesomeIcon>
        </Wrapper>
      </a>
    </div>
  );
};

export default LinkPreview;

const Wrapper = styled("div")`
  position: relative;
  .card_container {
    width: 100%;
    height: 160px;
    display: flex;
    gap: 10px;
    text-align: left;
    border: 2px solid #adadad2f;
    @media screen and (max-width: 500px) {
      flex-direction: column;
      height: unset;
    }
  }
  .container-flat {
    flex-direction: column;
    height: unset;

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }

    img {
      width: 100% !important;
      max-height: 400px;
    }
  }
  p.link_header {
    font-weight: 00;
    font-size: 1.1rem;
    padding-top: 5px;
    padding-left: 0px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    -webkit-line-clamp: 1;
    height: 27px;
  }

  p {
    display: inline;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    background-color: transparent;
    margin-bottom: 10px;
    max-height: fit-content;
    color: var(--text-primary);
    text-decoration: none;
    margin-top: 0;
    font-weight: 400 !important;

    &:hover {
      text-decoration: underline;
      /* color: #8ab4f8; */
    }
  }

  .image_box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image_box img {
    width: 200px;
    height: 100%;
  }
  .text_box {
    flex: 1;
    position: relative;
    padding: 10px;
    padding-bottom: 30px;
  }

  cite {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #639efd;
    font-weight: 600;
  }

  .link_icon_link {
    position: absolute;
    top: 2px;
    left: 2px;
    padding: 10px;
    border-radius: 50%;
    background-color: var(--nav-bg-opacity);
    &:hover {
      color: #639efd;
    }
  }
`;
