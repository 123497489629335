import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faGraduationCap,
  faHeart,
  faHome,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";

export const Overview = ({ info, getData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setformData] = useState({
    users_info: "",
    education_info: "",
    work_info: "",
    relationship: "",
  });
  useEffect(() => {
    if (!isEmpty(info)) {
      setformData({
        users_info: info && info.users_info ? info.users_info : "",
        education_info: info && info.education_info ? info.education_info : "",
        work_info: info && info.work_info ? info.work_info : "",
        relationship: info && info.relationship ? info.relationship : "",
      });
    }
  }, [info]);
  const address =
    formData && formData.users_info && formData.users_info.address
      ? JSON.parse(formData.users_info.address)
      : "";
  const relationship =
    formData && formData.relationship && formData.relationship.relationship
      ? JSON.parse(formData.relationship.relationship)
      : "";

  // console.log(address)
  return (
    <div className="mb-4">
      <Col xs={12} sm={12} md={12} lg={12} className="aboutBox mt-1 mb-4 ">
        <div className="d-flex justify-content-between border-bottom pb-2 border-white">
          <div>
            <h6>
              {formData.users_info.first_name} {formData.users_info.last_name}{" "}
              {isEmpty(info) && " Full Name"}
            </h6>
            <div className="smallTextSize">
              {isEmpty(info) && " Position"} @{formData.work_info.position}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="d-flex justify-content-between my-4">
            <div className="d-flex">
              <div className="me-3">
                <FontAwesomeIcon icon={faBriefcase} />
              </div>
              <div>
                Works at {formData.work_info.work} ( {isEmpty(info) && "?"}{" "}
                {formData.work_info.position})
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between my-4">
            <div className="d-flex">
              <div className="me-3">
                <FontAwesomeIcon icon={faGraduationCap} />
              </div>
              <div>
                Studies at {formData.education_info.institute} ,
                {formData.education_info.location} {isEmpty(info) && "?"}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between my-4">
            <div className="d-flex">
              <div className="me-3">
                <FontAwesomeIcon icon={faHome} />
              </div>
              <div>
                Lives in {address.current_city},{address.hometown}{" "}
                {isEmpty(info) && "?"}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between my-4">
            <div className="d-flex">
              <div className="me-3">
                <FontAwesomeIcon icon={faHeart} />
              </div>
              <div>
                {relationship.relationship} {isEmpty(info) && "?"}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between my-4">
            <div className="d-flex">
              <div className="me-3">
                <FontAwesomeIcon icon={faPhoneAlt} />
              </div>
              <div>
                {formData.users_info.phone} {isEmpty(info) && "?"}
              </div>
            </div>
          </div>
        </div>
      </Col>
    </div>
  );
};
