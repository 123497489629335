import React from 'react';

const DabbleCheckMark = ({className=""}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13" viewBox="0 0 19 13" fill="none">
        <path className={className} d="M1 5.95996L2.85239 9.47529L14.1626 3.51543" stroke="#F1A350"/>
        <path className={className} d="M5.61621 9.62695L6.74375 11.7667L18.0539 5.80686" stroke="#F1A350"/>
        </svg>
    );
};

export default DabbleCheckMark;