import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall_withToken from "src/app/helper/apiCall";
import { processDistricts, setData_to_sessionStorage, sortA_Z } from "src/Projects/Job/utilities/functions";
import {getAllDistricts as allDistrictUrl, getAllDivision as allDivisionUrl} from "src/Projects/Job/utilities/job_commonUrl"

const initialState = {
    districts: [],
    divisions: [],
    isLoading: true,
    error:""
}


export const getAllDistricts = createAsyncThunk("job/getAllDistrict", (_, thunkAPI) => apiCall_withToken(thunkAPI, allDistrictUrl))

export const getAllDivision = createAsyncThunk("job/getAllDivision", (_, thunkAPI) => apiCall_withToken(thunkAPI, allDivisionUrl))



const jobLocalDataSlice = createSlice({
    name: "job_localData",
    initialState,
    extraReducers: {
        [getAllDistricts.pending]: (state) => { state.isLoading = true },
        [getAllDistricts.fulfilled]: (state, action) => {
            state.isLoading = false;
            const sorted_districts = action.payload.sort(sortA_Z)
            state.districts = sorted_districts;
            setData_to_sessionStorage(processDistricts(sorted_districts), "districts");
        },
        [getAllDistricts.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

    //    get all division 
        [getAllDivision.pending]: (state) => { state.isLoading = true },
        [getAllDivision.fulfilled]: (state, action) => {
            state.isLoading = false;
            const sorted_divisions = action.payload.sort(sortA_Z)
            state.divisions = sorted_divisions;
            setData_to_sessionStorage(processDistricts(sorted_divisions), "divisions");
        },
        [getAllDivision.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        }
        
    }
})


export default jobLocalDataSlice.reducer;