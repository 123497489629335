import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Form, Col, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import AddPackageDetails from "../Common/AddPackageDetails";

import "./addWorkPackage.css";

function AddWorkPackage() {
  const [totalPackage, setTotalPackage] = useState(1);
  const { pathname } = useLocation();
  const dynamicPath = pathname.split("/")[1];
  const dynamicUser = pathname.split("/")[2];

  return (
    <div>
      <Form.Control type="text" className="input-reset" placeholder="Service Title" />
      <Row className="mt-3">
        <Col md={6}>
          <img
            className="rounded img-fluid"
            src="https://i.ibb.co/nMpBH8z/stock1.jpg"
            alt=""
          />
        </Col>
        <Col md={6} className="mt-4 mt-md-0">
          <div
            role="button"
            className="d-flex h-100 border border-dashed rounded align-items-center justify-content-center p-3 p-sm-5"
          >
            <div className="text-center">
              <img src="https://i.ibb.co/9qqNsFX/upload-icon.png" alt="" />
              <p className="mt-2 mb-0">Upload Image</p>
            </div>
          </div>
        </Col>
      </Row>
      <Form.Control
        className="shadow-0 mt-3 input-reset"
        rows={10}
        as="textarea"
        placeholder="Add Service Details"
      />
      <Form.Control
        className="shadow-0 mt-3 input-reset"
        rows={10}
        as="textarea"
        placeholder="Work Package"
      />

      {Array.from({ length: totalPackage }).map((_, index) => (
        <AddPackageDetails key={index} />
      ))}

      <div className="mt-3 d-flex flex-wrap gap-3">
        <Button
          onClick={() => setTotalPackage((prev) => (prev += 1))}
          className=" d-flex align-items-center justify-content-center flex-grow-1"
          variant="outline-secondary"
        >
          Add New Job Package
          <FontAwesomeIcon className="ms-2" icon={faPlusSquare} />
        </Button>
        <Button className="flex-grow-1" variant="outline-secondary">
          Edit Info
        </Button>
        <Button className="flex-grow-1" variant="outline-secondary">
          Save Info
        </Button>
        <Button
          as={Link}
          to={`/${dynamicPath}/${dynamicUser}/workpackage/package`}
          className="btn__creative"
        >
          Publish
        </Button>
      </div>
    </div>
  );
}
export default AddWorkPackage;
