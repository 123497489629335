import React from "react";
import { Col, Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { CutomTooltip } from "src/Common/CutomTooltip";
import styled from "styled-components";
import { SingleLinkBar } from "./Components/SingleLinkBar";

export const MiddleLeftCol = () => {
  let { url } = useLocation();
  // note::: [routeNames,title]
  const routeNames = [
    ["pending-friends", "Pending Friend"],
    ["find-friends", "Find Friends"],
    ["requested-friends", "Requested Friends"],
  ];

  let { pathname } = useLocation();
  const [, dynamicPath, dynamicLife] = pathname.split("/");

  return (
    <>
      <FriendsTab>
        <Link to={`/${dynamicPath}/${dynamicLife}/friends`}>
          <div className="LookMeTypeButton  box-shadow justify-content-start">
            <h6 className="mb-0 ps-4 "> All Friends </h6>
          </div>
        </Link>
        {routeNames.map((route, index) => (
          <SingleLinkBar
            key={`i+${index}`}
            placeholder={route[1]}
            link={`/${dynamicPath}/${dynamicLife}/friends/${route[0]}`}
          />
        ))}
        <CutomTooltip text="">
          <div>
            <div className="LookMeTypeButton box-shadow justify-content-start my-mt">
              <h6 className="mb-0 ps-4">Block Friends</h6>
            </div>
          </div>
        </CutomTooltip>
      </FriendsTab>
      {/* </Col> */}
      <div className="col-12 d-block d-sm-none">
        <Dropdown>
          <Dropdown.Toggle aria-label="all Friends" className="aboutDropdown">
            All Friends
          </Dropdown.Toggle>
          <Dropdown.Menu className=" aboutDropdownBody">
            <Dropdown.Item
              className="jobDropdownItem"
              as={Link}
              to={`/${dynamicPath}/${dynamicLife}/friends`}
            >
              All Friends
            </Dropdown.Item>
            {routeNames.map((route, index) => (
              <Dropdown.Item
                className="jobDropdownItem"
                as={Link}
                to={`/${dynamicPath}/${dynamicLife}/friends/${route[0]}`}
                key={index}
              >
                {route[1]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

const FriendsTab = styled.div`
  display: flex;
  gap: 1rem;
  padding-block: 0.5rem;
  padding-inline: 0.2rem;
  white-space: nowrap;

  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 36em) {
    display: none;
  }

  & .LookMeTypeButton {
    margin: 0 !important;

    & .ps-4 {
      padding-inline: 0.7rem !important;
    }
  }
`;
