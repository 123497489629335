import React from "react";
import { Row } from "react-bootstrap";
import FoodCard from "src/Projects/Food/Components/FoodCard/FoodCard";
import RestaurantCard from "src/Projects/Food/Components/RestaurantCard/RestaurantCard";
import useFoodStore from "src/Projects/Food/hooks/useFoodStore";
const img1 = "/food-image/restaurant1.png";
const img2 = "/food-image/restaurant2.png";
const SearchResultAll = () => {
  const { products } = useFoodStore();
  return (
    <div>
      <Row className="home__Product-box g-2 mb-4">
        {products.map((item) => (
          <FoodCard key={item.id} data={item} />
        ))}
      </Row>
      <Row className="home__Product-box g-2">
        <RestaurantCard img={img1} />
        <RestaurantCard img={img2} />
        <RestaurantCard img={img1} />
        <RestaurantCard img={img2} />
        <RestaurantCard img={img1} />
        <RestaurantCard img={img2} />
      </Row>
    </div>
  );
};

export default SearchResultAll;
