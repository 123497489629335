import { mainProfilePic } from "src/Projects/Life/Common/CommonUrl";
import styled from "styled-components";
import Actions from "./Actions";

function SinglePerson({ userId, userName, userImage, person }) {
  return (
    <SinglePersonWrapper>
      <img
        src={mainProfilePic + "/" + userImage}
        onError={(e) => (e.target.src = "https://i.ibb.co/MprdZS6/user.png")}
        alt={userName}
      />
      <div>
        <p>{userName}</p>
      </div>
      <div className="btns">
        <Actions userId={userId} person={person} />
      </div>
    </SinglePersonWrapper>
  );
}

export default SinglePerson;

const SinglePersonWrapper = styled.div`
  border-radius: 0.2rem;
  padding: 0.5rem;
  background-color: var(--bg-secondary);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1.5rem;
  box-shadow: var(--box-shadow);

  /* 1320px */
  @media (max-width: 82.5em) {
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 0.7rem;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  & p {
    font-size: 0.9rem;
    font-weight: 500;
    margin: 0;
    line-height: 1;
  }

  & small {
    font-size: 0.8rem;
  }

  & > img {
    height: 3.5rem;
    border-radius: 50%;
  }

  & > .btns {
    margin-left: auto;
    display: flex;

    & > .request {
      border-radius: 0.2rem;
      font-size: 0.8rem;
      background-color: var(--bg-success);
      color: var(--bg-primary);
    }

    & button {
      border: 0;
    }
  }
`;
