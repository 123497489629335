import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import {
  faEnvelope,
  faKey,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { hasFormSubmit } from "@testing-library/user-event/dist/utils";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import useAuth from "../../../../../hooks/useAuth";
const LoginPart = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [myLoginData, setLogInData] = useState({});
  // const [user, setUser] = useState({});
  // const [profile, setProfile] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [EmailerrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage8, setPasswordErrorMessage8] = useState("");
  const [formSubmit, setformSubmit] = useState(false);
  const { userLogin, isLoading } = useAuth();
  const emailSyntax = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
    myLoginData.email
  );
  let passwordFildType;
  if (showPassword) {
    passwordFildType = "text";
  } else {
    passwordFildType = "password";
  }

  const handelBlure = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...myLoginData };
    newData[name] = value;
    setLogInData(newData);
  };
  useEffect(() => {
    if ((myLoginData.email && myLoginData.email) || emailSyntax) {
      setEmailErrorMessage("");
    }
    if (myLoginData.password && myLoginData.password.length > 5) {
      setPasswordErrorMessage8("");
    }
  }, [myLoginData.email, myLoginData.password]);

  const handelSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (!myLoginData.email || !emailSyntax) {
      setEmailErrorMessage("Enter a valid Email.");
    } else {
      setEmailErrorMessage("");
    }

    if (!myLoginData.password || myLoginData.password.length < 6) {
      setPasswordErrorMessage8("Password must be 6 charecter");
    } else {
      setPasswordErrorMessage8("");
    }
    // console.log(myLoginData);
    setformSubmit(true);
  };

  useEffect(() => {
    if (formSubmit === true) {
      if (EmailerrorMessage === "" && passwordErrorMessage8 === "") {
        userLogin(myLoginData, setformSubmit);
        // console.log("form can be submit")
      }
      setformSubmit(false);
    }
  }, [formSubmit]);

  return (
    <div className="mt-4 mt-lg-5">
      <form>
        <div className="InputFildDiv ">
          <label>
            <FontAwesomeIcon className="inputIcon" icon={faEnvelope} />
          </label>
          <input
            onChange={handelBlure}
            type="email"
            name="email"
            placeholder=" Enter your Email*"
            required
          />
        </div>
        <div className="errorMessaage ">
          <small>{EmailerrorMessage}</small>
        </div>
        <div className="InputFildDiv ">
          <label>
            <FontAwesomeIcon className="inputIcon" icon={faKey} />
          </label>
          <input
            onChange={handelBlure}
            name="password"
            type={passwordFildType}
            placeholder="Enter Your Password*"
            required
          />
          <label>
            <span
              tabIndex="0"
              onClick={() => setShowPassword(!showPassword)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setShowPassword(!showPassword);
                }
              }}
              as={Button}
              className="ShowPassword"
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </span>
          </label>
        </div>
        <div className="errorMessaage ">
          <small>{passwordErrorMessage8}</small>
        </div>
        <div className="errorMessaage ">
          <small>{errorMessage}</small>
        </div>

        <div className="w-75 mx-auto text-end">
          <Link to="/forget-p">
            {" "}
            <small className="ForgatePassword">Forget Password?</small>
          </Link>
        </div>
        <div className="d-flex my-3 justify-content-center">
          <button
            className="button"
            aria-label="Sign In"
            onClick={handelSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handelSubmit();
              }
            }}
          >
            {isLoading && (
              <FontAwesomeIcon className="me-2" icon={faSpinner} spin />
            )}
            Sign In
          </button>
        </div>
        {/* <div className="text-center ForgatePassword">
          ---- <span>Sign up with this link</span> ----
            </div> */}
      </form>
      {/*
      <div>
        <span className="OtherLoginLinkParent my-3 d-flex">
          <a href="https://www.facebook.com/uviominc/">
            <span className="OtherLoginLink">
              <FontAwesomeIcon icon={faFacebookF} />
            </span>
          </a>
          <a href="/">
            <span className="OtherLoginLink">
              <FontAwesomeIcon icon={faInstagram} />
            </span>
          </a>
          <a href="/">
            <span className="OtherLoginLink">
              <FontAwesomeIcon icon={faGoogle} />
            </span>
          </a>
          <a href="/">
            <span className="OtherLoginLink">
              <FontAwesomeIcon icon={faYahoo} />
            </span>
          </a>
        </span>
      </div>
      */}

      <p className="DontHaveAcc mt-4">
        Dont have account? <Link to="/signup">Sign Up</Link>
      </p>
    </div>
  );
};

export default LoginPart;
