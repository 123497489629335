import React from "react";

import { useNavigate, useLocation } from "react-router-dom";

import "./BrandCard.css";
import { brand_logoBase } from "../../utilities/CommonUrl";
import SingleCategory from "./SingleCategory";

export const BrandCard = ({ extraClass, item }) => {
  const { brand_name } = item;
  const navigate = useNavigate();
  // const { currentTheme } = useTheme();

  const { pathname } = useLocation();
  const path = "/" + pathname.split("/")[1];
  const brandDetailsHandle = () => {
    // console.log("item",item)
    navigate({
      pathname: `${path}/brand-details/${item.slug}`,
      state: {
        stateData: item,
      },
    });
  };
  return (
    // <Col className="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2 ">
    //   <Card
    //     onClick={handleJobClick}
    //     className={`product-card h-100 ${extraClass}  ${
    //       currentTheme === "dark" ? "secondary-bg" : ""
    //     }`}
    //   >
    //     <Card.Img as={"div"} className="product__card__img-box bg-white">
    //       <span className="w-100 h-100 d-flex justify-content-center align-items-center">
    //         <img
    //           src="/corporate/cap.png"
    //           alt="product card"
    //           className="img-fluid"
    //         />
    //       </span>
    //     </Card.Img>
    //     <Card.Footer className="border-0">
    //       <div className="text-center">
    //         <p className="mb-0">name</p>
    //       </div>
    //     </Card.Footer>
    //   </Card>
    // </Col>
    <div
      className="category__slider__item-container"
      onClick={brandDetailsHandle}
    >
      <div className=" mx-auto">
        <SingleCategory
          img={`${brand_logoBase}/${item.brand_logo}`}
          brand_name={brand_name}
        ></SingleCategory>
      </div>
    </div>
  );
};
