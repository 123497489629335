import React from 'react';
import SavePostItem from './SavePostItem';
import "./SavePost.css"

const SavePost = () => {
    return (
        <div className='h-100 bg-gray p-2'>


<h6 className="py-3 ps-3">Save Post</h6>
         <SavePostItem/>
         <SavePostItem/>
         <SavePostItem/>
         <SavePostItem/>
         <SavePostItem/>
        </div>
    );
};

export default SavePost;