import React from "react";
import { useEffect } from "react";

export const SalePrice = ({ stock, outOfStock, setoutOfStock, sale_price }) => {
  useEffect(() => {
    if (Number(stock) <= 0) {
      setoutOfStock("Out of Stock");
    } else {
      setoutOfStock("");
    }
  }, []);
  return (
    <>
      {outOfStock ? (
        <div className="smallTextSize text-danger text-center">
          {outOfStock}
        </div>
      ) : (
        <>
          {Math.round(sale_price)}
          <small>৳</small>
        </>
      )}
    </>
  );
};
