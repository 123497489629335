import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getInfo } from "src/Common/apiCall/GetApiCalls";
import { userOverviewInfoUrl } from "src/Projects/Life/Common/CommonUrl";
import { MiddleLeftCol } from "./MiddleLeftCol";
import { MiddleRightCol } from "./MiddleRightCol";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";
import "./About.css";

export const About = () => {
  let { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [info, setinfo] = useState();
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    getInfo(setIsLoading, userOverviewInfoUrl, setinfo);
  };
  return (
    <div>
      {/* <Row className="gy-4 mb-5 1"> */}
      <div className="gy-4 mb-5">
        <MiddleLeftCol />
        {isLoading ? (
          <DottedCircleSpinner />
        ) : (
          <>
            <div
              className={`${
                pathname.includes("/public-life") ? " col-12 " : "col-lg-12"
              }`}
            >
              <MiddleRightCol info={info} getData={getData} />
            </div>
          </>
        )}
      </div>
      {/* </Row> */}
    </div>
  );
};
