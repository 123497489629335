import axios from "axios";
import React from "react";
import { fmSentRequestUrl, fnSentRequestUrl, pSentRequestUrl } from "../../Common/CommonUrl";
import { ConfirmModal } from "src/Projects/Life/Common/ConfirmModal";
import Swal from "sweetalert2";
import ToastFunc from "src/Common/ToastFunc";

const FriendRequestModal = ({ show, setShow, userId, location, dataReload }) => {
 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userId) {
      let tempUrl;
      let userIdField;

      if (location.pathname.includes("/family-life")) {
        tempUrl = fmSentRequestUrl;
        userIdField = "family_id";
      } else if (location.pathname.includes("/friends-life")) {
        tempUrl = fnSentRequestUrl;
        userIdField = "friend_id";

      } else if (location.pathname.includes("/professional-life")) {
        tempUrl = pSentRequestUrl;
        userIdField = "professional_id";

      }
      const formData = {
        [userIdField]: userId
      };

      axios
        .post(`${tempUrl}`, formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 1) {
            // swal({
            //   title: res.data.message,
            //   icon: "success",
            //   button: "Ok",
            // }).then(
            //   dataReload()
            // );
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })
            Toast.fire({
              icon: "success",
              title: res.data.message
            }).then(
              dataReload()
            );
          } else { }

        })
        .catch((err) => {
          ToastFunc('error', 'Request Failed')

        });

    } else {
      return ToastFunc('error', 'Cant Send Empty Request')

    }
    setShow(false)
  };
  const title = "Request Life";
  const modalArg = {
    "show": show,
    "setShow": setShow,
    "title": title,
    "handleSubmit": handleSubmit,

  }
  return (
    <div>
      <ConfirmModal modalArg={modalArg} />
    </div>
  );
};

export default FriendRequestModal;
