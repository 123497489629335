import React from 'react';

const CancelAndSaveBtn = ({handleCancelBtn,handleSave}) => {
    return (
        <div className="py-3 mainProfileBtnDiv">
          <button
          type="button"
          onClick={handleCancelBtn}
          className="mainProfileBtn btnCandel me-3"
        >
          Cancel
        </button>
        <button type="button" className="mainProfileBtn btnSave" onClick={handleSave}>save</button>
      </div>
      )
};

export default CancelAndSaveBtn;