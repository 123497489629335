import { prepareApiResponse } from "src/app/helper/rtkFunctions";
import {
  allJobsPostUrl,
  CACHE_EXPIRE_TIME,
  getAllCompanyUrl,
  getApplicantCount,
  getApplicantEducationUrl,
  getAppliedJobUrl,
  getCompanyCount,
  getCompanyPost,
  getCompanySocialLinkUrl,
  getJobCount,
  getLastWeekJobCount,
  getSavedJobURl,
  postedJobUrl,
} from "src/Projects/Job/utilities/job_commonUrl";
import { api } from "../api";

export const jobGetApi = api.injectEndpoints({
  endpoints: (builder) => ({


    getHomeJobPost: builder.query({
      query: (page = 1) => `${allJobsPostUrl}?page=${page}`,
      keepUnusedDataFor: CACHE_EXPIRE_TIME,
      transformResponse: prepareApiResponse,
      providesTags: (_result, _error, page ) => [{type:"AllJobPost", page }]
    }),


    getHomeCompanies: builder.query({
      query: (page = 1) => `${getAllCompanyUrl}?page=${page}`,
      keepUnusedDataFor: CACHE_EXPIRE_TIME,
      transformResponse: prepareApiResponse, // this is middleware function
    }),


    getSaveJobPost: builder.query({
      query: (page = 1) => `${getSavedJobURl}?page=${page}`,
      keepUnusedDataFor: CACHE_EXPIRE_TIME,
      providesTags: ["SaveJobPost"],
      transformResponse: prepareApiResponse,
    }),


    getAppliedJobPost: builder.query({
      query: (page = 1) => `${getAppliedJobUrl}?page=${page}`,
      keepUnusedDataFor: CACHE_EXPIRE_TIME,
      providesTags: ["AppliedJobPost"],
      transformResponse: prepareApiResponse,
    }),


    getCompanyJobPost: builder.query({
      query: ({page = 1, company_id}) => `${getCompanyPost + company_id}?page=${page}`,
      keepUnusedDataFor: CACHE_EXPIRE_TIME,
      providesTags: ["CompanyJobPost"],
      transformResponse: prepareApiResponse,
    }),


    getCategoryJobPost: builder.query({
      query: ({page = 1, url}) => `${url}?page=${page}`,
      keepUnusedDataFor: CACHE_EXPIRE_TIME,
      providesTags: ["CategoryJobPost"],
      transformResponse: prepareApiResponse,
    }),


    getDivisionJobPost: builder.query({
      query: ({page = 1, url}) => `${url}?page=${page}`,
      keepUnusedDataFor: CACHE_EXPIRE_TIME,
      providesTags: ["DivisionJobPost"],
      transformResponse: prepareApiResponse,
    }),


    getApplicantEducation: builder.query({
      query: () => getApplicantEducationUrl,
      keepUnusedDataFor: CACHE_EXPIRE_TIME,
      providesTags: ["ApplicantEducation"],
    }),


    getCompanySocialLink: builder.query({
      query: () => getCompanySocialLinkUrl,
      keepUnusedDataFor: CACHE_EXPIRE_TIME,
      providesTags: ["CompanySocialLink"],
    }),


    getPostedJob: builder.query({
      query: (page = 1) => `${postedJobUrl}?page=${page}`,
      keepUnusedDataFor: CACHE_EXPIRE_TIME,
      transformResponse: prepareApiResponse,
      providesTags:(_result, _error, page ) => [{type:"CompanyPostedJob", page }]
    }),


    getLiveJob: builder.query({
      query: () => `${getJobCount}`,
      keepUnusedDataFor: CACHE_EXPIRE_TIME,
    }),


    getCompaniesCount: builder.query({
      query: () => `${getCompanyCount}`,
      keepUnusedDataFor: CACHE_EXPIRE_TIME,
    }),


    getApplicantCount: builder.query({
      query: () => `${getApplicantCount}`,
      keepUnusedDataFor: CACHE_EXPIRE_TIME,
    }),


    getNewJobCount: builder.query({
      query: () => `${getLastWeekJobCount}`,
      keepUnusedDataFor: CACHE_EXPIRE_TIME,
    }),



  }),
});

export const {
  useGetHomeJobPostQuery,
  useGetHomeCompaniesQuery,
  useGetSaveJobPostQuery,
  useGetAppliedJobPostQuery,
  useGetCompanyJobPostQuery,
  useGetCategoryJobPostQuery,
  useGetDivisionJobPostQuery,
  useGetApplicantEducationQuery,
  useGetCompanySocialLinkQuery,
  useGetPostedJobQuery,
  useGetLiveJobQuery,
  useGetCompaniesCountQuery,
  useGetApplicantCountQuery,
  useGetNewJobCountQuery,
} = jobGetApi;
