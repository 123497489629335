import { Modal, Button, Form } from "react-bootstrap";

function CancelOrderModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="creative__modal__header" closeButton>
        <h6 className="mb-0">Cancel Order</h6>
      </Modal.Header>
      <Modal.Body className="creative__modal__body">
        <h6 className="mb-1">Order Title:</h6>
        <p>The standard chunk of Lorem Ipsum used since the 1500s is </p>
        <h6 className="mb-1">Order Details:</h6>
        <small>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur
          vel ac urna, dolor. Amet et euismod eget quis eu. Volutpat sociis
          aenean aenean vitae sed consectetur turpis. Vestibulum parturient
          molestie scelerisque nisi, amet, pulvinar. Cras senectus eget quam
          blandit morbi sed. Molestie urna, enim, mauris platea viverra velit
          rhoncus, eros consectetur.
        </small>

        <h6 className="mt-3">Cancelling Reason:</h6>

        <Form.Control as="textarea" rows={5} className="shadow-0 input-reset" />

        <div className="py-3 text-center">
          <Button className="btn__creative" onClick={props.onHide}>
            Confirm
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default CancelOrderModal;
