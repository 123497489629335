import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { getInfo } from "src/Common/apiCall/GetApiCalls";
import { getUserMainProfileInfoUrl } from "src/Projects/Life/Common/CommonUrl";
import UviomMainProfileBasicInfo from "../UviomMainProfileRoutes/UviomMainProfileBasicInfo";
import UviomMainProfileMailingAddress from "../UviomMainProfileRoutes/UviomMainProfileMailingAddress";
import UviomMainProfileShipingAddress from "../UviomMainProfileRoutes/UviomMainProfileShipingAddress";
import UviomMainProfileVerifiedDocs from "../UviomMainProfileRoutes/UviomMainProfileVerifiedDocs";

import { BubbleScale } from "src/Common/BubbleScale";

const UviomMainProfileMiddleCol = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [info, setinfo] = useState();
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    getInfo(setIsLoading, getUserMainProfileInfoUrl, setinfo);
  };
  return (
    <div>
      <div>
        <h6 className="mainProfkeMiddleColTitle nav-bg">
          Edit & View Personal Information
        </h6>
      </div>

      {isLoading ? (
        <BubbleScale extraStyle={{ marginTop: "5rem" }} />
      ) : (
        <>
          <div className="d-none d-lg-block">
            <Routes>
              <Route
                path="/"
                element={
                  <UviomMainProfileBasicInfo info={info} getData={getData} />
                }
              />
              <Route
                path="/basic-info"
                element={
                  <UviomMainProfileBasicInfo info={info} getData={getData} />
                }
              />
              <Route
                path="/mailing-address"
                element={
                  <UviomMainProfileMailingAddress
                    info={info}
                    getData={getData}
                  />
                }
              />
              <Route
                path="/shiping-address"
                element={
                  <UviomMainProfileShipingAddress
                    info={info}
                    getData={getData}
                  />
                }
              />
              <Route
                path="/verified-document"
                element={
                  <UviomMainProfileVerifiedDocs info={info} getData={getData} />
                }
              />
            </Routes>
          </div>
          <div className="d-block d-lg-none">
            <UviomMainProfileBasicInfo info={info} getData={getData} />
            <UviomMainProfileMailingAddress info={info} getData={getData} />
            <UviomMainProfileShipingAddress info={info} getData={getData} />
            <UviomMainProfileVerifiedDocs info={info} getData={getData} />
          </div>
        </>
      )}
    </div>
  );
};

export default UviomMainProfileMiddleCol;
