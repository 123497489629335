/* eslint-disable eqeqeq */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  banner: [],
  vendor_slider: [],
  home_store:[],
  brand_slider: [],
  category_product: [],
  home_product: [],
};

const ubizzDataSlice = createSlice({
  name: "ubizz_data",
  initialState,

  reducers: {
    storeData: (state, action) => {
      const { data, field_name } = action.payload;
      state[field_name] = data;
    },
  },
});

export default ubizzDataSlice.reducer;

export const { storeData } = ubizzDataSlice.actions;
