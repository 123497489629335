import React from "react";
import { Col, Placeholder } from "react-bootstrap";
import "./placeholder.css";
const JobSkeleton = () => {
  return (
    <Col>
      <div className="job-skeleton">
        <div className="job-skeleton-content">
          <Placeholder
            as="div"
            className="job-skeleton-content-avatar"
            animation="none"
          >
            <div className="placeholder-square bg-secondary">
              <span className="p-0 w-100 h-100 d-flex justify-content-center align-items-center ">
                <img
                  src="/corporate/logo-outline.png"
                  alt=""
                  className="img img-fluid"
                />
              </span>
            </div>
          </Placeholder>
          <Placeholder
            as="div"
            className="text-start job-skeleton-content-text"
            animation="wave"
          >
            <Placeholder bg="secondary" size="md" xs={10} />{" "}
            <Placeholder bg="secondary" xs={4} />{" "}
            <Placeholder bg="secondary" xs={4} />{" "}
            <Placeholder xs={6} bg="secondary" />{" "}
            <Placeholder xs={5} bg="secondary" />
          </Placeholder>
        </div>
      </div>
    </Col>
  );
};

export default JobSkeleton;
