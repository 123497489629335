import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Navigate,
  Route,
  Routes as Switch,
  useLocation,
} from "react-router-dom";
import CanceledOrder from "./CanceledOrder/CanceledOrder";
import ManageOrder from "./ManageOrder/ManageOrder";
import ManageProfile from "./ManageProfile/ManageProfile";
import OrderCancellation from "./OrderCancellation/OrderCancellation";
import { MyOrderInvoice } from "./MyOrderInvoice/MyOrderInvoice";
import OrderHistory from "./OrderHistory/OrderHistory";
import PaymentHistory from "./PaymentHistory/PaymentHistory";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import Profile from "./Profile/Profile";
import ReceivedOrder from "./ReceivedOrder/ReceivedOrder";
import { PendingOrder } from "./Order/PendingOrder/PendingOrder";
import { ConfirmOrder } from "./Order/ConfirmOrder/ConfirmOrder";
import { ShowCanceledOrder } from "./Order/ShowCanceledOrder/ShowCanceledOrder";
import { UviomPending } from "./Order/UviomPending/UviomPending";
import { UviomApproved } from "./Order/UviomApproved/UviomApproved";
import { UviomDelivered } from "./Order/UviomDelivered/UviomDelivered";
import { VendorReceived } from "./Order/VendorReceived/VendorReceived";
import { CustomerReceived } from "./Order/CustomerReceived/CustomerReceived";

const MyProfile = () => {
  return (
    <Container fluid style={{ minHeight: "75vh" }}>
      <h6 className="fs-6 py-3">My Profile</h6>
      <Row>
        <Col xs="12" md="4" xl="3" className="d-none d-md-block h-100">
          <Profile boxShadow />
        </Col>
        <Col xs="12" md="8" xl="9" className="h-100">
          <Switch>
            <Route index element={<Navigate to="personal-info" />} />

            <Route path={`/personal-info`} element={<PersonalInformation />} />

            <Route path={`/manage-profile`} element={<ManageProfile />} />

            <Route path={`/my-order`} element={<OrderHistory />} />

            <Route path={`/pending-order`} element={<PendingOrder />} />

            <Route path={`/confirm-order`} element={<ConfirmOrder />} />

            <Route path={`/canceled-order`} element={<ShowCanceledOrder />} />

            {/* //------- */}

            <Route path={`/uviom-pending`} element={<UviomPending />} />

            <Route path={`/uviom-approved`} element={<UviomApproved />} />

            <Route path={`/uviom-received`} element={<ConfirmOrder />} />

            <Route path={`/uviom-delivered`} element={<UviomDelivered />} />

            <Route path={`/vendor-received`} element={<VendorReceived />} />

            <Route path={`/customer-received`} element={<CustomerReceived />} />

            <Route path={`/payment-history`} element={<PaymentHistory />} />

            <Route
              path={`/manage-order/:productId`}
              element={<ManageOrder />}
            />

            <Route path={`/invoice/:productId`} element={<MyOrderInvoice />} />

            <Route path={`/received-order`} element={<ReceivedOrder />} />

            <Route path={`/canceled-order`} element={<CanceledOrder />} />

            <Route
              path={`/order-cancellation/:productId`}
              element={<OrderCancellation />}
            />
          </Switch>
        </Col>
      </Row>
      <br />
    </Container>
  );
};

export default MyProfile;
