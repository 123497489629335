import React from "react";
import { Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ShopBannerImgBase,
  ShopLogoBaseM,
} from "src/Projects/Corporate/utilities/CommonUrl";
import "./storeItem.css";

const StoreItem = (props) => {
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const mainUrl = `/${pathname?.split("/")[1]}`;

  const handleClick = () => {
    // if(location.pathname === "/ubizz" || "/ubizz/"){
    //   navigate({
    //     pathname: `${mainUrl}/shop/store-view/${slug}`
    //   });
    // }else{
    //   navigate({
    //     pathname: `${path}/store-view/${slug}`
    //   });
    // }

    navigate({
      pathname: `${mainUrl}/shop/store-view/${slug}`,
    });
  };
  const { data, ShopBannerImgBaseWithLocation } = props;

  const { store_name, slug, vendor_image } = data;
  const { banner_image, logo_image } = vendor_image && vendor_image;
  return (
    <Col onClick={handleClick} className="store__item-box">
      <div
        className="store__item-main d-flex align-items-end justify-content-end"
        style={
          ShopBannerImgBaseWithLocation
            ? {
                backgroundImage: `url(${ShopBannerImgBaseWithLocation}/${banner_image})`,
              }
            : { backgroundImage: `url(${ShopBannerImgBase}/${banner_image})` }
        }
      >
        <div className="text-end store__item__text-box">
          <h4 className="mb-0">{data && store_name}</h4>
          <p className="font-14 mb-0">Dhanmondi, Dhaka</p>
          <p className="font-14">
            <span>
              <span className="product__card-star">&#9733;</span>
              <span className="product__card-star">&#9733;</span>
              <span className="product__card-star">&#9733;</span>
              <span className="product__card-star">&#9733;</span>
              <span className="product__card-star">&#9733;</span> (5.0)
            </span>
            <span className="ms-2">50 Reviews</span>
          </p>
        </div>
        <div>
          <img
            src={`${ShopLogoBaseM}/${logo_image}`}
            className="store__item-logo"
            alt=""
          />
        </div>
      </div>
    </Col>
  );
};

export default StoreItem;
