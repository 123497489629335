import React from "react";
import { Form, Col } from "react-bootstrap";

const InputSelectWithLabel = ({
  label,
  handler = () => {},
  children,
  value,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
}) => {
  return (
    <Form.Group
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      xxl={xxl}
      as={Col}
      className={`mb-3 double-file-input-box`}
      controlId=""
    >
      <Form.Label className={"d-block"} style={{fontSize:"0.9rem"}}>{label}</Form.Label>
      <Form.Select
        onChange={handler}
        value={value}
        aria-label="payment method"
        className="payment-method-input delivery-charge "
      >
        {children}
      </Form.Select>
    </Form.Group>
  );
};

export default InputSelectWithLabel;
