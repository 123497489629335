import React from "react";
import { Col, Form } from "react-bootstrap";

const TextInputBox = ({
  value,
  name,
  label,
  handler = () => {},
  placeholder,
  disabled = false,
  type = "text",
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
}) => {
  return (
    <Col className="mb-3" xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
      <Form.Label style={{fontSize:"0.9rem"}}>{label}</Form.Label>
      <Form.Control
        disabled={disabled}
        required={true}
        name={name}
        value={value}
        onChange={handler}
        className={`InputWithLabelInput checkout-input`}
        type={type}
        placeholder={placeholder || "Write Here..."}
      />
    </Col>
  );
};

export default TextInputBox;
