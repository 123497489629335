import { Link, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import downloadIcon from "./../../assets/download-icon.svg";
import "./projectCandidate.css";

function ProjectCandidate() {
  const { pathname:url } = useLocation();
  const dynamicPath = url.split("/")[1];

  return (
    <div>
      <div className="projectCandidate">
        <div className="projectCandidate__top">
          <img src='https://i.ibb.co/F8yxsjF/client1.jpg' alt="" className="projectCandidate__user__img" />
          <div className="projectCandidate__user">
            <p className="projectCandidate__user__name">Enamul Gazi</p>
            <p className="projectCandidate__user__title">Ui/Ux Designer</p>
            <p className="projectCandidate__user__hourly">$10/hr</p>
            <p className="projectCandidate__user__rating">
              <FontAwesomeIcon icon={faStar} />
              5.0 <span>(12)</span>
            </p>
          </div>

          <div className="text-sm-end mb-3 mb-sm-0">
            <p className="mb-1">4 hours ago</p>
            <div className="d-flex gap-3 ">
              <Button className="px-2 px-sm-4" variant="outline-secondary">
                Delete
              </Button>
              <Button
                as={Link}
                to={`/${dynamicPath}/client/messages`}
                className="btn__creative px-2 px-sm-4"
              >
                Contact
              </Button>
            </div>
          </div>
        </div>

        <h5>Vietnamese Chinese English Translation</h5>
        <p>
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here', making it
          look like readable English. Many desktop publishing packages
        </p>
        <p className="fw-500 mb-2">Skills</p>
        <div className="d-flex flex-wrap gap-2 gap-sm-3">
          <span className="creative__skills__badge">UX Research</span>
          <span className="creative__skills__badge">making solution</span>
          <span className="creative__skills__badge">
            information architecture
          </span>
          <span className="creative__skills__badge">wireframe</span>
          <span className="creative__skills__badge">mockup</span>
        </div>

        <h6 className="mt-3 mb-1">Freelancer Brief</h6>
        <p>
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here', making it
          look like readable English. Many desktop publishing packages
        </p>

        <div className="text-center mt-4">
          <Button className="btn__creative">
            {" "}
            <img src={downloadIcon} alt="" /> Download Attachment
          </Button>
        </div>
      </div>
    </div>
  );
}
export default ProjectCandidate;
