/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import InputRangeWithLabel from "../../Components/Common/InputRangeWithLabel";
import InputTextAreaWithLabel from "../../Components/Common/InputTextAreaWithLabel";
import InputWithLabel from "src/Common/InputWithLabel/InputWithLabel";
import JobCategorySelectBox from "../../Components/Common/JobCategorySelectBox";
import { useEffect } from "react";
import LocationSelectInput from "../../Components/Common/LocationSelectInput";
import {
  getAllCategories,
  getAllDivisions,
  getCategoryBySubCategory,
  getDivisionByDistricts,
} from "../../utilities/functions";
const CreateNewJob = () => {
  const navigate = useNavigate();
  const [jobPostData, setJobPostData] = useState({
    job_category_id: "",
    job_sub_category_id: "0",
    job_type: "",
    job_title: "",
    range_start: "",
    range_end: "",
    job_address: "",
    job_details: "",
    role_responsibility: "",
    job_requirements: "",
    deadline: "",
    district_id: "",
    division_id: "",
  });

  const [subCategory, setSubCategory] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [error, setError] = useState("");

  const divisions = getAllDivisions();
  const categories = getAllCategories() || [];

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const prevState = jobPostData;
    prevState[name] = value;
    setJobPostData({ ...prevState });
  };

  const {
    job_category_id,
    job_sub_category_id,
    job_type,
    job_title,
    range_start,
    range_end,
    job_address,
    job_details,
    job_requirements,
    role_responsibility,
    deadline,
    district_id,
    division_id,
  } = jobPostData;

  useEffect(() => {
    const sub_category = getCategoryBySubCategory(job_category_id) || [];
    setSubCategory(sub_category);
  }, [job_category_id]);

  useEffect(() => {
    const districts = getDivisionByDistricts(division_id) || [];
    setDistricts(districts);
  }, [division_id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (parseInt(job_category_id) > 0) {
      // if category item not select value = 0 or if select item value will be < 0
      setError("");
      // we have category id but job preview we need category name so use this find method to find category or sub category name
      jobPostData["job_category_name"] = categories.find(
        (item) => item.id == job_category_id
      ).title;
      jobPostData["job_subcategory_name"] = subCategory.find(
        (item) => item.id == job_sub_category_id
      )?.title;
      jobPostData["division_name"] = divisions.find(
        (item) => item.id == division_id
      ).name;
      jobPostData["district_name"] = districts.find(
        (item) => item.id == district_id
      ).name;

      navigate("/Job/company/job-post-preview", {
        state: { jobPostData },
      });
    } else {
      setError("Please Select Category");
    }
  };

  const inputProps = {
    required: true,
    className: ["bordaredInput", "text-start ps-4"],
    checkoutInput: true,
  };

  return (
    <div>
      <h6 className="jobApplyTitle ms-2">Create New Job Post</h6>

      <div className="updateCompanyDetail ms-1">
        {" "}
        <Form onSubmit={handleSubmit}>
          <JobCategorySelectBox
            {...inputProps}
            value={jobPostData.job_category_id}
            label="Job Category"
            name="job_category_id"
            handler={handleInput}
          >
            <option value="" disabled>
              Select Category
            </option>
            {categories.map((item) => (
              <option key={item.id} value={item.id}>
                {item.title}
              </option>
            ))}
          </JobCategorySelectBox>

          <JobCategorySelectBox
            {...inputProps}
            value={job_sub_category_id}
            label="Job Sub Category"
            name="job_sub_category_id"
            handler={handleInput}
            required={false}
          >
            <option value="0" disabled>
              Select Sub Category
            </option>
            {subCategory.map((item) => (
              <option key={item.id} value={item.id}>
                {item.title}
              </option>
            ))}
          </JobCategorySelectBox>

          <JobCategorySelectBox
            {...inputProps}
            value={job_type}
            label="Job Type"
            name="job_type"
            handler={handleInput}
            placeholder="Job Type"
          >
            <option value="" disabled>
              Select Job Type
            </option>
            <option value="Full Time">Full Time</option>
            <option value="Part Time">Part Time</option>
          </JobCategorySelectBox>

          <InputWithLabel
            {...inputProps}
            value={job_title}
            label="Job Title"
            name="job_title"
            handler={handleInput}
            placeholder="Job Title"
            required
          />

          <InputRangeWithLabel
            {...inputProps}
            startValue={range_start}
            endValue={range_end}
            handler={handleInput}
            label="Salary range"
            startName="range_start"
            endName="range_end"
          />
          <LocationSelectInput
            {...inputProps}
            label="Location"
            handler={handleInput}
            district_value={district_id}
            division_value={division_id}
          >
            <option value="" disabled>
              {" "}
              Select District
            </option>
            {districts.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </LocationSelectInput>

          <InputWithLabel
            {...inputProps}
            value={job_address}
            label="Job Address"
            name="job_address"
            handler={handleInput}
            placeholder="Job Location"
            required
          />
          <InputWithLabel
            {...inputProps}
            value={deadline}
            label="Deadline"
            name="deadline"
            handler={handleInput}
            placeholder="Job deadline"
            type="date"
            required
          />

          <InputTextAreaWithLabel
            {...inputProps}
            value={job_requirements}
            label="Job requirement"
            name="job_requirements"
            handler={handleInput}
            placeholder="Job Requirement"
            required
            rows="3"
          />
          <InputTextAreaWithLabel
            {...inputProps}
            value={role_responsibility}
            label="Role responsibility"
            name="role_responsibility"
            handler={handleInput}
            placeholder="Role responsibility"
            required
            rows="3"
          />

          <InputTextAreaWithLabel
            {...inputProps}
            value={job_details}
            label="Job Details"
            name="job_details"
            handler={handleInput}
            placeholder="Job Details"
            required
          />

          <div style={{ fontSize: "0.9rem" }}>
            <p className="text-danger text-center">{error}</p>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button type="submit" className="companyProfileBtn px-5 ">
              Preview
            </button>
          </div>
        </Form>
      </div>
      <br />
      <br />
    </div>
  );
};

export default CreateNewJob;
