import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { PostApiCalls } from "src/Common/apiCall/PostApiCalls";
import { InputField } from "../Components/InputField";

export const StoringInputs = ({ storingInputsArg }) => {
  const {
    isLoading,
    setIsLoading,
    setIsEditableFalse,
    dataReload,
    componentMainUrl,
    formData,
    inputHandleChange,
  } = storingInputsArg;

  // console.log("edit id ", formData && formData.id);
  const updateFormFunc = (e) => {
    e.preventDefault();
    const formArg = {
      formData: formData,
      Url: componentMainUrl,
      setIsLoading: setIsLoading,
      dataReload: dataReload,
      setIsEditableFalse: setIsEditableFalse,
    };
    PostApiCalls(formArg);
  };

  return (
    <form onSubmit={updateFormFunc}>
      <textarea
        className="m-0 p-3 rounded-3 box-shadow border border-dark"
        name="details_about"
        rows="5"
        placeholder="About Your Self ...!"
        value={formData.details_about ? formData.details_about : ""}
        onChange={inputHandleChange}
      ></textarea>

      <div className="py-3 mainProfileBtnDiv">
        <button
          onClick={setIsEditableFalse}
          className="mainProfileBtn btnCandel me-3"
        >
          Cancel
        </button>
        <button
          className={`mainProfileBtn btnSave  ${isLoading ? "disabled" : ""} `}
        >
          Save
        </button>
      </div>
    </form>
  );
};
