import React from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faUserPlus,
  faCommentDots,
  faBookDead,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import {
  fmSentRequestUrl,
  fnSentRequestUrl,
  pSentRequestUrl,
} from "src/Projects/Life/Common/CommonUrl";
import ToastFunc from "src/Common/ToastFunc";
import { useDispatch } from "react-redux";
import { api } from "src/app/services/api";
import {
  getSingleUserChat,
  setCurrentChatting,
  setCurrentChattingUser,
  setShowMessagePopup,
} from "src/features/life/messengerSlice";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";

const Actions = ({ userId, person }) => {
  const { last_name, first_name, photo } = person || {};
  const data = {
    first_name,
    last_name,
    photo,
    id: userId,
    profile_id: null,
    username: null,
  };

  const dispatch = useDispatch();
  const { pathname: url } = useLocation();

  const handleMessageClick = () => {
    dispatch(setCurrentChatting([]));
    dispatch(setCurrentChattingUser(data));
    dispatch(getSingleUserChat({ user_id: userId }));
    dispatch(setShowMessagePopup(true));
  };

  const handleAddRequest = () => {
    if (userId) {
      let tempUrl;
      let userIdField;

      if (url.includes("/family-life")) {
        tempUrl = fmSentRequestUrl;
        userIdField = "family_id";
      } else if (url.includes("/friends-life")) {
        tempUrl = fnSentRequestUrl;
        userIdField = "friend_id";
      } else if (url.includes("/professional-life")) {
        tempUrl = pSentRequestUrl;
        userIdField = "professional_id";
      }
      const formData = {
        [userIdField]: userId,
      };

      axios
        .post(`${tempUrl}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((res) => {
          if (res.data.status === 1) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            dispatch(api.util.invalidateTags(["suggestedFriends"]));
            Toast.fire({
              icon: "success",
              title: res.data.message,
            }).then(() => {});
          }
        })
        .catch((err) => {
          console.log("failed", err);
        });
    } else {
      return ToastFunc("error", "Cant Send Empty Request");
    }
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          aria-label="toggle button"
          className="ToggleDesignClear"
        >
          {/* <FontAwesomeIcon icon={faEllipsisH} size="lg" /> */}
          <ThreeDots size="20px" />
        </Dropdown.Toggle>
        <Dropdown.Menu variant="dark">
          <div className="ClickThreeDot dropDown-sm mb-3 px-3 pb-3 pt-3">
            <div
              className="btnHoverBorder"
              aria-label="Add"
              tabIndex="0"
              onClick={handleAddRequest}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleAddRequest();
                }
              }}
            >
              <div className="mt-1">
                <FontAwesomeIcon icon={faUserPlus} />
                <span className="smallTextSize mx-2 fw-bold">Add</span>
              </div>
            </div>
            <div
              className="btnHoverBorder"
              onClick={handleMessageClick}
              aria-label="Message"
              tabIndex="0"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleMessageClick();
                }
              }}
            >
              <div className=" mt-1">
                <FontAwesomeIcon icon={faCommentDots} />
                <span className="smallTextSize mx-2 fw-bold">Message</span>
              </div>
            </div>
            <div className=" btnHoverBorder">
              <div className=" mt-1">
                <FontAwesomeIcon icon={faBookDead} />
                <span className="smallTextSize mx-2 fw-bold">Block</span>
              </div>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default Actions;
