import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import SingleJobPost from "../../Components/SingleJobPost/SingleJobPost";
import { useGetHomeJobPostQuery } from "src/app/services/jobApi/jobGetApi";
import { useState } from "react";
import JobSkeleton from "../../Components/Placeholder/JobSkeleton";

const HomeJobs = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [jobData, setJobData] = useState([]);

  const {
    data: result,
    isFetching,
    error,
  } = useGetHomeJobPostQuery(currentPage);
  const { data, hasMore } = result || {};

  useEffect(() => {
    if (data) {
      setJobData((prevState) => [...prevState, ...data]);
    }
  }, [data]);

  const handleLoadMore = () => {
    if (hasMore) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  return (
    <div className="pt-3">
      <div className="jobRecommended p-2 pb-4">
        <p className="text-orange">Recommended Job</p>

        {jobData.length < 1 && !isFetching && (
          <p className="text-center mt-4"> 0 item Found </p>
        )}
        {isFetching && (
          <Row className="g-3" xxl="2">
            {[...new Array(6)].map((_, index) => (
              <JobSkeleton key={index} />
            ))}
          </Row>
        )}
        <Row className="g-3">
          {jobData.map((post) => (
            <SingleJobPost key={post.id} data={post} />
          ))}
        </Row>

        {error && <p className="text-danger">{error.error}</p>}
        <div className="mt-4 mb-3 text-end">
          <button
            className="send-code-btn primary-text"
            disabled={!hasMore}
            onClick={handleLoadMore}
          >
            Load More
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeJobs;
