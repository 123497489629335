import React from 'react';
import { Container } from "react-bootstrap";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Payment.css"


const PaymentCanceled = () => {
  const handleClose = () => {
    window.open('https://www.google.com');
    window.close();
  };
    return (
        <Container className="h-100 payment canceled p-0 b" fluid>
        <div className="payment-card-box">
          <div className="payment-card p-2 p-sm-3 p-md-4 pb-3">
            <div className="payment-card-header mt-4">
            <FontAwesomeIcon
              className="check-icon"
              style={{ fontSize: "8rem" }}
              icon={faCheckCircle}
            />
              <h3 className="mt-4">Payment Cancelled</h3>
            </div>
            <div className="payment-card-body mt-2 mb-4">
              <div  >
               <p className="text-center">Your bill payment was cancelled successfully </p>
              </div>
            </div>
            <div className="payment-card-footer">
              <button onClick={handleClose}>Close</button>
            </div>
          </div>
        </div>
      </Container>
    );
};

export default PaymentCanceled;