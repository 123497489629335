import React from "react";
import { sliderImgBase } from "../../utilities/CommonUrl";

const BannerSliderItem = (props) => {
  const { slider_image } = props.data;
  return (
    <div className="banner__slider-item">
      <img
        src={`${sliderImgBase}/${slider_image}`}
        className="img img-fluid w-100 h-100"
        alt=""
      />
    </div>
  );
};

export default BannerSliderItem;
