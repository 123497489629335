import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faPen, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { TextInputField } from "./Components/TextInputField";
import { isEmpty } from "lodash";
import useAuth from "src/hooks/useAuth";
import { PostApiCalls } from "src/Common/apiCall/PostApiCalls";
import { userMainInfoUrl } from "src/Projects/Life/Common/CommonUrl";

export const GeneralInfo = ({ info }) => {
  const { authUser, getUserInfo } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const setIsEditableFalse = () => setIsEditable(false);
  const setIsEditableTrue = () => setIsEditable(true);

  const [formData, setformData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    phone: "",
    email: "",
  });
  useEffect(() => {
    if (!isEmpty(info)) {
      setformData({
        first_name: authUser && authUser.first_name ? authUser.first_name : "",
        last_name: authUser && authUser.last_name ? authUser.last_name : "",
        username: authUser && authUser.username ? authUser.username : "",
        phone:
          info && info.users_info && info.users_info.phone
            ? info.users_info.phone
            : "",
        email: authUser && authUser.email ? authUser.email : "",
      });
    }
  }, [info]);
  const inputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...formData };
    newData[name] = value;
    setformData(newData);
  };
  const updateFormFunc = (e) => {
    e.preventDefault();
    const formArg = {
      formData: formData,
      Url: userMainInfoUrl,
      setIsLoading: setIsLoading,
      dataReload: getUserInfo,
      setIsEditableFalse: setIsEditableFalse,
    };
    PostApiCalls(formArg);
  };
  return (
    <div className="mb-4">
      <Col xs={12} sm={12} md={12} lg={12} className="aboutBox mt-1 mb-4 ">
        <div className="d-flex justify-content-between border-bottom pb-2 border-white">
          <div>
            <h6>General Info</h6>
            <div className="d-flex pointer" onClick={setIsEditableTrue}>
              <div className="me-2">
                <FontAwesomeIcon icon={faPen} />
              </div>
              <div>Edit General Info</div>
            </div>
          </div>
        </div>
        <form onSubmit={updateFormFunc}>
          <div className="mt-5">
            <div className="d-flex justify-content-between my-4 align-items-start ">
              <TextInputField
                iconName={faHeart}
                isEditable={isEditable}
                inputPlaceholder="first name"
                textInputName="first_name"
                textInputValue={formData.first_name ? formData.first_name : ""}
                changeHandle={inputHandleChange}
              />
            </div>
            <div className="d-flex justify-content-between my-4 align-items-start">
              <TextInputField
                iconName={faHeart}
                isEditable={isEditable}
                inputPlaceholder="last name"
                textInputName="last_name"
                textInputValue={formData.last_name ? formData.last_name : ""}
                changeHandle={inputHandleChange}
              />
            </div>
            <div className="d-flex justify-content-between my-4 align-items-start ">
              <TextInputField
                iconName={faHeart}
                isEditable={isEditable}
                inputPlaceholder="email"
                textInputName="email"
                textInputValue={formData.email ? formData.email : ""}
                changeHandle={inputHandleChange}
                textInputType="email"
              />
            </div>
            <div className="d-flex justify-content-between my-4 align-items-start">
              <TextInputField
                iconName={faHeart}
                isEditable={isEditable}
                inputPlaceholder="username"
                textInputName="username"
                textInputValue={formData.username ? formData.username : ""}
                changeHandle={inputHandleChange}
                // disable={"disabled bg-dark"}
                required={false}
              />
            </div>
            <div className="d-flex justify-content-between my-4 align-items-start">
              <TextInputField
                iconName={faPhoneAlt}
                isEditable={isEditable}
                inputPlaceholder="phone"
                textInputName="phone"
                textInputValue={formData.phone ? formData.phone : ""}
                changeHandle={inputHandleChange}
                textInputType="number"
              />
            </div>
          </div>
          {isEditable && (
            <div className="py-3 mainProfileBtnDiv">
              <button
                onClick={setIsEditableFalse}
                className="mainProfileBtn btnCandel me-3"
              >
                Cancel
              </button>
              <button
                className={`mainProfileBtn btnSave  ${
                  isLoading ? "disabled" : ""
                } `}
              >
                save
              </button>
            </div>
          )}
        </form>
      </Col>
    </div>
  );
};
