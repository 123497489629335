import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { booksInfoUrl } from "src/Projects/Life/Common/CommonUrl";
import { getInfo } from "src/Common/apiCall/GetApiCalls";

import { singleGetInfo } from "../Components/singleGetInfo";
import { SingleItem } from "./SingleItem";
import { StoringInputs } from "./StoringInputs";
import { BubbleScale } from "src/Common/BubbleScale";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";

export const Books = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const setIsEditableFalse = () => setIsEditable(false);
  const setIsEditableTrue = () => setIsEditable(true);
  const componentMainUrl = booksInfoUrl;
  const [info, setinfo] = useState();
  const [singleInfo, setsingleInfo] = useState();
  useEffect(() => {
    getData();
  }, []);
  const previousDataState = {
    data: info,
    setData: setinfo,
  };
  const getData = () => {
    getInfo(setIsLoading, componentMainUrl, setinfo);
  };

  const storingInputsArg = {
    isLoading: isLoading,
    setIsEditableFalse: setIsEditableFalse,
    setIsLoading: setIsLoading,
    dataReload: getData,
    singleInfo: singleInfo,
    componentMainUrl: componentMainUrl,
  };
  const editClick = (itemId) => {
    singleGetInfo(setIsLoading, componentMainUrl, setsingleInfo, itemId);
    setIsEditableTrue();
  };
  const addClick = () => {
    setsingleInfo(); //formate if any edit data exist
    setIsEditableTrue();
  };
  if (isLoading) {
    return <DottedCircleSpinner />;
  }
  return (
    <div className="mb-4">
      <Col xs={12} sm={12} md={12} lg={12} className="aboutBox mt-1 mb-4 ">
        <div className="border-bottom pb-2 border-white">
          <div className="d-flex ">
            <div className="me-2 ">
              <FontAwesomeIcon icon={faBook} />
            </div>
            <div>
              <h6>Books</h6>
            </div>
          </div>
          <div className="d-flex pointer" onClick={addClick}>
            <div className="me-2">
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div className=""> Add Book</div>
          </div>
        </div>
        <div className="mt-5">
          {isEditable ? (
            <>
              <StoringInputs storingInputsArg={storingInputsArg} />
            </>
          ) : (
            <>
              {info &&
                info.map((item, index) => (
                  <SingleItem
                    key={index}
                    item={item}
                    previousDataState={previousDataState}
                    editClick={editClick}
                    componentMainUrl={componentMainUrl}
                  />
                ))}
            </>
          )}
        </div>
      </Col>
    </div>
  );
};
