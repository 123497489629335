import React from "react";
import { Link, useLocation } from "react-router-dom";
import SingleCategory from "../Common/SingleCategory";

const CategoriesSliderItem = (props) => {
  const {data}=props
  const { pathname } = useLocation();
  const {CategoryName,limited_product,slug}=data

  return (
    <div className="category__slider__item-container">
    
      <Link to={`${pathname}/category/category-wise-product/${slug}`}>
      <h6>{CategoryName}</h6>
                  </Link>
      <div className="categories__slider-item mx-auto">
        {limited_product.map((item) =>
              <SingleCategory item={item} key={item.id}/>

          )}
      </div>
    </div>
  );
};

export default CategoriesSliderItem;
