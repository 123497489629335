import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Navbar, Nav, Offcanvas, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";

import CreatePostModal from "./../Common/Modal/CreatePostModal";

import useAuth from "./../../../../hooks/useAuth";

import logo from "./../../assets/logo.svg";
import humanAvatar from "./../../assets/human-avatar.svg";

import "./header.css";

function Header() {
  const [navOffcanvasShow, setNavOffcanvasShow] = useState(false);
  const [showCreatePostModal, setShowCreatePostModal] = useState(false);

  const isAuthenticated = useAuth();
  const { pathname } = useLocation();
  const dynamicPath = pathname.split("/")[1];

  const handleNavOffcanvasClose = () => setNavOffcanvasShow(false);
  const handleNavOffcanvasShow = () => setNavOffcanvasShow(true);

  return (
    <>
      <CreatePostModal
        show={showCreatePostModal}
        onHide={() => setShowCreatePostModal(false)}
      />
      <header className="header">
        <Navbar collapseOnSelect expand="md">
          <Container>
            <Navbar.Brand>
              <div className="header__logo--container">
                <Link to="/dashboard">
                  <img src={logo} alt="UVIOM Creative" />
                </Link>
                <Link to={pathname}>
                  <div className="header__logo--text">
                    <p className="logo-uviom-ride">
                      <bdo>UVIOM</bdo>
                      <span>Creative</span>
                    </p>
                    <h6 className="logo-slogan">
                      <Typewriter
                        className="logo-slogan"
                        options={{
                          strings: "Yes You Can Do IT",
                          autoStart: true,
                          loop: true,
                        }}
                      />
                    </h6>
                  </div>
                </Link>
              </div>
            </Navbar.Brand>

            <Navbar.Collapse>
              <Nav className="mx-auto header__nav">
                {isAuthenticated.authUser.id ? (
                  <>
                    <Nav.Link
                      as={Link}
                      className="header__nav--link"
                      to={`/${dynamicPath}/findfreelancer`}
                    >
                      Find Freelancer
                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      className="header__nav--link"
                      to={`/${dynamicPath}/findjobs`}
                    >
                      Find Jobs
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => setShowCreatePostModal(true)}
                      className="header__nav--link"
                    >
                      Create Post
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    <Nav.Link as={Link} className="header__nav--link" to={pathname}>
                      Home
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      className="header__nav--link"
                      to={`/${dynamicPath}/about`}
                    >
                      About
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      className="header__nav--link"
                      to={`/${dynamicPath}/privacy`}
                    >
                      Privacy
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      className="header__nav--link"
                      to={`/${dynamicPath}/contact`}
                    >
                      Contact
                    </Nav.Link>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>

            <div className="header__icon">
              <Dropdown>
                <Dropdown.Toggle className="toggle-reset">
                  <img src={humanAvatar} alt="" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-right-align">
                  <Dropdown.Item as={Link} to={`/${dynamicPath}/freelancer`}>
                    As Freelancer
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={`/${dynamicPath}/client`}>
                    As Client
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <button
                onClick={handleNavOffcanvasShow}
                className="nav__burger false"
              >
                <span className="burger-icon">&nbsp;</span>
              </button>
            </div>
          </Container>
        </Navbar>

        <Offcanvas
          className="ride__offcanvas"
          placement="end"
          show={navOffcanvasShow}
          onHide={handleNavOffcanvasClose}
        >
          <Offcanvas.Header closeButton>
            <img src={logo} className="" alt="UVIOM Creative" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="ride__offcanvas--menu">
              <li>
                <Link onClick={handleNavOffcanvasClose} to={pathname}>
                  Home
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleNavOffcanvasClose}
                  to={`/${dynamicPath}/about`}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleNavOffcanvasClose}
                  to={`/${dynamicPath}/privacy`}
                >
                  Privacy{" "}
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleNavOffcanvasClose}
                  to={`/${dynamicPath}/contact`}
                >
                  Contact{" "}
                </Link>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </header>
    </>
  );
}
export default Header;
