import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import CompanyBanner from "../../Components/Common/CompanyBanner";
import CompanySingleInfo from "../../Components/Common/CompanySingleInfo";
import "./CompanyProfile.css";
import {
  companyBannerPhotoUrl,
  companyProfilePhotoUrl,
} from "../../utilities/job_commonUrl";

import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import { useSelector } from "react-redux";
import { useGetCompanySocialLinkQuery } from "src/app/services/jobApi/jobGetApi";

const CompanyProfile = () => {
  const {
    data: socialLinks,
    isFetching,
    error,
  } = useGetCompanySocialLinkQuery();
  const { company_profile } = useSelector((store) => store.jobProfile);
  const {
    company_EINN,
    company_address,
    company_phone,
    company_email,
    company_name,
    website,
    profile_banner,
    profile_picture,
    district,
    division,
  } = company_profile;

  const uploaded_banner = profile_banner
    ? companyBannerPhotoUrl + profile_banner
    : "";
  const uploaded_logo = profile_picture
    ? companyProfilePhotoUrl + profile_picture
    : "";

  return (
    <>
      {isFetching ? (
        <BubbleLoader />
      ) : (
        <div>
          <CompanyBanner
            profile_banner={uploaded_banner}
            profile_picture={uploaded_logo}
          />

          <br />
          <div className="applicantTitle">
            <h6>Company Profile</h6>
            <Link to="/Job/company/update-profile" state={{ socialLinks }}>
              Update Profile
              <FontAwesomeIcon
                className="ms-2"
                icon={faPencilAlt}
              ></FontAwesomeIcon>
            </Link>
          </div>

          <div
            style={{ fontSize: "14px" }}
            className="company-profile applicantProfile p-2 p-lg-3 p-xl-4 my-3 pt-4"
          >
            <CompanySingleInfo title="Company name" value={company_name} />
            <CompanySingleInfo title="Company EINN" value={company_EINN} />
            <CompanySingleInfo title="Phone" value={company_phone} />
            <CompanySingleInfo title="Email" value={company_email} />
            <CompanySingleInfo title="Division" value={division?.name} />
            <CompanySingleInfo title="District" value={district?.name} />

            <CompanySingleInfo title="Address" value={company_address} />
            <CompanySingleInfo isLink={true} title="Website" value={website} />

            {socialLinks.length > 0 &&
              socialLinks.map((item) => (
                <CompanySingleInfo
                  key={item.id}
                  isLink={true}
                  title={item.social_media_name}
                  value={item.social_media_link.substr()}
                />
              ))}
            <br />
          </div>
          <br />

          {error && <p className="text-danger">{error?.error}</p>}
        </div>
      )}
    </>
  );
};

export default CompanyProfile;
