// export const imageUrl = "https://uviom-life.s3.amazonaws.com";
export const imageUrl =
  "https://uviom-life-running.s3.ap-southeast-1.amazonaws.com";
export const Url = "https://admin.uviom.com/frontend_api";
export const CACHE_EXPIRE_TIME = 600;

export const getAllCategoryUrl = `${Url}/job/get-all-categories`;
export const allJobsPostUrl = `${Url}/job/get-all-job-posts`;
export const categoryJobsPostUrl = `${Url}/job/get-category-posts`;
export const createCompanyUrl = `${Url}/job/create-company-profile`;
export const getCompanyDataUrl = `${Url}/job/get-single-compnany-profile`;
export const getSubCategoryByIdUrl = `${Url}/job/job-subcategories/`;
export const createJobPostUrl = `${Url}/job/create-job-post`;
export const postedJobUrl = `${Url}/job/get-compnany-posts-dashboard`;
export const updateJobPostUrl = `${Url}/job/update-job-post/`;
export const applicantProfileUrl = `${Url}/job/get-single-job-applicant-profile`;
export const createApplicantProfileUrl = `${Url}/job/create-applicant-profile`;
export const updateApplicantProfileUrl = `${Url}/job/update-applicant-profile`;
export const applicantResumeUploadUrl = `${Url}/applicant/update-applicant-cv`;
export const jobApplyUrl = `${Url}/job/job-apply/`;
export const getAppliedJobUrl = `${Url}/job/get-submit-post-save-applicant`;
export const jobSaveUrl = `${Url}/job/job-save/`;
export const getSavedJobURl = `${Url}/job/get-job-save-applicant`;
export const getApplicantListUrl = `${Url}/job/get-job-applicants/`;
export const updateCompanyBannerUrl = `${Url}/company/update-company-cover-photo`;
export const updateCompanyLogoUrl = `${Url}/company/update-company-logo`;
export const updateCompanyProfileUrl = `${Url}/job/update-company-profile`;
export const deleteCompanyJobPostUrl = `${Url}/job/delete-company-job-post/`;
export const createCompanySocialLinkUrl = `${Url}/job/create-company-social-link`;
export const getCompanySocialLinkUrl = `${Url}/company/social-link`;
export const getCompanyImageInfo = `${Url}/company/company-image-info`;
export const createApplicantEducationUrl = `${Url}/job/create-applicant-education`;
export const getApplicantEducationUrl = `${Url}/job/get-applicant-applicant-profile-educations`;
export const deleteApplicantEducationUrl = `${Url}/job/delete-applicant-education/`;
export const updateApplicantEducationUrl = `${Url}/job/update-applicant-education/`;
export const getAllCompanyUrl = `${Url}/job/get-all-company`;
export const getCompanyPost = `${Url}/job/get-compnany-posts/`;
export const getAllDivision = `${Url}/job/get-all-divisions`;
export const getAllDistricts = `${Url}/job/get-all-districts`;
export const getDistrictByPost = `${Url}/job/get-district-by-posts/`;
export const getDivisionByPost = `${Url}/job/get-division-by-posts/`;
export const getDistrictByDivision = `${Url}/job/get-division-by-districts/`;
export const subCategoryByPost = `${Url}/job/get-sub-category-posts`;
export const jobSearchPost = `${Url}/job/search-post`;
export const getCompanyCount = `${Url}/job/company-count`;
export const getJobCount = `${Url}/job/job-count`;
export const getApplicantCount = `${Url}/job/applicant-count`;
export const getLastWeekJobCount = `${Url}/job/job-count-last-week`;
export const getJobSliderUrl = `${Url}/job/get-all-sliders`;

// job photo url
export const companyProfilePhotoUrl = `${imageUrl}/images/job/company/profile_pic/`;
export const companyBannerPhotoUrl = `${imageUrl}/images/job/company/profile_banner/`;
export const applicantCvUrl = `${imageUrl}/images/job/applicant/profile_cv/`;
export const applicantPhoto = `${imageUrl}/images/personal/profile_pic/`;
export const categoryImgUrl = `${imageUrl}/images/job/job_category/`;
export const jobSliderImgUrl = `${imageUrl}/images/job/job_slider/`;
