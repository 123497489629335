import { Link, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import FreelancerCard from "./../Common/FreelancerCard";

import "./freelanceTopFreelancer.css";

const freelancer = [
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/y5PzgRy/freelancer1.jpg",
  },
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/wcQMxNk/freelancer2.jpg",
  },
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/ph7c15Z/freelancer3.jpg",
  },
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/wByd9DP/freelancer4.jpg",
  },
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/6r5jH9m/freelancer5.jpg",
  },
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/k4264Lj/freelancer6.jpg",
  },
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/Z2VTXhz/freelancer7.jpg",
  },
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/pv86nC1/freelancer8.jpg",
  },
];

function FreelanceTopFreelancer() {
  const { pathname } = useLocation();
  const dynamicPath = pathname.split("/")[1];
  return (
    <Container>
      <div className="freelanceTopFreelancer">
        <h2 className="freelanceTopFreelancer__title">Top Freelancers</h2>

        <ul className="freelanceTopFreelancer__list">
          {freelancer.map((person, index) => (
            <Link to={`/${dynamicPath}/user`} key={index} >
              <FreelancerCard person={person} />
            </Link>
          ))}
        </ul>
      </div>
    </Container>
  );
}
export default FreelanceTopFreelancer;
