import React, { useState } from "react";
import DabbleCheckMark from "src/Projects/Life/icons/DabbleCheckMark";
import ChatAvatar from "./ChatAvatar";
import { useNavigate, useLocation } from "react-router-dom";
import { personalProfilePic } from "src/Projects/Life/Common/CommonUrl";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleUserChat,
  setChatListPopup,
  setCurrentChatting,
  setCurrentChattingUser,
  setShowMessagePopup,
} from "src/features/life/messengerSlice";
import { useEffect } from "react";
import { timeAgo } from "src/utilities/utilities";
import { useMemo } from "react";

const ChatPerson = ({ active, seen, data, authUserId }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const url = pathname.split("/message")[0];

  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const { chatList } = useSelector((state) => state.messengerData);

  useEffect(() => {
    if (authUserId !== data?.from_user?.id) {
      setUserData(data?.from_user);
    } else {
      setUserData(data?.to_user);
    }
  }, [data, authUserId]);

  const ChatContentAction = () => {
    const messages = chatList[`chat_list_${userData.id}`];

    dispatch(setCurrentChattingUser(userData));

    if (messages?.length) {
      dispatch(setCurrentChatting(messages));
    } else {
      dispatch(getSingleUserChat({ user_id: userData.id }));
    }

    if (pathname.includes("/message")) {
      navigate(`${url}/message/${userData.id}`);
    } else {
      dispatch(setChatListPopup(false));
      dispatch(setShowMessagePopup(true));
    }
  };
  const getTimeAgo = useMemo(
    () => timeAgo(data?.created_at),
    [data.created_at]
  );
  return (
    <div
      className={`px-2 py-2 my-2 pointer rounded border ${
        !pathname.includes("/message") ? "nav-bg" : null
      }`}
      onClick={ChatContentAction}
    >
      <div className="chat_parson" style={{ maxWidth: "inherit" }}>
        {userData && userData.photo ? (
          <ChatAvatar
            active={active}
            img={`${personalProfilePic}/${userData.photo}`}
          />
        ) : (
          <ChatAvatar active={active} img="/user.png" />
        )}

        <div className="chat__person-info ms-2 ">
          <p className="text-ellipsis m-0 font-14 w-100">
            {userData && userData.first_name} {userData && userData.last_name}
          </p>
          <p className="chat__person__recent-message font-12 m-0 text-ellipsis">
            {data && data.content ? data.content : <small>Attachment</small>}
          </p>
        </div>

        <div className="d-flex flex-row justify-content-end align-items-center ms-auto">
          <span className="chat__last-seen font-12 mt-1 me-2">
            {getTimeAgo}
          </span>
          {data?.my_status === "1" && (
            <span className="chat__sent-icon">
              <DabbleCheckMark />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatPerson;
