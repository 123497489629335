import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const NavButton = ({ RouteName, title, icon, className = "" }) => {
  const { pathname } = useLocation();
  const path = "/" + pathname.split("/")[1];
  return (
    <NavLink
      className={({ isActive }) =>
        `${className}  justify-content-start nav__button mb-3 primary-text ${
          isActive && "nav-button-active"
        }`
      }
      to={`${path}/${RouteName}`}
    >
      <button className=" ms-2 btn-0">
        <FontAwesomeIcon
          className="me-2 icon-clr"
          size="lg"
          icon={icon}
        ></FontAwesomeIcon>
        <span className="primary-text">{title}</span>
      </button>
    </NavLink>
  );
};

export default NavButton;
