import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import VehicleOptions from "./../VehicleOption";

import manWithBriefcase from "./../../assets/manWithBriefcase-icon.svg";
import manGoingInsideCar from "./../../assets/manGoingInsideCar-icon.svg";
import car from "./../../assets/car-icon.svg";

import "./findRide.css";

function FindRide({ rideFrom, rideTo }) {
  const [vehicleOption, setVehicleOption] = useState(false);
  const { pathname:url } = useLocation();

  function handleVehicleOption() {
    if (url === "/ride/findride") {
      setVehicleOption(!vehicleOption);
    }
  }


  return (
    <>
      <div className="findRide">
        <Container>
          <div className="findRide__container">
            <ul className="findRide__timeline">
              <li className="findRide__timeline--item active">
                <span />
              </li>
              <li className="findRide__timeline--item">
                <span />
              </li>
              <li className="findRide__timeline--item">
                <span />
              </li>
            </ul>
            <div className="findRide__row">
              <div>
                <p>Pick Up</p>
                <div className="findRide__button">
                  <span className="findRide__icon">
                    <img src={manWithBriefcase} alt="" />
                  </span>
                  <input
                    type="text"
                    className="findRide__text"
                    placeholder="Enter Destination"
                    defaultValue={rideFrom && rideFrom}
                  />
                </div>
              </div>
              <div>
                <p>Drop</p>
                <div className="findRide__button">
                  <span className="findRide__icon">
                    <img src={manGoingInsideCar} alt="" />
                  </span>
                  <input
                    className="findRide__text"
                    type="text"
                    placeholder="Enter Destination"
                    defaultValue={rideTo && rideTo}
                  />
                </div>
              </div>
              <div>
                <p>Find</p>
                <div
                  onClick={handleVehicleOption}
                  className="findRide__button active"
                >
                  <span className="findRide__icon">
                    <img src={car} alt="" />
                  </span>
                  <span className="findRide__text">Find My Ride</span>
                </div>
              </div>
            </div>
          </div>

          {/* Vehicle Options */}
          {vehicleOption && <VehicleOptions />}
        </Container>
      </div>
    </>
  );
}
export default FindRide;
