import React from "react";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect } from "react";
import FloatSaveJobCard from "../../Components/Common/FloatSaveJobCard";
import { Row } from "react-bootstrap";
import { useGetSaveJobPostQuery } from "src/app/services/jobApi/jobGetApi";
import JobSkeleton from "../../Components/Placeholder/JobSkeleton";

const SaveJob = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postList, setPostList] = useState([]);
  
  const { data:result, isFetching, error } = useGetSaveJobPostQuery(currentPage);
  const { data, hasMore } = result || {}

 
  useEffect(() => {
    if (data) {
      setPostList(prevState => [...prevState, ...data]);
   }
  }, [data]);


  return (
    <div className="secondary-bg p-2 pb-5">
      <h6 className="jobApplyTitle">Save Jobs</h6>
      <InfiniteScroll
        dataLength={postList.length} //This is important field to render the next data
        next={() => {
          setCurrentPage(currentPage + 1);
        }}
        hasMore={hasMore}
      >
        {postList.length < 1 && !error && !isFetching &&(
          <p className="text-center mt-4"> 0 item Found </p>
        )}
         {
          isFetching && (
            <Row className="g-3" xxl="2">
              {[...new Array(6)].map((_, index) => (<JobSkeleton key={index} />))}
            </Row>
          )
        }

        <Row xs={1} xxl={2} className="g-3">
          {postList.map((item) => {
            if (item.job_post && item.company_profile_section) {
              return <FloatSaveJobCard key={item.id} data={item} />;
            } else return null;
          })}
        </Row>
        {error && <p className="text-danger">{error?.error}</p>}
      </InfiniteScroll>
    </div>
  );
};

export default SaveJob;
