import React from "react";
import { RightCol } from "./../RightCol/RightCol";
import { LeftCol } from "../LeftCol/LeftCol";
import { LeftColInner } from "../LeftCol/LeftColInner";
// import { RightCol } from '../RightCol/RightCol'

export const Menuoptions = () => {
  return (
    <>
      <div className="mt-3">
        {/* <div className="row ">
          <div className="col-6 col-lg-6 d-block d-lg-none  ">
            <LeftColInner />
          </div>
          <div className="col-6 col-lg-6 d-block d-lg-none  ">
          </div>

          <LeftCol />
        </div> */}
        <RightCol />
      </div>
    </>
  );
};
