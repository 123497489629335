import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { SeeMore } from "src/Projects/Life/Components/SeeMore";
import CommentIcon from "src/Projects/Life/icons/CommentIcon";
import ShareIcon from "src/Projects/Life/icons/ShareIcon";
import { PostActions } from "../NewsFeedPost/Components/PostActions/PostActions";

export const SinglePostSample = () => {
  return (
    <>
      <div className="mb-3 singlePostSample bg-footer box-shadow rounded-3">
        <div className="col-12 MiddlePartScrool  py-0 my-0">
          <div className="PostHeaderOption px-3 pt-3 mb-2">
            <div className="ProfileAndName">
              <div className="PostSectionProfile">
                <img className="img-fluid" src="/user.png" alt="user" />
              </div>
              <div className="ms-2">
                <h6 className="my-0 smallTextSize-sm namehoverEffect">
                  Suborna Akter
                </h6>
                <div className="smallTextSize-sm">
                  <small>1 hr ago</small>
                </div>
              </div>
            </div>
            <PostActions />
          </div>
        </div>
        <div className="col-12 NewsPhotoDiv position-relative">
          <div className=" rounded float-start oneImageCss  ">
            <img
              className=" img-fluid"
              src="/Life/Sample/post.png"
              alt="post"
            />
          </div>
        </div>
        <div className="col-12 py-3 px-4 px-lg-3 text-align-justify ">
          <small>
            <SeeMore>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita
              facilis quibusdam adipisci asperiores! Quidem doloremque pariatur
              deserunt molestiae exercitationem consectetur. Inventore porro
              quidem, velit ipsa nesciunt explicabo ut consectetur. Iure quae
              minima ipsam praesentium, architecto doloremque non. Debitis
              dignissimos ad aut adipisci, odit consequatur autem culpa ipsa
              esse incidunt quod.
            </SeeMore>
          </small>
        </div>
        <div className="col-12  my-2 ">
          <div className="LikeCommentShareParent">
            <div className="text-center">
              <div className="LikeCommentsharePart hoverEffect  ">
                <div className="LikeCommentIcon ">
                  <FontAwesomeIcon icon={faThumbsUp} />
                </div>
                <div className="mt-1 ms-md-1">
                  <small>Like</small>
                </div>
              </div>
              <div className="smallTextSize  mt-1 pointer hoverEffect clickEffect ">
                1 Like
              </div>
            </div>
            <div className="text-center ">
              <div className="LikeCommentsharePart hoverEffect">
                <div className="LikeCommentIcon">
                  <CommentIcon className="primary-text" size="22" />
                </div>
                <div className="mt-1 ms-md-1 ">
                  <small>Comment</small>
                </div>
              </div>
              <div className="smallTextSize  mt-1 pointer hoverEffect clickEffect ">
                1 Comment
              </div>
            </div>
            <div className="text-center ">
              <div className="LikeCommentsharePart hoverEffect">
                <div className="LikeCommentIcon">
                  <ShareIcon size="20" className="primary-text" />
                </div>
                <div className="mt-1 ms-md-1">
                  <small>Share</small>
                </div>
              </div>
              <div className="smallTextSize mt-1 clickEffect pointer">
                0 share
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
