import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const payment_columns = [
    {
      name: "Food Name",
      selector: (row) => row.food_name,
    },
    {
      name: "Restaurant Name",
      selector: (row) => row.restaurant_name,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Payment Method",
      selector: (row) => row.payment_method,
    },
    {
      name: "Statement",
      button: true,
      selector: (row) => (
        <button className="eco-btn primary-text" >
          Invoice
          <FontAwesomeIcon className="text-orange ms-2" icon={faDownload} />
        </button>
      ),
    },
  ];
  
  export const payment_data = [
    {
      id: 1,
      food_name: "steak frites, (French: “steak [and] ",
      restaurant_name: "ZamZam Restuant (Mirpur-11)",
      date: "18 jun 2022",
      amount: "1000.00 Tk ",
      payment_method: "UVIOM Wallet",
    },
    {
      id: 2,
      food_name: "steak frites, (French: “steak [and] ",
      restaurant_name: "ZamZam Restuant (Mirpur-11)",
      date: "18 jun 2022",
      amount: "1000.00 Tk ",
      payment_method: "UVIOM Wallet",
    },
    {
      id: 3,
      food_name: "steak frites, (French: “steak [and] ",
      restaurant_name: "ZamZam Restuant (Mirpur-11)",
      date: "18 jun 2022",
      amount: "1000.00 Tk ",
      payment_method: "UVIOM Wallet",
    },
    {
      id: 4,
      food_name: "steak frites, (French: “steak [and] ",
      restaurant_name: "ZamZam Restuant (Mirpur-11)",
      date: "18 jun 2022",
      amount: "1000.00 Tk ",
      payment_method: "UVIOM Wallet",
    },
    {
      id: 5,
      food_name: "steak frites, (French: “steak [and] ",
      restaurant_name: "ZamZam Restuant (Mirpur-11)",
      date: "18 jun 2022",
      amount: "1000.00 Tk ",
      payment_method: "UVIOM Wallet",
    },
    {
      id: 6,
      food_name: "steak frites, (French: “steak [and] ",
      restaurant_name: "ZamZam Restuant (Mirpur-11)",
      date: "18 jun 2022",
      amount: "1000.00 Tk ",
      payment_method: "UVIOM Wallet",
    },
  ];
  