/* eslint-disable react-hooks/exhaustive-deps */
import {
  faEdit,
  faEyeSlash,
  faSave,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBug,
  faLink,
  faTrash,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useDeleteNewsFeedPostMutation } from "src/app/services/lifeApi/newsFeedApi";
import AlertToast from "src/Common/Toast/AlertToast";
import { hideNewsFeedPost } from "src/features/life/newsFeedSlice";
import useAuth from "src/hooks/useAuth";
import { deleteAlert } from "src/Projects/Job/utilities/functions";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import { PostModal } from "../../../ScroolabelNewsSection/PostModal/PostModal";
import StatusUpdateModal from "./StatusUpdateModal";
import PostReportModal from "./PostReportModal";

export const PostActions = ({ postActionsArg }) => {
  const { authUser } = useAuth();
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const dropRef = useRef(null);
  const { user_id, post_id, post } = postActionsArg || {};
  const {
    family_post_status,
    friend_post_status,
    professional_post_status,
    public_post_status,
  } = post || {};

  const [deletePost] = useDeleteNewsFeedPostMutation();

  const handlePostDelete = () => {
    deleteAlert().then((result) => {
      if (result.isConfirmed) {
        deletePost(post_id);
      }
    });
  };

  // post modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showToast, setShowToast] = useState(false);
  // status modal
  const [showStatus, setShowStatus] = useState(false);
  const handleStatusModalClose = () => setShowStatus(false);
  const handleStatusModalShow = () => setShowStatus(true);
  const [showReportModal, setShowReportModal] = useState(false);
  const statusUpdateData = {
    family_post_status,
    friend_post_status,
    professional_post_status,
    public_post_status,
    post_id,
  };

  const postModalArg = {
    activity: "updating",
    show: show,
    handleClose: handleClose,
    title: "Update Your Post",
    post_id: post_id,
    singlePostData: post,
  };
  const timeline_name = pathname?.split("/")[2]?.split("-")[0];

  const handleCopyLink = () => {
    let timeline_name = pathname?.split("/")[2];

    if (pathname.includes("post")) {
      if (pathname.includes("public-post")) {
        timeline_name = pathname?.split("/")[2];
      } else {
        const str_arr = search.split("?");
        timeline_name = str_arr[2]?.split("=")[1];
      }
    }

    const origin = window.location.origin;
    const copyText = `${origin}/life/post/${post_id}?user_id=${user_id}?timeline=${timeline_name}`;
    navigator.clipboard.writeText(copyText);
    setShowToast(true);
  };

  const handleHidePost = () => {
    dropRef.current.click();
    dispatch(hideNewsFeedPost({ post_id, timeline_name }));
  };

  const handleReport = () => setShowReportModal(true);

  return (
    <div className="MoreOption">
      <Dropdown>
        <Dropdown.Toggle
          tabIndex={0}
          aria-label="toggle button"
          className="ToggleDesignClear"
          ref={dropRef}
        >
          <ThreeDots className="info-text" size="20" />
        </Dropdown.Toggle>
        <Dropdown.Menu variant="dark">
          <div className="ClickThreeDot mb-3 px-3 pb-3 pt-3">
            <div
              className="btnHoverBorder box-shadow justify-content-start py-2 ps-3 pe-2 "
              tabIndex={0}
            >
              <div className="">
                <h6 className="mb-0">
                  <FontAwesomeIcon icon={faSave} className="me-2" /> Save Post
                </h6>
              </div>
            </div>
            {timeline_name !== "public" &&
              Number(user_id) !== Number(authUser.id) && (
                <div
                  className="btnHoverBorder box-shadow justify-content-start py-2 ps-3 pe-2 "
                  tabIndex={0}
                  onClick={handleHidePost}
                >
                  <div className="  ">
                    <h6 className="mb-0">
                      <FontAwesomeIcon icon={faEyeSlash} className="me-2" />
                      Hide Post
                    </h6>
                  </div>
                </div>
              )}
            {Number(user_id) !== Number(authUser.id) && (
              <div
                className="btnHoverBorder box-shadow justify-content-start py-2 ps-3 pe-2 "
                tabIndex={0}
                onClick={handleReport}
              >
                <div className="  ">
                  <h6 className="mb-0">
                    <FontAwesomeIcon icon={faBug} className="me-2" />
                    Report
                  </h6>
                </div>
              </div>
            )}

            {public_post_status === "active" && (
              <div
                className="btnHoverBorder box-shadow justify-content-start py-2 ps-3 pe-2 "
                tabIndex={0}
                onClick={handleCopyLink}
              >
                <div className="  ">
                  <h6 className="mb-0">
                    <FontAwesomeIcon icon={faLink} className="me-2" /> Copy Post
                    Link
                  </h6>
                </div>
              </div>
            )}
            {Number(user_id) === Number(authUser.id) && (
              <div
                className="  btnHoverBorder box-shadow justify-content-start py-2 ps-3 pe-2 mt-2 "
                tabIndex={0}
                onClick={handleShow}
              >
                <div className="  ">
                  <h6 className="mb-0">
                    <FontAwesomeIcon icon={faEdit} className="me-2" />
                    Edit Post
                  </h6>
                </div>
              </div>
            )}
            {Number(user_id) === Number(authUser.id) && (
              <div
                className=" btnHoverBorder box-shadow justify-content-start py-2 ps-3 pe-2 mt-2"
                tabIndex={0}
                onClick={handleStatusModalShow}
              >
                <div className="">
                  <h6 className="mb-0">
                    <FontAwesomeIcon icon={faUsersCog} className="me-2" />
                    Edit Audience
                  </h6>
                </div>
              </div>
            )}
            {Number(user_id) === Number(authUser.id) && (
              <div
                className="btnHoverBorder box-shadow justify-content-start py-2 ps-3 pe-2 mt-2"
                tabIndex={0}
                onClick={handlePostDelete}
              >
                <div>
                  <h6 className=" mb-0">
                    <FontAwesomeIcon icon={faTrash} className="me-2" />
                    Delete Post
                  </h6>
                </div>
              </div>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <PostModal postModalArg={postModalArg} />
      <StatusUpdateModal
        show={showStatus}
        handleClose={handleStatusModalClose}
        data={statusUpdateData}
      />

      <PostReportModal
        show={showReportModal}
        handleClose={() => setShowReportModal(false)}
        postId={post_id}
      ></PostReportModal>

      <AlertToast
        show={showToast}
        setShow={setShowToast}
        text="Post Link Copied"
      />
    </div>
  );
};
