import React, { useState } from "react";
// import VideoPostModal from '../Common/VideoPostModal';
import LiveVideoModal from "./LiveVideoModal";
import "./GoLive.css";
import NewsFeedVideoPost from "../VideoPost/NewsFeedVideoPost/NewsFeedVideoPost";
import styled from "styled-components";

const videos = [
  {
    url: "https://uviom-life-running.s3.ap-southeast-1.amazonaws.com/test/Advice+to+Young+People+And+His+Biggest+Regret+Jack+Ma+Goal+Quest.mp4",
    id: "naid-7",
  },
  {
    url: "https://uviom-life-running.s3.ap-southeast-1.amazonaws.com/test/The+Pursuit+of+Happyness+-88-+Movie+CLIP+-+Final+Scene+Chris+is+Hired+-2006-+HD.mp4",
    id: "naid-5",
  },
  {
    url: "https://uviom-life-running.s3.ap-southeast-1.amazonaws.com/test/Best+Interview+ever-+The+Pursuit+Of+Happyness+Job+Interview.+-Motivational-.mp4",
    id: "naid-6",
  },
];

const GoLive = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Wrapper>
      {/* <div>
        <button className="go__live-btn" onClick={handleShow}>
          Go Live
        </button>
        {videos.map((item) => (
          <NewsFeedVideoPost url={item.url} videoId={item.id} key={item.id} />
        ))}
        <LiveVideoModal show={show} handleClose={handleClose}></LiveVideoModal>
      </div> */}
      <div className="text-center">
        <img
          src="https://res.cloudinary.com/yex/image/upload/v1668081554/UVIOM/Maintenance-bro_1_o3uvin.svg"
          alt="maintenance"
        />
      </div>

      <h4>
        Coming Soon
        <br />
        Features Is Under Development
      </h4>
    </Wrapper>
  );
};

export default GoLive;

const Wrapper = styled.div`
  & h4 {
    margin-top: 2rem;
    font-size: 1.2rem;
    text-align: center;
  }
`;
