import React from 'react';
import { Container } from 'react-bootstrap';
import HomeBanner from './HomeBanner/HomeBanner';
import HomePopularFood from './HomePopularFood/HomePopularFood';
import HomeRestaurant from './HomeRestaurant/HomeRestaurant';

const FoodHome = () => {
    return (
        <Container fluid className='p-0 m-0 overflow-hidden'>
            <HomeBanner />
            <HomePopularFood />
            <HomeRestaurant/>
        </Container>
    );
};

export default FoodHome;