import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useStore from "src/hooks/useStore";
import SinglePhotoModal from "src/Projects/Life/LifePages/SharedComponent/SinglePhotoModal/SinglePhotoModal";
import "./CoverAndProfile.css";
import { ProfileEditModal } from "./ProfileEditModal";
import { isEmpty } from "lodash";
import { mainCoverPUrl, mainProPUrl } from "src/Projects/Life/Common/CommonUrl";
import { Container } from "react-bootstrap";

const CoverAndProfile = ({ coverAndProfileArg }) => {
  const { info, getData } = coverAndProfileArg;
  const { getPictures, getDefaultCovers, defaultCover } = useStore();
  const [userPictures, setuserPictures] = useState({ profile: "", cover: "" });

  const [show, setShow] = useState(false);
  const [showProfileEditModal, setProfileEditModal] = useState(false);
  const handleCloseProfileEditModal = () => setProfileEditModal(false);
  const [uploadStatus, setuploadStatus] = useState("");
  const location = useLocation();
  const [access_token] = useState(localStorage.getItem("access_token"));

  useEffect(() => {
    if (access_token) {
      const getDefaultCoversArg = {
        location: location,
      };
      getDefaultCovers(getDefaultCoversArg);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(info)) {
      setuserPictures({
        profile:
          info.users_info && info.users_info.photo
            ? `${mainProPUrl}/${info.users_info.photo}`
            : "",
        cover:
          info.image_info && info.image_info.cover_photo
            ? `${mainCoverPUrl}/${info.image_info.cover_photo}`
            : "",
      });
    }
  }, [info]);

  return (
    <div className="overflow-hidden mb-3">
      <div className="life__cover__gradient">
        <Container fluid="xxl">
          <div className="coverAndProfileSection coverPic">
            {userPictures.cover !== "" ? (
              <img
                className="img-fluid cover"
                src={userPictures.cover}
                alt="cover"
              />
            ) : (
              <img className="img-fluid cover" src={defaultCover} alt="cover" />
            )}
            {/* <div className="coverEditIcon roundBackground" onClick={() => handleShowProfileEditModal("cover")}>
        <FontAwesomeIcon icon={faPencilAlt} />
      </div> */}
            <div className="ProfileSection ">
              <div className="position-relative">
                {userPictures.profile !== "" ? (
                  <img
                    className="img-fluid"
                    src={userPictures.profile}
                    alt="user"
                    onClick={() => setShow(true)}
                  />
                ) : (
                  <img
                    className="img-fluid"
                    src="/user.png"
                    alt="user"
                    onClick={() => setShow(true)}
                  />
                )}
              </div>
            </div>
            <ProfileEditModal
              showProfileEditModal={showProfileEditModal}
              handleCloseProfileEditModal={handleCloseProfileEditModal}
              ExistingPicture={userPictures}
              getPictures={getPictures}
              uploadStatus={uploadStatus}
            />
            <SinglePhotoModal
              photo={
                userPictures.profile !== "" ? userPictures.profile : "user.png"
              }
              setShow={setShow}
              show={show}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CoverAndProfile;
