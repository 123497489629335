import React, { createContext } from "react";
import useThemeGlobal from "src/hooks/useThemeGlobal";


export const ThemeContext = createContext();
const ThemeProvider = ({ children }) => {
  return (
    <ThemeContext.Provider value={useThemeGlobal()}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;