import React from "react";
import CartModalItem from "../../Components/Common/CartModal/CartModalItem";
import useCartStore from "../../hooks/useCartStore";

export const CartItemsBox = () => {
  const { handleRemove, state } = useCartStore();
  const { cartItems } = state;

  return (
    <>
      {cartItems.length > 0 ? (
        cartItems.map((item) => (
          <CartModalItem
            key={item.id}
            data={item}
            handleRemove={handleRemove}
          />
        ))
      ) : (
        <h6 className="text-center mt-4">Your Cart is Empty</h6>
      )}
    </>
  );
};
