
export default function processToArray(data) {
    const postList = data.data;

    if (typeof postList == 'object' && postList != null) {
        return Object.values(postList);
    }
    
    return postList;
    

}



