import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import order_steps from "src/Projects/Food/utilities/orderStepData";
import OrderSuccessModal from "../../OrderSuccessModal/OrderSuccessModal";
import "./manage_order.css";

const ManageOrder = () => {
  const [currentStatus, setCurrentStatus] = useState("preparing");
  const { pathname } = useLocation();
  const currentPath = "/" + pathname.split("/").slice(1, 3).join("/");
  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const handleQRCodeModalClose = () => setShowQRCodeModal(false);

  useEffect(() => {
    setCurrentStatus("preparing");
  }, []);

  return (
    <div className="p-3 secondary-bg primary-text manage-order">
      <h5 className="fs-6 py-3">Order Details</h5>
      <button
        onClick={() => setShowQRCodeModal(true)}
        className="btn-0 font-14 mb-1 fw-bold ps-0 primary-text"
      >
        <u>View QR Code And OTP</u>
      </button>
      <p>Order ID: #220610001</p>
      <p>Order Date : 19 Jun 2022</p>
      <p>Total: 4000</p>

      <div className="step-wizard food-step-wizard responsive-step">
        <ul className="step-wizard-list text-black">
          {order_steps.map((step) => (
            <li
              key={step.count}
              className={`step-wizard-item  ${
                currentStatus === step.status ? "current-item" : ""
              }`}
            >
              <span className="progress-count"></span>
              <span className="progress-label bg-white p-2 rounded-2">
                <span className="mb-2 d-inline-block">{step.name}</span> <br />
                <img src={step.icon} className="img img-fluid" alt="" />
              </span>
            </li>
          ))}
        </ul>
      </div>
      <br />

      <Row className="g-3 pb-3">
        <Col xl="6">
          <div className="favorite-card p-0 m-0">
            <div className="cart-item-img-box">
              <img
                className="img img-fluid"
                src="/food-image/food1.png"
                alt=""
              />
            </div>
            <div
              style={{ height: "100%" }}
              className="cart-text-box align-items-start d-flex flex-column justify-content-between"
            >
              <p className="font-14 fw-bold">ZamZam Restuant (Mirpur-11)</p>
              <p className="mb-1">
                <small>
                  {"steak frites, (French: “steak [and] fries”) a simple dish of beef steak.".substring(
                    0,
                    60
                  )}
                  ...
                </small>
              </p>
              <p>Quantity: 1</p>
            </div>
          </div>
        </Col>
        <Col xl="6">
          <div className="d-flex align-items-center h-100">
            <div className="flex-grow-1">
              <p className="mb-1">
                <span>Estimate delivery Time</span> <br />{" "}
                <span>
                  <FontAwesomeIcon className="text-orange" icon={faClock} />{" "}
                  30-40 Min
                </span>
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center flex-grow-1 flex-column ">
              <Link
                to={`${currentPath}/order-cancellation/100`}
                className=" send-code-btn cart-confirm-btn manage-order-btn py-1 text-dark"
              >
                Cancel
              </Link>
              <small className="mt-2 font-12">
                *Order Cancel before confirm
              </small>
            </div>
          </div>
        </Col>
      </Row>

      <div className="food-google-map">
        <iframe
          title="google map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d58408.26472408621!2d90.38425252154123!3d23.80022418648587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1659330700243!5m2!1sen!2sbd"
          width="100%"
          height="auto"
          style={{ aspectRatio: "16/9" }}
        ></iframe>
      </div>

      <OrderSuccessModal
        show={showQRCodeModal}
        handleClose={handleQRCodeModalClose}
      />
    </div>
  );
};

export default ManageOrder;
