import React from "react";
import { Form, Row } from "react-bootstrap";
import FileAndTextInputBox from "src/Common/FileAndTextInputBox/FileAndTextInputBox";
import InputSelectWithLabel from "src/Common/InputSelectWithLabel/InputSelectWithLabel";
import InputPhoneNumber from "src/Common/InputWithLabel/InputPhoneNumber";
import TextInputBox from "src/Common/TextInputBox/TextInputBox";

const PersonalInfo = () => {
  return (
    <Form>
      <br />
      <Row xs={1} xl={2}>
        <TextInputBox
          label={"Name On Business"}
          placeholder={"Name On Business"}
        ></TextInputBox>

        <TextInputBox
          placeholder={"Permanent Address"}
          label={"Permanent Address"}
        ></TextInputBox>
        <TextInputBox
          placeholder={"Mailing Address"}
          label={"Mailing Address"}
        ></TextInputBox>
        <FileAndTextInputBox
          label={"NID Card Number"}
          placeholder="NID Card Number"
        ></FileAndTextInputBox>

        <InputPhoneNumber
          className={["store-reg-phone-input", "store-reg-phone-label"]}
          label="Phone Number"
          checkoutInput
          small
        ></InputPhoneNumber>

        <TextInputBox
          label={"E-Mail Address"}
          placeholder={"E-Mail Address"}
          checkoutInput
          className={["", "seller-reg-input-label"]}
        ></TextInputBox>

        <InputSelectWithLabel label={"Payment Get way"}>
          <option value="">Payment Get way </option>
          <option value="1">Payment Get way 1</option>
          <option value="3">Payment Get way 2</option>
          <option value="2">Payment Get way 3</option>
        </InputSelectWithLabel>

        <FileAndTextInputBox
          label={"UVIOM ID / RQ Code"}
          placeholder="UVIOM ID / RQ Code"
          singleFile
          filePlaceholder="optional"
        ></FileAndTextInputBox>
      </Row>
      <br />

      <div className="d-md-flex justify-content-center cancellation-check">
        <Form.Check
          type="checkbox"
          label={
            <small>
              I have read and agree to @ UVIOM Corporate Terms and conditions.
            </small>
          }
        />
        <button className="eco-btn"> View TC</button>
      </div>
      <br />
      <div className="text-center">
        <button
          type="submit"
          className=" send-code-btn cart-confirm-btn text-dark"
        >
          Submit
        </button>
      </div>
      <br />
    </Form>
  );
};

export default PersonalInfo;
