import { faFileDownload, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col } from "react-bootstrap";
import "./common.css";

const CompanySingleInfo = ({
  title = "",
  value = "",
  handleEditButton = () => {},
  editBtnName = "",
  showEditBtn = false,
  showPreviewBtn = false,
  previewLink = "",
  isLink,
}) => {
  return (
    <div className="d-flex row   justify-content-between update-company-profile ms-0 mb-0 mt-0 mb-md-1 mt-md-1">
      <Col
        xs={showEditBtn || showPreviewBtn ? "11" : "12"}
        className=" d-flex flex-column  flex-sm-row  updateProfileRow flex-row"
      >
        <p className="profielItemName mb-0 fw-bold">{title} : </p>
        {isLink ? (
          <p className="profileItemValue font-14">
            <a
              className="profile-info-link"
              href={`http://${value}`}
              target="_blank"
              rel="noreferrer"
            >
              {value.substring(0, 35)}
            </a>
          </p>
        ) : (
          <p className="profileItemValue">{value}</p>
        )}
      </Col>
      {showEditBtn && (
        <Col
          xs="1"
          className="d-flex justify-content-center align-items-center "
        >
          <button
            className="ms-auto"
            type="button"
            name={editBtnName}
            onClick={() => handleEditButton(editBtnName)}
          >
            <FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon>
          </button>
        </Col>
      )}
      {showPreviewBtn && (
        <Col
          xs="1"
          className="d-flex justify-content-center align-items-center"
        >
          <a
            href={previewLink}
            download
            target="_blank"
            rel="noreferrer"
            className="pdfPreviewBtn"
          >
            <FontAwesomeIcon size="lg" icon={faFileDownload}></FontAwesomeIcon>
          </a>
        </Col>
      )}
    </div>
  );
};

export default CompanySingleInfo;
