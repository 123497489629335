import React from "react";
import { useState } from "react";
import useAuth from "src/hooks/useAuth";
import {
  ShopBannerImgBase,
  ShopLogoBaseM,
  shopReportUrl,
} from "../../../utilities/CommonUrl";
import ReportModal from "../../ReportModal/ReportModal";
import { Star } from "./Star";

const Banner = (props) => {
  const {
    store_name,
    permanent_address,
    vendor_image,
    email_address,
    vendor_id,
  } = props.data;
  const { banner_image, logo_image } = vendor_image;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { authUser } = useAuth();

  return (
    <>
      <div
        className="store__banner-main d-flex align-items-end justify-content-end"
        style={
          banner_image
            ? { backgroundImage: `url(${ShopBannerImgBase}/${banner_image})` }
            : { backgroundImage: `url("/corporate/banner1.jpg")` }
        }
      >
        <div className="text-end store__item__text-box text-white flex-1">
          <h4 className="text-ellipsis">{store_name && store_name}</h4>
          <p className="text-ellipsis">
            {permanent_address && permanent_address}
          </p>
          <small> Mail us- {email_address} </small>
          <Star />
          <button
            className="btn-0 text-dark mb-0 px-3 rounded font-14 orange-bg"
            onClick={() => setShow(true)}
            style={{ border: "1px solid var(--text-success)" }}
          >
            Report
          </button>
        </div>
        <div>
          <img
            src={`${ShopLogoBaseM}/${logo_image}`}
            className="store__item-logo"
            alt=""
          />
        </div>

        <ReportModal
          show={show}
          handleClose={handleClose}
          title={"Shop Report"}
          id={vendor_id}
          fieldName="vendor_id"
          user_id={authUser.id}
          url={shopReportUrl}
        />
      </div>
    </>
  );
};

export default Banner;
