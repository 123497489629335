import { Link, useLocation } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import trackProduct from "./../../assets/find-product-icon.svg";

import "./trackingPopup.css";

function TrackingPopup(props) {
  const { pathname:url } = useLocation();
  const dynamicPath = url.split("/")[1];

  return (
    <div className="trackingPopup">
      <Modal
        className="trackingPopup__content"
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <img
          className="dark-invert trackingPopup__img"
          src={trackProduct}
          alt=""
        />
        <h4 className="trackingPopup__title">Track Your Express</h4>
        <div className="trackingPopup__input">
          <select>
            <option>Track By</option>
            <option>Number</option>
            <option>Track Id</option>
          </select>
          <input type="text" placeholder="Tracking Number" name="" id="" />
        </div>

        <div className="text-center mb-3">
          <Button
            as={Link}
            to={`/${dynamicPath}/trackhistory`}
            className="btn__ride px-5"
            onClick={props.onHide}
          >
            Track Product
          </Button>
        </div>
      </Modal>
    </div>
  );
}
export default TrackingPopup;
