import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useStoreReportMutation } from "src/app/services/ubizzApi/ubizzApi";

const ReportModal = ({
  show,
  handleClose,
  url = "",
  fieldName,
  id,
  user_id,
  title = "Report",
}) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [storeRating, { isLoading, isSuccess, data }] =
    useStoreReportMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!message) {
      setError("This field is required");
    } else {
      setError("");
      const data = {
        message: message,
        user_id: user_id,
      };

      data[fieldName] = id;
      storeRating({ url, data });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      setMessage("");
    }

    if (error) {
      setError(error?.error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, error]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      className="rating-modal corporate-rating-modal"
    >
      <Modal.Header className="border-0">
        <Modal.Title as={"h5"}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Control
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            as="textarea"
            className="border-gray"
            rows={4}
          />
        </Form>
        <p className="text-danger text-end font-14 mt-2 mb-0">{error}</p>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <button
          onClick={handleClose}
          className="send-code-btn  manage-order-btn py-1 primary-text"
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="send-code-btn cart-confirm-btn manage-order-btn py-1 text-dark"
          disabled={isLoading}
        >
          {isLoading ? (
            <span className="text-dark">
              <FontAwesomeIcon icon={faSpinner} className="me-2" spin />{" "}
              Submit...
            </span>
          ) : (
            "Submit"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportModal;
