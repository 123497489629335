import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDotCircle,
  faMapMarkerAlt,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";
import scooterIcon from "./../../assets/scooter-icon.svg";

import "./paymentBox.css";

function Payment() {
  return (
    <div className="paymentBox">
      <div className="paymentBox__location--container">
        <div className="paymentBox__location--icon">
          <img src={scooterIcon} alt="" />
        </div>
        <div className="paymentBox__location">
          <span>
            <FontAwesomeIcon
              className="rideHistoryCard__icon"
              icon={faDotCircle}
            />
            Mirpur-1 Bus Stand
          </span>
          <span className="d-none d-sm-block">
            <FontAwesomeIcon icon={faLongArrowAltRight} />
          </span>
          <span>
             <FontAwesomeIcon
              className="rideHistoryCard__icon"
              icon={faMapMarkerAlt}
            />{" "}
            Dhanmondi 32
          </span>
        </div>
      </div>
      <p className="my-3">
        Total Distance: 15 Km
        <br />
        Estimate time: 55 min
        <br />
        Fare Estimate: $300
      </p>

      <div className="my-5 border p-4">
        <h3 className="heading-3 text-center mb-3">Payment Option</h3>

        <Form.Select>
          <option value="cash">Cash</option>
          <option value="cash">Bkash</option>
          <option value="cash">Nogod</option>
          <option value="cash">Rocket</option>
        </Form.Select>
        <p className="pt-2 pe-pointer" role="button">
          + Add Payment Method
        </p>
      </div>

      <div className="text-end mt-3">
        <Button className="btn__ride">Confirm</Button>
      </div>
    </div>
  );
}
export default Payment;
