import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./common.css";

const JobCategorySelectBox = ({
  value,
  name,
  label,
  handler = () => {},
  className = ["", ""],
  checkoutInput = false,
  disabled = false,
  children,
  required=true
}) => {
  return (
    <Form.Group
      as={Row}
      className={`mb-3 ${checkoutInput && "mx-md-1"}`}
      controlId=""
    >
      <Form.Label
        className={`d-none d-md-block InputWithLabelLabel ${className[1]} ${
          checkoutInput && "text-start"
        }`}
        column
        sm="3"
      >
        {label}
      </Form.Label>
      <Col md="9">
        <Form.Label
          className={"d-block d-md-none "}
          style={{ fontSize: "0.9rem" }}
        >
          {label}
        </Form.Label>
        <Form.Select
          required = {required}
          onChange={handler}
          value={value}
          name={name}
          aria-label="payment method"
          className={`payment-method-input delivery-charge  ${
            className[0]
          } ${checkoutInput && "checkout-input"}`}
        >
          {children}
        </Form.Select>
      </Col>
    </Form.Group>
  );
};

export default JobCategorySelectBox;
