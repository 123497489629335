import { useState } from "react";
import { Modal, Button, CloseButton } from "react-bootstrap";

import "./addFundOTPModal.css";

function AddFundOTPModal(props) {
  const [showOTP, setShowOTP] = useState(false);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="creative__modal__header">
        <h6 className="mb-0">Add Balance</h6>
        <CloseButton
          onClick={() => {
            props.onHide();
            setShowOTP(false);
          }}
        />
      </Modal.Header>
      <Modal.Body className="creative__modal__body">
        <div className="d-flex justify-content-center">
          <div className="creative__modal__bankInfo">
            <span className="fw-500">Account Type</span>
            <span>Bank Of America</span>

            <span className="fw-500">Account Name</span>
            <span>UVIOM Inc</span>

            <span className="fw-500">Account Number</span>
            <span>1596-3596-0059</span>

            <span className="fw-500">Amount</span>
            <span>$5000.00</span>
          </div>
        </div>

        {!showOTP && (
          <div className="my-4 text-center">
            <Button className="btn__creative" onClick={() => setShowOTP(true)}>
              Continue
            </Button>
          </div>
        )}

        {showOTP && (
          <>
            <h5 className="text-center mt-4 mb-3">OTP</h5>
            <div className="otp__input">
              <input type="text" className="input-reset" placeholder="0" name="" id="" />
              <input type="text" className="input-reset" placeholder="5" name="" id="" />
              <input type="text" className="input-reset" placeholder="4" name="" id="" />
              <input type="text" className="input-reset" placeholder="7" name="" id="" />
              <input type="text" className="input-reset" placeholder="6" name="" id="" />
              <input type="text" className="input-reset" placeholder="9" name="" id="" />
            </div>
            <div className="py-3 mt-2 text-center">
              <Button
                className="btn__creative"
                onClick={() => {
                  setShowOTP(false);
                  props.onHide();
                  props.onSuccess();
                }}
              >
                Confirm
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
export default AddFundOTPModal;
