import React from "react";

export const Ubiz = ({ size = "", className = "", fillcolor }) => {
  return (
    <svg
      width={size ? size : "48"}
      height={size ? size : "29"}
      viewBox="0 0 48 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.3238 21.8218C35.4351 22.1902 34.5868 22.0868 33.682 21.4825C33.9663 22.8026 33.9696 23.9934 32.7125 24.706C31.5264 25.3782 30.4293 24.9355 29.3192 24.1906C29.3725 25.278 29.3483 26.2605 28.3772 26.8325C27.4061 27.4045 26.519 27.1653 25.5689 26.4802C25.6933 27.6371 25.5269 28.6002 24.2875 28.9185C23.3051 29.1722 22.4536 28.8393 21.72 27.8407C22.4519 27.1443 23.2065 26.4269 23.9579 25.7046C24.21 25.4622 24.4604 25.2199 24.6963 24.9581C25.5721 23.9999 26.0439 22.9092 25.5042 21.6376C24.9646 20.3659 23.8884 19.6986 22.5133 19.6646C21.9623 19.6501 21.7345 19.4901 21.5164 19.0038C20.7343 17.2587 19.8101 16.8483 17.5576 17.1989C17.0195 15.2728 15.7414 14.452 13.7943 14.6136C13.56 13.434 12.9864 12.5566 11.844 12.1187C10.4932 11.6 9.2587 11.8666 8.23911 12.9105C7.81092 13.3532 7.35525 13.7701 6.92221 14.2031C6.48917 14.6362 6.06905 15.0902 5.62147 15.5588L0 11.4578C2.00686 7.62025 3.98463 3.83598 5.98826 0C6.75416 0.355482 7.47483 0.698038 8.20195 1.02767C10.136 1.94659 12.3432 2.11136 14.3922 1.48979C16.3118 0.937181 18.3445 0.746513 20.3401 0.50737C21.156 0.408805 21.9947 0.630175 22.6022 1.46071C21.644 2.35265 20.7036 3.23004 19.76 4.10421C18.6143 5.16581 17.4526 6.20478 16.3231 7.28739C14.7461 8.79334 15.0595 10.6629 17.1052 11.7455C19.1508 12.8281 21.2401 12.6923 23.2065 11.5839C24.8224 10.6758 26.3202 9.58995 27.9377 8.53966C28.8264 9.31849 29.8056 10.1555 30.7622 11.0151C32.9166 12.9541 35.0624 14.9001 37.1996 16.8531C38.998 18.4883 38.5795 20.8846 36.3238 21.8218Z"
        fill="#F1A350"
      />
      <path
        d="M47.4979 11.5282L40.0053 17.2628L35.1691 12.9534C33.1817 11.1835 31.1942 9.41528 29.2067 7.64864C28.2146 6.77124 27.5505 6.71469 26.4598 7.44343C25.1591 8.30628 23.8745 9.19983 22.5818 10.0659C21.6123 10.7122 20.5653 11.0904 19.3922 11.0096C19.0165 11.0019 18.6457 10.9223 18.2999 10.7753C16.7551 10.0061 16.655 9.26123 17.8943 8.05583C20.0741 5.93586 22.2748 3.8369 24.4319 1.69592C25.4596 0.676335 26.6343 0.316007 28.0352 0.589082C30.2005 1.01081 32.3802 1.35822 34.5244 1.8672C36.2485 2.27601 37.832 2.00939 39.3719 1.26934C40.1443 0.899319 40.8875 0.46143 41.6971 0.0348511L47.4979 11.5282Z"
        fill="#F1A350"
      />
      <path
        d="M20.5667 20.523C20.473 20.6846 20.3453 21.0563 20.0997 21.3116C18.736 22.726 17.3539 24.1231 15.9535 25.503C15.27 26.1752 14.4201 26.096 13.672 25.3528C12.9238 24.6095 12.8479 23.7531 13.4991 23.068C14.7917 21.701 16.128 20.3663 17.4239 18.9977C17.9087 18.479 18.4338 18.2076 19.1416 18.4936C19.542 18.6691 19.8867 18.9511 20.138 19.309C20.3893 19.6668 20.5376 20.0868 20.5667 20.523Z"
        fill="#F1A350"
      />
      <path
        d="M16.3849 18.0893C16.254 18.3059 16.115 18.6646 15.871 18.9183C14.5299 20.309 13.1726 21.6846 11.7992 23.0451C11.0769 23.7609 10.2383 23.7076 9.46105 22.9498C8.68384 22.1919 8.62244 21.3549 9.32533 20.6181C10.6034 19.2786 11.9106 17.9714 13.1839 16.6222C13.6945 16.0793 14.2277 15.7755 14.9613 16.1083C15.7418 16.4687 16.2217 17.0762 16.3849 18.0893Z"
        fill="#F1A350"
      />
      <path
        d="M7.16792 20.5924C6.10794 20.5068 5.55209 20.0576 5.19338 19.3272C4.87021 18.6534 5.05926 18.0927 5.56502 17.5773C6.88353 16.2378 8.21498 14.9095 9.48664 13.5264C10.3059 12.6345 11.1703 12.9786 11.8716 13.6072C12.6795 14.3375 12.6892 15.3167 11.9685 16.0487C10.6872 17.3527 9.41392 18.6647 8.10995 19.9477C7.80294 20.2547 7.38929 20.4438 7.16792 20.5924Z"
        fill="#F1A350"
      />
      <path
        d="M24.5055 23.0012C24.3843 23.208 24.255 23.57 24.0094 23.8221C22.6457 25.2224 21.2652 26.6126 19.868 27.9925C19.212 28.6388 18.3976 28.5839 17.6544 27.8988C16.9111 27.2137 16.7463 26.283 17.3651 25.6205C18.7547 24.1323 20.1944 22.6894 21.6196 21.2238C22.0494 20.7843 22.5649 20.7391 23.0997 20.9992C23.8688 21.3854 24.3811 21.9622 24.5055 23.0012Z"
        fill="#F1A350"
      />
    </svg>
  );
};
