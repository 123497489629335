import React from "react";
import { Link } from "react-router-dom";
import MoreCare from "src/DashBord/Icon/MoreCare";
import MoreConnect from "src/DashBord/Icon/MoreConnect";
import MoreLookMe from "src/DashBord/Icon/MoreLookMe";
import MoreWallet from "src/DashBord/Icon/MoreWallet";
import AppleIcon from "src/Projects/Food/assets/icons/AppleIcon";
import DesktopIcon from "src/Projects/Food/assets/icons/DesktopIcon";
import PhoneIcon from "src/Projects/Food/assets/icons/PhoneIcon";
import PlayStoreIcon from "src/Projects/Food/assets/icons/PlayStoreIcon";
import { SocialLinks } from "src/Projects/Life/LifePages/LoginPage/Shared/SocialLinks";

const CorporateFooter = () => {
  return (
    <footer className="footer-section px-md-5">
      <div className="container-fluid">
        <div className="footer-cta pt-4 pb-3 row  mb-30">
          <div className="col-6 col-lg-3 col-sm-6">
            <div className="single-cta">
              <div className="FooterBlock ps-0">
                <h6 className="fw-600">UVIOM</h6>
                <div>
                  <Link to="/about">About</Link>
                </div>
                <div>
                  <Link to="/contact">Contact</Link>
                </div>
                <div>
                  <Link to="/privacy">Privacy/Policy</Link>
                </div>
                <div>
                  <Link to="/dashboard">Dashboard</Link>
                </div>
                <div>
                  <Link to="/">My Account</Link>
                </div>
                <div>
                  <Link to="/">Join With UVIOM</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-sm-6">
            <div className="single-cta">
              <div className="FooterBlock">
                <h6 className="fw-600">UVIOM SERVICE</h6>
                <div>
                  <Link to="/ubizz">Business/E-Commerce</Link>
                </div>
                <div>
                  <Link to="/food">Food/Food Delivery</Link>
                </div>
                <div>
                  <Link to="/ride">Ride/Ride Share</Link>
                </div>
                <div>
                  <Link to="/express">Express/Currier Service</Link>
                </div>
                <div>
                  <Link to="/">Connect/Coming Soon</Link>
                </div>
                <div>
                  <Link to="/">Investment Plan/Coming Soon</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 col-lg-3 col-sm-6 mt-3 mt-lg-0 ">
            <div className="single-cta">
              <div className="FooterBlock">
                <h6 className="fw-600">UVIOM PRODUCTS</h6>
                <div>
                  <Link to="/life/public-post">Life/Social Media </Link>
                </div>
                <div>
                  <Link to="/view">View/Video Sharing</Link>
                </div>
                <div>
                  <Link to="/job">Job/Job Market</Link>
                </div>
                <div>
                  <Link to="/creative">Creative/Freelancing</Link>
                </div>
                <div>
                  <Link to="/">Care/Non Profit Organization</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 col-lg-3 col-sm-6 d-flex justify-content-lg-center mt-3 mt-lg-0 ">
            <div className="single-cta">
              <div className="FooterBlock">
                <h6 className="fw-600">Get UVIOM</h6>
                <AppleIcon size="1.7rem" className="text-orange" />
                <span className="px-2">
                  <PlayStoreIcon size="1.65rem" className="text-orange " />
                </span>

                <div className="mt-3">
                  <h6 className="fw-600">View More</h6>
                  <div className="d-flex gap-2 mb-2">
                    <MoreLookMe size="2rem" />
                    <MoreConnect size="2rem" />
                  </div>

                  <div className="d-flex gap-2">
                    <MoreCare size="2rem" />
                    <MoreWallet size="2rem" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-md-0 py-3">
        <div className="row align-items-center ">
          <div className="col-md-5 text-right">
            <div className="footer-menu">
              <SocialLinks />
            </div>
          </div>
          <div className="col-md-7 text-center text-md-end">
            <small className="primary-text">
              Ⓒ UVIOM INC,2022. All Right Resolved. Developed By{" "}
              <Link to="/" className="text-orange">
                UVIOM
              </Link>
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default CorporateFooter;
