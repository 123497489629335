/* eslint-disable react-hooks/exhaustive-deps */
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleUserChat } from "src/features/life/messengerSlice";
import SingleMessage from "../Common/SingleMessage";

export const MessageList = () => {
  const [endMessage, setEndMessage] = useState(false);
  const dispatch = useDispatch();
  const first_messageRef = createRef(null);

  const {
    current_chatting_messages: messages,
    current_chatting_user,
    messageLoading,
    current_chatting_hasMore: hasMore,
  } = useSelector((state) => state.messengerData);

  const handleLoadMore = () => {
    dispatch(
      getSingleUserChat({ user_id: current_chatting_user.id, add_old: true })
    );
  };

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setEndMessage(entry?.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    if (first_messageRef.current) {
      observer.observe(first_messageRef?.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [first_messageRef, observer]);

  useEffect(() => {
    if (endMessage && hasMore) {
      handleLoadMore();
    }
  }, [endMessage]);

  return (
    <>
      {messageLoading && (
        <h2 className="text-center  message__loader-spinner">
          <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon>
        </h2>
      )}
      {messages &&
        messages.map((item, index, allMessages) => {
          return (
            <SingleMessage
              ref={{
                first_ref: index === 5 ? first_messageRef : null,
              }}
              prev_created_at={allMessages[index - 1]?.created_at}
              chatData={item}
              key={item?.id}
              targetedUserInfo={current_chatting_user}
            />
          );
        })}
    </>
  );
};
