
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";


// set base url and auth token 
const baseQuery = fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: (headers) => {
        const token = localStorage.getItem("access_token");
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        headers.set("Content-Type", "application/json");
        return headers;
    }
})



const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 });
const tagList = ["JobHomePosts", "HomeCompanies", "SaveJobPost", "AppliedJobPost", "CompanyJobPost", "CategoryJobPost", "divisionJobPost", "CompanyPostedJob", ];


export const api = createApi({
    reducerPath: "fetch_api",
    baseQuery: baseQueryWithRetry,
    tagTypes: tagList,
    endpoints: () =>({}),
})