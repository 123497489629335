import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { PostApiCalls } from "src/Common/apiCall/PostApiCalls";
import { InputField } from "../Components/InputField";

export const StoringInputs = ({ storingInputsArg }) => {
  const {
    isLoading,
    setIsLoading,
    setIsEditableFalse,
    dataReload,
    componentMainUrl,
    formData,
    inputHandleChange,
  } = storingInputsArg;

  // console.log("edit id ", formData && formData.id);
  const updateFormFunc = (e) => {
    e.preventDefault();
    const formArg = {
      formData: formData,
      Url: componentMainUrl,
      setIsLoading: setIsLoading,
      dataReload: dataReload,
      setIsEditableFalse: setIsEditableFalse,
    };
    PostApiCalls(formArg);
  };

  return (
    <form onSubmit={updateFormFunc}>
      {/* <InputField
        inputPlaceholder="relationship"
        textInputName="relationship_status"
        textInputValue={
          formData.relationship_status ? formData.relationship_status : ""
        }
        changeHandle={inputHandleChange}
      /> */}
      <div className="container-fluid mainProfileSelect">
        <Row>
          <Col className="basicInfoSelectCol">
            <Form.Select
              aria-label="gender select"
              id="gender"
              className="mainProfileInput m-0"
              name="relationship_status"
              value={
                formData.relationship_status ? formData.relationship_status : ""
              }
              onChange={inputHandleChange}
            >
              <option className="selectPlaceholder">relationship</option>
              <option value="Single">Single</option>
              <option value="Engaged">Engaged</option>
              <option value="Married">Married</option>
              <option value="It's Complicated">It's Complicated</option>
              <option value="Separated">Separated</option>
              <option value="Divoced">Divoced</option>
            </Form.Select>
            <label htmlFor="gender" className="selectDownArrow">
              <FontAwesomeIcon
                className="ms-2 text-white"
                size="lg"
                icon={faPlusCircle}
              ></FontAwesomeIcon>
            </label>
          </Col>
        </Row>
      </div>

      <div className="py-3 mainProfileBtnDiv">
        <button
          onClick={setIsEditableFalse}
          className="mainProfileBtn btnCandel me-3"
        >
          Cancel
        </button>
        <button
          type="submit"
          className={`mainProfileBtn btnSave  ${isLoading ? "disabled" : ""} `}
        >
          Save
        </button>
      </div>
    </form>
  );
};
