/* eslint-disable eqeqeq */
import { createSlice } from "@reduxjs/toolkit";
import { personalPicturesTempBase as base_url } from "src/Projects/Life/Common/CommonUrl";
import {
  family_default_profile,
  friend_default_profile,
  professional_default_profile,
  public_default_profile,
} from "src/Projects/Life/utils/life_utilities";

const initialState = {
  search_result: [],
  suggest_family_friends: [],
  suggest_friend_friends: [],
  suggest_professional_friends: [],
  family_profile: family_default_profile,
  friends_profile: friend_default_profile,
  professional_profile: professional_default_profile,
  public_profile: public_default_profile,
};

const lifeCommonDataSlice = createSlice({
  name: "life_common_data",
  initialState,

  reducers: {
    addSearchResult: (state, action) => {
      const data = action.payload;
      state.search_result = data;
    },

    destroySearchResult: (state) => {
      state.search_result = [];
    },

    setProfileImages: (state, action) => {
      const { timeline_name, data } = action.payload;
      state[`${timeline_name}_profile`] = data;
    },
    destroyProfilePhoto: (state) => {
      state.family_profile = family_default_profile;
      state.friends_profile = friend_default_profile;
      state.professional_profile = professional_default_profile;
      state.public_profile = public_default_profile;
    },

    updateProfilePhoto: (state, action) => {
      const { timeline, data, type } = action.payload;
      const { image_location, image_name } = data;
      let img_url = `${base_url}/${image_location + image_name}`;
      if (timeline === "public" && type === "profile")
        img_url = `${base_url}/${image_location}/${image_name}`;
      state[`${timeline}_profile`][type] = img_url;
    },
  },
});

export default lifeCommonDataSlice.reducer;

export const {
  addSearchResult,
  destroySearchResult,
  setProfileImages,
  updateProfilePhoto,
  destroyProfilePhoto,
} = lifeCommonDataSlice.actions;
