import { Container, Button } from "react-bootstrap";
import Features from "src/Projects/Ride/components/Common/Features";
import HeroArea from "src/Projects/Ride/components/HeroArea";
import overlay from "./../assets/overlay.png";
import stock1 from "./../assets/stock1.jpg";
import stock2 from "./../assets/stock2.jpg";

function Home() {
  return (
    <div>
      <HeroArea />
      <div className="py-5">
        <Container>
          {/* Features Start */}
          <Features
            imageSrc={stock1}
            title="Safety Fast"
            desc="Your safety Always comes first. We look out for you
                  before, during, and after every single ride. And we monitor
                  rides to help you get where you need to go. If you ever need
                  it, we're standing by, ready to help."
            isReversed={true}
          />
          {/* Features End */}
          <div className=" py-3 py-lg-5" />
          {/* Features Start */}
          <Features
            imageSrc={stock2}
            title="Opportunity is everywhere for everyone"
            desc="New benefits and guaranteed earnings."
          >
            <Button className="shadow-sm rounded btn__ride mt-2">
              Sign In With UVIOM
            </Button>
          </Features>
          {/* Features End */}
        </Container>
        <div className="py-3 py-lg-5" />
      </div>
    </div>
  );
}
export default Home;
