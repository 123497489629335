import axios from "axios";

export const publicProfileMultiInfo = (setIsLoading, Url, setInfo,user_id) => {
  setIsLoading(true);

  axios.get(`${Url}/${user_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        if(res.data.status === 1){
          const response = res.data;
          setInfo(response);
        }
      } else { }
      setIsLoading(false);

    }).catch((err) => {
      console.log(err.message);
      setIsLoading(false);

    });
  setIsLoading(false);

}