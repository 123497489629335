import React from "react";
import {
  faEllipsisH,
  faGraduationCap,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SingleItem = ({ item }) => {
  return (
    <div className="d-flex justify-content-between my-4">
      <div className="d-flex">
        <div className="me-3 border  p-1 myIconCircle">
          <FontAwesomeIcon icon={faHeart} />
        </div>
        <div>{item && item.relationship_status}</div>
       
      </div>
      
    </div>
  );
};
