import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import apiCall_withToken from "src/app/helper/apiCall";
import {
  getAllVideosUrl,
  getCurrentVideoUrl,
  getSavedVideosUrl,
  getRelatedToCategoryUrl,
  getCurrentVideoCommentUrl,
  getCurrentCommentReplyUrl,
  getChannelDetailsUrl,
} from "src/Projects/View/common/view_commonUrl";

const initialState = {
  homePageVideos: { data: [], has_more: false },
  playlistVideos: { data: [], has_more: false },
  savedVideos: { data: [], has_more: false },
  searchResult: { data: [], has_more: false },
  currentVideo: {},
  channelDetails: {},
  ownPlaylist: [],
  videoCategories: [],
  currentVideoComments: { data: [], has_more: false },
  categories: [],
  relatedToCategory: { isLoading: false, data: [] },
  isLoading: false,
  error: "",
};

/* export const getHomePageVideos = createAsyncThunk(
  "view/getAllVideos",
  (currentPage = 1, thunkAPI) => {
    const url = getAllVideosUrl + "?page=" + currentPage;
    const resp = apiCall_withToken(thunkAPI, url);
    return resp;
  }
);
 */
export const getCurrentVideo = createAsyncThunk(
  "view/getCurrentVideo",
  (videoId, thunkAPI) => {
    const url = getCurrentVideoUrl + videoId;
    const resp = apiCall_withToken(thunkAPI, url);
    return resp;
  }
);

export const getChannelDetails = createAsyncThunk(
  "view/getChannelDetails",
  (channelId, thunkAPI) => {
    const url = getChannelDetailsUrl + channelId;
    const resp = apiCall_withToken(thunkAPI, url);
    return resp;
  }
);

export const getCurrentVideoComments = createAsyncThunk(
  "view/getCurrentVideoComments",
  ({ videoId, currentPage = 1 }, thunkAPI) => {
    const url = `${getCurrentVideoCommentUrl + videoId}?page=${currentPage}`;
    const resp = apiCall_withToken(thunkAPI, url);
    return resp;
  }
);

export const getCurrentVideoCommentReply = createAsyncThunk(
  "view/getCurrentVideoCommentReply",
  (videoId, thunkAPI) => {
    const url = getCurrentCommentReplyUrl + videoId;
    const resp = apiCall_withToken(thunkAPI, url);
    return resp;
  }
);

export const getSavedVideos = createAsyncThunk(
  "view/getSavedVideos",
  (pageId = 1, thunkAPI) => {
    const url = getSavedVideosUrl + "?page=" + pageId;
    const resp = apiCall_withToken(thunkAPI, url);
    return resp;
  }
);

export const getRelatedToCategory = createAsyncThunk(
  "view/getRelatedToCategory",
  (categoryId, thunkAPI) => {
    const url = getRelatedToCategoryUrl + categoryId;
    const resp = apiCall_withToken(thunkAPI, url);
    return resp;
  }
);

const viewDataSlice = createSlice({
  name: "view_data",
  initialState,

  reducers: {
    homePageVideos: (state, action) => {
      const data = action.payload;
      state.homePageVideos.data = [...state.homePageVideos.data, ...data.data];
      state.homePageVideos.has_more = data?.next_page_url ? true : false;
    },

    destroyViewData: (state, action) => {
      state.homePageVideos = { data: [], has_more: false };
      state.playlistVideos = { data: [], has_more: false };
      state.savedVideos = { data: [], has_more: false };
      state.currentVideo = {};
      state.channelDetails = {};
      state.ownPlaylist = [];
      state.videoCategories = [];
      state.currentVideoComments = { data: [], has_more: false };
      state.categories = [];
      state.relatedToCategory = { isLoading: false, data: [] };
      state.isLoading = false;
      state.error = "";
    },

    searchedValue: (state, action) => {
      const data = action.payload;
      state.searchResult.data = data.data;
      state.searchResult.has_more = data?.next_page_url ? true : false;
    },

    destroySearchResult: (state, action) => {
      state.searchResult.data = [];
      state.searchResult.has_more = false;
    },

    updateVideoPost: (state, action) => {
      const {
        title,
        thumbnail,
        videoId,
        description,
        playlist_id,
        restriction,
        kids,
        tag,
        video_status,
        video_category_id,
      } = action.payload;

      if (!_.isEmpty(state.currentVideo)) {
        const video = state.currentVideo;
        video.title = title;
        video.thumbnail = thumbnail;
        video.description = description;
        video.playlist_id = playlist_id;
        video.restriction = restriction;
        video.kids = kids;
        video.tag = tag;
        video.video_status = video_status;
        video.video_category_id = video_category_id;
      }

      if (!state.channelDetails.videos) return;

      const video = state.channelDetails.videos.find(
        (video) => video.id === parseInt(videoId)
      );

      video.title = title;
      video.thumbnail = thumbnail;
      video.description = description;
      video.playlist_id = playlist_id;
      video.restriction = restriction;
      video.kids = kids;
      video.tag = tag;
      video.video_status = video_status;
      video.video_category_id = video_category_id;
    },

    deleteVideo: (state, action) => {
      const videoId = action.payload;

      if (state.homePageVideos.data) {
        const afterFilter = state.homePageVideos.data.filter(
          (e) => e.id !== parseInt(videoId)
        );
        state.homePageVideos.data = afterFilter;
      }

      if (state.channelDetails) {
        const afterFilter = state.channelDetails.videos.filter(
          (e) => e.id === parseInt(videoId)
        );
        state.homePageVideos.data = afterFilter;
      }
    },

    playlistVideos: (state, action) => {
      const data = action.payload;
      state.playlistVideos.data = data.data;
      state.playlistVideos.has_more = data?.next_page_url ? true : false;
    },

    destroyPlaylistVideos: (state, action) => {},

    // Toggle the Follow and unFollow
    toggleChannelFollow: (state, action) => {
      const userId = action.payload;
      if (state.channelDetails.user_id == userId) {
        const isFollowing = parseInt(state.channelDetails.follows_status_count);
        const totalFollowers = parseInt(state.channelDetails.total_followers);
        if (isFollowing) {
          state.channelDetails.total_followers = totalFollowers - 1;
        } else {
          state.channelDetails.total_followers = totalFollowers + 1;
        }
        state.channelDetails.follows_status_count = isFollowing ? 0 : 1;
      }
    },

    destroyChannelDetails: (state, action) => {
      state.channelDetails = [];
    },

    ownPlaylist: (state, action) => {
      const data = action.payload;
      state.ownPlaylist = data;
    },

    updatePlaylist: (state, action) => {
      const {
        playlistId,
        formData: { name, status, description },
      } = action.payload;

      const playlist = state.channelDetails.playlists.find(
        (playlist) => playlist.id === parseInt(playlistId)
      );

      playlist.name = name;
      playlist.status = status;
      playlist.description = description;
    },

    videoCategories: (state, action) => {
      const data = action.payload;
      state.videoCategories = data;
    },

    updateLikeStatus: (state, action) => {
      if (parseInt(state["currentVideo"]["personal_like_status_count"]) === 1) {
        state["currentVideo"]["personal_like_status_count"] = 0;
        state["currentVideo"]["liked_videos_count"] =
          parseInt(state["currentVideo"]["liked_videos_count"]) - 1;
      } else {
        state["currentVideo"]["personal_like_status_count"] = 1;
        state["currentVideo"]["liked_videos_count"] =
          parseInt(state["currentVideo"]["liked_videos_count"]) + 1;
      }
    },

    updateCommentLikeStatus: (state, action) => {
      const id = action.payload.id;
      const type = action.payload.type;
      const parentId = action.payload.parentId;

      if (type === "comment") {
        let mainComment = state.currentVideoComments.data.find(
          (comment) => comment.id === parseInt(id)
        );
        const isLiked = parseInt(
          mainComment.personal_comment_like_status_count
        );
        const likeCount = parseInt(mainComment.comment_likes_count);
        mainComment.comment_likes_count = isLiked
          ? likeCount - 1
          : likeCount + 1;

        mainComment.personal_comment_like_status_count = isLiked ? 0 : 1;
      } else if (type === "reply_comment") {
        let parentComment = state.currentVideoComments.data.find(
          (comment) => comment.id === parseInt(parentId)
        );

        let mainComment = parentComment.replies.find(
          (comment) => comment.id === parseInt(id)
        );
        const isLiked = parseInt(
          mainComment.personal_reply_comment_like_status_count
        );
        const likeCount = parseInt(mainComment.comment_reply_likes_count);
        mainComment.comment_reply_likes_count = isLiked
          ? likeCount - 1
          : likeCount + 1;

        mainComment.personal_reply_comment_like_status_count = isLiked ? 0 : 1;
      }
    },

    updateSaveStatus: (state, action) => {
      if (parseInt(state["currentVideo"]["personal_save_status_count"]) === 1) {
        state["currentVideo"]["personal_save_status_count"] = 0;
      } else {
        state["currentVideo"]["personal_save_status_count"] = 1;
      }
    },

    destroyCurrentVideoComments: (state, action) => {
      state.currentVideoComments = { data: [], has_more: false };
    },
    /*   destroySavedVideos: (state, action) => {
      state.savedVideos = { data: [], has_more: false };
    }, */

    addComment: (state, action) => {
      const data = action.payload.payload;
      const type = action.payload.type;

      let preparerData;

      if (type === "comment") {
        preparerData = {
          id: data.comment.id,
          comment: data.comment.comment,
          comment_likes_count: 0,
          created_at: data.comment.created_at,
          channel_profile_section: {
            channel_name: data.channel_profile_section.channel_name,
            photo: data.channel_profile_section.photo,
            user_id: data.comment.user_id,
          },
          personal_comment_like_status_count: 0,
          replies: [],
          replies_count: 0,
        };
        state.currentVideo.comments_count =
          parseInt(state.currentVideo.comments_count) + 1;

        state.currentVideoComments.data.unshift(preparerData);
      } else if (type === "comment_reply") {
        preparerData = {
          id: data.reply_comment.id,
          comment_id: data.reply_comment.comment_id,
          comment_reply: data.reply_comment.comment_reply,
          comment_likes_count: 0,
          created_at: data.reply_comment.created_at,
          channel_profile_section: {
            channel_name: data.channel_profile_section.channel_name,
            photo: data.channel_profile_section.photo,
            user_id: data.reply_comment.user_id,
          },
          personal_comment_like_status_count: 0,
        };
        // find the comment id and push into replies
        const mainComment = state.currentVideoComments.data.find(
          (comment) => comment.id === parseInt(data.reply_comment.comment_id)
        );

        mainComment.replies_count = parseInt(mainComment.replies_count) + 1;

        mainComment.replies.unshift(preparerData);
      }
    },

    updateComment: (state, action) => {
      const id = action.payload.id;
      const type = action.payload.type;
      const comment = action.payload.comment;
      const parentId = action.payload.parentId;

      if (type === "comment_reply") {
        const parentComment = state.currentVideoComments.data.find(
          (comment) => comment.id === parseInt(parentId)
        );

        const mainComment = parentComment.replies.find(
          (comment) => comment.id === parseInt(id)
        );
        mainComment.comment_reply = comment.comment_reply;
      } else if (type === "comment") {
        const mainComment = state.currentVideoComments.data.find(
          (comment) => comment.id === parseInt(id)
        );

        mainComment.comment = comment.comment;
      }
    },

    deleteComment: (state, action) => {
      const type = action.payload.type;
      const commentId = action.payload.commentId;
      const parentId = action.payload.parentId;

      if (type === "comment") {
        const afterFilter = state.currentVideoComments.data.filter(
          (e) => e.id !== parseInt(commentId)
        );
        state.currentVideoComments.data = afterFilter;

        state.currentVideo.comments_count =
          parseInt(state.currentVideo.comments_count) - 1;
      } else if (type === "comment_reply") {
        const parentComment = state.currentVideoComments.data.find(
          (e) => e.id === parseInt(parentId)
        );

        const afterFilter = parentComment.replies.filter(
          (comment) => comment.id !== parseInt(commentId)
        );
        parentComment.replies_count = parseInt(parentComment.replies_count) - 1;
        parentComment.replies = afterFilter;
      }
    },

    channelProfileUpdate: (state, action) => {
      const data = action.payload;
      state.channelDetails.channel_name = data.channel_name;
      state.channelDetails.channel_title = data.channel_title;
      state.channelDetails.channel_description = data.channel_description;
      state.channelDetails.privacy = data.privacy;
      state.channelDetails.channel_category_id = data.channel_category_id;
    },
  },

  extraReducers: {
    //get Channel Details
    [getChannelDetails.fulfilled]: (state, action) => {
      const data = action.payload;
      state.channelDetails = data.data[0];
    },

    [getChannelDetails.rejected]: (state, action) => {
      state.error = action.payload;
    },

    //get all Comments
    [getCurrentVideoComments.fulfilled]: (state, action) => {
      state.isLoading = false;
      const data = action.payload;
      const previousState = state.currentVideoComments.data;
      state.currentVideoComments.data = [...previousState, ...data.data];
      state.currentVideoComments.has_more = data?.next_page_url ? true : false;
    },

    [getCurrentVideoComments.rejected]: (state, action) => {
      state.error = action.payload;
    },
    //get Comment replay
    [getCurrentVideoCommentReply.fulfilled]: (state, action) => {
      const data = action.payload;

      // catching the comment to replace into replay
      const getComment = state.currentVideoComments.data?.find(
        (comment) => comment.id === parseInt(data.data[0].comment_id)
      );

      if (getComment) {
        getComment.replies = data.data;
      }
    },

    [getCurrentVideoCommentReply.rejected]: (state, action) => {
      state.error = action.payload;
    },

    // Get current Video
    [getCurrentVideo.pending]: (state) => {
      state.isLoading = true;
    },
    [getCurrentVideo.fulfilled]: (state, action) => {
      state.isLoading = false;
      const data = action.payload;
      state.currentVideo = data;
    },
    [getCurrentVideo.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Get Saved Videos
    [getSavedVideos.pending]: (state) => {
      state.isLoading = true;
    },
    [getSavedVideos.fulfilled]: (state, action) => {
      const data = action.payload;
      state.isLoading = false;

      // const previousState = state.savedVideos.data;
      // state.savedVideos.data = [...previousState, ...data.data];
      state.savedVideos.data = data.data;
      state.savedVideos.has_more = data?.next_page_url ? true : false;
    },
    [getSavedVideos.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Get Related Category Video
    // Get Saved Videos
    [getRelatedToCategory.pending]: (state) => {
      state.relatedToCategory.isLoading = true;
    },
    [getRelatedToCategory.fulfilled]: (state, action) => {
      state.relatedToCategory.isLoading = false;
      const data = action.payload;
      state.relatedToCategory.data = data;
    },
    [getRelatedToCategory.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default viewDataSlice.reducer;

export const {
  homePageVideos,
  updateVideoPost,
  searchedValue,
  destroySearchResult,
  deleteVideo,
  toggleChannelFollow,
  destroyChannelDetails,
  updateLikeStatus,
  updateCommentLikeStatus,
  updateSaveStatus,
  addComment,
  updateComment,
  deleteComment,
  destroyCurrentVideoComments,
  destroySavedVideos,
  channelProfileUpdate,
  ownPlaylist,
  updatePlaylist,
  playlistVideos,
  destroyPlaylistVideos,
  videoCategories,
  destroyViewData,
} = viewDataSlice.actions;
