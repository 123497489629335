import React from "react";
import { Row } from "react-bootstrap";
import SingleJobPost from "../../Components/SingleJobPost/SingleJobPost";

const data = {
  id: 125,
  company_id: "37",
  company_user_id: "74",
  job_category_id: "1",
  job_sub_category_id: "0",
  slug: "react-native-developer",
  job_type: "remote",
  job_title: "react native developer",
  range_start: "12000",
  range_end: "16000",
  job_address: "pabna , bangladesh",
  job_details: "10 am to 10 pm",
  role_responsibility: "IOS App developer",
  job_requirements: "good at javascript and react",
  job_status: "accept",
  status: "active",
  deadline: "2022-07-29",
  deleted_at: null,
  job_apply_count: "0",
  job_save_count: "0",
  company_profile_section: {
    id: 37,
    user_id: "74",
    company_name: "Nahid Technology",
    website: "nahidahmed.netlify.com",
    company_phone: "01758597460",
    company_email: "mdnahidahmed2002@gmail.com",
    company_address: "dhaka, bangladesh",
    profile_banner: "2022/July/j6pXMkqQypHWWLoHsqX2sjIuKVF56aUble4lL7I7.jpg",
    profile_picture: "2022/July/u8VsJJ38fhDdrEe6Mr0on8sSdYjPAetGOzHLPM1M.png",
    company_EINN: "5555",
    company_status: "Inapproved",
    slug: "nahid-technology",
    deleted_at: null,
  },
  company_social_link_section: [
    {
      id: 137,
      user_id: "74",
      title: "Facebook",
      company_id: "37",
      social_media_name: "Facebook",
      social_media_link: "www.facebook.com/profile.php?id=100047821750933",
      status: "active",
    },
    {
      id: 161,
      user_id: "74",
      title: "Twitter",
      company_id: "37",
      social_media_name: "Twitter",
      social_media_link: "www.twitter.com/nahid",
      status: "active",
    },
  ],
  company_job_category: {
    id: 1,
    title: "Goverment",
    short_description:
      "Illo ducimus ut totam deleniti quo ut quas eum. Eius necessitatibus fuga quod perferendis eveniet. Accusamus sint velit modi beatae soluta. Doloribus et debitis iste repudiandae quo ut.",
    long_description:
      "Nihil rerum quisquam odit earum. Occaecati ea vero cumque iste est aspernatur autem in. Maiores labore impedit dicta hic.",
    slug: "goverment",
    status: "1",
    photo: "K4GCqt1Mmbpc9K913T6UX9MLJtSr1NGHRNI8Kuna.jpg",
    deleted_at: null,
  },
  company_job_sub_category: null,
};

const JobNotification = () => {
  return (
    <div className="secondary-bg p-2 pb-5">
      <h6 className="jobApplyTitle">Notification</h6>

      <Row xxl={2} className="g-3">
        {[...new Array(8)].map((_, index) => (
          <SingleJobPost key={index} data={data}></SingleJobPost>
        ))}
      </Row>
    </div>
  );
};

export default JobNotification;
