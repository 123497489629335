import {
  faCamera,
  faSmile,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Dropdown, FormControl, InputGroup } from "react-bootstrap";
import AttachIcon from "src/Projects/Life/icons/AttachIcon";
// import MicrophoneIcon from "src/Projects/Life/icons/MicrophoneIcon";
import SentIcon from "src/Projects/Life/icons/SentIcon";

import MessageFileInput from "../Common/MessageFileInput";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import {
  messageImageSendUrl,
  messageSendUrl,
} from "src/Projects/Life/Common/CommonUrl";

import useAuth from "src/hooks/useAuth";
import { useDispatch } from "react-redux";
import {
  // highlightCurrentChatUser,
  storeMyMessage,
} from "src/features/life/messengerSlice";
import styled from "styled-components";
import CloseIcon from "src/Projects/Life/icons/CloseIcon";
import compress_image from "src/utilities/compress_image";
import _ from "lodash";

import { createNewMessage, postData } from "../Common/message_apiCall";
import EmojiPicker from "emoji-picker-react";
import useTheme from "src/hooks/useTheme";
const ChatContentFooter = ({ messagesEndRef, to_user, isPopup = false }) => {
  const [message, setMessage] = useState("");
  const [imageFiles, setImageFiles] = useState([]);
  const [blobImages, setBlobImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { authUser } = useAuth();
  const authUserId = String(authUser.id);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleEmojiClick = (text) => {
    setMessage((prev) => prev + text.emoji);
  };

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { currentTheme } = useTheme();

  const handleFileInput = (e) => {
    const images = e.target.files;
    setImageFiles(images);
    const imagesUrl = [];
    for (const imageFile of images) {
      const blobUrl = URL.createObjectURL(imageFile);
      imagesUrl.push(blobUrl);
    }
    setBlobImages(imagesUrl);
  };

  const removeSingleImage = (index) => {
    const remainingBlob = [...blobImages];
    remainingBlob.splice(index, 1);
    setBlobImages([...remainingBlob]);
  };

  const removeAllImages = () => {
    setImageFiles([]);
    setBlobImages([]);
  };

  const submitNewMessage = async () => {
    setShowEmojiPicker(false);
    if ((message || !_.isEmpty(imageFiles)) && to_user) {
      const newMessage = createNewMessage(
        authUserId,
        to_user,
        message,
        blobImages
      );
      let formData = new FormData();
      formData.append("to_user", to_user);
      formData.append("message", message);
      let url = messageSendUrl;
      // dispatch(setMessageLoading());
      setIsLoading(true);

      if (imageFiles.length) {
        url = messageImageSendUrl;
        const compressed_images = await compress_image(imageFiles);
        _.forEach(compressed_images, (file, index) => {
          formData.append(`message_content[${index}]`, file);
        });
      }

      dispatch(storeMyMessage(newMessage));

      // dispatch(
      //   highlightCurrentChatUser({ user_id: to_user, content: message })
      // );

      postData(url, formData, setIsLoading);
      messagesEndRef.current.scrollIntoView();
      setMessage("");
      removeAllImages();
    }
  };

  const onkeyPressSubmit = (event) => {
    if (event.key === "Enter" && event.shiftKey === false && !isLoading) {
      event.preventDefault();
      submitNewMessage();
    }
  };

  return (
    <>
      {blobImages.length > 0 && (
        <ImgShow className="message__selected__img-show">
          {blobImages.map((image, index) => (
            <SingleImage key={index} isPopup={isPopup}>
              <img
                alt="selected photos"
                className="img img-fluid"
                src={image}
              />
              <button
                className="image_close btn-0"
                onClick={() => {
                  removeSingleImage(index);
                }}
              >
                <CloseIcon size="10" />
              </button>
            </SingleImage>
          ))}

          <button className="image_close btn-0" onClick={removeAllImages}>
            <CloseIcon size="10" />
          </button>
        </ImgShow>
      )}

      <div className="d-flex align-items-center pe-2" style={{ gap: "8px" }}>
        <Dropdown className={`${isPopup ? "d-block" : "d-sm-none"}`}>
          <Dropdown.Toggle
            aria-label="toggle button"
            id="message__dropdown-btn"
            className="chat__content-btn"
          >
            <ThreeDots size="20px"></ThreeDots>
          </Dropdown.Toggle>
          <Dropdown.Menu className="message__dropdown-body">
            <MessageFileInput handleChange={handleFileInput}>
              <AttachIcon size={20} />
            </MessageFileInput>

            <MessageFileInput handleChange={handleFileInput}>
              <FontAwesomeIcon size="lg" icon={faCamera} />
            </MessageFileInput>

            {/* <button type="button" className="chat__content-btn">
              <MicrophoneIcon />
            </button> */}
          </Dropdown.Menu>
        </Dropdown>

        <MessageFileInput
          className={`${isPopup ? "d-none" : "d-none d-sm-block"}`}
          handleChange={handleFileInput}
        >
          <AttachIcon size={20} />
        </MessageFileInput>
        <MessageFileInput
          handleChange={handleFileInput}
          className={`${isPopup ? "d-none" : "d-none d-sm-block"}`}
        >
          <FontAwesomeIcon size="lg" icon={faCamera} />
        </MessageFileInput>
        {/* <button
          type="button"
          className={`${
            isPopup ? "d-none" : "d-none d-sm-block"
          } chat__content-btn`}
        >
          <MicrophoneIcon />
        </button> */}
        <div style={{ flex: 1 }}>
          <InputGroup className="profileVideoSearchInputGroup border-0 rounded-3">
            <FormControl
              aria-label="search video"
              placeholder="Type a Message Here..."
              className="profileVideoSearchInput "
              onChange={handleInputChange}
              value={message}
              onKeyPress={onkeyPressSubmit}
              autoFocus
              onFocus={() => setShowEmojiPicker(false)}
            />
            <InputGroup.Text
              className="chat__content__footer-emoji pointer"
              onClick={() => setShowEmojiPicker((prev) => !prev)}
            >
              <FontAwesomeIcon size="lg" icon={faSmile}></FontAwesomeIcon>
            </InputGroup.Text>
          </InputGroup>
        </div>

        <button
          className={`chat__content-btn `}
          disabled={isLoading}
          onClick={submitNewMessage}
        >
          {isLoading ? (
            <FontAwesomeIcon
              className="primary-text"
              size="lg"
              icon={faSpinner}
              spin
            ></FontAwesomeIcon>
          ) : (
            <SentIcon size="25" />
          )}
        </button>

        {showEmojiPicker && (
          <div className="emoji__picker_message">
            <EmojiPicker
              onEmojiClick={handleEmojiClick}
              skinTonesDisabled
              searchDisabled
              theme={currentTheme}
              height="350px"
              previewConfig={{ showPreview: false }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ChatContentFooter;

const ImgShow = styled.div`
  /* border: 1px solid gray; */
  background-color: var(--bg-primary);
  gap: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-right: 0.5rem;
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  z-index: 1200;

  &::-webkit-scrollbar {
    height: 0.675rem !important;
  }

  .image_close {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: var(--bg-secondary);
    color: white;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SingleImage = styled.div`
  min-height: ${(props) => (props.isPopup ? "80px" : "120px")};
  max-height: ${(props) => (props.isPopup ? "80px" : "120px")};
  min-width: ${(props) => (props.isPopup ? "80px" : "120px")};
  max-width: ${(props) => (props.isPopup ? "80px" : "120px")};
  border-radius: 3px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
`;
