import React from "react";

const CircleLogo = ({ size = "50" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 85 83"
      fill="none"
    >
      <path
        d="M51.8106 63.7633C49.5367 65.9343 42.2727 71.9614 35.2298 74.0961H35.2086C35.1314 74.1264 27.3466 76.9605 20.9955 76.9605C20.0296 76.9726 19.0637 76.8969 18.1114 76.738C17.0259 75.6388 5.95131 64.2675 4.68262 55.108C15.8117 63.4499 25.8811 65.2894 32.3805 65.356C32.7938 65.4499 33.6477 65.4953 35.4039 65.4953C38.6104 65.4877 44.0107 65.1819 51.8106 63.7633Z"
        fill="#688FB4e3"
      />
      <path
        d="M32.3563 64.8186C28.9469 64.5521 19.0607 63.1169 13.8361 59.8528L13.7377 59.7937H13.6983C12.9746 59.4667 3.42306 54.9748 0 48.7888C0.0484467 47.352 0.707018 31.7007 6.54484 24.5125C8.04972 38.3137 13.5605 46.9175 17.9449 51.7168C18.1932 52.0741 18.4642 52.4132 18.7549 52.7357C19.256 53.3201 20.1175 54.2814 21.3847 55.5425C23.4709 57.6046 27.0196 60.8505 32.3563 64.8186Z"
        fill="#F1A350e3"
      />
      <path
        d="M18.4734 51.2626C16.4992 49.184 11.1655 42.2894 9.33359 34.1367L9.30785 34.0217L9.28817 34.005C9.02322 33.1375 6.08009 23.0273 7.60313 16.6323C8.73709 15.6134 20.4354 5.30793 29.6327 4.54187C20.7034 15.2183 18.328 25.1711 17.9132 31.6538C17.7315 32.3775 17.571 34.5637 17.5347 36.7589C17.4999 39.7142 17.621 44.58 18.4734 51.2626Z"
        fill="#688FB4e3"
      />
      <path
        d="M32.2398 17.8738V17.8829C31.5071 18.3128 29.5965 19.9782 28.3278 21.1803C26.187 23.2105 22.8018 26.7032 18.6233 31.9915C18.7262 29.1301 19.9177 20.4945 24.4672 13.4788L24.5323 13.3789V13.3546C24.9607 12.5643 30.1415 3.37764 35.7643 0C37.2859 0.0984073 52.8327 1.2475 59.8151 7.28365C45.9457 8.36462 37.1663 13.6317 32.2398 17.8738Z"
        fill="#F1A350e3"
      />
      <path
        d="M77.5268 29.9506C66.6203 21.3665 56.6145 19.2742 50.1151 19.0532C49.7563 18.9926 49.3929 18.9532 49.0296 18.9381C48.3846 18.8896 47.2976 18.8306 45.7897 18.8306C42.7134 18.8306 37.6159 19.0728 30.4927 20.2022C32.5199 18.169 39.2418 12.6234 47.337 10.5523L47.4505 10.5235L47.4717 10.5023C48.3392 10.1768 55.4215 7.9846 61.3154 7.9846C62.4766 7.97249 63.6363 8.07544 64.7778 8.29648C65.8285 9.39864 76.4822 20.7791 77.5268 29.9506Z"
        fill="#688FB4e3"
      />
      <path
        d="M75.6935 66.7958C74.5913 67.8496 63.229 78.5215 54.062 79.5827C62.6446 68.6383 64.7006 58.6099 64.9095 52.1104C65.07 51.3338 65.1563 48.9947 65.1245 46.9978C65.0791 44.0441 64.7975 39.1873 63.7317 32.5334C65.771 34.55 71.3317 41.2705 73.4149 49.3626L73.4422 49.4731L73.4619 49.4913C73.5391 49.7154 73.8237 50.5995 74.1856 51.897C74.8835 54.4177 75.8373 58.5009 75.9947 62.3403C76.0704 63.833 75.969 65.3288 75.6935 66.7958Z"
        fill="#688FB4e3"
      />
      <path
        d="M59.6984 68.5671L59.6409 68.6746V68.7003C59.2791 69.5239 54.8522 79.0846 49.5065 82.8982H48.8494C46.0122 82.8982 31.9021 82.6318 24.9622 77.5267C38.6711 75.3618 46.9963 69.4346 51.5791 64.8231C51.9348 64.5521 52.274 64.2599 52.5949 63.9495C53.1808 63.406 54.1392 62.4871 55.373 61.1427C57.4048 58.9308 60.5644 55.1716 64.3054 49.6033C64.3221 52.4889 63.771 61.0549 59.6984 68.5671Z"
        fill="#F1A350e3"
      />
      <path
        d="M66.4265 33.8263H66.4174C66.0026 33.083 64.3796 31.1375 63.2078 29.8446C61.217 27.6554 57.806 24.1915 52.6101 19.8918C55.47 20.0569 64.0753 21.4331 70.9926 26.1354L71.091 26.2035H71.1152C71.8949 26.6486 80.9666 32.0247 84.2201 37.7248C84.0899 39.2448 82.6016 54.7598 76.4156 61.612C75.6329 47.7351 70.5596 38.8466 66.4265 33.8263Z"
        fill="#F1A350e3"
      />
    </svg>
  );
};

export default CircleLogo;
