import React from 'react'
import { useNavigate, useLocation } from "react-router-dom";

export const FollowerSingleRow = () => {
  const navigate = useNavigate();
  const {state} = useLocation();
  return (
    <div className="col-sm-12 col-md-6 col-lg-6" onClick={()=>navigate(`${state.data}/channelView`)}>
          <div className="d-flex followerSingleRow my-2 px-3 py-2">
            <img src="/View/cofffe.png" className="me-2" alt="" />
            <div>Coffe With CEO</div>
          </div>
        </div>
  )
}
