import React from "react";
import {
  fmFriendsphotoGetUrl,
  fnFriendsphotoGetUrl,
  pFriendsphotoGetUrl,
} from "src/Projects/Life/Common/CommonUrl";

export const PostUserPicture = ({ postUserPictureArg, userName = "user" }) => {
  const { profile_images } = postUserPictureArg;
  let postProviderPhoto = "";
  let postProviderPhotoPath = "";
  if (profile_images) {
    if (profile_images.family_profile_photo) {
      postProviderPhoto = profile_images.family_profile_photo;
      postProviderPhotoPath = fmFriendsphotoGetUrl;
    } else if (profile_images.friend_profile_photo) {
      postProviderPhoto = profile_images.friend_profile_photo;
      postProviderPhotoPath = fnFriendsphotoGetUrl;
      // console.log(postProviderPhoto)
    } else if (profile_images.professional_profile_photo) {
      postProviderPhoto = profile_images.professional_profile_photo;
      postProviderPhotoPath = pFriendsphotoGetUrl;
    }
  }
  return (
    <>
      <div className="PostSectionProfile">
        <img
          className="img-fluid"
          src={`${postProviderPhotoPath}/${postProviderPhoto}`}
          alt={userName}
          onError={(e) => (e.target.src = "/user.png")}
        />
      </div>
    </>
  );
};
