import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { SingleItem } from "./SingleItem";

export const Works = ({ info }) => {
  const [data, setdata] = useState();
  useEffect(() => {
    if (!isEmpty(info)) {
      setdata(info.work_info);
    }
  }, [info]);

  return (
    <div className="aboutBox mt-1 mb-4 ">
      <div className="d-flex justify-content-between border-bottom pb-2 border-white">
        <div>
          <h6>Works</h6>
        </div>
      </div>
      <div className="mt-5">
        {data &&
          data.map((item, index) => <SingleItem key={index} item={item} />)}
      </div>
    </div>
  );
};
