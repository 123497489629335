import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState, startTransition, useEffect } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { increaseCurrentPage } from "src/features/life/messengerSlice";
import useAuth from "src/hooks/useAuth";
import ChatPerson from "../Common/ChatPerson";
import "./messageSideBar.css";

const MessageSideBar = () => {
  const { authUser } = useAuth();
  const authUserId = Number(authUser.id);
  const dispatch = useDispatch();
  const {
    chatUserList: chatList,
    hasMore,
    isLoading,
  } = useSelector((state) => state.messengerData);

  const [filtered_list, setFilteredList] = useState([]);

  useEffect(() => {
    setFilteredList(chatList);
  }, [chatList]);

  const handleSearch = (e) => {
    const text = e.target.value;
    startTransition(() => {
      const filtered = chatList.filter((person) => {
        if (person.to_user.id === authUserId) {
          return (
            person.from_user.first_name
              ?.toLowerCase()
              ?.includes(text.toLowerCase()) ||
            person.from_user.last_name
              ?.toLowerCase()
              ?.includes(text.toLowerCase())
          );
        } else {
          return (
            person.to_user.first_name
              ?.toLowerCase()
              ?.includes(text.toLowerCase()) ||
            person.to_user.last_name
              ?.toLowerCase()
              ?.includes(text.toLowerCase())
          );
        }
      });

      setFilteredList(filtered);
    });
  };

  return (
    <div className="pb-2 message__side__bar mt-lg-2">
      <div className="stickyParts  pb-2" style={{ zIndex: "29" }}>
        <InputGroup className="profileVideoSearchInputGroup">
          <InputGroup.Text className="profileVideoSearchLabel">
            <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
          </InputGroup.Text>
          <FormControl
            aria-label="search video"
            placeholder="Search Profile"
            className="profileVideoSearchInput"
            onChange={handleSearch}
          />
        </InputGroup>
      </div>
      {/* person section */}
      <div>
        <div className="d-flex justify-content-between align-items-center pb-2">
          <p className="m-0">Person</p>
          <small className="font-12">Active Friends</small>
        </div>

        {filtered_list?.length ? (
          <>
            {filtered_list.map((item) => (
              <ChatPerson data={item} key={item.id} authUserId={authUserId} />
            ))}
          </>
        ) : (
          <p className="text-center">No result found !</p>
        )}

        {isLoading && (
          <h2 className="text-center my-3">
            <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon>
          </h2>
        )}

        {/* <ChatPerson active seen data={chatPersonData} />
        <ChatPerson active seen data={chatPersonData} />
       
        <ChatPerson seen data={chatPersonData} />
        <ChatPerson active data={chatPersonData} /> */}
        {filtered_list?.length > 0 && (
          <div className="text-end font-14 pointer">
            <button
              className="btn-0 message_sellAll-btn primary-text"
              disabled={!hasMore}
              onClick={() => dispatch(increaseCurrentPage())}
            >
              See All
            </button>
          </div>
        )}
      </div>
      {/* group section */}
      {/*   
      
      <div>
        <div className="d-flex justify-content-between align-items-center py-3">
          <p className="m-0">Groups</p>
          <small className="font-12">Active Groups</small>
        </div>
        <ChatPerson active seen />
        <ChatPerson seen />
        <ChatPerson active />
        <ChatPerson seen />
        <div className="text-end font-10">
          <span>See All</span>
        </div>
      </div>
       */}
    </div>
    // </Col>
  );
};

export default MessageSideBar;
