/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import {
  companyProfilePhotoUrl,
  companyBannerPhotoUrl,
} from "../../utilities/job_commonUrl";
import { useNavigate, useLocation } from "react-router-dom";
import handlePrint from "src/utilities/handlePrint";
import JobInformation from "../../Components/Common/JobInformation";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import { Tab, Tabs } from "react-bootstrap";
import LocationInfo from "../../Components/Common/LocationInfo";
import CompanyInfo from "../../Components/Common/CompanyInfo";
import CompanyBanner from "../../Components/Common/CompanyBanner";
import { useSaveJobPostMutation } from "src/app/services/jobApi/jobPostApi";
import { useGetHomeJobPostQuery } from "src/app/services/jobApi/jobGetApi";
import { showToast } from "../../utilities/functions";
const SaveJobDetails = () => {

  const { state } = useLocation();
  const printContent = useRef();
  const printContainer = useRef();
  const navigate = useNavigate(); 
  const [key, setKey] = useState("job_info");
  const { company_profile_section, job_post, job_apply_count } = state.jobData;

  const [savePost, { isLoading, isSuccess, error }] = useSaveJobPostMutation()
  const { data: ser_data, refetch } = useGetHomeJobPostQuery(1);


  const profilePhoto = company_profile_section?.profile_picture
    ? companyProfilePhotoUrl + company_profile_section.profile_picture
    : "/user.png";
  const bannerPhoto = company_profile_section?.profile_banner
    ? companyBannerPhotoUrl + company_profile_section.profile_banner
    : "/Life/job/Nogod Cover Photo.png";

  const handleApply = () => {
    navigate(`/Job/job-apply/${job_post?.id}`);
  };

  const handleRemove = () => {
    savePost(job_post.id)
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
      refetch();
      showToast({title:"job post removed successfully"})
    }
  },[isSuccess])
  const data = { ...job_post };

  const location_info = {
    district: job_post?.district || {},
    division: job_post?.division || {},
    job_address: job_post.job_address,
  };
  

  return (
    <div className="position-relative job-details">
      <CompanyBanner profile_banner={bannerPhoto} profile_picture={profilePhoto} />
      {isLoading && <BubbleLoader />}
 
      <br />
      <div className="jobInformation px-0 px-md-2">
        <div ref={printContent}>
          <Tabs
            id="food-order-history"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 mt-3 food-tab "
            transition={true}
          >
            <Tab eventKey="job_info" title="Job Info">
              <JobInformation data={data} />
            </Tab>
            <Tab eventKey="location" title="Location">
              <LocationInfo data={location_info} />
            </Tab>
            <Tab eventKey="company_info" title="Company">
              <CompanyInfo data={company_profile_section} />
            </Tab>
          </Tabs>
        </div>

        <br />
        <div style={{ fontSize: "0.9rem" }}>
          <p className="text-danger text-center">{error}</p>
        </div>
          
        <div className="jobCategoriesItemBtn jobDetailsBtn noprint">
          <button
            disabled={job_apply_count == "1"}
            onClick={handleApply}
            type="button"
          >
            {job_apply_count == "1" ? "Applied" : "Apply"}
          </button>
          <button onClick={handleRemove} type="button">
            Remove
          </button>
          <button type="button">Share</button>
          <button onClick={(e) => handlePrint(e, printContent, printContainer)}>
            Print
          </button>
        </div>

        <br />
      </div>
      <iframe
        ref={printContainer}
        title="print"
        style={{ opacity: "0" }}
      ></iframe>

      <br />
      <br />
    </div>
  );
};

export default SaveJobDetails;
