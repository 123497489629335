import React from 'react';
import CompanySingleInfo from '../../Components/Common/CompanySingleInfo';

const GeneralInfo = ({data}) => {
    const {
        company_name,
        website,
        company_email,
        company_phone,
        company_EINN,
        
      } = data;
    return (
        <div
        style={{ fontSize: "14px" }}
        className="company-profile applicantProfile p-2 my-3"
      >
        <CompanySingleInfo title="Company Name" value={company_name|| "Not Found"} />
        <CompanySingleInfo title="Company EINN" value={company_EINN || "Not Found"} />
        <CompanySingleInfo isLink={true} title="Email" value={company_email} />
        <CompanySingleInfo title="Phone" value={company_phone || "Not Found"}  />
        <CompanySingleInfo isLink title="Website" value={website} />
        

       
        <br />
      </div>
    );
};

export default GeneralInfo;