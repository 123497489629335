import React from "react";
import QRCode from "react-qr-code";
export const Footer = () => {
  return (
    <div className="mt-4 dFlexBetween mediumTextSize">
      <div>
        <div>123-456-78900</div>
        <div>contact@uviomcorporate.com</div>
        <div>www.uviomcorporate.com</div>
        <div>Dhaka,Bangladesh</div>
      </div>
      <div>
        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 80,
            width: "100%",
          }}
        >
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={window.location.href}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>
    </div>
  );
};
