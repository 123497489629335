import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { PostApiCalls } from "src/Common/apiCall/PostApiCalls";
import { InputField } from "../Components/InputField";

export const StoringInputs = ({ storingInputsArg }) => {
  const {
    isLoading,
    setIsLoading,
    setIsEditableFalse,
    dataReload,
    componentMainUrl,
    formData,
    inputHandleChange,
  } = storingInputsArg;
  const dateRef = useRef();

  // console.log("edit id ", formData && formData.id);
  const updateFormFunc = (e) => {
    e.preventDefault();
    const formArg = {
      formData: formData,
      Url: componentMainUrl,
      setIsLoading: setIsLoading,
      dataReload: dataReload,
      setIsEditableFalse: setIsEditableFalse,
    };
    PostApiCalls(formArg);
  };

  return (
    <form onSubmit={updateFormFunc}>
      <div className="container-fluid mainProfileSelect mb-4">
        <Row>
          <Col className="basicInfoSelectCol">
            <Form.Select
              className="mainProfileInput m-0"
              name="gender"
              value={formData.gender ? formData.gender : ""}
              onChange={inputHandleChange}
            >
              <option className="selectPlaceholder">gender</option>
              <option value="1">Male</option>
              <option value="2">Female</option>
            </Form.Select>
            <label htmlFor="gender" className="selectDownArrow">
              <FontAwesomeIcon
                className="ms-2 text-white"
                size="lg"
                icon={faPlusCircle}
              ></FontAwesomeIcon>
            </label>
          </Col>
        </Row>
      </div>
      <div className="container-fluid mainProfileSelect mb-4 ">
        <Row>
          <Col className="basicInfoSelectCol">
          <Form.Control
              className="mainProfileInput"
              type="text"
              placeholder="Birthday"
              ref={dateRef}
              onFocus={() => dateRef.current.type = "date"}
              onBlur={() => dateRef.current.type = "text"}
              value={formData.birthday}
              name="birthday"
              onChange={inputHandleChange}
            />
          </Col>
        </Row>
      </div>
      <div className="container-fluid mainProfileSelect mb-4">
        <Row>
          <Col className="basicInfoSelectCol">
            <Form.Select
              className="mainProfileInput m-0"
              name="religion"
              value={
                formData.religion ? formData.religion : ""
              }
              onChange={inputHandleChange}
            >
              <option className="selectPlaceholder">religion</option>
              <option value="Muslims">Muslims</option>
              <option value="Christians">Christians</option>
              <option value="Hindus">Hindus</option>
              <option value="Buddhists">Buddhists</option>
            </Form.Select>
            <label htmlFor="gender" className="selectDownArrow">
              <FontAwesomeIcon
                className="ms-2 text-white"
                size="lg"
                icon={faPlusCircle}
              ></FontAwesomeIcon>
            </label>
          </Col>
        </Row>
      </div>
      <InputField
        inputPlaceholder="timezone"
        textInputName="timezone"
        textInputValue={formData.timezone ? formData.timezone : ""}
        changeHandle={inputHandleChange}
      />
      <div className="py-3 mainProfileBtnDiv">
        <button
          onClick={setIsEditableFalse}
          className="mainProfileBtn btnCandel me-3"
        >
          Cancel
        </button>
        <button
          className={`mainProfileBtn btnSave  ${isLoading ? "disabled" : ""} `}
        >
          Save
        </button>
      </div>
    </form>
  );
};
