import React, { useRef } from "react";
import { useSelector } from "react-redux";
import MessageSideBar from "../MessageSideBar/MessageSideBar";

export const ChatListPopup = () => {
  const { showChatListPopup } = useSelector((store) => store.messengerData);
  const messagesEndRef = useRef(null);

  return (
    <>
      {showChatListPopup ? (
        <div className={`popup__chatList-main box-shadow}`}>
          <div
            className="popup__chat__content-body"
            style={{ overflowX: "hidden" }}
          >
            <MessageSideBar />
            <div ref={messagesEndRef}></div>
          </div>
          <div
            className="d-flex align-items-center h-1"
            style={{ gap: "8px" }}
          ></div>
        </div>
      ) : null}
    </>
  );
};
