import axios from "axios";
import Swal from "sweetalert2";

export const DeleteApiCalls = (DeleteApiCallsArg) => {
    const { id, title, url, previousDataState,info } = DeleteApiCallsArg
    Swal.fire({
        title: 'Do you want to save the changes?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            axios
                .delete(`${url}/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    }
                )
                .then((res) => {
                    if (res.data.status === 1) {
                        const filterData = previousDataState.data.filter(
                            (item) => Number(item.id) !== Number(id)
                        )
                        previousDataState.setData(filterData)
                    } else {
                        console.log("err")
                    }
                })
                .catch((err) => {
                    console.log(err)
                });
        }
    })
    // swal({
    //     text: title,
    //     cancelButtonColor: '#d33',
    //     buttons: ["Cancel", "Delete"],
    //     cancel: {
    //         text: "Cancel",
    //         value: null,
    //         visible: true,
    //         className: "",
    //         closeModal: true,
    //     },
    //     confirm: {
    //         text: "OK",
    //         value: true,
    //         visible: true,
    //         className: "",
    //         closeModal: true
    //     },
    // }).then((result) => {
    //     if (result === true) {
    //         axios
    //             .delete(`${url}/${id}`,
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    //                     },
    //                 }
    //             )
    //             .then((res) => {
    //                 if (res.data.status === 1) {
    //                     const filterData = previousDataState.data.filter(
    //                         (item) => item.id !== id
    //                     )
    //                     previousDataState.setData(filterData)
    //                 } else {
    //                     console.log("err")
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log(err)
    //             });
    //     }

    // })

}