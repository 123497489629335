import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import pickup from "./../../assets/pickup.svg";
import drop from "./../../assets/drop.svg";
import avatar from "./../../assets/avatar.svg";
import penPaper from "./../../assets/penpaper.svg";
import "./deliveryForm.css";

function DeliveryForm() {
  const { pathname:url } = useLocation();

  return (
    <div className="deliveryForm">
      <Form>
        <Row>
          <Col md={6}>
            <Form.Group className="mt-3">
              <Form.Label>Pickup Location</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <img className="dark-invert" src={pickup} alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Bronx, New York -10472"
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mt-3">
              <Form.Label>Drop Off Location</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <img className="dark-invert" src={drop} alt="" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Manhattan Area, New York"
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mt-3">
              <Form.Label>Sender Name</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <img className="dark-invert" src={avatar} alt="" />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="eg. john doe" />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mt-3">
              <Form.Label>Receiver Name</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <img className="dark-invert" src={avatar} alt="" />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="eg. john doe" />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mt-3">
              <Form.Label>Sender Zip Code</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <img className="dark-invert" src={penPaper} alt="" />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="3234" />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mt-3">
              <Form.Label>Receiver Zip Code</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <img className="dark-invert" src={penPaper} alt="" />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="4534" />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mt-3">
              <Form.Label>Sender Email Address</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <img className="dark-invert" src={penPaper} alt="" />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="demo@example.com" />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mt-3">
              <Form.Label>Receiver Email Address</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <img className="dark-invert" src={penPaper} alt="" />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="demo@example.com" />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mt-3">
              <Form.Label>Sender Phone Number</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <img className="dark-invert" src={penPaper} alt="" />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="018XXXXXXXX" />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mt-3">
              <Form.Label>Receiver Phone Number</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <img className="dark-invert" src={penPaper} alt="" />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="018XXXXXXXX" />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <div className="text-center mt-4 mt-sm-5">
          <Button
            as={Link}
            to={`${url}/productinfo`}
            className="btn__ride px-5"
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
}
export default DeliveryForm;
