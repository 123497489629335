/* eslint-disable eqeqeq */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import processToArray from "src/app/helper/processToArray";
import {
  loadLatestMessagesUrl,
  loadOldMessageUrl,
  usersMessagesListUrl,
} from "src/Projects/Life/Common/CommonUrl";
import {
  create_response_message,
  create_user,
  find_person_index,
} from "src/Projects/Life/LifePages/MainPage/NestedRouteOnDashBodr/Message/Common/message_apiCall";

const initialState = {
  chatUserList: [],
  currentPage: 1,
  isLoading: false,
  messageLoading: false,
  hasMore: false,
  error: "",
  messageNotification: false,
  chatList: {},
  current_chatting_user: {},
  current_chatting_messages: [],
  current_chatting_hasMore: true,
  newMessageBool: false,
  changeUserList: false,
  showMessagePopup: false,
  showChatListPopup: false,
};

export const getChatUserList = createAsyncThunk(
  "life/messenger_data",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState().messengerData;
    const url = `${usersMessagesListUrl}?page=${state.currentPage}`;

    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getSingleUserChat = createAsyncThunk(
  "life/userChat_data",

  async ({ user_id, add_old }, thunkAPI) => {
    const { chatList, current_chatting_messages } =
      thunkAPI.getState().messengerData;
    const previousChat = chatList[`chat_list_${user_id}`];

    let url = loadLatestMessagesUrl;
    let formData = { user_id };

    if (previousChat && add_old) {
      url = loadOldMessageUrl;
      delete formData.user_id;
      formData.to_user = user_id;
      formData.old_message_id = current_chatting_messages[0].id;
      // console.log(current_chatting_messages[0].id, url, user_id);
    }
    try {
      const resp = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

const messengerSlice = createSlice({
  name: "messenger",
  initialState,

  reducers: {
    setChatUserList: (state, action) => {
      const data = action.payload;
      state.search_result = data;
    },

    increaseCurrentPage: (state) => {
      const prev = state.currentPage;
      state.currentPage = prev + 1;
    },

    destroyMessengerData: (state) => {
      state = initialState;
    },

    setMessageNotification: (state) => {
      const prev = state.messageNotification;
      state.messageNotification = !prev;
    },

    storeSingleMessage: (state, action) => {
      const {
        response = {},
        type,
        user_id,
        message_content = [],
      } = action.payload;

      const newMessage = create_response_message(response, message_content);

      const prevMessage = state.current_chatting_messages || [];
      if (type === "me") {
        const messageOpArr = [...prevMessage];
        messageOpArr.pop();
        messageOpArr.push(newMessage);
        state.current_chatting_messages = [...messageOpArr];
      } else {
        if (response.from_user.id === state.current_chatting_user?.id) {
          state.current_chatting_messages = [...prevMessage, newMessage];
        }
      }

      const userPrevMessage = state.chatList[`chat_list_${user_id}`] || [];
      state.chatList[`chat_list_${user_id}`] = [...userPrevMessage, newMessage];
      state.newMessageBool = !state.newMessageBool;
    },

    storeMyMessage: (state, action) => {
      const newMessage = action.payload;
      const prevMessages = state.current_chatting_messages;
      state.current_chatting_messages = [...prevMessages, newMessage];
      state.newMessageBool = !state.newMessageBool;
    },

    setCurrentChattingUser: (state, action) => {
      state.current_chatting_user = action.payload;
    },

    setCurrentChatting: (state, action) => {
      state.current_chatting_messages = action.payload;
    },
    setCurrentChattingHasMore: (state) => {
      state.current_chatting_hasMore = true;
    },

    highlightCurrentChatUser: (state, action) => {
      const { type, res } = action.payload;
      const userList = [...state.chatUserList];

      if (type === "me") {
        const index = find_person_index(res.to_user.id, userList);
        if (index === -1) {
          userList.unshift(create_user(res));
        } else {
          const currentUser = userList.splice(index, 1)[0];
          currentUser.created_at = new Date().toISOString();
          currentUser.content = res.content;
          userList.unshift(currentUser);
        }
      } else {
        const index = find_person_index(res.from_user.id, userList);
        if (index === -1) {
          userList.unshift(create_user(res));
        } else {
          const currentUser = userList.splice(index, 1)[0];
          currentUser.created_at = new Date().toISOString();
          currentUser.content = res.content;
          userList.unshift(currentUser);
        }
      }

      state.chatUserList = [...userList];
    },

    setShowMessagePopup: (state, action) => {
      state.showMessagePopup = action.payload;
    },
    toggleMessagePopup: (state) => {
      state.showMessagePopup = !state.showMessagePopup;
    },
    setChatListPopup: (state, action) => {
      if (state.showMessagePopup) {
        state.showMessagePopup = false;
      }
      state.showChatListPopup = action.payload;
    },
    toggleChatListPopup: (state) => {
      if (state.showMessagePopup) {
        state.showMessagePopup = false;
      }
      state.showChatListPopup = !state.showChatListPopup;
    },
  },

  extraReducers: {
    [getChatUserList.pending]: (state) => {
      state.isLoading = true;
    },

    [getChatUserList.fulfilled]: (state, action) => {
      state.isLoading = false;

      let next_page = action.payload?.next_page_url ? true : false;
      state.hasMore = next_page;
      const response_data = processToArray(action.payload);
      const previous_data = state.chatUserList;

      const total_data = [...previous_data, ...response_data];
      total_data.sort((a, b) => b.id - a.id); // sorting data using id gater than to less than
      if (previous_data.length < 1) {
        state.chatUserList = [...total_data];
        state.changeUserList = !state.changeUserList;
      } else {
        state.chatUserList = [...total_data];
      }
    },
    [getChatUserList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // get single user chat list
    [getSingleUserChat.pending]: (state) => {
      state.messageLoading = true;
    },
    [getSingleUserChat.fulfilled]: (state, action) => {
      state.messageLoading = false;
      const { user_id, add_old } = action.meta?.arg;
      const { code, messages } = action.payload;

      if (code === 200) {
        if (add_old) {
          if (messages.length < 1) {
            state.current_chatting_hasMore = false;
          } else {
            const prev = state.current_chatting_messages;
            state.current_chatting_messages = [...messages, ...prev];
          }
        } else {
          state.chatList[`chat_list_${user_id}`] = [...messages];
          state.current_chatting_messages = [...messages];
        }
      }
    },
    [getSingleUserChat.rejected]: (state, action) => {
      state.messageLoading = false;
      state.error = action.payload;
    },
  },
});

export default messengerSlice.reducer;

export const {
  setChatUserList,
  destroyMessengerData,
  increaseCurrentPage,
  setMessageNotification,
  storeSingleMessage,
  setCurrentChattingUser,
  setCurrentChatting,
  setCurrentChattingHasMore,
  storeMyMessage,
  highlightCurrentChatUser,
  setShowMessagePopup,
  toggleMessagePopup,
  setChatListPopup,
  toggleChatListPopup,
} = messengerSlice.actions;
