import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import NavSideBar from "./NavSideBar/NavSideBar";
import useUbizzStore from "../../hooks/useUbizzStore";
import { isEmpty } from "lodash";

export const Categories = () => {
  const [show, setShow] = useState(false);
  const [categoryMenu, setcategoryMenu] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { pathname } = useLocation();
  const path = "/" + pathname.split("/")[1];

  const categoryMenuRef = useRef();
  const showHandleCategory = () => {
    setcategoryMenu(true);

    categoryMenuRef.current.style.display = "table";
  };
  const hideHandleCategory = () => {
    setcategoryMenu(false);
    categoryMenuRef.current.style.display = "none";
  };
  const handleCategoryClick = () => {
    if (categoryMenu) {
      hideHandleCategory();
    } else {
      showHandleCategory();
    }
  };
  useEffect(() => {
    hideHandleCategory();
  }, []);

  const { categoriesInfo } = useUbizzStore();
  const clickOutSide = () => {
    setTimeout(() => {
      hideHandleCategory();
    }, 400);
  };
  return (
    <>
      <button
        onClick={handleShow}
        className="corporate__nav-subItem eco-btn d-lg-none px-0"
      >
        Categories
      </button>
      <button
        onClick={handleCategoryClick}
        onBlur={clickOutSide}
        type="button"
        className="corporate__nav-subItem eco-btn category-btn category-menu position-relative d-none d-lg-block"
      >
        Categories
        <FontAwesomeIcon
          icon={categoryMenu ? faChevronUp : faChevronDown}
          className="ms-2"
        />
        {/* for main item  */}
        <ul className="" ref={categoryMenuRef}>
          {categoriesInfo &&
            !isEmpty(categoriesInfo) &&
            categoriesInfo.map((item, index) => (
              <li key={`${index}a`}>
                <span className="w-100 d-flex justify-content-between align-items-center">
                  <Link
                    to={`${path}/category/category-wise-product/${item.slug}`}
                  >
                    {item.CategoryName}
                  </Link>
                  <FontAwesomeIcon
                    className="ms-2 submenu-item-icon"
                    icon={faChevronRight}
                  />
                </span>
                {/* for sub item  */}
                <ul className="submenu">
                  {item?.has_sub_category?.map((subItem, index) => (
                    <li key={`${index}b`}>
                      <span className="w-100 d-flex justify-content-between align-items-center">
                        <Link
                          to={`${path}/category/sub-category-wise-product/${subItem.slug}`}
                        >
                          {subItem.subcategory_name}
                        </Link>
                        <FontAwesomeIcon
                          className="ms-2 submenu-item-icon"
                          icon={faChevronRight}
                        />
                      </span>
                      {/* for sub sub item  */}
                      <ul className="submenu">
                        {subItem?.has_sub_sub_category?.map(
                          (subSubItem, index) => (
                            <li key={`${index}c`}>
                              <span className="w-100 d-flex justify-content-between align-items-center">
                                <Link
                                  to={`${path}/category/sub-sub-category-wise-product/${subSubItem.slug}`}
                                >
                                  {subSubItem.sub_sub_category_name}
                                </Link>
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
        </ul>
      </button>
      <NavSideBar
        show={show}
        handleClose={handleClose}
        categoriesInfo={categoriesInfo}
      ></NavSideBar>
    </>
  );
};
