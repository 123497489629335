
  const useJobStoreGlobal = () => {
  const sortA_Z = (a, b) => {
    let fa = a.name.toLowerCase(),
      fb = b.name.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  };
  return {
    sortA_Z
  };
};

export default useJobStoreGlobal;
