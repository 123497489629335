import { Container } from "react-bootstrap";
import Features from "./../components/Common/Features";

import stock1 from "./../assets/stock1.jpg";
import stock3 from "./../assets/stock3.jpg";
import stock4 from "./../assets/stock4.jpg";
import overlay from "./../assets/overlay.png";

function Safety() {
  return (
    <div id="wrapper"
    // style={{ backgroundImage: `url(${overlay})` }}
    >
      <div className="py-2 py-lg-4" />
      <Container>
        {/* Features Start */}
        <Features
          imageSrc={stock1}
          isReversed={true}
          title="SAFETY FIRST"
          desc="Your safety Always comes first. We look out for you
                before, during, and after every single ride. And we monitor
                rides to help you get where you need to go. If you ever need it,
                we're standing by, ready to help."
        />
        {/* Features End */}
        <div className=" py-3 py-lg-5" />
        {/* Features Start */}
        <Features
          imageSrc={stock3}
          title="Helping to keep each other safe during COVID-19"
          desc="We're actively monitoring the coronavirus (COVID-19) situation
          and are continually working to help keep those who rely on our
          platform healthy and safe."
        >
          <a href="#" className="link-unstyled">
            Read More
          </a>
        </Features>
        {/* Features End */}
        <div className=" py-3 py-lg-5" />
        {/* Features Start */}
        <Features
          imageSrc={stock4}
          isReversed={true}
          title="Monitoring Oue Drone Cops"
          desc="The application of drones equipped with optical, zoom, and/or
          thermal cameras allow law enforcement agents to monitor
          unfolding crime scenes more accurately and at a safer distance.
          A quickly deployable camera drone allows officials to have a
          better vantage point during times of chaotic situations where
          deploying ground personnel is too risky."
        />
        {/* Features End */}
        <div className="py-3 py-lg-5" />
      </Container>
    </div>
  );
}
export default Safety;
