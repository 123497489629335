import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SingleItem = ({ item,componentMainIcon}) => {
  return (
    <div className="d-flex justify-content-between mb-4">
    <div className="d-flex">
      <div className='me-3 border  p-1 myIconCircle'>
        <FontAwesomeIcon icon={componentMainIcon} />
      </div>
      <div>
        <h5>{item.apps_games_name}</h5>
       
      </div>
    </div>
  </div>
  );
};
