import React from "react";
import { Col, Row } from "react-bootstrap";
import { Route } from "react-router-dom";
import { Routes as Switch } from "react-router-dom";
import JobPostContainer from "../JobPostContainer/JobPostContainer";

const JobPostPage = () => {
  return (
    <div>
      <Row className="g-2">
        <Col xs={12}>
          <Switch>
            <Route
              index
              element={<JobPostContainer title="Recommended Jobs" />}
            />

            <Route
              path={`/:categoryName`}
              element={<JobPostContainer title="Recommended Jobs" />}
            />

            <Route
              path={`/categories/:categoryName/:categoryId`}
              element={<JobPostContainer />}
            />

            <Route
              path={`/sub-categories/:categoryName/:categoryId`}
              element={<JobPostContainer isSub={true} />}
            />
          </Switch>
        </Col>
      </Row>
    </div>
  );
};

export default JobPostPage;
