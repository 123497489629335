import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import SingleVideoCard from "../../Components/SingleVideoCard";
import SingleVideoCardSkeleton from "../../common/SingleVideoCardSkeleton";
import { getRelatedToCategory } from "src/features/view/viewDataSlice";

function SuggestedVideo({ currentCategoryId, currentVideoId }) {
  const dispatch = useDispatch();
  const {
    relatedToCategory: {
      data: { data: relatedVideos },
    },
  } = useSelector((store) => store.viewData);

  useEffect(() => {
    dispatch(getRelatedToCategory(currentCategoryId));
  }, [currentCategoryId, dispatch]);

  const relatedVideo = relatedVideos?.filter(
    (el) => el.id !== parseInt(currentVideoId)
  );

  return (
    <CardWrapper>
      {!relatedVideo ? (
        <>
          <SingleVideoCardSkeleton />
          <SingleVideoCardSkeleton />
          <SingleVideoCardSkeleton />
        </>
      ) : (
        relatedVideo.map((video, index) => (
          <SingleVideoCard
            key={index}
            id={video.id}
            userId={video.user_id}
            created_at={video.created_at}
            channelName={video.channel_profile_section.channel_name}
            channelCategory={
              video.channel_profile_section.channel_category.name
            }
            videoTitle={video.title}
            thumbnail={video.thumbnail}
            channelProfile={video.channel_profile_section.photo}
            viewCount={video.video_views_count}
          />
        ))
      )}
    </CardWrapper>
  );
}
export default SuggestedVideo;

const CardWrapper = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;

  /* 576px */
  @media (max-width: 36em) {
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  }
`;
