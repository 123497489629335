import styled from "styled-components";

function SponsoredSection() {
  return (
    <Wrapper>
      <div className="fw-500 mt-n1">Sponsored</div>
      <img
        src="https://i.ibb.co/yRCGR5Z/Whats-App-Image-2022-11-07-at-22-41-42.jpg"
        className="img-fluid"
        alt="Sponsored"
      />
      <img
        src="https://i.ibb.co/kyRgCVp/sponc-2.jpg"
        className="img-fluid"
        alt="Sponsored"
      />
      <img
        src="https://i.ibb.co/pWJVT44/sponc3.jpg"
        className="img-fluid"
        alt="Sponsored"
      />

      {/* <iframe
        width="100%"
        src="https://www.youtube.com/embed/0rvg1p-9eJI"
        title="YouTube video player"
      ></iframe> */}
    </Wrapper>
  );
}
export default SponsoredSection;

const Wrapper = styled.div`
  margin-top: 1rem;

  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
`;
