import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Form, Modal, InputGroup } from "react-bootstrap";
import { useGetVideoDetailsQuery } from "src/app/services/viewApi/viewGetApi";
import styled from "styled-components";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";
import { useSelector } from "react-redux";
import _ from "lodash";
import compress_image from "src/utilities/compress_image";
import {
  updateVideoPostUrl,
  videoThumbnailImg,
} from "../common/view_commonUrl";
import axiosRequest from "../common/axiosRequest";
import { compare_2_Object } from "src/utilities/utilities";
import { updateVideoPost } from "src/features/view/viewDataSlice";
import { useDispatch } from "react-redux";
import { api } from "src/app/services/api";
import CreatePlaylistContainer from "./CreatePlaylistContainer";
import Select from "react-select";

const initialData = {
  title: "",
  description: "",
  playlist_id: "",
  restriction: "",
  kids: "",
  tag: "",
  video_status: "",
  video_category_id: "",
};

function EditVideoDetailsModal({ show, handleClose, videoId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [postData, setPostData] = useState(initialData);
  const [thumbnail, setThumbnail] = useState();
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [selectedVideoCategory, setSelectedVideoCategory] = useState(null);
  const dispatch = useDispatch();
  const { data } = useGetVideoDetailsQuery(videoId);

  const { ownPlaylist, videoCategories } = useSelector(
    (store) => store.viewData
  );

  useEffect(() => {
    if (
      !_.isEmpty(data) &&
      !_.isEmpty(ownPlaylist) &&
      !_.isEmpty(videoCategories)
    ) {
      setPostData((prevData) => {
        return {
          ...prevData,
          title: data.title,
          description: data.description,
          playlist_id: data.playlist_id,
          restriction: data.restriction,
          kids: data.kids,
          tag: data.tag,
          video_status: data.video_status,
          video_category_id: data.video_category_id,
        };
      });

      setSelectedPlaylist({
        value: +data.playlist_id,
        label: ownPlaylist.find((p) => p.id === parseInt(data.playlist_id))
          .name,
      });

      setSelectedVideoCategory({
        value: +data.video_category_id,
        label: videoCategories.find(
          (p) => p.id === parseInt(data.video_category_id)
        ).name,
      });
    }
  }, [data, ownPlaylist, videoCategories]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const prevData = { ...postData };
    prevData[name] = value;
    setPostData(prevData);
  };

  const afterSuccess = (data) => {
    const thumbnail = data.video_update.thumbnail;
    dispatch(updateVideoPost({ thumbnail, ...postData, videoId }));
    dispatch(api.util.invalidateTags(["videoDetails"]));
    setIsLoading(false);
    handleClose();
  };
  const afterReject = (data) => {
    setIsLoading(false);
    handleClose();
    throw Error("Post Update Failed");
  };

  const handleImageChange = (event) => {
    const name = event.target.name;
    const value = event.target.files[0];
    if (name === "thumbnail") {
      setThumbnail(value);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    // Converting state into javascript Data
    const formData = new FormData();
    formData.append("title", postData.title);
    formData.append("description", postData.description);
    formData.append("playlist_id", selectedPlaylist.value);
    formData.append("restriction", postData.restriction);
    formData.append("kids", postData.kids);
    formData.append("tag", postData.tag);
    formData.append("video_status", postData.video_status);
    formData.append("video_category_id", postData.video_category_id);

    // they don't exit on state but they must be send to server (thumbnail is optional)
    formData.append("key_search", postData.tag);

    // Compressing the thumbnail before sending to the server \ compress func require array of file and return array of file
    if (thumbnail) {
      const compressed_thumbnail = await compress_image([thumbnail]);
      formData.append("thumbnail", compressed_thumbnail[0]);
    }

    const _forCompare = {
      title: data.title,
      description: data.description,
      playlist_id: data.playlist_id,
      restriction: data.restriction,
      kids: data.kids,
      tag: data.tag,
      video_status: data.video_status,
      video_category_id: data.video_category_id,
    };

    // request only if there is any changes
    if (!compare_2_Object(_forCompare, postData) || thumbnail) {
      axiosRequest({
        url: updateVideoPostUrl + videoId,
        method: "POST",
        formData,
        afterSuccess,
        afterReject,
      });
    } else {
      handleClose();
      setIsLoading(false);
    }
  };

  const handlePlaylistChange = (event) => {
    setSelectedPlaylist(event);
    const prevData = { ...postData };
    prevData["playlist_id"] = event.value;
    setPostData(prevData);
  };

  const handleVideoCategoryChange = (event) => {
    setSelectedVideoCategory(event);
    const prevData = { ...postData };
    prevData["video_category_id"] = event.value;
    setPostData(prevData);
  };

  return (
    <Modal className="viewModal" centered show={show} onHide={handleClose}>
      {isLoading || !data ? (
        <div className="py-5">
          <DottedCircleSpinner />
        </div>
      ) : (
        <ModalWrapper>
          <Modal.Header className="stickyParts z-index-1">
            <button className="text-orange" onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="modal-title">Edit Video Details</div>

            <button
              className="save save-modal"
              onClick={handleSubmit}
              type="submit"
            >
              Save
            </button>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <img
                className="thumbnail"
                src={
                  thumbnail
                    ? URL.createObjectURL(thumbnail)
                    : videoThumbnailImg + data.thumbnail
                }
                alt=""
              />
              <input
                type="file"
                onChange={handleImageChange}
                name="thumbnail"
                id="thumbnail"
                accept="image/*"
                hidden
              />
              <div className="text-center">
                <label className="select-image" htmlFor="thumbnail">
                  Select Image
                </label>
              </div>

              <Form.Group className="mb-3">
                <Form.Label>Video Title</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  value={postData.title}
                  name="title"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Video Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={handleChange}
                  value={postData.description}
                  name="description"
                />
              </Form.Group>

              {ownPlaylist && (
                <Form.Group className="mt-2 mt-lg-3 ">
                  <Form.Label className="mb-0">Select Playlist</Form.Label>
                  <InputGroup>
                    <Select
                      options={ownPlaylist.map((playlist) => ({
                        value: playlist.id,
                        label: playlist.name,
                      }))}
                      onChange={handlePlaylistChange}
                      value={selectedPlaylist}
                      name="playlist_id"
                      className="flex-grow-1 text-black"
                    />

                    <InputGroup.Text className="py-0">
                      <CreatePlaylistContainer />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              )}

              {videoCategories && (
                <Form.Group className="mt-2 mt-lg-3">
                  <Form.Label className="mb-0">
                    Select Video Category
                  </Form.Label>

                  <Select
                    options={videoCategories.map((category) => ({
                      value: category.id,
                      label: category.name,
                    }))}
                    onChange={handleVideoCategoryChange}
                    value={selectedVideoCategory}
                    name="playlist_id"
                    className="flex-grow-1 text-black"
                  />
                </Form.Group>
              )}

              <Form.Group className="mt-3">
                <Form.Label>Keyword's</Form.Label>
                <Form.Control
                  type="text"
                  name="tag"
                  value={postData.tag}
                  onChange={handleChange}
                  placeholder="keywords"
                />
              </Form.Group>

              <Form.Group className="mt-2 mt-lg-3">
                <Form.Label className="mb-0">Video Status</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  value={postData.video_status}
                  name="video_status"
                >
                  <option value="public"> Public </option>
                  <option value="private"> Private </option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mt-2 mt-lg-3">
                <Form.Label className="mb-0">For Kids?</Form.Label>
                <Form.Select
                  name="kids"
                  onChange={handleChange}
                  value={postData.kids}
                >
                  <option value="yes"> Yes, For Kids </option>
                  <option value="no"> No, Not for Kids </option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mt-2 mt-lg-3">
                <Form.Label className="mb-0">Age Restricted?</Form.Label>
                <Form.Select
                  name="restriction"
                  onChange={handleChange}
                  value={postData.restriction}
                >
                  <option value="18+"> Yes, 18+ </option>
                  <option value="18-"> No, Not 18+ </option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Modal.Body>
        </ModalWrapper>
      )}
    </Modal>
  );
}
export default EditVideoDetailsModal;

const ModalWrapper = styled.div`
  & .thumbnail {
    width: 100%;
    height: 10rem;
    object-fit: contain;
  }

  & .select-image {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    background-color: var(--light-gray);
    color: var(--text-info);
  }

  & .modal-header {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    gap: 0.5rem;
    position: sticky;

    & > button {
      background-color: transparent;
      border: 0;
    }

    & > .modal-title {
      margin: 0;
      font-size: 1.1rem;
    }
  }
`;
