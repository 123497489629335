import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGetBestTimePostQuery } from "src/app/services/lifeApi/bestTimeApi";
import {
  videoStoryFamilyUrl,
  videoStoryFriendUrl,
  videoStoryProfessionalUrl,
} from "src/Projects/Life/Common/CommonUrl";

const useBestTimeStoreGlobal = () => {
  const [postList, setPostList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { pathname } = useLocation();

  let Url = videoStoryFamilyUrl;

  if (pathname.includes("/life/professional-life")) {
    Url = videoStoryProfessionalUrl;
  } else if (pathname.includes("/life/friends-life")) {
    Url = videoStoryFriendUrl;
  }

  const {
    data: result,
    isLoading,
    error,
  } = useGetBestTimePostQuery({ page: currentPage, url: Url });
  const { data = [], hasMore } = result || {};
  useEffect(() => {
    if (data.length > 0) {
      setPostList(prevData => [...prevData,...data]);
    }
  }, [data]);

  return {
    postList,
    isLoading,
    error: error?.error,
    hasMore,
    setCurrentPage,
  };
};

export default useBestTimeStoreGlobal;
