/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "./banner.css";
import BannerItem from "./BannerItem";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getJobSlider } from "src/features/job/jobHomeDataSlice";

// const banners = ["/job-image/job3.jpg", "/job-image/job-banner2.png"];

const Banner = () => {
  const dispatch = useDispatch();
  const { jobSlider = [] } = useSelector((store) => store.jobHomeData);

  useEffect(() => {
    if (jobSlider.length < 1) {
      dispatch(getJobSlider());
    }
  }, []);

  return (
    <div className="job-banner-main mb-2 mb-md-2 row">
      <div className=".h-100 p-0">
        <Swiper
          modules={[Autoplay]}
          speed={1000}
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          effect={"fade"}
          className="mySwiper h-100"
        >
          {jobSlider.map((slider) => (
            <SwiperSlide key={slider.id}>
              <BannerItem data={slider} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="job-banner-overlay">
        <div className="d-flex flex-column justify-content-center align-items-start w-100 h-100 ps-0 ps-sm-4 m">
          <div className="display-6 mb-2 mb-lg-3 text-dark fw-500">
            Find Your Dream <br /> Corporate Life, With
          </div>
          <div className="display-5 fw-bold text-dark">UVIOM Job!</div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
