import "./workPackage.css";

const packages = [
  {
    title: "Basic",
    price: 50,
    subtitle: "This Contest. Always Did Design",
    subList: ["10 Page", "XD File", "Icon", "Presentation", "3 Day Delivery"],
  },
  {
    title: "Smart",
    price: 100,
    subtitle: "This Contest. Always Did Design",
    subList: ["10 Page", "XD File", "Icon", "Presentation", "5 Day Delivery"],
  },
  {
    title: "Gold",
    price: 150,
    subtitle: "This Contest. Always Did Design",
    subList: ["10 Page", "XD File", "Icon", "Presentation", "5 Day Delivery"],
  },
];

function WorkPackage() {
  return (
    <div className="workPackage">
      <div className="workPackage__top">
        <img src="https://i.ibb.co/XJVvZ3L/package.jpg" alt="" />
        <div>
          <h5>
            The Standard Chunk Of Lorem Ipsum Used Since The 1500s Is Reproduced
            Below For
          </h5>
          <p>
            Company name is bramble & bee. It's a Honey Company Logo. It was for
            a contest that I won here on Freelancer.com. it was a great
            experience doing this logo and working for this contest. Always did
            design
          </p>
        </div>
      </div>

      <div className="workPackage__bottom mt-3">
        <div>
          <h6>Work Process</h6>
          <ul className="ps-4">
            <li>UX Research</li>
            <li>Market Problem Stateman</li>
            <li>Making Solution</li>
            <li>Wireframe</li>
            <li>Mockup</li>
          </ul>
        </div>

        <div className="workPackage__list">
          {packages.map((packages, index) => (
            <div key={index} className="workPackage__pack py-2 border">
              <h5 className="text-center">
                {packages.title} <small>(${packages.price})</small>
              </h5>
              <hr className="my-2" />
              <p className="fw-500 p-2 mb-0">{packages.subtitle}</p>
              <hr className="my-2" />
              <ul className="ps-4">
                {packages.subList.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default WorkPackage;
