import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faFlag,
  faPlay,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import { videoThumbnailImg, channelLogoImg } from "./../common/view_commonUrl";
import { timeAgo } from "src/utilities/utilities";
import useAuth from "src/hooks/useAuth.js";
import { Dropdown } from "react-bootstrap";
import {
  useDeleteVideoPostMutation,
  useUpdateSaveStatusMutation,
} from "src/app/services/viewApi/viewPostApi";
import EditVideoDetailsModal from "./EditVideoDetailsModal";
import ReportVideoModal from "./ReportVideoModal";
import { useMemo } from "react";

const SingleVideoCard = (props) => {
  const {
    channelName,
    channelCategory,
    videoTitle,
    videoCategoryId,
    viewCount,
    thumbnail,
    channelProfile,
    id,
    userId,
    created_at,
    isSaved,
  } = props;

  const [showVideoDetailsModal, setShowVideoDetailsModal] = useState(false);

  const [showVideoReportModal, setShowVideoReportModal] = useState(false);

  const [_isSaved, setIsSaved] = useState(parseInt(isSaved));
  let { pathname } = useLocation();
  const { authUser } = useAuth();
  const dynamicPath = pathname.split("/")[1];
  const [deleteVideo] = useDeleteVideoPostMutation();
  const [updateSaveStatus] = useUpdateSaveStatusMutation();

  const getTimeAgo = useMemo(() => timeAgo(created_at), [created_at]);

  return (
    <>
      {showVideoDetailsModal && (
        <EditVideoDetailsModal
          show={showVideoDetailsModal}
          handleClose={() => setShowVideoDetailsModal(false)}
          videoId={id}
        />
      )}

      {showVideoReportModal && (
        <ReportVideoModal
          show={showVideoReportModal}
          handleClose={() => setShowVideoReportModal(false)}
          videoId={id}
        />
      )}

      <VideoCardWrapper>
        <div className="d-flex justify-content-between align-items-center mb-3 px-3 pt-3">
          <Link to={`/${dynamicPath}/channel/${userId}`}>
            <div className="d-flex">
              <img
                className="img-fluid rounded-circle chennelImg"
                src={
                  channelProfile
                    ? channelLogoImg + channelProfile
                    : "https://i.ibb.co/MprdZS6/user.png"
                }
                alt=""
                onError={(e) =>
                  (e.target.src = "https://i.ibb.co/MprdZS6/user.png")
                }
              />
              <div>
                <p title={channelName} className="channel__name">
                  {channelName}
                </p>
                <div className="smallTextSize view-yellow">
                  {channelCategory}
                </div>
              </div>
            </div>
          </Link>
          <div>
            <Dropdown>
              <Dropdown.Toggle
                aria-labelledby="toggle button"
                className="ToggleDesignClear"
              >
                <ThreeDots className="topNavIcon" size="15" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  onClick={() => {
                    updateSaveStatus(id);
                    setIsSaved((prev) => (prev ? 0 : 1));
                  }}
                >
                  <FontAwesomeIcon icon={faSave} /> &nbsp;
                  {_isSaved ? "Unsave" : "Save"}
                </Dropdown.Item>

                {parseInt(userId) === authUser.id ? (
                  pathname.includes("/view/channel/") && (
                    <>
                      <Dropdown.Item
                        onClick={() => setShowVideoDetailsModal(true)}
                      >
                        <FontAwesomeIcon icon={faEdit} /> Edit
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteVideo(id)}>
                        <FontAwesomeIcon icon={faTrash} /> Delete
                      </Dropdown.Item>
                    </>
                  )
                ) : (
                  <Dropdown.Item
                    onClick={() => setShowVideoReportModal(true)}
                    as="button"
                  >
                    <FontAwesomeIcon icon={faFlag} /> Report Video
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <Link
          className="thumbnail__container"
          to={`/${dynamicPath}/video/${id}`}
        >
          <img
            className="thumbnail"
            src={videoThumbnailImg + thumbnail}
            onError={(e) =>
              (e.target.src =
                "https://uviom-life-running.s3.ap-southeast-1.amazonaws.com/images/channel/video_thumbnail/18433d144b5xjpPqoPZozthumb.jpeg")
            }
            alt=""
          />
          <FontAwesomeIcon className="video__play" icon={faPlay} />
        </Link>
        <div className="px-3 pb-3">
          <p className="title">{videoTitle}</p>
          <div className=" d-flex text-gray ">
            <div className="viewCount">{viewCount ? viewCount : 0} View </div>
            <div>{created_at && getTimeAgo}</div>
          </div>
        </div>
      </VideoCardWrapper>
    </>
  );
};

export default SingleVideoCard;

const VideoCardWrapper = styled.div`
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border-radius: 0.3rem;
  max-width: 25rem;
  overflow: hidden;

  &:hover .thumbnail {
    transform: scale(1.05);
  }

  & .thumbnail__container {
    cursor: pointer;
    position: relative;

    & > .thumbnail {
      transition: 0.3s ease-out;
      width: 100%;
      max-width: 100%;
      max-height: 10rem;
      height: 10rem;
      object-fit: cover;
      /* 820 */
      @media (max-width: 51.25em) {
        height: 8rem;
      }

      /* 423px; */
      @media (max-width: 26.4375em) {
        height: 11rem;
      }
    }
    & > .video__play {
      font-size: 1.5rem;
      color: var(--clr-white);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & .channel__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15vw;
    margin-bottom: 0;

    /* 423px; */
    @media (max-width: 26.4375em) {
      max-width: 80vw;
    }
  }

  & .title {
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 0.2rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 30vw;

    /* 423px; */
    @media (max-width: 26.4375em) {
      max-width: 80vw;
    }
  }
`;
