import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { showOrderdetailsUrl } from "src/Projects/Corporate/utilities/CommonUrl";
import { Address } from "./Address";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { getInfo } from "src/Common/apiCall/GetApiCalls";
import "./MyOrderInvoice.css";
import { isEmpty } from "lodash";
import { rPrice } from "src/utilities/utilities";
export const MyOrderInvoice = () => {
  const printDiv = (bigReceiptDiv) => {
    var bigReceipt = document.getElementById(bigReceiptDiv).innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = bigReceipt;
    window.print();
    document.body.innerHTML = originalContents;
  };
  const { productId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [mapping_order, setmapping_order] = useState([]);
  const [info, setinfo] = useState();
  useEffect(() => {
    getInfo(setIsLoading, `${showOrderdetailsUrl}/${productId}`, setinfo);
  }, []);
  useEffect(() => {
    if (info) {
      setmapping_order(info.mapping_order);
    }
  }, [info]);
  useEffect(() => {
    if (!isEmpty(mapping_order)) {
      printDiv('PrintArea')
    }
  }, [mapping_order]);
  if (isLoading) {
    return null;
  }
  return (
    <>
      <div id="PrintArea">
        <div className="PrintArea position-relative">
       <div className="InvoiceLogo absolute-center"></div>

          <Header printDiv={printDiv} info={info} />
          <Address info={info} />
          <table className="table">
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">Item</th>
                <th scope="col">Price</th>
                <th scope="col">Qty.</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              {!isEmpty(mapping_order) &&
                mapping_order.map((data, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{data.product_order.product_name}</td>
                    <td>{rPrice(data.Price)}</td>
                    <td>{rPrice(data.Qty)}</td>
                    <td>{rPrice(data.TotalPrice)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="mt-4">
            <div className="total">
              <div className="dFlexBetween">
                <div>Total Price</div>
                <div>{info.TotalPrice}</div>
              </div>
              <div className="dFlexBetween">
                <div>Delivery Charge</div>
                <div>{info.delivery_charge}</div>
              </div>
            </div>
          </div>
          <div className="d-flex bar p-2 mt-4 ">
            <div className="text-align-right w-50">TOTAL</div>
            <div className="text-align-right w-50">
              {Number(info.TotalPrice) + Number(info.delivery_charge)}tk
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
