import React from "react";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SingleItem = ({ item}) => {
  return (
    <div className="d-flex justify-content-between my-4">
      <div className="d-flex">
        <div className="me-3 border  p-1 myIconCircle">
          <FontAwesomeIcon icon={faGraduationCap} />
        </div>
        <div>
          <h5>{item.level}</h5>
          <div>Studies at {item.institute} , {item.location}</div>

          <div className="mediumTextSize">{item.year}</div>
          <div>
            <small> {item.result? `result- ${item.result}`:""}</small>
          </div>
        </div>
      </div>
    </div>
  );
};
