import React from "react";
import HomeStore from "../../Pages/Home/HomeStore/HomeStore";
import "./banner.css";
import "src/Projects/Corporate/Components/Placeholder/placeholder.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import BannerSliderItem from "./BannerSliderItem";

const Banner = ({ banner_loading, vendor_loading, banner, vendor_slider }) => {
  return (
    <div className="container-fluid p-0 m-0 overflow-hidden">
      <div className="position-relative corporate__banner-main">
        <div className="corporate__banner category-slider parentSlider">
          {/* hero banner slider */}
          <Swiper
            navigation={true}
            modules={[Navigation, Autoplay]}
            speed={1000}
            loop={true}
            autoplay={true}
            effect={"fade"}
            className="mySwiper parentSliderSwiper"
          >
            {banner_loading ? (
              <SwiperSlide>
                <div className="placeholder-slider-box">
                  <span className="product-skeleton-img p-0 w-100 h-100 d-flex justify-content-center align-items-center ">
                    <img
                      src="/corporate/logo-outline.png"
                      alt=""
                      className="img img-fluid"
                    />
                  </span>
                </div>
              </SwiperSlide>
            ) : (
              <>
                {banner &&
                  banner.length > 0 &&
                  banner.map((data) => (
                    <SwiperSlide key={data.slider_id}>
                      <BannerSliderItem data={data} />
                    </SwiperSlide>
                  ))}
              </>
            )}
          </Swiper>
        </div>

        <HomeStore {...{ vendor_slider, vendor_loading }} />
      </div>
    </div>
  );
};

export default Banner;
