import React from "react";
import { useState } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { ProductImg } from "src/Projects/Corporate/Components/Common/ProductCard/ProductImg";
import RatingModal from "src/Projects/Corporate/Components/RatingModal/RatingModal";
import {
  productImgBase_m,
  productRatingUrl,
  shopRatingUrl,
} from "src/Projects/Corporate/utilities/CommonUrl";
import { rPrice } from "src/utilities/utilities";

export const OrderWiseProduct = ({ data }) => {
  const { product_order, Qty } = data;
  const { product_name, product_image } = product_order;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { pathname } = useLocation();

  const currentPath = "/" + pathname.split("/").slice(1, 3).join("/");

  return (
    <Row className="g-3 pb-3 border-bottom border-white border-2">
      <Col xl="6">
        <div className="favorite-card p-0 m-0">
          <div className="cart-item-img-box">
            {product_image ? (
              <ProductImg
                product_image={product_image}
                imgBase={productImgBase_m}
              />
            ) : (
              <div className="no_img">No img</div>
            )}
          </div>
          <div
            style={{ height: "100%" }}
            className="cart-text-box align-items-start d-flex flex-column justify-content-between   mediumTextSize"
          >
            <div className="mb-3">
              <div className="overme">{product_name}</div>
            </div>
            <div>Shop:Sample</div>
            <small>Quantity: {rPrice(Qty)}</small>
          </div>
        </div>
      </Col>
      <Col xl="6">
        <div className="d-flex align-items-center h-100">
          <div className="flex-grow-1 align-self-end">
            <div className="mb-1">
              <small>Estimate delivery Date</small> <br />{" "}
              <small>20 Jun 2022 - 22 Jun 2022</small>
            </div>
          </div>
          <div className="d-flex justify-content-evenly align-items-center flex-grow-1 flex-column h-100">
            <Dropdown>
              <Dropdown.Toggle className="ToggleDesignClear">
                <span className="send-code-btn cart-confirm-btn manage-order-btn py-1 text-dark">
                  Review
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="dark">
                <div className="ClickThreeDot mb-3 px-3 pb-3 pt-3">
                  <div
                    className="btnHoverBorder box-shadow justify-content-start py-2 ps-3 pe-2 "
                    tabIndex={0}
                    onClick={() => setShow("product")}
                  >
                    <div className="">
                      <h6 className="mb-0">Product Review</h6>
                    </div>
                  </div>

                  <div
                    className="btnHoverBorder box-shadow justify-content-start py-2 ps-3 pe-2 "
                    tabIndex={0}
                    onClick={() => setShow("store")}
                  >
                    <div className="  ">
                      <h6 className="mb-0">Store Review</h6>
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>

            <Link
              to={`${currentPath}/order-cancellation/100`}
              className="send-code-btn cart-confirm-btn manage-order-btn py-1 text-dark mt-3"
            >
              Cancel
            </Link>
          </div>
        </div>
      </Col>
      <RatingModal
        show={show}
        handleClose={handleClose}
        orderId={data.OrderID}
        url={show === "product" ? productRatingUrl : shopRatingUrl}
        id={show === "product" ? data?.ProductID : data.vendor_id}
        fieldName={show === "product" ? "product_id" : "vendor_id"}
      />
    </Row>
  );
};
