import React from 'react'
import { SinglePostSample } from '../SinglePostSample'

export const Post = () => {
  return (
    <>
     <SinglePostSample/>
      <SinglePostSample/>
      <SinglePostSample/>
    </>
  )
}
