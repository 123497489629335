import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { LifeIcon } from "src/DashBord/Icon/LifeIcon";
import useAuth from "src/hooks/useAuth";
import { TimeLines } from "./TimeLines";

const LeftCol = ({ leftColArg }) => {
  const { user_id } = leftColArg;
  const { authUser } = useAuth();

  return (
    <Col
      className="d-none  d-lg-block stickyContainer leftCol"
      id="timeline"
      md={3}
      lg={3}
    >
      <div className="LookMeWhiteIcon   box-shadow nav-bg-opacity stickyParts py-2 ps-3 d-block z-index-1">
        <div className="d-flex">
          <div className="LookMeButtonTypeIcon">
            <LifeIcon size="40" />
          </div>
          <Link to="/life/family-life">
            <div className="type-writer-text-box">
              <h5 className="mb-0">
                <span>UVIOM</span>
                <span className="text-orange">Life</span>
              </h5>
              <div className="lh-1 overflow-hidden type-writer-text logo-slogan">
                <Typewriter
                  options={{
                    strings: "Find Your Roots",
                    autoStart: true,
                    loop: true,
                  }}
                ></Typewriter>
              </div>
            </div>
          </Link>
        </div>
      </div>

      {String(user_id) !== String(authUser.id) ? (
        <TimeLines leftColArg={leftColArg} />
      ) : null}
    </Col>
  );
};

export default LeftCol;
