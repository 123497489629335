import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import FileAndTextInputBox from "./common/FileAndTextInputBox";
import InputBoxNationalCard from "./common/InputBoxNationalCard";
import { isEmpty } from "lodash";
import { updateMainBankImgUrl, updateMainBirthImgUrl, updateMainNidImgUrl, updateMainPassportImgUrl, updateMainProfileBasicInfoUrl } from "src/Projects/Life/Common/CommonUrl";
import { updateForm } from "./common/apiCalls";
const UviomMainProfileVerifiedDocs = ({ info, getData }) => {
  const [brithday, setBirthday] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setformData] = useState({
    "birth_certificate_id": "",
    "nid_number": "",
    "passport_id": "",
    "bank_number": "",
    "uviom_wallet_number": ""
  })
  useEffect(() => {
    if (!isEmpty(info)) {
      setformData({
        "birth_certificate_id": info && info.uviom_profile && info.uviom_profile.birth_certificate_id ? info.uviom_profile.birth_certificate_id : "",
        "nid_number": info && info.uviom_profile && info.uviom_profile.nid_number ? info.uviom_profile.nid_number : "",
        "passport_id": info && info.uviom_profile && info.uviom_profile.passport_id ? info.uviom_profile.passport_id : "",
        "bank_number": info && info.uviom_profile && info.uviom_profile.bank_number ? info.uviom_profile.bank_number : "",
        "uviom_wallet_number": info && info.uviom_profile && info.uviom_profile.uviom_wallet_number ? info.uviom_profile.uviom_wallet_number : "",

        "gender": info && info.uviom_profile && info.uviom_profile.gender ? info.uviom_profile.gender : "",
        "birth_day": info && info.uviom_profile && info.uviom_profile.birth_day ? info.uviom_profile.birth_day : "",
        "religion": info && info.uviom_profile && info.uviom_profile.religion ? info.uviom_profile.religion : "",
        "marital_status": info && info.uviom_profile && info.uviom_profile.marital_status ? info.uviom_profile.marital_status : ""
      })
    }
  }, [info])

  const inputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...formData };
    newData[name] = value;
    setformData(newData);
  };


  return (
    <div>
      <div className="p-1 p-sm-2 p-lg-3 pt-3 mainProfileEditSection my-mt">
        <div>
          <p>Verified Document</p>
        </div>

        <form className="p-1 p-lg-3">
          <Row xs={1}>
            {/* birth       */}
            <FileAndTextInputBox
              placeholder={"6958-6957-6586-6586"}
              sectionName="Birth Certificate"
              fileInputName={"birth_photo"}
              textInputName={"birth_certificate_id"}
              textInputValue={formData.birth_certificate_id}
              changeHandle={inputHandleChange}
              document={ info && info.uviom_images && info.uviom_images.birth_photo ? info.uviom_images.birth_photo : ""}
              uploadUrl={updateMainBirthImgUrl}
              dataReload={getData}
            />

            {/* National id */}
            <InputBoxNationalCard
              textInputName={"nid_number"}
              textInputValue={formData.nid_number}
              changeHandle={inputHandleChange}
              document={ info && info.uviom_images && info.uviom_images.nid_front_photo ? info.uviom_images.nid_front_photo : ""}
              uploadUrl={updateMainNidImgUrl}
              dataReload={getData}
            />

            {/* Passport  */}
            <FileAndTextInputBox
              placeholder={"6958-6957-6586-6586"}
              sectionName="Passport"
              fileInputName={"passport_photo"}
              textInputName={"passport_id"}
              textInputValue={formData.passport_id}
              changeHandle={inputHandleChange}
              document={ info && info.uviom_images && info.uviom_images.passport_photo ? info.uviom_images.passport_photo : ""}
              uploadUrl={updateMainPassportImgUrl}
              dataReload={getData}
            />

            {/* Driving  */}
            {/* <FileAndTextInputBox
              placeholder={"Driving License Number"}
              sectionName="Driving License"
              fileInputName={"driving_file"}
              textInputName={"driving"}

            /> */}

            {/* Bank card */}
            <FileAndTextInputBox
              placeholder={"Bank Card Number"}
              sectionName="Bank Card"
              fileInputName={"bank_photo"}
              textInputName={"bank_number"}
              textInputValue={formData.bank_number}
              changeHandle={inputHandleChange}
              document={ info && info.uviom_images && info.uviom_images.bank_photo ? info.uviom_images.bank_photo : ""}
              uploadUrl={updateMainBankImgUrl}
              dataReload={getData}
            />
            {/* uviom wallet */}
            {/* <FileAndTextInputBox
              placeholder={"UVIOM Wallet Number"}
              sectionName="UVIOM Wallet"
              fileInputName={"wallet_file"}
              textInputName={"uviom_wallet_number"}
              textInputValue={formData.uviom_wallet_number}
              changeHandle={inputHandleChange}
              document={ info && info.uviom_images && info.uviom_images.wallet_photo ? info.uviom_images.wallet_photo : ""}
            /> */}
          </Row>
          <br />
        </form>
      </div>
    </div>
  );
};

export default UviomMainProfileVerifiedDocs;
