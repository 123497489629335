import { Navigate, Route, Routes } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import LeftSidebar from "../LeftSidebar";
import PersonalInfo from "../PersonalInfo";
import ProfileInfo from "../ProfileInfo";
import "./myProfile.css";

function MyProfile() {
  return (
    <Row>
      <Col md={5} xl={3}>
        <LeftSidebar />
      </Col>
      <Col md={7} xl={9}>
        <ProfileRoutes />
      </Col>
    </Row>
  );
}
export default MyProfile;

const ProfileRoutes = () => {
  return (
    <Routes>
      <Route path="/manage-profile" element={<ProfileInfo />}></Route>
      <Route path="/personal-info" element={<PersonalInfo />}></Route>
      <Route path="*" element={<Navigate to="personal-info" replace />} />
    </Routes>
  );
};
