import React from "react";
import { Offcanvas } from "react-bootstrap";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import ShoppingCartIcon from "src/Projects/Corporate/assets/icons/ShoppingCartIcon";
import useCartStore from "src/Projects/Corporate/hooks/useCartStore";
import { CartItemsBox } from "src/Projects/Corporate/Pages/MyCart/CartItemsBox";
import CloseIcon from "src/Projects/Life/icons/CloseIcon";
import "./CartModal.css";
import CircleLogo from "./CircleLogo";

const CartModal = ({ show, handleClose, handleShow, extraClass }) => {
  const { state } = useCartStore();

  const { cartItems, allTotal } = state;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const path = "/" + pathname.split("/")[1];
  const handleCheckoutClick = () => {
    navigate({
      pathname: `${path}/my-cart`,
      state: { openCheckout: true },
    });
    handleClose();
  };

  return (
    <>
      {location.pathname !== "/Corporate/my-cart" && (
        <button
          type="button"
          onClick={handleShow}
          className={`cart-modal-show-btn ${extraClass}`}
        >
          <span className="cart-modal-show-btn-logo">
            <CircleLogo />
          </span>
          <span className="cart-modal-show-btn-text">
            <span>{cartItems.length > 0 ? cartItems.length : ""}</span>
          </span>
        </button>
      )}

      <Offcanvas
        show={show}
        onHide={handleClose}
        className="sidebar-main cart-modal"
        placement="end"
        scroll
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            <h6 className="fs-6">
              <ShoppingCartIcon /> Cart{" "}
              {cartItems.length > 0 ? `(${cartItems.length} Items)` : ""}
            </h6>
          </Offcanvas.Title>
          <button className="eco-btn" onClick={handleClose}>
            <CloseIcon color="#fff" />
          </button>
        </Offcanvas.Header>
        <hr className="mb-0 mt-0" />
        <Offcanvas.Body className="sidebar-body p-1 position-relative">
          <div className="cart-items-box">
            <CartItemsBox />
          </div>
          <div className="cart-modal-footer w-100 py-3">
            <div className="d-flex justify-content-between mx-3 cart-modal-total-box">
              <p>Sub Total : </p> <p>{allTotal}Tk</p>
            </div>
            <br />
            <div className="text-center">
              <NavLink
                to={`${path}/my-cart`}
                onClick={handleClose}
                className="send-code-btn me-2"
              >
                <small>View Cart Page</small>
              </NavLink>
              <button
                onClick={handleCheckoutClick}
                type="submit"
                className=" send-code-btn cart-confirm-btn"
              >
                <small>Checkout</small>
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CartModal;
