import React from "react";

const MoreCare = ({ size = "2rem" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9056 38.018C29.8045 38.018 37.8292 29.9933 37.8292 20.0944C37.8292 10.1955 29.8045 2.17081 19.9056 2.17081C10.0066 2.17081 1.98197 10.1955 1.98197 20.0944C1.98197 29.9933 10.0066 38.018 19.9056 38.018ZM39.8111 20.0944C39.8111 31.0879 30.8991 40 19.9056 40C8.91202 40 0 31.0879 0 20.0944C0 9.10087 8.91202 0.188843 19.9056 0.188843C30.8991 0.188843 39.8111 9.10087 39.8111 20.0944Z"
        fill="#688FB4"
      />
      <path
        d="M31.3479 25.2164C31.1267 25.3491 30.8835 25.4818 30.618 25.6145C29.2247 26.278 27.6986 26.6098 26.1725 27.0079C25.3762 27.2069 24.58 27.406 23.7838 27.6714C23.0539 28.0031 22.3241 28.2022 21.4615 28.2022C20.2671 28.2022 19.0728 28.2022 17.8785 28.2022C16.286 28.2685 15.0254 28.9321 14.1628 30.2591C14.0301 30.4582 13.831 30.5245 13.632 30.5245C11.9068 30.5245 10.1817 30.5245 8.45652 30.5245C8.39016 30.5245 8.25746 30.5245 8.12476 30.5245L8.52287 29.9937C9.31909 29.1311 10.1153 28.2022 10.9115 27.3396C11.6414 26.5434 12.3713 25.7472 13.1011 25.0173C13.632 24.5528 14.2291 24.1547 14.8263 23.823C15.8879 23.0931 17.0159 23.1594 18.1439 23.6239C19.0728 23.9557 19.9354 23.9557 20.8643 23.7566C21.7269 23.5575 22.6558 23.5576 23.5184 23.4912C23.6069 23.4912 23.7174 23.5133 23.8502 23.5576C24.0492 23.6903 24.1819 23.823 24.381 23.9557C24.2483 24.1547 24.1156 24.3538 23.9829 24.4201C23.6511 24.5528 23.253 24.6855 22.9212 24.8182C22.8328 24.8625 22.7222 24.8846 22.5895 24.8846C22.7664 24.9731 22.9212 24.9952 23.0539 24.9509C23.8502 24.8625 24.6464 24.7519 25.4426 24.6192C26.7254 24.4423 28.0082 24.2653 29.291 24.0884C29.4901 24.0884 29.6891 24.1547 29.8882 24.1547C30.0209 24.2211 30.1536 24.2874 30.2863 24.2874C30.7507 24.2874 31.0825 24.5528 31.3479 24.8846V25.2164Z"
        fill="#F1A350"
      />
      <path
        d="M15.8217 20.9698C16.7506 20.3063 17.7459 19.8418 18.8075 19.6428L18.4757 18.8465C18.8739 18.9792 18.8075 19.7091 19.471 19.5764L19.3383 18.3157H19.471C19.6037 18.7138 19.7143 19.1119 19.8028 19.5101C20.3999 19.5764 21.0635 19.7755 21.3952 18.9129L21.4616 19.0456L21.1962 19.6428C23.5848 20.2399 25.2436 21.6997 26.1062 24.022L24.9782 24.1547C24.7128 23.4248 24.1157 23.0267 23.3858 23.0267C22.4569 23.0267 21.5279 23.0267 20.6654 23.2258C19.8028 23.4248 18.9402 23.4248 18.144 23.0931C17.3478 22.8277 16.5515 22.695 15.7553 22.894C15.1581 23.0267 14.4946 23.2921 13.8975 23.4912C14.2956 22.5622 14.9591 21.6997 15.8217 20.9698Z"
        fill="#F1A350"
      />
      <path
        d="M19.2057 18.1167C19.2057 18.0725 19.1836 18.0283 19.1394 17.984C17.2815 15.6617 18.0777 11.6142 20.7318 10.2872C21.2626 10.0218 21.8598 9.88909 22.4569 9.95545C22.656 10.0218 22.7224 10.0882 22.656 10.2872C22.656 10.6853 22.7224 11.0171 22.656 11.4152C22.656 12.2778 22.7224 13.2067 22.5897 14.0693C22.2579 15.7944 21.1299 17.0551 19.9356 18.3158C19.8692 18.3158 19.8692 18.3158 19.8029 18.3158C19.6038 16.657 19.6038 15.0645 20.3337 13.5384C20.1346 13.8039 19.9356 14.1356 19.8029 14.4674C19.4048 15.6617 19.2721 16.8561 19.3384 18.0504L19.2057 18.1167Z"
        fill="#F1A350"
      />
      <path
        d="M13.8311 14.9318C15.5563 14.6001 17.3478 15.529 18.144 17.1214C18.3652 17.5638 18.4758 18.0283 18.4758 18.5148C18.1219 18.2052 17.7459 17.8734 17.3478 17.5196C16.7727 17.033 16.3304 16.7897 16.0207 16.7897C16.9497 17.3205 17.6795 18.1167 18.343 19.112C17.9449 19.0456 17.6132 19.0457 17.2814 18.9793C15.7553 18.8466 14.4946 18.1831 13.5657 16.9887C13.234 16.5906 12.9022 16.1925 12.5704 15.7281C12.8358 15.2636 13.3003 15.0645 13.8311 14.9318Z"
        fill="#F1A350"
      />
      <path
        d="M21.4612 18.3822C21.6602 16.3916 23.9162 14.6665 25.9068 15.0646C26.3712 15.1309 26.7693 15.3963 27.1674 15.5954C27.3665 15.7281 27.4329 15.9271 27.1674 16.1262C26.9905 16.3031 26.8357 16.4801 26.703 16.657C25.6856 18.161 24.2922 19.0014 22.5228 19.1784C22.2574 19.1784 21.992 19.1784 21.5939 19.2447C22.2574 18.1831 23.0536 17.3869 24.0489 16.8561C22.9873 17.1878 22.2574 17.984 21.4612 18.8466C21.4612 18.6476 21.4612 18.5149 21.4612 18.3822Z"
        fill="#F1A350"
      />
    </svg>
  );
};

export default MoreCare;
