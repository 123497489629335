import {
  NavLink,
  useLocation,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import AddAccount from "./AddAccount";
import Transfer from "./Transfer";

import "./fundTransfer.css";

function FundTransfer() {
  const { pathname } = useLocation();
  const dynamicPath = pathname.split("/")[1];

  return (
    <div className="border rounded px-2 py-3">
      <div className="fundTransfer__links">
        <NavLink
          to={`/${dynamicPath}/freelancer/wallet/fundtransfer/addaccount`}
        >
          Add Account
        </NavLink>
        <NavLink to={`/${dynamicPath}/freelancer/wallet/fundtransfer/transfer`}>
          Transfer
        </NavLink>
      </div>
      <div>
        <TransferRoutes />
      </div>
    </div>
  );
}
export default FundTransfer;

const TransferRoutes = () => {
  return (
    <Routes>
      <Route path={`/addaccount`} element={<AddAccount />} />
      <Route path={`/transfer`} element={<Transfer />} />
      <Route path="*" element={<Navigate to="addaccount" replace />} />
    </Routes>
  );
};
