import React from "react";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";
export const LookMeWhiteIcon = ({ RouteName, children }) => {
  let { pathname } = useLocation();
  const dynamicPath = pathname.split("/")[1];

  return (
    <Wrapper as={RouteName && Link} to={`/${dynamicPath}/${RouteName}`}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 0.7rem;
  padding: 5px;
  padding-left: 2rem;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  display: flex;
  justify-content: flex-start;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  transition: all 0.6s;
  cursor: pointer;
  align-items: center;
  transition: 0.2s;

  & > svg {
    margin-right: 0.5rem;
    transition: 0.2s;
  }

  & .badge {
    background-color: var(--bg-success);
    border-radius: 50%;
    color: var(--clr-dark);
    font-size: 0.6rem;
    margin-left: 1rem;
    height: 1.1rem;
    width: 1.1rem;
  }

  &:hover {
    color: var(--text-success);

    & svg {
      color: var(--text-info);
    }
  }
`;
