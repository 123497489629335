import React from "react";
import "./JobCategories.css";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { Accordion } from "react-bootstrap";
import MainItem from "./MainItem";
import { useSelector } from "react-redux";
const JobCategories = ({ handleClose = () => {}, showBg = true }) => {
  const { categories } = useSelector((store) => store.jobHomeData);
  const { districts, divisions } = useSelector((store) => store.jobLocalData);

  const [seeMore, setSeeMore] = useState(false);
  return (
    <div
      className="jobCategories job__categories-container p-2 h-100 overflow-y-scroll"
      style={{ backgroundColor: !showBg && "transparent" }}
    >
      <p className="text-orange">Categories</p>
      <div className="">
        <NavLink
          onClick={handleClose}
          className={({ isActive }) =>
            `categoriesLink d-none d-md-block ${
              isActive && "categoriesLinkActive"
            }`
          }
          to={`/Job/job-market/recommended`}
        >
          <span> Recommended</span>
        </NavLink>

        {categories.length > 0 ? (
          <Accordion
            className="job__category-sidebar"
            defaultActiveKey="0"
            style={{ background: "transparent" }}
          >
            {categories.map((category, index) => {
              if (index > 9) {
                return null;
              } else {
                return category.job_subcategory.length > 0 ? (
                  <MainItem
                    key={category.id}
                    handleClose={handleClose}
                    menuitem={category}
                  />
                ) : (
                  <NavLink
                    onClick={handleClose}
                    key={category.id}
                    className={({ isActive }) =>
                      `categoriesLink d-block ${
                        isActive && "categoriesLinkActive"
                      }`
                    }
                    to={`/Job/job-market/categories/${category.slug}/${category.id}`}
                  >
                    <span> {category.title}</span> ({category.job_posts_count})
                  </NavLink>
                );
              }
            })}
          </Accordion>
        ) : null}
        <NavLink
          onClick={handleClose}
          className={({ isActive }) =>
            `categoriesLink d-block d-lg-none d-xl-block ${
              isActive && "categoriesLinkActive"
            }`
          }
          to={`/Job/all-categories`}
        >
          <u>Explore More → </u>
        </NavLink>
        <hr />
        <p className="text-orange">Divisions</p>

        {divisions.length > 0
          ? divisions.map((division, index) => {
              return (
                <NavLink
                  onClick={handleClose}
                  key={division.id}
                  className={({ isActive }) =>
                    `categoriesLink d-block ${
                      isActive && "categoriesLinkActive"
                    }`
                  }
                  to={`/Job/job-post/${division.name}/${division.id}`}
                >
                  <span> {division.name}</span> ({division.job_post_count})
                </NavLink>
              );
            })
          : null}
        <hr />
        <p className="text-orange">Districts</p>

        {districts.length > 0
          ? [...new Array(seeMore ? districts.length : 10)].map((_, index) => {
              const district = districts[index];
              return (
                <NavLink
                  onClick={handleClose}
                  key={district.id}
                  className={({ isActive }) =>
                    `categoriesLink d-block ${
                      isActive && "categoriesLinkActive"
                    }`
                  }
                  to={`/Job/job-post/${district.name}/${district.id}`}
                >
                  <span> {district.name}</span> ({district.job_post_count})
                </NavLink>
              );
            })
          : null}
        <div className="text-end">
          <button
            onClick={() => setSeeMore((prev) => !prev)}
            className="btn-0 text-orange"
          >
            {seeMore ? "See Less" : "See More"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobCategories;
