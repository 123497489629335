import React, { useEffect, useState } from "react";
import { Row, Tab, Tabs } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useParams } from "react-router-dom";
import { useGetCompanyJobPostQuery } from "src/app/services/jobApi/jobGetApi";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import CompanyBanner from "../../Components/Common/CompanyBanner";
import LocationInfo from "../../Components/Common/LocationInfo";
import SingleJobPost from "../../Components/SingleJobPost/SingleJobPost";
import {
  companyBannerPhotoUrl,
  companyProfilePhotoUrl,
} from "../../utilities/job_commonUrl";
import GeneralInfo from "./GeneralInfo";
import SocialLink from "./SocialLink";

const CompanyDetails = () => {
  const { state } = useLocation();
  const [jobType, setJobType] = useState("All");
  const jobTypeList = ["All", "Full Time", "Part Time"];
  const [filteredPost, setFilteredPost] = useState([]);
  const [key, setKey] = useState("info");

  const [postList, setPostList] = useState([]);
  const { companyId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  const { data:result, isFetching, error } = useGetCompanyJobPostQuery({page:currentPage, company_id:companyId});
  const { data, hasMore } = result || {}

 
  useEffect(() => {
    if (data) {
      setPostList(prevState => [...prevState, ...data]);
   }
  }, [data]);

  

  const {
    district,
    division,
    company_social_link,
    company_name,
    website,
    profile_picture,
    profile_banner,
    company_address,
  } = state.data;
  const photoUrl = profile_picture
    ? companyProfilePhotoUrl + profile_picture
    : "/user.png";

  const bannerPhoto = profile_banner
    ? companyBannerPhotoUrl + profile_banner
    : null;

  const locationData = {
    district: district || {},
    division: division || {},
    job_address: company_address,
  };

  useEffect(() => {
    if (jobType === "Full Time") {
      const filteredPost = postList.filter(
        (item) => item.job_type?.toLowerCase() === "full time"
      );
      setFilteredPost(filteredPost);
    } else if (jobType === "Part Time") {
      const filteredPost = postList.filter(
        (item) => item.job_type?.toLowerCase() === "Part Time"
      );
      setFilteredPost(filteredPost);
    } else {
      setFilteredPost(postList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobType, postList]);

  return (
    <div className="secondary-bg p-2">
      <CompanyBanner
        profile_picture={photoUrl}
        profile_banner={bannerPhoto}
      ></CompanyBanner>

      <div className="my-4 primary-text ">
        <p className=" mb-2 fs-4 fw-bold">{company_name}</p>
        <p className="mb-2 font-14">{company_address}</p>
        <p className="mb-2 font-14">
          <a href={`http://${website}`}>{website}</a>
        </p>
      </div>

      <Tabs
        id="food-order-history"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 mt-3 food-tab "
        transition={true}
      >
        <Tab eventKey="info" title="Info">
          <GeneralInfo data={state.data} />
        </Tab>
        <Tab eventKey="location" title="Location">
          <LocationInfo data={locationData} />
        </Tab>
        <Tab eventKey="social" title="Social Link">
          <SocialLink data={company_social_link} />
        </Tab>
      </Tabs>
      <div>
        {jobTypeList.map((item) => (
          <span
            onClick={() => setJobType(item)}
            key={item}
            className={`food__nav__sub-item font-14 ${
              jobType === item ? "active" : ""
            }`}
          >
            {item}
          </span>
        ))}
      </div>

      {isFetching && <BubbleLoader />}
        
      <InfiniteScroll
        dataLength={postList.length} //This is important field to render the next data
        next={() => {
          setCurrentPage(currentPage + 1);
        }}
        hasMore={hasMore}
      >
        <div className="jobRecommended p-2 pb-4">
          {filteredPost.length < 1 && !error && (
            <p className="text-center mt-4"> 0 item Found </p>
          )}
          <Row className="g-3">
            {filteredPost.map((post) => (
              <SingleJobPost key={post.id} data={post} />
            ))}
          </Row>
        </div>
        {error && <p className="text-danger">{error.error}</p>}
      </InfiniteScroll>
    </div>
  );
};

export default CompanyDetails;
