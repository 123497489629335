import React from "react";

const ShoppingCartIcon = ({ size = "20", className = "" ,svgClass = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size - 1}
      height={size}
      viewBox="0 0 19 20"
      fill="none"
      className={svgClass}
    >
      <path
        className={className}
        d="M18.0815 4.52283C17.9196 4.19694 17.6879 3.92536 17.4088 3.73458C17.1297 3.5438 16.8128 3.44033 16.489 3.43427H4.4984L3.95507 0.974139C3.90018 0.736657 3.77771 0.52777 3.60761 0.381498C3.4375 0.235227 3.22979 0.160189 3.01831 0.168607H1.14477C0.896328 0.168607 0.658058 0.283294 0.48238 0.487438C0.306703 0.691582 0.208008 0.96846 0.208008 1.25716C0.208008 1.54587 0.306703 1.82274 0.48238 2.02689C0.658058 2.23103 0.896328 2.34572 1.14477 2.34572H2.30636L4.89184 13.5143C4.94673 13.7518 5.06921 13.9607 5.23931 14.1069C5.40941 14.2532 5.61712 14.3283 5.82861 14.3198H14.2595C14.4325 14.3192 14.602 14.263 14.7491 14.1573C14.8963 14.0516 15.0154 13.9006 15.0932 13.7211L18.1658 6.5802C18.299 6.25583 18.361 5.89819 18.3463 5.5392C18.3316 5.18021 18.2406 4.83103 18.0815 4.52283ZM13.6787 12.1427H6.54055L5.01362 5.61139H16.489L13.6787 12.1427Z"
        fill="#F1A350"
      />
      <path
        className={className}
        d="M5.35925 19.7618C6.13529 19.7618 6.7644 19.0307 6.7644 18.1289C6.7644 17.2271 6.13529 16.4961 5.35925 16.4961C4.58321 16.4961 3.9541 17.2271 3.9541 18.1289C3.9541 19.0307 4.58321 19.7618 5.35925 19.7618Z"
        fill="#F1A350"
      />
      <path
        className={className}
        d="M14.7274 19.7618C15.5035 19.7618 16.1326 19.0307 16.1326 18.1289C16.1326 17.2271 15.5035 16.4961 14.7274 16.4961C13.9514 16.4961 13.3223 17.2271 13.3223 18.1289C13.3223 19.0307 13.9514 19.7618 14.7274 19.7618Z"
        fill="#F1A350"
      />
    </svg>
  );
};

export default ShoppingCartIcon;
