import React from "react";
import { faCalendarCheck} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SingleItem = ({ item}) => {
  return (
    <div className="d-flex justify-content-between mb-4">
    <div className="d-flex">
      <div className='me-3 border  p-1 myIconCircle'>
        <FontAwesomeIcon icon={faCalendarCheck} />
      </div>
      <div>
        <h5>{item.event_name}</h5>
        <div>
          {item.details}
        </div>
        <div className="mediumTextSize">{item.year}</div>

      </div>
    </div>
  </div>
  );
};
