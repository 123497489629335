/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PhotoSlider } from "react-photo-view";
import useAuth from "src/hooks/useAuth";
import Avatar from "src/Projects/Life/Common/Avatar";
import {
  personalProfilePic,
  temporaryImageUrl,
} from "src/Projects/Life/Common/CommonUrl";
import DabbleCheckMark from "src/Projects/Life/icons/DabbleCheckMark";
import { URL_REGEX } from "src/Projects/Life/utils/life_utilities";
import { EMOJI_REGEX } from "src/utilities/utilities";
import styled from "styled-components";
import LastMessageTime from "./LastMessageTime";
import MessageUrlPreview from "./MessageUrlPreview";
import { getFullTime } from "./message_apiCall";

const SingleMessage = React.forwardRef(
  ({ chatData, targetedUserInfo, prev_created_at }, ref) => {
    const {
      content,
      created_at,
      from_user,
      // to_user,
      message_content = [],
      my_status,
      offline = false,
    } = chatData;
    const { authUser } = useAuth();
    const imgSize = "35px";
    const authUserId = String(authUser.id);
    const [fromUserId, setfromUserId] = useState();
    // const [toUserId, setToUserId] = useState();
    const [contentUrl, setContentUrl] = useState("");

    useEffect(() => {
      if (from_user) {
        if (typeof from_user === "object") {
          setfromUserId(String(from_user.id));
        } else {
          setfromUserId(String(from_user));
        }
      }
    }, [from_user]);

    // useEffect(() => {
    //   if (to_user) {
    //     if (typeof to_user === "object") {
    //       setToUserId(String(to_user.id));
    //     } else {
    //       setToUserId(String(to_user));
    //     }
    //   }
    // }, [to_user]);

    const [isGalleryActive, setIsGalleryActive] = useState(false);
    const [galleryIndex, setGalleryIndex] = useState();
    const setIsGalleryActiveFnc = (setIndexNumber) => {
      setGalleryIndex(setIndexNumber);
      setIsGalleryActive(true);
    };

    const text_words = content?.split(" ") || [];
    useEffect(() => {
      _.forEach(text_words, (word) => {
        if (word.match(URL_REGEX)) {
          setContentUrl(word);
        }
      });
    }, []);

    return (
      <>
        <LastMessageTime
          create_at={created_at}
          prev_create_at={prev_created_at}
        />

        <div
          ref={ref.first_ref}
          className={`single__message-item my-3 gap-1  ${
            fromUserId && fromUserId === authUserId ? "me" : ""
          } `}
        >
          {fromUserId && fromUserId !== authUserId && (
            <div className="">
              {targetedUserInfo && targetedUserInfo.photo ? (
                <Avatar
                  imgUrl={`${personalProfilePic}/${targetedUserInfo.photo}`}
                  size={imgSize}
                />
              ) : (
                <Avatar imgUrl="/user.png" size="35px" />
              )}
            </div>
          )}

          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="bottom"
            delay={400}
            overlay={
              <span className="message-time">{getFullTime(created_at)}</span>
            }
          >
            <div
              className={`single__message-content ${
                contentUrl && "single__message__with-url"
              }`}
            >
              {content ? (
                <div className="single__message-text position-relative">
                  {contentUrl ? (
                    <MessageUrlPreview
                      url={contentUrl}
                      content={content}
                    ></MessageUrlPreview>
                  ) : content ? (
                    <p
                      className={`font-14 mb-1 pe-2 p-10px ${
                        EMOJI_REGEX.test(content) && content?.length <= 2
                          ? "single_emoji"
                          : ""
                      }`}
                    >
                      {content}
                    </p>
                  ) : null}

                  {fromUserId == authUserId && my_status == "1" && (
                    <span className="single__message__checkMark">
                      <DabbleCheckMark className="seen" />
                    </span>
                  )}
                </div>
              ) : null}

              {/* 
                {
                true && <div className="rounded-3 overflow-hidden mt-1">
                <img className="img img-fluid" style={{width:200}} src="/user.png" alt="" />
                </div>
                } */}
              <p className="font-10 mt-0 mb-0 single__message-time">{}</p>

              <ImageContent
                className="message__images_content"
                single={message_content?.length === 1}
              >
                {message_content &&
                  message_content.map((item, index) => {
                    let imageUrl = `${temporaryImageUrl}/${item?.source}${item?.content_name}`;
                    if (offline) {
                      imageUrl = item;
                    }

                    return (
                      <div
                        className="single__image"
                        key={item?.id || index}
                        onClick={() => setIsGalleryActiveFnc(index)}
                      >
                        <LazyLoadImage
                          placeholderSrc="/Life/placeholder.jpeg"
                          effect="blur"
                          src={imageUrl}
                          className="img img-fluid"
                          height="100%"
                          width="100%"
                          alt={imageUrl}
                        />

                        {fromUserId === authUserId && my_status === "1" && (
                          <span className="single__image__checkMark">
                            <DabbleCheckMark className="seen" />
                          </span>
                        )}
                      </div>
                    );
                  })}
              </ImageContent>
            </div>
          </OverlayTrigger>
        </div>

        <PhotoSlider
          images={message_content?.map((item) => ({
            src: `${temporaryImageUrl}/${item?.source}${item.content_name}`,
            key: item.id,
          }))}
          visible={isGalleryActive}
          onClose={() => setIsGalleryActive(false)}
          index={galleryIndex}
          onIndexChange={setGalleryIndex}
        />

        <div
          className={`single__message-item  my-3 gap-1  ${
            fromUserId && fromUserId === authUserId ? "me" : ""
          }`}
        ></div>
      </>
    );
  }
);

export default SingleMessage;

const ImageContent = styled.div`
  max-width: ${(props) => (props.single ? "50%" : "75%")};
  display: grid;
  grid-template-columns: ${(props) => (props.single ? "1fr" : "1fr 1fr")};

  .single__image {
    width: 100%;
    height: fit-content;
    overflow: hidden;
    border: 1px solid var(--bg-info);
    cursor: pointer;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .single__image__checkMark {
      position: absolute;
      right: 2px;
      bottom: 0px;
      z-index: 12;
    }
  }
`;
