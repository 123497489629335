const useStore = () => {
  const carts = [
    { quantity: 2, price: "200", id: 12 },
    { quantity: 1, price: "300", id: 13 },
  ];
  const products = [
    {
      product_name: "Steak Frites",
      restaurant_name: "ZamZam Restuant (Mirpur-11)",
      price: "200",
      rating: "12",
      image: "/food-image/food1.png",
      id: "1",
    },
    {
      product_name: "Mutton Paya",
      restaurant_name: "New Dhansiri Restaurant - (Gulshan-2)",
      price: "150",
      rating: "20",
      image: "/food-image/food2.png",
      id: "2",
    },
    {
      product_name: "Bhuna Khichuri",
      restaurant_name: "Tabaq Coffee - Baridhara DOHS",
      price: "120",
      rating: "25",
      image: "/food-image/food3.png",
      id: "3",
    },
    {
      product_name: "Steak Frites",
      restaurant_name: "ZamZam Restuant (Mirpur-11)",
      price: "200",
      rating: "12",
      image: "/food-image/food1.png",
      id: "4",
    },
    {
      product_name: "Mutton Paya",
      restaurant_name: "New Dhansiri Restaurant - (Gulshan-2)",
      price: "150",
      rating: "20",
      image: "/food-image/food2.png",
      id: "5",
    },
    {
      product_name: "Bhuna Khichuri",
      restaurant_name: "Tabaq Coffee - Baridhara DOHS",
      price: "120",
      rating: "25",
      image: "/food-image/food3.png",
      id: "6",
    },
    {
      product_name: "Steak Frites",
      restaurant_name: "ZamZam Restuant (Mirpur-11)",
      price: "200",
      rating: "12",
      image: "/food-image/food1.png",
      id: "7",
    },
    {
      product_name: "Mutton Paya",
      restaurant_name: "New Dhansiri Restaurant - (Gulshan-2)",
      price: "150",
      rating: "20",
      image: "/food-image/food2.png",
      id: "8",
    },
    {
      product_name: "Bhuna Khichuri",
      restaurant_name: "Tabaq Coffee - Baridhara DOHS",
      price: "120",
      rating: "25",
      image: "/food-image/food3.png",
      id: "9",
    },
    {
      product_name: "Mutton Paya",
      restaurant_name: "New Dhansiri Restaurant - (Gulshan-2)",
      price: "150",
      rating: "20",
      image: "/food-image/food2.png",
      id: "10",
    },
  ];
  const handleRemove = () => {};
  const handleQuantity = () => {};

  return {
    carts,
    handleQuantity,
      handleRemove,
    products
  };
};

export default useStore;
