import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
export const PageIntroPart = () => {
  let { url } = useLocation();

  return (
    <>
      <div className="row mt-3">
        <div className="col-sm-9 pb-4">
          <div className="d-flex ">
            <div className="me-3">
              <h4>UVIOM World</h4>
            </div>
            <div>
              <h4> Public</h4>
            </div>
          </div>
          <div className="smallTextSize">
            @Uviominc.information Technology Company
          </div>
        </div>
        <div className="col-sm-3 ">
          <button className="btn btn-secondary">
            <h6 className="mb-0">Folllow</h6>
          </button>
        </div>
        {/* options  */}
        <div className="col-sm-12 px-0">
          <Navbar
            collapseOnSelect
            expand="md"
            className=" text-white px-0  lifeNav"
          >
            <Container fluid className="px-3">
              <Navbar.Brand as={Link} to={`${url}`} className="allunset">
                Post
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar" />
              <Navbar.Collapse id="responsive-navbar">
                <Nav className="me-auto">
                  <Nav.Link as={NavLink} to={`${url}/photos`}>
                    Photos
                  </Nav.Link>
                  <Nav.Link as={Link} to={`${url}/videos`}>
                    Videos
                  </Nav.Link>
                  <Nav.Link as={Link} to={`${url}/about`}>
                    About
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </>
  );
};
