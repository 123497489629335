import React from "react";
import { Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import "./about.css";

export const About = () => {
  const navigate = useNavigate();
  let { path } = useLocation();
  const location = useLocation();


  return (
    <div className="container-fluid mt-3 followerContainer px-0 ViewAbout">
      <div className="row ">
        <div className="col-sm-6 ">
          <div className="cardInner graphCard">
            <img src={"/View/graph.png"} alt="" className=" img-fluid" />
          </div>
          <div className="cardInner rounded-0 p-1 d-flex justify-content-between">
            <div>Total View</div>
            <div>18.00M</div>
          </div>
        </div>

        <div className="col-sm-6 px-0">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-4 px-1 ">
                <div className="cardInner div_center h5rem">
                  <div className="text-center">
                    <h6>Videos</h6>
                    <div>106</div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 px-1 ">
                <div className="cardInner div_center h5rem">
                  <div className="text-center">
                    <h6>Followers</h6>
                    <div>106</div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 px-1 ">
                <div className="cardInner div_center h5rem">
                  <div className="text-center">
                    <h6>Report</h6>
                    <div>106</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 col-sm-6 px-1">
                <div className="cardInner div_center h5rem">
                  <div>Like: 101 M</div>
                </div>
              </div>
              <div className="col-12 col-sm-6 px-1">
                <div className="cardInner div_center h5rem">
                  <div>Dislike: 101 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-4">
          <div className="cardInner p-2 text-center">Channel Description</div>
        </div>
        <div className="col-sm-8">
          <div className="cardInner p-2 text-gray mediumTextSize">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore
            quod eveniet quas? Ipsum ea doloremque fugiat animi, nam inventore.
            Voluptatem quisquam, consequuntur explicabo perspiciatis magni fuga
            laborum harum accusantium culpa?
          </div>
          <h6 className="mt-3">Joined Apr 19,2012</h6>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-sm-4">
          <div className="cardInner p-2 text-center">Channel Status</div>
        </div>
        <div className="col-sm-8">
          <div className="">
            <Form.Select aria-label="Floating label select example">
              <option>Open this select menu</option>
              <option value="Uploaded">Uploaded</option>
              <option value="Pending">Pending</option>
              <option value="Get Report">Get Report</option>
            </Form.Select>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-sm-12 text-end">
            <div
              className="btn channelButton px-3"
              // onClick={() => editchannelClick("editchannel")}
              onClick={() => navigate(`${location.state.data}/editchannel`)}
             
            >
              Edit Chanel Info
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
