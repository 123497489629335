import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall_withToken from "src/app/helper/apiCall";
import {
    applicantProfileUrl,
    getCompanyDataUrl,
  } from "src/Projects/Job/utilities/job_commonUrl";

const initialState = {
    company_profile: {},
    applicant_profile: {},
    isLoading: true,
    error:""
}


export const getCompanyProfile = createAsyncThunk("job/getCompanyProfile", (_, thunkAPI) => apiCall_withToken(thunkAPI, getCompanyDataUrl))

export const getApplicantProfile = createAsyncThunk("job/getApplicantProfile", (_, thunkAPI) => apiCall_withToken(thunkAPI, applicantProfileUrl))



const jobProfileSlice = createSlice({
    name: "job_profile",
    initialState,
    extraReducers: {
        [getCompanyProfile.pending]: (state) => { state.isLoading = true },
        [getCompanyProfile.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.company_profile = action.payload
        },
        [getCompanyProfile.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

    //    get all division 
        [getApplicantProfile.pending]: (state) => { state.isLoading = true },
        [getApplicantProfile.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.applicant_profile = action.payload
        },
        [getApplicantProfile.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        }
        
    }
})


export default jobProfileSlice.reducer;