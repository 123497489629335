import React from 'react';
import CallScreenFooter from '../Common/CallScreenFooter';

const VideoCallScreen = () => {
    return (
        <div >
            <div className='csc__img-container' style={{backgroundImage:"url(/ceo.png)"}}>
                <img className='img img-fluid csc-img' src="/ceo.png" alt="user" />
                <div className='csc__call-text text-center'>
                    <p className='mb-1'>Kamrul Dolon</p>
                    <p>20:26</p>
                </div>

                <div className='csc__other-video'>
                    <img className='img img-fluid' src="/Life/message/video-call.png" alt="calling" />
                </div>
            </div>

            <CallScreenFooter video/>
        </div>
    );
};

export default VideoCallScreen