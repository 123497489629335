import downloadIcon from "./../../assets/download-icon.svg";
import "./billingHistory.css";

function BillingHistory() {
  return (
    <div>
      <h3 className="heading-3 mb-2">Payment History</h3>

      <div className="express__billing__table__container">
        <table className="express__billing__table">
          <thead>
            <tr>
              <td>Transition Id</td>
              <td>Date</td>
              <td>Amount</td>
              <td>Method</td>
              <td>Statement</td>
            </tr>
          </thead>

          <tbody>
            {Array.from({ length: 5 }).map((_, index) => (
              <tr key={index}>
                <td>#220610001</td>
                <td>18 jun 2022</td>
                <td>1000.00 Tk</td>
                <td>UVIOM Wallet</td>
                <td>
                  Invoice <img src={downloadIcon} alt="download" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default BillingHistory;
