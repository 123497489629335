import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import SingleJobPost from "../../Components/SingleJobPost/SingleJobPost";
import {
  allJobsPostUrl,
  categoryJobsPostUrl,
  subCategoryByPost,
} from "../../utilities/job_commonUrl";
import { Row } from "react-bootstrap";
import { useGetCategoryJobPostQuery } from "src/app/services/jobApi/jobGetApi";
import JobSkeleton from "../../Components/Placeholder/JobSkeleton";

const JobPostContainer = ({ title, isSub = false }) => {
  const { categoryName, categoryId } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [postList, setPostList] = useState([]);

  let jobPostUrl = allJobsPostUrl;
  if (categoryName && categoryId) {
    jobPostUrl = `${
      isSub ? subCategoryByPost : categoryJobsPostUrl
    }/${categoryId}`;
  }

  const {
    data: result,
    isFetching,
    error,
  } = useGetCategoryJobPostQuery({ page: currentPage, url: jobPostUrl });
  const { data, hasMore } = result || {};

  // if pathname is changed then postList and currentPage set default
  const { pathname } = useLocation();
  useEffect(() => {
    setPostList([]);
    setCurrentPage(1);
  }, [pathname]);

  //  if scroll loading add previous page data
  useEffect(() => {
    if (data) {
      setPostList((prevState) => [...prevState, ...data]);
    }
  }, [data]);

  return (
    <div>
      <InfiniteScroll
        dataLength={postList.length} //This is important field to render the next data
        next={() => {
          setCurrentPage(currentPage + 1);
        }}
        hasMore={hasMore}
      >
        <div className="jobRecommended p-2 pb-4">
          <p className="text-orange">{title || categoryName}</p>

          {postList.length < 1 && !isFetching && (
            <p className="text-center mt-4"> 0 item Found </p>
          )}

          {isFetching && (
            <Row className="g-3" xxl="2">
              {[...new Array(6)].map((_, index) => (
                <JobSkeleton key={index} />
              ))}
            </Row>
          )}

          <Row className="g-3">
            {postList.map((post) => (
              <SingleJobPost key={post.id} data={post} />
            ))}
          </Row>
          {error && <p className="text-danger">{error.error}</p>}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default JobPostContainer;
