import React, { useEffect, useState } from "react";
import { SingleVideoCard } from "./SingleVideoCard";
import { useDispatch, useSelector } from "react-redux";
import "./saveVideo.css";
import {
  getSavedVideos,
  destroySavedVideos,
} from "src/features/view/viewDataSlice";
import { videoThumbnailImg } from "../../common/view_commonUrl";
import styled from "styled-components";
import SingleVideoCardSkeleton from "../../common/SingleVideoCardSkeleton";

export const SaveVideo = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getSavedVideos(currentPage));
  }, [dispatch, currentPage]);

  const {
    savedVideos: { data: savedVideos, has_more },
  } = useSelector((store) => store.viewData);

  return (
    <div className="container-fluid mt-3 followerContainer ">
      <SavedVideoWrapper>
        {savedVideos.length >= 1
          ? savedVideos.map((video) => {
              const details = video.videos[0];
              if (details?.title) {
                return (
                  <SingleVideoCard
                    key={video.id}
                    videoId={video.video_id}
                    savedAt={video.created_at}
                    title={details?.title}
                    desc={details.description}
                    viewCount={details.view_count}
                    thumbnail={videoThumbnailImg + details.thumbnail}
                  />
                );
              }
            })
          : Array.from({ length: 3 }).map((_, index) => (
              <SingleVideoCardSkeleton key={index} />
            ))}
      </SavedVideoWrapper>
      {has_more && (
        <HasMore onClick={() => setCurrentPage(currentPage + 1)}>
          Load More
        </HasMore>
      )}
    </div>
  );
};

const SavedVideoWrapper = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;
`;

export const HasMore = styled.div`
  font-size: 80%;
  display: inline-block;
  border-radius: 3rem;
  padding: 0.1rem 0.5rem;
  background-color: #6984a524;
  color: var(--text-primary);
  border: 1px solid #6984a5fd;
  margin-top: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;

  & > svg {
    margin-right: 0.2rem;
  }
`;
