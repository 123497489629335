import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
const OrderItemCard = ({ status }) => {
  const { pathname } = useLocation();

  const currentPath = "/" + pathname.split("/").slice(1, 3).join("/");

  return (
    <div className="secondary-bg p-2 my-3 rounded-2">
      <Row className="g-3">
        <Col xl="6">
          <div className="favorite-card p-0 m-0">
            <div className="cart-item-img-box">
              <img
                className="img img-fluid"
                src="/food-image/food1.png"
                alt=""
              />
            </div>
            <div
              style={{ height: "100%" }}
              className="cart-text-box align-items-start d-flex flex-column justify-content-between"
            >
              <p>ZamZam Restuant (Mirpur-11)</p>
              <p className="">
                <small>
                  {"steak frites, (French: “steak [and] fries”) a simple dish of beef steak.".substring(
                    0,
                    60
                  )}
                  ...
                </small>
              </p>
              <p>Quantity: 1</p>
            </div>
          </div>
        </Col>
        <Col xl="6">
          <div className="d-flex align-items-center h-100">
            <div className="flex-grow-1">
              <p className="mb-1">
                <small>Estimate delivery Time</small> <br />{" "}
                <small>
                  <FontAwesomeIcon className="text-orange" icon={faClock} />{" "}
                  30-40 Min
                </small>
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center flex-grow-1 flex-column flex-sm-row">
              <p className="m-0 product-status-text border-orange mb-2 mb-sm-0">
                <small>{status}</small>
              </p>
              <Link
                to={`${currentPath}/manage-order/100`}
                className=" send-code-btn cart-confirm-btn manage-order-btn py-1 font-14 text-dark"
              >
                Manage
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OrderItemCard;
