import axios from "axios";

export const getPhotos = (getPhotosArg) => {
  const {
    setIsLoading,
    Url,
    user_id,
    info,
    setinfo,
    currentPage,
    sethasMore
  }=getPhotosArg
  setIsLoading(true);

  axios.get(`${Url}/${user_id}?page=${currentPage}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then((res) => {

      if (res.status === 200) {
        let next_page = res.data.next_page_url;
        let responseData = res.data.data;
        if (next_page === null) {
          sethasMore(false);
        } else {
          sethasMore(true);
        }
        if (Array.isArray(responseData)) {
          setinfo([...info, ...responseData]);
        } else if (typeof responseData == 'object' && responseData != null) {
          const resArray = [...info]
          Object.entries(responseData).map(([key, value]) => resArray.push(value))
          setinfo(resArray);
        }
        // setpostList([...postList, ...responseData]);

        // setinfo(response);
      } else { }
      setIsLoading(false);

    }).catch((err) => {
      console.log(err.message);
      setIsLoading(false);

    });
  setIsLoading(false);

}
export const getOthersPublicPost = (getPhotosArg) => {
  const {
    setIsLoading,
    Url,
    user_id,
    postList,
    setpostList,
    currentPage,
    sethasMore
  }=getPhotosArg
  setIsLoading(true);

  axios.get(`${Url}/${user_id}?page=${currentPage}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then((res) => {

      if (res.status === 200) {
        let next_page = res.data.next_page_url;
        let responseData = res.data.data;
        if (next_page === null) {
          sethasMore(false);
        } else {
          sethasMore(true);
        }
        if (Array.isArray(responseData)) {
          setpostList([...postList, ...responseData]);
        } else if (typeof responseData == 'object' && responseData != null) {
          const resArray = [...postList]
          Object.entries(responseData).map(([key, value]) => resArray.push(value))
          setpostList(resArray);
        }
      } else { }
      setIsLoading(false);

    }).catch((err) => {
      console.log(err.message);
      setIsLoading(false);

    });
  setIsLoading(false);

}