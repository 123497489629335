import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useUpdatePostPrivacyMutation } from "src/app/services/lifeApi/newsFeedApi";
import { showToast } from "src/Projects/Job/utilities/functions";
import CloseIcon from "src/Projects/Life/icons/CloseIcon";

const StatusUpdateModal = ({ show, handleClose, data = {} }) => {
  const {
    family_post_status,
    friend_post_status,
    professional_post_status,
    public_post_status,
    post_id,
  } = data || {};

  const defaultValue = {
    family_post_status: family_post_status === "active",
    friend_post_status: friend_post_status === "active",
    professional_post_status: professional_post_status === "active",
    public_post_status: public_post_status === "active",
  };
  const [status, setStatus] = useState(defaultValue);
    const [updatePrivacy, {isLoading, isSuccess, error}] = useUpdatePostPrivacyMutation();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    if (name === "public_post_status" && value) {
      setStatus({
        family_post_status: true,
        friend_post_status: true,
        professional_post_status: true,
        public_post_status: true,
      });
    } else {
      const prevStatus = { ...status };
      prevStatus[name] = value;
      setStatus(prevStatus);
    }
  };

  const handleCancel = () => {
    setStatus(defaultValue);
    handleClose();
  };


  const handleSubmit = () => {
    const argData = {
      family_post_status: status.family_post_status ? "active" : "inactive",
      friend_post_status: status.friend_post_status ? "active" : "inactive",
      professional_post_status: status.professional_post_status
        ? "active"
        : "inactive",
      public_post_status: status.public_post_status ? "active" : "inactive",
      post_id,
    };
      updatePrivacy(argData);
    };
    
    useEffect(() => {
        if (isSuccess) {
            handleClose();
        }
        if (error) {
            showToast({ title: error.error, icon: "error" });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isSuccess, error])

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="bestTime__update-modal"
    >
      <Modal.Header>
        <Modal.Title className="text-center">Edit Audience</Modal.Title>
        <button className="btn-0" onClick={handleCancel}>
          <CloseIcon className="primary-text" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <Form className="story__status-form">
          <Form.Check
            onChange={handleChange}
            name={"family_post_status"}
            checked={status.family_post_status || false}
            disabled={status.public_post_status}
            type="checkbox"
            id={`story-status-family`}
            label={`Family`}
          />
          <Form.Check
            onChange={handleChange}
            name={"friend_post_status"}
            checked={status.friend_post_status || false}
            disabled={status.public_post_status}
            type="checkbox"
            id={`story-status-friend`}
            label={`Friend`}
          />
          <Form.Check
            onChange={handleChange}
            name={"professional_post_status"}
            checked={status.professional_post_status || false}
            disabled={status.public_post_status}
            type="checkbox"
            id={`story-status-professional`}
            label={`Professional`}
          />
          <Form.Check
            onChange={handleChange}
            name={"public_post_status"}
            checked={status.public_post_status || false}
            type="checkbox"
            id={`story-status-public`}
            label={`Public`}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button
          onClick={handleSubmit}
          variant="primary"
          className="bestTime__update_modal-saveBtn"
        >
          {isLoading ? (
            <span className="text-white" >
              <FontAwesomeIcon
                icon={faSpinner}
                className="text-white me-2"
                spin
              />{" "}
              Updating...
            </span>
          ) : (
            "update"
          )}
        </Button>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StatusUpdateModal;
