import React from "react";

export const About = () => {
  return (
    <>
      <div className="row p-0 p-lg-3 mb-3 singlePostSample  bg-footer rounded-3 ">
        <div className="col-12 MiddlePartScrool NewsFeedPostBg newsFeedPage  py-0 my-0 lifePageViewAbout  text-align-justify">
         Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, aperiam! Aspernatur optio doloribus deleniti officia aut repellat incidunt suscipit impedit necessitatibus doloremque! Eos, accusantium? Itaque quae, quam dolores exercitationem quo tempore amet soluta maxime accusamus, minus labore assumenda incidunt sed ad asperiores? Recusandae, eligendi dolorum praesentium consequatur soluta officia nemo. Necessitatibus aliquid soluta deleniti eligendi eos mollitia atque architecto ut rem, facilis non omnis corporis, repellat dolorem aspernatur velit molestias! Velit praesentium perferendis facere, libero atque optio sit repudiandae laudantium magnam nobis. Molestiae, numquam odit quos, nesciunt facilis fuga nihil necessitatibus commodi distinctio ab eveniet, amet ullam nam? Nam, illum.
        </div>
      </div>
    </>
  );
};
