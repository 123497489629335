import {
  deleteNewsFeedPost,
  deletePostContent,
  updatePostCaption,
} from "src/features/life/newsFeedSlice";
import { showToast } from "src/Projects/Job/utilities/functions";
import {
  deletePostContUrl,
  deletePostUrl,
  updatePostCaptionUrl,
  updatePostPrivacy,
} from "src/Projects/Life/Common/CommonUrl";
import { api } from "../api";

export const newsFeedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deleteNewsFeedPost: builder.mutation({
      query: (post_id) => ({
        url: `${deletePostUrl}/${post_id}`,
        method: "DELETE",
      }),
      async onQueryStarted(post_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.status === 1) {
            dispatch(deleteNewsFeedPost(post_id));
          } else {
            showToast({ title: "Please try Again", icon: "error" });
          }
        } catch {}
      },
    }),

    updatePostCaption: builder.mutation({
      query: ({ post_id, post_details }) => ({
        url: updatePostCaptionUrl,
        method: "POST",
        body: { post_id, post_details },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.status === 1) {
            dispatch(updatePostCaption(arg));
          } else {
            showToast({ title: "Please try Again", icon: "error" });
          }
        } catch {}
      },
    }),

    deletePostContent: builder.mutation({
      query: ({ post_id, post_content_id }) => ({
        url: `${deletePostContUrl}/${post_content_id}`,
        method: "DELETE",
        body: { post_id, post_content_id },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.status === 1) {
            dispatch(deletePostContent(arg));
          } else {
            showToast({ title: "Please try Again", icon: "error" });
          }
        } catch (err) {
          console.log(err);
        }
      },
    }),

    updatePostPrivacy: builder.mutation({
      query: (data) => ({
        url: updatePostPrivacy,
        method: "POST",
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log(data);
        } catch (err) {
          console.log(err.message);
        }
      },
    }),
  }),
});

export const {
  useDeleteNewsFeedPostMutation,
  useUpdatePostCaptionMutation,
  useDeletePostContentMutation,
  useUpdatePostPrivacyMutation,
} = newsFeedApi;
