import React from "react";
import CompanySingleInfo from "./CompanySingleInfo";
import { applicantCvUrl } from "../../utilities/job_commonUrl";

import { education_columns as columns } from "./educationTableData";
import DataTable from "react-data-table-component";

const ApplicantInformation = ({
  data,
  educations = [],
  theme = "light",
  hideAddress = false,
}) => {
  const {
    applicant_name,
    qualification,
    permanent_address,
    present_address,
    contact_email,
    contact_phone,
    experiences,
    expected_salary,
    website,
    cv_attachment,
    district,
    division,
  } = data || {};

  return (
    <div className="applicantProfile p-2 p-lg-3 p-xl-2">
      <CompanySingleInfo title="Name" value={applicant_name} />
      <CompanySingleInfo title="Qualification" value={qualification} />
      <CompanySingleInfo title="Phone" value={contact_phone} />
      <CompanySingleInfo title="Email" value={contact_email} />
      <CompanySingleInfo title="Website" value={website || "N/A"} />
      <CompanySingleInfo title="Experience" value={experiences} />
      <CompanySingleInfo title="Expected Salary" value={expected_salary} />
      {!hideAddress && (
        <>
          <CompanySingleInfo
            title="Division"
            value={division?.name || "not found"}
          />
          <CompanySingleInfo
            title="District"
            value={district?.name || "not found"}
          />
          <CompanySingleInfo title="Present Address" value={present_address} />
          <CompanySingleInfo
            title="Permanent Address"
            value={permanent_address}
          />
        </>
      )}
      {cv_attachment && (
        <CompanySingleInfo
          title="Resume Attach"
          value={cv_attachment.substr(1, 20)}
          showPreviewBtn
          previewLink={applicantCvUrl + cv_attachment}
        />
      )}
      {educations.length > 0 && (
        <>
          <CompanySingleInfo title="Education" value={""} />
          <DataTable
            columns={columns}
            data={educations}
            responsive
            theme={theme}
            pointerOnHover
          />
        </>
      )}
    </div>
  );
};

export default ApplicantInformation;
