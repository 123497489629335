import React from "react";
import { Link, useLocation } from "react-router-dom";
import { productImgBase_m } from "../../utilities/CommonUrl";

const SingleCategory = (props) => {
  const { item } = props;
  const { product_name, product_image, slug } = item;
  const { pathname } = useLocation();
  const path = "/" + pathname.split("/")[1];

  return (
    <Link to={`${path}/product-details/${slug}`} className="single-category">
      <span className="w-100 h-100 d-flex justify-content-center align-items-center">
        {product_image ? (
          <img
            src={`${productImgBase_m}/${product_image}`}
            alt="product card"
            className="single-category-img img img-fluid "
          />
        ) : (
          <div className="no_img">No img</div>
        )}
      </span>
      <span className="overme mediumTextSize">{product_name}</span>
    </Link>
  );
};

export default SingleCategory;
