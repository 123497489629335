import React from "react";
import { Carousel, Col, Row } from "react-bootstrap";

const Carusel = () => {
  return (
    <Row>
      <Col sm={8} className=" offset-sm-2 rounded-3 mt-2">
        <div className="position-relative">
          <Carousel interval="2000">
            {Array.from({ length: 4 }).map((_, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100  rounded-3"
                  src="/Life/Upcomming.jpg"
                  alt="Upcomming"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </Col>
    </Row>
  );
};

export default Carusel;
