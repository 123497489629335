import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import useCartStore from "src/Projects/Corporate/hooks/useCartStore";
import ShippingAndBilling from "./ShippingAndBilling";

export const BillingAccordion = ({
  shippingAndBillingBtnRef,
  shipping_billing_status,
  newBillingAddress,
  setNewBillingAddress,
  shipping_billing_error,
  handleAddressSubmit,
  setState,
}) => {
  const { shippingAddress, billingAddress,setBillingAddress } = useCartStore();

  const handleBillingAddressChange = (e) => {
    const address = e.target.value;
    setState((prevState) => ({ ...prevState, billing_address: address }));
    if (address === "same_as_shipping") {
      setBillingAddress({
        billing_name: shippingAddress.shipping_name,
        billing_email: shippingAddress.shipping_email,
        billing_phone: shippingAddress.shipping_phone,
        billing_address1: shippingAddress.shipping_address1,
        billing_address2: shippingAddress.shipping_address2,
      });
    } else {
      setBillingAddress(newBillingAddress);
    }
  };
  
  return (
    <Accordion
      className="shipping-address my-2"
      id="shipping-and-billing-accordion"
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header ref={shippingAndBillingBtnRef}>
          <div
            className="checkout-collapse-btn"
            style={{ color: shipping_billing_status && "#f9a31a" }}
          >
            <span>Billing Address</span>
            <FontAwesomeIcon icon={faCheckCircle} />
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <ShippingAndBilling
            newBillingAddress={newBillingAddress}
            setNewBillingAddress={setNewBillingAddress}
            handleBillingAddressChange={handleBillingAddressChange}
            errorMessage={shipping_billing_error}
            doneAccordion={handleAddressSubmit}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
