//! api level url
export const apiUrl = "https://admin.uviom.com/frontend_api/channel/";
export const CACHE_EXPIRE_TIME = 600;

export const searchUrl = apiUrl + "search-post";
export const getChannelhomePageVideosUrl = `${apiUrl}get-all-video-posts`;

export const createVideoPlaylistUrl = apiUrl + "create-channel-playlist";
export const updateVideoPlaylistUrl = apiUrl + "update-channel-playlist/";

export const createVideoPostUrl = apiUrl + "create-video-post";
export const updateVideoPostUrl = apiUrl + "update-video-post/";
export const deleteVideoPostUrl = apiUrl + "delete-video-post/";

export const getChannelDetailsUrl = apiUrl + "get-channel-details/";
export const getMeFollowingUrl = apiUrl + "follows-list";
export const toggleFollowUrl = apiUrl + "follow/";

export const getRandomVideosUrl = apiUrl + "get-random-video-posts";
export const getAllVideosUrl = apiUrl + "get-all-video-posts";

export const getCurrentVideoUrl = apiUrl + "get-single-video-post-details/";
export const getCurrentVideoCommentUrl = apiUrl + "video-comment-show/";
export const getCurrentCommentReplyUrl = apiUrl + "video-reply-comment-show/";

export const getRelatedToCategoryUrl = apiUrl + "get-category-by-videos/";
export const getPlaylistVideosUrl = apiUrl + "get-channel-video-by-playlist/";

export const getSavedVideosUrl = apiUrl + "get-save-video";
export const getCategoryOfAllVideo = apiUrl + "get-all-video-categories";

export const saveThisVideoUrl = apiUrl + "video-save/";
export const likeThisVideoUrl = apiUrl + "video-like/";

export const likeThisCommentUrl = apiUrl + "video-comment-like/";
export const likeThisReplyCommentUrl = apiUrl + "video-reply-comment-like/";

export const updateThisCommentUrl = apiUrl + "video-comment-update/";
export const updateThisReplyCommentUrl = apiUrl + "video-reply-comment-update/";

export const deleteThisCommentUrl = apiUrl + "video-comment-remove/";
export const deleteThisReplyCommentUrl = apiUrl + "video-reply-comment-remove/";

export const doCommentOnVideoUrl = apiUrl + "video-comment/";
export const doCommentOnReplyUrl = apiUrl + "video-reply-comment/";
export const createVideoReportUrl = apiUrl + "create-video-report/";

export const getChannelCategoryUrl = apiUrl + "get-all-categories";
export const getReportCategoryUrl = apiUrl + "get-all-report-categories";
export const getReportContentTypeUrl = apiUrl + "get-all-report-content-type";

export const getOwnPlaylistUrl = apiUrl + "get-all-user-playlist";
export const getVideoCategoriesUrl = apiUrl + "get-all-video-categories";

export const updateChannelProfileInfoUrl = apiUrl + "update-channel-profile";
export const updateChannelProfilePhotoUrl = apiUrl + "update-channel-photo";

export const updateChannelBannerPhotoUrl = apiUrl + "update-channel-banner";

//! frontend level url
export const frontEndUrl =
  "https://uviom-life-running.s3.ap-southeast-1.amazonaws.com/";

export const channelBannerImg = frontEndUrl + "images/channel/channel_banner/";
export const channelLogoImg = frontEndUrl + "images/channel/channel_user/";
export const videoThumbnailImg =
  frontEndUrl + "images/channel/video_thumbnail/";

export const videoReportImg = frontEndUrl + "images/channel/report_photo/";
export const videoPlayUrl = frontEndUrl + "images/channel/videos/";
export const videoSliderImage = frontEndUrl + "images/channel/channel_slider/";

// aws video upload
export const AWS_BASE_URL = "https://s3.amazonaws.com/uviom-life";
export const AWS_BUCKET_NAME = "uviom-life";
export const AWS_view_video_thumbnail_path = "images/channel/video_thumbnail/";
export const AWS_view_video_path = "images/channel/videos/";
export const AWS_REGION_NAME = "us-east-1";
export const AWS_ACCESS_ID = process.env.REACT_APP_AWS_ACCESS_ID;
export const AWS_SECRET_KEY = process.env.REACT_APP_AWS_SECRET_KEY;
