import React from 'react';

const Pickup = () => {
    return (
        <div className="my-4">
        <h6 className="fw-500">Pick-Up</h6>
            <div className="p-3 bg-white rounded-2 mt-2 w-100" style={{height:"12rem"}}>
                
        </div>
        </div>
    );
};

export default Pickup;