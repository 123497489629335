import React from "react";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SingleItem = ({ item }) => {
  return (
    <div className="d-flex justify-content-between my-4">
      <div className="d-flex">
        <div className="me-3 border  p-1 myIconCircle">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </div>
        <div>
          <div>{item.current_city}</div>
          <div>
            <small>{item.hometown}</small>
          </div>
        </div>
      </div>
    </div>
  );
};
