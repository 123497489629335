import axios from "axios";

export const updateForm = (updateFormArg) => {
    const { formData, updateMainProfileInfoUrl, setIsLoading, dataReload, setIsEditableFalse } = updateFormArg
    setIsLoading(true);
    axios
        .post(`${updateMainProfileInfoUrl}`, formData,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            }
        )
        .then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                dataReload()
                if (setIsEditableFalse) {
                    setIsEditableFalse()

                }

            } else {
                console.log("err")
                setIsLoading(false);
            }
        })
        .catch((err) => {
            console.log(err)
        });
    setIsLoading(false);

}
export const fileUploadToServer = (setIsLoading, compressedFile, Url, formData, dataReload) => {
    setIsLoading(true);
    axios
        .post(`${Url}`, formData,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    "Content-Type": "multipart/form-data",
                },
            }
        )
        .then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                dataReload()

            } else {
                console.log("err")
                setIsLoading(false);
            }
        })
        .catch((err) => {
            setIsLoading(false);

        });

}