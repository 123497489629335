import { faMicrophone, faPhone, faVideo, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import LookMeIcon from 'src/Projects/Life/icons/LookMeIcon';

const CallScreenFooter = ({ handleLookMeBtn = () => { },lookMe=false,video=false }) => {
    
   

    return (
        <div className='p-2 py-3 bg-gray call__screen__control'>
            <button type='button' className={`call__screen__control-btn csc-video ${video ? "video-active" : " "}`}>
                    <FontAwesomeIcon  icon={faVideo}></FontAwesomeIcon>
                </button>
                <button onClick={handleLookMeBtn} type='button' className={`call__screen__control-btn csc-look ${lookMe ? "look-active" : " "}`}>
                    <LookMeIcon className='csc-look-me' width='40' height='25'></LookMeIcon>
                </button>
                <button type='button' className='call__screen__control-btn csc-phone'>
                    <FontAwesomeIcon   icon={faPhone}></FontAwesomeIcon>
                </button>
                <button type='button' className='call__screen__control-btn csc-microphone'>
                    <FontAwesomeIcon  icon={faMicrophone}></FontAwesomeIcon>
                </button>
                <button type='button' className='call__screen__control-btn csc-volume'>
                    <FontAwesomeIcon  icon={faVolumeUp}></FontAwesomeIcon>
                </button>
            </div>
    );
};

export default CallScreenFooter;