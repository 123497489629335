import React from 'react';

const FoodsCategorySliderItem = () => {
    return (
        <div className='foods__category__slider-item mx-3 pointer'>
            <div>
                <img src="/food-image/burger.png" alt="" />
            </div>
            <h6 className='mt-2 text-center primary-text'>Burger</h6>
        </div>
    );
};

export default FoodsCategorySliderItem;