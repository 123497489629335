import React from "react";
import { Routes, Route } from "react-router-dom";
import { Overview } from "./Overview";
import { Works } from "./Works/Works";
import { Education } from "./Education/Education";
import { Address } from "./Address/Address";
import { BasicInfo } from "./BasicInfo/BasicInfo";
import { Relationship } from "./Relationship/Relationship";
import { DetailsAbout } from "./DetailsAbout/DetailsAbout";
import { Events } from "./Events/Events";
import { Sports } from "./Sports/Sports";
import { Music } from "./Music/Music";
import { TvProgram } from "./TvProgram/TvProgram";
import { Film } from "./Film/Film";
import { Books } from "./Books/Books";
import { GeneralInfo } from "./General_info";

export const MiddleRightCol = ({ info, getData }) => {
  return (
    <Routes>
      <Route path="/" element={<Overview info={info} getData={getData} />} />
      <Route
        path="/general_info"
        element={<GeneralInfo info={info} getData={getData} />}
      />
      <Route path="/works" element={<Works info={info} getData={getData} />} />
      <Route
        path="/education"
        element={<Education info={info} getData={getData} />}
      />
      <Route
        path="/relationship"
        element={<Relationship info={info} getData={getData} />}
      />
      <Route path="/details-about" element={<DetailsAbout />} />
      <Route path="/address" element={<Address />} />
      <Route path="/basic-info" element={<BasicInfo />} />
      <Route path="/events" element={<Events />} />
      <Route path="/sports" element={<Sports />} />
      <Route path="/film" element={<Film />} />
      <Route path="/music" element={<Music />} />
      <Route path="/tv-program" element={<TvProgram />} />
      <Route path="/books" element={<Books />} />
    </Routes>
  );
};
