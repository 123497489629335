import React from "react";
import OrderItemCard from "../Common/OrderItemCard";
import ReceivedOrderCard from "./ReceivedOrderCard";

const ReceivedOrder = () => {
  return (
    <div className="order-history  p-3">
      <ReceivedOrderCard />
      <ReceivedOrderCard />
      <ReceivedOrderCard />
     
    </div>
  );
};

export default ReceivedOrder;
