import React from "react";
import { faMinus, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FoodCartItem = ({ data, handleRemove, handleQuantity }) => {
  const { id, price, quantity } = data;

  const product_name = "steak frites, (French: “steak [and] fries”)";
  return (
    <div className="cart-item  secondary-bg">
      <div className="cart-item-img-box">
        <img className="img img-fluid" src="/food-image/food1.png" alt="" />
      </div>

      <div className="cart-text-box">
        <h6 className="fw-500 mb-1">Steak Frites</h6>
        <p>
          <small>
            {product_name.substring(0, window.innerWidth < 500 ? 20 : 40)}...
          </small>
        </p>

        <div
          className="d-sm-flex g-0 overflow-visible"
          style={{ gap: "0.5rem" }}
        >
          <h6 className="flex-grow-1 fw-500 mb-0">Addon's:</h6>
          <p className="flex-grow-1 fw-bold mb-0">{price}Tk</p>
        </div>
        <p>
          <small>Borhani : 50 TK</small>
        </p>
      </div>
      <div className="cart-action-box d-flex flex-column  h-100 w-100 align-items-center justify-content-between">
        <div className=" text-center d-flex">
          <button
            className="eco-btn text-orange mx-1"
            onClick={() => handleRemove(id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>

        <div className="d-flex food__addModal__plus-minus">
          <button
            onClick={() => handleQuantity(id, false)}
            disabled={quantity <= 1}
          >
            <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>
          </button>{" "}
          <span>{quantity}</span>{" "}
          <button
            disabled={quantity >= 10}
            onClick={() => handleQuantity(id, true)}
          >
           <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FoodCartItem;
