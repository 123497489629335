import React from 'react';

const VideoIcon = ({size="29px",className="",fillcolor}) => {
    return (
    <svg  className={className}  width={size} height={size} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className={className} d="M2.9 5.8H0V26.1C0.00229495 26.8684 0.308567 27.6047 0.851926 28.1481C1.39528 28.6914 2.13158 28.9977 2.9 29H23.2V26.1H2.9V5.8ZM26.1 0H8.7C7.93158 0.00229495 7.19528 0.308567 6.65193 0.851926C6.10857 1.39528 5.8023 2.13158 5.8 2.9V20.3C5.8023 21.0684 6.10857 21.8047 6.65193 22.3481C7.19528 22.8914 7.93158 23.1977 8.7 23.2H26.1C26.8684 23.1977 27.6047 22.8914 28.1481 22.3481C28.6914 21.8047 28.9977 21.0684 29 20.3V2.9C28.9977 2.13158 28.6914 1.39528 28.1481 0.851926C27.6047 0.308567 26.8684 0.00229495 26.1 0ZM14.5 18.125V5.075L23.2 11.6L14.5 18.125Z"  fill={fillcolor? fillcolor:"#F2F2F2"}/>
</svg>

    );
};

export default VideoIcon;