import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import JobCategories from "../JobCategories/JobCategories";

const LeftCol = () => {
  const navigate = useNavigate();
  return (
    <div className="d-none d-lg-block col-3 col-xxl-2 stickyContainer ">
      <div className="sticky-top">
        <div className="LookMeWhiteIcon box-shadow nav-bg-opacity py-2 ps-3 d-block">
          <div className="d-flex ">
            <div
              className="LookMeButtonTypeIcon  "
              onClick={() => navigate("/dashboard")}
            >
              <img className="img-fluid" src="/images/logo.svg" alt="logo" />
            </div>
            <Link to="/job">
              <div className="type-writer-text-box">
                <h5 className="mb-0">UVIOM</h5>
                <div className="lh-1 logo-slogan overflow-hidden type-writer-text text-nowrap">
                  <Typewriter
                    options={{
                      strings: "Your Vision Is Our Mission",
                      autoStart: true,
                      loop: true,
                    }}
                  ></Typewriter>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <br />
        <JobCategories />
      </div>
    </div>
  );
};

export default LeftCol;
