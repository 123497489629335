import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import InputPhoneNumber from "src/Common/InputWithLabel/InputPhoneNumber";
import InputWithLabel from "src/Common/InputWithLabel/InputWithLabel";
import "./addAddressForm.css";


const ShippingAddAddressForm = ({ btnRef, setFunc, data, indexName, doneAccordion }) => {
  const [formData, setFormData] = useState(data ? data : {});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let prevState = formData;
    prevState[name] = value;
    setFormData({ ...prevState });
  };
  const phoneHandler = (value) => {
    setFormData(prevState => ({ ...prevState, shipping_phone: value }))
  };


  const {
    shipping_name, shipping_phone, shipping_email, shipping_address1, shipping_address2,
  } = formData;

// console.log("first",shipping_address1)

  const handleSubmit = (e) => {
    e.preventDefault();
    setFunc(formData);
    if (shipping_name  && shipping_email && shipping_address1 && shipping_address2 && shipping_phone && isValidPhoneNumber(shipping_phone) && isPossiblePhoneNumber(shipping_phone)) {
      doneAccordion()
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {/* name */}
      <InputWithLabel
        label="Name "
        name="shipping_name"
        value={shipping_name ? shipping_name : ""}
        handler={handleChange}
        checkoutInput
        placeholder="Enter Your Name"
        disabled
        required={true}
      ></InputWithLabel>

      {/* Phone  */}

      <InputPhoneNumber defaultCountry="BD" value={shipping_phone} handler={phoneHandler} label="Phone" />
      {/* email  */}
      <InputWithLabel
        label="Email"
        checkoutInput
        name="shipping_email"
        value={shipping_email ? shipping_email : ""}
        handler={handleChange}
        placeholder="Enter Your Email"
        disabled
      ></InputWithLabel>
      <InputWithLabel
        label="Address 1"
        checkoutInput
        name="shipping_address1"
        value={shipping_address1 ? shipping_address1 : ""}
        handler={handleChange}
        placeholder="Enter Your Address"
        required={true}

      ></InputWithLabel>
      <InputWithLabel
        label="Address 2"
        checkoutInput
        name="shipping_address2"
        value={shipping_address2 ? shipping_address2 : ""}
        handler={handleChange}
        placeholder="Enter Your Address"
        required={true}

      ></InputWithLabel>
      <div className="text-center mt-2">
        <button
          type="submit"
          className=" send-code-btn text-dark cart-confirm-btn"
        >
          Save
        </button>
      </div>
    </Form>
  );
};

export default ShippingAddAddressForm;
