import React from 'react';
import CompanySingleInfo from './CompanySingleInfo';

const CompanyInfo = ({ data, socialLinks }) => {
    const {
        company_EINN,
        company_address,
        company_phone,
        company_email,
        company_name,
        website
    } = data;
    
    return (
        <div
            style={{ fontSize: "14px" }}
            className="company-profile applicantProfile p-2 my-3 pt-4"
          >
            <CompanySingleInfo title="Company name" value={company_name} />
            <CompanySingleInfo title="Company EINN" value={company_EINN} />
            <CompanySingleInfo title="Phone" value={company_phone} />
            <CompanySingleInfo isLink title="Email" value={company_email} />

            <CompanySingleInfo title="Address" value={company_address} />
            <CompanySingleInfo isLink={true} title="Website" value={website} />

            {socialLinks?.length > 0 &&
              socialLinks?.map((item) => (
                <CompanySingleInfo
                  key={item.id}
                  isLink={true}
                  title={item.social_media_name}
                  value={item.social_media_link.substr()}
                />
              ))}
            <br />
          </div>
    );
};

export default CompanyInfo;