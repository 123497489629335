import React from "react";
import ViewPhotos from "../../NestedRouteOnDashBodr/ViewPhotos/ViewPhotos";

export const Photos = () => {
  return (
    <>
      <div className="row mb-3 singlePostSample bg-footer rounded-3">
        <div className="col-12 MiddlePartScrool NewsFeedPostBg newsFeedPage  py-0 my-0 px-0  ">
          <ViewPhotos />
        </div>
      </div>
    </>
  );
};
