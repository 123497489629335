import React, { useState } from 'react';
import CallScreenFooter from '../Common/CallScreenFooter';



const LookMe = () => {

    const [showLookMe, setShowLookMe] = useState(false);

    const handleLookMeBtn = () => {
        setShowLookMe(prevState => !prevState);
    }

    return (
        <div>
         <div className='csc__img-container' style={{backgroundImage:"url(/ceo.png)"}}>
                <img className='img img-fluid csc-img' src="/ceo.png" alt="user" />
                {
                    showLookMe &&  <div className='csc__call-text csc__lookMe-popup text-center'>
                    <p className='mb-4'>Share Your Screen</p>
                    <div className='csc__lookMe__popup-btn'>
                        <button onClick={handleLookMeBtn}>cancel</button>
                        <button>save</button>
                    </div>
                </div>
               }
            </div>

            <CallScreenFooter handleLookMeBtn={handleLookMeBtn} lookMe/>   
        </div>
    );
};

export default LookMe;