import React from "react";
import { Col } from "react-bootstrap";
import {Link} from "react-router-dom"
// import "./storeItem.css";
const StoreItem = (props) => {
//   const {data}=props
//   const {slug}=data
  
  return (
    <Col as={Link} to={`/corporate/store-view/slug`} className="store__item-box">
      <div
        className="store__item-main d-flex align-items-end justify-content-end"
        style={{ backgroundImage: `url("/corporate/store-banner.png")` }}
      >
        <div className="text-end store__item__text-box">
          <h4 className="mb-0">Lidi . com</h4>
          <p className="font-14 mb-0">Dhanmondi, Dhaka</p>
          <p className="font-14">
            <span>
              <span className="product__card-star">&#9733;</span>
              <span className="product__card-star">&#9733;</span>
              <span className="product__card-star">&#9733;</span>
              <span className="product__card-star">&#9733;</span>
              <span className="product__card-star">&#9733;</span> (5.0)
            </span>
            <span className="ms-2">50 Reviews</span>
          </p>
        </div>
        <div>
          <img
            src="/corporate/store-logo.png"
            className="store__item-logo"
            alt=""
          />
        </div>
      </div>
    </Col>
  );
};

export default StoreItem;