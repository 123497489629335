import React, { useEffect, useState } from "react";
import { Overview } from "../NestedRouteOnDashBodr/About/Overview";

import { getInfo } from "src/Common/apiCall/GetApiCalls";
import { userOverviewInfoUrl } from "src/Projects/Life/Common/CommonUrl";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";

function AboutOverviewWrapper() {
  const [isLoading, setIsLoading] = useState(true);
  const [info, setinfo] = useState();
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    getInfo(setIsLoading, userOverviewInfoUrl, setinfo);
  };

  return <>{isLoading ? <DottedCircleSpinner /> : <Overview info={info} />}</>;
}
export default AboutOverviewWrapper;
