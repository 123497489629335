import React from "react";

const BarsIcon = ({size="25", className}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className={className}
        d="M15 1C15 1.55228 14.5523 2 14 2L4 2C3.44772 2 3 1.55228 3 0.999999C3 0.447714 3.44772 -1.00993e-06 4 -9.61649e-07L14 -8.74226e-08C14.5523 -3.91404e-08 15 0.447715 15 1Z"
        fill="#3D3D3D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.5C12 8.05228 11.5523 8.5 11 8.5L1 8.5C0.447716 8.5 9.92815e-07 8.05228 1.0411e-06 7.5C1.08938e-06 6.94771 0.447716 6.5 1 6.5L11 6.5C11.5523 6.5 12 6.94772 12 7.5Z"
        fill="#F1A350"
      />
      <path
        className={className}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 14C15 14.5523 14.5523 15 14 15L4 15C3.44772 15 3 14.5523 3 14C3 13.4477 3.44772 13 4 13L14 13C14.5523 13 15 13.4477 15 14Z"
        fill="#3D3D3D"
      />
    </svg>
  );
};

export default BarsIcon;
