import React, { useEffect, useRef } from "react";
import "./popupMessage.css";
import PopupMessageHeader from "./PopupMessageHeader";
import { MessageList } from "../ChatContent/MessageList";
import { useSelector } from "react-redux";
import ChatContentFooter from "../ChatContent/ChatContentFooter";
const PopupMessage = () => {
  const messagesEndRef = useRef(null);

  const {
    current_chatting_user: targetedUserInfo,
    newMessageBool,
    messageLoading,
    showMessagePopup,
  } = useSelector((state) => state.messengerData);

  useEffect(() => {
    messagesEndRef?.current?.scrollIntoView();
  }, [newMessageBool]);
  // console.log("targetedUserInfo",targetedUserInfo)
  return (
    <>
      {showMessagePopup && targetedUserInfo ? (
        <div className={`popup__message-main box-shadow`}>
          <PopupMessageHeader targetedUserInfo={targetedUserInfo} />

          <div
            className="chat__content-wrapper"
            style={{ overflowY: !messageLoading ? "hidden" : "scroll" }}
          >
            <div
              className="popup__chat__content-body"
              id="popup__chat__content"
              style={{
                overflowX: "hidden",
                overflowY: messageLoading ? "hidden" : "scroll",
              }}
            >
              <MessageList />
              <div ref={messagesEndRef}>{"  "}</div>
            </div>
          </div>
          <ChatContentFooter
            messagesEndRef={messagesEndRef}
            to_user={targetedUserInfo.id}
            isPopup={true}
          />
        </div>
      ) : null}
    </>
  );
};

export default PopupMessage;
