import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import HomeIcon from "src/Projects/Life/icons/HomeIcon";
import LiveVideoIcon from "src/Projects/Life/icons/LiveVideoIcon";
import MessageIcon from "src/Projects/Life/icons/MessageIcon";
import SearchIcon from "src/Projects/Life/icons/SearchIcon";
import VideoIcon from "src/Projects/Life/icons/VideoIcon";
import NavigationOffCanvas from "../../../../Components/NavigationOffCanvas";

export const TopNavBar = ({ openSearchbox, searchBoxShow }) => {
  // previos path calculation
  const { state } = useLocation();
  const [previosLife, setpreviosLife] = useState();
  let familyPath = "/life/family-life";
  let friendPath = "/life/friend-life";
  let professionalPath = "/life/professional-life";
  useEffect(() => {
    if (state) {
      let path = state.from.pathname;
      if (path) {
        if (path.includes(familyPath)) {
          setpreviosLife(familyPath);
        } else if (path.includes(friendPath)) {
          setpreviosLife(friendPath);
        } else if (path.includes(professionalPath)) {
          setpreviosLife(professionalPath);
        } else {
          setpreviosLife(familyPath);
        }
      }
    } else {
      setpreviosLife(familyPath);
    }
  }, [state]);

  const location = useLocation();
  let homeActiveClass = "";
  if (location.pathname === "/life/professional-life")
    homeActiveClass = "navbarActiveItem";
  if (location.pathname === "/life/family-life")
    homeActiveClass = "navbarActiveItem";
  if (location.pathname === "/life/friends-life")
    homeActiveClass = "navbarActiveItem";

  return (
    <Container
      fluid
      className="customContainerFluidPadding MainMenuBar mb-3 stickyParts"
      id="main-navigation"
      role="navigation"
    >
      <NavLink
        className={({ isActive }) =>
          `topNavItem ${isActive && homeActiveClass}`
        }
        onClick={() => {
          window.scrollTo({ top: 0, left: 0 });
        }}
        aria-label="home"
        to={`${previosLife}`}
      >
        <HomeIcon className="topNavIcon" size="25" />
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          `topNavItem ${isActive && "navbarActiveItem"}`
        }
        aria-label="videos"
        to={`${previosLife}/watch-video`}
      >
        <VideoIcon className="topNavIcon" size="25" />
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          `topNavItem ${isActive && "navbarActiveItem"}`
        }
        aria-label="live"
        to={`${previosLife}/go-live`}
      >
        <LiveVideoIcon className="topNavIcon mt-2" size="28" />
      </NavLink>
      <div
        className={`topNavItem ${searchBoxShow && "active"}`}
        role="search"
        onClick={openSearchbox}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            openSearchbox();
          }
        }}
      >
        <SearchIcon className="topNavIcon " size="25" />
      </div>
      <NavLink
        className={({ isActive }) =>
          `topNavItem ${isActive && "navbarActiveItem"}`
        }
        aria-label="notification"
        to={`${previosLife}/notificaiton`}
      >
        <FontAwesomeIcon
          className="topNavIcon"
          style={{ fontSize: 25 }}
          icon={faBell}
        />
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          `topNavItem ${isActive && "navbarActiveItem"}`
        }
        aria-label="message"
        to={`${previosLife}/message`}
      >
        <MessageIcon className="topNavIcon" size="25" />
      </NavLink>
      <NavigationOffCanvas />
      {/* <NavLink
        className={({ isActive }) =>
          `d-flex d-lg-none topNavItem ${isActive && "navbarActiveItem"}`
        }
        aria-label="navigation extra"
        to={`${previosLife}/more-option`}
      >
        <ThreeDots className="topNavIcon" size="20" />
      </NavLink> */}
    </Container>
  );
};
