import React from "react";
import { Col, Row } from "react-bootstrap";

const CancelledOrderCard = () => {
  return (
    <div className="secondary-bg p-2 my-3 rounded-2">
      <Row className="g-3">
        <Col lg="8" xl="10">
          <div className="favorite-card p-0 m-0">
            <div className="cart-item-img-box">
              <img className="img img-fluid" src="/food-image/food1.png" alt="" />
            </div>
            <div
              style={{ height: "100%" }}
              className="cart-text-box align-items-start d-flex flex-column justify-content-between"
            >
              <p>ZamZam Restuant (Mirpur-11)</p>
              <p className="">
                <small>
                  {"steak frites, (French: “steak [and] fries”) a simple dish of beef steak.".substring(
                    0,
                    60
                  )}
                  ...
                </small>
              </p>
              <p>Quantity: 1</p>
            </div>
          </div>
        </Col>
        <Col lg="4" xl="2">
          <div className="d-flex align-items-center h-100">
            <div className="d-flex justify-content-end align-items-center flex-grow-1 flex-lg-column flex-xxl-row">
              <p className="m-0 mb-0 product-status-text border-orange">
                <small>Cancelled</small>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CancelledOrderCard;
