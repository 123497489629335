import SearchedJobs from "./../components/SearchedJobs";

function FindJobs() {
  return (
    <div id="wrapper">
      <SearchedJobs />
    </div>
  );
}
export default FindJobs;
