import React from "react";
import { Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductCard from "../../Components/Common/ProductCard/ProductCard";

export const ProductInfiniteScroll = ({
  product,
  setCurrentPage,
  currentPage,
  hasMore,
  zoroLengthShow

}) => {
  return (
    <>
      {product && product.length !== 0 ?
       (
        <InfiniteScroll
          dataLength={product.length} //This is important field to render the next data
          next={() => {
            setCurrentPage(currentPage + 1);
          }}
          hasMore={hasMore}
        >
          <Row className="home__Product-box g-2">
            {product.map((data) => (
              <ProductCard data={data} key={data.id} />
              // <div key={data.id}>hi</div>
            ))}
          </Row>
        </InfiniteScroll>
      ):
      <>
      {zoroLengthShow?<h5>Yet No Data</h5>:null}
      </>
      }
    </>
  );
};
