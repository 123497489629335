import { Link, useLocation } from "react-router-dom";
import PreviousWorkPreview from "../Common/PreviousWorkPreview";

import "./previousWorks.css";

function PreviousWorks() {
  const { pathname:url } = useLocation();
  const dynamicPath = url.split("/")[1];
  const dynamicUser = url.split("/")[2];

  return (
    <div className="previousWork__list">
      {Array.from({ length: 6 }).map((_, index) => (
        <Link
          to={`/${dynamicPath}/${dynamicUser}/previouswork/work1`}
          key={index}
        >
          <PreviousWorkPreview />
        </Link>
      ))}
    </div>
  );
}
export default PreviousWorks;
