import {
  Navigate,
  Route,
  Routes as Switch,
  useLocation,
} from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import LeftSidebar from "./../LeftSidebar";
import PersonalInfo from "./../PersonalInfo";
import ReceivedHistory from "./../ReceivedHistory";
import SentHistory from "./../SentHistory";
import BillingHistory from "./../BillingHistory";

function MyProfile() {
  return (
    <Row>
      <Col md={5} xl={3}>
        <LeftSidebar />
      </Col>
      <Col md={7} xl={9}>
        <ProfileRoutes />
      </Col>
    </Row>
  );
}
export default MyProfile;

const ProfileRoutes = () => {
  return (
    <Switch>
      <Route path={`/personal-info`} element={<PersonalInfo />} />
      <Route path={`/receivedhistory`} element={<ReceivedHistory />} />
      <Route path={`/senthistory`} element={<SentHistory />} />
      <Route path={`/billinghistory`} element={<BillingHistory />} />
      <Route path="*" element={<Navigate to="personal-info" replace />} />
    </Switch>
  );
};
