import styled from "styled-components";

function WelcomeIntro() {
  return (
    <Wrapper>
      <div className="banner-container">
        <img
          src="https://res.cloudinary.com/yex/image/upload/v1669443334/UVIOM/mobiles_dkzqvb.png"
          className="img-fluid"
          alt=""
        />
      </div>
      <div>
        <h5 className="title">Welcome to UVIOM Life</h5>
        <p className="details">
          UVIOM Life is a social media where your information will be Protected
          and you can express yourself to your Family, Friends, and Professional
          life around the world the way you want.
          <br />
          <br />
          Welcome to the new unique social media life!
        </p>

        <div>
          <img src="https://i.ibb.co/9tgq73J/signatue.png" alt="" />
        </div>
      </div>
    </Wrapper>
  );
}

export default WelcomeIntro;

const Wrapper = styled.div`
  display: grid;
  background-image: url("https://i.ibb.co/7jQg6Hc/logo.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  grid-template-columns: 1.2fr 1fr;
  align-items: center;

  @media (max-width: 43.75em) {
    grid-template-columns: 1fr;
  }

  & .banner-container {
    /* height: 25rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    padding-right: 2.5rem;

    @media (max-width: 43.75em) {
      /* display: none; */
      height: 10rem;

      & img {
        height: 10rem;
      }
    }

    &::after {
      content: "";
      display: block;
      height: 100%;
      width: 0.15rem;
      border-radius: 3rem;
      background: var(--bg-blue);
      position: absolute;
      right: 1.5rem;

      @media (max-width: 43.75em) {
        display: none;
      }
    }
  }

  & .title {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  & .details {
  }
`;
