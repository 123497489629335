import React, { useEffect, useState } from "react";
import styled from "styled-components";

function LogoBlink({ size = 6 }) {
  const [logoLife, setLogoLife] = useState(true);

  useEffect(() => {
    const logoSwap = setInterval(() => {
      setLogoLife(!logoLife);
    }, 2500);

    return () => clearInterval(logoSwap);
  }, [logoLife]);

  return (
    <Wrapper size={size}>
      {logoLife ? (
        <>
          <img src="/images/logo.svg" alt="UVIOM" />
          <div className="title">UVIOM</div>
        </>
      ) : (
        <>
          <img src="/images/logo-life.svg" alt="UVIOM" />
          <div className="title">LIFE</div>
        </>
      )}
    </Wrapper>
  );
}

export default LogoBlink;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & img {
    height: ${(pros) => pros.size}rem;
  }

  & .title {
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--text-info);
  }
`;
