import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import "./PdfViewer.css";
const PdfViewer = () => {
  const navigate = useNavigate();
  const { pdfUrl } = useParams();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <>
      <div className="pdfViewerMainContainer py-5">
        <Document
          className="PDFDocument"
          file={`/${pdfUrl}`}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {[...new Array(numPages)].map((_, index) => {
            return (
              <Page
                className="PDFPage"
                key={"pages" + index + 1}
                pageNumber={index + 1}
              />
            );
          })}
        </Document>
        <button
          className="pdfViewerCloseBtn"
          onClick={() => navigate(-1)}
          type="button"
        >
          {" "}
          X{" "}
        </button>
      </div>
    </>
  );
};

export default PdfViewer;
