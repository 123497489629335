import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { allShopsUrl, ShopBannerImgBaseL } from "../../utilities/CommonUrl";
import StoreItem from "../Store/StoreItem/StoreItem";
import paginationApiCall from "src/Common/apiCall/paginationApiCall";
import ShopSkeleton from "../../Components/Placeholder/ShopSkeleton";

const Shop = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [info, setinfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const paginationApiArg = {
    postList: info,
    setPostList: setinfo,
    currentPage: currentPage,
    setHasMore: setHasMore,
    Url: allShopsUrl,
    setIsLoading: setIsLoading,
  };

  useEffect(() => {
    paginationApiCall(paginationApiArg, true);
  }, [currentPage]);

  return (
    <Container fluid className="py-4" style={{ minHeight: "65vh" }}>
      <h4 className="py-2">Shops</h4>

      <Row xs="1" lg="2" className="g-4">
        {isLoading
          ? [...new Array(4)].map((_, index) => <ShopSkeleton key={index} />)
          : info &&
            info.length !== 0 &&
            info.map((data) => (
              <StoreItem
                data={data}
                key={data.id}
                ShopBannerImgBaseWithLocation={ShopBannerImgBaseL}
              />
            ))}
      </Row>

      {hasMore ? (
        <Row>
          <div
            className="col-12 text-center"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <div className="btn btn-warning w-50 my-3">Load More</div>
          </div>
        </Row>
      ) : null}
    </Container>
  );
};

export default Shop;
