import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ScrollToTop from "src/Common/ScrollToTop";
import styled from "styled-components";
import ChannelVideos from "./ChannelVideos";

function SinglePlaylistVideo({ playlists }) {
  const { playlistId } = useParams();
  const navigate = useNavigate();

  const {
    channelDetails: { channel_name, photo },
  } = useSelector((store) => store.viewData);

  const currentPlaylist = playlists.find(
    (playlist) => playlist.id === parseInt(playlistId)
  );

  return (
    <div>
      <ScrollToTop />
      <GoBack onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faChevronLeft} /> Go To Playlist
      </GoBack>
      {currentPlaylist.videos.length < 1 && (
        <div className="primary-text"> NO Video In this Playlist</div>
      )}
      <ChannelVideos
        videos={currentPlaylist.videos}
        channelName={channel_name}
        channelProfile={photo}
      />
    </div>
  );
}
export default SinglePlaylistVideo;

const GoBack = styled.button`
  background-color: var(--text-info);
  color: var(--clr-white);
  border-radius: 0.2rem;
  padding: 0.3rem 0.5rem;
  border: 0;
  margin-bottom: 0.5rem;
`;
