import React from "react";
import "./CompanyBanner.css";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const CompanyBanner = ({
  showEditIcon,
  handler,
  profile_picture,
  profile_banner,
}) => {
  return (
    <div>
      <div className="jobDeatailsCover">
        <LazyLoadImage
          className="img-fluid img"
          src={profile_banner || "/Life/job/Nogod Cover Photo.png"}
          alt=""
          height="500"
          width="100%"
          effect="blur"
        />

        {showEditIcon && (
          <>
            <input
              type="file"
              name={"profile_banner"}
              id="profile_banner_photo"
              className="file-input"
              onChange={handler}
              accept="image/png, image/jpg, image/jpeg"
            />
            <label
              htmlFor="profile_banner_photo"
              className="companyBannerCoverIcon"
            >
              <FontAwesomeIcon icon={faCamera} />
            </label>
          </>
        )}

        <div className="jobDetailsProfile">
          <img
            className=" img img-fluid"
            src={profile_picture || "/Life/job/Nogod Logo.png"}
            alt=""
          />
          {showEditIcon && (
            <>
              <input
                type="file"
                name={"profile_picture"}
                id="profile_picture_photo"
                className="file-input "
                onChange={handler}
                accept="image/png, image/jpg, image/jpeg"
              />
              <label
                htmlFor="profile_picture_photo"
                className="companyBannerProfileIcon pointer"
              >
                <FontAwesomeIcon icon={faCamera} />
              </label>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyBanner;
