import React from "react";
import { Form, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import useStore from "src/hooks/useStore";
import useTheme from "src/hooks/useTheme";
import "./Navigation.css";

export const NavLinks = ({ data }) => {
  const { handleLogout } = useStore();
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const { handleThemeChange, currentTheme } = useTheme();

  return (
    <>
      <Nav.Link as={Link} to="/about">
        About
      </Nav.Link>

      <Nav.Link as={Link} to="/contact">
        Contact
      </Nav.Link>
      {/* <Nav.Link as={Link} to="/career">
        Career
      </Nav.Link> */}

      <Nav.Link as={Link} to="/privecy">
        Privacy
      </Nav.Link>
      <Nav.Link as={Link} to="/dashboard">
        Dashboard
      </Nav.Link>
      {Number(authUser.id) ? (
        <>
          <Nav.Link as={Link} to="/life/uviom-profile">
            Profile
          </Nav.Link>
          <Nav.Link
            as={Link}
            to=""
            aria-label="Logout"
            onClick={() => handleLogout(navigate)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLogout(navigate);
              }
            }}
          >
            Logout
          </Nav.Link>
        </>
      ) : (
        <Nav.Link as={Link} to="/login">
          Login
        </Nav.Link>
      )}

      <Nav.Link as="button" className="btn-0">
        <Form.Check
          onChange={handleThemeChange}
          checked={currentTheme === "dark"}
          type="switch"
          label="Dark"
          id="disabled-custom-switch"
        />
      </Nav.Link>
    </>
  );
};
