import React from 'react'
import ViewVideos from '../../NestedRouteOnDashBodr/ViewVideos/ViewVideos'

export const Videos = () => {
  return (
    <>
     <div className="row mb-3 singlePostSample bg-footer rounded-3">
        <div className="col-12 MiddlePartScrool NewsFeedPostBg newsFeedPage  py-0 my-0 px-0  ">
          <ViewVideos />
        </div>
      </div>
    </>
  )
}
