/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { PhotoSlider } from "react-photo-view";
import {
  commentStoreUrl,
  mainProfilePic,
  postLikeUserListUrl,
  postVideoPath,
  temporaryImageUrl,
  videoThumbnailPath,
} from "../../../../Common/CommonUrl";

import { SeeMore } from "src/Projects/Life/Components/SeeMore";
import { useLocation, useNavigate } from "react-router-dom";
import { postLikeSubmit } from "../../NewsFeedPost/newsFeedPostFunc";
import {
  commentRemove,
  commentShow,
  getLikeCount,
  getPostCommentCount,
  showcommentEdit,
} from "../../NewsFeedPost/apiCalls";
import useStore from "src/hooks/useStore";
import { LikeCommentShare } from "../../NewsFeedPost/Components/PostActions/LikeCommentShare";
import { CommentextArea } from "../../NewsFeedPost/Post/CommentextArea";
import Swal from "sweetalert2";
import axios from "axios";
import { CommentRow } from "../../NewsFeedPost/Post/FirstStageComment/CommentRow";
import useAuth from "src/hooks/useAuth";
import { LikedUserModal } from "../../NewsFeedPost/Post/LikedUserList/LikedUserModal";
import { PostActions } from "../../NewsFeedPost/Components/PostActions/PostActions";
import FeedVideoPlayer from "src/Common/VideoPlayer/VideoPlayer";
import { LazyLoadImage } from "react-lazy-load-image-component";

const NewsFeedMyPost = (props) => {
  const { pathname } = useLocation();
  const ToastFunc = (icon, title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: icon,
      title: title,
    });
  };

  const { getLikedUserList } = useStore();
  const { authUser } = useAuth();

  const { post, lastPostRef } = props;
  const {
    name,
    time,
    full_post_details,
    data_content,
    profile_photo,
    personal_like_status,
    post_like_count,
    post_reply_count,
    post_id,
    user_id,
    friend_wall_post_status,
    family_wall_post_status,
    professional_wall_post_status,
    public_wall_post_status,
  } = post;

  const modifiedPost = {
    id: post_id,
    post_details: full_post_details,
    post_contents: data_content,
    family_post_status: family_wall_post_status,
    friend_post_status: friend_wall_post_status,
    professional_post_status: professional_wall_post_status,
    public_post_status: public_wall_post_status,
  };

  const postActionsArg = {
    post_id,
    user_id,
    post: modifiedPost,
  };

  const postMainId = post.post_id;
  const post_contents = data_content ? data_content : null;
  const contentLength = post_contents && post_contents.length;
  const lengthEqualOne = post_contents && contentLength === 1;
  const lengthEqualThree = post_contents && contentLength === 3;
  const lengthMoreThenFour = post_contents && contentLength > 4;
  const navigate = useNavigate();
  const [clickShare, setClickShare] = useState(false);
  // --------galarry work --------
  const [isGalleryActive, setIsGalleryActive] = useState(false);
  const [galleryIndex, setgalleryIndex] = useState();
  const setIsGalleryActiveFnc = (setIndexNumber) => {
    setgalleryIndex(setIndexNumber);
    setIsGalleryActive(true);
  };
  // galarry work -end-------

  // ------------/// like commnt share arg -///--------------------------------------
  const personalLikeUserId =
    personal_like_status !== null
      ? Number(personal_like_status.user_id)
      : Number(0);
  const [postLikeCount, setpostLikeCount] = useState(
    post_like_count && post_like_count
  );
  const commentInitialPage = 1;

  const [isColored, setisColored] = useState("logoColorText");
  const [colorUpdate, setcolorUpdate] = useState(false);
  const [likedUserListPage, setlikedUserListPage] = useState(1);
  const [likedUserHasMore, setlikedUserHasMore] = useState(false);
  const [postLikedUserList, setpostLikedUserList] = useState([]);
  const [ListDropdownShow, setListDropdownShow] = useState(false);
  const [likedUsersModalState, setlikedUsersModalstate] = useState(false);
  const [commentBoxShow, setcommentBoxShow] = useState(false);
  const [commentsData, setcommentsData] = useState([]);
  const [buttonRowVisible, setbuttonRowVisible] = useState(false);
  const [commentloading, setcommentloading] = useState(false);
  const [commentListShow, setcommentListShow] = useState(false);
  const [commentCountState, setcommentCountState] = useState(post_reply_count);
  const showLikedUsersModal = () => {
    setlikedUsersModalstate(true);
  };
  const commentboxClick = () => {
    setcommentBoxShow(!commentBoxShow);
  };
  const getLikedUserListFunc = () => {
    const arg = {
      id: postMainId,
      currentPage: likedUserListPage,
      setHasMore: setlikedUserHasMore,
      likedUserListState: postLikedUserList,
      setpostLikedUserList: setpostLikedUserList,
      preDataFormate: false,
      likedUserListUrl: postLikeUserListUrl,
    };
    getLikedUserList(arg);
  };
  useEffect(() => {
    if (likedUserListPage > 1) {
      getLikedUserListFunc();
    }
  }, [likedUserListPage]);
  const closeLikedUsersModal = () => {
    setlikedUserListPage(1);
    setpostLikedUserList([]);
    setlikedUsersModalstate(false);
  };
  const likedUserModalArg = {
    likedUsersModalState: likedUsersModalState,
    closeLikedUsersModal: closeLikedUsersModal,
    likedUserListState: postLikedUserList,
    currentPage: likedUserListPage,

    setcurrentPage: setlikedUserListPage,
    hasMore: likedUserHasMore,
  };
  // ------------/// like commnt share arg end -///--------------------------------------
  // commnt box state --------------------
  // eslint-disable-next-line no-unused-vars
  const [loading, setloading] = useState(false);
  const [formData, setformData] = useState({
    post_id: postMainId,
    comment: "",
  });
  const [editCommentBox, seteditCommentBox] = useState({
    commentId: "",
    post_id: "",
    comment: "",
  });
  const [commentCurrentPage, setcommentCurrentPage] =
    useState(commentInitialPage);
  const [commentReplyBox, setcommentReplyBox] = useState({
    post_id: "",
    comment_id: "",
    comment_reply: "",
  });

  let commentRowArg = {
    commentReplyBox: commentReplyBox,
    setcommentReplyBox: setcommentReplyBox,
  };
  useEffect(() => {
    if (commentCurrentPage > 1) {
      const arg = {
        PostId: postMainId,
        commentCurrentPage: commentCurrentPage,
        commentsData: commentsData,
        setcommentsData: setcommentsData,
        setbuttonRowVisible: setbuttonRowVisible,
        previousDataReset: false,
      };
      commentShow(arg);
    }
  }, [commentCurrentPage]);
  const formateCommentBox = () => {
    setformData({ post_id: postMainId, comment: "" });
  };
  // profile Picture ------------------
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(false);

  // profile Picture end------------------

  const postLikeSubmitArg = {
    setisColored: setisColored,
    setcolorUpdate: setcolorUpdate,
    getLikeCount: getLikeCount,
    setpostLikeCount: setpostLikeCount,
  };
  const showLikeListDropdown = () => {
    const arg = {
      id: postMainId,
      currentPage: likedUserListPage,
      setHasMore: setlikedUserHasMore,
      likedUserListState: postLikedUserList,
      setpostLikedUserList: setpostLikedUserList,
      preDataFormate: true,
      likedUserListUrl: postLikeUserListUrl,
    };
    getLikedUserList(arg);
    setListDropdownShow(!ListDropdownShow);
  };
  const likedUserListArg = {
    showLikeListDropdown: showLikeListDropdown,
    postLikeCount: postLikeCount,
    ListDropdownShow: ListDropdownShow,
    likedUserListState: postLikedUserList,
    showLikedUsersModal: showLikedUsersModal,
  };
  const commentListClick = () => {
    const arg = {
      PostId: postMainId,
      commentCurrentPage: commentInitialPage,
      commentsData: commentsData,
      setcommentsData: setcommentsData,
      setbuttonRowVisible: setbuttonRowVisible,
      previousDataReset: true,
      setcommentloading: setcommentloading,
    };
    commentShow(arg);
    setcommentListShow(true);
  };
  const likeCommentShareArg = {
    personalLikeUserId: personalLikeUserId,
    isColored: isColored,
    colorUpdate: colorUpdate,
    postLikeSubmit: postLikeSubmit,
    postMainId: postMainId,
    postLikeSubmitArg: postLikeSubmitArg,
    postLikeCount: postLikeCount,
    likedUserListArg: likedUserListArg,
    commentboxClick: commentboxClick,
    commentListClick: commentListClick,
    commentCountState: commentCountState,
    comment_reply_count: 0,
    setClickShare: setClickShare,
    clickShare: clickShare,
    commentloading: commentloading,
  };
  // for commntbox ----------------
  const inputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...formData };
    newData[name] = value;
    setformData(newData);
  };
  const commentReload = () => {
    setcommentListShow(true);

    const arg = {
      PostId: postMainId,
      commentCurrentPage: commentInitialPage,
      commentsData: commentsData,
      setcommentsData: setcommentsData,
      setbuttonRowVisible: setbuttonRowVisible,
      setcommentloading: setcommentloading,
      previousDataReset: true,
    };
    commentShow(arg);
  };
  const commentSubmitCode = (formData) => {
    if (formData.comment && formData.comment.trim() !== "") {
      setloading(true);
      axios
        .post(`${commentStoreUrl}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((res) => {
          if (res.data.status === 1) {
            commentReload();
            getPostCommentCount(postMainId, setcommentCountState);
            setloading(false);
            formateCommentBox();
          } else {
            setloading(false);

            ToastFunc("error", "something went wrong");
          }
        })
        .catch(() => {
          setloading(false);

          ToastFunc("error", "Request Failed");
        });
    }
  };
  const onkeyPressSubmit = (event) => {
    if (event.type === "keypress") {
      if (event.key === "Enter" && event.shiftKey === false) {
        event.preventDefault();

        commentSubmitCode(formData);
      } else if (event.shiftKey && event.key === "Enter") {
      }
    } else {
      commentSubmitCode(formData);
    }
  };
  const commentRemoveFunc = (commentId) => {
    const arg = {
      commentId: commentId,
    };
    const commentShowArg = {
      PostId: postMainId,
      commentCurrentPage: commentCurrentPage,
      commentsData: commentsData,
      setcommentsData: setcommentsData,
      setbuttonRowVisible: setbuttonRowVisible,
      previousDataReset: true,
    };
    commentRemove(arg, commentShowArg);
    getPostCommentCount(postMainId, setcommentCountState);
  };
  const commentEditFunc = (commentId) => {
    // console.log("commentEditFunc")
    const arg = {
      commentId: commentId,
      seteditCommentBox: seteditCommentBox,
    };
    showcommentEdit(arg);
  };
  // zz
  const loadMore = () => {
    setcommentCurrentPage((prev) => {
      return prev + 1;
    });
  };
  const CommentextAreaArg = {
    inputHandleChange: inputHandleChange,
    onkeyPressSubmit: onkeyPressSubmit,
    placeHolder: "Write comment...",
    BorderAreaClass: "",
    fieldName: "comment",
    inputValue: formData.comment,
    commentloading: commentloading,
  };

  return (
    <>
      <div
        className="box-shadow mb-3 singlePostSample bg-footer rounded-3"
        ref={lastPostRef}
      >
        <div className="col-12 MiddlePartScrool  py-0 my-0">
          <div className="PostHeaderOption pt-3 px-3 mb-2">
            <div className="ProfileAndName">
              <div className="PostSectionProfile">
                <img
                  className="img-fluid"
                  src={`${mainProfilePic}/${profile_photo}`}
                  alt={name}
                  onError={(e) => (e.target.src = "/user.png")}
                  onClick={() => setShow(true)}
                />
              </div>
              <div className="ms-2">
                <h6
                  aria-labelledby={name}
                  className="my-0 smallTextSize-sm namehoverEffect"
                  onClick={() =>
                    navigate(`/life/public-life/${user_id}`, {
                      from: pathname,
                    })
                  }
                >
                  {name}
                </h6>
                <div className="smallTextSize-sm">
                  <small> {time}</small>
                </div>
              </div>
            </div>
            <PostActions postActionsArg={postActionsArg} />
          </div>
        </div>
        {post_contents.length > 0 && post_contents[0].type === "image" && (
          <>
            <div className="col-12 NewsPhotoDiv mt-2 position-relative">
              {post_contents.slice(0, 4).map((item, index) => (
                <div
                  key={`i+${index}`}
                  className={`float-start 
                  ${lengthEqualOne ? "oneImageCss" : "multiImageCss"} 
                  ${lengthEqualThree && index === 0 ? "threeImageCss" : ""} 
                  ${
                    lengthMoreThenFour && index === 3
                      ? "MoreThenFourImages"
                      : ""
                  }`}
                >
                  <LazyLoadImage
                    effect="blur"
                    placeholderSrc="/Life/placeholder.jpg"
                    className={` img-fluid `}
                    src={`${temporaryImageUrl}/${item.location}${item.content_name}`}
                    alt="photos"
                    onClick={() => setIsGalleryActiveFnc(index)}
                    width="100%"
                    height="100%"
                    threshold={300}
                  />

                  {/* button over 4no image  */}
                  {lengthMoreThenFour && index === 3 && (
                    <button
                      type="button"
                      className="btn btn-secondary  width-fit-content bseemorePhotoBtn rounded-0 absolute-center smallTextSize-sm"
                      onClick={() => setIsGalleryActiveFnc(index)}
                    >
                      See More {contentLength - 4} Photos
                    </button>
                  )}
                </div>
              ))}
              <PhotoSlider
                images={post_contents.map((item) => ({
                  src: `${temporaryImageUrl}/${item.location}${item.content_name}`,
                  key: item.id,
                }))}
                visible={isGalleryActive}
                onClose={() => setIsGalleryActive(false)}
                index={galleryIndex}
                onIndexChange={setgalleryIndex}
              />
            </div>
          </>
        )}
        {post_contents.length > 0 && post_contents[0].type === "video" && (
          <div className="col-12 mt-2 position-relative">
            <FeedVideoPlayer
              thumbnail={videoThumbnailPath + post_contents[0]?.thumbnail}
              url={postVideoPath + post_contents[0]?.content_name}
            />
          </div>
        )}
        <div className="col-12 pt-2 px-3 text-align-justify ">
          <small>
            {full_post_details !== "null" ? (
              <SeeMore>{full_post_details}</SeeMore>
            ) : (
              ""
            )}
          </small>
        </div>
        {/* like share comment option  */}
        <div className="col-12  my-2 mt-3 ">
          {/* next code here  */}
          <LikeCommentShare data={likeCommentShareArg} />
          <div className="searchDropdown  mx-auto firstStageCommentDiv">
            {commentBoxShow && (
              <div className="MessageInput ">
                <div className="container-fluid px-2">
                  <CommentextArea data={CommentextAreaArg} />
                </div>
              </div>
            )}
            {commentListShow && (
              <>
                {commentsData &&
                  commentsData.length > 0 &&
                  commentsData.map((item, index) => (
                    <div className="MessageInput " key={`${index}`}>
                      <CommentRow
                        item={item}
                        authUser={authUser}
                        editCommentBox={editCommentBox}
                        seteditCommentBox={seteditCommentBox}
                        commentRemoveFunc={commentRemoveFunc}
                        commentEditFunc={commentEditFunc}
                        commentReload={commentReload}
                        commentRowArg={commentRowArg}
                      />
                    </div>
                  ))}
                {buttonRowVisible && (
                  <div className="mediumTextSize pointer" onClick={loadMore}>
                    See More...
                  </div>
                )}
              </>
            )}
          </div>
          <LikedUserModal data={likedUserModalArg} />
        </div>
      </div>
    </>
  );
};

export default NewsFeedMyPost;
