import React from 'react'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap'

export const ConfirmModal = ({modalArg}) => {
    const {show, setShow,title,handleSubmit}=modalArg;
  return (
    <Modal
    show={show}
    onHide={() => setShow(false)}
    aria-labelledby="popup modal for confirm"
    centered
  >
    <Modal.Header className="modalTop modalBody d-flex justify-content-center ">
      <h3>{title}</h3>
    </Modal.Header>
    <Modal.Body className="modalBody">
      <div className="addFndTypeModal">
        <form onSubmit={handleSubmit}>

          <div className="d-flex flex-wrap place-content-center">
            <button aria-labelledby="confirm" type="submit" className="button" >
              Confirm <FontAwesomeIcon icon={faCheck} />
            </button>
            <div aria-labelledby="cancel button"
            onClick={() => setShow(false)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setShow(false)
              }
            }}
            className="button">
              Cancel <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        </form>
      </div>
    </Modal.Body>
  </Modal>
  )
}
