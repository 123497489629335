import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";

function AgreementModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="creative__modal__header" closeButton>
        <h6 className="mb-0">Agreement</h6>
      </Modal.Header>
      <Modal.Body className="creative__modal__body">
        <Row>
          <Col lg={5}>
            <div className="d-flex gap-3 align-items-center">
              <img
                src="https://i.ibb.co/Bfp9vX0/freelancer-3.jpg"
                alt=""
                className="creative__modal__freelancer__img"
              />
              <div>
                <h6>Freelancer</h6>
                <p className="mb-0">Thailand</p>
                <p>
                  <FontAwesomeIcon className="text-orange" icon={faStar} /> 5.0
                  (12)
                </p>
              </div>
            </div>

            <h6 className="mt-3">Requirement Task</h6>

            <div className="creative__skills__badge mt-3">UX Research</div>
            <br />
            <div className="creative__skills__badge mt-2 mb-4">
              Information Architecture
            </div>

            <br />
            <div className="creative__skills__badge align-items-center d-inline-flex">
              Add New Task
              <FontAwesomeIcon
                className="ms-2 text-orange"
                icon={faPlusSquare}
              />
            </div>
            <br />
            <div className="creative__skills__badge align-items-center d-inline-flex mt-2">
              Upload File
              <FontAwesomeIcon
                className="ms-2 text-orange"
                icon={faCloudUploadAlt}
              />
            </div>
          </Col>
          <Col className="mt-4 mt-lg-0" lg={7}>
            <Form.Control type="text" className="input-reset" placeholder="Agreement Title" />
            <Form.Control
              className="mt-3 input-reset"
              type="text"
              placeholder="Agreement Amount"
            />
            <Form.Control
              className="mt-3 shadow-0 input-reset"
              as="textarea"
              placeholder="Agreement Details"
              rows={8}
            />
          </Col>
        </Row>

        <div className="mt-3 py-3 text-lg-end">
          <Button className="btn__creative" onClick={props.onHide}>
            Sent Job Agreement
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default AgreementModal;
