import React from "react";
import { Container } from "react-bootstrap";
import NotIcon from "../icons/NotIcon";
import "../Payment.css";

const PaymentFailed = () => {
  const handleClose = () => {
    window.open('https://www.google.com');
    window.close();
  };
  return (
    <Container className="h-100 payment failed p-0 b" fluid>
      <div className="payment-card-box">
        <div className="payment-card p-2 p-sm-3 p-md-4 pb-3">
          <div className="payment-card-header mt-4">
            <div className="payment-card-icon">
              <NotIcon className="close-icon" />
            </div>
            <h3 className="mt-4">Payment Failed</h3>
          </div>
          <div className="payment-card-body my-0">
            <div className="text-center">
             <p>We couldn't process this payment. Error Code: CSE_65240 </p>
            </div>
            <div className="my-3 ">
              <p className="mb-0 text-center">Please try another card or payment method</p>
            </div>
           
          </div>
          <div className="payment-card-footer">
            <button onClick={handleClose}>Try Again</button>
            <button onClick={handleClose}>Back To Home</button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PaymentFailed;
