import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import RatingModal from "src/Projects/Corporate/Components/RatingModal/RatingModal";
const ReceivedOrderCard = () => {

    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => setShowModal(false);

  return (
    <div className="secondary-bg p-2 my-3">
      <Row className="g-3">
        <Col xl="6">
          <div className="favorite-card p-0 m-0">
            <div className="cart-item-img-box">
              <img className="img img-fluid" src="/corporate/man.png" alt="" />
            </div>
            <div
              style={{ height: "100%" }}
              className="cart-text-box align-items-start d-flex flex-column justify-content-between"
            >
              <p className="mb-3">
                <small>
                  {"Titan NN1639SM01 Metal Analog Watch for Men - Silver Titan NN1639SM01 Metal Analog Watch for Men - Silver".substring(
                    0,
                    60
                  )}
                  ...
                </small>
              </p>
              <p>Quantity: 1</p>
            </div>
          </div>
        </Col>
        <Col xl="6">
          <div className="d-flex">
            <div className="flex-grow-1">
              <p>
                <small>Order Date</small> <br /> <small>18 jun 2022</small>
              </p>
              <p className="mb-1">
                <small>Estimate delivery Date</small> <br />{" "}
                <small>20 Jun 2022 - 22 Jun 2022</small>
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center flex-grow-1 flex-column flex-xxl-row">
              <p className="m-0 product-status-text">
                <small>Received</small>
              </p>
              <button onClick={()=> setShowModal(true)} className=" send-code-btn cart-confirm-btn manage-order-btn py-1 text-dark">
                Review
              </button>
            </div>
          </div>
        </Col>
          </Row>
          <RatingModal show={showModal} handleClose={handleModalClose} />
    </div>
  );
};

export default ReceivedOrderCard;
