import React from 'react'
import { CoverArea } from './Components/CoverArea';
import { PageIntroPart } from './PageIntroPart';
import { LifePageViewRoutes } from './LifePageViewRoutes';
export const LifePageView = () => {

  return (
    <>
      <div className='container-fluid mainContentWithRoundedBg  mt-3 mb-0 mb-lg-2 p-3 lifePageView'>
        {/* cover ------------ */}
        <CoverArea />
        {/* page Intro part  */}
        <PageIntroPart />
        {/* post  */}
      </div>
      <div className='container-fluid my-3 lifePageView customContainerFluidPadding'>
        <LifePageViewRoutes />
      </div>

    </>
  )
}
