import React, { useEffect, useState } from "react";

import { publicProfileMultiInfoUrl } from "src/Projects/Life/Common/CommonUrl";
import "./About.css";
import { MiddleLeftCol } from "./MiddleLeftCol";
import { MiddleRightCol } from "./MiddleRightCol";

import { publicProfileMultiInfo } from "./Components/GetApiCalls";
import { BubbleScale } from "src/Common/BubbleScale";

export const About = ({ middleColArg }) => {
  const { user_id } = middleColArg;
  const [isLoading, setIsLoading] = useState(true);

  const [info, setinfo] = useState();
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    publicProfileMultiInfo(
      setIsLoading,
      publicProfileMultiInfoUrl,
      setinfo,
      user_id
    );
  };
  if (isLoading) {
    return <BubbleScale extraStyle={{ marginTop: "5rem" }} />;
  }
  return (
    <div className="mb-5">
      <MiddleLeftCol />
      <div className=".col-lg-8">
        <MiddleRightCol
          info={info}
          getData={getData}
          middleColArg={middleColArg}
        />
      </div>
    </div>
  );
};
