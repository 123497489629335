import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useNavigate } from "react-router-dom";
import { personalProfilePic } from "src/Projects/Life/Common/CommonUrl";

const PersonCard = ({ data }) => {
  const navigate = useNavigate();
  const { first_name, last_name, photo, address } = data;
  const pro_url = photo ? `${personalProfilePic}/${photo}` : "/user.png";
  const addr = address ? JSON.parse(address) : null;
  const final_addr = addr
    ? (addr?.current_city || "Bangladesh") + " " + (addr.hometown || " ")
    : "Bangladesh";

  return (
    <div className="my-3">
      <div className="body-dark-bg p-2 d-flex align-items-center justify-content-between rounded-2">
        <Link to={""} className="d-flex person__card__name">
          <span
            className="rounded-circle overflow-hidden me-2"
            style={{ height: "4.4rem", width: "4.4rem" }}
          >
            <LazyLoadImage
              effect={"opacity"}
              placeholderSrc="/user.png"
              src={pro_url}
              className="img img-fluid"
              style={{ height: "4.4rem", width: "4.4rem" }}
              alt={first_name + last_name}
            />
          </span>
          <span className="">
            <p className="text-ellipsis mb-1 mt-2 fs-6">
              {first_name + " " + last_name}
            </p>
            <p className="mb-2">
              <small>{final_addr}</small>
            </p>
          </span>
        </Link>

        <div className="d-flex position-relative ">
          <button
            className="me-3 sava__post__view-btn border-0 fs-6"
            onClick={() => navigate(`/life/public-life/${data.id}`)}
          >
            <small>View Profile</small>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonCard;
