import React from "react";
import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "./contact.css";
import { ContactUsUrl } from "src/Projects/Corporate/utilities/CommonUrl";
import { LeftComp } from "./LeftComp";
import { SimplePostApiCalls } from "src/Common/apiCall/PostApiCalls";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let prevState = formData;
    prevState[name] = value;
    setFormData({ ...prevState });
  };
  const formateFormData = () => {
    setFormData({});
  };
  const updateFormFunc = (e) => {
    e.preventDefault();
    const formDataModify = {
      name: `${formData.fname} ${formData.lname}`,
      ...formData,
    };
    delete formDataModify.fname;
    delete formDataModify.lname;
    const formArg = {
      formData: formDataModify,
      Url: ContactUsUrl,
      setIsLoading: setIsLoading,
      formateFormData: formateFormData,
    };

    if (
      formDataModify.name &&
      formDataModify.email &&
      formDataModify.message &&
      formDataModify.phone &&
      formDataModify.subject &&
      formDataModify.uviom_id
    ) {
      SimplePostApiCalls(formArg);
    }
  };
  return (
    <Container
      fluid
      style={{ minHeight: "65vh" }}
      className=" mb-4 py-4 corporate-contact"
    >
      <Row className="g-4">
        <LeftComp />
        <Col lg={6}>
          <Form className="contactForm me-0 me-lg-5" onSubmit={updateFormFunc}>
            <Row>
              <Col>
                <Form.Group className="mb-5" controlId="fname">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.fname ? formData.fname : ""}
                    name="fname"
                    onChange={handleChange}
                    placeholder="First Name"
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-5" controlId="lname">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.lname ? formData.lname : ""}
                    name="lname"
                    onChange={handleChange}
                    placeholder="Last Name"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-5" controlId="phone">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    value={formData.phone ? formData.phone : ""}
                    name="phone"
                    onChange={handleChange}
                    placeholder="017......."
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-5" controlId="uviom_id">
                  <Form.Label>UVIOM Id</Form.Label>
                  <Form.Control
                    type="number"
                    value={formData.uviom_id ? formData.uviom_id : ""}
                    name="uviom_id"
                    onChange={handleChange}
                    placeholder="UVIOM Id"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-5" controlId="email">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    value={formData.email ? formData.email : ""}
                    name="email"
                    onChange={handleChange}
                    placeholder="Email Address"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-5" controlId="lName">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.subject ? formData.subject : ""}
                    name="subject"
                    onChange={handleChange}
                    placeholder="Subject"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-5" controlId="message">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                className="contactTextarea"
                value={formData.message ? formData.message : ""}
                name="message"
                onChange={handleChange}
                placeholder="Write Here..."
                required
              />
            </Form.Group>

            <div className="text-end">
              {isLoading ? (
                <button className="send-code-btn cart-confirm-btn" disabled>
                  Send
                </button>
              ) : (
                <button
                  className="send-code-btn cart-confirm-btn"
                  type="submit"
                >
                  Send
                </button>
              )}
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
