import styled from "styled-components";
import { formateDate } from "src/utilities/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import useAuth from "src/hooks/useAuth";

function ChannelAbout({
  userId,
  channelTitle,
  channelDesc,
  createdAt,
  totalVideos,
  channelCategory,
  totalViews,
}) {
  const { authUser } = useAuth();
  return (
    <Wrapper>
      <div className="content">
        <h4 className="title">{channelTitle}</h4>
        <p className="desc">
          {channelDesc
            ? channelDesc
            : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi corrupti consequatur perferendis mollitia, repudiandae obcaecati quasi ullam quod, accusantium modi dicta quo necessitatibus natus veritatis. Hic placeat tempora eos! Fuga error debitis itaque. Natus dolores nisi minus vitae molestias non asperiores illo, voluptas sequi vel consequuntur mollitia perferendis nobis maxime consectetur! Id veniam aut itaque, commodi, maiores quibusdam at consequuntur corporis, reiciendis eum deleniti? Laborum nihil quas deserunt cupiditate? Eaque neque, nisi velit beatae atque sed amet, voluptatem aspernatur nihil quibusdam soluta ex magnam, laboriosam expedita eligendi fugiat aperiam quas aliquam id enim facere officia fugit. Necessitatibus quasi omnis aliquam."}
        </p>
      </div>

      <div className="stats">
        <div className="fw-500">Joined</div>
        <div>{formateDate(createdAt)}</div>
        <br />
        <div className="fw-500">Total Videos</div>
        <div>{totalVideos}</div>
        <br />
        <div className="fw-500">Channel Category</div>
        <div>{channelCategory}</div>
        <br />
        <div className="fw-500">Total Views</div>
        <div>{parseInt(totalViews) ? totalViews : "Not Counted Yet"}</div>
        {authUser.id !== parseInt(userId) && (
          <>
            <br />
            <button className="report" title="Report This Channel">
              <FontAwesomeIcon color={`var(--text-primary)`} icon={faFlag} />
            </button>
          </>
        )}
      </div>
    </Wrapper>
  );
}
export default ChannelAbout;

const Wrapper = styled.div`
  display: flex;
  gap: 2rem;
  color: var(--text-primary);

  @media (max-width: 48em) {
    flex-direction: column;
  }

  & .content {
    flex-basis: 60%;

    & .title {
      font-size: 1.2rem;
    }
  }

  & .report {
    background-color: transparent;
    border: 0;
  }
`;
