import { NavLink, useLocation } from "react-router-dom";

import rabiul from "./../../assets/rabiul.jpg";

import "./leftSidebar.css";

function LeftSidebar() {
  const { pathname:url } = useLocation();
  const dynamicPath = url.split("/")[1];

  return (
    <div className="leftSidebar mb-4 text-center">
      <img className="leftSidebar__avatar mb-3" src={rabiul} alt="" />
    <h5>Rabiul Islam</h5>
    <hr className="my-4" />
      <div className="mt-4 text-start">
        <NavLink
          className="leftSidebar__link"
          to={`/${dynamicPath}/profile/personal-info`}
        >
          Personal Information
        </NavLink>
        <NavLink
          className="leftSidebar__link"
          to={`/${dynamicPath}/profile/manage-profile`}
        >
          Manage Profile
        </NavLink>
      </div>
    </div>
  );
}
export default LeftSidebar;
