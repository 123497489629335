import axios from "axios";

const deleteData = ({
  Url,
  setIsLoading,
  setError = () => {},
  setSuccess = () => {},
  callAfterSuccess = () => {},
}) => {
  setIsLoading(true);
  axios
    .get(`${Url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      setIsLoading(false);
      if (res.status === 200) {
        setSuccess(res.data.message);
        setError("");
        callAfterSuccess(res.data);
      }
    })
    .catch((err) => {
      setIsLoading(false);
      setError(err.message);
      setSuccess("");
      console.log(err);
    });
};

export default deleteData;
