import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "src/Common/ScrollToTop";
import ProductDescription from "./ProductDescription";
import { useEffect } from "react";
import "./ProductDetails.css";
import { useNavigate, useLocation } from "react-router-dom";
import {
  productDetailsUrl,
  productFeatureImageUrl,
  productImgBase,
  storeWishlistUrl,
} from "../../utilities/CommonUrl";
import { getInfoWithoutResponseStatus } from "src/Common/apiCall/GetApiCalls";
import { isEmpty } from "lodash";
import { ImageMagnify } from "./ImageMagnify";
import { FeatureImage } from "./FeatureImage";
import useAuth from "src/hooks/useAuth";
import { storeWishlist } from "../../Components/Common/apiCalls";
import { SalePrice } from "../../Components/Common/ProductCard/SalePrice";
import useCartStore from "../../hooks/useCartStore";
import ToastFunc from "src/Common/ToastFunc";
import { rPrice } from "src/utilities/dist/utilities.dev";
import ProductDetailsSkeleton from "../../Components/Placeholder/ProductDetailsSkeleton";

const colors = ["#fff", "#F1A350", "#09ADA8"];
const ProductDetails = () => {
  const [imgIndex, setImgIndex] = useState();
  const [selectedColor, setSelectedColor] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [showDescription, setShowDescription] = useState(false);
  const { authUser } = useAuth();
  const [outOfStock, setoutOfStock] = useState("");
  const { addToCart, state } = useCartStore();
  const { cartItems } = state;
  const handleQuantity = (isIncrease) => {
    if (isIncrease === "+" && quantity < 10) {
      setQuantity((prevState) => prevState + 1);
    } else if (isIncrease === "-" && quantity > 1) {
      setQuantity((prevState) => prevState - 1);
    }
  };

  // ----------------------------------------------------------------

  const { pathname } = useLocation();
  const lastSegment = pathname.split("/").pop();
  const [isLoading, setIsLoading] = useState(true);
  const [info, setinfo] = useState([]);
  const {
    id,
    has_category,
    product_image,
    product_name,
    stock,
    sale_price,
    has_brand,
    has_feature_image,
    short_description,
  } = info && info;

  useEffect(() => {
    getInfoWithoutResponseStatus(
      setIsLoading,
      `${productDetailsUrl}/${lastSegment}`,
      setinfo
    );
  }, [lastSegment]);
  // -----------wishlist--------
  const [whishListAddLoading, setwhishListAddLoading] = useState(false);

  const addToWhishList = (e) => {
    e.preventDefault();
    const formArg = {
      formData: { product_id: id },
      Url: storeWishlistUrl,
      setIsLoading: setwhishListAddLoading,
    };
    storeWishlist(formArg);
  };
  // -------------buy now-------------------
  const navigate = useNavigate();
  const mainUrl = `${pathname.split("/")[1]}`;
  const handleCheckoutClick = () => {
    addThisProductMainFunc();
    navigate({
      pathname: `/${mainUrl}/my-cart`,
      state: { openCheckout: true },
    });
  };

  const addThisProductMainFunc = () => {
    if (outOfStock) {
    } else {
      const sameIdProduct = cartItems.filter(
        (item) => Number(item.id) === Number(info.id)
      );
      if (sameIdProduct.length > 0) {
        ToastFunc("error", "Already on cart");
      } else {
        const itemAllInfo = {
          ...info,
          sale_price: rPrice(info.sale_price),
          quantity: 1,
          eachItemTotal: rPrice(info.sale_price) * 1,
        };
        addToCart(itemAllInfo);
        ToastFunc("success", "Successfully added");
      }
    }
  };
  // -------------add to cart-------------------

  const addThisProduct = (e) => {
    e.preventDefault();
    addThisProductMainFunc();
  };

  return (
    <Container fluid>
      <ScrollToTop />
      {isLoading ? (
        <ProductDetailsSkeleton />
      ) : (
        <>
          <h6 className="py-3">{has_category && has_category.CategoryName}</h6>

          <Row className="g-4" xs="1" sm="2">
            {/* product image  */}
            <Col className="">
              <div className="h-100">
                <div className="product__details-img d-flex justify-content-center align-items-center rounded-2">
                  {imgIndex === undefined ? (
                    <ImageMagnify
                      srcPath={`${productImgBase}/${product_image}`}
                    />
                  ) : (
                    <ImageMagnify
                      srcPath={`${productFeatureImageUrl}/${has_feature_image[imgIndex].image_name}`}
                    />
                  )}
                </div>
                <div className="product__image-indicator mt-2 ">
                  <FeatureImage
                    imgIndex={imgIndex}
                    index={"undifined"}
                    setImgIndex={setImgIndex}
                    item={product_image}
                  />
                  {!isEmpty(has_feature_image) && (
                    <>
                      {has_feature_image.map((item, index) => (
                        <FeatureImage
                          key={index}
                          imgIndex={imgIndex}
                          index={index}
                          setImgIndex={setImgIndex}
                          item={item}
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div className="product__details-text p-1">
                <p className="product__details-name">
                  {product_name && product_name}
                </p>

                <p className="product-model">Model: WT25DDUI</p>
                <p>
                  <small>Available in Stoke {stock && <>({stock})</>}</small>
                </p>

                {/* rating section  */}
                <p>
                  {[...new Array(5).keys()].map((star) => (
                    <span key={star} className={"review-star"}>
                      &#9733;
                      {/* this is star html code &#9733;*/}
                    </span>
                  ))}
                  <span className="review-star">(5.00)</span> ||
                  <span>20 Rating</span>
                </p>

                <div className="d-flex">
                  <p className="me-4">
                    Brand: {has_brand && has_brand.brand_name}
                  </p>
                  <p>Shop: UVIOM Store</p>
                </div>

                <div>
                  <h5 className="text-orange">Sort Description</h5>
                  <div
                    dangerouslySetInnerHTML={{ __html: short_description }}
                  ></div>
                </div>
                {/* <div className="text-gray"><small>Original Price- {original_price && Math.round(original_price)} </small> </div> */}
                <h5 className="text-orange mb-3">
                  <SalePrice
                    stock={stock}
                    outOfStock={outOfStock}
                    setoutOfStock={setoutOfStock}
                    sale_price={sale_price}
                  />
                </h5>

                {/* color selection section  */}
                <div>
                  <p className="mb-1">Colors</p>
                  <div className="color-dot-box">
                    {colors.map((color, index) => (
                      <span
                        key={index}
                        style={{ backgroundColor: color }}
                        className={`color-dot ${
                          index === selectedColor && "selected-color-dot"
                        }`}
                        onClick={() => setSelectedColor(index)}
                      ></span>
                    ))}
                  </div>
                </div>

                {/* quantity and total price section  */}
                <div className="my-3 d-flex align-items-center">
                  <div className="quantity-box flex-grow-1">
                    <p className="mb-1">Quantity</p>
                    <div>
                      <button
                        className="eco-btn"
                        onClick={() => handleQuantity("-")}
                        disabled={quantity <= 1}
                      >
                        -
                      </button>
                      <span>{quantity}</span>
                      <button
                        disabled={quantity >= 10}
                        className="eco-btn"
                        onClick={() => handleQuantity("+")}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div className="flex-grow-1">
                    <p className="mb-1">Total Price</p>
                    <h5 className="text-orange">
                      {Math.round(quantity * sale_price)}Tk
                    </h5>
                  </div>
                </div>

                {/* add to favorite and  add to card button  */}

                <div>
                  {!isEmpty(authUser) && Number(authUser.id) && (
                    <>
                      {whishListAddLoading ? (
                        <button className="eco-btn" disabled>
                          <FontAwesomeIcon
                            className="me-2"
                            icon={faHeart}
                          ></FontAwesomeIcon>
                          Add to favorite
                        </button>
                      ) : (
                        <button className="eco-btn" onClick={addToWhishList}>
                          <FontAwesomeIcon
                            className="me-2"
                            icon={faHeart}
                          ></FontAwesomeIcon>
                          Add to favorite
                        </button>
                      )}
                    </>
                  )}
                  <div className="buy-now-btn-box">
                    <button
                      className="buy-now-btn orange-bg"
                      onClick={handleCheckoutClick}
                    >
                      Buy Now
                    </button>
                    <button
                      onClick={addThisProduct}
                      className={`buy-now-btn bg-transparent border primary-text ${
                        outOfStock ? "cursor-unset" : ""
                      } `}
                    >
                      Add To Cart
                    </button>
                  </div>

                  <button
                    onClick={() =>
                      setShowDescription((prevState) => !prevState)
                    }
                    className="eco-btn more-info-btn mt-4"
                  >
                    Additional Information
                    <FontAwesomeIcon
                      className="ms-3"
                      icon={showDescription ? faChevronUp : faChevronDown}
                    />
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}

      <br />
      {showDescription && <ProductDescription info={info} />}
    </Container>
  );
};

export default ProductDetails;
