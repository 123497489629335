import BalanceLayout from "../Common/BalanceLayout";
import "./transferHistory.css";

function TransferHistory() {
  return (
    <BalanceLayout title="transfer history">
      <div className="transferHistory">
        {Array.from({ length: 3 }).map((_, index) => (
          <div key={index} className="transferHistory__card">
            <span className="transferHistory__card__badge">
              Payment Freelancer
            </span>
            <div className="transferHistory__card__profile">
              <img
                className="transferHistory__card__img"
                src="https://i.ibb.co/F8yxsjF/client1.jpg"
                alt=""
              />
              <div>
                <h6 className="mb-1">Freelancer</h6>
                <p className="mb-0">T-Mobile, USA</p>
              </div>
            </div>

            <table className="transferHistory__card__table">
              <tbody>
                <tr>
                  <td>Account Type</td>
                  <td> UVIOM Wallet</td>
                </tr>
                <tr>
                  <td>Account Name</td>
                  <td>UVIOM Creative</td>
                </tr>
                <tr>
                  <td>UVIOM IDN (UIN)</td>
                  <td>289-10-3210</td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td>$500</td>
                </tr>
                <tr>
                  <td>Date & Time</td>
                  <td>jun-06-2022,est 10:46 AM</td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </BalanceLayout>
  );
}
export default TransferHistory;
