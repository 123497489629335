import React from "react";
import { SingleNotif } from "./SingleNotif";
import "./notificaiton.css";

export const Notificaiton = () => {
  return (
    <div className="mt-3 Notificaiton">
      <SingleNotif />
      <SingleNotif />
      <SingleNotif />
      <SingleNotif />
    </div>
  );
};
