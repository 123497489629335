import React from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { threeDotMenuUrl } from '../../../../Common/CommonUrl'

export const SinglePersonRow = (props) => {
    const { item } = props

    return (

        <Row className={`commentMargin mb-3 w-100`} >
            <Col sm={2} xs={3} className="px-xs-3">
                <a href="#">
                    <img
                        className="img-fluid w-100 rounded-circle"
                        src="/user.png"
                        alt="user"
                    />
                </a>
            </Col>
            <Col md={9} xs={7} className="px-0 align-self-center">
                <a href="#">
                    <div>{item.user.first_name} {item.user.last_name}</div>
                </a>
            </Col>
            <Col md={1} xs={2} className="pe-0 text-right align-self-center">
                <Dropdown>
                    <Dropdown.Toggle aria-label="comments" className="ToggleDesignClear">
                        <img
                            className="img-fluid w-50"
                            src={threeDotMenuUrl}
                            alt="navigation"
                        />
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="dark">
                        <div className="ClickThreeDot mb-3 px-3 pb-3 pt-3">
                            <div className="btnHoverBorder">
                                <div className=" mt-1">
                                    <h6>Add</h6>
                                </div>
                            </div>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
    )
}
