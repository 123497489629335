import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  NavLink,
  Route,
  Routes as Switch,
  Navigate,
  useLocation,
} from "react-router-dom";
import CoverProfileBanner from "../../Components/Common/CoverProfileBanner/CoverProfileBanner";
import BusinessInfo from "./BusinessInfo/BusinessInfo";
import "./create_store.css";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
const CreateStore = () => {
  const { pathname } = useLocation();
  const path = "/" + pathname.split("/")[1];

  return (
    <Container fluid className="create-store-main">
      <CoverProfileBanner
        showEditIcon
        coverImg={"/corporate/banner1.jpg"}
        profileImg={"/corporate/store-logo.png"}
      />
      <br />
      <div className="create-store-nav">
        <NavLink
          to={`${path}/seller-registration/business-info`}
          className={({ isActive }) =>
            isActive ? "create-store-nav-item-active" : ""
          }
        >
          Business Info
        </NavLink>
        <NavLink
          to={`${path}/seller-registration/personal-info`}
          relative={true}
          className={({ isActive }) =>
            isActive ? "create-store-nav-item-active" : ""
          }
        >
          Personal Info
        </NavLink>
      </div>

      <Container fluid className="secondary-bg mt-2">
        <Row>
          <Col xl="10" className="mx-auto">
            <Switch>
              <Route
                index
                element={
                  <Navigate to={`${path}/seller-registration/business-info`} />
                }
              />

              <Route path={`/business-info`} element={<BusinessInfo />} />

              <Route path={`/personal-info`} element={<PersonalInfo />} />
            </Switch>
          </Col>
        </Row>
      </Container>
      <br />
    </Container>
  );
};

export default CreateStore;
