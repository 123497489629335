/* eslint-disable react-hooks/exhaustive-deps */
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Row } from "react-bootstrap";
import React from "react";
import { useState } from "react";
import SaveAndCancelBtnGroup from "../../Components/Common/SaveAndCancelBtnGroup";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import { useDeleteSocialLinkMutation, useUpdateSocialLinkMutation } from "src/app/services/jobApi/jobPostApi";
import { useEffect } from "react";
import { deleteAlert } from "../../utilities/functions";

const SocialLinkItemUpdate = ({ data, socialLinks, setSocialLinks }) => {
  const [updateSocialLink, { isLoading, isSuccess, error: server_err }] = useUpdateSocialLinkMutation();
  const [deleteSocialLink, { isLoading: delete_loading,isSuccess:delete_success, error: delete_err }] = useDeleteSocialLinkMutation();
  const { social_media_name, social_media_link, id, title } = data;
  const [isEditable, setIsEditable] = useState(false);
  const [socialLink, setSocialLink] = useState(social_media_link);
  const [prevSocialLink, setPrevSocialLink] = useState(social_media_link);

  const [error, setError] = useState("");


  const handleSave = () => {
    if (prevSocialLink === socialLink) {
      setError("Link up to date.");
    } else if (socialLink.length < 1) {
      setError("Link filed can't empty");
    } else {
      setError("");
      const updatedData = {
        social_media_link: socialLink,
        social_media_name,
        title,
      };
      updateSocialLink({ data: updatedData, id: id });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setPrevSocialLink(socialLink)
      setIsEditable(false);
    }
    if (server_err) {
      setError(server_err.error)
    }
  },[isSuccess, server_err])

  const handleDelete = () => {

    deleteAlert().then((result) => {
      if (result.isConfirmed) {
        deleteSocialLink(id);
     }
   })
  };

  useEffect(() => {
    if (delete_success) {
      const remainData = socialLinks.filter((item) => item.id !== id);
      setSocialLinks([...remainData]);
    }
    if (delete_err) {
      setError(delete_err.error)
    }
  },[delete_success, delete_err])

  const handleCancelBtn = () => {
    setError("");
    setIsEditable(false);
    setSocialLink(prevSocialLink);
  };
  return (
    <Form.Group as={Row} className={`mb-3`} controlId="">
      {(isLoading || delete_loading) && <BubbleLoader />}
      <Form.Label
        className={`d-none d-md-block InputWithLabelLabel text-start ps-4`}
        column
        sm="3"
      >
        {social_media_name}
      </Form.Label>
      <Col md="9" className="mb-2">
        <Form.Label className={"d-block d-md-none "}>
          {social_media_name}
        </Form.Label>
        {isEditable ? (
          <Form.Control
            required
            value={socialLink}
            onChange={(e) => setSocialLink(e.target.value)}
            className={`bordaredInput InputWithLabelInput`}
            type={"text"}
            placeholder="Social link"
          />
        ) : (
          <div className="d-flex mt-1 justify-content-between">
            <p className="profileItemValue m-0">
              <a
                className="profile-info-link font-14"
                href={`http://${socialLink}`}
                target="_blank"
                rel="noreferrer"
              >
                {socialLink}
              </a>
            </p>

            <div className="social-action-btn">
              <button
                onClick={handleDelete}
                type="button"
                className="eco-btn me-2"
              >
                <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
              </button>
              <button
                className="eco-btn"
                type="button"
                onClick={() => setIsEditable(true)}
              >
                <FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon>
              </button>
            </div>
          </div>
        )}
      </Col>
      <p className="text-end text-danger font-12 mb-2">{error}</p>

      {isEditable && (
        <SaveAndCancelBtnGroup
          cancelBtnHander={handleCancelBtn}
          saveBtnHandeler={handleSave}
        ></SaveAndCancelBtnGroup>
      )}
    </Form.Group>
  );
};

export default SocialLinkItemUpdate;
