import { Col } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import { MiddleLeftCol } from "./MiddleLeftCol";
import { FriendFamilyList } from "./AllFriendList/AllFriendList";
import { PendingFriends } from "./PendingFriends/PendingFriends";
import { RequestedFriends } from "./RequestedFriends/RequestedFriends";
import { FindFriends } from "./FindFriends/FindFriends";

import "./Friends.css";

const Friends = () => {
  return (
    <>
      <MiddleLeftCol />
      <Col lg={12}>
        <Routes>
          <Route path="/" element={<FriendFamilyList />} />
          <Route path="/find-friends" element={<FindFriends />} />
          <Route path="/pending-friends" element={<PendingFriends />} />
          <Route path="/requested-friends" element={<RequestedFriends />} />
        </Routes>
      </Col>
    </>
  );
};

export default Friends;
