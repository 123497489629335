import React, { createContext } from "react";
import useAuthentication from "../hooks/useAuthentication";

export const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  return (
    <AuthContext.Provider value={useAuthentication()}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
