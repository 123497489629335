import React from "react";
import { Col } from "react-bootstrap";
import { postImagePath } from "src/Projects/Life/Common/CommonUrl";

export const SingleImage = ({ item, index, setIsGalleryActiveFnc }) => {
  return (
    <Col xs={6} md={3} className="mb-4 px-2 imageParent pointer">
      <img
        className="img-fluid imgFolder object-cover"
        src={`${postImagePath}/${item.content_name}`}
        alt="photos"
        onError={(e) =>
          (e.target.src =
            "https://i.ibb.co/F4tpvw8/istockphoto-1147544807-612x612.jpg")
        }
        onClick={() => setIsGalleryActiveFnc(index)}
      />
    </Col>
  );
};
