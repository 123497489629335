import styled from "styled-components";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import {
  useGetReportCategoriesQuery,
  useGetReportContentTypeQuery,
} from "src/app/services/viewApi/viewGetApi";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import compress_image from "src/utilities/compress_image";
import axios from "axios";
import { reportPostUrl } from "src/Projects/Life/Common/CommonUrl";
// import axiosRequest from "../common/axiosRequest";
// import { createVideoReportUrl } from "../common/view_commonUrl";

function ReportVideoModal({ show, handleClose, postId }) {
  const { isLoading, data } = useGetReportCategoriesQuery();
  const { isLoading: rc_IsLoading, data: rc_Data } =
    useGetReportContentTypeQuery();

  const [selectedReportCategory, setSelectedReportCategory] = useState(null);
  const [selectedReportContentType, setSelectedReportContentType] =
    useState(null);
  const [isReporting, setIsReporting] = useState(false);
  const [selectImage, setSelectImage] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const [reportData, setReportData] = useState({
    content_type_id: "",
    category_id: "",
    description: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const prevData = { ...reportData };
    prevData[name] = value;
    setReportData(prevData);
  };

  const afterSuccess = (data) => {
    setIsReporting(false);
    handleClose();
  };
  const afterReject = (data) => {
    console.error(data);
    setIsReporting(false);
    handleClose();
  };

  const handleReportCategory = (event) => {
    setSelectedReportCategory(event);
    const prevData = { ...reportData };
    prevData["category_id"] = event.value;
    setReportData(prevData);
  };

  const handleReportContentType = (event) => {
    setSelectedReportContentType(event);
    const prevData = { ...reportData };
    prevData["content_type_id"] = event.value;
    setReportData(prevData);
  };

  const handleSubmit = async () => {
    if (reportData.category_id === "") {
      setErrorMsg("Category is Required");
      return;
    } else if (reportData.content_type_id === "") {
      setErrorMsg("Type is Required");
      return;
    } else if (reportData.description === "") {
      setErrorMsg("Description is Required");
      return;
    } else {
      setErrorMsg("");
    }
    setIsReporting(true);

    const formData = new FormData();
    formData.append("content_type_id", reportData.content_type_id);
    formData.append("category_id", reportData.category_id);
    formData.append("description", reportData.description);

    if (selectImage) {
      const compressed_image = await compress_image([selectImage]);
      formData.append("photo", compressed_image[0]);
    }

    axios({
      method: "POST",
      url: reportPostUrl + postId,
      data: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "applications/json",
      },
    })
      .then((res) => {
        if (res.data.status === 1) {
          afterSuccess(res.data);
        } else {
          afterReject(res.data);
        }
      })
      .catch((err) => {
        afterReject(err);
      });
  };

  return (
    <Modal className="viewModal" show={show} onHide={handleClose}>
      {isLoading || rc_IsLoading || isReporting ? (
        <div className="py-5">
          <DottedCircleSpinner />
        </div>
      ) : (
        <ModalWrapper>
          <Modal.Header className="stickyParts z-index-2 py-3">
            <button className="text-orange" onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h4 className="modal-title">Report Post</h4>

            {/*   <button
              className="save save-modal rounded-1"
              onClick={handleSubmit}
              type="submit"
            >
              Submit
            </button> */}
          </Modal.Header>
          <Modal.Body>
            <Form>
              {selectImage && (
                <img
                  className="image"
                  src={URL.createObjectURL(selectImage)}
                  alt=""
                />
              )}

              <input
                type="file"
                onChange={(event) => setSelectImage(event.target.files[0])}
                name="photo"
                id="photo"
                accept="image/*"
                hidden
              />
              <div className="text-center">
                <label className="select-image" htmlFor="photo">
                  Select Image
                </label>
              </div>

              {data && (
                <Form.Group className="mt-2 mt-lg-3 ">
                  <Form.Label className="mb-0">Select Category</Form.Label>

                  <Select
                    options={data.map((report) => ({
                      value: report.id,
                      label: report.name,
                    }))}
                    onChange={handleReportCategory}
                    value={selectedReportCategory}
                    className="flex-grow-1 text-black"
                  />
                </Form.Group>
              )}

              {rc_Data && (
                <Form.Group className="mt-2 mt-lg-3 ">
                  <Form.Label className="mb-0">Select Type</Form.Label>
                  <Select
                    options={rc_Data.map((rcContent) => ({
                      value: rcContent.id,
                      label: rcContent.content_type,
                    }))}
                    onChange={handleReportContentType}
                    value={selectedReportContentType}
                    className="flex-grow-1 text-black"
                  />
                </Form.Group>
              )}

              <Form.Group className="mt-2 mt-lg-3">
                <Form.Label className="mb-0">Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Tell Us What's Wrong"
                  name="description"
                  onChange={handleChange}
                  value={reportData.description}
                />
              </Form.Group>
              {errorMsg && (
                <div className="text-center text-danger">{errorMsg}</div>
              )}
            </Form>

            <div className="text-end mt-3">
              <button
                className="submit save save-modal"
                onClick={handleSubmit}
                type="submit"
              >
                Submit
              </button>
            </div>
          </Modal.Body>
        </ModalWrapper>
      )}
    </Modal>
  );
}
export default ReportVideoModal;

const ModalWrapper = styled.div`
  .cropper-container {
    height: 200px;
    position: relative;
  }

  & .modal-header {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    gap: 0.5rem;
    position: sticky;

    & > button {
      background-color: transparent;
      border: 0;
    }

    & > .modal-title {
      margin: 0;
      font-size: 1.1rem;
    }
  }

  & .submit {
    border: 0;
    background-color: var(--bg-success) !important;
    border-radius: 2rem;
    padding-inline: 1rem;
  }

  & .image {
    width: 100%;
    height: 10rem;
    object-fit: contain;
  }

  & .select-image {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    background-color: var(--light-gray);
    color: var(--text-info);
  }
`;
