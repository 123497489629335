import Navigation from "src/Projects/Life/LifePages/LoginPage/Shared/Navigation";
import Footer from "src/Projects/Life/LifePages/LoginPage/Footer/Footer";

function LayoutWrapper({ children }) {
  return (
    <>
      <Navigation />
      <div id="wrapper" className="pt-0">
        {children}
      </div>
      <Footer />
    </>
  );
}
export default LayoutWrapper;
