import React from "react";
import { Modal } from "react-bootstrap";
import { SinglePersonRow } from "../SinglePersonRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";

export const LikedUserModal = ({ data }) => {
  const {
    likedUsersModalState,
    closeLikedUsersModal,
    likedUserListState,
    currentPage,

    setcurrentPage,
    hasMore,
  } = data;

  return (
    <Modal
      show={likedUsersModalState}
      onHide={closeLikedUsersModal}
      centered
      className="lifeComModal"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>All</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid scroll-y px-2 position-relative">
          {likedUserListState ? (
            <>
              {likedUserListState.map((item) => (
                <SinglePersonRow item={item} key={item.id} />
              ))}
            </>
          ) : (
            <div>Loading</div>
          )}
        </div>
      </Modal.Body>
      {hasMore && (
        <Modal.Footer className="border-0">
          <div
            className=" mx-auto  text-center d-flex pointer"
            onClick={() => setcurrentPage(currentPage + 1)}
          >
            <div className="me-2">
              <FontAwesomeIcon icon={faAngleDoubleDown} />
            </div>
            <div>Load More</div>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};
