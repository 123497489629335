import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ShowPhotos from "./ShowPhotos";
import "./ViewPhotoAndVIdeos.css";

import { Routes, Route } from "react-router-dom";
import {
  otherProfileImgUrl,
  postImagePath,
} from "src/Projects/Life/Common/CommonUrl";
import { getInfo } from "../../PublicLife/Components/GetApiCalls";

const ViewPhotos = ({ middleColArg }) => {
  let { path, url } = useLocation();
  const { user_id } = middleColArg || {};
  // postImagePath
  const [isLoading, setIsLoading] = useState(true);
  const [info, setinfo] = useState();
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    getInfo(setIsLoading, otherProfileImgUrl, setinfo, user_id);
  };
  if (isLoading) {
    return null;
  }
  return (
    <div>
      <div className="photoVideoDiv">
        <Routes>
          <Route path="/" element={<ShowPhotos />} />
          {/* <h5 className="singleNotification d-inline-block">All Photos </h5> */}

          {/* <Route path="/videos" element={<ShowVideos />} /> */}
        </Routes>
      </div>
    </div>
  );
};

export default ViewPhotos;
