import { Container } from "react-bootstrap";
import { Routes as Switch, Route } from "react-router-dom";
import FormTimeline from "../components/FormTimeline";
import DeliveryForm from "./../components/DeliveryForm";
import ProductForm from "./../components/ProductForm";
import SelectVehicle from "./../components/SelectVehicle";
import stock1 from "./../assets/stock1.jpg";

function Request() {
  return (
    <div id="wrapper">
      <div
        className="heroArea__bg lg-vh-90"
        style={{ backgroundImage: `url(${stock1})` }}
      >
        <div className="py-5"></div>
        <Container>
          <div className="w-lg-75 mx-auto">
            {/* Form timeline */}
            <FormTimeline />
            {/* Routes */}
            <RequestRoutes />
          </div>
        </Container>
        <div className="py-5"></div>
      </div>
    </div>
  );
}
export default Request;

const RequestRoutes = () => {
  return (
    <Switch>
      <Route path="/" element={<DeliveryForm />} />
      <Route path="/productinfo/vehicle" element={<SelectVehicle />} />
      <Route path="/productinfo" element={<ProductForm />} />
    </Switch>
  );
};
