import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faGraduationCap,
  faHeart,
  faHome,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";

export const Overview = ({ middleColArg }) => {
  const { OverviewInfo } = middleColArg;
  const [formData, setformData] = useState({
    users_info: "",
    education_info: "",
    work_info: "",
    relationship: "",
  });
  useEffect(() => {
    if (!isEmpty(OverviewInfo)) {
      setformData({
        users_info:
          OverviewInfo && OverviewInfo.users_info
            ? OverviewInfo.users_info
            : "",
        education_info:
          OverviewInfo && OverviewInfo.education_info
            ? OverviewInfo.education_info
            : "",
        work_info:
          OverviewInfo && OverviewInfo.work_info ? OverviewInfo.work_info : "",
        relationship:
          OverviewInfo && OverviewInfo.relationship
            ? OverviewInfo.relationship
            : "",
      });
    }
  }, [OverviewInfo]);
  const address =
    formData && formData.users_info && formData.users_info.address
      ? JSON.parse(formData.users_info.address)
      : "";
  const relationship =
    formData && formData.relationship && formData.relationship.relationship
      ? JSON.parse(formData.relationship.relationship)
      : "";

  return (
    <div className="mb-3 mt-2 lifePageView">
      <div className="mb-4">
        <div className="aboutBox mt-1 mb-4 ">
          <div className="d-flex justify-content-between border-bottom pb-2 border-white">
            <div>
              <h6>
                {formData.users_info.first_name} {formData.users_info.last_name}{" "}
                {isEmpty(OverviewInfo) && " Full Name"}
              </h6>
              <div className="smallTextSize">
                {isEmpty(OverviewInfo) && " Position"}{" "}
                {formData.work_info.position}
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="d-flex justify-content-between my-4">
              <div className="d-flex">
                <div className="me-3">
                  <FontAwesomeIcon icon={faBriefcase} />
                </div>
                <div>
                  Works at {formData.work_info.work} ({" "}
                  {isEmpty(OverviewInfo) && "?"} {formData.work_info.position})
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between my-4">
              <div className="d-flex">
                <div className="me-3">
                  <FontAwesomeIcon icon={faGraduationCap} />
                </div>
                <div>
                  Studies at {formData.education_info.institute} ,
                  {formData.education_info.location}{" "}
                  {isEmpty(OverviewInfo) && "?"}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between my-4">
              <div className="d-flex">
                <div className="me-3">
                  <FontAwesomeIcon icon={faHome} />
                </div>
                <div>
                  Lives in {address.current_city},{address.hometown}{" "}
                  {isEmpty(OverviewInfo) && "?"}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between my-4">
              <div className="d-flex">
                <div className="me-3">
                  <FontAwesomeIcon icon={faHeart} />
                </div>
                <div>
                  {relationship.relationship} {isEmpty(OverviewInfo) && "?"}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between my-4">
              <div className="d-flex">
                <div className="me-3">
                  <FontAwesomeIcon icon={faPhoneAlt} />
                </div>
                <div>
                  {formData.users_info.phone} {isEmpty(OverviewInfo) && "?"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
