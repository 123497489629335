import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { PostApiCalls } from "src/Common/apiCall/PostApiCalls";
import { workInfoStoreUrl } from "src/Projects/Life/Common/CommonUrl";

import { isEmpty } from "lodash";
import { InputField } from "../Components/InputField";
import { BubbleScale } from "src/Common/BubbleScale";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";

export const StoringInputs = ({ storingInputsArg }) => {
  const { dataReload, setIsEditableFalse, singleInfo, componentMainUrl } =
    storingInputsArg;
  const dateRef = useRef();
  const endDateRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setformData] = useState({
    work: "",
    position: "",
    description: "",
    start_date: "",
    end_date: "",
    status: 1,
    friend_status: 1,
    family_status: 1,
    professional_status: 1,
  });
  useEffect(() => {
    if (!isEmpty(singleInfo)) {
      setformData({
        user_work_info_id: singleInfo.id,
        work: singleInfo.work,
        position: singleInfo.position,
        description: singleInfo.description,
        start_date: singleInfo.start_date,
        end_date: singleInfo.end_date,
        status: singleInfo.status.public_status,
        friend_status: singleInfo.status.friend_status,
        family_status: singleInfo.status.family_status,
        professional_status: singleInfo.status.professional_status,
      });
    }
  }, [singleInfo]);

  const inputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...formData };
    newData[name] = value;
    setformData(newData);
  };
  // console.log("edit id ", formData && formData.user_work_info_id);
  const updateFormFunc = (e) => {
    e.preventDefault();
    const formArg = {
      formData: formData,
      Url:
        formData && formData.user_work_info_id
          ? componentMainUrl
          : workInfoStoreUrl,
      setIsLoading: setIsLoading,
      dataReload: dataReload,
      setIsEditableFalse: setIsEditableFalse,
      itemId:
        formData && formData.user_work_info_id
          ? formData.user_work_info_id
          : "",
    };
    PostApiCalls(formArg);
  };
  if (isLoading) {
    return <DottedCircleSpinner />;
  }
  return (
    <form onSubmit={updateFormFunc}>
      <InputField
        inputPlaceholder="work"
        textInputName="work"
        textInputValue={formData.work ? formData.work : ""}
        changeHandle={inputHandleChange}
      />
      <InputField
        inputPlaceholder="position"
        textInputName="position"
        textInputValue={formData.position ? formData.position : ""}
        changeHandle={inputHandleChange}
      />
      <InputField
        inputPlaceholder="description"
        textInputName="description"
        textInputValue={formData.description ? formData.description : ""}
        changeHandle={inputHandleChange}
      />

      <div className="container-fluid my-4">
        <Row>
          <Col className="basicInfoSelectCol">
            <Form.Control
              className="mainProfileInput"
              type="text"
              placeholder="start_date"
              ref={dateRef}
              onFocus={() => (dateRef.current.type = "date")}
              onBlur={() => (dateRef.current.type = "text")}
              value={formData.start_date}
              name="start_date"
              onChange={inputHandleChange}
            />
          </Col>
        </Row>
      </div>
      <div className="container-fluid my-4">
        <Row>
          <Col className="basicInfoSelectCol">
            <Form.Control
              className="mainProfileInput"
              type="text"
              placeholder="end_date"
              ref={endDateRef}
              onFocus={() => (endDateRef.current.type = "date")}
              onBlur={() => (endDateRef.current.type = "text")}
              value={formData.end_date}
              name="end_date"
              onChange={inputHandleChange}
            />
          </Col>
        </Row>
      </div>
      <div className="py-3 mainProfileBtnDiv">
        <button
          onClick={setIsEditableFalse}
          className="mainProfileBtn btnCandel me-3"
        >
          Cancel
        </button>
        <button
          className={`mainProfileBtn btnSave  ${isLoading ? "disabled" : ""} `}
        >
          {formData && formData.user_work_info_id ? "Update" : "Save"}
        </button>
      </div>
    </form>
  );
};
