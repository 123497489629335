import { storeData } from "src/features/bizz/UBizzDataSlice";
import {
  CACHE_EXPIRE_TIME,
  productRequestUrl,
} from "src/Projects/Corporate/utilities/CommonUrl";
import { api } from "../api";

export const ubizzApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHomeStaticData: builder.query({
      query: ({ url }) => url,
      keepUnusedDataFor: CACHE_EXPIRE_TIME,
      async onQueryStarted({ field_name }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(storeData({ data, field_name }));
        } catch {}
      },
    }),

    productRequest: builder.mutation({
      query: (data) => ({
        url: productRequestUrl,
        method: "POST",
        body: data,
      }),
    }),

    storeRating: builder.mutation({
      query: ({ data, url }) => ({
        url: url,
        method: "POST",
        body: data,
      }),
    }),

    storeReport: builder.mutation({
      query: ({ data, url }) => ({
        url: url,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetHomeStaticDataQuery,
  useProductRequestMutation,
  useStoreRatingMutation,
  useStoreReportMutation,
} = ubizzApi;
