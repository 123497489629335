import React from "react";
import { Modal } from "react-bootstrap";
import "./SinglePhotoModal.css";

const SinglePhotoModal = ({ show, setShow, photo, setSinglePhotoState }) => {
  const close = () => {
    // setSinglePhotoState("");
    setShow(false);
  };
  return (
    <Modal
      show={show}
      onHide={close}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="modalTop " closeButton></Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <img className="img-fluid" src={`${photo}`} alt="" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SinglePhotoModal;
