import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SinglePhotoModal from "../../../SharedComponent/SinglePhotoModal/SinglePhotoModal";
import "./CoverAndProfile.css";
import FriendshipStatus from "./FriendshipStatus";

const CoverAndProfileSmall = ({
  status,
  sender_id,
  mainId,
  name,
  pagetype,
  type,
  bgImage,
  timeline_name,
  friendshipDetails,
}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const location = useLocation();

  const state = useSelector((state) => state.lifeData);
  const userPhotos = state[`${timeline_name}_profile`];

  const pushLocation = () => {
    if (pagetype === "public-post") {
      navigate(`/life/${pagetype}`);
    } else {
      navigate(`/life/${pagetype}-life`);
    }
  };

  if (friendshipDetails) {
    return (
      <FriendshipStatus
        lifeType={pagetype}
        status={status}
        sender_id={sender_id}
        mainId={mainId}
        details={friendshipDetails}
      />
    );
  }

  return (
    <>
      <div
        tabIndex={0}
        aria-labelledby={`${type} life`}
        onClick={pushLocation}
        className="coverAndProfileSectionSmall box-shadow mt-4"
      >
        <img
          className="coverAndProfileSectionSmall__cover"
          src={userPhotos && userPhotos.cover ? userPhotos.cover : bgImage}
          alt="Cover"
        />
        <div className="d-flex justify-content-between align-items-center px-3 pt-4 pb-3 secondary-bg ">
          <div>
            <div className="lh-1 mb-1 fw-500">{name ? name : "User"}</div>
            <img
              src="https://res.cloudinary.com/yex/image/upload/v1669472215/UVIOM/uviom-verified_l3d95w.svg"
              alt=""
            />
          </div>
          <div className="coverAndProfileSectionSmall__profile__container">
            {userPhotos &&
            userPhotos.profile &&
            !location.pathname.includes("/public-life") ? (
              <>
                <img
                  src={userPhotos.profile}
                  className="coverAndProfileSectionSmall__profile"
                  alt="user"
                />
              </>
            ) : (
              <>
                <img
                  className="coverAndProfileSectionSmall__profile"
                  src="/user.png"
                  alt="user"
                />
              </>
            )}
          </div>
          <div>
            <div className="lh-1 font-14 fw-500">{type} Life</div>
            <div className="font-14">Friends: 1.2M</div>
          </div>
        </div>

        <SinglePhotoModal setShow={setShow} show={show} />
      </div>
    </>
  );
};

export default memo(CoverAndProfileSmall);
