function ViewIcon({ size }) {
  return (
    <svg
      width="57"
      height="18"
      viewBox="0 0 57 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.604 2.17395L6.867 14.438L11.151 2.17395H13.755L8.4 17H5.334L0 2.17395H2.604Z"
        fill="#F1A350"
      />
      <path
        d="M15.8232 6.25194V16.9959H18.084V6.25194H15.8232ZM15.8232 2V4.48479H18.084V2H15.8232Z"
        fill="#F1A350"
      />
      <path
        d="M22.6475 14.9632V10.5039H29.1602V8.59152H22.6475V4.44328H30.1641V2.41064H20.3452V16.9917H30.3508V14.9591H22.6475V14.9632Z"
        fill="#F1A350"
      />
      <path
        d="M42.6641 10L39.8084 17H37.8379L31.8188 2.41479H34.3244L38.8833 14.3285L41.1399 8.77404"
        fill="#F1A350"
      />
      <path
        d="M47.7166 16.3186C47.249 17.0859 46.1348 17.0859 45.6672 16.3186L39.5765 6.32448C39.0892 5.52482 39.6648 4.5 40.6012 4.5L52.7825 4.5C53.719 4.5 54.2946 5.52482 53.8073 6.32448L47.7166 16.3186Z"
        fill="#6984A5"
      />
      <path
        d="M47.6316 13.9194C47.2073 14.6292 46.1803 14.6292 45.756 13.9194L39.6456 3.64953C39.2134 2.9239 39.7368 2 40.5814 2H52.7982C53.6428 2 54.1662 2.91993 53.734 3.64953L47.6316 13.9194Z"
        fill="#F1A350"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9491 9.33977C47.7191 9.33977 47.5327 9.16926 47.5327 8.95514V5.12872C47.5327 4.91856 47.7191 4.74805 47.9491 4.74805C48.179 4.74805 48.3654 4.91856 48.3654 5.12872V8.95514C48.3654 9.16926 48.179 9.33977 47.9491 9.33977Z"
        fill="#6984A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.4437 9.33977C45.2137 9.33977 45.0273 9.16926 45.0273 8.95514V5.12872C45.0273 4.91856 45.2137 4.74805 45.4437 4.74805C45.6737 4.74805 45.86 4.91856 45.86 5.12872V8.95514C45.86 9.16926 45.6737 9.33977 45.4437 9.33977Z"
        fill="#6984A5"
      />
    </svg>
  );
}
export default ViewIcon;
