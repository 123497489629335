import React from "react";
import { Container } from "react-bootstrap";
import "./DashBord.css";
import { Link } from "react-router-dom";
import Footer from "src/Projects/Life/LifePages/LoginPage/Footer/Footer";
import Navigation from "src/Projects/Life/LifePages/LoginPage/Shared/Navigation";
import ViewIcon from "./Icon/ViewIcon";
import { FoodDeliveryIcon } from "./Icon/FoodDeliveryIcon";
import { JobIcon } from "./Icon/JobIcon";
import { ExpressIcon } from "./Icon/ExpressIcon";
import { CarIcon } from "./Icon/CarIcon";
import { Ubiz } from "./Icon/Ubiz";
import { LifeIcon } from "./Icon/LifeIcon";
import CreativeIcon from "./Icon/CreativeIcon";

export const DashBord = () => {
  return (
    <>
      <Navigation />
      <div className="container-fluid d-flex align-items-center uviomRoundIicons dashboard">
        {/* <Carusel /> */}
        <Container>
          <div className="py-5">
            <div className="dashboard__list">
              <div className="dashboard__list-wrapper db-creative">
                <Link to="/creative">
                  <div className="dashboard__icon">
                    <span>CREATIVE</span>
                    <CreativeIcon size="55" />
                  </div>
                </Link>
              </div>

              <div className="dashboard__list-wrapper db-ubizz">
                <Link to="/ubizz">
                  <div className="dashboard__icon">
                    <Ubiz size="55" />
                    <span>BUSINESS</span>
                  </div>
                </Link>
              </div>

              <div className="dashboard__list-wrapper db-food">
                <Link to="/food">
                  <div className="dashboard__icon">
                    <FoodDeliveryIcon size="55" />
                    <span>FOOD</span>
                  </div>
                </Link>
              </div>

              <div className="dashboard__list-wrapper db-job">
                <Link to="/Job">
                  <div
                    className="dashboard__icon"
                    style={{ padding: "1.08rem" }}
                  >
                    <span>JOB</span>
                    <JobIcon size="40" />
                  </div>
                </Link>
              </div>

              <div className="dashboard__list-wrapper db-dashboard">
                <div className="dashboard__icon">
                  <img className="img-fluid" src={"/images/logo.svg"} alt="" />
                </div>
              </div>
              <div className="dashboard__list-wrapper db-ride">
                <Link to="/ride">
                  <div className="dashboard__icon">
                    <CarIcon size="55" />
                    <span>RIDE</span>
                  </div>
                </Link>
              </div>
              <div className="dashboard__list-wrapper db-view">
                <Link to="/view">
                  <div
                    className="dashboard__icon "
                    style={{ padding: "1.1rem" }}
                  >
                    <span>VIEW</span>
                    <ViewIcon size="40" />
                  </div>
                </Link>
              </div>

              <div className="dashboard__list-wrapper db-life">
                <Link to="/life/public-post">
                  <div className="dashboard__icon">
                    <LifeIcon size="55" />
                    <span>LIFE</span>
                  </div>
                </Link>
              </div>

              <div className="dashboard__list-wrapper db-express">
                <Link to="/express">
                  <div className="dashboard__icon">
                    <ExpressIcon size="55" />
                    <span>EXPRESS</span>
                  </div>
                </Link>
              </div>

              <br />
            </div>
          </div>
        </Container>
      </div>

      <Footer />
    </>
  );
};
