import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./JobApply.css"

const InputFileWithLabel = ({name="", value="", handler=()=>{}, label="", accept="",className}) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId="">
          <Form.Label
            className={`d-none d-md-block jobApplyInputLabel ${className}`}
            column
            sm="3"
          >
           {label}
          </Form.Label>
          <Col md="9">
                <Form.Label className={"d-block d-md-none"}>{label}</Form.Label>
            <input
              type="file"
              name={name}
              id="file-input-driving"
              className="file-input"
            onChange={handler}
            accept={accept}
            />
            <label
              htmlFor="file-input-driving"
              className="jobApplyInput"
              id="jobApplyFileInput"
            >
              <span className="file-placeholder">{value}</span>
              <span className="file-text-btn ">
               Add CV
                <FontAwesomeIcon
                className="ms-2 primary-text"
                size="lg"
                  icon={faPlusCircle}
                ></FontAwesomeIcon>
              </span>
            </label>
          </Col>
        </Form.Group>

    );
};

export default InputFileWithLabel;