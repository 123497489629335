import { Navigate, useLocation } from "react-router-dom";
import useAuth from "src/hooks/useAuth";

export function UnAuthorized({ children }) {
  const { authUser } = useAuth();
  const { state } = useLocation();

  if (!authUser.id) {
    return children;
  } else {
    return <Navigate to={state?.from ?? "/dashboard"} replace />;
  }
}
