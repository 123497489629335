import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { getInfoWithoutResponseStatus } from "src/Common/apiCall/GetApiCalls";
import paginationApiCall from "src/Common/apiCall/paginationApiCall";
import ProductCard from "../../Components/Common/ProductCard/ProductCard";
// import ProductSearchBox from "../../Components/Common/ProductSearchBox";
import {
  branddetailsUrl,
  brandWiseProductUrl,
} from "../../utilities/CommonUrl";
import Banner from "./AllBrand/Banner/Banner";

export const BrandDetails = () => {
  const { state } = useLocation();
  const { brand_name } = state.stateData;
  const [isLoading, setIsLoading] = useState(false);
  const [info, setinfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const { BrandId } = useParams();
  const {pathname } = useLocation();
  const [brandDetails, setbrandDetails] = useState();
  const lastSegment = pathname.split("/").pop();
  useEffect(() => {
    getInfoWithoutResponseStatus(
      setIsLoading,
      `${branddetailsUrl}/${lastSegment}`,
      setbrandDetails
    );
  }, []);
  const paginationApiArg = {
    postList: info,
    setPostList: setinfo,
    currentPage: currentPage,
    setHasMore: setHasMore,
    Url: `${brandWiseProductUrl}/${BrandId}`,
    setIsLoading: setIsLoading,
  };
  useEffect(() => {
    if (currentPage > 1) {
      paginationApiCall(paginationApiArg, true);
    }
  }, [currentPage]);
  useEffect(() => {
    if (BrandId) {
      paginationApiCall(paginationApiArg, true);
    }
  }, [BrandId]);
  if (isLoading) {
    return null;
  }
  return (
    <Container fluid className={`py-4 mb-5 box-shadow px-3`}>
      {isLoading ?
        <div
          className="store__banner-main d-flex align-items-end justify-content-end"
          style={{ backgroundImage: `url("/corporate/banner1.jpg")` }}
        >
        </div> :
        <>
          {!isEmpty(brandDetails) && <Banner data={brandDetails}/>}

        </>
      }
      <h4 className="py-3">{brand_name} : Products</h4>
      {/* <ProductSearchBox /> */}
      <br />
      <Row className="">
        {info && info.length !== 0 ? (
          info.map((data) => (
            <ProductCard data={data} key={data.id} img="/corporate/man.png" />
          ))
        ) : (
          <h4>No Product</h4>
        )}
      </Row>
      {hasMore ? (
        <Row>
          <div
            className="col-12 text-center"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <div className="btn loadButtonGray w-50 mt-5">Load More</div>
          </div>
        </Row>
      ) : null}
    </Container>
  );
};
