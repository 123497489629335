import React from "react";
import styled from "styled-components";

export const ModalBody1 = ({
  selectedVideo,
  setSelectedVideo,
  videoThumbnail,
  setVideoThumbnail,
}) => {
  const handleFileChange = (event) => {
    if (event.target.name === "video") {
      setSelectedVideo(event.target.files[0]);
    } else if (event.target.name === "thumbnail") {
      setVideoThumbnail(event.target.files[0]);
    }
  };

  return (
    <ModalBodyWrapper>
      <div>
        <div className="addVideo div_center">
          {selectedVideo ? (
            <video
              className="mw-100"
              controls
              src={URL.createObjectURL(selectedVideo)}
            />
          ) : (
            <label htmlFor="video">
              <img className="img-fluid" src="/View/addVideo.png" alt="" />
            </label>
          )}
        </div>
        <div className="text-center">
          <label className="select-file" htmlFor="video">
            Select Video
          </label>
        </div>
        <input
          type="file"
          name="video"
          id="video"
          accept="video/*"
          hidden
          onChange={handleFileChange}
        />
      </div>

      <div>
        <div className="thumbnail div_center">
          <label htmlFor="thumbnail">
            <img
              className="img-fluid"
              src={
                videoThumbnail
                  ? URL.createObjectURL(videoThumbnail)
                  : "/View/addThumbnail.png"
              }
              alt=""
            />
          </label>
        </div>
        <div className="text-center">
          <label className="select-file" htmlFor="thumbnail">
            Select Thumbnail
          </label>
        </div>
        <input
          type="file"
          hidden
          name="thumbnail"
          id="thumbnail"
          accept="image/*"
          onChange={handleFileChange}
        />
      </div>
    </ModalBodyWrapper>
  );
};

const ModalBodyWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 2rem;
  margin-top: 1rem;

  & > * {
    flex-basis: 100%;
  }

  & .select-file {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    background-color: var(--light-gray);
    color: var(--text-info);
    box-shadow: var(--box-shadow-2);
  }
`;
