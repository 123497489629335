import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export const TextInputField = ({
  iconName,
  isEditable,
  textInputName,
  textInputValue,
  changeHandle,
  level,
  textInputType,
  inputPlaceholder,
  disable,
  required,
}) => {
  return (
    <>
      {!isEditable ? (
        <>
          <div className="d-flex">
            <div className="me-3 align-self-start">
              <FontAwesomeIcon icon={iconName} />
            </div>
            <div>
              {level ? <>{level} &nbsp;</> : null}
              {textInputValue}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-fluid">
            <Row>
              <Col>
                <Form.Control
                  type={textInputType ? textInputType : "text"}
                  name={textInputName}
                  value={textInputValue}
                  placeholder={inputPlaceholder ? inputPlaceholder : ""}
                  className={`mainProfileInput ${disable}`}
                  onChange={changeHandle}
                  // disabled={disable}
                  required={required ? true : false}
                />
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};
