import React, { useRef } from "react";
import { Routes, useLocation, Route } from "react-router-dom";

import { About } from "../../Pages/About/About";
import { CreateChannel } from "../../Pages/CreateChannel/CreateChannel";
import { Editchannel } from "../../Pages/Editchannel/Editchannel";
import { Follower } from "../../Pages/Follower/Follower";
import { Notificaiton } from "../../Pages/Notificaiton/Notificaiton";
import { Playlist } from "../../Pages/Playlist/Playlist";
import { SaveVideo } from "../../Pages/SaveVideo/SaveVideo";
import { Video } from "../../Pages/Video/Video";
import { VideoPlay } from "../../Pages/VideoPlay/VideoPlay";
import { ChannelView } from "../../Pages/ChannelView/ChannelView";
import { TopNavBar } from "../TopNavBar/TopNavBar";
import { CardsContainer } from "./CardsContainer";
import "./middleCol.css";
import { Menuoptions } from "../Menuoptions/Menuoptions";
import Categories from "./Categories";
import Search from "../../Pages/Search/Search";

export const MiddleCol = () => {
  let { path } = useLocation();

  return (
    <div className="stickyParts">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <TopNavBar />
              <Categories />
              <CardsContainer />
            </>
          }
        />

        <Route
          path={`/video/:id`}
          element={
            <>
              <TopNavBar />
              <VideoPlay />
            </>
          }
        />

        <Route
          path={`/search`}
          element={
            <>
              <TopNavBar />
              <Search />
            </>
          }
        />

        <Route
          path={`/editchannel`}
          element={
            <>
              <TopNavBar />
              <Editchannel />
            </>
          }
        />

        <Route
          path={`/playlist/:playlistId`}
          element={
            <>
              <TopNavBar />
              <Playlist />
            </>
          }
        />

        <Route
          path={`/createChannel`}
          element={
            <>
              <TopNavBar />
              <CreateChannel />
            </>
          }
        />

        <Route
          path={`/save_video`}
          element={
            <>
              <TopNavBar />
              <SaveVideo />
            </>
          }
        />

        <Route
          path={`/menuoptions`}
          element={
            <>
              <TopNavBar />
              <Menuoptions />
            </>
          }
        />

        <Route
          path={`/follower`}
          element={
            <>
              <TopNavBar />
              <Follower />
            </>
          }
        />

        <Route
          path={`/about`}
          element={
            <>
              <TopNavBar />
              <About />
            </>
          }
        />

        <Route
          path={`/channel/:userId/*`}
          element={
            <>
              {/* <TopNavBar /> */}
              <ChannelView />
            </>
          }
        />

        <Route
          path={`/notificaiton`}
          element={
            <>
              <TopNavBar />
              <Notificaiton />
            </>
          }
        />
      </Routes>
    </div>
  );
};
