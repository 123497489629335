/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState, memo } from "react";
import {
  temporaryImageUrl,
  commentStoreUrl,
  postLikeUserListUrl,
  videoThumbnailPath,
  postVideoPath,
} from "../../../Common/CommonUrl";
import "./NewsFeedPost.css";
import Swal from "sweetalert2";
import {
  getPostCommentCount,
  commentRemove,
  showcommentEdit,
  getLikeCount,
  commentShow,
} from "./apiCalls";
import useAuth from "../../../../../hooks/useAuth";
import { CommentextArea } from "./Post/CommentextArea";
import { CommentRow } from "./Post/FirstStageComment/CommentRow";
import { postLikeSubmit } from "./newsFeedPostFunc";
import { PostActions } from "./Components/PostActions/PostActions";
import { LikeCommentShare } from "./Components/PostActions/LikeCommentShare";
import useStore from "../../../../../hooks/useStore";
import { SeeMore } from "../../../Components/SeeMore";
import { LikedUserModal } from "./Post/LikedUserList/LikedUserModal";
import { useLocation, useNavigate } from "react-router-dom";
import { PhotoSlider } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { PostUserPicture } from "./Components/PostUserPicture";
import { timeAgo } from "src/utilities/utilities";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FeedVideoPlayer from "src/Common/VideoPlayer/VideoPlayer";
import _ from "lodash";
import { URL_REGEX } from "src/Projects/Life/utils/life_utilities";
import LinkPreview from "src/Projects/Life/Components/LinkPreview/LinkPreview";
import { useMemo } from "react";
const NewsFeedPost = (props) => {
  const ToastFunc = (icon, title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: icon,
      title: title,
    });
  };
  const { authUser } = useAuth();
  const { getLikedUserList } = useStore();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { post, lastPostRef, noAction = false } = props;
  const {
    comments_count,
    comment_reply_count,
    post_details,
    liked_posts_count,
    personal_like_status,
    user,
    time,
    user_id,
    profile_images,
    post_contents,
  } = post;

  const postMainId = post.id;
  const personalLikeUserId =
    personal_like_status !== null
      ? Number(personal_like_status?.user_id)
      : Number(0);
  const { first_name, last_name } = user || {};
  const [postLikeCount, setpostLikeCount] = useState(
    liked_posts_count && liked_posts_count
  );
  const postUserPictureArg = {
    profile_images: profile_images,
  };
  const contentLength = post_contents && post_contents.length;
  const lengthEqualOne = post_contents && contentLength === 1;
  const lengthEqualThree = post_contents && contentLength === 3;
  const lengthMoreThenFour = post_contents && contentLength > 4;

  const [isColored, setisColored] = useState("logoColorText");
  const [colorUpdate, setcolorUpdate] = useState(false);
  const [commentCountState, setcommentCountState] = useState(comments_count);
  const [clickShare, setClickShare] = useState(false);
  const [commentBoxShow, setcommentBoxShow] = useState(false);
  //---------liked users
  const [ListDropdownShow, setListDropdownShow] = useState(false);
  const [likedUserListPage, setlikedUserListPage] = useState(1);
  const [postLikedUserList, setpostLikedUserList] = useState([]);
  const [likedUserHasMore, setlikedUserHasMore] = useState(false);
  //--------------
  const [buttonRowVisible, setbuttonRowVisible] = useState(false);
  const [commentListShow, setcommentListShow] = useState(false);
  const [captionUrl, setCaptionUrl] = useState("");
  const [formData, setformData] = useState({
    comment: "",
  });
  const [editCommentBox, seteditCommentBox] = useState({
    commentId: "",
    post_id: "",
    comment: "",
  });
  const [commentReplyBox, setcommentReplyBox] = useState({
    post_id: "",
    comment_id: "",
    comment_reply: "",
  });
  let commentRowArg = {
    commentReplyBox: commentReplyBox,
    setcommentReplyBox: setcommentReplyBox,
  };
  const [loading, setloading] = useState(false);
  // comment
  const commentInitialPage = 1;
  const [commentCurrentPage, setcommentCurrentPage] =
    useState(commentInitialPage);
  const [commentsData, setcommentsData] = useState([]);
  //like
  const [likedUsersModalState, setlikedUsersModalstate] = useState(false);
  const closeLikedUsersModal = () => {
    setlikedUserListPage(1);
    setpostLikedUserList([]);
    setlikedUsersModalstate(false);
  };
  const showLikedUsersModal = () => {
    setlikedUsersModalstate(true);
  };
  const commentboxClick = () => {
    setcommentBoxShow(!commentBoxShow);
  };
  const [commentloading, setcommentloading] = useState(false);

  const commentListClick = () => {
    const arg = {
      PostId: postMainId,
      commentCurrentPage: commentInitialPage,
      commentsData: commentsData,
      setcommentsData: setcommentsData,
      setbuttonRowVisible: setbuttonRowVisible,
      previousDataReset: true,
      setcommentloading: setcommentloading,
    };
    commentShow(arg);
    setcommentListShow(true);
  };

  const loadMore = () => {
    setcommentCurrentPage((prev) => {
      return prev + 1;
    });
  };
  const commentRemoveFunc = (commentId) => {
    const arg = {
      commentId: commentId,
    };
    const commentShowArg = {
      PostId: postMainId,
      commentCurrentPage: commentCurrentPage,
      commentsData: commentsData,
      setcommentsData: setcommentsData,
      setbuttonRowVisible: setbuttonRowVisible,
      previousDataReset: true,
    };
    commentRemove(arg, commentShowArg);
    getPostCommentCount(postMainId, setcommentCountState);
  };
  const commentEditFunc = (commentId) => {
    // console.log("commentEditFunc")
    const arg = {
      commentId: commentId,
      seteditCommentBox: seteditCommentBox,
    };
    showcommentEdit(arg);
  };
  useEffect(() => {
    if (commentCurrentPage > 1) {
      const arg = {
        PostId: postMainId,
        commentCurrentPage: commentCurrentPage,
        commentsData: commentsData,
        setcommentsData: setcommentsData,
        setbuttonRowVisible: setbuttonRowVisible,
        previousDataReset: false,
      };
      commentShow(arg);
    }
  }, [commentCurrentPage]);

  const inputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...formData };
    newData[name] = value;
    setformData(newData);
  };

  const handleEmojiClick = (text) => {
    const newData = { ...formData };
    const prev_text = newData["comment"];
    newData["comment"] = prev_text ? prev_text + text.emoji : text.emoji;
    setformData(newData);
  };

  const commentReload = () => {
    setcommentListShow(true);
    const arg = {
      PostId: postMainId,
      commentCurrentPage: commentInitialPage,
      commentsData: commentsData,
      setcommentsData: setcommentsData,
      setbuttonRowVisible: setbuttonRowVisible,
      setcommentloading: setcommentloading,
      previousDataReset: true,
    };
    commentShow(arg);
  };

  const formateCommentBox = () => {
    setformData({ post_id: postMainId, comment: "" });
  };

  const commentSubmitCode = (formData) => {
    if (formData.comment && formData.comment.trim() !== "") {
      // const formDataStore = { ...formData };

      setloading(true);
      axios
        .post(`${commentStoreUrl + postMainId}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((res) => {
          if (res.data.status === 1) {
            commentReload();

            getPostCommentCount(postMainId, setcommentCountState);
            setloading(false);
            formateCommentBox();
          } else {
            setloading(false);

            ToastFunc("error", "something went wrong");
          }
        })
        .catch((err) => {
          setloading(false);

          ToastFunc("error", "something went wrong");
        });
    }
  };
  const onkeyPressSubmit = (event) => {
    if (event.type === "keypress") {
      if (event.key === "Enter" && event.shiftKey === false) {
        event.preventDefault();

        commentSubmitCode(formData);
      } else if (event.shiftKey && event.key === "Enter") {
      }
    } else {
      commentSubmitCode(formData);
    }
  };
  const getLikedUserListFunc = () => {
    const arg = {
      id: postMainId,
      currentPage: likedUserListPage,
      setHasMore: setlikedUserHasMore,
      likedUserListState: postLikedUserList,
      setpostLikedUserList: setpostLikedUserList,
      preDataFormate: false,
      likedUserListUrl: postLikeUserListUrl,
    };
    getLikedUserList(arg);
  };

  useEffect(() => {
    if (likedUserListPage > 1) {
      getLikedUserListFunc();
    }
  }, [likedUserListPage]);

  const showLikeListDropdown = (e) => {
    const arg = {
      id: postMainId,
      currentPage: likedUserListPage,
      setHasMore: setlikedUserHasMore,
      likedUserListState: postLikedUserList,
      setpostLikedUserList: setpostLikedUserList,
      preDataFormate: true,
      likedUserListUrl: postLikeUserListUrl,
    };
    getLikedUserList(arg);
    setListDropdownShow(!ListDropdownShow);
  };
  // const hideLikeListDropdown = e => {
  //   setListDropdownShow(false);
  // }
  const likedUserListArg = {
    showLikeListDropdown: showLikeListDropdown,
    postLikeCount: postLikeCount,
    ListDropdownShow: ListDropdownShow,
    likedUserListState: postLikedUserList,
    showLikedUsersModal: showLikedUsersModal,
  };
  const likedUserModalArg = {
    likedUsersModalState: likedUsersModalState,
    closeLikedUsersModal: closeLikedUsersModal,
    likedUserListState: postLikedUserList,
    currentPage: likedUserListPage,

    setcurrentPage: setlikedUserListPage,
    hasMore: likedUserHasMore,
  };

  const postLikeSubmitArg = {
    setisColored: setisColored,
    setcolorUpdate: setcolorUpdate,
    getLikeCount: getLikeCount,
    setpostLikeCount: setpostLikeCount,
  };
  const CommentextAreaArg = {
    inputHandleChange: inputHandleChange,
    onkeyPressSubmit: onkeyPressSubmit,
    placeHolder: "Write comment...",
    BorderAreaClass: "",
    fieldName: "comment",
    inputValue: formData.comment,
    commentloading: commentloading,
    handleEmojiClick,
  };
  const likeCommentShareArg = {
    personalLikeUserId: personalLikeUserId,
    isColored: isColored,
    colorUpdate: colorUpdate,
    postLikeSubmit: postLikeSubmit,
    postMainId: postMainId,
    postLikeSubmitArg: postLikeSubmitArg,
    postLikeCount: postLikeCount,
    likedUserListArg: likedUserListArg,
    commentboxClick: commentboxClick,
    commentListClick: commentListClick,
    commentCountState: commentCountState,
    comment_reply_count: comment_reply_count,
    setClickShare: setClickShare,
    clickShare: clickShare,
    commentloading: commentloading,
  };
  const [isGalleryActive, setIsGalleryActive] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState();
  const setIsGalleryActiveFnc = (setIndexNumber) => {
    setGalleryIndex(setIndexNumber);
    setIsGalleryActive(true);
  };
  const postActionsArg = {
    post_id: postMainId,
    user_id: noAction ? null : user_id,
    post,
  };
  const text_words = post_details?.split(" ") || [];

  const getTimeAgo = useMemo(() => timeAgo(post.created_at), [post.created_at]);

  useEffect(() => {
    _.forEach(text_words, (word) => {
      if (word.match(URL_REGEX)) {
        setCaptionUrl(word);
      }
    });
  }, []);

  return (
    <>
      <div
        tabIndex="0"
        className="box-shadow mb-3 singlePostSample  bg-footer rounded-3"
        ref={lastPostRef}
      >
        <div className="col-12 MiddlePartScrool  py-0 my-0">
          <div className="PostHeaderOption pt-3 px-3 mb-2">
            <div className="ProfileAndName">
              <PostUserPicture
                postUserPictureArg={postUserPictureArg}
                userName={`${first_name} ${last_name}`}
              />
              <div className="ms-2">
                <div className="d-flex flex-column align-items-start">
                  <h6
                    tabIndex="0"
                    aria-labelledby={`${first_name} ${last_name}`}
                    className="my-0 smallTextSize-sm namehoverEffect me-2"
                    onClick={() =>
                      navigate(`/life/public-life/${user_id}`, {
                        from: pathname,
                      })
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        navigate(`/life/public-life/${user_id}`, {
                          from: pathname,
                        });
                      }
                    }}
                  >
                    {first_name} {last_name}
                  </h6>
                  <img
                    src="https://res.cloudinary.com/yex/image/upload/v1669472215/UVIOM/uviom-verified_l3d95w.svg"
                    alt=""
                  />
                </div>
                <div className="smallTextSize-sm">
                  <small>{getTimeAgo}</small>
                </div>
              </div>
            </div>
            <PostActions postActionsArg={postActionsArg} />
          </div>
        </div>
        {post_contents?.length > 0 &&
          post_contents[0].content_type === "image" && (
            <>
              <div className="col-12 NewsPhotoDiv mt-2 position-relative">
                {post_contents.slice(0, 4).map((item, index) => (
                  <div
                    key={item.id}
                    className={`float-start
                   ${lengthEqualOne ? "oneImageCss" : "multiImageCss"}
                   ${lengthEqualThree && index === 0 ? "threeImageCss" : ""}
                   ${
                     lengthMoreThenFour && index === 3
                       ? "MoreThenFourImages"
                       : ""
                   }`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      // placeholderSrc="/Life/placeholder.jpg"
                      className={`img-fluid`}
                      src={`${temporaryImageUrl}/${item.source}${item.content_name}`}
                      alt={post_details}
                      onClick={() => setIsGalleryActiveFnc(index)}
                      width="100%"
                      height="100%"
                      threshold={500}
                    />

                    {/* button over 4no image  */}
                    {lengthMoreThenFour && index === 3 && (
                      <button
                        type="button"
                        className="btn btn-secondary  width-fit-content bseemorePhotoBtn rounded-0 absolute-center smallTextSize-sm"
                        onClick={() => setIsGalleryActiveFnc(index)}
                      >
                        See More {contentLength - 4} Photos
                      </button>
                    )}
                  </div>
                ))}
                <PhotoSlider
                  images={post_contents.map((item) => ({
                    src: `${temporaryImageUrl}/${item.source}${item.content_name}`,
                    key: item.id,
                  }))}
                  visible={isGalleryActive}
                  onClose={() => setIsGalleryActive(false)}
                  index={galleryIndex}
                  onIndexChange={setGalleryIndex}
                />
              </div>
            </>
          )}

        {post_contents?.length > 0 &&
          post_contents[0].content_type === "video" && (
            <div className="col-12 mt-2 position-relative">
              <FeedVideoPlayer
                thumbnail={videoThumbnailPath + post_contents[0]?.thumbnail}
                url={postVideoPath + post_contents[0]?.content_name}
              />
            </div>
          )}
        {post_contents?.length < 1 && captionUrl && (
          <LinkPreview url={captionUrl} />
        )}

        {post_details !== "null" ? (
          <div className="col-12 pt-2 px-3 text-align-justify ">
            <SeeMore setUrl={setCaptionUrl}>{post_details}</SeeMore>{" "}
          </div>
        ) : (
          ""
        )}

        {/* like share comment option  */}
        <div className="col-12  mt-3 mb-2 ">
          <LikeCommentShare data={likeCommentShareArg} />

          <div className="searchDropdown  mx-auto firstStageCommentDiv">
            {commentBoxShow && (
              <div className="MessageInput ">
                <div className="container-fluid px-2">
                  <CommentextArea data={CommentextAreaArg} />
                </div>
              </div>
            )}
            {commentListShow && (
              <>
                {commentsData &&
                  commentsData.length > 0 &&
                  commentsData.map((item) => (
                    <div className="MessageInput " key={`${item.id}`}>
                      <CommentRow
                        item={item}
                        authUser={authUser}
                        editCommentBox={editCommentBox}
                        seteditCommentBox={seteditCommentBox}
                        commentRemoveFunc={commentRemoveFunc}
                        commentEditFunc={commentEditFunc}
                        commentReload={commentReload}
                        commentRowArg={commentRowArg}
                      />
                    </div>
                  ))}
                {buttonRowVisible && (
                  <div className="mediumTextSize pointer" onClick={loadMore}>
                    See More...
                  </div>
                )}
              </>
            )}
          </div>
          <LikedUserModal data={likedUserModalArg} />
        </div>
      </div>
      {/* ----------end---------- */}
    </>
  );
};

export default memo(NewsFeedPost);
