import HistoryCard from "../HistoryCard";

function ReceivedHistory() {
  return (
    <div>
      <h3 className="heading-3">Received History</h3>

      {/*  */}
      {Array.from({ length: 3 }).map((_, index) => (
        <HistoryCard key={index} />
      ))}
    </div>
  );
}
export default ReceivedHistory;
