import React from "react";

const MessageFileInput = ({ children, className, handleChange }) => {
  return (
    <div className={className} style={{ cursor: "pointer" }}>
      <input
        type="file"
        accept="image/*"
        id="file-input-driving"
        multiple
        className="file-input"
        onChange={handleChange}
      />
      <label
        htmlFor="file-input-driving"
        className="jobApplyInput p-0"
        id="jobApplyFileInput"
      >
        <span className="file-text-btn chat__content-btn">{children}</span>
      </label>
    </div>
  );
};

export default MessageFileInput;
