import React from 'react';
import "./addresscard.css";

const AddressCard = ({ type, data, handleRemoveAddress = () => { } }) => {
    const { shipping_name, shipping_email, shipping_phone, shipping_address1, shipping_address2, billing_name, billing_email, billing_phone, billing_address1, billing_address2 } = data
    return (
        <div className='address-card px-2 pt-3 '>
            <div className='address-card-item'>
                <p>Name</p>
                <p>{shipping_name} {billing_name}</p>
            </div>
            <div className='address-card-item'>
                <p>Email</p>
                <p>{shipping_email} {billing_email}</p>
            </div>
            <div className='address-card-item'>
                <p>Phone</p>
                <p>{shipping_phone} {billing_phone}</p>
            </div>
            <div className='address-card-item'>
                <p>Address 1</p>
                <p>{shipping_address1} {billing_address1}</p>
            </div>
            <div className='address-card-item'>
                <p>Address 2</p>
                <p>{shipping_address2} {billing_address2}</p>
            </div>

            {/* <div className='text-end mb-2'>
                <button type='button' onClick={() => handleRemoveAddress(id, type)} className="eco-btn  text-danger">Remove Address</button>
            </div> */}
        </div>
    );
};
export default AddressCard;