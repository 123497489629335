import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faHeart } from "@fortawesome/free-solid-svg-icons";
import useAuth from "src/hooks/useAuth";
import { isEmpty } from "lodash";
import { updateRelationInfoUrl } from "src/Projects/Life/Common/CommonUrl";
import { SingleItem } from "./SingleItem";
import { StoringInputs } from "./StoringInputs";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";

export const Relationship = ({ info, getData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const setIsEditableFalse = () => setIsEditable(false);
  const setIsEditableTrue = () => setIsEditable(true);

  const [formData, setformData] = useState({
    relationship_status: "",
    privacy: "",
  });
  useEffect(() => {
    if (!isEmpty(info.relationship)) {
      const parseData = JSON.parse(info && info.relationship.relationship);
      setformData({
        relationship_status:
          parseData && parseData.relationship ? parseData.relationship : "",
        privacy: parseData && parseData.privacy ? parseData.privacy : "",
      });
    }
  }, [info]);
  const inputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...formData };
    newData["privacy"] = 1;
    newData[name] = value;
    setformData(newData);
  };
  const storingInputsArg = {
    isLoading: isLoading,
    setIsLoading: setIsLoading,
    setIsEditableFalse: setIsEditableFalse,
    dataReload: getData,
    componentMainUrl: updateRelationInfoUrl,
    formData: formData,
    inputHandleChange: inputHandleChange,
  };

  if (isLoading) {
    return <DottedCircleSpinner />;
  }
  return (
    <div className="mb-4">
      <Col xs={12} sm={12} md={12} lg={12} className="aboutBox mt-1 mb-4 ">
        <div className="d-flex border-bottom pb-2 border-white">
          <div>
            <h6> Relationship</h6>
            <div className="d-flex pointer" onClick={setIsEditableTrue}>
              <div className="me-2">
                <FontAwesomeIcon icon={faPlusCircle} />
              </div>
              <div className=""> Update Relationship</div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          {isEditable ? (
            <>
              <StoringInputs storingInputsArg={storingInputsArg} />
            </>
          ) : (
            <>
              <SingleItem item={formData} />
            </>
          )}
        </div>
      </Col>
    </div>
  );
};
