import { Button, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import truck from "./../../assets/express-truck.svg";

import "./payment.css";

function Payment() {
  const { pathname:url } = useLocation();
  const dynamicPath = url.split("/")[1];

  return (
    <>
      <div className="express__payment">
        <div className="text-center">
          <div className="d-inline-flex align-items-center gap-3 mb-3 mb-md-5">
            <img className="img-fluid" src={truck} alt="" />
            <h6 className="m-0 text-start">
              UVIOM
              <br />
              EXPRESS
            </h6>
          </div>
        </div>

        <div className="express__payment__box ">
          <div className="express__payment__box__left">
            <p className="mb-2">Same Day </p>
            <p className="mb-2">06/09/2022</p>
            <p className="mb-2">Time</p>
            <p className="mb-2">3.00PM</p>
            <p className="mb-2">Bill</p>
            <p className="mb-2">$30.00</p>
          </div>
          <div className="express__payment__box__divider" />
          <div className="express__payment__box__right">
            <Form.Group>
              <Form.Select>
                <option value="">UVIOM Wallet</option>
                <option value="">Bkash</option>
                <option value="">Rocket</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Control type="password" className="input-reset" placeholder="Password" />
            </Form.Group>
            <div className="text-center mt-4">
              <Button
                as={Link}
                to={`/${dynamicPath}/delivery/confirm`}
                className="btn__ride px-4"
              >
                Pay Now
              </Button>
            </div>
          </div>
        </div>
        <div className="py-3 py-md-5" />
      </div>
    </>
  );
}
export default Payment;
