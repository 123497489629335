import { Container } from "react-bootstrap";


import "./freelanceClient.css";

function FreelanceClient() {
  return (
    <Container>
      <div className="freelanceClient">
        <p className="m-0">Regular Client : </p>
        <ul className="freelanceClient__logo">
          <li>
            <img src="https://i.ibb.co/jk1vstQ/brand1.png" alt="" />
          </li>
          <li>
            <img src="https://i.ibb.co/tb8p4wp/brand2.png" alt="" />
          </li>
          <li>
            <img src="https://i.ibb.co/HFnsBXk/brand3.png" alt="" />
          </li>
          <li>
            <img src="https://i.ibb.co/DbMJQzv/brand4.png" alt="" />
          </li>
        </ul>
      </div>
    </Container>
  );
}
export default FreelanceClient;
