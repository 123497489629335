import React from 'react';
import { Placeholder } from 'react-bootstrap';

const NewsFeedSkeleton = () => {
    return (
        <div className="secondary-bg shadow-lg my-4 p-3 d-flex flex-column justify-content-between rounded-2"  style={containerStyles}>
            <span className="bg-transparent d-flex gap-3 align-items-center w-100 ">
                <Placeholder animation='wave'  bg="secondary" style={avatarStyles}/>
                
                <span >
                <Placeholder animation='wave'  xs="3" size='sm' bg='secondary' style={{width:"100px", ...barStyles }} />
                <Placeholder animation='wave' xs="3" size="sm" bg='secondary'  style={{width:"70px", ...barStyles}}/>
               </span>
            </span>

            <span className='d-flex justify-content-evenly mb-3'>
            <Placeholder animation='wave' xs="2" size='sm' bg='secondary'  className="rounded-2"/>
            <Placeholder animation='wave' xs="2" size="sm" bg='secondary' className="rounded-2" />
            <Placeholder animation='wave'  xs="2" size="sm" bg='secondary' className="rounded-2" />

            </span>
            
        </div>
    );
};

export default NewsFeedSkeleton;

const containerStyles = {
    height: "270px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
}

const avatarStyles = {
    height: "50px",
    width: "50px",
    borderRadius:"50%",
}

const barStyles = {
    display: "block", 
    borderRadius: "5px",
    margin: "10px 0",
    
    
}