import React, { useEffect } from "react";
import "./Privecy.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Navigation from "../LoginPage/Shared/Navigation";
import { Container } from "react-bootstrap";

const Privecy = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <Container fluid className="p-0 m-0" >
        <Navigation />
      <Container>
      <div className="Privecy mt-5" style={{overflowX:"hidden"}}>
        <h1 className="text-warning">PRIVACY NOTICE </h1>
        <h5 className="text-warning">Last updated December 15, 2021</h5>
        <hr />
        <div  data-aos="fade-left">
          <p>
            Thank you for choosing to be part of our community at UVIOM Inc.
            ("Company," "we," "us," or "our"). We are committed to protecting
            your personal information and your right to privacy. If you have any
            questions or concerns about this privacy notice or our practices
            with regard to your personal information, please contact us at
            uviominc@yahoo.com. This privacy notice describes how we might use
            your information if you: Visit our website Download and use our
            mobile application Engage with us in other related ways ― including
            any sales, marketing, or events In this privacy notice, if we refer
            to: "Website," we are referring to any website of ours that
            references or links to this policy "App," we are referring to any
            application of ours that references or links to this policy,
            including any listed above "Services," we are referring to our
            Website, App, and other related services, including any sales,
            marketing, or events The purpose of this privacy notice is to
            explain to you in the clearest way possible what information we
            collect, how we use it, and what rights you have in relation to it.
            If there are any terms in this privacy notice that you do not agree
            with, please discontinue use of our Services immediately. Please
            read this privacy notice carefully, as it will help you understand
            what we do with the information that we collect.
          </p>
        </div>
        <hr />
        <ol>
          <li>
            <div data-aos="fade-right">
              WHAT INFORMATION DO WE COLLECT? Personal information you disclose
              to us In Short: We collect personal information that you provide
              to us. We collect personal information that you voluntarily
              provide to us when you register on the Services, express an
              interest in obtaining information about us or our products and
              Services, when you participate in activities on the Services (such
              as by posting messages in our online forums or entering
              competitions, contests or giveaways) or otherwise when you contact
              us. The personal information that we collect depends on the
              context of your interactions with us and the Services, the choices
              you make and the products and features you use. The personal
              information we collect may include the following: Personal
              Information Provided by You. We collect names; phone numbers;
              email addresses; mailing addresses; job titles; usernames;
              passwords; and other similar information. Social Media Login Data.
              We may provide you with the option to register with us using your
              existing social media account details, like your Facebook, Twitter
              or other social media account. If you choose to register in this
              way, we will collect the information described in the section
              called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below. All personal
              information that you provide to us must be true, complete and
              accurate, and you must notify us of any changes to such personal
              information. Information collected through our App In Short: We
              collect information regarding your geolocation, mobile device,
              push notifications, when you use our App. If you use our App, we
              also collect the following information: Geolocation Information.
              We may request access or permission to and track location-based
              information from your mobile device, either continuously or while
              you are using our App, to provide certain location-based services.
              If you wish to change our access or permissions, you may do so in
              your device's settings. Mobile Device Access. We may request
              access or permission to certain features from your mobile device,
              including your mobile device's camera, microphone, bluetooth,
              storage, and other features. If you wish to change our access or
              permissions, you may do so in your device's settings. Push
              Notifications. We may request to send you push notifications
              regarding your account or certain features of the App. If you wish
              to opt-out from receiving these types of communications, you may
              turn them off in your device's settings. This information is
              primarily needed to maintain the security and operation of our
              App, for troubleshooting and for our internal analytics and
              reporting purposes.
            </div>
          </li>

          <li>
            <div  data-aos="fade-left">
              HOW DO WE USE YOUR INFORMATION? In Short: We process your
              information for purposes based on legitimate business interests,
              the fulfillment of our contract with you, compliance with our
              legal obligations, and/or your consent. We use personal
              information collected via our Services for a variety of business
              purposes described below. We process your personal information for
              these purposes in reliance on our legitimate business interests,
              in order to enter into or perform a contract with you, with your
              consent, and/or for compliance with our legal obligations. We
              indicate the specific processing grounds we rely on next to each
              purpose listed below. We use the information we collect or
              receive: To facilitate account creation and logon process. If you
              choose to link your account with us to a third-party account (such
              as your Google or Facebook account), we use the information you
              allowed us to collect from those third parties to facilitate
              account creation and logon process for the performance of the
              contract. See the section below headed "HOW DO WE HANDLE YOUR
              SOCIAL LOGINS?" for further information. To post testimonials. We
              post testimonials on our Services that may contain personal
              information. Prior to posting a testimonial, we will obtain your
              consent to use your name and the content of the testimonial. If
              you wish to update, or delete your testimonial, please contact us
              at support@uviom.com and be sure to include your name, testimonial
              location, and contact information. Request feedback. We may use
              your information to request feedback and to contact you about your
              use of our Services. To enable user-to-user communications. We may
              use your information in order to enable user-to-user
              communications with each user's consent. To manage user accounts.
              We may use your information for the purposes of managing our
              account and keeping it in working order. To send administrative
              information to you. We may use your personal information to send
              you product, service and new feature information and/or
              information about changes to our terms, conditions, and policies.
              To protect our Services. We may use your information as part of
              our efforts to keep our Services safe and secure (for example, for
              fraud monitoring and prevention). To enforce our terms, conditions
              and policies for business purposes, to comply with legal and
              regulatory requirements or in connection with our contract. To
              respond to legal requests and prevent harm. If we receive a
              subpoena or other legal request, we may need to inspect the data
              we hold to determine how to respond. Fulfill and manage your
              orders. We may use your information to fulfill and manage your
              orders, payments, returns, and exchanges made through the
              Services. Administer prize draws and competitions. We may use your
              information to administer prize draws and competitions when you
              elect to participate in our competitions. To deliver and
              facilitate delivery of services to the user. We may use your
              information to provide you with the requested service. To respond
              to user inquiries/offer support to users. We may use your
              information to respond to your inquiries and solve any potential
              issues you might have with the use of our Services. To send you
              marketing and promotional communications. We and/or our
              third-party marketing partners may use the personal information
              you send to us for our marketing purposes, if this is in
              accordance with your marketing preferences. For example, when
              expressing an interest in obtaining information about us or our
              Services, subscribing to marketing or otherwise contacting us, we
              will collect personal information from you. You can opt-out of our
              marketing emails at any time (see the "WHAT ARE YOUR PRIVACY
              RIGHTS?" below). Deliver targeted advertising to you. We may use
              your information to develop and display personalized content and
              advertising (and work with third parties who do so) tailored to
              your interests and/or location and to measure its effectiveness.
              For other business purposes. We may use your information for other
              business purposes, such as data analysis, identifying usage
              trends, determining the effectiveness of our promotional campaigns
              and to evaluate and improve our Services, products, marketing and
              your experience. We may use and store this information in
              aggregated and anonymized form so that it is not associated with
              individual end users and does not include personal information.
            </div>
          </li>

          <li>
            <div data-aos="fade-right">
              WILL YOUR INFORMATION BE SHARED WITH ANYONE? In Short: We only
              share information with your consent, to comply with laws, to
              provide you with services, to protect your rights, or to fulfill
              business obligations. We may process or share your data that we
              hold based on the following legal basis: Consent: We may process
              your data if you have given us specific consent to use your
              personal information for a specific purpose. Legitimate Interests:
              We may process your data when it is reasonably necessary to
              achieve our legitimate business interests. Performance of a
              Contract: Where we have entered into a contract with you, we may
              process your personal information to fulfill the terms of our
              contract. Legal Obligations: We may disclose your information
              where we are legally required to do so in order to comply with
              applicable law, governmental requests, a judicial proceeding,
              court order, or legal process, such as in response to a court
              order or a subpoena (including in response to public authorities
              to meet national security or law enforcement requirements). Vital
              Interests: We may disclose your information where we believe it is
              necessary to investigate, prevent, or take action regarding
              potential violations of our policies, suspected fraud, situations
              involving potential threats to the safety of any person and
              illegal activities, or as evidence in litigation in which we are
              involved. More specifically, we may need to process your data or
              share your personal information in the following situations:
              Business Transfers. We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business to another company. Google Maps Platform APIs. We may
              share your information with certain Google Maps Platform APIs
              (e.g., Google Maps API, Place API). To find out more about
              Google’s Privacy Policy, please refer to this link. Other Users.
              When you share personal information (for example, by posting
              comments, contributions or other content to the Services) or
              otherwise interact with public areas of the Services, such
              personal information may be viewed by all users and may be
              publicly made available outside the Services in perpetuity. If you
              interact with other users of our Services and register for our
              Services through a social network (such as Facebook), your
              contacts on the social network will see your name, profile photo,
              and descriptions of your activity. Similarly, other users will be
              able to view descriptions of your activity, communicate with you
              within our Services, and view your profile.
            </div>
          </li>
          <li>
            <div  data-aos="fade-left">
              DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? In Short: We
              may use cookies and other tracking technologies to collect and
              store your information. We may use cookies and similar tracking
              technologies (like web beacons and pixels) to access or store
              information. Specific information about how we use such
              technologies and how you can refuse certain cookies is set out in
              our Cookie Notice.
            </div>
          </li>
          <li>
            <div data-aos="fade-right">
              {" "}
              HOW DO WE HANDLE YOUR SOCIAL LOGINS? In Short: If you choose to
              register or log in to our services using a social media account,
              we may have access to certain information about you. Our Services
              offers you the ability to register and login using your
              third-party social media account details (like your Facebook or
              Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, profile picture as well as
              other information you choose to make public on such social media
              platform. We will use the information we receive only for the
              purposes that are described in this privacy notice or that are
              otherwise made clear to you on the relevant Services. Please note
              that we do not control, and are not responsible for, other uses of
              your personal information by your third-party social media
              provider. We recommend that you review their privacy notice to
              understand how they collect, use and share your personal
              information, and how you can set your privacy preferences on their
              sites and apps.
            </div>
          </li>
          <li>
            <div  data-aos="fade-left">
              WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES? In Short: We are not
              responsible for the safety of any information that you share with
              third-party providers who advertise, but are not affiliated with,
              our Website. The Services may contain advertisements from third
              parties that are not affiliated with us and which may link to
              other websites, online services or mobile applications. We cannot
              guarantee the safety and privacy of data you provide to any third
              parties. Any data collected by third parties is not covered by
              this privacy notice. We are not responsible for the content or
              privacy and security practices and policies of any third parties,
              including other websites, services or applications that may be
              linked to or from the Services. You should review the policies of
              such third parties and contact them directly to respond to your
              questions.
            </div>
          </li>
          <li>
            <div data-aos="fade-right">
              HOW LONG DO WE KEEP YOUR INFORMATION? In Short: We keep your
              information for as long as necessary to fulfill the purposes
              outlined in this privacy notice unless otherwise required by law.
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than six (6) months past the start of the idle period of
              the user's account. When we have no ongoing legitimate business
              need to process your personal information, we will either delete
              or anonymize such information, or, if this is not possible (for
              example, because your personal information has been stored in
              backup archives), then we will securely store your personal
              information and isolate it from any further processing until
              deletion is possible.
            </div>
          </li>
          <li>
            <div  data-aos="fade-left">
              HOW DO WE KEEP YOUR INFORMATION SAFE? In Short: We aim to protect
              your personal information through a system of organizational and
              technical security measures. We have implemented appropriate
              technical and organizational security measures designed to protect
              the security of any personal information we process. However,
              despite our safeguards and efforts to secure your information, no
              electronic transmission over the Internet or information storage
              technology can be guaranteed to be 100% secure, so we cannot
              promise or guarantee that hackers, cybercriminals, or other
              unauthorized third parties will not be able to defeat our
              security, and improperly collect, access, steal, or modify your
              information. Although we will do our best to protect your personal
              information, transmission of personal information to and from our
              Services is at your own risk. You should only access the Services
              within a secure environment.
            </div>
          </li>
          <li>
            <div data-aos="fade-right">
              WHAT ARE YOUR PRIVACY RIGHTS? In Short: You may review, change, or
              terminate your account at any time. If you are a resident in the
              EEA or UK and you believe we are unlawfully processing your
              personal information, you also have the right to complain to your
              local data protection supervisory authority. You can find their
              contact details here:
              https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
              If you are a resident in Switzerland, the contact details for the
              data protection authorities are available here:
              https://www.edoeb.admin.ch/edoeb/en/home.html. If you have
              questions or comments about your privacy rights, you may email us
              at support@uviom.com. Account Information If you would at any time
              like to review or change the information in your account or
              terminate your account, you can: Log in to your account settings
              and update your user account. Contact us using the contact
              information provided. Upon your request to terminate your account,
              we will deactivate or delete your account and information from our
              active databases. However, we may retain some information in our
              files to prevent fraud, troubleshoot problems, assist with any
              investigations, enforce our Terms of Use and/or comply with
              applicable legal requirements. Cookies and similar technologies:
              Most Web browsers are set to accept cookies by default. If you
              prefer, you can usually choose to set your browser to remove
              cookies and to reject cookies. If you choose to remove cookies or
              reject cookies, this could affect certain features or services of
              our Services. To opt-out of interest-based advertising by
              advertisers on our Services visit
              http://www.aboutads.info/choices/. Opting out of email marketing:
              You can unsubscribe from our marketing email list at any time by
              clicking on the unsubscribe link in the emails that we send or by
              contacting us using the details provided below. You will then be
              removed from the marketing email list — however, we may still
              communicate with you, for example to send you service-related
              emails that are necessary for the administration and use of your
              account, to respond to service requests, or for other
              non-marketing purposes. To otherwise opt-out, you may: Access your
              account settings and update your preferences. Contact us using the
              contact information provided.
            </div>
          </li>
          <li>
            <div  data-aos="fade-left">
              CONTROLS FOR DO-NOT-TRACK FEATURES Most web browsers and some
              mobile operating systems and mobile applications include a
              Do-Not-Track ("DNT") feature or setting you can activate to signal
              your privacy preference not to have data about your online
              browsing activities monitored and collected. At this stage no
              uniform technology standard for recognizing and implementing DNT
              signals has been finalized. As such, we do not currently respond
              to DNT browser signals or any other mechanism that automatically
              communicates your choice not to be tracked online. If a standard
              for online tracking is adopted that we must follow in the future,
              we will inform you about that practice in a revised version of
              this privacy notice
            </div>
          </li>
          <li>
            <div data-aos="fade-right">
              DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS? In Short:
              Yes, if you are a resident of California, you are granted specific
              rights regarding access to your personal information. California
              Civil Code Section 1798.83, also known as the "Shine The Light"
              law, permits our users who are California residents to request and
              obtain from us, once a year and free of charge, information about
              categories of personal information (if any) we disclosed to third
              parties for direct marketing purposes and the names and addresses
              of all third parties with which we shared personal information in
              the immediately preceding calendar year. If you are a California
              resident and would like to make such a request, please submit your
              request in writing to us using the contact information provided
              below. If you are under 18 years of age, reside in California, and
              have a registered account with a Service, you have the right to
              request removal of unwanted data that you publicly post on the
              Services. To request removal of such data, please contact us using
              the contact information provided below, and include the email
              address associated with your account and a statement that you
              reside in California. We will make sure the data is not publicly
              displayed on the Services, but please be aware that the data may
              not be completely or comprehensively removed from all our systems
              (e.g. backups, etc.).
            </div>
          </li>
          <li>
            <div  data-aos="fade-left">
              DO WE MAKE UPDATES TO THIS NOTICE? In Short: Yes, we will update
              this notice as necessary to stay compliant with relevant laws. We
              may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </div>
          </li>
          <li>
            <div data-aos="fade-right">
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE? If you have questions or
              comments about this notice, you may email us at support@uviom.com
              or by post to: UVIOM Inc. 2064 Powell Avenue APT B Bronx, NY 10472
              United States
            </div>
          </li>
          <li>
            <div  data-aos="fade-left">
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU? Based on the applicable laws of your country, you may have
              the right to request access to the personal information we collect
              from you, change that information, or delete it in some
              circumstances. To request to review, update, or delete your
              personal information, please submit a request form by clicking
              here. This privacy policy was created using Termly's Privacy
              Policy Generator.
            </div>
          </li>
        </ol>
      </div>
      </Container>
    </Container>
  );
};

export default Privecy;
