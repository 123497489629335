import { Routes, Route, Navigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";

import LeftSidebar from "../components/LeftSidebar";
import Messages from "../components/Messages";
import Wallet from "../components/Wallet";
import PreviousWorks from "../components/PreviousWorks";
import WorkPackage from "./../components/WorkPackage";
import Orders from "./../components/Orders";
import ProfileInfo from "../components/ProfileInfo";
import Notifications from "../components/Notifications";
import AtaGlance from "../components/Common/AtaGlance";
import AddWorkPackage from "../components/AddWorkPackage";
import PreviousWorkDetails from "../components/PreviousWorkDetails";

const ataGlance = [
  { title: "Complete Project", sub: "300+" },
  { title: "Total Earning", sub: "$1500.00" },
  { title: "Pending Balance", sub: "$302.00" },
  { title: "Available Balance", sub: "$150.00" },
];

function FreelancerProfile() {
  return (
    <div id="wrapper">
      <Container>
        <div className="my-4" />
        <Row>
          <Col md={4} lg={3}>
            <LeftSidebar
              userImage="https://i.ibb.co/DCt1HtT/person2.jpg"
              userName="Enamul Hossen"
              userSub="UI/UX Designer"
            />
          </Col>
          <Col md={8} lg={9}>
            <AtaGlance stats={ataGlance} />
            <FreelanceRoutes />
          </Col>
        </Row>
        <div className="my-4" />
      </Container>
    </div>
  );
}
export default FreelancerProfile;

const FreelanceRoutes = () => {
  return (
    <Routes>
      <Route path={`/messages`} element={<Messages />} />
      <Route path={`/wallet/*`} element={<Wallet />} />
      <Route path={`/profile-info`} element={<ProfileInfo />} />
      <Route path={`/previouswork/work1`} element={<PreviousWorkDetails />} />
      <Route path={`/previouswork`} element={<PreviousWorks />} />
      <Route path={`/workpackage/package`} element={<WorkPackage />} />
      <Route path={`/workpackage`} element={<AddWorkPackage />} />
      <Route path={`/orders/*`} element={<Orders />} />
      <Route path={`/notifications`} element={<Notifications />} />
      <Route path="*" element={<Navigate to="messages" replace />} />
    </Routes>
  );
};
