import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import styled from "styled-components";
import useAuth from "src/hooks/useAuth";
import VideoPlayer from "src/Common/VideoPlayer/VideoPlayer";
import ScrollToTop from "src/Common/ScrollToTop";
import MetaDetails from "./MetaDetails";
import { CommentBox } from "./CommentBox";
import SuggestedVideo from "./SuggestedVideo";
import {
  videoThumbnailImg,
  channelLogoImg,
  videoPlayUrl,
} from "src/Projects/View/common/view_commonUrl";
import {
  destroyCurrentVideoComments,
  getCurrentVideo,
} from "src/features/view/viewDataSlice";
import VideoPlaySkeleton from "./../../common/VideoPlaySkeleton";
import VideoComments from "./VideoComments";
import { Dropdown } from "react-bootstrap";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faFlag, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDeleteVideoPostMutation } from "src/app/services/viewApi/viewPostApi";
import EditVideoDetailsModal from "../../Components/EditVideoDetailsModal";
import ReportVideoModal from "../../Components/ReportVideoModal";

import "./videoPlay.css";

export const VideoPlay = () => {
  const { authUser } = useAuth();
  const [deleteVideo] = useDeleteVideoPostMutation();
  const dispatch = useDispatch();

  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showVideoDetailsModal, setShowVideoDetailsModal] = useState(false);
  const [showVideoReportModal, setShowVideoReportModal] = useState(false);

  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dynamicPath = pathname.split("/")[1];

  const { currentVideo, isLoading } = useSelector((store) => store.viewData);

  useEffect(() => {
    dispatch(getCurrentVideo(params.id));

    return () => dispatch(destroyCurrentVideoComments());
  }, [dispatch, params.id]);

  const handleVideoDelete = () => {
    deleteVideo(currentVideo.id);
    navigate(-1);
  };

  if (isLoading || _.isEmpty(currentVideo)) {
    return <VideoPlaySkeleton />;
  }

  return (
    <>
      <ScrollToTop />
      {showVideoDetailsModal && (
        <EditVideoDetailsModal
          show={showVideoDetailsModal}
          handleClose={() => setShowVideoDetailsModal(false)}
          videoId={currentVideo.id}
        />
      )}

      {showVideoReportModal && (
        <ReportVideoModal
          show={showVideoReportModal}
          handleClose={() => setShowVideoReportModal(false)}
          videoId={currentVideo.id}
        />
      )}

      <div className="container-fluid mt-2 mt-sm-3 WatchVideoContainer VideoPlay px-0">
        <PlayerWrapper>
          <VideoPlayer
            // autoPlay={true}
            url={videoPlayUrl + currentVideo.video}
            thumbnail={encodeURI(videoThumbnailImg + currentVideo.thumbnail)}
          />
        </PlayerWrapper>

        <MetaDetails
          toggleCommentBox={() => setShowCommentBox(true)}
          userId={currentVideo.user_id}
          videoId={currentVideo.id}
          title={currentVideo.title}
          desc={currentVideo.description}
          createdAt={currentVideo.created_at}
          totalComment={currentVideo.comments_count}
          totalLike={currentVideo.liked_videos_count}
          totalView={currentVideo.video_views_count}
          isSaved={currentVideo.personal_save_status_count}
          isLiked={currentVideo.personal_like_status_count}
        />
        <div className="row ">
          <div className="col-sm-12 my-2">
            <div className="d-flex mb-2 justify-content-between">
              <Link to={`/${dynamicPath}/channel/${currentVideo.user_id}`}>
                <div className="d-flex">
                  <img
                    className="img-fluid rounded-circle chennelProfileImg"
                    src={
                      currentVideo.channel_profile_section.photo
                        ? channelLogoImg +
                          currentVideo.channel_profile_section.photo
                        : "https://i.ibb.co/MprdZS6/user.png"
                    }
                    alt=""
                  />
                  <div>
                    <div>
                      {currentVideo.channel_profile_section.channel_name}
                    </div>
                    <div className="smallTextSize view-yellow">
                      {
                        currentVideo.channel_profile_section.channel_category
                          .name
                      }
                    </div>
                  </div>
                </div>
              </Link>
              <div className="d-flex justify-content-end">
                <Dropdown>
                  <Dropdown.Toggle
                    aria-label="toggle button"
                    className="ToggleDesignClear"
                  >
                    <ThreeDots className="topNavIcon" size="15" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {parseInt(currentVideo.user_id) === authUser.id ? (
                      <>
                        <Dropdown.Item
                          onClick={() => setShowVideoDetailsModal(true)}
                        >
                          <FontAwesomeIcon icon={faEdit} /> Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleVideoDelete}>
                          <FontAwesomeIcon icon={faTrash} /> Delete
                        </Dropdown.Item>
                      </>
                    ) : (
                      <Dropdown.Item
                        onClick={() => setShowVideoReportModal(true)}
                        as="button"
                      >
                        <FontAwesomeIcon icon={faFlag} /> Report Video
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        {showCommentBox && (
          <>
            <CommentBox id={currentVideo.id} onSubmit={() => {}} />
            <VideoComments videoId={currentVideo.id} />
          </>
        )}
        <SuggestedVideo
          currentCategoryId={currentVideo.video_category_id}
          currentVideoId={currentVideo.id}
        />
      </div>
    </>
  );
};

const PlayerWrapper = styled.div`
  height: 25rem;

  @media (min-width: 95em) {
    height: calc((100vw - 240px) / 3.5 - 1px);
  }

  /* 768px */
  @media (max-width: 48em) {
    height: initial;
    /* position: sticky; */
    top: 4rem;
    z-index: 3;
  }
`;
