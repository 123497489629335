import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const InputTextAreaWithLabel = ({
  value = "",
  name = "",
  label = "",
  handler = () => {},
  required = false,
  rows = "4",
  checkoutInput = false,
  className = ["", ""],
}) => {
  return (
    <Form.Group
      as={Row}
      className={`mb-3 align-items-start input-textarea-box ${
        checkoutInput && "mx-md-1"
      }`}
      controlId=""
    >
      <Form.Label
        className={`d-none d-md-block InputWithLabelLabel ${className[1]} ${
          checkoutInput && "text-start"
        }`}
        column
        sm="3"
      >
        {label}
      </Form.Label>
      <Col md="9" className="ms-0 ">
        <Form.Label className={"d-block d-md-none"}>{label}</Form.Label>
        <Form.Control
          rows={rows}
          required={required}
          as="textarea"
          name={name}
          value={value}
          onChange={handler}
          className={`InputWithLabelInput ms-0 ${className[0]} ${
            checkoutInput && "checkout-input"
          }`}
          type="text"
          placeholder="Write Here..."
        />
      </Col>
    </Form.Group>
  );
};

export default InputTextAreaWithLabel;
