import React from "react";

const SaveAndCancelBtnGroup = ({
  cancelBtnHander = () => {},
  saveBtnHandeler = () => {},
  cancelBtnName = "",
  saveBtnName = "",
}) => {
  return (
    <div className="py-1 mainProfileBtnDiv">
      <button
        type="button"
        onClick={cancelBtnHander}
        name={cancelBtnName}
        className="me-3 send-code-btn py-1"
      >
        Cancel
      </button>
      <button
        onClick={saveBtnHandeler}
        name={saveBtnName}
        type="button"
        className="send-code-btn py-1 cart-confirm-btn "
      >
        save
      </button>
    </div>
  );
};

export default SaveAndCancelBtnGroup;
