import React, { useState } from "react";
import {
  faComments,
  faEye,
  faShareSquare,
  faThumbsUp,
} from "@fortawesome/free-regular-svg-icons";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { formateDate } from "src/utilities/utilities";
import styled from "styled-components";
import {
  useUpdateVideoLikeStatusMutation,
  useUpdateSaveStatusMutation,
} from "src/app/services/viewApi/viewPostApi";
import ReadMore from "src/Common/ReadMore";

export const MetaDetails = ({
  title,
  userId,
  desc,
  totalComment,
  totalLike,
  totalView,
  createdAt,
  isLiked,
  isSaved,
  videoId,
  toggleCommentBox,
}) => {
  const [updateLikeStatus] = useUpdateVideoLikeStatusMutation();
  const [updateSaveStatus] = useUpdateSaveStatusMutation();

  return (
    <Wrapper>
      <div className="mt-3">
        <h6 className="fw-500">{title}</h6>
      </div>
      <div>
        <div className="fw-500">
          <FontAwesomeIcon icon={faEye} className="mx-1" />
          {totalView} Views &nbsp; {formateDate(createdAt)}
        </div>
        <div className="description">
          <ReadMore>{desc}</ReadMore>
        </div>
      </div>

      <ActinButtons>
        <button
          onClick={() => updateLikeStatus(videoId)}
          className={`${!!parseInt(isLiked) ? "active" : ""} btn__action`}
        >
          <FontAwesomeIcon icon={faThumbsUp} className="mx-1" />
          {totalLike} {!!parseInt(isLiked) ? "Liked" : "Like"}
        </button>

        <button onClick={toggleCommentBox} className="btn__action">
          <FontAwesomeIcon icon={faComments} className="mx-1" />
          {totalComment} Comment
        </button>
        <button
          onClick={() => updateSaveStatus(videoId)}
          className={`${parseInt(isSaved) ? "active" : ""} btn__action`}
        >
          <FontAwesomeIcon icon={faSave} className="mx-1" />
          {parseInt(isSaved) ? "Saved" : "Save"}
        </button>

        <button className="btn__action">
          <FontAwesomeIcon icon={faShareSquare} className="mx-1" /> Share
        </button>
      </ActinButtons>
    </Wrapper>
  );
};

export default MetaDetails;

const Wrapper = styled.div`
  & .description {
    margin-block: 0.5rem;
    max-width: 70ch;

    & > .show-more {
      border: 0;
      font-size: 0.7rem;
      background-color: transparent;
    }
  }
`;

const ActinButtons = styled.div`
  white-space: nowrap;
  margin-block: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  overflow-x: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  /* 576px */
  @media (max-width: 36em) {
    gap: 0.5rem;
  }

  & .btn__action {
    border: 0;
    padding: 0.3rem 0.5rem;
    border-radius: 0.2rem;
    background-color: var(--bg-primary);
    font-size: 0.95rem;
    transition: 0.3s;
    color: var(--text-primary);

    &:hover {
      filter: brightness(90%);
    }
  }

  & .active.btn__action {
    background-color: var(--text-info);
    color: var(--clr-white);
  }
`;
