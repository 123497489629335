import React, { useState } from "react";
import "./common.css"
import { ShareActions } from "../../../NewsFeedPost/Components/PostActions/ShareActions";
import { Row, Col } from "react-bootstrap";
import { useLocation} from "react-router-dom";
import VideoPlayer from "src/Common/VideoPlayer/VideoPlayer";
import LikeCommentShareView from "../LikeCommentShareView/LikeCommentShareView";



const YoutubeVideoPlayer = ({handleClose=()=>{}}) => {
  const [showShareOption] = useState(false);
  const { search } = useLocation();
  const url = search?.split("=")[1];


  
  return (
    <div className="NewsFeedPostBg container-fluid newsFeedPage p-3 position-relative youtubePlayer">
      <Row>
        <Col xs={12} md={12} lg={12} className="NewsPhotoDiv">
          <VideoPlayer url={url} isPlaying={true}></VideoPlayer>
        </Col>
      </Row>

      {showShareOption && <ShareActions />}
      <LikeCommentShareView />
      <div className="searchDropdown  mx-auto firstStageCommentDiv">
        {false && (
          <div className="MessageInput ">
            <div className="container-fluid px-2">
              {/* <CommentextArea data={{}} /> */}
            </div>
          </div>
        )}
      </div>

      <button onClick={handleClose} type="button" className="profileCloseBtn">
        ❌
      </button>
    </div>
  );
};

export default YoutubeVideoPlayer;
