import SearchHeroArea from "./../components/SearchHeroArea";
import FindFreelancerHeroArea from "./../components/FindFreelancer";
import FreelancerSort from "./../components/FreelancerSort";
import SearchedFreelancer from "./../components/SearchedFreelancer";

function FindFreelancer() {
  return (
    <div id="wrapper">
      <FindFreelancerHeroArea />
      {/* <SearchHeroArea /> */}
      <FreelancerSort />
      <SearchedFreelancer />
    </div>
  );
}
export default FindFreelancer;
