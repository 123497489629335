import React from 'react'

export const JobIcon = ({size="",className="",fillcolor}) => {
  return (
    <svg width={size? size:"43"} height={size? size:"39"} viewBox="0 0 43 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.22 21.3508C29.4636 21.3508 34.6916 21.3486 39.9195 21.3531C40.7807 21.3531 41.254 21.8016 41.2562 22.6606C41.2607 26.5833 41.2966 30.5059 41.2226 34.4285C41.1844 36.3775 39.6459 37.8376 37.7059 37.9878C37.1474 38.0305 36.5867 38.017 36.026 38.017C26.2744 38.0192 16.5249 38.0192 6.77328 38.017C3.44273 38.017 2.09482 36.6646 2.09258 33.3273C2.09033 29.9272 2.08809 26.5272 2.09482 23.1293C2.09706 21.6558 2.40432 21.3508 3.86662 21.3508C8.68638 21.3463 13.5061 21.3374 18.3237 21.3732C18.7004 21.3755 19.2477 21.6312 19.4181 21.9362C19.9452 22.8692 20.627 23.4411 21.7215 23.4232C22.8048 23.4052 23.5539 22.8826 23.962 21.8756C24.0338 21.7052 24.1258 21.5459 24.22 21.3508Z" fill="#F1A350"/>
    <path d="M24.2267 18.9982C24.0383 17.3385 24.0383 17.3385 22.3069 17.3363C21.8583 17.3363 21.4098 17.3363 20.9634 17.3363C19.324 17.3385 19.3105 17.352 19.1423 19.1036C18.7902 19.1036 18.4246 19.1036 18.059 19.1036C12.793 19.1036 7.52688 19.1059 2.2608 19.1036C0.677394 19.1036 0.3791 18.8008 0.376857 17.204C0.374615 15.8605 0.372372 14.5149 0.376857 13.1714C0.385828 10.4262 1.91542 8.88545 4.68302 8.86526C6.9617 8.84732 9.24037 8.86302 11.5168 8.85853C11.8084 8.85853 12.0977 8.82489 12.5126 8.79798C12.5126 8.16103 12.5036 7.57566 12.5149 6.99029C12.5776 3.579 14.9326 1.13436 18.3551 1.02895C20.5575 0.961662 22.7622 0.968395 24.9646 1.02671C28.4364 1.1209 30.7869 3.56554 30.8474 7.06206C30.8564 7.61378 30.8497 8.16551 30.8497 8.86302C32.8772 8.86302 34.806 8.85629 36.7348 8.86526C37.6678 8.86975 38.6097 8.8249 39.5315 8.93255C41.5433 9.17029 42.9249 10.6954 42.9787 12.7072C43.0213 14.3489 42.9854 15.9929 42.9921 17.6368C42.9966 18.525 42.6086 19.0969 41.6824 19.0991C35.9318 19.1081 30.1791 19.0969 24.4286 19.0879C24.359 19.0834 24.2918 19.0296 24.2267 18.9982ZM16.821 8.77107C20.1426 8.77107 23.2915 8.77107 26.539 8.77107C26.539 8.31802 26.539 7.95245 26.539 7.58463C26.5345 5.75003 26.1421 5.35305 24.3074 5.34857C22.5177 5.34408 20.7257 5.34408 18.936 5.34857C17.2539 5.35529 16.83 5.78591 16.8188 7.47249C16.8188 7.87844 16.821 8.2799 16.821 8.77107Z" fill="#F1A350"/>
    </svg>
    
  )
}
