import React from "react";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { timeAgo } from "src/utilities/utilities";
import { PostActions } from "./Components/PostActions";
import { PostUserPicture } from "./Components/PostUserPicture";

export const Header = ({ headerArg }) => {
  const { post } = headerArg;
  const { pathname } = useLocation();
  const {
    comments_count,
    comment_reply_count,
    post_details,
    liked_posts_count,
    personal_like_status,
    user,
    user_id,
    profile_images,
    post_contents,
    created_at,
  } = post;
  const postMainId = post.id;
  const personalLikeUserId =
    personal_like_status !== null
      ? Number(personal_like_status.user_id)
      : Number(0);
  const { first_name, last_name } = user;
  const navigate = useNavigate();

  const getTimeAgo = useMemo(() => timeAgo(created_at), [created_at]);

  return (
    <div className="col-12 MiddlePartScrool  py-0 my-0">
      <div className="PostHeaderOption pt-3 px-3 mb-2">
        <div className="ProfileAndName">
          <PostUserPicture
            profile_image={user.photo}
            userName={`${first_name} ${last_name}`}
          />
          <div className="ms-2">
            <div className="d-flex flex-column align-items-start">
              <h6
                aria-labelledby={`${first_name} ${last_name}`}
                className="my-0 smallTextSize-sm namehoverEffect"
                onClick={() =>
                  navigate(`/life/public-life/${user_id}`, {
                    from: pathname,
                  })
                }
              >
                {first_name} {last_name}
              </h6>
              <img
                src="https://res.cloudinary.com/yex/image/upload/v1669472215/UVIOM/uviom-verified_l3d95w.svg"
                alt=""
              />
            </div>
            <div className="smallTextSize-sm">
              <small>{getTimeAgo}</small>
            </div>
          </div>
        </div>
        <PostActions />
      </div>
    </div>
  );
};
