import React from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { companyProfilePhotoUrl } from "src/Projects/Job/utilities/job_commonUrl";

const CompanyCard = ({ data }) => {
  const {
    profile_picture,
    company_name,
    company_address,
    user_id,
    company_email,
  } = data;
  const navigate = useNavigate();

  const photoUrl = profile_picture
    ? companyProfilePhotoUrl + profile_picture
    : "/user.png";

  const handleClick = () => {
    navigate(`/Job/company-details/${user_id}`, { state: { data } });
  };

  return (
    <Col>
      <div
        className="jobPostImgContainer pointer py-3 job__company-card job_cart-hover primary-bg align-items-center d-flex gap-3 p-2"
        onClick={handleClick}
      >
        <div className="jobPostImgDiv border-1 border-primary">
          <img src={photoUrl} className="img img-fluid" alt="" />
        </div>
        <div className="jobPostTitleText">
          <h6 className="my-0 fw-bold  text-ellipsis">
            <span>{company_name || "No Name Found"}</span>
          </h6>
          <p className="my-0 fs-6 text-ellipsis">
            <span className="font-14">{company_address}</span>
          </p>
          <p className="my-0 fs-6 text-ellipsis">
            <span className="font-14">{company_email}</span>
          </p>
        </div>
      </div>
    </Col>
  );
};

export default CompanyCard;
