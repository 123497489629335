import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./InputWithLabel.css";

const InputPhoneNumber = ({
  value = "",
  name = "",
  label = "",
  handler = () => {},
  className = ["", ""],
  disabled = false,
  small,
  defaultCountry
}) => {
  return (
    <Form.Group
      as={small ? Col : Row}
      className={`mb-3  phone-number-input ${small ? null : "mx-md-1"}`}
      controlId=""
    >
      <Form.Label
        className={ ` d-none ${small ? null : "d-md-block"} InputWithLabelLabel ${className[1]}  text-start ps-2 h-100`}
        column
        sm="3"
      >
        {label}
      </Form.Label>
      <Col md={small ? "12" : "9"} className="h-100">
        <Form.Label className={`d-block ${small ? null : "d-md-none"} ` }>{label}</Form.Label>

        <PhoneInput
        defaultCountry={`${defaultCountry?defaultCountry:"BD"}`}
          placeholder="Enter phone number"
          onChange={handler}
          className={className[0]}
          required
          value={value}
          disabled={disabled}
        />
        {value && (
          <label className="form-label ps-4" style={{ fontSize: "0.855rem" }}>
            <span className="text-danger bold">
              {isPossiblePhoneNumber(value)
                ? isValidPhoneNumber(value)
                  ? ""
                  : "Invalid phone number"
                : "Number Length is not correct yet"}
            </span>
          </label>
        )}
      </Col>
    </Form.Group>
  );
};

export default InputPhoneNumber;
