import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";

import "./Notification.css";
import { useGetPersonalNotificationQuery } from "src/app/services/lifeApi/lifeCommonApi";
import { timeAgo } from "src/utilities/utilities";
import { personalProfilePic } from "src/Projects/Life/Common/CommonUrl";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";

const Notification = () => {
  // const allNotification = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const [currentPage, setCurrentPage] = useState(1);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const { authUser } = useAuth();

  const {
    data: result,
    isFetching,
    error,
  } = useGetPersonalNotificationQuery(currentPage);
  const { data, hasMore } = result || {};
  useEffect(() => {
    if (data) {
      setNotifications((prevState) => [...prevState, ...data]);
    }
  }, [data]);

  const handleLoadMore = () => {
    if (hasMore) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const location = useLocation();
  const timeline_name = location.pathname?.split("/")[2];

  const handleClick = (data) => {
    const { post_id, type, user_id } = data;
    if (type === "post_like_notification") {
      navigate(
        `/life/post/${post_id}?user_id=${authUser.id}?timeline=${timeline_name}`
      );
    } else if (type.includes("accept")) {
      navigate(`/life/public-life/${user_id}`);
    }
  };

  return (
    <div className="notificatioParent mb-5" id="mainpart">
      <h6 className="secondary-bg px-3 py-2 rounded-1 d-block">
        All Notification
      </h6>

      {notifications.map((notification, index) => (
        <div
          tabIndex="0"
          className="singleNotification secondary-bg"
          key={index}
        >
          <div className="notification_container">
            <div className="notification_image">
              <img
                className="img-fluid fndImg"
                src={`${personalProfilePic}/${notification.photo}`}
                alt={notification.name}
                onClick={() =>
                  navigate(`/life/public-life/${notification.data.user_id}`)
                }
              />
            </div>
            <div className="notification_content">
              <p
                className="mb-1"
                onClick={() => handleClick(notification.data)}
              >
                {notification.data.message}
              </p>
              <span className="font-12"> {timeAgo(notification.time)}</span>
            </div>
            <div className="notification_action">
              <NotificationAction />
            </div>
          </div>
        </div>
      ))}
      {isFetching ? (
        <h2 className="text-center my-4">
          <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon>
        </h2>
      ) : (
        <div className="mt-4 mb-3 text-end">
          <button
            className="send-code-btn primary-text"
            disabled={!hasMore}
            onClick={handleLoadMore}
          >
            Load More
          </button>
        </div>
      )}

      {error && <p className="text-danger">{error.error}</p>}
    </div>
  );
};

export default Notification;

const NotificationAction = () => {
  return (
    <div className="MoreOption">
      <Dropdown>
        <Dropdown.Toggle
          tabIndex={0}
          aria-label="toggle button"
          className="ToggleDesignClear"
        >
          <ThreeDots className="info-text" size="20" />
        </Dropdown.Toggle>
        <Dropdown.Menu variant="dark">
          <div className="ClickThreeDot mb-3 px-3 pb-3 pt-3">
            <div
              className="btnHoverBorder box-shadow justify-content-start py-2 ps-3 pe-2 "
              tabIndex={0}
            >
              <div className="">
                <h6 className="mb-0">
                  <FontAwesomeIcon icon={faTrash} className="me-2" /> Remove
                </h6>
              </div>
            </div>

            <div
              className="btnHoverBorder box-shadow justify-content-start py-2 ps-3 pe-2 "
              tabIndex={0}
            >
              <div className="  ">
                <h6 className="mb-0">
                  <FontAwesomeIcon icon={faLink} className="me-2" /> Action
                </h6>
              </div>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
