import React from "react";
import { Col, Row } from "react-bootstrap";
import VideoPlayer from "src/Common/VideoPlayer/VideoPlayer";
import "./BestTimeProfileBanner.css";

const videoUrl =
  "https://uviom-life-running.s3.ap-southeast-1.amazonaws.com/test/Advice+to+Young+People+And+His+Biggest+Regret+Jack+Ma+Goal+Quest.mp4";

const BestTimeProfileBanner = ({ showFollowBtn = false, userData }) => {
  return (
    <div className="bestTimeBanner">
      <Row className="g-3 h-100">
        <Col
          xs="12"
          sm="7"
          className="d-flex flex-column justify-content-between h-100"
        >
          <div className="d-flex justify-content-between align-items-center ">
            <div className="ProfileAndName">
              <div className="PostSectionProfile">
                <img
                  className="img-fluid"
                  src={userData?.photo ? userData.photo : "/user.png"}
                  alt={userData?.name}
                />
              </div>
              <div className="ms-2">
                <h6 className="my-0 smallTextSize-sm bestTimeProfileName">
                  {userData?.name || "user Name"}
                </h6>
                <div className="smallTextSize-sm">
                  <small>{userData?.username || "user name"}</small>
                </div>
              </div>
            </div>

            {showFollowBtn && (
              <div>
                <button type="button" className="bestTimeFollowBtn">
                  Follow
                </button>
              </div>
            )}
          </div>

          <div className="bestTimeProfileInfo  d-flex  align-items-center mt-5">
            <div className="bestTimeProfileInfoDivOne">
              <p className="bestTimeProfileInfoItem mb-2">
                <span>Followers</span> <span>10.11 M</span>
              </p>
              <p className="bestTimeProfileInfoItem">
                <span>Following</span> <span>100.00 K</span>
              </p>
            </div>
            <div className="bestTimeProfileInfoDivTwo">
              <p className="bestTimeProfileInfoItem mb-2">
                <span>Average View</span> <span>6.51 K</span>
              </p>
              <p className="bestTimeProfileInfoItem">
                <span>Average Like</span> <span>4.08 K</span>
              </p>
            </div>
          </div>
        </Col>

        <Col xs="12" sm="5" className="h-100">
          <p className="d-block d-sm-none">Recent Video</p>
          <div className="  bestTimeProfileVideo">
            <VideoPlayer
              url={videoUrl}
              control={false}
              playIconSize="40px"
              aspectRatio="4/3"
              width="100%"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BestTimeProfileBanner;
