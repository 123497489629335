import React from "react";
import { Modal } from "react-bootstrap";

export const ModalHeader = ({ headerTitle, handleClose }) => {
  return (
    <Modal.Header className="stickyParts z-index-2 py-0">
      <div className="py-3">{headerTitle}</div>
      <div
        onClick={() => handleClose()}
        role="button"
        className="display-6 lh-1 text-orange"
      >
        &times;
      </div>
    </Modal.Header>
  );
};
