import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const HomeBanner = () => {
  return (
    <Container fluid>
      <Row>
        <Col className="p-0 m-0">
          <div className="job__home__banner row g-1">
            <div className="col-12 col-md-6 col-lg-12 col-xl-6">
              <div className="job__home__banner-item1">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/dnkimage/Front/images/career_view_banner.jpg"
                  alt="job- banner"
                  className="img img-fluid"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-12 col-xl-6">
              <div className="job__home__banner-item2">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/dnkimage/Front/images/career_view_banner.jpg"
                  alt="job- banner"
                  className="img img-fluid"
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeBanner;
