import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, Route, Routes as Switch } from "react-router-dom";
import HelpCenter from "../HelpCenter/HelpCenter";
import MyFavorite from "../MyFavorite/MyFavorite";
import ManageOrder from "./ManageOrder/ManageOrder";
import ManageProfile from "./ManageProfile/ManageProfile";
import OrderCancellation from "./OrderCancellation/OrderCancellation";
import OrderHistory from "./OrderHistory/OrderHistory";
import PaymentHistory from "./PaymentHistory/PaymentHistory";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import Profile from "./Profile/Profile";
const MyProfile = () => {
  return (
    <Container fluid style={{ minHeight: "75vh" }} className="mt-3">
      {/* <h6 className="fs-6 py-3 d-none d-md-block">My Profile</h6> */}
      <Row>
        <Col xs="12" lg="4" xl="3" className="d-none d-lg-block h-100">
          <Profile />
        </Col>
        <Col xs="12" lg="8" xl="9" className="h-100 p-0">
          <Switch>
            <Route
              index
              element={<Navigate to="/food/my-profile/my-order" />}
            />

            <Route path={`personal-info`} element={<PersonalInformation />} />

            <Route path={`manage-profile`} element={<ManageProfile />} />

            <Route path={`my-order`} element={<OrderHistory />} />

            <Route path={`my-favorite`} element={<MyFavorite />} />

            <Route path={`payment-history`} element={<PaymentHistory />} />

            <Route path={`help-center`} element={<HelpCenter />} />

            <Route path={`manage-order/:productId`} element={<ManageOrder />} />

            <Route
              path={`order-cancellation/:productId`}
              element={<OrderCancellation />}
            />
          </Switch>
        </Col>
      </Row>
      <br />
    </Container>
  );
};

export default MyProfile;
