import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { paginationPostApiCall } from "src/Common/apiCall/PostApiCalls";
import { ShopwiseProductSearchUrl } from "../../utilities/CommonUrl";
import SearchBox from "./SearchBox";

const ProductSearchBox = (props) => {
  const { setIsLoading, product, setProduct, setsearchMore, serachCurrentPage, formData,
    setformData } = props?.ProductSearchBoxArg

  const [alert, setAlert] = useState("")
  const inputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...formData };
    newData[name] = value;
    setformData(newData);

  };
  useEffect(() => {
    if (alert !== "") {
      const timer = setTimeout(() => {
        setAlert("")
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [alert])

  useEffect(() => {
    const productName = formData.product_name
    if (productName !== "") {
      if (productName.length < 2) {
        setAlert("Minimum letter is : 2")
      } else if (productName.length > 1) {
        setAlert()
      }
    }
  }, [formData])
  useEffect(() => {
    if (serachCurrentPage > 1) {
      messageFormSubmit(formData, true)
    }
  }, [serachCurrentPage])

  const messageFormSubmit = (formData, isAddPrevData) => {
    const formArg = {
      setIsLoading: setIsLoading,
      postList: product,
      setPostList: setProduct,
      setHasMore: setsearchMore,
      Url: `${ShopwiseProductSearchUrl}?page=${serachCurrentPage}`,
      formData: formData,
    };
    paginationPostApiCall(formArg, isAddPrevData)
  };
  const onkeyPressSubmit = (event) => {
    if (formData.product_name.length > 1) {
      if (event.type === "keypress") {
        if (event.key === "Enter" && event.shiftKey === false) {

          event.preventDefault();
          messageFormSubmit(formData, false);
        } else if (event.shiftKey && event.key === "Enter") {
        }
      } else {
        messageFormSubmit(formData, false);
      }
    }
  };
  return (
    <Row>
      <Col xs="8" md="6">
        <SearchBox
          onChange={inputHandleChange}
          handleSubmit={onkeyPressSubmit}
          onKeyPress={onkeyPressSubmit}
          value={formData.product_name}
          inputName="product_name"
          placeholder="Search By Products"
        />
        {alert && <div className="smallTextSize text-danger my-2">{alert}</div>}

      </Col>
      <Col xs="4" md="6" className="text-end">
        <div className="position-relative d-inline-block mt-1">
          <Form.Select
            aria-label="Default select example"
            className="corporate__navbar__dropdown border-0 py-2 "
          >
            <option>Sort by</option>
            <option value="1">New Arrived</option>
            <option value="2">Low Price</option>
            <option value="2">High Price</option>
            <option value="2">Free Shiping</option>
          </Form.Select>
          <FontAwesomeIcon
            className="corporate__navbar__icon-down"
            icon={faChevronDown}
          ></FontAwesomeIcon>
        </div>
      </Col>
    </Row>
  );
};

export default ProductSearchBox;
