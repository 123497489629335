import React from "react";
import ReactImageMagnify from "react-image-magnify";

export const ImageMagnify = ({ srcPath }) => {
  return (
    <ReactImageMagnify
      {...{
        smallImage: {
          alt: "Mobile View",
          isFluidWidth: true,
          src: srcPath,
          sizes: "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 300px",
        },
        largeImage: {
          src: srcPath,
          width: 1200,
          height: 1800,
        },
      }}
      imageClassName="img img-fluid product-magnify-image"
      enlargedImagePosition={window.innerWidth < 577 ? "over" : "beside"}
    />
  );
};
