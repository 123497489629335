import { isEmpty } from "lodash";
import { useEffect, useReducer, useState } from "react";
import useAuth from "src/hooks/useAuth";
import useStore from "src/hooks/useStore";
import CartReducer from "../context/cart/CartReducer";
import { ADD_TO_CART, QUANTITY_ADD, REMOVE_FROM_CART, REMOVE_ITEM, TOTAL_UPDATE } from "../context/cart/Types";

const useCartGlobal = () => {
    const initalState = {
        cartItems: [],
        allTotal: 0,
        total_quantity: 0,
    };
    const [state, dispatch] = useReducer(CartReducer, initalState, () => {
        const localData = localStorage.getItem('cartdata');
        return localData ? JSON.parse(localData) : initalState;
    });
    const deliveryCost = 100;

    useEffect(() => {
        localStorage.setItem('cartdata', JSON.stringify(state))
    }, [state])
    const addToCart = (item) => {
        // const localData = JSON.parse(localStorage.getItem('cartdata'));
        dispatch({ type: ADD_TO_CART, payload: item });

    };
    const quantityAdd = (itemID, value) => {
        // console.log("Item id ",itemID);
        // console.log("increase value ",value);
        dispatch({
            type: QUANTITY_ADD, payload: {
                id: itemID,
                quantity: value,
            }
        });
    };
    const totalUpdate = () => {
        dispatch({ type: TOTAL_UPDATE });
    }
    useEffect(() => {
        totalUpdate();

    }, [state.cartItems]);
    const { authUser,addressModify } = useAuth();
//   const {  } = useStore();
    
    useEffect(() => {
      if(addressModify){
        setShippingAddress({...shippingAddress,shipping_address1: addressModify,shipping_address2:addressModify})
      }
    }, [addressModify])
    
    const [shippingAddress, setShippingAddress] = useState({
        shipping_name: authUser && `${authUser.first_name} ${authUser.last_name}`,
        shipping_email: authUser && `${authUser.email}`,
        shipping_phone: authUser && `${authUser.phone}`,
        shipping_address1: addressModify,
        shipping_address2: addressModify,
    });
    const [billingAddress, setBillingAddress] = useState({
        billing_name: '',
        billing_email: '',
        billing_phone: '',
        billing_address1: '',
        billing_address2: '',
    })
    const removeFromCart = () => {
        dispatch({ type: REMOVE_FROM_CART });
    };
    //---------
    const defaultCarts = [
        {
            id: "100",
            price: 1200,
            quantity: 1,
        },
        {
            id: "101",
            price: 1100,
            quantity: 1,
        },
        {
            id: "102",
            price: 1500,
            quantity: 1,
        },
    ];
    const [cart, setCart] = useState(defaultCarts);
    const [totalPrice, setTotalPrice] = useState(0);


    const removeItem = (id) => {
        // console.log(id)
        dispatch({ type: REMOVE_ITEM, payload: id });
    };

    // console.log("hi")
    const handleQuantity = (id, isIncrease) => {
        const updateCart = cart.map((item) => {
            if (item.id === id) {
                if (isIncrease) {
                    item.quantity += 1;
                } else {
                    item.quantity -= 1;
                }
                return item;
            } else return item;
        });
        setCart(updateCart);
    };
    // useEffect(() => {
    //     setTotalPrice(0);
    //     const total = cart.reduce((prevValue, currentItem) => {
    //         return prevValue + currentItem.quantity * currentItem.price;
    //     }, 0);
    //     setTotalPrice(total);
    // }, [cart]);

    return {
        handleQuantity,
        totalPrice,
        cart,
        addToCart, state,
        removeItem,
        quantityAdd,
        totalUpdate,
        billingAddress,
        setBillingAddress,
        shippingAddress,
        setShippingAddress,
        deliveryCost,
        removeFromCart,
    };
}
export default useCartGlobal;
