import React, { useState } from "react";
import "./UpdateProfile.css";
import Navigation from "../LoginPage/Shared/Navigation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFastForward } from "@fortawesome/free-solid-svg-icons";

const UpdateProfileBasic = () => {
  const [data, setData] = useState({});
  const [startDate, setStartDate] = useState(new Date("2004/01/02"));
  const navigate = useNavigate();
  const handelBlure = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newData = { ...data };
    newData.gender = "Male";
    newData.date_of_birth = startDate.toLocaleDateString("en-CA");
    if (value && name) {
      newData[name] = value;
    }
    setData(newData);
  };
  const handelSubmit = (e) => {
    e.preventDefault();

    // let UpdateData;
    // for (let fild in data) {
    //   let key = fild;
    //   let value = data[fild];
    //   if (!UpdateData) {
    //     UpdateData = key + "=" + value;
    //   } else {
    //     UpdateData = UpdateData + "&" + key + "=" + value;
    //   }
    // }
    // console.log(UpdateData);
    // fetch(`https://life.uviom.com/api/v1/profile/update/basic?${UpdateData}`, {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: `Bearer ${JSON.parse(
    //       localStorage.getItem("access_token")
    //     )}`,
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     if (errorCheck(data)) {
    //       swal({
    //         title: data.message,
    //         icon: "success",
    //         button: "Ok",
    //       });
    //       // Navigatedahsbord
    navigate("/dashboard");
    //     }
    //   });
    // e.target.reset();
  };

  return (
    <div>
      <Navigation />

      <h2 className="text-center updateProfileTitle mt-5">
        Please Update Your Profile Information
      </h2>
      <div className="updateProfilePage  mt-4">
        <form onSubmit={handelSubmit}>
          <label>
            {" "}
            <small>Address*</small>
          </label>
          <div className="InputFildDiv ">
            <input
              required
              onBlur={handelBlure}
              name="address"
              type="text"
              placeholder=" Enter Your Address*"
            />
          </div>
          <label>
            {" "}
            <small>Phone Number*</small>
          </label>
          <div className="InputFildDiv ">
            <input
              required
              onBlur={handelBlure}
              name="phone_number"
              type="text"
              placeholder=" Enter Your Phone Number*"
            />
          </div>
          <label>
            {" "}
            <small>Date of birth*</small>
          </label>

          <div onClick={handelBlure} className="InputFildDiv ">
            <DatePicker
              className="w-100"
              minDate={new Date("1950/01/01")}
              maxDate={new Date("2004/01/02")}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <label>
            {" "}
            <small>Gender*</small>
          </label>

          <div className="InputFildDiv text-start ">
            <input
              onClick={handelBlure}
              defaultChecked
              type="radio"
              id="Male"
              name="gender"
              value="Male"
            />
            <label htmlFor="Male">Male</label>
            <input
              onClick={handelBlure}
              type="radio"
              id="Female"
              name="gender"
              value="Female"
            />
            <label htmlFor="Female">Female</label>
            <input
              onClick={handelBlure}
              type="radio"
              id="Others"
              name="gender"
              value="Others"
            />
            <label htmlFor="Others">Others</label>
          </div>

          <div className="d-flex my-2 justify-content-center">
            <button onClick={() => navigate("/dashboard")} className="button">
              Skip <FontAwesomeIcon icon={faFastForward} />
            </button>
            <button type="submit" className="button">
              Update <FontAwesomeIcon icon={faCheck} />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateProfileBasic;
