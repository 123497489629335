import { useLocation } from "react-router-dom";
import "./Cover.css";
export const Cover = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/view" || location.pathname === "/view/" ? (
        <>
          <div className="row ps-md-3 pe-md-3">
            <div className="col-12   cover">
              {/* <img className="img-fluid" src="/View/cover.png" alt="" /> */}
              <div className="view__cover-video__overlay">
                <video className="view__cover-video" playsInline={true} loop muted autoPlay>
                  <source src="/View/viewVideo.mp4" />
                </video>
              </div>
              <div className="view__cover-overlay">
                <div className="d-flex flex-column justify-content-center align-items-start w-100 h-100 ps-0 ps-sm-4 m">
                  <h3 className="display-6 mb-2 mb-lg-3 text-dark fw-500">
                    Experice A New
                    <br /> View, With
                  </h3>
                  <h2 className="display-5 fw-bold text-dark">UVIOM View!</h2>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
