import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import RatingModal from "src/Projects/Food/Common/RatingModal/RatingModal";

const ReceivedOrderCard = () => {
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  return (
    <div className="secondary-bg p-2 my-3 rounded-2">
      <Row className="g-3">
        <Col lg="8" xl="9">
          <div className="favorite-card p-0 m-0">
            <div className="cart-item-img-box">
              <img className="img img-fluid" src="/food-image/food1.png" alt="" />
            </div>
            <div
              style={{ height: "100%" }}
              className="cart-text-box align-items-start d-flex flex-column justify-content-between"
            >
              <p>ZamZam Restuant (Mirpur-11)</p>
              <p className="">
                <small>
                  {"steak frites, (French: “steak [and] fries”) a simple dish of beef steak.".substring(
                    0,
                    60
                  )}
                  ...
                </small>
              </p>
              <p>Quantity: 1</p>
            </div>
          </div>
        </Col>
        <Col lg="4" xl="3">
          <div className="d-flex align-items-center h-100">
            <div className="d-flex justify-content-end align-items-center flex-grow-1 ">
              <p className="m-0 mb-0 py-1 me-3 product-status-text border-orange">
                <small>Received</small>
              </p>
              <button onClick={()=>setModalShow(true)} className=" send-code-btn cart-confirm-btn manage-order-btn py-1 font-14 text-dark">
                Review
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <RatingModal show={modalShow} handleClose={handleClose} />
    </div>
  );
};

export default ReceivedOrderCard;
