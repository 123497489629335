import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import {
  updateDetailsInfoUrl,
  userAdditionalInfoUrl,
} from "src/Projects/Life/Common/CommonUrl";
import { SingleItem } from "./SingleItem";
import { StoringInputs } from "./StoringInputs";

import { getInfo } from "src/Common/apiCall/GetApiCalls";
import { BubbleScale } from "src/Common/BubbleScale";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";

export const DetailsAbout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const setIsEditableFalse = () => setIsEditable(false);
  const setIsEditableTrue = () => setIsEditable(true);
  const [info, setinfo] = useState();
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    getInfo(setIsLoading, userAdditionalInfoUrl, setinfo);
  };
  const [formData, setformData] = useState({
    details_about: "",
    privacy: "",
  });
  // console.log(info.data.details_about)
  useEffect(() => {
    // userAdditionalInfoUrl
    if (!isEmpty(info)) {
      const parseData = JSON.parse(
        info && info.data && info.data.details_about
      );
      setformData({
        details_about:
          parseData && parseData.details_info ? parseData.details_info : "",
        privacy: parseData && parseData.privacy ? parseData.privacy : 1,
      });
    }
  }, [info]);
  const inputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...formData };
    newData[name] = value;
    setformData(newData);
  };
  const storingInputsArg = {
    isLoading: isLoading,
    setIsLoading: setIsLoading,
    setIsEditableFalse: setIsEditableFalse,
    dataReload: getData,
    componentMainUrl: updateDetailsInfoUrl,
    formData: formData,
    inputHandleChange: inputHandleChange,
  };
  if (isLoading) {
    return <DottedCircleSpinner />;
  }
  return (
    <div className="mb-4">
      <Col xs={12} sm={12} md={12} lg={12} className="aboutBox mt-1 mb-4 ">
        <div className="d-flex justify-content-between border-bottom pb-2 border-white">
          <div>
            <h6>Details About You</h6>
            <div className="d-flex pointer" onClick={setIsEditableTrue}>
              <div className="me-2">
                <FontAwesomeIcon icon={faPlusCircle} />
              </div>
              <div className=""> Update Details</div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          {isEditable ? (
            <>
              <StoringInputs storingInputsArg={storingInputsArg} />
            </>
          ) : (
            <>
              <SingleItem item={formData} />
            </>
          )}
        </div>
      </Col>
    </div>
  );
};
