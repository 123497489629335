import React from "react";
import "./cover_profile_banner.css";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CoverProfileBanner = ({showEditIcon,coverImg, profileImg}) => {
  return (
    <div className="cover-profile-banner ">
      
        <img
          className="img-fluid img "
          src={coverImg}
          alt=""
        />

        {showEditIcon && (
          <div className="companyBannerCoverIcon">
            <FontAwesomeIcon icon={faCamera} />
          </div>
        )}

        <div className="store-banner-profile">
          <img className="img-fluid img" src={profileImg} alt="" />
          {showEditIcon && (
            <div className="companyBannerProfileIcon">
              <FontAwesomeIcon icon={faCamera} />
            </div>
          )}
        </div>
      
    </div>
  );
};

export default CoverProfileBanner;
