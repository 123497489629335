import { Link, useLocation } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";

import stock2 from "./../../assets/stock2.jpg";
import "./partnerRegistration.css";

function PartnerRegistration() {
  const { pathname:url } = useLocation();

  const dynamicPath = url.split("/")[1];

  return (
    <div className="partner__registration">
      <Row>
        <Col md={6}>
          <div className="me-md-4 mb-4 text-center text-md-start ">
            <h2 className="heading-1">
              Opportunity is everywhere for everyone
            </h2>
            <p>New benefits and guaranteed earnings</p>

            <img
              src={stock2}
              className="img-fluid mt-3 d-none d-md-block"
              alt=""
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="ms-md-4">
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  className="shadow-sm"
                  placeholder=""
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  className="shadow-sm"
                  type="text"
                  placeholder=""
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  className="shadow-sm"
                  type="text"
                  placeholder=""
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  className="shadow-sm"
                  type="text"
                  placeholder=""
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  className="shadow-sm"
                  type="text"
                  placeholder=""
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Referral Code (optional)</Form.Label>
                <Form.Control
                  className="shadow-sm"
                  type="text"
                  placeholder=""
                />
              </Form.Group>
              <div className="text-end mt-4">
                <Button variant="outline-dark me-3">Cancel</Button>
                <Button
                  as={Link}
                  to={`/${dynamicPath}/partner/documents`}
                  className="btn__ride px-4"
                  type="submit"
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default PartnerRegistration;
