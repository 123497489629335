import React from "react";
import { Col } from "react-bootstrap";
import { Route, Routes as Switch } from "react-router-dom";
import ApplicantDetails from "../../Pages/ApplicantDetails/ApplicantDetails";
import ApplicantList from "../../Pages/ApplicantList/ApplicantList";
import ApplicantProfile from "../../Pages/ApplicantProfile/ApplicantProfile";
import ApplicantProfileUpdate from "../../Pages/ApplicantProfile/ApplicantProfileUpdate";
import CreateApplicantProfile from "../../Pages/ApplicantProfile/CreateApplicantProfile";
import UpdateApplicantEducation from "../../Pages/ApplicantProfile/UpdateApplicantEducation";
import AppliedJob from "../../Pages/AppliedJob/AppliedJob";
import CompanyDetails from "../../Pages/CompanyDetails/CompanyDetails";
import CompanyProfile from "../../Pages/CompanyProfile/CompanyProfile";
import CompanyRegister from "../../Pages/CompanyRegister/CompanyRegister";
import CreateNewJob from "../../Pages/CreateNewJob/CreateNewJob";
import JobPostPreview from "../../Pages/CreateNewJob/JobPostPreview";
import DistrictByPost from "../../Pages/DistrictByPost/DistrictByPost";
import DivisionByPost from "../../Pages/DivisionByPost/DivisionByPost";
import JobApply from "../../Pages/JobApply/JobApply";
import AppliedJobDetails from "../../Pages/JobDetails/AppliedJobDetails";
import JobPostDetails from "../../Pages/JobDetails/JobPostDetails";
import SaveJobDetails from "../../Pages/JobDetails/SaveJobDetails";
import Home from "../../Pages/JobHome/Home";
import JobNotification from "../../Pages/JobNotification/JobNotification";
import JobPostPage from "../../Pages/JobPostPage/JobPostPage";
import EditJobPost from "../../Pages/PostedJobs/EditJobPost";
import PostedJobs from "../../Pages/PostedJobs/PostedJobs";
import SaveJob from "../../Pages/SaveJobs/SaveJob";
import SearchResult from "../../Pages/SearchResult/SearchResult";
import UpdateCompanyProfile from "../../Pages/UpdateCompanyProfile/UpdateCompanyProfile";
import ApplicantRoute from "../../Routes/ApplicantRoute";
import CompanyRoute from "../../Routes/CompanyRoute";
import AllJobCategories from "../JobCategories/AllJobCategories";
import TopNavbar from "../TopNavbar/TopNavbar";

const MiddleCol = () => {
  return (
    <Col lg="6" xxl="8" className="px-0 px-md-1">
      <TopNavbar />
      <br />
      <Switch>
        <Route index element={<Home></Home>} />

        <Route path={`/search`} element={<SearchResult />} />

        <Route path={`/job-market/*`} element={<JobPostPage />} />

        <Route
          path={`/job-details/:categoriesName/:jobPostId`}
          element={<JobPostDetails />}
        />

        <Route
          path={`/company-details/:companyId`}
          element={<CompanyDetails />}
        />

        <Route
          path={`/job-post/:divisionName/:divisionId`}
          element={<DivisionByPost />}
        />

        <Route
          path={`/job-post/:districtName/:districtId`}
          element={<DistrictByPost />}
        />

        <Route path={`/all-categories`} element={<AllJobCategories />} />

        <Route path={`/company/register`} element={<CompanyRegister />} />

        <Route
          path="/applied-job"
          element={<ApplicantRoute element={<AppliedJob />} />}
        />
        <Route
          path="/save-job"
          element={<ApplicantRoute element={<SaveJob />} />}
        />
        <Route
          path="/job-apply/:jobId"
          element={<ApplicantRoute element={<JobApply />} />}
        />
        <Route
          path="/applied-job-details/:jobId"
          element={<ApplicantRoute element={<AppliedJobDetails />} />}
        />
        <Route
          path="/save-job-details/:jobId"
          element={<ApplicantRoute element={<SaveJobDetails />} />}
        />
        <Route
          path="/applicant-profile"
          element={<ApplicantRoute element={<ApplicantProfile />} />}
        />
        <Route
          path="/applicant-profile-update"
          element={<ApplicantRoute element={<ApplicantProfileUpdate />} />}
        />
        <Route
          path="/applicant-education-update"
          element={<ApplicantRoute element={<UpdateApplicantEducation />} />}
        />

        <Route path={`/notification`} element={<JobNotification />} />

        <Route
          path={`/create-applicant-profile`}
          element={<CreateApplicantProfile />}
        />

        <Route
          path="/company/profile"
          element={<CompanyRoute element={<CompanyProfile />} />}
        />
        <Route
          path="/company/update-profile"
          element={<CompanyRoute element={<UpdateCompanyProfile />} />}
        />
        <Route
          path="/company/create-job"
          element={<CompanyRoute element={<CreateNewJob />} />}
        />
        <Route
          path="/company/job-post-preview"
          element={<CompanyRoute element={<JobPostPreview />} />}
        />
        <Route
          path="/company/posted-job"
          element={<CompanyRoute element={<PostedJobs />} />}
        />
        <Route
          path="/company/edit-job-post"
          element={<CompanyRoute element={<EditJobPost />} />}
        />
        <Route
          path="/company/applicant-list/:postId"
          element={<CompanyRoute element={<ApplicantList />} />}
        />
        <Route
          path="/company/applicant-details/:applicantId"
          element={<CompanyRoute element={<ApplicantDetails />} />}
        />
      </Switch>
    </Col>
  );
};

export default MiddleCol;
