import { Link, useLocation } from "react-router-dom";
import { Container, Form, Pagination } from "react-bootstrap";
import FreelancerCard from "./../Common/FreelancerCard";

import "./searchedFreelancer.css";

const freelancer = [
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/y5PzgRy/freelancer1.jpg",
  },
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/wcQMxNk/freelancer2.jpg",
  },
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/ph7c15Z/freelancer3.jpg",
  },
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/wByd9DP/freelancer4.jpg",
  },
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/6r5jH9m/freelancer5.jpg",
  },
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/k4264Lj/freelancer6.jpg",
  },
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/Z2VTXhz/freelancer7.jpg",
  },
  {
    name: "enamul",
    title: "UI/UX Designer",
    persona:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of by the read.",
    image: "https://i.ibb.co/pv86nC1/freelancer8.jpg",
  },
];

function SearchedFreelancer() {
  const { pathname:url } = useLocation();
  const dynamicPath = url.split("/")[1];

  return (
    <Container>
      <div className="searchedFreelancer">
        <div className="searchedFreelancer__switches">
          <span className="searchedFreelancer__count">1.5k Service</span>
          <Form.Check type="switch" id="custom-switch" label="Top Sellers" />
          <Form.Check type="switch" id="custom-switch" label="Local Sellers" />
          <Form.Check type="switch" id="custom-switch" label="Online Sellers" />
        </div>
        <div>
          <div className="searchedFreelancer__list">
            {freelancer.map((person, index) => (
              <Link to={`/${dynamicPath}/user`} key={index}>
                <FreelancerCard person={person} />
              </Link>
            ))}
          </div>

          <Pagination className=" creative__pagination justify-content-center mb-5 mt-4">
            {Array.from({ length: 3 }).map((_, index) => (
              <Pagination.Item key={index}>{index + 1}</Pagination.Item>
            ))}
          </Pagination>
        </div>
      </div>
    </Container>
  );
}
export default SearchedFreelancer;
