import React from "react";
import { useDispatch } from "react-redux";
import { FollowerSingleRow } from "./FollowerSingleRow";

export const Follower = () => {
  const dispatch = useDispatch()

  

  return (
    <div className="container-fluid mt-1 followerContainer px-0">
      <div className="mt-3">
        <span className="fw-bold">Follower</span>
        <span>(500)</span>
      </div>
      <div className="row followerList">
        <FollowerSingleRow />
        <FollowerSingleRow />
        <FollowerSingleRow />
        <FollowerSingleRow />
        <FollowerSingleRow />
        <FollowerSingleRow />
        <FollowerSingleRow />
        <FollowerSingleRow />


        
      
      </div>
    </div>
  );
};
