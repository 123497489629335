import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  Navigate,
  Route,
  Routes as Switch,
  useLocation,
} from "react-router-dom";
import AddProduct from "../AddProduct/AddProduct";
import Dashboard from "../Dashboard/Dashboard";
import ProductList from "../ProductList/ProductList";
import MyStoreNavigation from "./MyStoreNavigation";
import MyStoreSidebar from "./MyStoreSidebar";

const MyStore = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { pathname } = useLocation();
  const path = "/" + pathname.split("/")[1];

  return (
    <Container fluid>
      <h6 className="fs-6 py-3 d-none d-lg-block">My Store</h6>
      <button
        type="button"
        onClick={handleShow}
        className="d-lg-none my-4 eco-btn myStore-sidebar-open-btn"
      >
        <FontAwesomeIcon
          icon={faBars}
          size="lg"
          className="me-2 text-orange"
        ></FontAwesomeIcon>
        My Store
      </button>
      <Row>
        <Col lg="3" xxl="3" className="d-none d-lg-block">
          <MyStoreNavigation boxShadow></MyStoreNavigation>
        </Col>
        <Col lg="9" xxl="9">
          <Switch>
            <Route
              index
              element={<Navigate to={`${path}/my-store/dashboard`} />}
            />

            <Route path={`/dashboard`} element={<Dashboard />} />

            <Route path={`/add-product`} element={<AddProduct />} />

            <Route path={`/product-list`} element={<ProductList />} />
          </Switch>
        </Col>
      </Row>

      <MyStoreSidebar show={show} handleClose={handleClose}></MyStoreSidebar>
      <br />
    </Container>
  );
};

export default MyStore;
