import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import Select from "react-select";
import CreatePlaylistContainer from "../CreatePlaylistContainer";

export const ModalBody3 = ({ handleChange, postData, setPostData }) => {
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [setSelectedVideoCategory, setSetSelectedVideoCategory] =
    useState(null);
  const { ownPlaylist, videoCategories } = useSelector(
    (store) => store.viewData
  );

  const handlePlaylistChange = (event) => {
    setSelectedPlaylist(event);
    const prevData = { ...postData };
    prevData["playlist_id"] = event.value;
    setPostData(prevData);
  };

  const handleVideoCategoryChange = (event) => {
    setSetSelectedVideoCategory(event);
    const prevData = { ...postData };
    prevData["video_category_id"] = event.value;
    setPostData(prevData);
  };

  return (
    <div className="row justify-content-evenly my-4">
      <Form>
        {ownPlaylist && (
          <Form.Group className="mt-2 mt-lg-3 ">
            <Form.Label className="mb-0">Select Playlist</Form.Label>
            <InputGroup>
              <Select
                options={ownPlaylist.map((playlist) => ({
                  value: playlist.id,
                  label: playlist.name,
                }))}
                onChange={handlePlaylistChange}
                value={selectedPlaylist}
                name="playlist_id"
                className="flex-grow-1 text-black"
              />

              <InputGroup.Text className="py-0">
                <CreatePlaylistContainer />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        )}

        {videoCategories && (
          <Form.Group className="mt-2 mt-lg-3 ">
            <Form.Label className="mb-0">Select Video Category</Form.Label>

            <Select
              options={videoCategories.map((category) => ({
                value: category.id,
                label: category.name,
              }))}
              onChange={handleVideoCategoryChange}
              value={setSelectedVideoCategory}
              name="playlist_id"
              className="flex-grow-1 text-black"
            />
          </Form.Group>
        )}

        <Form.Group className="mt-2 mt-lg-3">
          <Form.Label className="mb-0">Video Status</Form.Label>
          <Form.Select
            onChange={handleChange}
            name="video_status"
            value={postData.video_status}
          >
            <option value="public"> Public </option>
            <option value="private"> Private </option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mt-2 mt-lg-3">
          <Form.Label className="mb-0">For Kids?</Form.Label>
          <Form.Select
            onChange={handleChange}
            name="kids"
            value={postData.kids}
          >
            <option value="yes"> Yes, For Kids </option>
            <option value="no"> No, Not for Kids </option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mt-2 mt-lg-3">
          <Form.Label className="mb-0">Age Restricted?</Form.Label>
          <Form.Select
            onChange={handleChange}
            name="restriction"
            value={postData.restriction}
          >
            <option value="18+"> Yes, 18+ </option>
            <option value="18-"> No, Not 18+ </option>
          </Form.Select>
        </Form.Group>

        {/* <div className="col-lg-5 col-sm-6 ">
        <div className="thumbnail div_center">Creat New Playlist</div>
      </div> */}
      </Form>
    </div>
  );
};

const load7 = keyframes`

  0%,
  80%,
  100% {
    box-shadow: 0 1em 0 -1.3em;
  }
  40% {
    box-shadow: 0 1em 0 0;
  }
`;

const Loader = styled.div`
  color: var(--icon-clr);
  font-size: 10px;
  margin: 10px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;

  &,
  &:before,
  &:after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: ${load7} 1.8s infinite ease-in-out;
    animation: ${load7} 1.8s infinite ease-in-out;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
  }
  &:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &:after {
    left: 3.5em;
  }
`;
