import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faTrash } from "@fortawesome/free-solid-svg-icons";
import OrderRequirementModal from "./../Common/Modal/OrderRequirementModal";

import "./notifications.css";

function Notifications() {
  const [showRequirementModal, setShowRequirementModal] = useState(false);
  const { pathname } = useLocation();
  const dynamicPath = pathname.split("/")[1];
  const dynamicUser = pathname.split("/")[2];

  return (
    <div>
      <OrderRequirementModal
        show={showRequirementModal}
        onHide={() => setShowRequirementModal(false)}
      />
      <div className="creative__notifications">
        {pathname.includes("client") && (
          <div className="creative__notifications__item">
            <img
              src="https://i.ibb.co/F8yxsjF/client1.jpg"
              alt=""
              className="creative__notifications__item__img"
            />
            <div>
              <p className="mb-2">
                <span className="fw-500">Freelancer</span>: Ux Ui Project . See
                This And 9 Other Job Recommendationd
              </p>

              <Link
                to={`/${dynamicPath}/client/receivedproject`}
                className="d-inline-block px-3 py-1 border rounded hover-orange-bg"
              >
                Receive Project
              </Link>
            </div>
            <div>
              <p className="mb-1">1d Ago</p>
              <div className="d-flex gap-3 justify-content-end">
                <a href="">
                  <FontAwesomeIcon className="text-orange" icon={faHeart} />
                </a>
                <a href="">
                  <FontAwesomeIcon icon={faTrash} />
                </a>
              </div>
            </div>
          </div>
        )}

        <div className="creative__notifications__item">
          <img
            src="https://i.ibb.co/6gtGkxB/notification-profile.jpg"
            alt=""
            className="creative__notifications__item__img"
          />
          <div>
            <p className="mb-2">
              <span className="fw-500">UVIOM Creative</span>: Long Established
              Fact That A Reader Will Be Distracted By The Readable Content Of A
              Page When Looking At Its Layout. The Point Of Using Lorem Ipsum Is
              That It Has A More-Or-Less Normal Distribution Of Letters
            </p>
          </div>
          <div>
            <p className="mb-1">2d Ago</p>
            <div className="d-flex gap-3 justify-content-end">
              <a href="">
                <FontAwesomeIcon className="text-orange" icon={faHeart} />
              </a>
              <a href="">
                <FontAwesomeIcon icon={faTrash} />
              </a>
            </div>
          </div>
        </div>
        {pathname.includes("freelancer") && (
          <div className="creative__notifications__item">
            <img
              src="https://i.ibb.co/0j3WMk1/chat-Avatar06.jpg"
              alt=""
              className="creative__notifications__item__img"
            />
            <div>
              <p className="mb-2">
                <span className="fw-500">Ying P</span>: Ordered Your Service
              </p>

              <div className="d-flex gap-2 flex-wrap align-items-center">
                <Link
                  to={`/${dynamicPath}/${dynamicUser}/orders/pending`}
                  className="d-inline-block px-3 py-1 border rounded orange-bg hover-transparent-bg"
                >
                  Accept Order
                </Link>
                <div
                  onClick={() => setShowRequirementModal(true)}
                  className="d-inline-block px-3 py-1 border rounded hover-orange-bg"
                >
                  View Requirement
                </div>
              </div>
            </div>
            <div>
              <p className="mb-1">1d Ago</p>
              <div className="d-flex gap-3 justify-content-end">
                <a href="">
                  <FontAwesomeIcon className="text-orange" icon={faHeart} />
                </a>
                <a href="">
                  <FontAwesomeIcon icon={faTrash} />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default Notifications;
