import React from "react";
import { Placeholder } from "react-bootstrap";

const CategorySkeleton = () => {
  return (
    <Placeholder as="div"   animation="wave"  className="category__slider__item-container">
      <Placeholder bg="secondary" className="rounded-1"  xs={7}></Placeholder>
      <Placeholder as="div"  className="categories__slider-item mx-auto mt-2 secondary-bg">
        <Placeholder as="div" animation="wave" className="single-category">
          <span className="w-100 h-100 d-flex justify-content-center align-items-center">
            <span className="category-skeleton-img"></span>
          </span>
          <Placeholder xs="8" bg="secondary" className="rounded-1" size="sm"></Placeholder>
        </Placeholder>
        <Placeholder as="div" animation="wave"  className="single-category">
          <span className="w-100 h-100 d-flex justify-content-center align-items-center">
            <span className="category-skeleton-img"></span>
          </span>
          <Placeholder xs="8"  bg="secondary" className="rounded-1" size="sm"></Placeholder>
        </Placeholder>
        <Placeholder as="div" animation="wave"  className="single-category">
          <span className="w-100 h-100 d-flex justify-content-center align-items-center">
            <span className="category-skeleton-img"></span>
          </span>
          <Placeholder xs="8"  bg="secondary" className="rounded-1" size="sm"></Placeholder>
        </Placeholder>

        <Placeholder as="div" animation="wave"  className="single-category">
          <span className="w-100 h-100 d-flex justify-content-center align-items-center">
            <span className="category-skeleton-img"></span>
          </span>
          <Placeholder xs="8"  bg="secondary" className="rounded-1" size="sm"></Placeholder>
        </Placeholder>

      </Placeholder>
    </Placeholder>
  );
};

export default CategorySkeleton;
