
import React from 'react';

const HomeIcon = ({size="29px",className="",fillcolor}) => {
    return (
        <svg width={size} height={size}  className={className}   viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path  className={className} d="M16.667 40V26.667H26.667V40H36.833V20H43.333L21.667 0L0 20H6.5V40H16.667Z" fill="#F2F2F2" style={{fill:fillcolor}}/>
        </svg>
    );
};

export default HomeIcon;