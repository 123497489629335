import axios from "axios";

export const getInfo = (setIsLoading, Url, setInfo=()=>{}, setError=()=>{}) => {
  setIsLoading(true);

  axios.get(`${Url}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then((res) => {

      if (res.status === 200) {
        const response = res.data;
        setInfo(response);
      } else { }
      setIsLoading(false);

    }).catch((err) => {
      setError(err.message)
      console.log(err.message);
      setIsLoading(false);

    });
}


export const getInfoWithoutResponseStatus = (setIsLoading, Url, setInfo) => {
  setIsLoading(true);
  axios.get(`${Url}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then((res) => {

      const response = res.data;
      setInfo(response);
      setIsLoading(false);

    }).catch((err) => {
      console.log(err.message);
      setIsLoading(false);

    });

}