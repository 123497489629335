import React from "react";

const ThreeDots = ({ size="32px", className ,fillcolor}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={className}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.06666 0.5C3.20805 0.5 4.13333 1.42528 4.13333 2.56667L4.13333 23.2333C4.13333 24.3747 3.20805 25.3 2.06666 25.3C0.925275 25.3 -3.42778e-06 24.3747 -3.37789e-06 23.2333L-2.47452e-06 2.56667C-2.42463e-06 1.42528 0.925276 0.5 2.06666 0.5Z"
        fill="#6984a5" style={{fill:fillcolor}}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5004 6.70035C16.6418 6.70035 17.5671 7.62563 17.5671 8.76701L17.5671 29.4337C17.5671 30.5751 16.6418 31.5003 15.5004 31.5003C14.3591 31.5003 13.4338 30.5751 13.4338 29.4337L13.4338 8.76701C13.4338 7.62563 14.3591 6.70035 15.5004 6.70035Z"
        fill="#F1A350" style={{fill:fillcolor}}
      />
      <path
        className={className}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9335 0.5C30.0749 0.5 31.0002 1.42528 31.0002 2.56667L31.0002 23.2333C31.0002 24.3747 30.0749 25.3 28.9335 25.3C27.7921 25.3 26.8668 24.3747 26.8668 23.2333L26.8668 2.56667C26.8668 1.42528 27.7921 0.5 28.9335 0.5Z"
        fill="#6984a5" style={{fill:fillcolor}}
      />
    </svg>
  );
};

export default ThreeDots;
