import { Link, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import FindFreelancerSidebar from "./../Common/FindFreelancerSidebar";
import image1 from "./../../assets/slide1.png";

import "./findFreelancer.css";

function FindFreelancer() {
  const { pathname } = useLocation();

  return (
    <div className="findFreelancer">
      <Container>
        <div className="findFreelancer__container">
          <FindFreelancerSidebar />

          <div className="findFreelancer__content">
            <div className="py-2 py-md-3" />
            <div className="findFreelancer__search">
              <input
                type="text"
                className="input-reset"
                placeholder="What skills are you looking for ?"
              />
              <input type="submit" value="Search" />
            </div>
            <div className="py-2 py-lg-4"></div>
            <div className="findFreelancer__title">
              Find & Hire
              <br />
              <span>Expert Freelancer</span>
            </div>
            <div className="py-2 py-lg-3"></div>
            <div className="findFreelancer__sub">
              the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever
            </div>
            <div className="findFreelancer__skills">
              <Link className="findFreelancer__skills__link" to={pathname}>
                Web Design
              </Link>
              <Link className="findFreelancer__skills__link" to={pathname}>
                Data Entry
              </Link>
              <Link className="findFreelancer__skills__link" to={pathname}>
                Logo Design
              </Link>
              <Link className="findFreelancer__skills__link" to={pathname}>
                UX Design
              </Link>
            </div>
            <div className="py-3 py-ld-5"></div>

            <img className="findFreelancer__overlay" src={image1} alt="" />
          </div>
        </div>
        <hr className="m-0" />
      </Container>
    </div>
  );
}
export default FindFreelancer;
