/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useApplyJobPostMutation } from "src/app/services/jobApi/jobPostApi";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";

import ApplicantInformation from "../../Components/Common/ApplicantInformation";
import { showToast } from "../../utilities/functions";


const JobApply = () => {
  const { applicant_profile } = useSelector((store) => store.jobProfile);
  const [applyJob, { isLoading, isSuccess, error }] = useApplyJobPostMutation();


  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate("/Job/applied-job");
      showToast({title:"Job Applied Successfully"})
    }
  },[isSuccess])


  const handleApply = () => {
    applyJob(jobId);
  };


  return (
    <div className="secondary-bg pt-2">
      {isLoading && <BubbleLoader />}

      <div className="applicantTitle pt-2">
        <h6>Jop Apply</h6>
      </div>

      <ApplicantInformation data={applicant_profile} />
      <div className="font-14">
        <p className="text-center text-danger">{error}</p>
      </div>
      <div className="text-center">
        <button
          onClick={handleApply}
          className="cart-confirm-btn send-code-btn text-dark"
        >
          Apply Now
        </button>
      </div>
      <br />
    </div>
  );
};

export default JobApply;
