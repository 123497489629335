import React from 'react';
import ProductListItem from '../Common/ProductListItem';
import "../dashboard.css"

const ProductList = () => {
    return (
        <div className=' p-3'>
            <h6 className="fs-5 py-2 text-orange">Product List</h6>
            <ProductListItem/>
            <ProductListItem/>
            <ProductListItem/>
            <ProductListItem/>
            
        </div>
    );
};

export default ProductList;