import {REMOVE_FROM_CART, TOTAL_UPDATE,QUANTITY_ADD, MODEL_TOGGLE, SHOW_HIDE_CART, ADD_TO_CART, REMOVE_ITEM } from "./Types";
const initalState = {
  cartItems: [],
  allTotal: 0,
  total_quantity:0,
}
const CartReducer = (state, action) => {
  switch (action.type) {
    case SHOW_HIDE_CART: {
      return {
        ...state,
        showCart: !state.showCart,
      };
    }
    case MODEL_TOGGLE: {
      return {
        ...state,
        modelToggle: !state.modelToggle,
      };
    }
    case ADD_TO_CART: {
      return {
        ...state,
        
        cartItems: [...state.cartItems, action.payload],
      };
    }
    case REMOVE_FROM_CART: {
      return initalState;
    }
    case REMOVE_ITEM: {
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item) => item.id !== action.payload
        ),
      };
    }
    case TOTAL_UPDATE: {
        
        return {
        ...state,
        allTotal: state.cartItems[0]? state.cartItems.map(item =>  item.eachItemTotal).reduce((prev, next) => prev + next): 0,
        total_quantity: state.cartItems[0]? state.cartItems.map(item =>  item.quantity).reduce((prev, next) => prev + next): 0,
       
      }
    }
    case QUANTITY_ADD: {
      return {
        ...state,
        cartItems: state.cartItems.map(item => item.id === action.payload.id ? {
          ...item, 
          quantity: action.payload.quantity, 
          eachItemTotal:  ((Number(item.sale_price)*action.payload.quantity))
        } : item)

      };
    }
    // addOnsPrice? (((price+addOnsPrice)*1)):((price*1))

    default:
      return state;
  }
};

export default CartReducer;