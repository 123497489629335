import { useState } from "react";
import { useLocation } from "react-router-dom";
import BalanceLayout from "../Common/BalanceLayout";

import CancelOrderModal from "../Common/Modal/CancelOrderModal";
import SuccessModal from "./../Common/Modal/SuccessModal";

import "./pendingBalance.css";

function PendingBalance() {
  const { pathname:url } = useLocation();
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);

  const [showPendingBalanceApprovedModal, setShowPendingBalanceApprovedModal] =
    useState(false);

  return (
    <>
      <CancelOrderModal
        show={showCancelOrderModal}
        onHide={() => setShowCancelOrderModal(false)}
      />

      <SuccessModal
        title="Payment"
        body="Pending Balance Approved"
        show={showPendingBalanceApprovedModal}
        onHide={() => setShowPendingBalanceApprovedModal(false)}
      />

      <BalanceLayout title="Pending Balance">
        <div className="pendingBalance">
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className="pendingBalance__card">
              <img
                className="pendingBalance__card__img"
                src="https://i.ibb.co/F8yxsjF/client1.jpg"
                alt=""
              />
              <div>
                <h6 className="m-0">Freelancer</h6>
                <p className="m-0">T-Mobile,USA</p>
                <p className="m-0">UX UI Design,Work</p>
              </div>
              <div className="text-end">
                <h6 className="">100$</h6>
                <h6 className="">Pending</h6>
                <div className="d-flex gap-2 justify-content-end">
                  {url.includes("freelancer/wallet") ? (
                    <div
                      role="button"
                      onClick={() => setShowPendingBalanceApprovedModal(true)}
                      className="pendingBalance__btn__approve"
                    >
                      Fund Request
                    </div>
                  ) : (
                    <>
                      <div
                        role="button"
                        onClick={() => setShowPendingBalanceApprovedModal(true)}
                        className="pendingBalance__btn__approve"
                      >
                        Approve
                      </div>
                      <div
                        onClick={() => setShowCancelOrderModal(true)}
                        role="button"
                        className="pendingBalance__btn__denied cursor-pointer"
                      >
                        Denied
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </BalanceLayout>
    </>
  );
}
export default PendingBalance;
