import React from "react";
import { Placeholder } from "react-bootstrap";

const CommentSkeleton = ({ reply }) => {
  return (
    <div style={{ marginLeft: `${reply ? "2rem" : "0"}` }}>
      <Placeholder animation="wave">
        <div className="d-flex gap-2 align-items-center">
          <Placeholder
            as="div"
            style={{ ...avatarStyle }}
            xs="12"
          ></Placeholder>
          <div></div>
          <Placeholder as="div" xs="3"></Placeholder>
        </div>
      </Placeholder>
    </div>
  );
};

export default CommentSkeleton;

const avatarStyle = { height: "50px", width: "50px", borderRadius: "50%" };

const imageStyle = {
  borderRadius: ".5rem",
  marginTop: ".7rem",
  marginBottom: ".7rem",
  height: "7rem",
  width: "100%",
  backgroundColor: "var(--bg-secondary)",
};
