import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, Col } from "react-bootstrap";
import ShoppingCartIcon from "src/Projects/Corporate/assets/icons/ShoppingCartIcon";
import useTheme from "src/hooks/useTheme";
import { ProductImg } from "./ProductImg";
import {
  productImgBase_m,
  storeWishlistUrl,
} from "../../../utilities/CommonUrl";
import useAuth from "src/hooks/useAuth";
import useCartStore from "../../../hooks/useCartStore";
import ToastFunc from "src/Common/ToastFunc";
import { isEmpty } from "lodash";
import { SalePrice } from "./SalePrice";
import { rPrice } from "src/utilities/utilities";
import { storeWishlist } from "../apiCalls";
import styled from "styled-components";

const ProductCard = (props) => {
  const { addToCart, state } = useCartStore();
  const { cartItems } = state;

  const { data, removeColClasses, isOffered, isNew, isBest } = props;
  const { id, product_name, sale_price, product_image, slug, stock } = data;

  const { pathname } = useLocation();
  const { authUser } = useAuth();

  // const currentPath = "/" + pathname.split("/")[1];
  const { currentTheme } = useTheme();
  const location = useLocation();

  const navigate = useNavigate();
  const mainUrl = `${pathname.split("/")[1]}`;
  const handleClick = () => {
    navigate(`/${mainUrl}/product-details/${slug}`);
  };

  const [whishListAddLoading, setwhishListAddLoading] = useState(false);
  const addToWhishList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const formArg = {
      formData: { product_id: id },
      Url: storeWishlistUrl,
      setIsLoading: setwhishListAddLoading,
    };
    storeWishlist(formArg);
  };
  const [outOfStock, setoutOfStock] = useState("");

  const addThisProduct = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (outOfStock) {
    } else {
      const sameIdProduct = cartItems.filter(
        (item) => Number(item.id) === Number(data.id)
      );
      if (sameIdProduct.length > 0) {
        ToastFunc("error", "Already on cart");
      } else {
        const itemAllInfo = {
          ...data,
          sale_price: rPrice(data.sale_price),
          quantity: 1,
          eachItemTotal: rPrice(data.sale_price) * 1,
        };
        addToCart(itemAllInfo);
        ToastFunc("success", "Successfully added");
      }
    }
  };

  return (
    <Col
      className={`h-100 ${
        removeColClasses ? "" : "col-xl-2 col-6 col-sm-4 col-md-3 col-lg-3"
      } ${location.pathname.includes("/search-product") ? "col-xl-3" : ""}`}
    >
      <Card
        onClick={handleClick}
        className={`product-card h-100 ${
          currentTheme === "dark" ? "secondary-bg" : ""
        }`}
      >
        <Card.Img as={"div"} className="product__card__img-box bg-white">
          <span className="w-100 h-100 d-flex justify-content-center align-items-center">
            {product_image ? (
              <ProductImg
                product_image={product_image}
                imgBase={productImgBase_m}
              />
            ) : (
              <div className="no_img">No img</div>
            )}
          </span>
        </Card.Img>
        <Card.Body className="p-1 pt-2">
          <div className={`product__card__text-box`}>
            <div className="mb-1 product_nameLine" style={{ height: "2.5rem" }}>
              {product_name && product_name ? product_name : "product name"}
            </div>
          </div>
        </Card.Body>
        <Card.Footer className="border-0">
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0 productStar">
              {/* ({stock && stock}) */}
              <span>
                <span className="product__card-star">&#9733;</span>
                <span className="product__card-star">&#9733;</span>
                <span className="product__card-star">&#9733;</span>
                <span className="product__card-star">&#9733;</span>
                <span className="product__card-star">&#9733;</span> 5.0
              </span>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <SalePrice
                stock={stock}
                outOfStock={outOfStock}
                setoutOfStock={setoutOfStock}
                sale_price={sale_price}
              />
            </div>
            <div>
              <button
                onClick={addThisProduct}
                className={`btn-unset ${outOfStock}?"cursor-unset":"" `}
              >
                <ShoppingCartIcon
                  svgClass="me-2"
                  size="22"
                  className="text-orange"
                />
              </button>
              {!isEmpty(authUser) && Number(authUser.id) && (
                <>
                  {whishListAddLoading ? (
                    <button
                      className={`btn-unset ${outOfStock}? "cursor-unset":"" `}
                      disabled
                    >
                      <FontAwesomeIcon
                        icon={faHeart}
                        className="me-1 text-orange"
                      />
                    </button>
                  ) : (
                    <button
                      onClick={addToWhishList}
                      className={`btn-unset ${outOfStock}? "cursor-unset":"" `}
                    >
                      <FontAwesomeIcon
                        icon={faHeart}
                        className="me-1 text-orange"
                      />
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </Card.Footer>
        {isOffered && <OfferedTag> 30% OFF</OfferedTag>}
        {isNew && <OfferedTag>New Arrivals</OfferedTag>}
        {isBest && <OfferedTag>Top Rated</OfferedTag>}
      </Card>
    </Col>
  );
};

export default ProductCard;

const OfferedTag = styled.span`
  position: absolute;
  background-color: #ff9d00b5;
  color: black;
  padding: 3px 10px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  top: 15px;
  left: 0px;
`;
