import React from 'react';
import "./addresscard.css";

const AddressCard = ({ data }) => {
    const {name, email, phone, address_one, address_two, city, post_code, country} = data
    return (
        <div className='address-card px-2 pt-3 secondary-bg pb-2 '>
            <div className='address-card-item'>
                <p>Name</p>
                <p>{name}</p>
            </div>
            <div className='address-card-item'>
                <p>Email</p>
                <p>{email}</p>
            </div>
            <div className='address-card-item'>
                <p>Phone</p>
                <p>{phone}</p>
            </div>
            <div className='address-card-item'>
                <p>Address 1</p>
                <p>{address_one}</p>
            </div>
            <div className='address-card-item'>
            <p>Address 2</p>
                <p>{address_two}</p>
            </div>
            <div className='address-card-item'>
                <p>City</p>
                <p>{city}</p>
            </div>
            <div className='address-card-item'>
                <p>Post Code</p>
                <p>{post_code}</p>
            </div>
            <div className='address-card-item'>
                <p>Country</p>
                <p>{country}</p>
            </div>
        </div>
    );
};

export default AddressCard;