import React from "react";
import { Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import RestaurantCard from "src/Projects/Food/Components/RestaurantCard/RestaurantCard";

const img1 = "/food-image/restaurant1.png";
const img2 = "/food-image/restaurant2.png";

const HomeRestaurant = () => {
  return (
    <Container fluid className=" mb-5">
      <div className="d-flex justify-content-between primary-text">
        <h6>All Restaurant</h6>
        <NavLink className="primary-text" to="">
          See More
        </NavLink>
      </div>

      <br />
      <Row
        // xs="1"
        // sm="1"
        // md={2}
        // lg="2"
        // xl={3}
        // xxl="3"
        className="home__Product-box g-2"
      >
        <RestaurantCard img={img1} />
        <RestaurantCard img={img2} />
        <RestaurantCard img={img1} />
        <RestaurantCard img={img2} />
        <RestaurantCard img={img1} />
        <RestaurantCard img={img2} />
      </Row>
    </Container>
  );
};

export default HomeRestaurant;
