import JobPost from "./../Common/JobPost";

import "./myPosts.css";

function MyPosts() {
  return (
    <div>
      {Array.from({ length: 5 }).map((_, index) => (
        <JobPost key={index} />
      ))}
    </div>
  );
}
export default MyPosts;
