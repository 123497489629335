import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { storyStatusUpdateUrl } from "src/Projects/Life/Common/CommonUrl";
import postData from "../apiCall/postData";
import "./bestTimeModal.css";
import Loader from "react-js-loader";

const StatusUpdateModal = ({ show, handleClose, data = {} }) => {
  const { status: preStatus, id } = data;

  const defaultValue = {
    family_status: preStatus.family_status === "active",
    friend_status: preStatus.friend_status === "active",
    professional_status: preStatus.professional_status === "active",
    public_status: preStatus.public_status === "active",
  };
  const [status, setStatus] = useState(defaultValue);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    if (name === "public_status" && value) {
      setStatus({
        family_status: true,
        friend_status: true,
        professional_status: true,
        public_status: true,
      });
    } else {
      const prevStatus = { ...status };
      prevStatus[name] = value;
      setStatus(prevStatus);
    }
  };

  const handleCancel = () => {
    setStatus(defaultValue);
    handleClose();
  };
  const callAfterSuccess = (data) => {
    handleClose();
  };

  const handleSubmit = () => {
    const argData = {
      family_status: status.family_status ? "active" : "inactive",
      friend_status: status.friend_status ? "active" : "inactive",
      professional_status: status.professional_status ? "active" : "inactive",
      public_status: status.public_status ? "active" : "inactive",
      video_story_id: id,
    };
    const postDataArg = {
      data: argData,
      url: storyStatusUpdateUrl,
      setIsLoading,
      callAfterSuccess,
    };
    postData(postDataArg);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="bestTime__update-modal"
    >
      {isLoading && (
        <div className={"item bubble-loader-center"}>
          <Loader
            type="bubble-scale"
            bgColor={"#f9a31a"}
            color={"#f9a31a"}
            size={80}
          />
        </div>
      )}
      <Modal.Header>
        <Modal.Title className="text-center">Edit Audience</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="story__status-form">
          <Form.Check
            onChange={handleChange}
            name={"family_status"}
            checked={status.family_status}
            type="checkbox"
            id={`story-status-family`}
            label={`Family`}
          />
          <Form.Check
            onChange={handleChange}
            name={"friend_status"}
            checked={status.friend_status}
            type="checkbox"
            id={`story-status-friend`}
            label={`Friend`}
          />
          <Form.Check
            onChange={handleChange}
            name={"professional_status"}
            checked={status.professional_status}
            type="checkbox"
            id={`story-status-professional`}
            label={`Professional`}
          />
          <Form.Check
            onChange={handleChange}
            name={"public_status"}
            checked={status.public_status}
            type="checkbox"
            id={`story-status-public`}
            label={`Public`}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="danger" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="secondary"
          className="bestTime__update_modal-saveBtn"
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StatusUpdateModal;
