const {
  friendProfilePic,
  familyProfilePic,
  professionalProfilePic,
} = require("src/Projects/Life/Common/CommonUrl");

export default function getProfilePic(profile_images) {
  let profileImage;
  const photo = profile_images;
  if (photo.friend_profile_photo) {
    profileImage = friendProfilePic + photo.friend_profile_photo;
  } else if (photo.family_profile_photo) {
    profileImage = familyProfilePic + photo.family_profile_photo;
  } else if (photo.professional_profile_photo) {
    profileImage = professionalProfilePic + photo.professional_profile_photo;
  } else {
    profileImage = "/user.png";
  }

  return profileImage;
}
