import React from "react";
import { useState } from "react";
import SliderItem from "./SliderItem";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const HomeCategories = () => {
  const [seeMore, setSeeMore] = useState(false);
  const { categories  } = useSelector((store) => store.jobHomeData)
  return (
    <div className="p-2 job-category-slider">
      <p className="text-orange mb-3 ">Job Categories</p>
      <Row className="g-2 border-primary" xs="2" sm="2" md="3" lg="2" xl={3} xxl="4">
        {[...new Array(seeMore ? categories?.length : 12)].map((_, index) => {
          const category = categories[index];
          return <SliderItem  data={category|| {}} key={index} />
        })}
      </Row>
      <div className="text-end mt-3 mb-4">
        <button
          onClick={() => setSeeMore((prev) => !prev)}
          className="send-code-btn "
        >
          {seeMore ? "See Less" : "See More"}
        </button>
      </div>
    </div>
  );
};

export default HomeCategories;
