import React, { createContext } from 'react'
import useCartGlobal from '../../hooks/useCartGlobal';
export const CartContext = createContext();

export const CartStoreProvider = ({ children }) => {
  return (
    <CartContext.Provider value={useCartGlobal()}>
    {children}
  </CartContext.Provider>
  )
}
