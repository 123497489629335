import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { useCloseReportMutation } from "src/app/services/lifeApi/lifeCommonApi";
import Avatar from "src/Projects/Life/Common/Avatar";
import {
  closeReportUrl,
  personalProfilePic,
} from "src/Projects/Life/Common/CommonUrl";
import styled from "styled-components";

const SingleReport = ({ data }) => {
  const [closeReport, { isLoading, data: res_data, error }] =
    useCloseReportMutation();
  const {
    defendant_profile_section: {
      first_name,
      last_name,
      id: user_id,
      photo,
      address,
    },
    post_details: { id: post_id },
    description,
    id,
  } = data;

  const image_url = photo ? personalProfilePic + "/" + photo : "/user.png";

  const addr = address
    ? JSON.parse(address)
    : { current_city: "Dhaka", hometown: "Bangladesh" };

  const handleRemove = (id) => {
    // axios
    //   .post(
    //     closeReportUrl + id,
    //     {},
    //     {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     console.log(res);
    //   });
    // closeReport(id);
  };

  console.log(isLoading, res_data, error);

  return (
    <Wrapper>
      <div className="d-flex gap-2 align-items-center justify-content-between">
        <Link
          to={`/life/public-life/${user_id}`}
          className="d-flex gap-2 align-items-center flex-1"
        >
          <Avatar size="3rem" imgUrl={image_url} className="flex-1" />
          <figure className="mb-0 flex-1 ">
            <span className="fw-600">{first_name + " " + last_name}</span>
            <address className="mb-0 font-14" style={{ width: "20ch" }}>
              {addr.current_city + " " + addr.hometown}
            </address>
          </figure>
        </Link>
        <button
          className="remove_btn flex-1"
          onClick={() => {
            handleRemove(id);
          }}
        >
          Remove
        </button>
      </div>
      <p className="text-center">
        <Link
          to={`/life/post/${post_id}?user_id=${user_id}?timeline=`}
          className="remove_btn mt-3"
        >
          View Post Details
        </Link>
      </p>

      <hr />
      <h6>Your Reported Info:</h6>
      <p>{description}</p>
    </Wrapper>
  );
};
export default SingleReport;
const Wrapper = styled.div`
  border-radius: 5px;
  padding: 0.5rem;
  background-color: var(--bg-primary);
  .remove_btn {
    background-color: var(--bg-secondary);
    border: none;
    padding: 0.2rem 1rem;
    border-radius: 5px;
    transition: 0.2ms ease-in;
    color: var(--text-primary);
    &:hover {
      color: var(--text-success);
      text-decoration: underline;
    }
  }

  address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  figure span:hover {
    text-decoration: underline;
  }
`;
