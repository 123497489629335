import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useGetOwnPlaylistMutation } from "src/app/services/viewApi/viewGetApi";
import { useUpdateVideoPlaylistMutation } from "src/app/services/viewApi/viewPostApi";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";

function EditPlaylistDetailsModal({
  show,
  handleClose,
  playlistId,
  playlistName,
  playlistDesc,
  playlistStatus,
}) {
  const [updateVideoPlaylist, { isLoading, isSuccess }] =
    useUpdateVideoPlaylistMutation();
  const [ownPlayList] = useGetOwnPlaylistMutation();

  const [errorMsg, setErrorMsg] = useState("");
  const [playlistDetails, setPlaylistDetails] = useState({
    name: playlistName,
    description: playlistDesc,
    status: playlistStatus,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const prevData = { ...playlistDetails };
    prevData[name] = value;
    setPlaylistDetails(prevData);
  };

  const handleSubmit = () => {
    setErrorMsg("");
    if (!playlistDetails.name) {
      setErrorMsg("Playlist Name Is Required");
      return;
    } else if (!playlistDetails.description) {
      setErrorMsg("Playlist Description Is Required");
      return;
    }
    updateVideoPlaylist({ playlistId, formData: playlistDetails });
  };

  useEffect(() => {
    if (isSuccess) {
      ownPlayList();
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Modal
      centered
      className="fullScreen viewModal"
      show={show}
      onHide={handleClose}
    >
      {isLoading ? (
        <div className="my-5">
          <DottedCircleSpinner />
        </div>
      ) : (
        <>
          <Modal.Header
            className="stickyParts bg-white opacity-85 z-index-1"
            closeButton
          >
            <div className="modal-title">Edit Playlist</div>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Control
                type="text"
                placeholder="Playlist Name"
                name="name"
                value={playlistDetails.name}
                onChange={handleChange}
              />

              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                className="mt-3"
                value={playlistDetails.description}
                onChange={handleChange}
                placeholder="Playlist Description"
              />

              <Form.Select
                name="status"
                className="mt-3"
                onChange={handleChange}
                value={playlistDetails.status}
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
              </Form.Select>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={handleSubmit}
              className="fileModalButton bg-light text-dark  px-lg-4"
            >
              Update
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}
export default EditPlaylistDetailsModal;
