import HistoryCard from "./../HistoryCard";

function SentHistory() {
  return (
    <div>
      <h3 className="heading-3">Sent History</h3>

      {Array.from({ length: 3 }).map((_, index) => (
        <HistoryCard key={index} />
      ))}
    </div>
  );
}
export default SentHistory;
