import React from 'react'

export const Star = () => {
  return (
    <div>
    <span>
      <span className="product__card-star d-none d-md-inline">&#9733;</span>
      <span className="product__card-star d-none d-md-inline">&#9733;</span>
      <span className="product__card-star d-none d-md-inline">&#9733;</span>
      <span className="product__card-star d-none d-md-inline">&#9733;</span>
      <span className="product__card-star">&#9733;</span> (5.0)
    </span>
    <span className="ms-2">50 Reviews</span>
  </div>
  )
}
