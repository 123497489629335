import { useLocation } from "react-router-dom";

import "./registrationTimeline.css";

function RegistrationTimeline() {
  const { pathname } = useLocation();

  return (
    <div className="registration__container">
      <ul className="registration__timeline">
        <li className="registration__timeline--item active">
          <span />
        </li>
        <li
          className={`registration__timeline--item ${
            pathname.includes("documents") && "active"
          }`}
        >
          <span />
        </li>
        <li
          className={`registration__timeline--item ${
            pathname.includes("verification") && "active"
          }`}
        >
          <span />
        </li>
        <li
          className={`registration__timeline--item ${
            pathname.includes("welcome") && "active"
          }`}
        >
          <span />
        </li>
      </ul>

      <ul className="registration__timeline__text">
        <li className="registration__timeline__text--item active">
          Information
        </li>
        <li
          className={`registration__timeline__text--item ${
            pathname.includes("documents") && "active"
          }`}
        >
          Documentation
        </li>
        <li
          className={`registration__timeline__text--item ${
            pathname.includes("verification") && "active"
          }`}
        >
          Verification
        </li>
        <li
          className={`registration__timeline__text--item ${
            pathname.includes("welcome") && "active"
          }`}
        >
          Congratulation
        </li>
      </ul>
    </div>
  );
}
export default RegistrationTimeline;
