import React from "react";

const CommentIcon = ({ size = "33", className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size - 4}
      viewBox="0 0 33 29"
      fill="none"
      className={className}
    >
      <path
        className={className}
        d="M18.5432 11.5166C17.7298 11.5166 17.0995 12.145 17.0995 12.9209C17.0995 13.6967 17.7298 14.3252 18.5432 14.3252C19.2862 14.3252 19.9165 13.6967 19.9165 12.9209C19.9165 12.145 19.2862 11.5166 18.5432 11.5166ZM8.68349 11.5166C7.87006 11.5166 7.23975 12.145 7.23975 12.9209C7.23975 13.6967 7.87006 14.3252 8.68349 14.3252C9.42649 14.3252 10.0568 13.6967 10.0568 12.9209C10.0568 12.145 9.42649 11.5166 8.68349 11.5166Z"
        fill="#FAFAFA"
      />
      <path
        className={className}
        d="M29.8467 8.84855C28.153 6.53148 25.7866 4.98326 23.1914 4.28463V4.28814C22.5892 3.62111 21.9096 3.00673 21.149 2.45906C15.3846 -1.71868 7.29257 -0.444289 3.08458 5.30274C-0.306463 9.97198 -0.162089 16.2526 3.29586 20.7147L3.32403 25.3699C3.32403 25.4823 3.34164 25.5946 3.37685 25.6999C3.56348 26.2932 4.19732 26.6197 4.78891 26.4337L9.24691 25.0329C10.4266 25.4507 11.6449 25.6894 12.8563 25.7561L12.8387 25.7702C15.9762 28.0486 20.0891 28.7332 23.8604 27.4904L28.3361 28.9438C28.4487 28.9789 28.5649 29 28.6847 29C29.3079 29 29.8115 28.498 29.8115 27.8766V23.1722C32.9138 18.9734 32.9948 13.1702 29.8467 8.84855ZM9.7399 22.5403L9.31734 22.3648L5.83122 23.4531L5.79601 19.802L5.5143 19.486C2.53525 15.8629 2.33806 10.6425 5.12695 6.81234C8.52152 2.17119 15.0289 1.14606 19.6701 4.49527C24.3253 7.86906 25.357 14.3463 21.9941 18.9594C19.1736 22.8176 14.124 24.243 9.7399 22.5403ZM27.5226 21.9435L27.2409 22.2946L27.2761 25.9457L23.8252 24.7872L23.4027 24.9627C21.4307 25.6929 19.3285 25.7526 17.3812 25.2084L17.3742 25.2049C19.9764 24.408 22.3357 22.7931 24.0365 20.469C26.7268 16.7722 27.1635 12.1275 25.6 8.16747L25.6211 8.18151C26.431 8.76078 27.174 9.48399 27.8043 10.3582C30.3608 13.8548 30.2165 18.6153 27.5226 21.9435Z"
        fill="#3D3D3D"
      />
      <path
        className={className}
        d="M13.6142 11.5166C12.8007 11.5166 12.1704 12.145 12.1704 12.9209C12.1704 13.6967 12.8007 14.3252 13.6142 14.3252C14.3572 14.3252 14.9875 13.6967 14.9875 12.9209C14.9875 12.145 14.3572 11.5166 13.6142 11.5166Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};

export default CommentIcon;
