import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const BestTimeVideoPlayer = ({
  url,
  width = "100%",
  height = "100%",
  aspectRatio = "9/16",
  control = false,
  setIsPlaying,
  isPlaying,
  handleVideoEnd = () => {},
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClicked = () => {
    setIsClicked(true);

    setTimeout(() => {
      setIsClicked(false);
    }, 0);
  };

  return (
    <Wrapper
      onClick={() => {
        setIsPlaying(!isPlaying);
        handleClicked();
      }}
      style={{ width: width, height: height, aspectRatio: aspectRatio }}
    >
      <ReactPlayer
        width={"100%"}
        height={"100%"}
        playing={isPlaying}
        url={url}
        // controls={control}
        style={{ color: "white" }}
        onEnded={handleVideoEnd}
      />
      <div className={`playIcon ${isClicked ? "active" : "noActive"} `}>
        {isPlaying ? (
          <FontAwesomeIcon icon={faPlay} />
        ) : (
          <FontAwesomeIcon icon={faPause} />
        )}
      </div>
    </Wrapper>
  );
};

export default BestTimeVideoPlayer;

const Wrapper = styled.div`
  background-color: #333;
  position: relative;

  & .playIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: #fff;
    padding: 1rem;
    border-radius: 50%;
    height: 3.5rem;
    width: 3.5rem;
    text-align: center;
    line-height: 1;
    background-color: #3a3a3a86;
    opacity: 0;
    visibility: hidden;
  }

  & .playIcon.active {
    opacity: 1;
    visibility: visible;
  }

  & .playIcon.noActive {
    transform: translate(-50%, -50%) scale(1.5);
    transition: 0.4s ease-in;
    opacity: 0;
    visibility: hidden;
  }
`;
