import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import useAuth from "src/hooks/useAuth";
import CommentIcon from "src/Projects/Life/icons/CommentIcon";
import ShareIcon from "src/Projects/Life/icons/ShareIcon";
import { getLikeCount } from "src/Projects/Life/LifePages/MainPage/NewsFeedPost/apiCalls";
import { postLikeSubmit } from "src/Projects/Life/LifePages/MainPage/NewsFeedPost/newsFeedPostFunc";

import { ShareActions } from "./Components/ShareActions";

export const Footer = ({ footerArg }) => {
  const { post } = footerArg;
  const { personal_like_status } = post;
  const postMainId = post.id;

  const personalLikeUserId =
    personal_like_status !== null
      ? Number(personal_like_status.user_id)
      : Number(0);

  const [postLikeCount, setpostLikeCount] = useState();
  const [commentCountState, setcommentCountState] = useState();

  const { authUser } = useAuth();
  const [isColored, setisColored] = useState("logoColorText");
  const [colorUpdate, setcolorUpdate] = useState(false);
  const postLikeSubmitArg = {
    setisColored: setisColored,
    setcolorUpdate: setcolorUpdate,
    getLikeCount: getLikeCount,
    setpostLikeCount: setpostLikeCount,
  };
  return (
    <>
      {/* like share comment option  */}
      <div className="col-12  my-2 ">
        <div className="LikeCommentShareParent mt-2">
          <div className="text-center">
            <div
              tabIndex="0"
              aria-labelledby="like"
              className={`LikeCommentsharePart ${
                personalLikeUserId === authUser.id ? isColored : ""
              } ${colorUpdate ? "logoColorText" : ""}`}
              onClick={() => {
                postLikeSubmit(postMainId, postLikeSubmitArg);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  postLikeSubmit(postMainId, postLikeSubmitArg);
                }
              }}
            >
              <div className="LikeCommentIcon ">
                <FontAwesomeIcon icon={faThumbsUp} />
              </div>
              <div className="">
                <small>Like</small>
              </div>
            </div>
            {/* {postLikeCount && postLikeCount !== 0 && (
              <LikedUserList data={likedUserListArg} />
            )} */}
            <div className="smallTextSize mt-1 clickEffect">
              {postLikeCount > 0 ? `${postLikeCount} likes` : null}
            </div>
          </div>
          <div className="text-center ">
            <div tabIndex="0" className="LikeCommentsharePart">
              <div className="LikeCommentIcon">
                <CommentIcon className="primary-text" size="22" />
              </div>
              <div className="">
                <small>Comment</small>
              </div>
            </div>
            <div className={`smallTextSize  mt-1 pointer clickEffect`}>
              {commentCountState > 0 ? `${commentCountState} ` : null}
            </div>
          </div>
          <div className="text-center ">
            <div tabIndex="0" className="LikeCommentsharePart">
              <div className="LikeCommentIcon">
                <ShareIcon size="20" className="primary-text" />
              </div>
              {/* //-------------- */}
              <ShareActions />
            </div>
          </div>
        </div>
      </div>
      {/* <LikedUserModal data={likedUserModalArg} /> */}
      {/* </div> */}
    </>
  );
};
