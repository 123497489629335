import React from "react";
import { Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import FoodCard from "src/Projects/Food/Components/FoodCard/FoodCard";
import useFoodStore from "src/Projects/Food/hooks/useFoodStore";

const HomePopularFood = () => {
  const { products } = useFoodStore();
  return (
    <Container fluid className=" mb-5">
      <div className="d-flex justify-content-between primary-text">
        <h6>Most Popular Deals</h6>
        <NavLink className="primary-text" to="">
          See More
        </NavLink>
      </div>

      <br />
      <Row className="home__Product-box g-2">
        {products.map((item) => (
          <FoodCard key={item.id} data={item} />
        ))}
      </Row>
    </Container>
  );
};

export default HomePopularFood;
