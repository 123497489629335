import React, { useEffect, useRef } from "react";
import { Row, Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import useStore from "src/hooks/useStore";
import LeftCol from "../Components/LeftCol/LeftCol";
import { RightCol } from "../Components/RightCol/RightCol";
import { MiddleCol } from "../Components/MiddleCol/MiddleCol";
import CoverAndProfile from "../Components/CoverAndProfileSection/CoverAndProfile";

import { BubbleScale } from "src/Common/BubbleScale";

const FamilyLife = () => {
  const { isLoading } = useStore();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { logout } = useAuth();
  const { formateGlobalData } = useStore();
  const handleLogout = () => {
    formateGlobalData();
    logout(navigate);
  };
  const myRef = useRef(null);
  useEffect(() => {
    if (myRef.current) {
      // myRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      {isLoading && <BubbleScale extraStyle={{ marginTop: "5rem" }} />}
      {!pathname.includes("/message") &&
        !pathname.includes("/notificaiton") && <CoverAndProfile />}
      <div className="MainPart">
        <div className="mainSectionForLife" ref={myRef}>
          <Container fluid="xxl">
            <Row className="middleContainer">
              {/* left col  */}
              <RightCol handleLogout={handleLogout} />
              {/* middle col  */}
              <MiddleCol />
              {/* right col  */}
              <LeftCol />
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default FamilyLife;
