"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.compare_2_Object = compare_2_Object;
exports.calculate_between_2_date = calculate_between_2_date;
exports.rPrice = void 0;

function compare_2_Object(object1, object2) {
  var keys1 = Object.keys(object1);
  var keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (var _i = 0, _keys = keys1; _i < _keys.length; _i++) {
    var key = _keys[_i];

    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

function calculate_between_2_date(deadline) {
  var currentData = new Date();
  var deadlineDate = new Date(deadline); // To calculate the time difference of two dates

  var Difference_In_Time = deadlineDate.getTime() - currentData.getTime(); // To calculate the no. of days between two dates

  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Math.floor(Difference_In_Days);
}

var rPrice = function rPrice(number) {
  return Math.round(number);
};

exports.rPrice = rPrice;