import { Routes as Switch, Route, useLocation } from "react-router-dom";

import Home from "./pages/Home";

import Safety from "./pages/Safety";
import Coverage from "./pages/Coverage";
import Receipt from "./pages/Receipt";
import Payment from "./pages/Payment";
import Profile from "./pages/Profile";
import AvailableRide from "./pages/AvailableRide";
import RidingHistory from "./pages/RidingHistory";
import JoinAsPartner from "./pages/JoinAsPartner";
import ConfirmRide from "./pages/ConfirmRide";
import FinishRide from "./pages/FinishRide";
import Ride from "./pages/Ride";
import Header from "./components/Header";
import Footer from "./components/Footer";
// using from creative instead of own page
// import About from "./pages/About";
// import Privacy from "./pages/Privacy";
// import Contact from "./pages/Contact";

// same as creative
import About from "./../Creative/pages/About";
import Privacy from "../Creative/pages/Privacy";
import Contact from "../Creative/pages/Contact";

import "./ride.css";

function App() { 

  return (
    <>
      <Header />
      <Switch>
        <Route path="/" element={<Home />} />
        <Route path="/profile/*" element={<Profile />} />
        <Route path="/findride/available" element={<AvailableRide />} />
        <Route path="/findride/confirm" element={<ConfirmRide />} />
        <Route path="/findride/finish" element={<FinishRide />} />
        <Route path="/findride/payment" element={<Payment />} />
        <Route path="/findride" element={<Ride />} />

        <Route path="/history" element={<RidingHistory />} />
        <Route path="/safety" element={<Safety />} />
        <Route path="/coverage" element={<Coverage />} />
        <Route path="/partner/*" element={<JoinAsPartner />} />
        <Route path="/receipt" element={<Receipt />} />

        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
