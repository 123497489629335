import React from "react";

const ChatAvatar = ({ size = "40px", active = false, img }) => {
  const activeCircleStyles = {
    height: "10px",
    width: "10px",
    backgroundColor: active ? "#3CFF8A" : "gray",
    bottom: 2,
    zIndex: 22,
    right: 0,
  };
  return (
    <div>
      <div
        className="position-relative overflow-hidden"
        style={{ height: size, width: size }}
      >
        <img
          src={img}
          onError={(e) => (e.target.src = "https://i.ibb.co/MprdZS6/user.png")}
          style={{ height: size, width: size }}
          className="img img-fluid rounded-circle"
          alt="user"
        />
        <span
          className="person__active-status position-absolute rounded-circle"
          style={activeCircleStyles}
        ></span>
      </div>
    </div>
  );
};

export default ChatAvatar;
