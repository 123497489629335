import React from "react";
import DataTable from "react-data-table-component";
import useTheme from "src/hooks/useTheme";
import { education_columns as columns } from "../../Components/Common/educationTableData";
const ApplicantEducation = ({ educations }) => {
  const { currentTheme } = useTheme();
  return (
    <div className="applicantProfile p-2 p-lg-3 p-xl-2">
      {educations.length > 0 && (
        <>
          <DataTable
            columns={columns}
            data={educations}
            responsive
            theme={currentTheme}
            pointerOnHover
          />
        </>
      )}
    </div>
  );
};

export default ApplicantEducation;
