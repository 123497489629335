import React from "react";
import CompanySingleInfo from "./CompanySingleInfo";

const JobInformation = ({ data, isPreview = false }) => {
  const {
    category_name,
    company_job_category,
    // sub_category_name,
    job_type,
    job_address,
    job_details,
    job_requirements,
    job_title,
    range_end,
    range_start,
    role_responsibility,
    deadline,
    job_category_name,
    division_name,
    district_name,
  } = data;

  return (
    <>
      <div className="job-information">
        <CompanySingleInfo
          title="Job Category"
          value={category_name || job_category_name || company_job_category?.title || "N/A"}
        />
        <CompanySingleInfo title="Job Type" value={job_type} />
        <CompanySingleInfo title="Job Title" value={job_title} />
        <CompanySingleInfo
          title="Salary Range"
          value={` ৳${range_start} - ৳${range_end}`}
        />
        {isPreview && (
          <>
            <CompanySingleInfo title="Division" value={division_name} />
            <CompanySingleInfo title="District" value={district_name} />
            <CompanySingleInfo title="Address" value={job_address} />
          </>
        )}
        <CompanySingleInfo title="Deadline" value={deadline} />
        <CompanySingleInfo title="Job Details" value={job_details} />
        <CompanySingleInfo
          title="Role Responsibility"
          value={role_responsibility}
        />
        <CompanySingleInfo title="Job Requirements" value={job_requirements} />
      </div>
    </>
  );
};

export default JobInformation;
