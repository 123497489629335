import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  getMetaImage,
  getMetaTitle,
} from "src/Projects/Life/Components/LinkPreview/link_preview_functions";
import styled from "styled-components";

const MessageUrlPreview = ({ url, content }) => {
  const [state, setState] = useState("");
  const [error, setError] = useState(null);
  const { image, title, host } = state;

  useEffect(() => {
    try {
      const host = new URL(url).host;
      axios
        .get(`https://api.allorigins.win/get?url=${encodeURIComponent(url)}`)
        .then((res) => {
          const doc = res?.data.contents;
          var html = new DOMParser().parseFromString(doc, "text/html").head;
          const image = getMetaImage(html);
          const title = getMetaTitle(html);
          setState({ image, title, host });
        })
        .catch((err) => {
          setError(err.message);
        });
    } catch (err) {
      setError(err);
    }
  }, [url]);

  if (error || !title) {
    return null;
  }

  return (
    <>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Wrapper>
          <div className="original_url">
            <p>{content}</p>
          </div>
          <div className="preview_image-box">
            <img src={image} alt={title} />
          </div>
          <div className="preview_text_box">
            <p>{title}</p>
            <cite>{host}</cite>
          </div>
        </Wrapper>
      </a>
    </>
  );
};

export default MessageUrlPreview;

const Wrapper = styled.div`
  .original_url {
    word-wrap: break-word;
    background-color: var(--text-success);
    padding: 0.625rem;
    color: white;
  }
  .preview_text_box p {
    display: inline;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  .preview_text_box {
    padding: 0.625rem;
  }
  img {
    width: 100%;
    /* aspect-ratio: 1/1; */
  }

  cite {
    font-weight: 400 !important;
  }
`;
