import { useEffect, useState } from 'react'
import { getInfoWithoutResponseStatus } from '../Components/Common/apiCalls';
import { navCategoryUrl } from '../utilities/CommonUrl';

const useUbizzGlobal = () => {
const [ubizzGlobalLoading, setubizzGlobalLoading] = useState(true)


  const [isLoading, setIsLoading] = useState(true);
  const [categoriesInfo, setcategoriesInfo] = useState([])
  const [productInfo, setproductInfo] = useState([])

  const homePageGetCall = () => {
    // setIsLoading(true)
    getInfoWithoutResponseStatus(navCategoryUrl, setcategoriesInfo,setubizzGlobalLoading)
    // getInfoWithoutResponseStatus(productUrl, setcategoriesInfo,setIsLoading)

  }
  useEffect(() => {
    homePageGetCall()
  }, [])





  return {
    isLoading,
    setIsLoading,
    categoriesInfo,
    ubizzGlobalLoading,
    setubizzGlobalLoading
  };
}
export default useUbizzGlobal;
