import { Container, Row, Col } from "react-bootstrap";

function About() {
  return (
    <div id="wrapper">
      <Container>
        <Row className="justify-content-md-center" >
          <Col md={8}>
            <div className="py-2 py-md-3" />
            <h4 className="text-center">About Us</h4>
            <p className="mx-auto text-center mt-2 mb-5">
              We are UVIOM. A startup TechLab company, first of its kind to
              bolster the technological needs worldwide. Our goal is to improve
              the lives and career outputs of impoverished nations and get them
              to a starting point of technology in the 21st century.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default About;
