import { useLocation } from "react-router-dom";
import IntersectionWithRider from "../components/IntersectionWithRider";
import FindRide from "../components/FindRide";
import overlay from "./../assets/overlay.png";

function AvailableRide() {
  const { pathname:url } = useLocation();
  const dynamicPath = url.split("/")[1];

  return (
    <div id="wrapper">
      <div>
        <iframe
          className="rideMap"
          title="rideMap"
          src="https://maps.google.com/maps?output=embed&ll=54.868705,-1.593018&z=11"
          width="100%"
        ></iframe>
      </div>

      <FindRide rideFrom="Mirpur 1 Bus Stand" rideTo="Dhanmondi 32" />

      <IntersectionWithRider
        nextRoute={`/${dynamicPath}/findride/confirm`}
        nextRouteText="Send Ride Request"
      />
      <div className="py-4 py-md-5" />
    </div>
  );
}
export default AvailableRide;
