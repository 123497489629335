import { Navigate, useLocation } from "react-router-dom";
import useAuth from "src/hooks/useAuth";

export function PrivateRoute({ children }) {
  const { authUser } = useAuth();
  const {pathname} = useLocation()

  if (!authUser.id) {
    return <Navigate to="/login" replace state={{from: pathname}} />;
  } else {
    return children;
  }
}
