import { faThumbsDown, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import {
  useDeleteCommentMutation,
  useUpdateCommentLikeStatusMutation,
} from "src/app/services/viewApi/viewPostApi";
import useAuth from "src/hooks/useAuth";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import { timeAgo } from "src/utilities/utilities";
import styled from "styled-components";
import { channelLogoImg } from "../../common/view_commonUrl";
import UpdateComment from "./UpdateComment";

export const SingleCommentReply = ({
  comment,
  createdAt,
  userName,
  userPhoto,
  commentLiked,
  isLiked,
  commentId,
  userId,
  parentId,
}) => {
  const [showCommentEdit, setShowCommentEdit] = useState(false);

  const [commentLikeStatus] = useUpdateCommentLikeStatusMutation();
  const [deleteComment] = useDeleteCommentMutation();
  const { authUser } = useAuth();

  const getTimeAgo = useMemo(() => timeAgo(createdAt), [createdAt]);

  return (
    <>
      {showCommentEdit ? (
        <UpdateComment
          id={commentId}
          comment={comment}
          parentId={parentId}
          reply
          onSubmit={() => setShowCommentEdit(false)}
        />
      ) : (
        <SingleCommentReplyWrapper>
          <img
            className="avatar"
            src={
              userPhoto
                ? channelLogoImg + userPhoto
                : "https://i.ibb.co/MprdZS6/user.png"
            }
            alt=""
          />

          <div className="comment__container">
            <div className="d-flex text-center">
              <div className="me-2 fw-500"> {userName}</div>
              <div className="mediumTextSize text-gray">{getTimeAgo}</div>
            </div>

            <p className="comment">{comment}</p>

            <div className="mt-1 text-gray">
              <div
                role="button"
                onClick={() => {
                  commentLikeStatus({
                    id: commentId,
                    type: "reply_comment",
                    parentId,
                  });
                }}
                className="me-3"
              >
                <FontAwesomeIcon icon={faThumbsUp} className="me-1" />
                {commentLiked} {parseInt(isLiked) ? "Liked" : "Like"}
              </div>
            </div>
          </div>
          {parseInt(userId) === authUser.id && (
            <div className="ms-auto">
              <Dropdown>
                <Dropdown.Toggle
                  aria-labelledby="toggle button"
                  className="ToggleDesignClear"
                >
                  <ThreeDots className="topNavIcon" size="15" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => setShowCommentEdit(!showCommentEdit)}
                  >
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      deleteComment({
                        commentId,
                        type: "comment_reply",
                        parentId: parentId,
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </SingleCommentReplyWrapper>
      )}
    </>
  );
};

const SingleCommentReplyWrapper = styled.div`
  margin-left: 2.5rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-block: 0.7rem;

  & > .avatar {
    --size: 2.5rem;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
  }

  & .comment {
    margin: 0;
  }

  & > .comment__container {
    /* display: flex; */
  }
`;
