import axios from 'axios';
import ToastFunc from 'src/Common/ToastFunc';
import Swal from "sweetalert2";
import {
    fmAllFriendsUrl, fmFindFnUrl, fmPendingListUrl, fmRequestedListUrl, fnAllFriendsUrl, pAllFriendsUrl, fnPendingListUrl,
    pPendingListUrl,
    fnFindFnUrl,
    pFindFnUrl,
    fnRequestedListUrl,
    pRequestedListUrl
} from "../../../../Common/CommonUrl";


export const AllFriendGetFunc = (FriendFamilyListArg) => {
    const { setIsLoading, currentPage, setuserList, setuserListStore, setbuttonRowVisible, location } = FriendFamilyListArg
    setIsLoading(true);
    let tempUrl = "";
    if (location.pathname.includes("/family-life")) {
        tempUrl = fmAllFriendsUrl;
    } else if (location.pathname.includes("/friends-life")) {
        tempUrl = fnAllFriendsUrl;
    } else if (location.pathname.includes("/professional-life")) {
        tempUrl = pAllFriendsUrl;
    }
    axios.get(`${tempUrl}?page=${currentPage}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    })
        .then((res) => {
            let next_page = res.data.links.next;
            let responseData = res.data.data;
// console.log(responseData);
            if (next_page === null) {
                setbuttonRowVisible(false); //kom hole next button hide kora must
            } else {
                setbuttonRowVisible(true);
            }
            // let dataObject = []
            // if (responseData.length > 0) {
            //     responseData.map((item) => {
            //         dataObject.push(item[0])
            //     })
            // }
            setuserList(responseData);
            setuserListStore(responseData)
            setIsLoading(false);
        }).catch((err) => {
            console.log(err.message);
            setIsLoading(false);
          ToastFunc('error', 'something went wrong !')


        });
}
export const PendingFriendsGet = (PendingFriendsGetArg) => {
    const { setIsLoading,
        currentPage,
        setuserList,
        setuserListStore,
        setbuttonRowVisible,
        location, } = PendingFriendsGetArg;
    setIsLoading(true);

    let tempUrl = "";
    if (location.pathname.includes("/family-life")) {
        tempUrl = fmPendingListUrl;
    } else if (location.pathname.includes("/friends-life")) {
        tempUrl = fnPendingListUrl;
    } else if (location.pathname.includes("/professional-life")) {
        tempUrl = pPendingListUrl;
    }
    axios.get(`${tempUrl}?page=${currentPage}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    })
        .then((res) => {
            let next_page = res.data.links.next;
            let responseData = res.data.data;

            if (next_page === null) {
                setbuttonRowVisible(false); //kom hole next button hide kora must
            } else {
                setbuttonRowVisible(true);
            }

            setuserList(responseData);
            setuserListStore(responseData)
            setIsLoading(false);
        }).catch((err) => {
            console.log(err.message);
            setIsLoading(false);
           
          ToastFunc('error', 'something went wrong !')


        });
}
export const findFriends = (findFriendsArg) => {
    const { setIsLoading, currentPage, setuserList, setuserListStore, setbuttonRowVisible,location } = findFriendsArg

    setIsLoading(true);

    let tempUrl = "";
    if (location.pathname.includes("/family-life")) {
        tempUrl = fmFindFnUrl;
    } else if (location.pathname.includes("/friends-life")) {
        tempUrl = fnFindFnUrl;
    } else if (location.pathname.includes("/professional-life")) {
        tempUrl = pFindFnUrl;
    }
    axios.get(`${tempUrl}?page=${currentPage}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    })
        .then((res) => {

            let next_page = res.data.next_page_url;
            let responseData = res.data.data;

            if (next_page === null) {
                setbuttonRowVisible(false); //kom hole next button hide kora must
            } else {
                setbuttonRowVisible(true);
            }
            setuserList(responseData);
            setuserListStore(responseData)
            setIsLoading(false);

        }).catch((err) => {
            console.log(err.message);
            setIsLoading(false);
            ToastFunc('error', 'something went wrong !')


        });
}
// ---------------
export const RequestedFriendsGet = (RequestedFriendsArg) => {
    const { setIsLoading, currentPage, setuserList, setuserListStore, setbuttonRowVisible,location } = RequestedFriendsArg
    setIsLoading(true);
    let tempUrl = "";
    if (location.pathname.includes("/family-life")) {
        tempUrl = fmRequestedListUrl;
    } else if (location.pathname.includes("/friends-life")) {
        tempUrl = fnRequestedListUrl;
    } else if (location.pathname.includes("/professional-life")) {
        tempUrl = pRequestedListUrl;
    }
    axios.get(`${tempUrl}?page=${currentPage}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    })
        .then((res) => {
            let next_page = res.data.links.next;
            let responseData = res.data.data;
            if (next_page === null) {
                setbuttonRowVisible(false); //kom hole next button hide kora must
            } else {
                setbuttonRowVisible(true);
            }
            setuserList(responseData);
            setuserListStore(responseData)
            setIsLoading(false);
        }).catch((err) => {
            console.log(err.message);
            setIsLoading(false);
            ToastFunc('error', 'something went wrong !')


        });
}
