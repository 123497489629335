import axios from "axios";
import Swal from "sweetalert2";
import {
  postLikeCountUrl,
  postLikeURL,
} from "src/Projects/Life/Common/CommonUrl";

export const postLikeSubmit = (postId, postLikeSubmitArg) => {
  const ToastFunc = (icon, title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: icon,
      title: title,
    });
  };
  const { setisColored, setcolorUpdate, getLikeCount, setpostLikeCount } =
    postLikeSubmitArg;
  axios
    .post(`${postLikeURL}/${postId}`, "", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      if (res.data.status === 1) {
        if (res.data.message === "Post Liked successfully") {
          setisColored("logoColorText");
          setcolorUpdate(true);
          getLikeCount(postLikeCountUrl, postId, setpostLikeCount);
        } else {
          setisColored("");
          setcolorUpdate(false);
          getLikeCount(postLikeCountUrl, postId, setpostLikeCount);
        }
      }
    })
    .catch((err) => {
      ToastFunc("error", "something went wrong");
    });
};
