import axios from "axios";
import ToastFunc from "src/Common/ToastFunc";

export const getInfoWithoutResponseStatus = (Url, setInfo, setIsLoading) => {
  axios.get(`${Url}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then((res) => {
      const response = res.data;
      setInfo(response);
      if (setIsLoading) {
        setIsLoading(false)
      }
    }).catch((err) => {
      console.log(err.message);
      if (setIsLoading) {
        setIsLoading(false)
      }
    });

}
export const storeWishlist = (formArg) => {
  const {
    formData,
    Url,
    setIsLoading,
  } = formArg;
  setIsLoading(true);
  axios
    .post(`${Url}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      if (res.data.status === 1) {
        setIsLoading(false);
        ToastFunc('success', 'Successfully added')
      } else {
        console.log("err");
        setIsLoading(false);
        ToastFunc('error', 'Adding failed')
      }
    })
    .catch((err) => {
      console.log(err);
    });

  setIsLoading(false);
};