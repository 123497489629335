import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentVideoComments } from "src/features/view/viewDataSlice";
import styled from "styled-components";
import CommentSkeleton from "../../common/CommentSkeleton";
import { SingleComment } from "./SingleComment";

function VideoComments({ videoId }) {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const {
    currentVideoComments: { data: comments, has_more },
    currentVideo,
  } = useSelector((store) => store.viewData);

  useEffect(() => {
    dispatch(getCurrentVideoComments({ videoId, currentPage }));
  }, [dispatch, videoId, currentPage]);

  return (
    <>
      {parseInt(currentVideo.comments_count) >= 1 && !comments.length >= 1 && (
        <CommentSkeleton />
      )}

      {comments &&
        comments.map((comment) => (
          <SingleComment
            key={comment.id}
            id={comment.id}
            comment={comment.comment}
            commentLiked={comment.comment_likes_count} //
            userName={comment.channel_profile_section.channel_name}
            userId={comment.channel_profile_section.user_id} //
            userPhoto={comment.channel_profile_section.photo}
            createdAt={comment.created_at}
            replyCount={comment.replies_count}
            isLiked={comment.personal_comment_like_status_count} //
          />
        ))}

      {has_more && (
        <HasMore role="button" onClick={() => setCurrentPage(currentPage + 1)}>
          Load More
        </HasMore>
      )}
    </>
  );
}

export default VideoComments;

const HasMore = styled.div`
  font-size: 80%;
  margin-top: 0.3rem;
  display: inline-block;
  border-radius: 3rem;
  padding: 0.1rem 0.5rem;
  background-color: #6984a524;
  border: 1px solid #6984a5fd;

  & > svg {
    margin-right: 0.2rem;
  }
`;
