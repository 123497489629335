import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import useAuth from "src/hooks/useAuth";
import useCartStore from "src/Projects/Corporate/hooks/useCartStore";
import { OtpSubmit } from "../cartApiCalls";
import Conformation from "../Conformation/Conformation";

function CustomToggle({ children, eventKey, disabled }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {});
  return (
    <fieldset
      style={{ cursor: disabled && "not-allowed" }}
      disabled={disabled}
      type="button"
      className="checkout-collapse-btn"
      onClick={decoratedOnClick}
    >
      {children}
    </fieldset>
  );
}
export const ConformationAccordion = ({
  conformationBtnRef,
  payment_method_status,
  conformation_status,
  pin,
  show_pin_input,
  sent_method,
  handleConformationSubmit,
  setState,
}) => {
  const { authUser } = useAuth();
  const { shippingAddress } = useCartStore();

  const handleSendMethodChange = (sentMethod) => {
    setState((prevState) => ({ ...prevState, sent_method: sentMethod }));
  };
    const handlePinChange = (pinCode) => {
        let pin = pinCode.trim(); // remove the white space
        setState((prevState) => ({ ...prevState, pin: pin }));
      };
      const handleSendCode = () => {
        // setState((prevState) => ({ ...prevState, show_pin_input: true }));
        if (authUser.id) {
          const formData = {
            name: `${authUser.first_name} ${authUser.last_name}`,
            phone: authUser.phone? authUser.phone : shippingAddress.shipping_phone,
            email: authUser.email,
            address: authUser.address ? authUser.address : shippingAddress.shipping_address1,
          };
    
          OtpSubmit(formData, setState,authUser.email);
        }
      };
  return (
    <Accordion
      className="shipping-address my-2"
      id="shipping-and-billing-accordion"
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header ref={conformationBtnRef}>
          <CustomToggle disabled={!payment_method_status}>
            <span style={{ color: conformation_status && "#f9a31a" }}>
              Conformation
            </span>
            <FontAwesomeIcon
              style={{ color: conformation_status && "#f9a31a" }}
              icon={faCheckCircle}
            />
          </CustomToggle>
        </Accordion.Header>
        <Accordion.Body>
          <Conformation
            pin={pin}
            handlePinChange={handlePinChange}
            handleSendCode={handleSendCode}
            handleSendMethodChange={handleSendMethodChange}
            show_pin_input={show_pin_input}
            sent_method={sent_method}
            handleConformationSubmit={handleConformationSubmit}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
