import { api } from "./../api";

import {
  doCommentOnVideoUrl,
  doCommentOnReplyUrl,
  updateThisCommentUrl,
  updateThisReplyCommentUrl,
  deleteThisCommentUrl,
  deleteThisReplyCommentUrl,
  likeThisCommentUrl,
  likeThisReplyCommentUrl,
  likeThisVideoUrl,
  saveThisVideoUrl,
  toggleFollowUrl,
  updateChannelProfileInfoUrl,
  updateChannelProfilePhotoUrl,
  updateChannelBannerPhotoUrl,
  createVideoPostUrl,
  deleteVideoPostUrl,
  createVideoPlaylistUrl,
  updateVideoPlaylistUrl,
  searchUrl,
  createVideoReportUrl,
} from "src/Projects/View/common/view_commonUrl";
import {
  toggleChannelFollow,
  updateLikeStatus,
  updateSaveStatus,
  updateCommentLikeStatus,
  addComment,
  updateComment,
  deleteComment,
  channelProfileUpdate,
  deleteVideo,
  searchedValue,
  updatePlaylist,
} from "src/features/view/viewDataSlice";

export const viewApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchVideo: builder.mutation({
      query: ({ query, currentPage }) => ({
        url: searchUrl + "?page=" + currentPage,
        method: "POST",
        body: { query: query },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(searchedValue(data));
        } catch (err) {
          console.error(err);
        }
      },
    }),

    toggleChannelFollow: builder.mutation({
      query: (id) => ({
        url: toggleFollowUrl + id,
        method: "POST",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        dispatch(toggleChannelFollow(args));

        try {
          const { data } = await queryFulfilled;
        } catch {
          // if fail then toggle back
          dispatch(toggleChannelFollow());
        }
      },
    }),

    doComment: builder.mutation({
      query: ({ id, type = "comment", comment }) => {
        // comment_reply/comment
        let commentUrl;
        if (type === "comment_reply") {
          commentUrl = doCommentOnReplyUrl + id;
        } else {
          commentUrl = doCommentOnVideoUrl + id;
        }

        return {
          url: commentUrl,
          method: "POST",
          body: comment,
        };
      },

      async onQueryStarted(action, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.status === 1 || data.status === 0) {
            dispatch(addComment({ payload: data, type: action.type }));
          }
        } catch {}
      },
    }),

    updateComment: builder.mutation({
      query: ({ id, type = "comment", comment, parentId }) => {
        // comment_reply/comment
        let commentUrl;
        if (type === "comment_reply") {
          commentUrl = updateThisReplyCommentUrl + id;
        } else {
          commentUrl = updateThisCommentUrl + id;
        }
        return {
          url: commentUrl,
          method: "POST",
          body: comment,
        };
      },

      async onQueryStarted(action, { dispatch, queryFulfilled }) {
        dispatch(
          updateComment({
            id: action.id,
            type: action.type,
            comment: action.comment,
            parentId: action.parentId,
          })
        );
      },
    }),

    updateCommentLikeStatus: builder.mutation({
      query: ({ id, type, parentId }) => {
        let likeUrl;
        if (type === "comment") {
          likeUrl = likeThisCommentUrl;
        } else if (type === "reply_comment") {
          likeUrl = likeThisReplyCommentUrl;
        } else {
          throw Error("Pass an Type in comment like status");
        }

        return {
          url: likeUrl + id,
          method: "POST",
        };
      },
      async onQueryStarted(action, { dispatch, queryFulfilled }) {
        dispatch(
          updateCommentLikeStatus({
            id: action.id,
            parentId: action.parentId,
            type: action.type,
          })
        );
        try {
          const { data } = await queryFulfilled;
        } catch {
          // if fail then toggle back
          dispatch(
            updateCommentLikeStatus({
              id: action.id,
              parentId: action.parentId,
              type: action.type,
            })
          );
        }
      },
    }),

    deleteComment: builder.mutation({
      query: ({ commentId, type = "comment", parentId }) => {
        let commentUrl;
        if (type === "comment_reply") {
          commentUrl = deleteThisReplyCommentUrl + commentId;
        } else {
          commentUrl = deleteThisCommentUrl + commentId;
        }

        return {
          url: commentUrl,
          method: "POST",
        };
      },
      async onQueryStarted(action, { dispatch, queryFulfilled }) {
        dispatch(
          deleteComment({
            type: action.type,
            commentId: action.commentId,
            parentId: action.parentId,
          })
        );
      },
    }),

    updateVideoLikeStatus: builder.mutation({
      query: (postId) => ({
        url: likeThisVideoUrl + postId,
        method: "POST",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        dispatch(updateLikeStatus());
        try {
          const { data } = await queryFulfilled;
        } catch {
          // if fail toggle back
          dispatch(updateLikeStatus());
          throw Error("Update Like Went Wrong Check Me Again");
        }
      },
    }),

    updateSaveStatus: builder.mutation({
      query: (postId) => ({
        url: saveThisVideoUrl + postId,
        method: "POST",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        dispatch(updateSaveStatus());
        try {
          const { data } = await queryFulfilled;
        } catch {
          // if fail then toggle back
          dispatch(updateSaveStatus());
        }
      },
    }),

    // Update Profile info
    updateProfileInfo: builder.mutation({
      query: (formData) => {
        return {
          url: updateChannelProfileInfoUrl,
          method: "POST",
          body: formData,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        dispatch(channelProfileUpdate(args));
      },
    }),
    createVideoPlaylist: builder.mutation({
      query: (formData) => {
        return {
          url: createVideoPlaylistUrl,
          method: "POST",
          body: formData,
        };
      },
    }),

    updateVideoPlaylist: builder.mutation({
      query: ({ playlistId, formData }) => {
        return {
          url: updateVideoPlaylistUrl + playlistId,
          method: "POST",
          body: formData,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        dispatch(updatePlaylist(args));
      },
    }),

    createVideoPost: builder.mutation({
      query: (formData) => {
        return {
          url: createVideoPostUrl,
          method: "POST",
          body: formData,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (err) {
          console.log(err);
        }
      },
    }),

    // TODO: shift this function into viewGetApi for sake of consistency
    deleteVideoPost: builder.mutation({
      query: (videoId) => {
        return {
          url: deleteVideoPostUrl + videoId,
          method: "GET",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        dispatch(deleteVideo(args));
      },
    }),
  }),
});

export const {
  useSearchVideoMutation,
  useToggleChannelFollowMutation,
  useUpdateVideoLikeStatusMutation,
  useUpdateSaveStatusMutation,
  useDoCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  useUpdateCommentLikeStatusMutation,
  useUpdateProfileInfoMutation,
  useCreateVideoPostMutation,
  useCreateVideoPlaylistMutation,
  useUpdateVideoPlaylistMutation,
  useDeleteVideoPostMutation,
} = viewApi;
