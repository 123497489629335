import React from "react";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect } from "react";
import FloatJobCard from "../../Components/Common/FloatJobCard";
import { Row } from "react-bootstrap";
import { useGetAppliedJobPostQuery } from "src/app/services/jobApi/jobGetApi";
import JobSkeleton from "../../Components/Placeholder/JobSkeleton";

const AppliedJob = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postList, setPostList] = useState([]);

  const {
    data: result,
    isFetching,
    error,
  } = useGetAppliedJobPostQuery(currentPage);
  const { data = [], hasMore } = result || {};

  useEffect(() => {
    if (data) {
      setPostList((prevState) => [...prevState, ...data]);
    }
  }, [data]);

  return (
    <div className="secondary-bg p-2">
      <h6 className="jobApplyTitle ms-1">Applied Jobs</h6>
      <InfiniteScroll
        dataLength={postList.length} //This is important field to render the next data
        next={() => {
          setCurrentPage(currentPage + 1);
        }}
        hasMore={hasMore}
      >
        {postList.length < 1 && !error && !isFetching && (
          <p className="text-center mt-4"> 0 item Found </p>
        )}
        {isFetching && (
          <Row className="g-3" xxl="2">
            {[...new Array(6)].map((_, index) => (
              <JobSkeleton key={index} />
            ))}
          </Row>
        )}

        <Row xs={1} xxl="2" className="g-3">
          {postList.map((item) => {
            if (item.company_job_section && item.company_profile_section) {
              return <FloatJobCard key={item.id} data={item} />;
            } else {
              return null;
            }
          })}
        </Row>
        {error && <p className="text-danger">{error.error}</p>}
      </InfiniteScroll>
      <br />
    </div>
  );
};

export default AppliedJob;
