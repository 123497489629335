import React from "react";

const ShareIcon = ({ size = "15", className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 15 16"
      fill="none"
      className={className}
    >
      <path
        className={className}
        d="M10.3126 2.38021C10.3125 1.83039 10.5058 1.29804 10.8586 0.876311C11.2115 0.454585 11.7014 0.170334 12.2426 0.0732918C12.7839 -0.0237506 13.3421 0.0725947 13.8195 0.345471C14.2969 0.618348 14.6631 1.05038 14.8541 1.56598C15.0451 2.08158 15.0487 2.64791 14.8642 3.16589C14.6798 3.68387 14.3191 4.12052 13.8452 4.39944C13.3713 4.67836 12.8144 4.78179 12.272 4.69163C11.7295 4.60147 11.236 4.32346 10.8779 3.90625L4.57986 6.83084C4.72465 7.28963 4.72465 7.78186 4.57986 8.24064L10.8779 11.1652C11.2565 10.725 11.7851 10.4409 12.3612 10.3681C12.9373 10.2954 13.5199 10.439 13.9961 10.7713C14.4723 11.1035 14.8083 11.6007 14.9388 12.1665C15.0692 12.7322 14.985 13.3263 14.7024 13.8335C14.4198 14.3408 13.959 14.725 13.4092 14.9119C12.8594 15.0987 12.2598 15.0748 11.7266 14.8448C11.1934 14.6148 10.7646 14.1951 10.5232 13.667C10.2819 13.1388 10.2453 12.54 10.4204 11.9864L4.12237 9.06178C3.81059 9.42507 3.39502 9.68419 2.93156 9.80425C2.46809 9.92432 1.97896 9.89959 1.52998 9.73338C1.08099 9.56717 0.693683 9.26747 0.420155 8.87457C0.146626 8.48168 0 8.01445 0 7.53574C0 7.05703 0.146626 6.5898 0.420155 6.19691C0.693683 5.80402 1.08099 5.50431 1.52998 5.3381C1.97896 5.17189 2.46809 5.14716 2.93156 5.26723C3.39502 5.3873 3.81059 5.64641 4.12237 6.00971L10.4204 3.08512C10.3487 2.85702 10.3123 2.61931 10.3126 2.38021Z"
        fill="#354455"
      />
    </svg>
  );
};

export default ShareIcon;
