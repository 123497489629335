import { Link, useLocation } from "react-router-dom";

function FreelancerCard({ person }) {
  const {pathname } = useLocation();
  const dynamicPath = pathname.split("/")[1];
  return (
    <div>
      <div className="freelancer__card">
        <img className="freelancer__card__img" src={person.image} alt="" />
        <div className="freelancer__card__details">
          <div className="freelancer__card__user">
            <div>
              <h4 className="freelancer__card__name">{person.name}</h4>
              <p className="freelancer__card__title">{person.title}</p>
            </div>
            <div>
              ⭐5.0 <span>(12)</span>
            </div>
          </div>
          <p className="freelancer__card__persona">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of by the read..
            {/* <Link to={`/${dynamicPath}/user`}>See More</Link> */}
          </p>
        </div>
      </div>
    </div>
  );
}
export default FreelancerCard;
