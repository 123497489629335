import { Link, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import stock1 from "./../../assets/stock1.jpg";
import pickup from "./../../assets/pickup.svg";
import drop from "./../../assets/drop.svg";
import "./heroArea.css";
import styled from "styled-components";

function HeroArea() {
  const { pathname:url } = useLocation();

  return (
    <Wrapper>
      <div
        className="heroArea__bg"
        style={{ backgroundImage: `url(${stock1})` }}
      >
        <div className="py-3 py-sm-0 "></div>
        <div className="py-3 py-sm-0"></div>
        <div className="expressHeroArea heroArea__express">
          <div className="express__request">
            {/*  <a className="btn package__details" href="">
            Package Detail <FontAwesomeIcon icon={faChevronRight} />
          </a> */}

            <div className="express__request__container">
              <div className="express__request__location">
                <img className="dark-invert" src={pickup} alt="" />
                <div className="express__request__text">
                  <p>Pick Up</p>
                  <input
                    type="text"
                    className="dark-invert"
                    placeholder="Bronx, New York -10472"
                  />
                </div>
              </div>

              <div className="express__request__location">
                <img className="dark-invert" src={drop} alt="" />
                <div className="express__request__text">
                  <p>Dropoff</p>
                  <input
                    type="text"
                    className="dark-invert"
                    placeholder="Manhattan Area, New York"
                  />
                </div>
              </div>
              <div className="request__express">
                <Link to={`${url}/request`}>Request Express</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
export default HeroArea;

const Wrapper = styled.div`
  @media (min-width: 130em) {
    width: 1320px;
    margin-inline: auto;
  }
  /* css don't support height and width at a time without specifying the min-max value on both */
  @media (min-height: 67.5rem) {
    width: 1320px;
    margin-inline: auto;
  }
`;
