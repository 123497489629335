
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { MyDateFormate } from "src/Common/MyDateFormate";
import { rPrice } from "src/utilities/utilities";

export const columns =({currentPath,downloadInvoice})=>
[
  {
      name: "Order ID",
      selector: (row) => `#${row.OrderID}`,
      sortable: true,
  },
  {
      name: "Total Price",
      selector: (row) => `${rPrice(row.TotalPrice)} Tk`,
      // sortable: true,
  },
  {
      name: "Total Qty",
      selector: (row) => `${rPrice(row.TotalQty)}`,
  },
  {
      name: "Phone",
      selector: (row) => row.Phone,
  },
  {
      name: "Actions",
      button: true,
      cell: (row) => (
          <>
              <Link to={`${currentPath}/manage-order/${row.OrderID}`} className="send-code-btn cart-confirm-btn manage-order-btn py-1  smallTextSize text-dark">
                  Details
              </Link>
          </>
      ),
  },
  {
      name: "Invoice",
      button: true,
      cell: (row) => (
          <>
              <button className="eco-btn" onClick={() => downloadInvoice(row.OrderID)} >
                  Invoice
                  <FontAwesomeIcon className="text-orange ms-2" icon={faDownload} />
              </button>
          </>
      ),
  },
]
