import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import "./leftSidebar.css";

function LeftSidebar({ userImage, userName, userSub }) {
  const { pathname } = useLocation();
  const dynamicPath = pathname.split("/")[1];
  const dynamicUser = pathname.split("/")[2];

  const [showNavigation, setShowNavigation] = useState(true);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 768) {
        setShowNavigation(true);
      } else {
        setShowNavigation(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return function cleanupListener() {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleShowNavigation = () => {
    setShowNavigation(() => {
      if (window.innerWidth > 768) {
        return true;
      } else {
        return !showNavigation;
      }
    });
  };

  return (
    <>
      <div className="creative__profile">
        <div className="creative__profile__inner">
          <img className="creative__profile__img" src={userImage} alt="" />
          <div>
            <h4 className="creative__profile__name">{userName}</h4>
            <p className="creative__profile__sub">{userSub}</p>
          </div>
        </div>

        <div
          className="creative__profile__see_more"
          onClick={handleShowNavigation}
        >
          See {showNavigation ? "Less" : "More"}{" "}
          <FontAwesomeIcon className="ms-2" icon={faChevronDown} />
        </div>
        <Collapse in={showNavigation}>
          <ul className="creative__profile__links">
            {pathname.includes("user") && (
              <li>
                <NavLink
                  onClick={handleShowNavigation}
                  to={`/${dynamicPath}/${dynamicUser}/messages`}
                  className="creative__profile__link"
                >
                  Hire Me
                </NavLink>
              </li>
            )}

            {(pathname.includes("freelancer") || pathname.includes("client")) && (
              <>
                <li>
                  <NavLink
                    onClick={handleShowNavigation}
                    to={`/${dynamicPath}/${dynamicUser}/messages`}
                    className="creative__profile__link"
                  >
                    Inbox
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    onClick={handleShowNavigation}
                    to={`/${dynamicPath}/${dynamicUser}/wallet`}
                    className="creative__profile__link"
                  >
                    My Wallet
                  </NavLink>
                </li>
                {pathname.includes("client") && (
                  <li>
                    <NavLink
                      onClick={handleShowNavigation}
                      to={`/${dynamicPath}/${dynamicUser}/posts`}
                      className="creative__profile__link"
                    >
                      My Posts
                    </NavLink>
                  </li>
                )}
              </>
            )}

            <li>
              <NavLink
                onClick={handleShowNavigation}
                to={`/${dynamicPath}/${dynamicUser}/profile-info`}
                className="creative__profile__link"
              >
                Personal Info
              </NavLink>
            </li>

            {pathname.includes("client") && (
              <>
                <li>
                  <NavLink
                    onClick={handleShowNavigation}
                    to={`/${dynamicPath}/${dynamicUser}/receivedproject`}
                    className="creative__profile__link"
                  >
                    Received Project
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={handleShowNavigation}
                    to={`/${dynamicPath}/${dynamicUser}/projectcandidate`}
                    className="creative__profile__link"
                  >
                    Project Candidate
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={handleShowNavigation}
                    to={`/${dynamicPath}/${dynamicUser}/runningorders`}
                    className="creative__profile__link"
                  >
                    Running Orders
                  </NavLink>
                </li>
              </>
            )}

            {(pathname.includes("freelancer") || pathname.includes("user")) && (
              <>
                <li>
                  <NavLink
                    onClick={handleShowNavigation}
                    to={`/${dynamicPath}/${dynamicUser}/previouswork`}
                    className="creative__profile__link"
                  >
                    Previous Work
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    onClick={handleShowNavigation}
                    to={`/${dynamicPath}/${dynamicUser}/workpackage`}
                    className="creative__profile__link"
                  >
                    Work Package
                  </NavLink>
                </li>
              </>
            )}

            {pathname.includes("freelancer") && (
              <li>
                <NavLink
                  onClick={handleShowNavigation}
                  to={`/${dynamicPath}/${dynamicUser}/orders`}
                  className="creative__profile__link"
                >
                  Orders
                </NavLink>
              </li>
            )}

            {(pathname.includes("freelancer") || pathname.includes("client")) && (
              <>
                <li>
                  <NavLink
                    onClick={handleShowNavigation}
                    to={`/${dynamicPath}/${dynamicUser}/notifications`}
                    className="creative__profile__link"
                  >
                    Notification
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={handleShowNavigation}
                    to={`/${dynamicPath}/${dynamicUser}/signout`}
                    className="creative__profile__link"
                  >
                    Sign Out
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </Collapse>
      </div>
    </>
  );
}
export default LeftSidebar;
