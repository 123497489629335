import React from "react";
import { Col, Form} from "react-bootstrap";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup } from "react-bootstrap";
import "./input_file_and_select.css";

const InputSelectAndFile = ({
  label,
  handler = () => {},
  selectInputName,
  selectInputValue,
  fileInputFrontValue,
  fileInputFrontName,
  fileInputBackValue,
  fileInputBackName,
  children,
}) => {
  return (
    <Form.Group as={Col} className={`mb-3 double-file-input-box`} controlId="">
      <Form.Label className={"d-block"}>{label}</Form.Label>
      <InputGroup className="file-and-text-input-group py-0" hasValidation>
        <Form.Select
          name={selectInputName}
          onChange={handler}
          value={selectInputValue}
          aria-label="payment method"
          className="payment-method-input delivery-charge border-0 input-file-and-select "
        >
          {children}
        </Form.Select>
        <InputGroup.Text className="profileVideoSearchLabel">
          <label
            htmlFor={"file_front"}
            className="mainProfileInput me-2 secondary-bg"
            id="file-label"
          >
            <span className="file-text-btn">
              F/pic
              <FontAwesomeIcon
                className={`primary-text ms-1 ms-lg-2 `}
                size="lg"
                icon={faPlusCircle}
              ></FontAwesomeIcon>
            </span>
          </label>
          <input
            accept="image/png, image/jpg"
            type="file"
            value={fileInputFrontValue}
            name={fileInputFrontName}
            id="file_front"
            className="file-input"
            onChange={handler}
          />
          {/* national file input back  */}
          <label
            htmlFor={"file_back"}
            className="mainProfileInput secondary-bg"
            id="file-label"
          >
            <span className="file-text-btn">
              B/pic
              <FontAwesomeIcon
                className={`primary-text ms-1 ms-lg-2 `}
                size="lg"
                icon={faPlusCircle}
              ></FontAwesomeIcon>
            </span>
          </label>
          <input
            accept="image/png, image/jpg"
            type="file"
            value={fileInputBackValue}
            name={fileInputBackName}
            id="file_back"
            className="file-input"
            onChange={handler}
          />
        </InputGroup.Text>
      </InputGroup>
    </Form.Group>
  );
};

export default InputSelectAndFile;
