import React from "react";
import Banner from "./Components/Banner/Banner";
import LeftCol from "./Components/LeftCol/LeftCol";
import MiddleCol from "./Components/MiddleCol/MiddleCol";
import RightCol from "./Components/RightCol/RightCol";
import JobStoreProvider from "./Context/JobStoreProvider";
import "./job_common.css";

import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getAllDistricts,
  getAllDivision,
} from "src/features/job/jobLocalDataSlice";
import {
  getApplicantProfile,
  getCompanyProfile,
} from "src/features/job/jobProfileSlice";
import { getAllCategories } from "src/features/job/jobHomeDataSlice";
import styled from "styled-components";

const Job = () => {
  const { categories } = useSelector((store) => store.jobHomeData);
  const { districts, divisions } = useSelector((store) => store.jobLocalData);
  const { company_profile, applicant_profile } = useSelector(
    (store) => store.jobProfile
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (categories.length < 1) {
      dispatch(getAllCategories());
    }
    if (districts.length < 1) {
      dispatch(getAllDistricts());
    }
    if (divisions.length < 1) {
      dispatch(getAllDivision());
    }
    if (!company_profile.id) {
      dispatch(getCompanyProfile());
    }
    if (!applicant_profile.id) {
      dispatch(getApplicantProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <JobStoreProvider>
        <div className="row px-lg-3 px-md-2">
          <div className="col-12  mx-auto">
            <Banner />
            <div className="row">
              <LeftCol />
              <MiddleCol />
              <RightCol />
            </div>
          </div>
        </div>
      </JobStoreProvider>
    </Wrapper>
  );
};

export default Job;

const Wrapper = styled.div`
  padding-inline: 0.75rem;
  /* margin-inline: auto; */

  @media (min-width: 130em) {
    width: 1920px;
    margin-inline: auto;
  }
  /* css don't support height and width at a time without specifying the min-max value on both */
  @media (min-height: 67.5rem) {
    width: 1920px;
    margin-inline: auto;
  }
`;
