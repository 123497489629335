import React from "react";
import { Col, Row } from "react-bootstrap";
import Navigation from "../Shared/Navigation";

const VerifyEmail = () => {
  const handelSubmit = {};
  const handelChange = {};
  return (
    <div>
      {/* <Navigation /> */}
      <div className="verifyDivParent">
        <Row>
          <Col sm={12} md={5} lg={5}>
            <div className="w-75 mx-auto">
              <img
                className="img-fluid"
                src="https://uviom.com/all_images/otp.png"
                alt="verify your email address"
              />
            </div>
            <h2>Verificaiton</h2>
            <p> Please Check Your Email</p>
          </Col>
          <Col className="" sm={12} md={7} lg={7}>
            <div className="otpBox">
              <div className="w-100">
                <h2 className="my-4">Enter OTP</h2>

                <form onSubmit={handelSubmit}>
                  <div className=" my-2 ">
                    <div className="InputFildDiv ">
                      <input
                        required
                        onBlur={handelChange}
                        name="OTP"
                        type="number"
                      />
                    </div>
                    <button
                      type="submit"
                      className="button mx-auto mt-4 d-block"
                    >
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default VerifyEmail;
