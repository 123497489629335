import { Link, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLocationDot, faCircleDot } from "@fortawesome/free-solid-svg-icons";
import miniMap from "./../../assets/minimap.jpg";
import "./rideHistoryCard.css";

function RideHistoryCard() {
  const { pathname:url } = useLocation();

  const dynamicPath = url.split("/")[1];

  return (
    <div className="rideHistoryCard">
      <div>
        <img className="rideHistoryCard__img" src={miniMap} alt="minimap" />
      </div>
      <div>
        <h3 className="rideHistoryCard__date">10 June 2022 | 9.00pm</h3>
        <p className="rideHistoryCard__location">
          <span className="rideHistoryCard__location--from">
            {/* <FontAwesomeIcon
              className="rideHistoryCard__icon"
              icon={faCircleDot}
            /> */}
            Mirpur-1 Bus Stand
          </span>
          <span className="rideHistoryCard__location--to">
            {/* <FontAwesomeIcon
              className="rideHistoryCard__icon"
              icon={faLocationDot}
            /> */}{" "}
            Dhanmondi 32
          </span>
        </p>
        <p className="rideHistoryCard__details">
          Total Distance: 15 Km
          <br />
          Time: 55 min
        </p>
        <p className="rideHistoryCard__cost">
          <span>Fare:</span>
          <span>$20</span>
        </p>
      </div>
      <div className="rideHistoryCard__button">
        <Button className="btn__ride shadow">Tips And Review</Button>
        <Button
          as={Link}
          to={`/${dynamicPath}/receipt`}
          className="btn__ride shadow"
        >
          View Receipt
        </Button>
      </div>
    </div>
  );
}
export default RideHistoryCard;
