import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faPhone,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Avatar from "src/Projects/Life/Common/Avatar";
import LookMeIcon from "src/Projects/Life/icons/LookMeIcon";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { personalProfilePic } from "src/Projects/Life/Common/CommonUrl";

const ChatContentHeader = ({ targetedUserInfo }) => {
  const { pathname: url } = useLocation();
  const navigate = useNavigate();

  return (
    <div
      className="d-flex justify-content-between align-items-center pe-2  bg-gray mb-3 px-2 px-sm-0"
      style={{ gap: "10px" }}
    >
      <button onClick={() => navigate(-1)} className="border-0 d-sm-none">
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      <div className="flex-1 w-100">
        <div className="d-flex align-items-center justify-content-center justify-content-sm-start  flex-column flex-sm-row">
          {/* <Avatar imgUrl="/user.png" size={"50px"} /> */}
          {targetedUserInfo && targetedUserInfo.photo ? (
            <Avatar
              imgUrl={`${personalProfilePic}/${targetedUserInfo.photo}`}
              size={"50px"}
            />
          ) : (
            <Avatar imgUrl="/user.png" size={"50px"} />
          )}
          <div className="ms-2">
            <p className="m-0 font-14">
              {targetedUserInfo.first_name} {targetedUserInfo.last_name}
            </p>
            {/* <p className="m-0 font-12">Active 6h ago</p> */}
          </div>
        </div>

        <div className="d-flex justify-content-evenly w-100 flex-1">
          {/* <NavLink to={`${url}/chat`} className="chat__content__header-btn"
            activeClassName="chat__btn-active"
            type="button" >
            <FontAwesomeIcon className="me-2 Chat__header__btn-icon" icon={faCommentDots}></FontAwesomeIcon>
            <span className="d-none d-md-block" >chat</span>
          </NavLink> 

          <NavLink
            to={`${url}/voice-call`}
            className="chat__content__header-btn"
            activeClassName="chat__btn-active"
            type="button"
          >
            <FontAwesomeIcon
              className="me-2 Chat__header__btn-icon"
              icon={faPhone}
            ></FontAwesomeIcon>
            <span className="d-none d-md-block">Voice Call </span>
          </NavLink>
          <NavLink
            to={`${url}/video-call`}
            className="chat__content__header-btn"
            activeClassName="chat__btn-active"
            type="button"
          >
            <FontAwesomeIcon
              className="me-2 Chat__header__btn-icon"
              icon={faVideo}
            ></FontAwesomeIcon>
            <span className="d-none d-md-block">Video Call</span>
          </NavLink>

          <NavLink
            to={`${url}/look-me`}
            className="chat__content__header-btn"
            activeClassName="chat__btn-active"
            type="button"
          >
            <LookMeIcon
              className="Chat__header__btn-icon"
              height="25"
              width="40"
            />
            <span className="d-none d-md-block ms-2">Look Me </span>
          </NavLink>
          */}
        </div>
      </div>
      <div className="mt-2 me-2">
        <ThreeDots size="20"></ThreeDots>
      </div>
    </div>
  );
};

export default ChatContentHeader;
