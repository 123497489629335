import { Container } from "react-bootstrap";

import category1 from "./../../assets/category1.png";
import category2 from "./../../assets/category2.png";
import category3 from "./../../assets/category3.png";
import category4 from "./../../assets/category4.png";
import category5 from "./../../assets/category5.png";
import category6 from "./../../assets/category6.png";
import category7 from "./../../assets/category7.png";

import "./freelanceTopCategory.css";

const categories = [
  { image: category1, title: "UI/UX", followers: "240,20" },
  { image: category2, title: "Logo Design", followers: "140,16" },
  { image: category3, title: "Poster Design", followers: "190,24" },
  { image: category4, title: "Web Design", followers: "260,50" },
  { image: category5, title: "Data Entry", followers: "142,25" },
  { image: category6, title: "Marketing", followers: "350,30" },
  { image: category7, title: "App Design", followers: "520,10" },
];

function FreelanceTopCategory() {
  return (
    <Container>
      <div className="freelanceTopCategory">
        <h2 className="freelanceTopCategory__title">Top Category</h2>

        <ul className="freelanceTopCategory__list">
          {categories.map((category,index) => (
            <li key={index} className="freelanceTopCategory__item">
              <img
                className="freelanceTopCategory__item__img"
                src={category.image}
                alt=""
              />
              <div>
                <h4 className="freelanceTopCategory__item__title">
                  {category.title}
                </h4>
                <p className="freelanceTopCategory__item__sub">
                  {category.followers} Freelancer
                </p>
              </div>
            </li>
          ))}
          <li className="freelanceTopCategory__item see-all">
            See All &gt;&gt;
          </li>
        </ul>
      </div>
    </Container>
  );
}
export default FreelanceTopCategory;
