import React, { useState } from "react";
import { Col, Container } from "react-bootstrap";
import { TopNavBar } from "../TopNavBar";
import MiddleColRoutes from "./MiddleColRoutes";
import LifeSearchBox from "../LifeSearchBox";

export const MiddleCol = () => {
  const [searchBoxShow, setSearchBoxShow] = useState(false);
  const openSearchbox = () => {
    setSearchBoxShow(!searchBoxShow);
  };

  return (
    <Col
      sm={12}
      md={12}
      lg={6}
      className="stickyContainer customContainerFluidPadding"
    >
      <TopNavBar openSearchbox={openSearchbox} searchBoxShow={searchBoxShow} />
      {searchBoxShow && <LifeSearchBox setSearchBoxShow={setSearchBoxShow} />}
      <Container fluid className="customContainerFluidPadding ">
        <div onClick={() => setSearchBoxShow(false)}>
          <MiddleColRoutes />
        </div>
      </Container>
    </Col>
  );
};
