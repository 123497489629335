import React from "react";
import { Col, Form, FormControl, Row } from "react-bootstrap";
const PaymentMethod = ({
  totalPrice,
  handlePaymentMethodChange,
  handlePaymentMethodSubmit,
  errorMessage,
  deliveryCharge,
  handleDeliveryChange
}) => {
  return (
    <div>
      <h6 className="mb-3">Payment Method</h6>

      <Form.Select
        onChange={handlePaymentMethodChange}
        defaultValue=""
        aria-label="payment method"
        className="payment-method-input ms-md-2 secondary-bg primary-text"
      >
        <option value=""> Select Payment Method</option>
        <option value="uviom_wallet"> UVIOM Wallet</option>
        <option value="cash_on_delivery"> Cash On Delivery</option>
        <option value="card">Credit or Debit Card</option>
        <option value="bikash">Bikash</option>
        <option value="nogod">Nogod</option>
        <option value="roket">Rocket</option>
      </Form.Select>
      <br />

      {/* <h6 className="mb-3">Delivery Charge</h6>
      <Form.Select
        aria-label="payment method"
        className="delivery-charge payment-method-input me-2 bg-white text-dark"
        value={deliveryCharge}
        onChange={handleDeliveryChange}
      >
        <option value={100}>Inside Dhaka 100Tk</option>
        <option value={150}>Outside Dhaka 150Tk</option>
      </Form.Select> */}

      <br />
      <div className="d-flex justify-content-between px-lg-2">
        <h6>Total Price</h6>
        <h6>{totalPrice}Tk</h6>
      </div>
      <br />
      <div className="d-flex justify-content-between px-lg-2">
        <h6>Delivery Charge</h6>
        <h6>{deliveryCharge}</h6>
      </div>
      <br />
      <Row className="g-xs-2 g-lg-4">
        <Col xs="9">
          <FormControl
            type="text"
            placeholder="Coupon Code"
            className="coupon-code-input bg-white"
          />
        </Col>
        <Col xs="3">
          <button type="button" className="coupon-apply-btn">
            Apply
          </button>
        </Col>
      </Row>
      <hr />
      <div className="d-flex justify-content-between px-lg-2">
        <h6>Total</h6>
        <h6>{totalPrice + parseInt(deliveryCharge)}Tk</h6>
      </div>
      <p className="text-center text-danger" style={{ fontSize: ".75rem" }}>
        {errorMessage}
      </p>
      <div className="text-center mt-2">
        <button
          onClick={handlePaymentMethodSubmit}
          type="button"
          className=" send-code-btn cart-confirm-btn"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default PaymentMethod;
