/* eslint-disable react-hooks/exhaustive-deps */
import { faSmile } from "@fortawesome/free-regular-svg-icons";
import { faSpinner, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmojiPicker from "emoji-picker-react";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  useDeletePostContentMutation,
  useUpdatePostCaptionMutation,
} from "src/app/services/lifeApi/newsFeedApi";
import useTheme from "src/hooks/useTheme";
import { deleteAlert } from "src/Projects/Job/utilities/functions";

import {
  postVideoPath,
  temporaryImageUrl,
} from "src/Projects/Life/Common/CommonUrl";

export const Updating = ({ updatingArg }) => {
  const location = useLocation();
  const [deletingItem, setDeletingItem] = useState(undefined);
  const [updateCaption, { isLoading, isSuccess }] =
    useUpdatePostCaptionMutation();
  const [deletePostContent, { isLoading: deleting }] =
    useDeletePostContentMutation();
  let timeline_name = location.pathname?.split("/")[2]?.split("-")[0];
  if (location.pathname.includes("my-post")) {
    timeline_name = timeline_name + "_personal";
  }
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { currentTheme } = useTheme();

  const { myFormData, setFormData, images, post_id, handleClose, formateData } =
    updatingArg;

  const handelChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...myFormData };
    newData[name] = value;
    setFormData(newData);
  };

  const handleEmojiClick = (text) => {
    const newData = { ...myFormData };
    const prev_text = newData["post_details"];
    newData["post_details"] = prev_text ? prev_text + text.emoji : text.emoji;
    setFormData(newData);
  };

  const deleteContentFunc = (content) => {
    const content_id = content.id ? content.id : content.content_id;
    const deleteActionArg = {
      post_id: content.post_id,
      post_content_id: content_id,
      timeline_name,
    };

    deleteAlert().then((result) => {
      if (result.isConfirmed) {
        setDeletingItem(content_id);
        deletePostContent(deleteActionArg);
      }
    });
  };

  const handleUpdate = () => {
    const arg = {
      post_id,
      timeline_name,
      post_details: myFormData.post_details,
    };
    updateCaption(arg);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      formateData();
    }
  }, [isSuccess]);

  return (
    <>
      <Modal.Body>
        <div className="position-relative emoji__picker_wrapper">
          <textarea
            onChange={handelChange}
            name="post_details"
            rows="4"
            cols="50"
            value={
              myFormData && myFormData.post_details
                ? myFormData.post_details
                : ""
            }
            placeholder="Create New Mind ...!"
            className="my-3"
            onFocus={() => setShowEmojiPicker(false)}
          ></textarea>

          <span
            className="emoji__picker-btn"
            onClick={() => setShowEmojiPicker((prev) => !prev)}
          >
            <FontAwesomeIcon icon={faSmile} size="lg" />
          </span>

          {showEmojiPicker && (
            <EmojiPicker
              onEmojiClick={handleEmojiClick}
              skinTonesDisabled
              searchDisabled
              theme={currentTheme}
              height="350px"
              previewConfig={{ showPreview: false }}
            />
          )}
        </div>

        {images.length !== 0 && (
          // d-flex flex-wrap mt-4  fileContainerMain
          <div className=" container-fluid  py-2">
            <div className="row  place-content-center ">
              {images
                ? images.map((content) => {
                    const content_id = content.id
                      ? content.id
                      : content.content_id;
                    const source = content.source
                      ? content.source
                      : content.content_source;
                    const type = content.content_type
                      ? content.content_type
                      : content.type;

                    return (
                      <div
                        className="col-12  px-0 col-lg-6 "
                        key={content.id ? content.id : content.content_id}
                      >
                        <div className="bg-footer rounded-3 position-relative p-2 m-2  popupFiles">
                          {type === "image" && (
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              size="lg"
                              className="customCross  rounded-circle fw-lighter"
                              onClick={() => deleteContentFunc(content)}
                            />
                          )}
                          {type === "image" ? (
                            <img
                              src={`${temporaryImageUrl}/${source}${content.content_name}`}
                              className="img-fluid popupFiles rounded-3"
                              alt=""
                            />
                          ) : (
                            <video width="100%" controls className="popupFiles">
                              <source
                                src={`${postVideoPath}${content.content_name}`}
                                // type={url.type}
                              />
                            </video>
                          )}
                          {deleting && deletingItem === content_id && (
                            <div className="content_delete_spinner">
                              <span className="">
                                <FontAwesomeIcon
                                  style={{ color: "white" }}
                                  id="delete-spinner"
                                  icon={faSpinner}
                                  spin
                                  className="me-2"
                                ></FontAwesomeIcon>
                                Deleting...
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        )}
        {/* temporaryImageUrl */}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="submit"
          className="btn btn-secondary  w-100 fw-bold btn-sm"
          onClick={handleUpdate}
        >
          {isLoading ? (
            <span className="text-white">
              <FontAwesomeIcon
                icon={faSpinner}
                className="text-white me-2"
                spin
              />{" "}
              Updating...
            </span>
          ) : (
            "update"
          )}
        </button>
      </Modal.Footer>
    </>
  );
};
