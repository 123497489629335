import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import { searchFriendUrl } from "src/Projects/Life/Common/CommonUrl";
import { findFriends } from "../FriendsApiReq";
import SingleFrieds from "./SingleFrieds";
import { Title } from "../../../../Common/Title";
import { NextButtonRow } from "../../../../Common/NextButtonRow";
import { useLocation } from "react-router-dom";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";

export const FindFriends = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [title, settitle] = useState("Find Friends");
  const [userList, setuserList] = useState([]);
  const [userListStore, setuserListStore] = useState([]);
  const [searchValue, setsearchValue] = useState();
  const [buttonRowVisible, setbuttonRowVisible] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const findFriendsArg = {
    setIsLoading: setIsLoading,
    currentPage: currentPage,
    setuserList: setuserList,
    setuserListStore: setuserListStore,
    setbuttonRowVisible: setbuttonRowVisible,
    location: location,
  };
  const dataReload = () => {
    findFriends(findFriendsArg);
  };
  useEffect(() => {
    dataReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (searchValue && searchValue.length > 0) {
      const formData = { name: searchValue };
      axios
        .post(`${searchFriendUrl}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((res) => {
          if (res.data.status === 1) {
            setuserList(res.data.data);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setuserList(userListStore);
    }
  }, [searchValue]);
  const handleChange = (e) => {
    setsearchValue(e.target.value);
  };
  const loadMore = () => {
    if (currentPage >= 0) {
      setcurrentPage((prev) => prev + 1);
    }
  };
  return (
    <>
      <Title title={title} />

      {isLoading ? (
        <DottedCircleSpinner />
      ) : (
        <>
          <Row className="mb-5">
            {userList &&
              userList.length !== 0 &&
              userList.map((userData) => (
                <SingleFrieds
                  userData={userData}
                  key={userData.id}
                  dataReload={dataReload}
                />
              ))}
          </Row>
          {buttonRowVisible === true && <NextButtonRow loadMore={loadMore} />}
        </>
      )}
    </>
  );
};
