import React from "react";
import {
  faBell,
  faBookmark,
  faBuilding,
  faCheckCircle,
  faClone,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { faBars, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Typewriter from "typewriter-effect";

import { Link, useLocation } from "react-router-dom";

import NavButton from "../Common/NavButton";
import { JobIcon } from "src/DashBord/Icon/JobIcon";

const RightCol = () => {
  const { pathname } = useLocation();

  return (
    <div className=" d-none d-lg-block col-3 col-xxl-2 RightCol ">
      <div className="text-align-webkit-right inner sticky-top ">
        <div className="LookMeWhiteIcon box-shadow nav-bg-opacity py-2 ps-3 d-block">
          <div className="d-flex ">
            <div className="LookMeButtonTypeIcon">
              {/* <img className="img-fluid" src="/images/logo.svg" alt="logo" /> */}
              <JobIcon />
            </div>
            <Link to="/job">
              <div className="type-writer-text-box">
                <h5 className="mb-0">
                  <span className="food-nav-uviom-text">UVIOM</span>
                  <span className="text-orange">Job</span>
                </h5>
                <div className="lh-1 logo-slogan overflow-hidden type-writer-text text-nowrap">
                  <Typewriter
                    options={{
                      strings: "Find Your Dream Job",
                      autoStart: true,
                      loop: true,
                    }}
                  ></Typewriter>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <br />
        <div className="job-right-column">
          <NavButton
            className="mt-0"
            RouteName={`job-market/recommended`}
            title={"Jobs Market"}
            icon={faBars}
          />
          {pathname.split("/").includes("company") ? (
            <>
              <NavButton
                RouteName={`company/create-job`}
                title={"Add Job"}
                icon={faPlusCircle}
              />
              <NavButton
                RouteName={`company/posted-job`}
                title={"Posted Job"}
                icon={faClone}
              />
            </>
          ) : (
            <>
              <NavButton
                RouteName={`applied-job`}
                title={"Applied Jobs"}
                icon={faCheckCircle}
              />
              <NavButton
                RouteName={`save-job`}
                title={"Save Post"}
                icon={faBookmark}
              />
              <NavButton
                RouteName={`applicant-profile`}
                title={"Applicant Profile"}
                icon={faUser}
              />
            </>
          )}
          <NavButton
            RouteName={`notification`}
            title={"Notification"}
            icon={faBell}
          />
          <NavButton
            RouteName={`company/profile`}
            title={"Company Profile"}
            icon={faBuilding}
          />
          {pathname.split("/").includes("company") && (
            <NavButton
              RouteName={`applicant-profile`}
              title={"Applicant Profile"}
              icon={faUser}
            />
          )}

          <div className="job__right-ads my-3">
            <img
              src="https://s3.ap-south-1.amazonaws.com/dnkimage/Front/images/career_view_banner.jpg"
              alt="job- banner"
              className="img img-fluid"
            />
          </div>
          <div className="job__right-ads my-3">
            <img
              src="https://s3.ap-south-1.amazonaws.com/dnkimage/Front/images/career_view_banner.jpg"
              alt="job- banner"
              className="img img-fluid"
            />
          </div>
          <div className="job__right-ads my-3">
            <img
              src="https://s3.ap-south-1.amazonaws.com/dnkimage/Front/images/career_view_banner.jpg"
              alt="job- banner"
              className="img img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightCol;
