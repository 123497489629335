import React, { useState } from "react";
import {  Modal} from "react-bootstrap";

import { BubbleScale } from "src/Common/BubbleScale";
import VideoIcon from "src/Projects/Life/icons/VideoIcon";

const VideoPostModal = ({ show, handleClose }) => {
  const [myFormData, setFormData] = useState({ family_post_status: true });
  const [isUploding, setUploding] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [videoFiles, setvideoFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [images, setImages] = useState([]);

  const handelChange = (e) => {
    if (e.target.type === "checkbox") {
      const name = e.target.name;
      const value = e.target.checked;
      const newData = { ...myFormData };
      newData[name] = value;
      setFormData(newData);
    } else {
      const name = e.target.name;
      const value = e.target.value;
      const newData = { ...myFormData };
      newData[name] = value;
      setFormData(newData);
    }
  };

  const fileHandleChange = async (e) => {
    //----------
    const selectedFIles = [];
    const selectedvideoFIles = [];

    const targetFiles = e.target.files;
    setSelectedFiles(targetFiles); // selectedFiles will submit to our api

    //code for image preview--------------------------
    // ---------------------------------------------------

    const targetFilesObject = [...targetFiles]; //converting (array) from object - [{..},{...},...]

    targetFilesObject.map((file) => {
      if (file.type.match("video.*")) {
        return selectedvideoFIles.push(URL.createObjectURL(file));
      } else {
        return selectedFIles.push(URL.createObjectURL(file)); //image
      }
    });
    setvideoFiles(selectedvideoFIles);
    setImages(selectedFIles);
    //code for image preview end--------------------------
    // ---------------------------------------------------
  };

  const formateFile = (imageId) => {
    setvideoFiles([]);
    setImages((images) => images.filter((_, imageId) => imageId !== 0));
  };

  const formateData = () => {
    setFormData({});
    setImages([]);
    setvideoFiles([]);
    setSelectedFiles([]);
    handleClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Modal id="PostModalPopup" show={show} onHide={handleClose}>
      {isUploding ? (
              <BubbleScale extraStyle={{marginTop: "25%"}} />

      ) : (
        <>
          <Modal.Header className="text-center">
            <Modal.Title>Create Your Post</Modal.Title>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={formateData}
            ></button>
            {/* {JSON.stringify(myFormData)} */}
          </Modal.Header>
          <Modal.Body>
            <div className="">
              {errorMessage && (
                <div className="text-danger text-center bg-warning rounded-pill py-1 mb-1">
                  {errorMessage}
                </div>
              )}
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="family"
                  name="family_post_status"
                  onChange={handelChange}
                  defaultChecked={myFormData.family_post_status}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox1">
                  Family
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                  value="friend"
                  name="friend_post_status"
                  onChange={handelChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox2">
                  Friend
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox4"
                  value="professional"
                  name="professional_post_status"
                  onChange={handelChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox4">
                  Professional
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox3"
                  value="professional"
                  name="life_video_status"
                  onChange={handelChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox3">
                  Life Videos
                </label>
              </div>
            </div>
            <textarea
              name="post_details"
              className="live__video__textarea"
              onChange={handelChange}
              rows="2"
              cols="50"
              placeholder="Type Your Caption"
            ></textarea>
            <div className="container-fluid">
              <div className="d-flex  justify-content-center ">
                <label htmlFor="video-upload">
                  <VideoIcon size="100"/>
                </label>
              </div>
                              <input
                                  
                id="video-upload"
                type="file"
                name="file"
                className="postFileInput d-none"
                onChange={fileHandleChange}
                multiple
                accept="video/mp4"
                onClick={(e) => (e.target.value = null)}
              />

              {/* "image/*,video/mp4,video/x-m4v,video/*" */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              className="btn btn-secondary w-100 fw-bold btn-sm"
              onClick={handleSubmit}
            >
              Post
            </button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default VideoPostModal;
