import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { vendorSliderImgBase } from "src/Projects/Corporate/utilities/CommonUrl";

const StoreSliderItem = (props) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { store_image, slug } = props.data;
  const path = "/" + pathname.split("/")[1];

  return (
    <div
      onClick={() => navigate(`${path}/shop/store-view/${slug}`)}
      className="pointer store__slider-item mx-auto"
    >
      <img
        className="img img-fluid"
        src={`${vendorSliderImgBase}/${store_image}`}
        alt="slider img"
        // style={{ height: "17rem", width: "15rem" }}
      />
    </div>
  );
};

export default StoreSliderItem;
