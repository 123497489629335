import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";
import { NextButtonRow } from "../../../../Common/NextButtonRow";
import { Title } from "../../../../Common/Title";
import { PendingFriendsGet } from "../FriendsApiReq";
import SingleFrieds from "./SingleFrieds";

export const PendingFriends = () => {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [title, settitle] = useState("Pending Friends");
  const [userList, setuserList] = useState([]);
  const [userListStore, setuserListStore] = useState([]);
  const [buttonRowVisible, setbuttonRowVisible] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  useEffect(() => {
    dataReload();
  }, [currentPage]);

  const PendingFriendsGetArg = {
    setIsLoading: setIsLoading,
    currentPage: currentPage,
    setuserList: setuserList,
    setuserListStore: setuserListStore,
    setbuttonRowVisible: setbuttonRowVisible,
    location: location,
  };
  const dataReload = () => {
    PendingFriendsGet(PendingFriendsGetArg);
  };

  const loadMore = () => {
    if (currentPage >= 0) {
      setcurrentPage((prev) => prev + 1);
    }
  };

  return (
    <>
      <Title title={title} />

      {isLoading ? (
        <DottedCircleSpinner />
      ) : (
        <>
          <Row className="mb-5">
            {userList &&
              userList.length !== 0 &&
              userList.map((userData) => (
                <SingleFrieds
                  userData={userData}
                  key={userData.user_id}
                  dataReload={dataReload}
                />
              ))}
          </Row>
          {buttonRowVisible === true && <NextButtonRow loadMore={loadMore} />}
        </>
      )}
    </>
  );
};
