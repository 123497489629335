import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import ApplicantInformation from "../../Components/Common/ApplicantInformation";
import "./ApplicantProfile.css";
import useTheme from "src/hooks/useTheme";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import { useSelector } from "react-redux";
import { useGetApplicantEducationQuery } from "src/app/services/jobApi/jobGetApi";

const ApplicantProfile = () => {
  const { applicant_profile } = useSelector((store) => store.jobProfile);
  const {
    data: educations,
    isFetching,
    error,
  } = useGetApplicantEducationQuery();

  const navigate = useNavigate();

  const handleUpdate = () => {
    navigate("/Job/applicant-profile-update", {
      state: { educations },
    });
  };

  const { currentTheme } = useTheme();

  return (
    <div className=" secondary-bg">
      {isFetching ? (
        <BubbleLoader />
      ) : (
        <>
          <div className="applicantTitle text-nowrap  py-4">
            <h6>Applicant Profile</h6>
            <button type="button" onClick={handleUpdate}>
              Update Profile
              <FontAwesomeIcon
                className="ms-2"
                icon={faPencilAlt}
              ></FontAwesomeIcon>
            </button>
          </div>

          <ApplicantInformation
            theme={currentTheme}
            data={applicant_profile}
            educations={educations}
          />

          <div style={{ fontSize: "0.9rem" }}>
            <p className="text-center text-danger">{error}</p>
          </div>
          <br />
        </>
      )}
    </div>
  );
};

export default ApplicantProfile;
