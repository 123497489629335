import React from "react";
import { faCompress, faEye, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommentIcon from "src/Projects/Life/icons/CommentIcon";
import ShareIcon from "src/Projects/Life/icons/ShareIcon";

const LikeCommentShareView = () => {
  return (
    <div className="mt-3 mb-2" >
      <div className="LikeCommentShareParent">

      {/* <div className="text-center ">
          <div className="LikeCommentsharePart d-flex justify-content-center align-items-center">
           
            <div className=" ms-md-1">
              <small>Views</small>
            </div>
          </div>
          <div className="smallTextSize mt-1 clickEffect pointer">0 view</div>
        </div> */}
        <div className="text-center">
          <div className={`LikeCommentsharePart `}>
            <div className="LikeCommentIcon ">
              <FontAwesomeIcon icon={faThumbsUp} />
            </div>
            <div className=" ms-md-1">
              <small>Like</small>
            </div>
          </div>
        </div>

        <div className="text-center ">
          <div className="LikeCommentsharePart">
            <div className="LikeCommentIcon">
            {/* <img
              className="img-fluid"
              src="https://i.ibb.co/XDCKZzH/Cmment.png"
              alt=""
            /> */}
            <CommentIcon className="primary-text" size="22" />
            </div>
            <div className=" ms-md-1 ">
              <small>Comment</small>
            </div>
          </div>
          <div
            className={`smallTextSize  mt-1 pointer hoverEffect clickEffect `}
          ></div>
        </div>
        <div className="text-center ">
          <div className="LikeCommentsharePart">
            <div className="LikeCommentIcon">
              {/* <img
                className="img-fluid"
                src="https://i.ibb.co/YbKjwTB/share.png"
                alt=""
              /> */}
               <ShareIcon size="20" className="primary-text" />
            </div>
            <div className="ms-md-1">
              <small>Share</small>
            </div>
          </div>
          <div className="smallTextSize mt-1 pointer">0 share</div>
        </div>
      </div>
    </div>
  );
};

export default LikeCommentShareView;
