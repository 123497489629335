import React from "react";
import { Col, Row } from "react-bootstrap";
import LoginPart from "./LoginPart";
import Mockup from "./Mockup";
import "./Login&Registrasion.css";
import LogoBlink from "src/Common/LogoBlink";

const LoginBlock = () => {
  return (
    <div className="Feature">
      <div className="InnerFeature">
        <Row className="gx-5">
          <Col className="order-md-2" sm={12} lg={6}>
            <div className="FeatureBlock LoginBlock">
              <div className="pt-5">
                <LogoBlink />
              </div>
              <LoginPart />
            </div>
          </Col>
          <Col sm={12} lg={6}>
            <Mockup />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LoginBlock;
