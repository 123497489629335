import { Container, Button, Pagination, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import JobPost from "../Common/JobPost";

import "./searchedJobs.css";

function SearchedJobs() {
  return (
    <div>
      <Container>
        <div className="searchedJobs">
          <h3 className="searchedJobs__title">find freelance jobs</h3>
          <p className="searchedJobs__sub">
            we have 12,6665 freelance jobs available now
          </p>
          <hr />

          <div className="searchedJobs__filter">
            <Form.Select>
              <option value="">Category</option>
              <option value="">Category</option>
              <option value="">Category</option>
            </Form.Select>
            <Form.Select>
              <option value="">Minimum</option>
              <option value="">Minimum</option>
              <option value="">Minimum</option>
            </Form.Select>
            <Form.Control type="input" placeholder="Search Freelance Jobs" />
            <Button variant="outline-secondary">Search</Button>
          </div>

          <div className="searchedJobs__list">
            <p className="searchedJobs__total">UX Designer 12,5564 jobs</p>
            {Array.from({ length: 5 }).map((_, index) => (
              <JobPost key={index} />
            ))}
          </div>
        </div>

        <Pagination className="creative__pagination justify-content-center mb-5 mt-4">
          {Array.from({ length: 3 }).map((_, index) => (
            <Pagination.Item key={index}>{index + 1}</Pagination.Item>
          ))}
        </Pagination>
      </Container>
    </div>
  );
}
export default SearchedJobs;
