import { faChevronDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { paginationPostApiCall } from "src/Common/apiCall/PostApiCalls";
import { BubbleScale } from "src/Common/BubbleScale";
import { globalSearch } from "src/Projects/Corporate/utilities/CommonUrl";
import { ProductInfiniteScroll } from "../../Product/ProductInfiniteScroll";
import "./searchProduct.css";

const related_categories = [
  "Men Fashion Watches",
  "Unisex Sports Watches",
  "Smart Watches",
  "Women Fashion Watches",
  "Men Casual Watches",
  "Men Business Watches",
  "Unisex Casual Watches",
  " Women Business Watches",
  " Women Casual Watches",
  " Unisex Fashion Watches",
];

const brands = ["HP", " Asus", "Lenovo", "Dell", "Aos", "Apple"];
const colors = ["White", "Silver", "Blue", "Green", "Golden", "Black"];

const SearchProduct = () => {
  const { pathname } = useLocation();
  const mainUrl = `/${pathname.split("/")[1]}`;
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [info, setinfo] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { searchText: UrlSearchText } = useParams()
  const [formData, setformData] = useState({
    type: location.pathname.includes("/search-product") ? 1 : null,
    value: UrlSearchText
  })
  const search = (isAddPrevData) => {
    const formArg = {
      setIsLoading: setIsLoading,
      postList: info,
      setPostList: setinfo,
      setHasMore: setHasMore,
      Url: `${globalSearch}?page=${currentPage}`,
      formData: formData,
    };
    paginationPostApiCall(formArg, isAddPrevData)
  }
  useEffect(() => {
    search(false)
  }, [formData])
  useEffect(() => {
    if (currentPage > 1) {
      search(true)
    }
  }, [currentPage])
  useEffect(() => {
    if (UrlSearchText !== formData.value) {
      setformData((prevState) => ({ ...prevState, value: UrlSearchText }))
      setCurrentPage(1)
    }
  }, [UrlSearchText])

  // console.log("location.state.data", location.state.data)
  return (
    <Container fluid>
      <br />
      <Row>
        <Col md={4} lg={3} xl={2}>
          <div>
            <h6>Related Categories</h6>
            <div>
              {related_categories.map((item, index) => (
                <Link
                  className="font-14 d-block my-2 hover-orange"
                  to={`${mainUrl}/search-product/${item}`}
                  key={index}
                >
                  {item}
                </Link>
              ))}
            </div>
            <br />
            <h6>Price</h6>
            <div className="price-filter-input-box">
              <Form.Control
                className="price-filter-input"
                placeholder="00"
                type="number"
              />

              <span className="mx-1"> ⇄ </span>

              <Form.Control
                className="price-filter-input"
                placeholder="00"
                type="number"
              />
              <button className="btn-0">
                <FontAwesomeIcon icon={faFilter} />
              </button>
            </div>
            <br />
            <h6>Brand</h6>
            <div className="product-filter-checkbox">
              {brands.map((item, index) => (
                <Form.Check
                  key={index}
                  name={item}
                  type="checkbox"
                  label={item}
                />
              ))}
            </div>
            <br />
            <h6>Colors</h6>
            <div className="product-filter-checkbox">
              {colors.map((item, index) => (
                <Form.Check
                  key={index}
                  name={item}
                  type="checkbox"
                  label={item}
                />
              ))}
            </div>
            <br />
          </div>
        </Col>
        <Col md={8} lg={9} xl={10}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className="font-14">
              Result for <span className="text-orange">"{formData?.value}" </span>
              <span>{info.length} items</span>
            </p>

            <div className="position-relative d-inline-block mt-1">
              <Form.Select
                aria-label="Default select example"
                className="corporate__navbar__dropdown border-0 py-2 "
              >
                <option>Sort by</option>
                <option value="1">New Arrived</option>
                <option value="2">Free Shiping</option>
              </Form.Select>
              <FontAwesomeIcon
                className="corporate__navbar__icon-down"
                icon={faChevronDown}
              ></FontAwesomeIcon>
            </div>
          </div>
          {/* <Row className="home__Product-box g-2" xs="2" sm="3" md="3" lg="4" xl="5"> */}

          <ProductInfiniteScroll
            product={info}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            hasMore={hasMore}
            zoroLengthShow={true}
          />
          {isLoading ? (
            <BubbleScale extraStyle={{ marginTop: "5rem", marginBottom: "5rem" }} />
          ) : null}
        </Col>
      </Row>
      <br />
    </Container>
  );
};

export default SearchProduct;
