/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import ChatContentHeader from "../Common/ChatContentHeader";
import ChatContentFooter from "./ChatContentFooter";

import { Route, Routes } from "react-router-dom";
import CallingAudioCall from "../AudioCall/CallingAudioCall";
import VideoCallScreen from "../VideoCall/VideoCallScreen";
import LookMe from "../LookMe/LookMe";
import { MessageList } from "./MessageList";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentChattingHasMore } from "src/features/life/messengerSlice";

const ChatContent = () => {
  const messagesEndRef = useRef(null);
  const dispatch = useDispatch();
  const {
    current_chatting_messages: messages,
    current_chatting_user: targetedUser,
    newMessageBool,
    messageLoading,
  } = useSelector((state) => state.messengerData);

  useEffect(() => {
    dispatch(setCurrentChattingHasMore());
    if (messages.length < 16) {
      messagesEndRef?.current?.scrollIntoView();
    }
  }, [messages]);

  useEffect(() => {
    messagesEndRef?.current?.scrollIntoView();
  }, [newMessageBool]);

  return (
    <>
      {messages && targetedUser ? (
        <div className="p-2 ps-3 ps-md-2 chat__content-main pe-0 rounded-3">
          <ChatContentHeader targetedUserInfo={targetedUser} />

          <hr style={{ margin: 0 }} />
          <Routes>
            <Route
              path="/"
              element={
                <div
                  className="chat__content-wrapper position-relative"
                  style={{ overflowY: !messageLoading ? "hidden" : "scroll" }}
                >
                  <div
                    className="chat__content-body"
                    id="chat__content_body"
                    style={{
                      overflowX: "hidden",
                      overflowY: messageLoading ? "hidden" : "scroll",
                    }}
                  >
                    <MessageList />
                    <div ref={messagesEndRef}>{"  "}</div>
                  </div>
                  <div>
                    <ChatContentFooter
                      messagesEndRef={messagesEndRef}
                      to_user={targetedUser.id}
                    />
                  </div>
                </div>
              }
            />
            <Route
              path="/chat"
              element={
                <div
                  className="chat__content-wrapper"
                  style={{ overflowY: !messageLoading ? "hidden" : "scroll" }}
                >
                  <div
                    className="chat__content-body"
                    id="chat__content_body"
                    style={{
                      overflowX: "hidden",
                      overflowY: messageLoading ? "hidden" : "scroll",
                    }}
                  >
                    <MessageList />
                    <div ref={messagesEndRef}>{"  "}</div>
                  </div>
                  <div>
                    <ChatContentFooter
                      messagesEndRef={messagesEndRef}
                      to_user={targetedUser.id}
                    />
                  </div>
                </div>
              }
            />
            <Route path="/voice-call" element={<CallingAudioCall />} />
            <Route path="/video-call" element={<VideoCallScreen />} />
            <Route path="/look-me" element={<LookMe />} />
          </Routes>
        </div>
      ) : (
        <div>
          <p className="text-center">Start Massaging</p>
        </div>
      )}
    </>
  );
};

export default ChatContent;
