import React from "react";
import "./shippingAndBilling.css";
import { Accordion, Form } from "react-bootstrap";
import { useRef } from "react";
import AddressCard from "../AddressCard/AddressCard";
import AddAddressForm from "../AddAddressForm/AddAddressForm";
import useCartStore from "src/Projects/Corporate/hooks/useCartStore";

const ShippingAndBilling = ({ handleBillingAddressChange, errorMessage, doneAccordion, newBillingAddress, setNewBillingAddress }) => {
  const billingFormBtnRef = useRef();
  const { shippingAddress } = useCartStore();

  return (
    <div>
      <h6 className="mb-2">Billing Address</h6>
      <div className="my-3" >
        <Form.Check
          type="radio"
          name="billing-address"
          onChange={handleBillingAddressChange}
          value="same_as_shipping"
          // defaultChecked
          label={
            <AddressCard
              type="billing"
              data={shippingAddress}
            />
          }
        />
        {newBillingAddress?.billing_name && <div className="" >
          <Form.Check
            type="radio"
            name="billing-address"
            value="newBillingAddress"
            onChange={handleBillingAddressChange}
            label={
              <AddressCard
                type="billing"
                data={newBillingAddress}
              />
            }
          />
        </div>}
      </div>

      <Accordion className="shipping-address">
        <Accordion.Item eventKey="0">
          <Accordion.Header ref={billingFormBtnRef}>
            <small>Add New Address</small>
          </Accordion.Header>
          <Accordion.Body>
            <AddAddressForm
              setNewBillingAddress={setNewBillingAddress}
              btnRef={billingFormBtnRef}
            ></AddAddressForm>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <p className="text-center text-danger" style={{ fontSize: ".75rem" }}>
        {errorMessage}
      </p>
      <div className="text-center mt-2">
        <button
          onClick={doneAccordion}
          type="submit"
          className=" send-code-btn text-dark cart-confirm-btn"
        >
          Save
        </button>
      </div>

      <br />
    </div>
  );
};

export default ShippingAndBilling;
