import React from "react";

export const Steps = ({ size = "29px", className = "", fillcolor }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={className}
        d="M19.9379 5.72727H4.66516V3.81818H19.9379V5.72727ZM18.0288 0H6.57425V1.90909H18.0288V0ZM10.6903 18.2318L7.54025 15.0818L8.87662 13.7455L10.6903 15.5591L15.7493 10.5L17.0857 11.8364L10.6903 18.2318Z"
        fill="currentColor"
      />
      <path
        className={className}
        d="M21.8467 7.63672H2.75577C2.24945 7.63672 1.76386 7.83785 1.40584 8.19588C1.04782 8.5539 0.84668 9.03949 0.84668 9.54581L0.84668 19.0913C0.84668 19.5976 1.04782 20.0832 1.40584 20.4412C1.76386 20.7992 2.24945 21.0004 2.75577 21.0004H21.8467C22.353 21.0004 22.8386 20.7992 23.1966 20.4412C23.5546 20.0832 23.7558 19.5976 23.7558 19.0913V9.54581C23.7558 9.03949 23.5546 8.5539 23.1966 8.19588C22.8386 7.83785 22.353 7.63672 21.8467 7.63672ZM21.8467 19.0913H2.75577V9.54581H21.8467V19.0913Z"
        fill="currentColor"
      />
    </svg>
  );
};
