import { prepareApiResponse } from "src/app/helper/rtkFunctions";
import { api } from "./../api";
import {
  getAllVideosUrl,
  getChannelCategoryUrl,
  getCurrentVideoUrl,
  getMeFollowingUrl,
  getOwnPlaylistUrl,
  getPlaylistVideosUrl,
  getReportCategoryUrl,
  getReportContentTypeUrl,
  getVideoCategoriesUrl,
} from "src/Projects/View/common/view_commonUrl";
import {
  ownPlaylist,
  playlistVideos,
  videoCategories,
  homePageVideos,
} from "src/features/view/viewDataSlice";

const viewGetApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHomePageVideos: builder.mutation({
      query: (currentPage = 1) => getAllVideosUrl + "?page=" + currentPage,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(homePageVideos(data));
        } catch (err) {
          console.log(err);
        }
      },
    }),

    getMeFollowing: builder.query({
      query: () => getMeFollowingUrl,
      keepUnusedDataFor: 1,
      providesTags: ["meFollowing"],
    }),

    getChannelCategories: builder.query({
      query: () => getChannelCategoryUrl,
      keepUnusedDataFor: 60 * 60 * 60 * 24,
      providesTags: ["channelCategories"],
    }),

    getReportCategories: builder.query({
      query: () => getReportCategoryUrl,
      keepUnusedDataFor: 60 * 60 * 60 * 24,
      providesTags: ["reportCategories"],
    }),

    getReportContentType: builder.query({
      query: () => getReportContentTypeUrl,
      keepUnusedDataFor: 60 * 60 * 60 * 24,
      providesTags: ["reportContentType"],
    }),

    getOwnPlaylist: builder.mutation({
      query: () => getOwnPlaylistUrl,
      keepUnusedDataFor: 60 * 60 * 60 * 24,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(ownPlaylist(data));
        } catch (err) {
          console.log(err);
        }
      },
      providesTags: ["channelPlaylist"],
    }),

    getVideoCategories: builder.mutation({
      query: () => getVideoCategoriesUrl,
      keepUnusedDataFor: 60 * 60 * 60 * 24,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(videoCategories(data));
        } catch (err) {}
      },
      providesTags: ["videoCategories"],
    }),

    getVideoDetails: builder.query({
      query: (videoId) => getCurrentVideoUrl + videoId,
      providesTags: ["videoDetails"],
    }),

    getPlaylistVideos: builder.mutation({
      query: ({ playlistId, currentPage = 1 }) =>
        getPlaylistVideosUrl + playlistId + "?page=" + currentPage,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(playlistVideos(data));
        } catch (err) {
          console.log(err);
          throw Error(err);
        }
      },
    }),
  }),
});

export const {
  useGetHomePageVideosMutation,
  useGetMeFollowingQuery,
  useGetChannelCategoriesQuery,
  useGetReportCategoriesQuery,
  useGetReportContentTypeQuery,
  useGetPlaylistVideosMutation,
  useGetOwnPlaylistMutation,
  useGetVideoCategoriesMutation,
  useGetVideoDetailsQuery,
} = viewGetApi;
