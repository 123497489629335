import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export const ModalBody2 = ({ handleChange, postData }) => {
  return (
    <div className="createChannel my-4">
      <Form>
        <Form.Group className="mb-1 mb-lg-3">
          <Form.Label>Video Title</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={postData.title}
            onChange={handleChange}
            placeholder="Video Title"
          />
        </Form.Group>

        <Form.Group className="mt-3">
          <Form.Label>Video Description</Form.Label>
          <Form.Control
            as="textarea"
            onChange={handleChange}
            name="description"
            value={postData.description}
            rows={5}
            placeholder="Video Description"
          />
        </Form.Group>

        <Form.Group className="mt-3">
          <Form.Label>Keyword's</Form.Label>
          <Col sm={12} className=".pe-0">
            <Form.Control
              type="text"
              name="tag"
              value={postData.tag}
              onChange={handleChange}
              placeholder="keywords"
            />
          </Col>
        </Form.Group>
      </Form>
    </div>
  );
};
