import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Toast } from "react-bootstrap";

const AlertToast = ({ setShow, show, text = "text" }) => {
  return (
    <Toast
      onClose={() => setShow(false)}
      show={show}
      delay={3000}
      autohide
      style={toastStyles}
      className="secondary-bg"
    >
      <Toast.Header>
        <img
          className="img-fluid"
          src="/images/logo.svg"
          alt="logo"
          width={30}
          height={30}
        />
        <strong className="me-auto ms-2 fs-5 fw-bold">UVIOM</strong>
      </Toast.Header>
      <Toast.Body>
        <FontAwesomeIcon
          icon={faCheckCircle}
          size="lg"
          className="text-orange me-2"
        ></FontAwesomeIcon>
        {text}
      </Toast.Body>
    </Toast>
  );
};

export default AlertToast;

const toastStyles = {
  position: "fixed",
  top: "5px",
  right: "5px",
  zIndex: "111",
  width: "250px",
};
