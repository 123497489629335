import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useParams } from "react-router-dom";
import { useGetDivisionJobPostQuery } from "src/app/services/jobApi/jobGetApi";
import JobSkeleton from "../../Components/Placeholder/JobSkeleton";
import SingleJobPost from "../../Components/SingleJobPost/SingleJobPost";
import { getDivisionByPost } from "../../utilities/job_commonUrl";

const DivisionByPost = () => {
  const { divisionName, divisionId } = useParams();
  const [postList, setPostList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { pathname } = useLocation();

  const url = getDivisionByPost + divisionId;

  const {
    data: result,
    isFetching,
    error,
  } = useGetDivisionJobPostQuery({ page: currentPage, url: url });
  const { data, hasMore } = result || {};

  // if pathname is changed then postList and currentPage set default
  useEffect(() => {
    setPostList([]);
    setCurrentPage(1);
  }, [pathname]);

  //  if scroll loading add previous page data
  useEffect(() => {
    if (data) {
      setPostList((prevState) => [...prevState, ...data]);
    }
  }, [data]);

  return (
    <div className="secondary-bg p-2 py-3">
      <InfiniteScroll
        dataLength={postList.length} //This is important field to render the next data
        next={() => {
          setCurrentPage(currentPage + 1);
        }}
        hasMore={hasMore}
      >
        <div className="jobRecommended p-2 pb-4">
          <p className="text-orange">{divisionName}</p>

          {postList.length < 1 && !isFetching && (
            <p className="text-center mt-4"> 0 item Found </p>
          )}
          {isFetching && (
            <Row className="g-3" xxl="2">
              {[...new Array(6)].map((_, index) => (
                <JobSkeleton key={index} />
              ))}
            </Row>
          )}
          <Row className="g-3">
            {postList.map((post) => (
              <SingleJobPost key={post.id} data={post} />
            ))}
          </Row>
          {error && <p className="text-danger">{error.error}</p>}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default DivisionByPost;
