import { useEffect, useState } from "react";

const ReadMore = ({ children, minLength = 100 }) => {
  const text = children.toString();
  const [isReadMore, setIsReadMore] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const yourString = text.substring();

  let trimmedString = yourString.substr(0, minLength);
  //re-trim if we are in the middle of a word
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  );
  useEffect(() => {
    if (text.length > minLength) {
      setIsReadMore(true);
    }
  }, [minLength, text.length]);
  return (
    <div>
      {isReadMore ? trimmedString : text}
      {text.length > minLength && (
        <span onClick={toggleReadMore} className="pointer fw-600">
          {isReadMore ? (
            <>...Read More</>
          ) : (
            <>
              <br />
              Show Less
            </>
          )}
        </span>
      )}
    </div>
  );
};

export default ReadMore;
