import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import CancelOrderModal from "./../Common/Modal/CancelOrderModal";
import SendSourceFileModal from "../Common/Modal/SendSourceFileModal";

import "./runningProject.css";

function RunningProject() {
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);

  const [showSendSourceFileModal, setShowSendSourceFileModal] = useState(false);

  const { pathname:url } = useLocation();
  const dynamicPath = url.split("/")[1];
  const dynamicUser = url.split("/")[2];

  return (
    <div>
      <CancelOrderModal
        show={showCancelOrderModal}
        onHide={() => setShowCancelOrderModal(false)}
      />

      <SendSourceFileModal
        show={showSendSourceFileModal}
        onHide={() => setShowSendSourceFileModal(false)}
      />

      <div className="projectPreview">
        <div className="projectPreview__top">
          <img
            src="https://i.ibb.co/F8yxsjF/client1.jpg"
            alt=""
            className="projectPreview__user__img"
          />

          <div className="projectPreview__user">
            <p className="projectPreview__user__name">Ying g</p>
            <p className="projectPreview__user__location">Thailand</p>
            <p className="projectPreview__user__rating">
              <FontAwesomeIcon icon={faStar} />
              5.0 <span>(12)</span>
            </p>
          </div>

          <div className="">
            <p className="border d-inline-block mb-1 p-2 rounded">
              {url.includes("orders/complete")
                ? "Complete Order Jun-06-2022,Est 10:46 Am"
                : url.includes("orders/cancel")
                ? "Cancel Order Jun-06-2022,Est 10:46 Am "
                : url.includes("orders/pending")
                ? "Delivery date -20/12/2022 "
                : "Time:-3 Day, 05:55:30"}
            </p>
            <div className="d-flex justify-content-md-between">
              <p></p>
              <p>
                Price <span className="fw-500">$100</span>
              </p>
            </div>
          </div>
        </div>

        <h5>
          The Standard Chunk Of Lorem Ipsum Used Since The 1500s Is Reproduced
          Below For
        </h5>
        <p>
          Company name is bramble & bee. It's a Honey Company Logo. It was for a
          contest that I won here on Freelancer.com. it was a great experience
          doing this logo and working for this contest. Always did design
        </p>
        <h6 className="mb-2">Jobs Task</h6>
        <div className="d-flex flex-wrap gap-2 gap-sm-3">
          <span className="creative__skills__badge">UX Research</span>
          <span className="creative__skills__badge">making solution</span>
          <span className="creative__skills__badge">
            information architecture
          </span>
          <span className="creative__skills__badge">wireframe</span>
          <span className="creative__skills__badge">mockup</span>
        </div>

        {/* Don't show this components if on Order/complete or order/pending or order/cancel */}
        {!url.includes("orders/cancel") &&
          !url.includes("orders/complete") &&
          !url.includes("orders/pending") && (
            <>
              <hr />
              <div className="d-flex gap-2 gap-sm-3 flex-wrap justify-content-sm-end">
                <Button
                  onClick={() => setShowCancelOrderModal(true)}
                  variant="outline-secondary"
                >
                  Cancel
                </Button>
                <Button
                  as={Link}
                  to={`/${dynamicPath}/${dynamicUser}/messages`}
                  className="btn__creative order-first order-sm-last"
                >
                  Get In Touch
                </Button>

                {url.includes("orders/running") && (
                  <Button
                    onClick={() => setShowSendSourceFileModal(true)}
                    className="btn__creative order-first order-sm-last"
                  >
                    Send Final Work
                  </Button>
                )}
              </div>
            </>
          )}
        {/* Only on Order pending page */}
        {url.includes("orders/pending") && (
          <div className="d-flex gap-2 gap-sm-3  flex-wrap mt-3">
            <div className="flex-grow-1 text-center border mb-1 p-2 rounded">
              Review Time -5 day, 10-40-42
            </div>
            <Button className="flex-grow-1 btn__creative"> Fund Request</Button>
          </div>
        )}

        {/* Only on Order cancel page */}
        {url.includes("orders/cancel") && (
          <>
            <h6 className="mt-3">Cancelling Reason</h6>
            <p className="mb-0 p-3 border rounded">
              Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Risus,
              Fringilla Ac Placerat Pretium Eu. Porttitor Tincidunt Et In
              Natoque Ac Non Augue Tincidunt. Proin Lacus Sit Amet, Ut Aliquet
              In Duis.
            </p>
          </>
        )}
      </div>
    </div>
  );
}
export default RunningProject;
