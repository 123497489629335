import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTheme from "src/hooks/useTheme";
import useStore from "src/hooks/useStore";
import {
  faCog,
  faDesktop,
  faHistory,
  faIndent,
  faSave,
  faSignOutAlt,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import ViewIcon from "./../../Icons/ViewIcon";
import { LookMeWhiteIcon } from "../LookMeWhiteIcon";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import OwnFollowingModal from "../OwnFollowingModal";
import { useState } from "react";
import { api } from "src/app/services/api";

export const RightCol = () => {
  const [showFollowingModal, setShowFollowingModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { ownPlaylist } = useSelector((store) => store.viewData);
  const { handleLogout } = useStore();
  const { currentTheme, handleThemeChange } = useTheme();

  return (
    <>
      {showFollowingModal && (
        <OwnFollowingModal
          show={showFollowingModal}
          handleClose={() => {
            setShowFollowingModal(false);
            dispatch(api.util.invalidateTags(["meFollowing"]));
          }}
        />
      )}

      <div className="RightCol">
        <div className="inner stickyParts">
          <div className="LookMeWhiteIcon box-shadow nav-bg-opacity stickyParts py-2 ps-3 d-block">
            <div className="d-flex align-items-center">
              <div
                onClick={() => navigate("/dashboard")}
                className="LookMeButtonTypeIcon"
              >
                <ViewIcon size="40" />
              </div>
              <div onClick={() => navigate("/view")}>
                <div className="type-writer-text-box text-nowrap">
                  <h5 className="mb-0">
                    <span>UVIOM</span>
                    <span className="text-orange">View</span>
                  </h5>
                  <div className="lh-1 type-writer-text logo-slogan d-block">
                    <Typewriter
                      options={{
                        strings: "Explore Your New World",
                        autoStart: true,
                        loop: true,
                      }}
                    ></Typewriter>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LinkContainer>
            <div onClick={() => setShowFollowingModal(true)}>
              <LookMeWhiteIcon>
                <FontAwesomeIcon icon={faUserFriends} />
                Following
              </LookMeWhiteIcon>
            </div>

            <LookMeWhiteIcon RouteName={`save_video`}>
              <FontAwesomeIcon icon={faSave} /> Save Video
            </LookMeWhiteIcon>

            <LookMeWhiteIcon RouteName={`liked`}>
              <FontAwesomeIcon icon={faThumbsUp} /> Liked Video
            </LookMeWhiteIcon>

            <LookMeWhiteIcon RouteName={`watchLater`}>
              <FontAwesomeIcon icon={faHistory} /> Watch Later
            </LookMeWhiteIcon>

            {ownPlaylist &&
              ownPlaylist.map((playlist) => (
                <LookMeWhiteIcon
                  key={playlist.id}
                  RouteName={`playlist/${playlist.id}`}
                >
                  <FontAwesomeIcon icon={faIndent} />
                  {playlist.name}{" "}
                  <span className="badge">{playlist.videos_count}</span>
                </LookMeWhiteIcon>
              ))}

            <LookMeWhiteIcon RouteName="playlist">
              <FontAwesomeIcon icon={faDesktop} />
              Look Me
            </LookMeWhiteIcon>

            <LookMeWhiteIcon>
              <Form.Check
                onChange={handleThemeChange}
                checked={currentTheme === "dark"}
                type="switch"
                label={
                  <span className="d-inline-block lh-base">
                    {currentTheme === "dark"
                      ? "Switch To Light"
                      : "Switch To Dark"}
                  </span>
                }
                id="disabled-custom-switch"
              />
            </LookMeWhiteIcon>

            <div onClick={() => handleLogout(navigate)}>
              <LookMeWhiteIcon>
                <FontAwesomeIcon icon={faSignOutAlt} />
                Logout
              </LookMeWhiteIcon>
            </div>
          </LinkContainer>
        </div>
      </div>
    </>
  );
};

export const LinkContainer = styled.div`
  /* 992px */
  @media (max-width: 62em) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
  /* 500px */
  @media (max-width: 31.25em) {
    grid-template-columns: 1fr;
  }
`;
