import { Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import "./payAndReviewModal.css";

function PayAndReviewModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="creative__modal__header" closeButton>
        <h6 className="mb-0">Pay & Review</h6>
      </Modal.Header>
      <Modal.Body className="creative__modal__body">
        <div className="creative__modal__payment">
          <div className="creative__modal__freelancer">
            <img
              className="creative__modal__freelancer__img"
              src="https://i.ibb.co/swSj3DD/freelancer2.jpg"
              alt=""
            />
            <div>
              <h6>Freelancer</h6>
              <p className="mb-0">Thailand</p>
              <p>
                <FontAwesomeIcon className="text-orange" icon={faStar} /> 5.0
                (12)
              </p>
            </div>

            <table className="creative__modal__freelancer__pay">
              <tbody>
                <tr>
                  <td>Agreement Price</td>
                  <td>$200</td>
                </tr>
                <tr>
                  <td>Tips</td>
                  <td>
                    <input type="text" className="input-reset" placeholder="0" />
                  </td>
                </tr>
                <tr>
                  <td>Total Amount</td>
                  <td>$200</td>
                </tr>
              </tbody>
            </table>
          </div>

          <Form>
            <Form.Control
              as="textarea"
              className="shadow-0 my-4 input-reset"
              placeholder="Comments For Freelancer Work"
              rows={6}
            />
          </Form>

          <div className="d-sm-flex justify-content-between align-items-center">
            <p className="d-flex gap-2 align-items-center fw-500">
              Get Feedback:{" "}
              <span className="d-inline-flex gap-2">
                <FontAwesomeIcon icon={faStar} className="text-orange" />
                <FontAwesomeIcon icon={faStar} className="text-orange" />
                <FontAwesomeIcon icon={faStar} className="text-orange" />
                <FontAwesomeIcon icon={faStar} className="text-orange" />
                <FontAwesomeIcon icon={faStar} />
              </span>
            </p>

            <Button
              onClick={() => {
                props.onHide();
                props.onSuccess();
              }}
              className="btn__creative"
            >
              Pay
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default PayAndReviewModal;
