import React from 'react';
import OrderItemCard from '../Common/OrderItemCard';
import OrderItemCardSample from '../Common/OrderItemCardSample';

const CanceledOrder = () => {
    return (
        <div className='order-history p-3'>
            <h6 className="fs-6 py-3"> Canceled Order </h6>
            
            <OrderItemCardSample status="Canceled"/>
            <OrderItemCardSample status="Canceled"/>
            <OrderItemCardSample status="Canceled"/>
        </div>
    );
};

export default CanceledOrder;