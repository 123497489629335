import React from 'react';
import BestTimeProfileBanner from '../BestTimeProfileBanner/BestTimeProfileBanner';
import FamilyBestTime from '../Common/FamilyBestTime';
import FriendBestTime from '../Common/FriendBestTime';
import ProfessionalBestTime from '../Common/ProfessionalBestTime';

const BestTimeOtherProfile = () => {
    return (
        <div>
            <BestTimeProfileBanner showFollowBtn />
            <br />
            <FamilyBestTime />
            <FriendBestTime />
            <ProfessionalBestTime/>
        </div>
    );
};

export default BestTimeOtherProfile;