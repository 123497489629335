import React from "react";

import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import VideoPlayer from "src/Common/VideoPlayer/VideoPlayer";

const videoUrl =
  "https://uviom-life-running.s3.ap-southeast-1.amazonaws.com/test/Advice+to+Young+People+And+His+Biggest+Regret+Jack+Ma+Goal+Quest.mp4";
const LifeVideoProfileBanner = () => {
  return (
    <div className="bestTimeBanner">
      <Row className="g-3 h-100">
        <Col
          xs="12"
          sm="7"
          className="d-flex flex-column justify-content-between h-100"
        >
          <div className="d-flex justify-content-between align-items-center ">
            <div className="ProfileAndName">
              <div className="PostSectionProfile">
                <img className="img-fluid" src="/user.png" alt="user" />
              </div>
              <div className="ms-2">
                <h6 className="my-0 smallTextSize-sm bestTimeProfileName">
                  Nahid Ahmed
                </h6>
                <div className="smallTextSize-sm">
                  <small>nahidahmed@235</small>
                </div>
              </div>
            </div>
          </div>

          <div className="bestTimeProfileInfo mt-5">
            <div>
              <button type="button" className="bestTimeFollowBtn me-3">
                Follow
              </button>
              <button type="button" className="bestTimeBellBtn">
                <FontAwesomeIcon icon={faBell} />
              </button>
            </div>
            <div className="bestTimeProfileInfoDivOne border-0 mt-2">
              <p className="bestTimeProfileInfoItem mb-2">
                <span>Followers</span> <span>10.11 M</span>
              </p>
            </div>
          </div>
        </Col>

        <Col xs="12" sm="5" className="h-100">
          <p className="d-block d-sm-none">Recent Video</p>
          <div className="  bestTimeProfileVideo">
            <VideoPlayer
              url={videoUrl}
              control={false}
              playIconSize="40px"
              aspectRatio="4/3"
              width="100%"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LifeVideoProfileBanner;
