import React from 'react';
import { createContext } from 'react';
import useStore from '../hooks/useStore';


export const StoreContext = createContext();

const FoodStoreProvider = ({children}) => {
    return (
        <StoreContext.Provider value={useStore()}>
            {children}
        </StoreContext.Provider>
    );
};

export default FoodStoreProvider;