import { Link, useLocation } from "react-router-dom";

function FindFreelancerSidebar() {
  const { pathname } = useLocation();
  return (
    <div className="findFreelancer__sidebar">
      <Link className="findFreelancer__sidebar__link" to={pathname}>
        Design
      </Link>
      <Link className="findFreelancer__sidebar__link" to={pathname}>
        Development
      </Link>
      <Link className="findFreelancer__sidebar__link" to={pathname}>
        Marketing
      </Link>
      <Link className="findFreelancer__sidebar__link" to={pathname}>
        Animation
      </Link>
      <Link className="findFreelancer__sidebar__link" to={pathname}>
        Audio
      </Link>
      <Link className="findFreelancer__sidebar__link" to={pathname}>
        More &rarr;
      </Link>
    </div>
  );
}
export default FindFreelancerSidebar;
