/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./SingleJobPost.css";
import { calculate_between_2_date } from "src/utilities/utilities";
import { useState } from "react";
import { companyProfilePhotoUrl } from "../../utilities/job_commonUrl";
import { Col } from "react-bootstrap";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import { useSaveJobPostMutation } from "src/app/services/jobApi/jobPostApi";
import { useEffect } from "react";
import { showToast } from "../../utilities/functions";
import { useSelector } from "react-redux";

const SingleJobPost = ({ data }) => {
  const navigate = useNavigate();
  const [savePost, { isLoading, isSuccess, error }] = useSaveJobPostMutation();
  const { applicant_profile } = useSelector((store) => store.jobProfile);

  const {
    range_start,
    range_end,
    job_title,
    company_profile_section,
    company_social_link_section,
    id,
    job_save_count,
    job_apply_count,
    company_job_category,
    company_job_sub_category,
    job_address,
    job_details,
    job_requirements,
    job_type,
    role_responsibility,
    deadline,
    district,
    division,
  } = data;

  const [jobSave_status, setJobSave_status] = useState(
    job_save_count == "0" ? false : true
  );

  if (error) {
    console.log(error);
  }

  const jobDetailsData = {
    id,
    profile_pic: company_profile_section?.profile_picture,
    banner_pic: company_profile_section?.profile_banner,
    category_id: company_job_category?.id,
    category_name: company_job_category?.title,
    sub_category_id: company_job_sub_category?.id,
    sub_category_name: company_job_sub_category?.title,
    job_type,
    job_address,
    job_apply_count,
    job_details,
    job_requirements,
    job_save_count,
    job_title,
    range_end,
    range_start,
    role_responsibility,
    deadline,
    company_profile_section,
    company_social_link_section,
  };

  useEffect(() => {
    if (isSuccess) {
      setJobSave_status(true);
      jobDetailsData["job_save_count"] = "1";
      showToast({ title: "Job post save successfully" });
    }
  }, [isSuccess]);

  const handleSave = (e) => {
    e.stopPropagation();

    if (applicant_profile?.id) {
      savePost(id);
    } else {
      navigate("/Job/create-applicant-profile");
      showToast({
        title: "Please Create you Applicant Profile",
        icon: "error",
      });
    }
  };

  const handleJobClick = () => {
    navigate(`/Job/job-details/${job_title}/${id}`, {
      state: {
        jobData: jobDetailsData,
      },
    });
  };
  let dayLeft = calculate_between_2_date(data.deadline);

  return (
    <Col xxl="6 h-100">
      {isLoading && <BubbleLoader />}

      <div
        onClick={handleJobClick}
        className="pointer primary-text job_cart-hover"
      >
        <div className="singleJobPostContainer d-sm-flex ">
          <div className="jobPostImgContainer align-items-center d-flex gap-3">
            <div className="jobPostImgDiv">
              <img
                src={`${
                  company_profile_section?.profile_picture
                    ? companyProfilePhotoUrl +
                      company_profile_section.profile_picture
                    : "/user.png"
                }`}
                className="img img-fluid"
                alt=""
              />
            </div>
            <div className="jobPostTitleText">
              <h6 className="my-0  fw-bold text-ellipsis">
                <span>{job_title}</span>
              </h6>
              <p className="my-0 text-ellipsis ">
                <span className="font-14">
                  {company_profile_section?.company_name || "No Name Found"}
                </span>
              </p>
              <p className="my-0  text-ellipsis ">
                <span className="font-14">
                  {district
                    ? (district ? district.name : "") +
                      " " +
                      (division ? division.name : "")
                    : "Not Available"}
                </span>
              </p>

              <p className="my-0 fs-6 text-ellipsis">
                <span className="font-14">
                  {" "}
                  ${range_start} - ${range_end}/month
                </span>
              </p>
            </div>
          </div>
          <div className="py-2 py-sm-0 py-xxl-2  d-flex flex-sm-column align-items-center justify-content-sm-between justify-content-evenly ">
            <button
              disabled={jobSave_status}
              type="button"
              onClick={handleSave}
              className="eco-btn job-save-btn ms-sm-auto ms-xxl-0"
            >
              <FontAwesomeIcon icon={faBookmark}></FontAwesomeIcon>
            </button>
            {job_apply_count === "1" && (
              <small className="isApplied-tag">applied</small>
            )}
            <span className="font-14">
              {dayLeft < 1 ? "Time over" : `${dayLeft}d left`}{" "}
            </span>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default SingleJobPost;
