import { NavLink, useLocation } from "react-router-dom";

import profileAvatar from "./../../assets/profile-avatar.svg";
import profileHistory from "./../../assets/profile-history.svg";
import profileBank from "./../../assets/profile-bank.svg";

import rabiul from "./../../../Ride/assets/rabiul.jpg"; 

import "./leftSidebar.css";

function LeftSidebar() {
  const { pathname: url } = useLocation();
  const dynamicPath = url.split("/")[1];

  return (
    <div className="leftSidebar mb-4 text-center">
      <img className="leftSidebar__avatar mb-3" src={rabiul} alt="" />
      <h5>Rabiul Islam</h5>
      <hr className="my-4" />
      <div className="mt-4 text-start">
        <NavLink
          className="leftSidebar__link"
          to={`/${dynamicPath}/profile/personal-info`}
        >
          <span>
            <img src={profileAvatar} alt="" />
          </span>{" "}
          Update Profile
        </NavLink>
        <NavLink
          className="leftSidebar__link"
          to={`/${dynamicPath}/profile/receivedhistory`}
        >
          <span>
            <img src={profileHistory} alt="" />
          </span>{" "}
          Received History
        </NavLink>
        <NavLink
          className="leftSidebar__link"
          to={`/${dynamicPath}/profile/senthistory`}
        >
          <span>
            <img src={profileHistory} alt="" />
          </span>{" "}
          Sent History
        </NavLink>
        <NavLink
          className="leftSidebar__link"
          to={`/${dynamicPath}/profile/billinghistory`}
        >
          <span>
            <img src={profileBank} alt="" />
          </span>{" "}
          Billing
        </NavLink>
      </div>
    </div>
  );
}
export default LeftSidebar;
