import React from "react";
import LogoBlink from "src/Common/LogoBlink";
import "./Login&Registrasion.css";
import SignUpPart from "./SignUpPart";

const SignupBlock = () => {
  return (
    <div className="Feature">
      <div className="InnerFeature">
        <div className="FeatureBlock LoginBlock">
          <div className="pt-5">
            <LogoBlink />
          </div>
          <SignUpPart />
        </div>
      </div>
    </div>
  );
};

export default SignupBlock;
