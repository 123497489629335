export const DuplicateVendor = (cartItems) => {
    var holder = {};
    cartItems.forEach(function (d) {
      if (holder.hasOwnProperty(d.vendor_id)) {
        holder[d.vendor_id] = holder[d.vendor_id] + d.eachItemTotal;
      } else {
        holder[d.vendor_id] = d.eachItemTotal;
      }
    });
    var obj2 = [];
    for (var prop in holder) {
      obj2.push({ vendor_id: prop, total_amount: holder[prop] });
    }
    return obj2;
  }
const ModifyData = (data) => {
    // console.log("create req: ", data);
    const cartlist = [];
    const vendor_order = [];
    data.map((item) => {
        cartlist.push({
            pID: item.id,
            pPrice: item.sale_price,
            pQty: item.quantity,
            vendor_id: item.vendor_id
        })
    })

    return [cartlist, vendor_order]
}
export default ModifyData;
