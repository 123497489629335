import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleChatListPopup } from "src/features/life/messengerSlice";
import { mainProfilePic } from "src/Projects/Life/Common/CommonUrl";
import styled from "styled-components";

const PopupMessageBubble = () => {
  const dispatch = useDispatch();

  const bubbleClickHandle = () => {
    dispatch(toggleChatListPopup());
  };

  const { current_chatting_user = {} } = useSelector(
    (store) => store.messengerData
  );

  return (
    <Wrapper
      hasCurrent={current_chatting_user.photo && true}
      onClick={bubbleClickHandle}
    >
      {/* <audio id="myAudio" src={song} ></audio> */}
      {current_chatting_user.photo ? (
        <>
          <div className="relative">
            <img
              className="avatar"
              src={mainProfilePic + "/" + current_chatting_user.photo}
              onError={(e) =>
                (e.target.src = "https://i.ibb.co/MprdZS6/user.png")
              }
              alt={
                current_chatting_user.first_name +
                current_chatting_user.last_name
              }
            />
            <svg
              className="helper"
              width="73"
              height="72"
              viewBox="0 0 73 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M65.0195 23.7837C63.4427 20.0202 61.1487 16.5995 58.2654 13.7122C55.3907 10.8198 51.9759 8.52022 48.215 6.94395C44.3621 5.32134 40.2225 4.4896 36.042 4.49811H35.9014C31.6493 4.5192 27.5378 5.36259 23.6652 7.02126C19.9365 8.61369 16.5538 10.9174 13.7061 13.8035C10.8508 16.6844 8.58305 20.0931 7.02929 23.8399C5.41641 27.7361 4.59915 31.9156 4.62562 36.1324C4.64671 41.01 5.8134 45.8525 7.99216 50.1819V60.8648C7.99216 62.65 9.43999 64.0978 11.2181 64.0978H21.8871C26.2367 66.2927 31.0365 67.4475 35.9085 67.4714H36.056C40.259 67.4714 44.3283 66.6561 48.1658 65.0607C51.9077 63.5032 55.3097 61.2305 58.181 58.3698C61.0767 55.4952 63.3538 52.1357 64.9493 48.3897C66.6009 44.5101 67.4443 40.3845 67.4654 36.1253C67.4794 31.8451 66.6501 27.6914 65.0195 23.7837ZM22.0136 39.3583C20.1581 39.3583 18.647 37.8473 18.647 35.9848C18.647 34.1223 20.1581 32.6112 22.0136 32.6112C23.869 32.6112 25.3801 34.1223 25.3801 35.9848C25.3801 37.8473 23.8761 39.3583 22.0136 39.3583ZM36.042 39.3583C34.1865 39.3583 32.6754 37.8473 32.6754 35.9848C32.6754 34.1223 34.1865 32.6112 36.042 32.6112C37.8975 32.6112 39.4085 34.1223 39.4085 35.9848C39.4085 37.8473 37.8975 39.3583 36.042 39.3583ZM50.0704 39.3583C48.2149 39.3583 46.7039 37.8473 46.7039 35.9848C46.7039 34.1223 48.2149 32.6112 50.0704 32.6112C51.9259 32.6112 53.437 34.1223 53.437 35.9848C53.437 37.8473 51.9259 39.3583 50.0704 39.3583Z"
                fill="#F1A350"
              />
            </svg>
          </div>
        </>
      ) : (
        <div className="noAvatar" />
      )}
    </Wrapper>
  );
};

export default PopupMessageBubble;

const Wrapper = styled.div`
  background-color: var(--bg-secondary);
  padding: ${(props) => (!props.hasCurrent ? "0.4rem" : "")};
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 1111;
  border-radius: 100%;
  cursor: pointer;
  color: var(--text-success);

  background-repeat: no-repeat;
  background-position: center;
  background-image: ${(props) =>
    !props.hasCurrent
      ? "url(https://res.cloudinary.com/yex/image/upload/v1668345196/UVIOM/Message_vx8waa.png)"
      : ""};
  --size: 2rem;
  border: 1px solid var(--nav-transparent-bg);

  & .avatar {
    height: calc(var(--size) * 1.3);
    width: calc(var(--size) * 1.3);
    border-radius: 50%;
    border: 2px solid #f1a350;
  }
  & .helper {
    position: absolute;
    width: 1rem;
    height: 1rem;
    bottom: 0px;
    left: 0;
  }

  & .noAvatar {
    height: var(--size);
    width: var(--size);
  }
`;
