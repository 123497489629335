import React from 'react'

export const LifeIcon = ({size="",className="",fillcolor}) => {
  return (
    <svg width={size? size:"46"} height={size? size:"47"} viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.9998 10.042L26.0084 10.0581V10.0507C26.0051 10.0482 26.0022 10.0453 25.9998 10.042ZM26.4966 8.80737H26.4705L26.6493 9.11291L26.4966 8.80737Z" fill="#F1A350"/>
    <path d="M20.5676 3.43028C20.4574 3.11777 20.4099 2.78661 20.4277 2.45573C20.4456 2.12485 20.5284 1.80072 20.6716 1.50187C20.8147 1.20302 21.0153 0.935294 21.262 0.713994C21.5086 0.492694 21.7964 0.322152 22.109 0.212112L22.1972 0.17982C22.8227 -0.0335699 23.5074 0.0100857 24.1008 0.301197C24.6942 0.592309 25.1478 1.10707 25.3619 1.73239C25.5764 2.39555 25.5201 3.1166 25.2053 3.73846C24.8906 4.36032 24.3428 4.8326 23.6814 5.0524C23.3705 5.15289 23.0428 5.19056 22.7172 5.16321C22.3916 5.13586 22.0747 5.04404 21.785 4.89309C21.4952 4.74214 21.2384 4.53508 21.0294 4.28395C20.8204 4.03282 20.6634 3.74264 20.5676 3.43028Z" fill="#F1A350"/>
    <path d="M27.0312 9.77388C26.9732 9.89993 26.8785 10.0055 26.7595 10.0769C26.6405 10.1482 26.5028 10.182 26.3643 10.1738C26.2382 10.1658 26.1169 10.1228 26.014 10.0496C26.0112 10.0492 26.0086 10.0482 26.0062 10.0467C26.0039 10.0452 26.0019 10.0432 26.0003 10.0409L25.7246 9.5677L25.5395 9.25097L24.8651 8.09338C24.4117 10.1415 24.1199 12.14 23.8243 14.1347C23.746 14.6427 23.6665 15.1619 23.5423 15.66C23.5013 15.8227 23.3212 15.9494 23.202 16.0947C23.089 15.9295 22.8778 15.7655 22.8766 15.5979C22.8629 14.7384 22.9064 13.8702 22.9238 13.0144C22.9238 12.6728 22.9151 12.3338 22.9101 11.9922C22.7673 12.2589 22.6999 12.5595 22.7151 12.8616C22.6903 13.7472 22.6903 14.634 22.6282 15.5171C22.6145 15.7059 22.3872 15.8786 22.2555 16.0599C22.1413 15.8922 21.95 15.737 21.9227 15.5519C21.8208 14.9694 21.7724 14.3794 21.719 13.7894C21.5418 11.885 21.3654 9.97758 21.1899 8.0673L21.0358 8.0201L20.2633 9.2398L20.0149 9.63974C19.9271 9.69942 19.8254 9.73558 19.7197 9.74479C19.6139 9.754 19.5075 9.73594 19.4107 9.69234C19.3139 9.64874 19.2299 9.58105 19.1667 9.49573C19.1036 9.41042 19.0633 9.31033 19.0498 9.20502C19.1926 8.98642 19.333 8.76658 19.4721 8.54922C19.9801 7.76175 20.4657 6.98795 21.011 6.25762C21.146 6.09536 21.3119 5.96159 21.4992 5.86414C21.6864 5.76668 21.8912 5.70749 22.1015 5.69C25.0986 5.6664 24.4614 5.33353 25.9991 7.87229C26.1804 8.17162 26.3382 8.48214 26.4959 8.80507H26.4698L26.6499 9.11062L26.7878 9.37891L26.8015 9.37145L26.881 9.50808L27.0312 9.77388Z" fill="#F1A350"/>
    <path d="M15.9489 11.741H15.9662V11.7323C15.9643 11.7351 15.9617 11.7374 15.9587 11.7389C15.9556 11.7404 15.9523 11.7412 15.9489 11.741ZM15.4235 10.5213C15.4158 10.5258 15.4087 10.5312 15.4023 10.5374L15.7464 10.6281L15.4235 10.5213Z" fill="#F1A350"/>
    <path d="M7.43235 10.9027C6.82962 10.6127 6.36672 10.0952 6.14545 9.46404C5.92418 8.83284 5.96265 8.1396 6.2524 7.53675L6.29214 7.45105C6.58331 6.85773 7.09817 6.40433 7.72354 6.19051C8.34891 5.9767 9.03359 6.01998 9.62706 6.31084C10.2473 6.62821 10.7171 7.17764 10.9344 7.83962C11.1516 8.50159 11.0987 9.22256 10.7871 9.84573C10.6383 10.1373 10.433 10.3964 10.183 10.6078C9.93303 10.8193 9.64349 10.9788 9.33123 11.0772C9.01898 11.1756 8.69026 11.2108 8.36425 11.1809C8.03824 11.1509 7.72144 11.0564 7.43235 10.9027Z" fill="#F1A350"/>
    <path d="M16.4881 10.8258C16.5233 10.92 16.5374 11.0208 16.5293 11.1211C16.5212 11.2214 16.4912 11.3187 16.4413 11.4061C16.3914 11.4934 16.3228 11.5688 16.2406 11.6267C16.1583 11.6846 16.0643 11.7237 15.9652 11.7412H15.9503L15.4199 11.5996L15.0647 11.5077L13.7692 11.1649C14.897 12.9335 16.1068 14.5532 17.3041 16.1728C17.6097 16.5902 17.9251 17.01 18.186 17.4509C18.2435 17.6274 18.2659 17.8135 18.2518 17.9986C18.0543 17.9626 17.7898 17.9986 17.6718 17.8744C17.0507 17.2758 16.4757 16.6324 15.8782 16.0114C15.641 15.7629 15.3913 15.5356 15.1454 15.2997C15.2338 15.5899 15.399 15.8508 15.6236 16.0548C16.2335 16.6982 16.8657 17.3267 17.4407 17.9949C17.565 18.1378 17.5264 18.4209 17.5649 18.6408C17.3613 18.6381 17.1601 18.596 16.9725 18.5166C16.4893 18.1763 16.0372 17.7937 15.5801 17.4161C14.1062 16.1964 12.6332 14.9726 11.1609 13.7446L11.0193 13.8204L11.3311 15.2251L11.4329 15.6872C11.413 15.7919 11.3665 15.8898 11.2978 15.9713C11.2292 16.0529 11.1407 16.1154 11.0408 16.1528C10.941 16.1903 10.8332 16.2014 10.7279 16.1851C10.6225 16.1688 10.5231 16.1256 10.4393 16.0598C10.3846 15.8039 10.3287 15.5518 10.2741 15.2959C10.0704 14.3805 9.87414 13.4887 9.74248 12.5883C9.72274 12.3778 9.74555 12.1655 9.80957 11.9641C9.87358 11.7627 9.97749 11.5762 10.1151 11.4158C12.2179 9.28066 11.5298 9.49678 14.4138 10.2035C14.7529 10.288 15.0883 10.396 15.4299 10.509C15.4222 10.5135 15.4151 10.519 15.4087 10.5252L15.7528 10.6159L16.0397 10.7078C16.0402 10.7032 16.0402 10.6987 16.0397 10.6941L16.1925 10.7339L16.4881 10.8258Z" fill="#F1A350"/>
    <path d="M10.042 20.046L10.0582 20.0373H10.0507C10.0483 20.0406 10.0453 20.0435 10.042 20.046ZM8.80741 19.5491C8.80638 19.5578 8.80638 19.5666 8.80741 19.5752L9.11295 19.3964L8.80741 19.5491Z" fill="#F1A350"/>
    <path d="M3.43051 25.4809C3.118 25.5911 2.78685 25.6386 2.45596 25.6208C2.12508 25.6029 1.80096 25.5201 1.50211 25.377C1.20325 25.2338 0.93553 25.0332 0.71423 24.7866C0.49293 24.5399 0.32239 24.2521 0.212351 23.9396L0.180056 23.8514C-0.0332763 23.2256 0.0105535 22.5408 0.301912 21.9474C0.59327 21.3539 1.10832 20.9005 1.73387 20.6866C2.39703 20.4721 3.11808 20.5284 3.73994 20.8432C4.3618 21.1579 4.83407 21.7057 5.05388 22.3671C5.15469 22.6781 5.19257 23.006 5.1653 23.3317C5.13803 23.6575 5.04616 23.9745 4.89504 24.2644C4.74393 24.5542 4.53659 24.8111 4.28515 25.02C4.0337 25.2288 3.74317 25.3856 3.43051 25.4809Z" fill="#F1A350"/>
    <path d="M9.77527 19.0155C9.90132 19.0735 10.0069 19.1682 10.0783 19.2872C10.1496 19.4062 10.1834 19.544 10.1752 19.6825C10.1672 19.8085 10.1242 19.9298 10.051 20.0327C10.0506 20.0355 10.0496 20.0381 10.0481 20.0405C10.0466 20.0429 10.0446 20.0449 10.0423 20.0464L9.56909 20.3221L9.25236 20.5072L8.09477 21.1816C10.1429 21.635 12.1414 21.9269 14.1361 22.2225C14.6441 22.3007 15.1633 22.3802 15.6614 22.5044C15.8241 22.5454 15.9508 22.7255 16.0961 22.8447C15.9309 22.9578 15.7669 23.1689 15.5993 23.1702C14.7398 23.1838 13.8716 23.1404 13.0158 23.123C12.6742 23.123 12.3351 23.1317 11.9936 23.1366C12.2603 23.2794 12.5609 23.3468 12.863 23.3316C13.7486 23.3565 14.6354 23.3565 15.5185 23.4186C15.7073 23.4322 15.88 23.6595 16.0613 23.7912C15.8936 23.9055 15.7384 24.0967 15.5533 24.1241C14.9708 24.2259 14.3808 24.2744 13.7908 24.3278C11.8864 24.5058 9.97897 24.6822 8.06869 24.8569L8.02149 25.0109L9.24119 25.7834L9.64113 26.0319C9.70081 26.1197 9.73697 26.2213 9.74618 26.3271C9.75539 26.4328 9.73733 26.5392 9.69373 26.636C9.65013 26.7328 9.58244 26.8168 9.49712 26.88C9.41181 26.9432 9.31172 26.9835 9.20641 26.9969C8.98781 26.8541 8.76797 26.7138 8.55061 26.5746C7.76314 26.0716 6.98934 25.581 6.25901 25.0357C6.09675 24.9008 5.96298 24.7348 5.86553 24.5476C5.76807 24.3603 5.70888 24.1556 5.69139 23.9452C5.66531 20.9531 5.33369 21.5915 7.87493 20.0526C8.17427 19.8713 8.48478 19.7135 8.80771 19.5558C8.80715 19.5645 8.80715 19.5732 8.80771 19.5819L9.11325 19.4018L9.38154 19.2639L9.37408 19.2502L9.51071 19.1708L9.77527 19.0155Z" fill="#F1A350"/>
    <path d="M11.7402 30.0973V30.08H11.7315C11.7343 30.0819 11.7366 30.0845 11.7381 30.0875C11.7397 30.0905 11.7404 30.0939 11.7402 30.0973ZM10.5205 30.6227C10.525 30.6304 10.5304 30.6375 10.5367 30.6438L10.6273 30.2998C10.5888 30.4091 10.5528 30.5159 10.5205 30.6227Z" fill="#F1A350"/>
    <path d="M10.9017 38.6152C10.7585 38.9141 10.5579 39.1818 10.3113 39.4031C10.0646 39.6244 9.77677 39.795 9.46419 39.905C9.15161 40.015 8.82041 40.0624 8.48951 40.0444C8.15862 40.0264 7.83451 39.9434 7.53571 39.8001L7.45001 39.7604C6.85669 39.4692 6.40329 38.9543 6.18948 38.329C5.97566 37.7036 6.01894 37.0189 6.3098 36.4254C6.62718 35.8052 7.1766 35.3354 7.83858 35.1181C8.50055 34.9009 9.22153 34.9538 9.84469 35.2653C10.1363 35.4135 10.3953 35.6184 10.6068 35.8679C10.8183 36.1174 10.9779 36.4065 11.0763 36.7184C11.1747 37.0303 11.21 37.3587 11.18 37.6844C11.15 38.01 11.0554 38.3265 10.9017 38.6152Z" fill="#F1A350"/>
    <path d="M10.8261 29.5577C10.9204 29.5224 11.0212 29.5084 11.1215 29.5164C11.2218 29.5245 11.3191 29.5546 11.4064 29.6045C11.4938 29.6544 11.5691 29.723 11.627 29.8052C11.6849 29.8875 11.724 29.9815 11.7415 30.0806C11.7425 30.0855 11.7425 30.0906 11.7415 30.0955L11.5999 30.6259L11.508 30.9811L11.1652 32.2766C12.9339 31.1488 14.5536 29.939 16.1732 28.7417C16.5905 28.4361 17.0103 28.1207 17.4513 27.8598C17.6278 27.8023 17.8139 27.7799 17.999 27.794C17.963 27.9915 17.999 28.256 17.8748 28.374C17.2761 28.995 16.6327 29.5701 16.0117 30.1675C15.7633 30.4048 15.536 30.6545 15.3 30.9004C15.5903 30.812 15.8512 30.6468 16.0552 30.4222C16.6986 29.8123 17.3271 29.1801 17.9953 28.605C18.1381 28.4808 18.4213 28.5194 18.6412 28.4809C18.6385 28.6845 18.5963 28.8857 18.5169 29.0733C18.1766 29.5565 17.7941 30.0086 17.4165 30.4657C16.1968 31.9396 14.9729 33.4126 13.745 34.8849L13.8207 35.0265L15.2255 34.7147L15.6875 34.6129C15.7923 34.6327 15.8901 34.6793 15.9717 34.748C16.0532 34.8166 16.1158 34.9051 16.1532 35.0049C16.1906 35.1048 16.2017 35.2125 16.1854 35.3179C16.1691 35.4232 16.126 35.5227 16.0602 35.6065L15.2963 35.7717C14.3809 35.9754 13.4891 36.1716 12.5886 36.3033C12.3782 36.323 12.1659 36.3003 11.9645 36.2362C11.763 36.1722 11.5766 36.0683 11.4161 35.9307C9.28103 33.8279 9.49715 34.516 10.2039 31.6319C10.2883 31.2929 10.3964 30.9575 10.5094 30.6159C10.5139 30.6236 10.5193 30.6307 10.5256 30.6371L10.6162 30.293C10.6473 30.1986 10.6771 30.1042 10.7081 30.0061H10.6945L10.7342 29.8533L10.8261 29.5577Z" fill="#F1A350"/>
    <path d="M20.0466 36.0044L20.0379 35.9882V35.9957C20.0412 35.9981 20.0441 36.0011 20.0466 36.0044ZM19.5497 37.239H19.5758L19.397 36.9334L19.5497 37.239Z" fill="#F1A350"/>
    <path d="M25.4799 42.6174C25.5901 42.93 25.6376 43.2611 25.6198 43.592C25.6019 43.9229 25.5191 44.247 25.3759 44.5459C25.2328 44.8447 25.0321 45.1124 24.7855 45.3337C24.5389 45.555 24.2511 45.7256 23.9385 45.8356L23.8503 45.8679C23.2249 46.0814 22.5404 46.0379 21.947 45.747C21.3536 45.4561 20.8999 44.9417 20.6856 44.3166C20.4711 43.6534 20.5274 42.9324 20.8421 42.3105C21.1569 41.6886 21.7047 41.2164 22.3661 40.9966C22.6769 40.8957 23.0046 40.8577 23.3302 40.8848C23.6559 40.9119 23.9728 41.0036 24.2627 41.1545C24.5525 41.3054 24.8094 41.5124 25.0184 41.7636C25.2274 42.0147 25.3843 42.305 25.4799 42.6174Z" fill="#F1A350"/>
    <path d="M19.0159 36.271C19.0739 36.145 19.1686 36.0393 19.2876 35.968C19.4066 35.8966 19.5443 35.8629 19.6828 35.8711C19.8089 35.879 19.9302 35.9221 20.0331 35.9953C20.0359 35.9957 20.0385 35.9967 20.0409 35.9982C20.0432 35.9997 20.0452 36.0016 20.0468 36.004L20.3225 36.4772L20.5076 36.7939L21.182 37.9515C21.6353 35.9033 21.9272 33.9049 22.2228 31.9102C22.3011 31.4022 22.3806 30.883 22.5048 30.3849C22.5458 30.2222 22.7259 30.0955 22.8451 29.9502C22.9581 30.1154 23.1693 30.2793 23.1705 30.447C23.1842 31.3065 23.1407 32.1697 23.1233 33.0305C23.1233 33.372 23.132 33.7111 23.137 34.0527C23.2798 33.786 23.3472 33.4854 23.332 33.1833C23.3568 32.2977 23.3568 31.4108 23.419 30.5277C23.4326 30.339 23.6599 30.1663 23.7916 29.985C23.9058 30.1526 24.0971 30.3079 24.1244 30.493C24.2263 31.0755 24.2747 31.6655 24.3281 32.2554C24.5062 34.1599 24.6825 36.0673 24.8572 37.9776L25.0113 38.0248L25.7838 36.81L26.0322 36.4101C26.12 36.3504 26.2217 36.3143 26.3274 36.3051C26.4332 36.2959 26.5396 36.3139 26.6364 36.3575C26.7332 36.4011 26.8172 36.4688 26.8804 36.5541C26.9436 36.6394 26.9838 36.7395 26.9973 36.8448C26.8545 37.0634 26.7141 37.2833 26.575 37.5006C26.072 38.2881 25.5814 39.0619 25.0361 39.7922C24.9011 39.9545 24.7352 40.0882 24.5479 40.1857C24.3607 40.2832 24.1559 40.3424 23.9456 40.3598C20.9485 40.3834 21.5857 40.7163 20.048 38.1776C19.8667 37.8782 19.7089 37.5677 19.5512 37.2448H19.5773L19.3972 36.9392L19.263 36.6672L19.2494 36.6747L19.1699 36.538L19.0159 36.271Z" fill="#F1A350"/>
    <path d="M30.0965 34.3059H30.0791V34.3145C30.081 34.3117 30.0836 34.3095 30.0866 34.308C30.0897 34.3064 30.0931 34.3057 30.0965 34.3059ZM30.6219 35.5256C30.6295 35.5211 30.6366 35.5156 30.643 35.5094L30.2989 35.4187L30.6219 35.5256Z" fill="#F1A350"/>
    <path d="M38.6154 35.1414C38.9143 35.2845 39.182 35.4851 39.4033 35.7318C39.6247 35.9784 39.7952 36.2663 39.9052 36.5789C40.0153 36.8915 40.0627 37.2227 40.0447 37.5536C40.0267 37.8845 39.9436 38.2085 39.8003 38.5073L39.7606 38.593C39.4694 39.1864 38.9546 39.6398 38.3292 39.8536C37.7038 40.0674 37.0191 40.0241 36.4257 39.7333C35.8055 39.4159 35.3356 38.8665 35.1184 38.2045C34.9011 37.5425 34.954 36.8215 35.2656 36.1984C35.4141 35.9072 35.6192 35.6484 35.8688 35.4372C36.1183 35.226 36.4074 35.0667 36.7191 34.9683C37.0309 34.8699 37.3591 34.8345 37.6847 34.8643C38.0102 34.894 38.3266 34.9882 38.6154 35.1414Z" fill="#F1A350"/>
    <path d="M29.5583 35.2289C29.523 35.1347 29.509 35.0339 29.517 34.9336C29.5251 34.8333 29.5552 34.736 29.6051 34.6487C29.655 34.5613 29.7236 34.486 29.8058 34.4281C29.8881 34.3701 29.9821 34.331 30.0812 34.3135H30.0961L30.6265 34.4552L30.9817 34.5471L32.2772 34.8899C31.1494 33.1212 29.9396 31.5015 28.7423 29.8819C28.4367 29.4646 28.1213 29.0448 27.8604 28.6038C27.8029 28.4273 27.7806 28.2412 27.7946 28.0561C27.9921 28.0921 28.2566 28.0561 28.3746 28.1803C28.9956 28.779 29.5707 29.4223 30.1681 30.0434C30.4054 30.2918 30.655 30.5191 30.901 30.7551C30.8126 30.4648 30.6474 30.2039 30.4228 29.9999C29.8129 29.3565 29.1807 28.728 28.6056 28.0598C28.4814 27.917 28.52 27.6338 28.4815 27.4139C28.6851 27.4166 28.8863 27.4588 29.0739 27.5382C29.5571 27.8785 30.0092 28.261 30.4662 28.6386C31.9402 29.8583 33.4132 31.0821 34.8855 32.3101L35.0271 32.2344L34.7153 30.8296L34.6135 30.3675C34.6333 30.2628 34.6799 30.165 34.7485 30.0834C34.8172 30.0018 34.9057 29.9394 35.0055 29.9019C35.1054 29.8645 35.2131 29.8534 35.3185 29.8697C35.4238 29.886 35.5233 29.9291 35.6071 29.9949C35.6618 30.2508 35.7177 30.5029 35.7723 30.7588C35.976 31.6742 36.1722 32.566 36.3039 33.4665C36.3236 33.6769 36.3008 33.8892 36.2368 34.0906C36.1728 34.2921 36.0689 34.4785 35.9313 34.639C33.8285 36.774 34.5166 36.5579 31.6325 35.8512C31.2935 35.7668 30.9581 35.6587 30.6165 35.5469C30.6242 35.5424 30.6313 35.537 30.6377 35.5308L30.2936 35.4401L30.0067 35.3482C30.0062 35.3527 30.0062 35.3573 30.0067 35.3618L29.8539 35.3221L29.5583 35.2289Z" fill="#F1A350"/>
    <path d="M36.0049 25.9996L35.9888 26.0083H35.9962C35.9987 26.005 36.0016 26.0021 36.0049 25.9996ZM37.2395 26.4964C37.2405 26.4878 37.2405 26.479 37.2395 26.4704L36.934 26.6492L37.2395 26.4964Z" fill="#F1A350"/>
    <path d="M42.6161 20.5672C42.9286 20.457 43.2598 20.4095 43.5906 20.4274C43.9215 20.4452 44.2457 20.5281 44.5445 20.6712C44.8434 20.8143 45.1111 21.015 45.3324 21.2616C45.5537 21.5082 45.7242 21.796 45.8342 22.1086L45.8665 22.1968C46.0799 22.8224 46.0363 23.507 45.7452 24.1004C45.4541 24.6938 44.9393 25.1474 44.314 25.3615C43.6508 25.576 42.9298 25.5198 42.3079 25.205C41.6861 24.8902 41.2138 24.3424 40.994 23.681C40.8935 23.3701 40.8558 23.0424 40.8832 22.7168C40.9105 22.3912 41.0023 22.0744 41.1533 21.7846C41.3042 21.4948 41.5113 21.238 41.7624 21.029C42.0136 20.82 42.3037 20.663 42.6161 20.5672Z" fill="#F1A350"/>
    <path d="M36.2712 27.0309C36.1452 26.9729 36.0396 26.8782 35.9682 26.7592C35.8969 26.6402 35.8631 26.5024 35.8713 26.3639C35.8793 26.2378 35.9223 26.1165 35.9955 26.0136C35.9959 26.0109 35.9969 26.0082 35.9984 26.0059C35.9999 26.0035 36.0019 26.0015 36.0042 26L36.4774 25.7242L36.7941 25.5392L37.9517 24.8647C35.9036 24.4114 33.9051 24.1195 31.9104 23.8239C31.4024 23.7456 30.8832 23.6661 30.3852 23.5419C30.2225 23.5009 30.0958 23.3208 29.9504 23.2016C30.1156 23.0886 30.2796 22.8774 30.4473 22.8762C31.3068 22.8625 32.17 22.906 33.0307 22.9234C33.3723 22.9234 33.7114 22.9147 34.0529 22.9097C33.7862 22.767 33.4856 22.6996 33.1835 22.7147C32.2979 22.6899 31.4111 22.6899 30.528 22.6278C30.3392 22.6141 30.1665 22.3868 29.9852 22.2552C30.1529 22.1409 30.3081 21.9496 30.4932 21.9223C31.0757 21.8204 31.6657 21.772 32.2557 21.7186C34.1602 21.5414 36.0675 21.365 37.9778 21.1895L38.025 21.0355L36.8103 20.2629L36.4104 20.0145C36.3507 19.9267 36.3145 19.8251 36.3053 19.7193C36.2961 19.6135 36.3141 19.5072 36.3577 19.4104C36.4013 19.3136 36.469 19.2296 36.5544 19.1664C36.6397 19.1032 36.7398 19.0629 36.8451 19.0494C37.0637 19.1923 37.2835 19.3326 37.5009 19.4717C38.2883 19.9797 39.0621 20.4654 39.7925 21.0106C39.9547 21.1456 40.0885 21.3116 40.1859 21.4988C40.2834 21.686 40.3426 21.8908 40.3601 22.1012C40.3837 25.0982 40.7166 24.4611 38.1778 25.9987C37.8785 26.1801 37.5679 26.3378 37.245 26.4956C37.2455 26.4869 37.2455 26.4782 37.245 26.4695L36.9395 26.6496L36.6675 26.7837L36.6749 26.7974L36.5383 26.8769L36.2712 27.0309Z" fill="#F1A350"/>
    <path d="M34.3052 15.9487V15.9661H34.3139C34.3111 15.9642 34.3088 15.9616 34.3073 15.9586C34.3058 15.9555 34.305 15.9521 34.3052 15.9487ZM35.5249 15.4233C35.5204 15.4157 35.515 15.4086 35.5087 15.4022L35.4181 15.7463C35.4566 15.637 35.4926 15.5302 35.5249 15.4233Z" fill="#F1A350"/>
    <path d="M35.1398 7.43198C35.4297 6.82925 35.9472 6.36636 36.5784 6.14509C37.2096 5.92381 37.9029 5.96229 38.5057 6.25203L38.5914 6.29178C39.1847 6.58294 39.6382 7.0978 39.852 7.72317C40.0658 8.34854 40.0225 9.03322 39.7316 9.62669C39.4143 10.2469 38.8648 10.7168 38.2029 10.934C37.5409 11.1512 36.8199 11.0983 36.1967 10.7868C35.9056 10.6375 35.647 10.4318 35.4359 10.1818C35.2249 9.93175 35.0656 9.6423 34.9673 9.33023C34.8689 9.01816 34.8336 8.68967 34.8632 8.36382C34.8928 8.03796 34.9868 7.72122 35.1398 7.43198Z" fill="#F1A350"/>
    <path d="M35.2291 16.4877C35.1348 16.523 35.034 16.5371 34.9337 16.529C34.8334 16.5209 34.7361 16.4908 34.6488 16.4409C34.5614 16.391 34.4861 16.3225 34.4282 16.2402C34.3703 16.1579 34.3312 16.0639 34.3137 15.9648C34.3127 15.9599 34.3127 15.9548 34.3137 15.9499L34.4553 15.4195L34.5472 15.0643L34.89 13.7689C33.1213 14.8966 31.5017 16.1064 29.882 17.3038C29.4647 17.6093 29.0449 17.9248 28.604 18.1856C28.4274 18.2431 28.2413 18.2655 28.0562 18.2514C28.0922 18.054 28.0562 17.7894 28.1804 17.6714C28.7791 17.0504 29.4225 16.4753 30.0435 15.8779C30.2919 15.6406 30.5192 15.391 30.7552 15.1451C30.4649 15.2334 30.204 15.3986 30 15.6232C29.3566 16.2331 28.7281 16.8653 28.0599 17.4404C27.9171 17.5646 27.6339 17.5261 27.4141 17.5646C27.4167 17.3609 27.4589 17.1597 27.5383 16.9721C27.8786 16.489 28.2611 16.0369 28.6387 15.5798C29.8584 14.1059 31.0822 12.6328 32.3102 11.1605L32.2345 11.019L30.8297 11.3307L30.3677 11.4326C30.2298 11.4074 30.1047 11.3354 30.0137 11.2289C29.919 11.1201 29.8651 10.9818 29.8613 10.8377C29.8574 10.6935 29.9038 10.5526 29.9926 10.4389L30.7564 10.2737C31.6718 10.07 32.5636 9.87378 33.4641 9.74212C33.6746 9.72237 33.8868 9.74518 34.0883 9.8092C34.2897 9.87322 34.4762 9.97712 34.6366 10.1147C36.7717 12.2175 36.5556 11.5294 35.8488 14.4135C35.7644 14.7526 35.6563 15.0879 35.5445 15.4295C35.54 15.4218 35.5346 15.4147 35.5284 15.4084L35.4377 15.7524C35.4067 15.8468 35.3769 15.9412 35.3458 16.0393H35.3595L35.3197 16.1921L35.2291 16.4877Z" fill="#F1A350"/>
    <path d="M35.4925 15.1823L35.187 14.7265L35.4925 15.1823Z" fill="#F1A350"/>
    <path d="M36.0793 14.2132L35.8806 13.9175H35.9067L36.0793 14.2132Z" fill="#F1A350"/>
    <path d="M36.2459 14.4753L36.2373 14.4865C36.1838 14.3995 36.1354 14.3126 36.0833 14.2256L36.2459 14.4753Z" fill="#F1A350"/>
    <path d="M25.0058 27.2478C24.7096 27.5306 23.7634 28.3156 22.8461 28.5937H22.8433C22.8332 28.5976 21.8193 28.9668 20.992 28.9668C20.8662 28.9684 20.7404 28.9585 20.6163 28.9378C20.4749 28.7946 19.0324 27.3135 18.8672 26.1204C20.3168 27.207 21.6284 27.4466 22.4749 27.4553C22.5288 27.4675 22.64 27.4734 22.8687 27.4734C23.2864 27.4724 23.9898 27.4326 25.0058 27.2478Z" fill="#688FB4"/>
    <path d="M22.4716 27.3853C22.0275 27.3506 20.7398 27.1636 20.0593 26.7385L20.0465 26.7308H20.0413C19.9471 26.6882 18.7029 26.1031 18.2571 25.2973C18.2634 25.1102 18.3492 23.0716 19.1096 22.1353C19.3056 23.9329 20.0234 25.0536 20.5945 25.6787C20.6268 25.7253 20.6621 25.7694 20.7 25.8114C20.7652 25.8875 20.8775 26.0128 21.0425 26.177C21.3142 26.4456 21.7765 26.8684 22.4716 27.3853Z" fill="#F1A350"/>
    <path d="M20.6632 25.6195C20.4061 25.3488 19.7114 24.4507 19.4728 23.3888L19.4694 23.3738L19.4668 23.3717C19.4323 23.2587 19.049 21.9418 19.2474 21.1088C19.3951 20.9761 20.9188 19.6338 22.1168 19.534C20.9537 20.9246 20.6443 22.221 20.5903 23.0654C20.5666 23.1597 20.5457 23.4444 20.541 23.7304C20.5364 24.1153 20.5522 24.7491 20.6632 25.6195Z" fill="#688FB4"/>
    <path d="M22.4565 21.2706V21.2718C22.361 21.3278 22.1122 21.5447 21.9469 21.7013C21.6681 21.9657 21.2271 22.4206 20.6829 23.1094C20.6963 22.7367 20.8515 21.6119 21.444 20.6981L21.4525 20.6851V20.6819C21.5083 20.579 22.1831 19.3824 22.9155 18.9424C23.1137 18.9553 25.1388 19.1049 26.0482 19.8912C24.2417 20.032 23.0981 20.718 22.4565 21.2706Z" fill="#F1A350"/>
    <path d="M28.3554 22.8436C26.9348 21.7255 25.6315 21.453 24.7849 21.4242C24.7382 21.4163 24.6908 21.4112 24.6435 21.4092C24.5595 21.4029 24.4179 21.3952 24.2215 21.3952C23.8208 21.3952 23.1568 21.4268 22.229 21.5739C22.4931 21.309 23.3686 20.5867 24.423 20.3169L24.4378 20.3132L24.4406 20.3104C24.5536 20.268 25.4761 19.9825 26.2438 19.9825C26.395 19.9809 26.5461 19.9943 26.6948 20.0231C26.8316 20.1667 28.2193 21.649 28.3554 22.8436Z" fill="#688FB4"/>
    <path d="M28.1166 27.6429C27.9731 27.7801 26.4931 29.1702 25.2991 29.3084C26.417 27.8828 26.6848 26.5766 26.712 25.73C26.7329 25.6289 26.7441 25.3242 26.74 25.0641C26.7341 24.6794 26.6974 24.0467 26.5586 23.1801C26.8242 23.4427 27.5485 24.3181 27.8199 25.3721L27.8234 25.3865L27.826 25.3889C27.836 25.4181 27.8731 25.5332 27.9202 25.7022C28.0111 26.0306 28.1354 26.5624 28.1559 27.0625C28.1657 27.2569 28.1525 27.4518 28.1166 27.6429Z" fill="#688FB4"/>
    <path d="M26.0331 27.8736L26.0256 27.8876V27.891C25.9784 27.9982 25.4018 29.2436 24.7055 29.7403H24.6199C24.2504 29.7403 22.4125 29.7056 21.5085 29.0406C23.2942 28.7586 24.3786 27.9866 24.9755 27.3859C25.0218 27.3506 25.066 27.3126 25.1078 27.2722C25.1841 27.2014 25.309 27.0817 25.4697 26.9066C25.7343 26.6184 26.1459 26.1288 26.6331 25.4035C26.6353 25.7794 26.5635 26.8951 26.0331 27.8736Z" fill="#F1A350"/>
    <path d="M26.9095 23.3484H26.9083C26.8543 23.2516 26.6429 22.9982 26.4903 22.8298C26.2309 22.5446 25.7866 22.0934 25.1099 21.5334C25.4824 21.5549 26.6032 21.7341 27.5042 22.3466L27.5171 22.3555H27.5202C27.6218 22.4135 28.8034 23.1137 29.2272 23.8562C29.2102 24.0542 29.0164 26.0751 28.2106 26.9676C28.1087 25.1601 27.4479 24.0023 26.9095 23.3484Z" fill="#F1A350"/>
    <path d="M25.575 23.4017V24.8734C25.575 25.1363 25.5492 25.3654 25.5001 25.5624C25.4058 25.8696 25.2276 26.14 24.9919 26.3463C24.8008 26.5143 24.5719 26.6403 24.3195 26.7099C24.1741 26.7499 24.0211 26.7714 23.8632 26.7714H23.6212C23.4544 26.7714 23.2931 26.7476 23.1406 26.7028C23.353 26.6411 23.5482 26.5389 23.7176 26.4056C23.7259 26.3989 23.7346 26.3922 23.7429 26.3851C23.7539 26.3762 23.7646 26.3672 23.7754 26.3577C24.0469 26.1909 24.2378 25.8965 24.3244 25.4601C24.3546 25.3348 24.3709 25.2043 24.3717 25.0698C24.3753 25.0063 24.3769 24.9408 24.3769 24.873V22.607L25.575 23.4017Z" fill="#F1A350"/>
    <path d="M23.7175 26.4058C23.5481 26.5391 23.3529 26.6412 23.1405 26.7029C22.9109 26.6361 22.7015 26.5223 22.5229 26.3724H22.5227C22.2758 26.166 22.0878 25.8909 21.9885 25.5761C21.9885 25.5759 21.9883 25.5756 21.9883 25.5754C21.937 25.376 21.9092 25.1427 21.9092 24.8733V22.9165L23.0636 21.911V25.0595C23.0636 25.6008 23.3146 26.0831 23.7066 26.3965C23.7102 26.3998 23.7137 26.4028 23.7175 26.4058Z" fill="#688FB4"/>
    </svg>
    
    
  )
}
