import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "src/Projects/Life/icons/CloseIcon";
import ShareIcon from "../../Common/icons/ShareIcon";
import "./OrderSuccessModal.css";

const otp = "86895";

const OrderSuccessModal = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      className="rating-modal"
    >
      <Modal.Header className="border-0">
        <Modal.Title  className="d-block w-100">
          <div className="d-flex justify-content-end">
            <button onClick={handleClose} className="btn-0"><CloseIcon className="primary-text"/></button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="order-success-modal ">
          <div className="QR-code-box mx-auto">
            <span className="corner1 corner"></span>
            <span className="corner2 corner"></span>
            <span className="corner3 corner"></span>
            <span className="corner4 corner"></span>
            <div className="QR-code-img-box">
              <img src="/food-image/QR-code.png" alt="" />
            </div>
            <p className="QR-code-text">Scan QR Code</p>
          </div>
          <br /> <br />
          <br />
          <div className="d-flex justify-content-center align-items-center">
            <div>
              <span className="me-2 font-14 fw-bold">Or OTP</span>
              {otp.split("").map((num, index) => (
                <span key={index} className="QR-code-otp">{num}</span>
              ))}
            </div>
          </div>
          <br />
          <br />
          <p className="font-14 fw-500 text-center">Your Order Successful</p>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center border-0">
        <button className=" border-0 rounded-2 cart-confirm-btn text-dark">
          <FontAwesomeIcon className="me-2" icon={faSave} />
          Save
        </button>
        <button className=" border-0 rounded-2 cart-confirm-btn text-dark">
          <span className="me-2"><ShareIcon/></span>
          Share
        </button>
        <button className=" border-0 rounded-2 cart-confirm-btn text-dark">
          <FontAwesomeIcon className="me-2"  icon={faPrint} />
          Print
        </button>
      </Modal.Footer>
      <br />
      <br />
    </Modal>
  );
};

export default OrderSuccessModal;
