import { useLocation } from "react-router-dom";
import IntersectionWithRider from "../components/IntersectionWithRider";

import overlay from "./../assets/overlay.png";

function ConfirmRide() {
  const { pathname:url } = useLocation();

  const dynamicPath = url.split("/")[1];

  return (
    <div id="wrapper"
    // style={{ backgroundImage: `url(${overlay})` }}
    >
      <div>
        <iframe
          className="rideMap"
          title="rideMap"
          src="https://maps.google.com/maps?output=embed&ll=54.868705,-1.593018&z=11"
          width="100%"
        ></iframe>
      </div>

      <div className="py-4 py-md-5" />

      <IntersectionWithRider
        riderImage="https://i.ibb.co/HCXF0rh/rider.jpg"
        riderName="Rabiul Islam"
        riderRating="5"
        riderTotalRating="35"
        riderCarModel="2BC-458266"
        nextRoute={`/${dynamicPath}/findride/finish`}
        nextRouteText="Confirm Ride"
      />
      <div className="py-4 py-md-5" />
    </div>
  );
}
export default ConfirmRide;
