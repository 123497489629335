import React, { memo } from "react";
import styled from "styled-components";

function FriendshipStatus({ lifeType, status, details }) {
  //   const status = details[`${lifeType}_status`];
  //   console.log(status);
  return (
    <Wrapper>
      <div className="life-type">{lifeType}</div>
      {status === "accept" ? (
        <button className="">Unfriend</button>
      ) : status === "pending" ? (
        <>
          <button className="">Cancel</button>
        </>
      ) : (
        <button className="">Send Request</button>
      )}
    </Wrapper>
  );
}

export default memo(FriendshipStatus);

const Wrapper = styled.div`
  padding-block: 1rem;
  display: grid;
  grid-template-columns: 1fr max-content;

  & .life-type {
    text-transform: capitalize;
  }

  & button {
    border: 0;
    background-color: var(--text-success);
    border-radius: 0.3rem;
  }
`;
