import {
  faEnvelope,
  faTruck,
  faUserCheck,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import MainProfileLeftBarBtn from "./MainProfileLeftBarBtn";

const profileBtnList = [
  {
    btnName: "Basic Info",
    icon: <FontAwesomeIcon className="me-2" icon={faUserCog} />,
    path: "basic-info",
    id: "D100",
  },
  {
    btnName: "Mailing Address",
    icon: <FontAwesomeIcon className="me-2" icon={faEnvelope} />,
    path: "mailing-address",
    id: "D110",
  },
  {
    btnName: "Shiping Address",
    icon: <FontAwesomeIcon className="me-2" icon={faTruck} />,
    path: "shiping-address",
    id: "D120",
  },
  {
    btnName: "Verified Documents",
    icon: <FontAwesomeIcon className="me-2" icon={faUserCheck} />,
    path: "verified-document",
    id: "D1040",
  },
];

const UviomMainProfileLeftBar = () => {
  const navigate = useNavigate();

  return (
    <div className="stickyParts divScroll px-2">
      <div className="LookMeWhiteIcon box-shadow nav-bg-opacity py-2 ps-3  d-block">
        <div className="d-flex ">
          <div
            className="LookMeButtonTypeIcon  "
            onClick={() => navigate("/dashboard")}
          >
            <img className="img-fluid" src="/images/logo.svg" alt="logo" />
          </div>
          <Link to="/life/family-life">
            <div className="type-writer-text-box">
              <h5 className="mb-0">UVIOM</h5>
              <div className="lh-1 logo-slogan overflow-hidden type-writer-text text-nowrap">
                <Typewriter
                  options={{
                    strings: "Your Vision Is Our Mission",
                    autoStart: true,
                    loop: true,
                  }}
                ></Typewriter>
              </div>
            </div>
          </Link>
        </div>
      </div>

      {profileBtnList.map((btn) => (
        <MainProfileLeftBarBtn
          key={btn.id}
          name={btn.btnName}
          path={btn.path}
          icon={btn.icon}
        />
      ))}
    </div>
  );
};

export default UviomMainProfileLeftBar;
