
import React, { useState, useEffect, useRef } from "react";
import "./myCart.css";
import useAuth from "src/hooks/useAuth";
import { waitSwal } from "./cartApiCalls";
import useCartStore from "../../hooks/useCartStore";
import { ShippingAccordion } from "./ShippingAndBilling/ShippingAccordion";
import { BillingAccordion } from "./ShippingAndBilling/BillingAccordion";
import { ConformationAccordion } from "./ShippingAndBilling/ConformationAccordion";
import { placeOrderUrl } from "../../utilities/CommonUrl";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ModifyData, { DuplicateVendor } from "./ModifyData";


const CartCheckout = () => {
  const { authUser } = useAuth();
  const shippingBtnRef = useRef();
  const shippingAndBillingBtnRef = useRef();
  // const paymentMethodBtnRef = useRef();
  const conformationBtnRef = useRef();
  const { shippingAddress, billingAddress, state: stateData, deliveryCost ,removeFromCart} = useCartStore();
  const [state, setState] = useState({
    pin: "",
    sent_method: "email",
    show_pin_input: false,
    shipping_address: "",
    billing_address: "",
    shipping_billing_status: false,
    shipping_billing_error: "",
    payment_method_status: false,
    payment_method: "",
    payment_method_error: "",
    conformation_status: false,
    delivery_charge: 100,
  });

  const {
    pin,
    billing_address,
    sent_method,
    show_pin_input,
    shipping_billing_error,
    shipping_billing_status,
    payment_method_status,
    // payment_method,
    // payment_method_error,
    conformation_status,
    // delivery_charge,
  } = state;
  const [newBillingAddress, setNewBillingAddress] = useState({});



  const handleShippingAddressSubmit = () => {
    setState((prevState) => ({
      ...prevState,
      shipping_billing_error: "",
      shipping_billing_status: true,
    }));
    shippingBtnRef.current.children[0].click();
    shippingAndBillingBtnRef.current.children[0].click();

  };
  const handleAddressSubmit = () => {
    if (billing_address === "") {
      setState((prevState) => ({
        ...prevState,
        shipping_billing_error: "please select billing address",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        shipping_billing_error: "",
        shipping_billing_status: true,
      }));
      shippingAndBillingBtnRef.current.children[0].click();
      conformationBtnRef.current.children[0].click();
    }
  };
  const navigate = useNavigate()
  const myCartData = ModifyData(stateData.cartItems);

  const orderApiSubmit = () => {
    //zz
    waitSwal();
    // console.log("shippingAddress.shipping_phone",shippingAddress.shipping_phone)
    const formData = {
      name: `${authUser.first_name} ${authUser.last_name}`,
      phone: authUser.phone? authUser.phone : shippingAddress.shipping_phone,
      email: authUser.email,
      address: authUser.address ? authUser.address : shippingAddress.shipping_address1,
      grandtotal: stateData.allTotal,
      total_quantity: stateData.total_quantity,
      deliverycost: deliveryCost,
      order_type: 1,
      verify_code: pin,
      ...shippingAddress,
      ...billingAddress,
      cartlist: myCartData[0],
      vendor_order: DuplicateVendor(stateData.cartItems),

    };
    // console.log("formData", formData)
    axios
      .post(placeOrderUrl, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          Swal.close();
          Swal.clickCancel();
          Swal.fire({
            icon: "success",
            title: "Successful",
            text: "Successful.........",
            footer: "<p>Order Is placed</p>",
          }).then(
            removeFromCart(),
            // localStorage.removeItem('cartdata'),

          ).then(
            window.location.assign(res.data)

          );

        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Order Placement Failed",
        }).then(
          navigate("/ubizz")
        );
      });
  }
  const handleConformationSubmit = () => {
    setState((prevState) => ({
      ...prevState,
      conformation_status: true,
    }));
    conformationBtnRef.current.children[0].click();
    orderApiSubmit()
  };
  useEffect(() => {
    shippingBtnRef.current.children[0].click();
  }, []);

  return (
    <div className="py-3 px-2 my-4 secondary-bg  overflow-hidden checkout-box">
      <ShippingAccordion
        shippingBtnRef={shippingBtnRef}
        shipping_billing_status={shipping_billing_status}
        handleShippingAddressSubmit={handleShippingAddressSubmit}
      />
      <BillingAccordion
        shippingAndBillingBtnRef={shippingAndBillingBtnRef}
        shipping_billing_status={shipping_billing_status}
        newBillingAddress={newBillingAddress}
        setNewBillingAddress={setNewBillingAddress}
        // handleBillingAddressChange={handleBillingAddressChange}
        shipping_billing_error={shipping_billing_error}
        handleAddressSubmit={handleAddressSubmit}
        setState={setState}
      />

      {/* <Accordion
        className="shipping-address my-2"
        id="shipping-and-billing-accordion"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header ref={paymentMethodBtnRef}>
            <CustomToggle disabled={!shipping_billing_status}>
              <span style={{ color: payment_method_status && "#f9a31a" }}>
                Payment Method
              </span>
              <FontAwesomeIcon
                style={{ color: payment_method_status && "#f9a31a" }}
                icon={faCheckCircle}
              />
            </CustomToggle>
          </Accordion.Header>
          <Accordion.Body>
            <PaymentMethod
              handlePaymentMethodSubmit={handlePaymentMethodSubmit}
              totalPrice={totalPrice}
              handlePaymentMethodChange={handlePaymentMethodChange}
              errorMessage={payment_method_error}
              handleDeliveryChange={handleDeliveryChange}
              deliveryCharge={delivery_charge}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion> */}
      {/* for conformation  */}
      <ConformationAccordion
        conformationBtnRef={conformationBtnRef}
        payment_method_status={payment_method_status}
        conformation_status={conformation_status}
        pin={pin}
        show_pin_input={show_pin_input}
        sent_method={sent_method}
        handleConformationSubmit={handleConformationSubmit}
        setState={setState}
      />
      <br />
    </div>
  );
};

export default CartCheckout;
