/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import { StorePost } from "../../ScroolabelNewsSection/StorePost";
import NewsFeedMyPost from "./NewsFeedMyPost";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewsFeedPost,
  setCurrentPage,
  setHasMoreFalse,
  setTempHasMore,
  spliceData,
} from "src/features/life/newsFeedSlice";

import NewsFeedSkeleton from "src/Common/Placeholder/NewsFeedSkeleton";
const ViewAndEditProfile = () => {
  const lastPostRef = useRef();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const timeline_name = pathname?.split("/")[2]?.split("-")[0] + "_personal";

  const personal_post = useSelector((state) => state.newsFeedPost);
  const postList = personal_post[`${timeline_name}_post`];

  const has_more = personal_post[`${timeline_name}_hasMore`];
  const current_page = personal_post[`${timeline_name}_current_page`];

  const { isLoading, error, changeData_personal } = personal_post;

  useEffect(() => {
    if (postList.length < 1 || has_more) {
      dispatch(getNewsFeedPost(timeline_name));
    }
    dispatch(setTempHasMore(timeline_name));

    return () => {
      dispatch(setHasMoreFalse(timeline_name));
    };
  }, [current_page]);

  useEffect(() => {
    if (lastPostRef.current && postList.length > 25) {
      const offset = lastPostRef.current.offsetTop;
      window.scrollTo({
        top: parseInt(offset - 100),
        left: 0,
        behavior: "instant",
      });
    }
    if (postList.length > 40) {
      dispatch(spliceData(timeline_name));
    }
  }, [changeData_personal]);

  const handleNextPage = () => {
    dispatch(setCurrentPage(timeline_name));
  };

  useEffect(() => {
    window.scrollTo({ top: 454, left: 0, behavior: "instant" });
  }, []);

  return (
    <div className="MiddlePartScrool" ref={myRef}>
      {!pathname.includes("/profile") && <StorePost />}

      {postList && postList.length > 0 && (
        <InfiniteScroll
          dataLength={postList.length} //This is important field to render the next data
          next={handleNextPage}
          hasMore={has_more}
          scrollThreshold={"200px"}
        >
          <div className="my-3 lifePageView">
            {postList.map((post, index) => (
              <NewsFeedMyPost
                key={index}
                post={post}
                lastPostRef={index === 24 ? lastPostRef : undefined}
              />
            ))}
          </div>
        </InfiniteScroll>
      )}

      {isLoading && (
        <div className="my-3 lifePageView">
          <NewsFeedSkeleton />
          <NewsFeedSkeleton />
        </div>
      )}

      {error && <p className="text-center text-danger">{error.error}</p>}
    </div>
  );
};

export default ViewAndEditProfile;
