import React, { useState, useEffect } from "react";
import "./order_history.css";
import { showAllOrderUrl } from "src/Projects/Corporate/utilities/CommonUrl";
import "react-data-table-component-extensions/dist/index.css";
import useTheme from "src/hooks/useTheme";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { FormControl, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { OrderDataTable } from "./OrderDataTable";
import { columns } from "./tableData";

const OrderHistory = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  // console.log("first",location.state.data)
  const { currentTheme } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [info, setinfo] = useState([]);
  //-------------------------------------
  const currentPath = "/" + pathname?.split("/").slice(1, 3).join("/");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(3);
  const token = localStorage.getItem("access_token");
  const fetchUsers = async (page) => {
    setIsLoading(true);
    const response = await axios.get(`${showAllOrderUrl}?page=${page}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setinfo(response.data.data);
    setTotalRows(response.data.total);
    setPerPage(response.data.per_page);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    const response = await axios.get(
      `${showAllOrderUrl}?page=${page}&per_page=${newPerPage}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setinfo(response.data.data);
    setPerPage(newPerPage);
    // setLoading(false);
  };

  const downloadInvoice = (OrderID) => {
    navigate(`${currentPath}/invoice/${OrderID}`);
  };
  // search work ------------

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = info?.filter((item) => {
    const hasOrderId = String(item.OrderID)
      .toLowerCase()
      .includes(filterText.toLowerCase());
    const hasStatus = item.status
      .toLowerCase()
      .includes(filterText.toLowerCase());
    return hasOrderId || hasStatus;
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <InputGroup className={`corporate__navbar__searchBox w-50`}>
        <FormControl
          // onChange={handleOnChange}
          className="corporate__navbar-input"
          id="search"
          type="text"
          placeholder="Filter By Name"
          aria-label="Search Input"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <InputGroup.Text
          onClick={handleClear}
          className="corporate__navbar__input-text"
        >
          <FontAwesomeIcon icon={faSearch} />
        </InputGroup.Text>
      </InputGroup>
    );
  }, [filterText, resetPaginationToggle]);
  // search work end------------

  return (
    <div>
      <h6 className="fs-6 my-3">{state?.data}</h6>

      <div className="box-shadow nav-bg">
        <OrderDataTable
          columns={columns({ currentPath, downloadInvoice })}
          filteredItems={filteredItems}
          currentTheme={currentTheme}
          totalRows={totalRows}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          perPage={perPage}
          subHeaderComponentMemo={subHeaderComponentMemo}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default OrderHistory;
