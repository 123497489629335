import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import SingleJobPost from "../../Components/SingleJobPost/SingleJobPost";
import { jobSearchPost } from "../../utilities/job_commonUrl";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";


const searchDefaultData = { query: "", district_id: "", division_id: "" };

const SearchResult = () => {
  const {districts = []} = useSelector((store)=> store.jobLocalData)
  const [postList, setPostList] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [data, setData] = useState(searchDefaultData);

  const { query, district_id } = data;

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const prevState = data;
    prevState[name] = value;
    setData({ ...prevState });
  };


  const api_call = (isAddPrevData = false, page) => {
    setIsLoading(true);
    axios
      .post(`${jobSearchPost}?page=${page}`, data , {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          let next_page = res.data.next_page_url;
          let responseData = res.data.data;
          if (next_page === null) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          if (isAddPrevData) {
            setPostList((prevState) => [...prevState, ...responseData]);
          } else {
            setPostList(responseData);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    api_call(false, 1);
  };
  
  useEffect(() => {
    if (hasMore) {
      api_call(true, currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);


  return (
    <div className="p-2 secondary-bg min-height-100">
      <Form onSubmit={handleSubmit}>
        <div className="d-flex my-3 align-items-lg-center justify-content-center">
          <div className="row me-2 g-0">
            <div className="col-12">
              <InputGroup className="job__select__search-box d-flex">
                {/* <InputGroup.Text className="d-sm-none bg-white border-end-0">
                     <LocationIcon/>
                    </InputGroup.Text> */}
                <Form.Select
                  onChange={handleInput}
                  value={district_id}
                  name="district_id"
                  className={`job__search-select border-start-0`}
                >
                  <option value="" disabled>
                    Select District
                  </option>
                  {districts.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
            </div>
          </div>

          <div className="d-flex ">
            <InputGroup className="job-search-box py-1 ">
              <InputGroup.Text className="d-none d-md-inline-block">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                required
                value={query}
                name="query"
                onChange={handleInput}
                placeholder="Search job category"
              />
            </InputGroup>

            <button
              type="submit"
              className="food-btn text-dark  ms-2 banner-find-btn"
            >
              <span className="d-none d-sm-block">Find&nbsp;Job</span>

              <FontAwesomeIcon className="d-sm-none" icon={faSearch} />
            </button>
          </div>
        </div>
      </Form>
      {loading && <BubbleLoader />}

      <InfiniteScroll
        dataLength={postList.length} //This is important field to render the next data
        next={() => {
          setCurrentPage((prevState) => prevState + 1);
        }}
        hasMore={hasMore}
      >
        <div className="jobRecommended p-2 pb-4">
         

          {postList.length < 1 ? (
            <p className="text-center mt-4"> No item found </p>
          ): <p>
          Search result for{" "}
          <span className="text-orange"> "{data.query}"</span>
        </p>}

          <Row className="g-3">
            {postList.map((post) => {
              if (post.company_profile_section) {
                return <SingleJobPost key={post.id} data={post} />;
              } else return null;
            })}
          </Row>
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default SearchResult;
