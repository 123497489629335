import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useStore from "src/hooks/useStore";
import CloseIcon from "src/Projects/Life/icons/CloseIcon";
import CartIcon from "../../assets/icons/CartIcon";
import CartModalItem from "./CartModalItem";
import "./FoodCartModal.css";

const FoodCartModal = ({ show, handleClose }) => {
  const { handleRemove, totalPrice, carts } = useStore();
  const navigate = useNavigate();

  const handleCheckoutClick = () => {
    handleClose();
    navigate("/food/cart");
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        className="sidebar-main cart-modal food-cart-modal"
        placement="end"
        scroll
      >
        <Offcanvas.Header className="pb-1">
          <Offcanvas.Title>
            <h6 className="fs-6">
              <CartIcon /> Cart ({carts.length} Items)
            </h6>
          </Offcanvas.Title>
          <button className="eco-btn" onClick={handleClose}>
            <CloseIcon className="modal-close-btn" />
          </button>
        </Offcanvas.Header>

        <div className="p-2 text-center">
          <p className="mb-1">
            <small>Your Order From ZamZam Restuant (Mirpur-11)</small>
          </p>
          <p className="mb-1">
            <FontAwesomeIcon
              className="text-orange"
              icon={faClock}
            ></FontAwesomeIcon>{" "}
            30 Min
          </p>
        </div>
        <hr className="mb-0 mt-0" />
        <Offcanvas.Body className="sidebar-body p-1 position-relative">
          <div className="food-cart-items-box">
            {carts.map((item) => (
              <CartModalItem
                key={item.id}
                data={item}
                handleRemove={handleRemove}
              />
            ))}

            {carts.length === 0 && (
              <h6 className="text-center mt-4">Your Cart is Empty</h6>
            )}
          </div>
          <div className="food__cart__modal-footer w-100 py-3">
            <div className="d-flex justify-content-between mx-3 ">
              <p>Sub Total : </p> <p>{totalPrice}Tk</p>
            </div>
            <div className="d-flex justify-content-between mx-3 ">
              <p>Delivery Fee : </p> <p>{50}Tk</p>
            </div>
            <div className="d-flex justify-content-between mx-3 ">
              <p> Vat : </p> <p>{10}Tk</p>
            </div>
            <div className="d-flex justify-content-between mx-3 ">
              <p>Discount : </p> <p>{5}Tk</p>
            </div>
            <div className="d-flex justify-content-between mx-3 last">
              <p>Total : </p> <p>{100}Tk</p>
            </div>

            <div className="text-center">
              <button
                className="send-code-btn cart-confirm-btn text-white"
                onClick={handleCheckoutClick}
              >
                <small>Checkout</small>
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default FoodCartModal;
