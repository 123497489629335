import {
  AWS_BUCKET_NAME,
  AWS_REGION_NAME,
} from "src/Projects/Life/Common/CommonUrl";
import AWS from "aws-sdk";

// export const EMOJI_REGEX = /\p{Emoji}+/gu;
export const EMOJI_REGEX =
  /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;

export const STRING_REGEX =
  /^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 _.-]+)$/;
export const HEX_REGEX = /[0-9A-Fa-f]{6}/g;
export function compare_2_Object(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}

export function calculate_between_2_date(deadline) {
  const currentData = new Date();
  const deadlineDate = new Date(deadline);
  // To calculate the time difference of two dates
  const Difference_In_Time = deadlineDate.getTime() - currentData.getTime();

  // To calculate the no. of days between two dates
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Math.floor(Difference_In_Days);
}
export const rPrice = (number) => {
  return Math.round(number);
};

export function timeAgo(session_time) {
  const time_one = new Date(session_time).getTime();
  const time_now = new Date().getTime();
  const time_one_sec = Math.round(time_one / 1000);
  const time_now_sec = Math.round(time_now / 1000);
  const time_diff = time_now_sec - time_one_sec;
  const time_array = [60, 3600, 86400, 604800, 2419200, 29030400];

  const seconds = time_diff;
  const minutes = Math.round(time_diff / time_array[0]);
  const hours = Math.round(time_diff / time_array[1]);
  const days = Math.round(time_diff / time_array[2]);
  const weeks = Math.round(time_diff / time_array[3]);
  const months = Math.round(time_diff / time_array[4]);
  const years = Math.round(time_diff / time_array[5]);

  if (seconds <= 60) {
    return seconds + "sec ago";
  } else if (minutes <= 60) {
    if (minutes === 1) {
      return "1min ago";
    } else {
      return minutes + "min ago";
    }
  } else if (hours <= 24) {
    if (hours === 1) {
      return "1h ago";
    } else {
      return hours + "h ago";
    }
  } else if (days <= 7) {
    if (days === 1) {
      return "1d ago";
    } else {
      return days + "d ago";
    }
  } else if (weeks <= 4) {
    if (weeks === 1) {
      return "1w ago";
    } else {
      return weeks + "w ago";
    }
  } else if (months <= 12) {
    if (months === 1) {
      return "1month ago";
    } else {
      return months + "m ago";
    }
  } else {
    if (years === 1) {
      return "1y ago";
    } else {
      return years + "y ago";
    }
  }
}

// formate time for player
export const formateMinute = (seconds) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

// Formate Date
// Example /Aug 13, 2022
export const formateDate = (date) => {
  return new Date(date).toLocaleDateString("en-us", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

export const get_AWS_date_path = (type) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.toLocaleString("en-us", { month: "long" });
  const day = date.getDate();
  return `${year}/${month}/${day}/${type}/`;
};

export const get_AWS_file_name = () => {
  const date = new Date().getTime().toString(16);
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return date + result;
};

export const get_AWS_file_upload_params = (file, key) => {
  return {
    ACL: "public-read",
    Body: file,
    Bucket: AWS_BUCKET_NAME,
    Key: key,
  };
};

export const emoji_text_to_hex = (text) => {
  const allStr = Array.from(text);
  const strArray = [];

  allStr.forEach((str) => {
    if (EMOJI_REGEX.test(str)) {
      const emoji_10 = str.codePointAt(0);
      strArray.push("e16x0x" + emoji_10.toString(16) + "e16x");
      // strArray.push("0x" + emoji_10.toString(16));
    } else {
      strArray.push(str);
    }
  });
  return strArray.join("");
};

export const hex_to_emoji_text = (text) => {
  if (text.includes("e16x")) {
    const emoji_text_array = [];
    const emoji_str = text.split("e16x");

    emoji_str.forEach((item) => {
      if (item.includes("0x")) {
        emoji_text_array.push(String.fromCodePoint(item));
      } else {
        emoji_text_array.push(item);
      }
    });
    return emoji_text_array.join("");
  } else {
    return text;
  }
};

export const save_upload_logs = (thumb, video, key) => {
  const prev_logs = localStorage.getItem("life_video_logs");
  let logs_obj = {};
  if (prev_logs) {
    logs_obj = JSON.parse(prev_logs);
  }
  logs_obj[key] = {
    video: {
      Key: video.Key,
      Bucket: video.Bucket,
    },
    thumb: {
      Key: thumb.Key,
      Bucket: thumb.Bucket,
    },
  };
  localStorage.setItem("life_video_logs", JSON.stringify(logs_obj));
};

export const delete_upload_logs = (key) => {
  const prev_logs = localStorage.getItem("life_video_logs");
  let logs_obj = {};
  if (prev_logs) {
    logs_obj = JSON.parse(prev_logs);
  }
  delete logs_obj[key];
  localStorage.setItem("life_video_logs", JSON.stringify(logs_obj));
};

export const delete_data_from_aws = (logs_obj) => {
  const uviom_life_bucket = new AWS.S3({
    params: { Bucket: AWS_BUCKET_NAME },
    region: AWS_REGION_NAME,
  });

  for (const key in logs_obj) {
    const video_logs = logs_obj[key].video;
    const thumb_logs = logs_obj[key].thumb;
    const params = {
      Bucket: video_logs.Bucket,
      Delete: {
        Objects: [{ Key: video_logs.Key }, { Key: thumb_logs.Key }],
      },
    };

    uviom_life_bucket.deleteObjects(params, function (error, data) {
      if (data?.Deleted) {
        delete_upload_logs(key);
      }
    });
  }
};

export const delete_unLink_data = () => {
  const prev_logs = localStorage.getItem("life_video_logs");
  let logs_obj = {};
  if (prev_logs) {
    logs_obj = JSON.parse(prev_logs);
    if (logs_obj) {
      delete_data_from_aws(logs_obj);
    }
  }
};
