/* eslint-disable react-hooks/exhaustive-deps */
import { faBuilding, faUser } from "@fortawesome/free-regular-svg-icons";
import { faChartLine, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import {
  useGetApplicantCountQuery,
  useGetCompaniesCountQuery,
  useGetLiveJobQuery,
  useGetNewJobCountQuery,
} from "src/app/services/jobApi/jobGetApi";

import DashboardSkeleton from "../../Components/Placeholder/DashboardSkeleton";

const JobDashboard = () => {
  const mounted = useRef(false);

  const {
    data: live_job = {},
    isFetching: loading_one,
    error: err_one,
  } = useGetLiveJobQuery();
  const {
    data: company_count = {},
    isFetching: loading_twe,
    error: err_two,
  } = useGetCompaniesCountQuery();
  const {
    data: applicant_count = {},
    isFetching: loading_three,
    error: err_three,
  } = useGetApplicantCountQuery();
  const {
    data: new_job = {},
    isFetching: loading_four,
    error: err_four,
  } = useGetNewJobCountQuery();

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  return (
    <div className="p-2 pt-4 text-center text-sm-right">
      <div className="job__dashboard-container cards">
        {loading_one ? (
          <DashboardSkeleton />
        ) : (
          <div className="job__dashboard-item p-sm-3">
            <div className="job__dashboard-icon">
              <FontAwesomeIcon size="lg" icon={faChartLine} />
            </div>
            <div className="job__dashboard-text">
              <p>LIVE JOB</p>
              <h6>{live_job?.count || 0}</h6>
            </div>
          </div>
        )}
        {loading_twe ? (
          <DashboardSkeleton />
        ) : (
          <div className="job__dashboard-item p-sm-3">
            <div className="job__dashboard-icon">
              <FontAwesomeIcon size="lg" icon={faBuilding} />
            </div>
            <div className="job__dashboard-text">
              <p>COMPANIES</p>
              <h6>{company_count?.count || 0}</h6>
            </div>
          </div>
        )}
        {loading_three ? (
          <DashboardSkeleton />
        ) : (
          <div className="job__dashboard-item p-sm-3">
            <div className="job__dashboard-icon">
              <FontAwesomeIcon size="lg" icon={faUser} />
            </div>
            <div className="job__dashboard-text">
              <p>APPLICANTS</p>
              <h6>{applicant_count?.count || 0}</h6>
            </div>
          </div>
        )}
        {loading_four ? (
          <DashboardSkeleton />
        ) : (
          <div className="job__dashboard-item p-sm-3">
            <div className="job__dashboard-icon">
              <FontAwesomeIcon size="lg" icon={faPlus} />
            </div>
            <div className="job__dashboard-text">
              <p>NEW JOBS</p>
              <h6>{new_job?.count || 0}</h6>
            </div>
          </div>
        )}
      </div>

      {(err_one || err_two || err_three || err_four) && (
        <p className="text-danger">{err_one?.error}</p>
      )}
    </div>
  );
};

export default JobDashboard;
