import { Form } from "react-bootstrap";

function Transfer() {
  return (
    <Form>
      <Form.Select className="mt-3">
        <option value="">Bank Of America</option>
        <option value="">American Express</option>
        <option value="">CITI Bank</option>
      </Form.Select>

      <Form.Control className="mt-3 input-reset" placeholder="UVIOM INC" />
      <Form.Control className="mt-3 input-reset" placeholder="$500.00" />
      <Form.Control className="mt-3 input-reset" placeholder="$500.00" />

      <Form.Control
        className="mt-4 orange-bg hover-transparent-bg"
        type="submit"
        value="Preview"
      />
    </Form>
  );
}
export default Transfer;
