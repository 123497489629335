/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { otherProfilePostUrl } from "src/Projects/Life/Common/CommonUrl";
import { getOthersPublicPost } from "../Components/ApiCalls";
import { SinglePost } from "./SinglePost/SinglePost";

import InfiniteScroll from "react-infinite-scroll-component";
import { BubbleScale } from "src/Common/BubbleScale";

export const Posts = ({ middleColArg }) => {
  const { user_id } = middleColArg;

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [hasMore, sethasMore] = useState(true);
  const [postList, setpostList] = useState([]);
  const getPhotosArg = {
    setIsLoading: setIsLoading,
    Url: otherProfilePostUrl,
    user_id: user_id,
    postList: postList,
    setpostList: setpostList,
    currentPage: currentPage,
    sethasMore: sethasMore,
  };
  const getData = () => {
    getOthersPublicPost(getPhotosArg);
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  if (isLoading) {
    return <BubbleScale extraStyle={{ marginTop: "5rem" }} />;
  }
  return (
    <>
      {postList && postList.length > 0 && (
        <InfiniteScroll
          dataLength={postList.length} //This is important field to render the next data
          next={() => {
            setcurrentPage(currentPage + 1);
          }}
          hasMore={hasMore}
        >
          <div className="container-fluid .my-3 lifePageView customContainerFluidPadding">
            {postList.map((post, index) => (
              <SinglePost key={`i${index}`} post={post} />
            ))}
          </div>
        </InfiniteScroll>
      )}
    </>
  );
};
