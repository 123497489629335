import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall_withToken from "src/app/helper/apiCall";
import { processCategories, setData_to_sessionStorage } from "src/Projects/Job/utilities/functions";
import { getAllCategoryUrl, getJobSliderUrl } from "src/Projects/Job/utilities/job_commonUrl";

const initialState = {
    jobSlider: [],
    categories:[],
    isLoading: true,
    error: "",
}

export const getJobSlider = createAsyncThunk("job/getJobSlider", ( _, thunkAPI) => {
    const url = `${getJobSliderUrl}`
    const resp = apiCall_withToken(thunkAPI, url)
    return resp;
})

export const getAllCategories = createAsyncThunk("job/getAllCategories", (_, thunkAPI) => apiCall_withToken(thunkAPI, getAllCategoryUrl));


const jobHomeDataSlice = createSlice({
    name: "job_homeData",
    initialState,
    extraReducers: {


    //    get all slider 
        [getJobSlider.pending]: (state) => { state.isLoading = true },
        [getJobSlider.fulfilled]: (state, action) => {
            state.isLoading = false;
            const data = action.payload;
            state.jobSlider = data;
        },
        [getJobSlider.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },


        // all categories api 
        [getAllCategories.pending]: (state) => { state.isLoading = true},
        [getAllCategories.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.categories = action.payload
            // save data to session storage 
            setData_to_sessionStorage(processCategories(action.payload), "category");
        },
        [getAllCategories.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    }
})


export default jobHomeDataSlice.reducer