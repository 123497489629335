import React, { Suspense, lazy } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";
import AddPost from "../../../NestedRouteOnDashBodr/AddPostSection/AddPost";
import Friends from "../../../NestedRouteOnDashBodr/Friends/Friends";
import GlobalSearch from "../../../NestedRouteOnDashBodr/GlobalSearch/GlobalSearch";
import GoLive from "../../../NestedRouteOnDashBodr/GoLiveSection/GoLive";
import Message from "../../../NestedRouteOnDashBodr/Message/Message";
import MoreOption from "../../../NestedRouteOnDashBodr/MoreOption/MoreOption";
import Notification from "../../../NestedRouteOnDashBodr/Notification/Notification";
import PostBestTime from "../../../NestedRouteOnDashBodr/PostBestTime/PostBestTime";
import VideoPost from "../../../NestedRouteOnDashBodr/VideoPost/VideoPost";
import ViewAndEditProfile from "../../../NestedRouteOnDashBodr/ViewAndEditProfile/ViewAndEditProfile";
import ViewVideos from "../../../NestedRouteOnDashBodr/ViewVideos/ViewVideos";
import MediaQuery from "react-responsive";
import { RightColButtons } from "../RightCol/RightColButtons";
import ViewPhotos from "./ViewPhotos/ViewPhotos";
import { About } from "./About/About";
import { Posts } from "./Posts/Posts";

export const MiddleColRoutes = ({ middleColArg }) => {
  let { pathname } = useLocation();
  return (
    <Suspense fallback={<DottedCircleSpinner />}>
      <MediaQuery maxWidth={992}>
        <RightColButtons timlineUrl={pathname} />
      </MediaQuery>
      <Routes>
        <Route path="/" element={<Posts middleColArg={middleColArg} />} />
        <Route path="/posts" element={<Posts middleColArg={middleColArg} />} />
        <Route
          path="/about/*"
          element={<About middleColArg={middleColArg} />}
        />
        <Route path="/watch-video" element={<VideoPost />} />

        <Route path="/go-live" element={<GoLive />} />
        <Route path="/notificaiton" element={<Notification />} />
        <Route path="/message/*" element={<Message />} />
        {/* middle    top bar  End---------------*/}
        {/* leftbar rute F ------------------------------ */}

        <Route
          path="/view-photos"
          element={<ViewPhotos middleColArg={middleColArg} />}
        />
        <Route path="/view-videos" element={<ViewVideos />} />
        <Route path="/friends" element={<Friends />} />
        <Route path="/my-post" element={<ViewAndEditProfile />} />
        <Route path="/post-best-time" element={<PostBestTime />} />
        <Route path="/groups" element={<Message />} />
        <Route path="/add-post" element={<AddPost />} />
        <Route path="/search" element={<GlobalSearch />} />
        <Route
          path="/more-option"
          element={<MoreOption timlineUrl={pathname} />}
        />
      </Routes>
    </Suspense>
  );
};
