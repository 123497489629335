import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../LoginPage/Footer/Footer";
import CoverAndProfile from "../MainPage/Components/CoverAndProfileSection/CoverAndProfile";
import "./UviomMainProfile.css";
import UviomMainProfileLeftBar from "./UviomMainProfileLeftBar/UviomMainProfileLeftBar";
import UviomMainProfileRightBar from "./UviomMainProfileRightBar/UviomMainProfileRightBar";
import UviomMainProfileMiddleCol from "./UviomMainProfileMiddleCol/UviomMainProfileMiddleCol";

const UviomMainProfile = () => {
  return (
    <>
      <CoverAndProfile />
      <Container fluid="xxl">
        <Row className="py-4 g-4">
          <Col lg={3} className="d-none d-lg-block">
            <UviomMainProfileLeftBar />
          </Col>
          <Col xs={12} lg={6}>
            <UviomMainProfileMiddleCol />
          </Col>
          <Col lg={3} className="d-none d-lg-block">
            <UviomMainProfileRightBar />
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default UviomMainProfile;
