import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetPlaylistVideosMutation } from "src/app/services/viewApi/viewGetApi";
import ScrollToTop from "src/Common/ScrollToTop";
import { destroyPlaylistVideos } from "src/features/view/viewDataSlice";
import styled from "styled-components";
import SingleVideoCardSkeleton from "../../common/SingleVideoCardSkeleton";
import CreatePlaylistContainer from "../../Components/CreatePlaylistContainer";
import SingleVideoCard from "../../Components/SingleVideoCard";
import "./playlist.css";

export const Playlist = () => {
  const dispatch = useDispatch();
  const { playlistId } = useParams();
  const [currentPage, setCurrentPage] = useState();
  const {
    playlistVideos: { data: playlistVideos, has_more },
  } = useSelector((store) => store.viewData);

  const [getPlaylistVideos, { isLoading }] =
    useGetPlaylistVideosMutation(playlistId);

  useEffect(() => {
    getPlaylistVideos({ playlistId, currentPage });

    return () => {
      dispatch(destroyPlaylistVideos());
    };
  }, [playlistId]);

  return (
    <CardWrapper>
      <ScrollToTop />
      {isLoading ? (
        Array.from({ length: 3 }).map((_, index) => (
          <SingleVideoCardSkeleton key={index} />
        ))
      ) : !_.isEmpty(playlistVideos) ? (
        playlistVideos.map((video) => (
          <SingleVideoCard
            key={video.id}
            channelName={video.channel_profile_section.channel_name}
            channelCategory={
              video.channel_profile_section.channel_category.name
            }
            videoTitle={video.title}
            viewCount={video.video_views_count}
            thumbnail={video.thumbnail}
            isSaved={video.personal_save_status_count}
            channelProfile={video.channel_profile_section.photo}
            id={video.id}
            userId={video.user_id}
            created_at={video.created_at}
          />
        ))
      ) : (
        <div className="no-video">NO Video In This Playlist</div>
      )}
    </CardWrapper>
  );
};

export const CardWrapper = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;

  & .no-video {
    color: var(--text-primary);
  }
`;
