import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchVideoMutation } from "src/app/services/viewApi/viewPostApi";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";
import { destroySearchResult } from "src/features/view/viewDataSlice";
import useQuery from "src/hooks/useQuery";
import styled from "styled-components";
import { HasMore } from "../SaveVideo/SaveVideo";
import SingleVideoCard from "./SingleVideoCard";

function Search() {
  const query = useQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchVideo, { isError, isLoading }] = useSearchVideoMutation();

  const {
    searchResult: { data: searchResult },
  } = useSelector((store) => store.viewData);

  useEffect(() => {
    searchVideo({ query: query.get("search"), currentPage });

    return () => destroySearchResult();
  }, [query, searchVideo, currentPage]);

  return (
    <Wrapper>
      {isLoading ? (
        <div className="my-4">
          <DottedCircleSpinner />
        </div>
      ) : searchResult.length > 0 ? (
        searchResult.map((video) => (
          <SingleVideoCard
            key={video.id}
            videoId={video.id}
            videoTitle={video.title}
            videoThumbnail={video.thumbnail}
            videoDesc={video.description}
            userName={video.channel_profile_section.channel_name}
            userProfile={video.channel_profile_section.photo}
            userId={video.channel_profile_section.user_id}
          />
        ))
      ) : isError ? (
        <h5 className="fw-500 mt-3 text-center primary-text">
          Something Went Wrong
        </h5>
      ) : (
        <h5 className="fw-500 mt-3 text-center primary-text">No Video Found</h5>
      )}
    </Wrapper>
  );
}
export default Search;

const Wrapper = styled.div`
  max-width: 95rem;
  margin-inline: auto;
`;
