import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import InputWithLabel from "src/Common/InputWithLabel/InputWithLabel";
import { createCompanyUrl } from "../../utilities/job_commonUrl";
import postData from "../../api_call/postApiCall";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import LocationSelectInput from "../../Components/Common/LocationSelectInput";
import { getDivisionByDistricts } from "../../utilities/functions";
import { useDispatch } from "react-redux";
import { getCompanyProfile } from "src/features/job/jobProfileSlice";

const defaultData = {
  company_name: "",
  company_EINN: "",
  company_phone: "",
  company_email: "",
  company_address: "",
  website: "",
  district_id: "",
  division_id: "",
};

const CompanyRegister = () => {
  const [companyData, setCompanyData] = useState(defaultData);
  const [loading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [districts, setDistricts] = useState([]);
  const dispatch = useDispatch();

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const prevState = companyData;
    prevState[name] = value;
    setCompanyData({ ...prevState });
  };

  const {
    company_name,
    company_EINN,
    company_phone,
    company_email,
    company_address,
    website,
    district_id,
    division_id,
  } = companyData;

  const removeInputFieldData = () => {
    setCompanyData(defaultData);
  };

  const callAfterSuccess = () => {
    navigate("/Job");
    dispatch(getCompanyProfile());
  };

  const postDataArg = {
    url: createCompanyUrl,
    data: companyData,
    setIsLoading,
    setSuccess,
    setError,
    removeInputFieldData,
    callAfterSuccess,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postData(postDataArg);
  };

  useEffect(() => {
    const districts = getDivisionByDistricts(division_id) || [];
    setDistricts(districts);
  }, [division_id]);

  const inputProps = {
    required: true,
    className: ["bordaredInput", "text-start ps-4"],
    checkoutInput: true,
  };
  return (
    <div className="secondary-bg p-2">
      {loading && <BubbleLoader></BubbleLoader>}
      <h6 className="jobApplyTitle">Company Register</h6>
      <Form onSubmit={handleSubmit}>
        <InputWithLabel
          {...inputProps}
          value={company_name}
          label="Company Name*"
          name="company_name"
          handler={handleInput}
          placeholder="Company Name"
        />
        <InputWithLabel
          {...inputProps}
          value={company_EINN}
          type="number"
          label="Company EINN*"
          name="company_EINN"
          handler={handleInput}
          placeholder="Company EINN"
        />
        <InputWithLabel
          {...inputProps}
          value={company_phone}
          label="Phone*"
          name="company_phone"
          handler={handleInput}
          placeholder="Phone Number"
        />
        <InputWithLabel
          {...inputProps}
          value={company_email}
          label="Email*"
          name="company_email"
          handler={handleInput}
          placeholder="Email Address"
        />
        <LocationSelectInput
          {...inputProps}
          label="Location"
          handler={handleInput}
          district_value={district_id}
          division_value={division_id}
        >
          <option value="" disabled>
            Select District
          </option>
          {districts.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </LocationSelectInput>
        <InputWithLabel
          {...inputProps}
          value={company_address}
          label="Address*"
          name="company_address"
          handler={handleInput}
          placeholder="Address"
        />
        <InputWithLabel
          {...inputProps}
          value={website}
          label="Website*"
          name="website"
          handler={handleInput}
          placeholder="Website Url"
        />

        <div style={{ fontSize: "0.85rem" }}>
          <p className="text-center text-danger">{error}</p>
          <p className="text-center ">{success}</p>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            className="send-code-btn py-1 me-2"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <button type="submit" className="jobApplySubmitBtn text-dark">
            Submit
          </button>
        </div>
      </Form>
      <br />
      <br />
    </div>
  );
};

export default CompanyRegister;
