import { forwardRef } from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPause,
  faPlay,
  faBackward,
  faForward,
  faExpand,
  faVolumeDown,
  faVolumeMute,
} from "@fortawesome/free-solid-svg-icons";
import styled, { keyframes } from "styled-components";

const FeedVideoControls = forwardRef((props, ref) => {
  const {
    handleVideoPlayPause,
    playing,
    handleRewind,
    handleFastForward,
    played,
    onSeek,
    onSeekMouseDown,
    onSeekMouseUp,
    toggleFullScreen,
    handleToggleMuted,
    handleVolumeChange,
    elapsedTime,
    totalDuration,
    isBuffering,
    isMuted,
    volume,
  } = props;

  return (
    <>
      {playing && isBuffering ? <Spinner></Spinner> : ""}
      <div style={{ display: "none" }} ref={ref}>
        <VolumeControl title="Volume Control">
          <Form.Range
            className="volume__range"
            type="range"
            min={0}
            max={1}
            step="any"
            value={isMuted ? 0 : volume}
            onChange={handleVolumeChange}
          />
          <button onClick={handleToggleMuted}>
            {isMuted ? (
              <FontAwesomeIcon icon={faVolumeMute} />
            ) : (
              <FontAwesomeIcon icon={faVolumeDown} />
            )}
          </button>
        </VolumeControl>
        <ControlsWrapper>
          <button
            tabIndex="0"
            onClick={handleVideoPlayPause}
            className="player__button toggle"
            title="Play/Pause"
          >
            {playing ? (
              <FontAwesomeIcon icon={faPause} />
            ) : (
              <FontAwesomeIcon icon={faPlay} />
            )}
          </button>

          <PlayerTimeline>
            <p className="time">
              {elapsedTime}/{totalDuration}
            </p>
            <div className="video__track__container">
              <span
                className="progress"
                style={{ "--played": parseFloat(played * 100) + "%" }}
              />
              <span className="background" />
              <Form.Range
                title="Volume Control"
                className="video__track"
                min={0}
                max={0.999999}
                step="0.001"
                value={played}
                onChange={onSeek}
                onMouseDown={onSeekMouseDown}
                onMouseUp={onSeekMouseUp}
              />
            </div>
          </PlayerTimeline>
          <button className="player__button" onClick={toggleFullScreen}>
            <FontAwesomeIcon icon={faExpand} />
          </button>
        </ControlsWrapper>

        <PlayerCenterControls>
          {/* Fast Forwarding 10 sec */}
          <button onClick={handleRewind}>
            <FontAwesomeIcon icon={faBackward} />
          </button>
          {/* Play / Pause */}
          <button onClick={handleVideoPlayPause}>
            {playing ? (
              <FontAwesomeIcon icon={faPause} />
            ) : (
              <FontAwesomeIcon icon={faPlay} />
            )}
          </button>
          {/* Fast Forwarding 10 sec */}
          <button onClick={handleFastForward}>
            <FontAwesomeIcon icon={faForward} />
          </button>
        </PlayerCenterControls>
      </div>
    </>
  );
});
export default FeedVideoControls;

const VolumeControl = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 1rem;

  &:hover .volume__range {
    opacity: 1;
  }

  & > .volume__range {
    position: absolute;
    bottom: 3.4rem;
    /* right: 0; */
    left: -1rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    transform: rotate(-90deg) translateY(2px);
    width: 5rem;
    z-index: 5;
    opacity: 0;
    --thumb-size: 0.7rem;
    --tracker-size: 0.3rem;
    --thumb-margin: -0.2rem;

    /* TODO: Volume range css */
    &::-webkit-slider-thumb {
      background: var(--text-success);
      width: var(--thumb-size);
      height: var(--thumb-size);
      margin-top: var(--thumb-margin);
    }
    &::-moz-range-thumb {
      background: var(--text-success);
      width: var(--thumb-size);
      height: var(--thumb-size);
      margin-top: var(--thumb-margin);
    }
    &::-ms-thumb {
      background: var(--text-success);
      width: var(--thumb-size);
      height: var(--thumb-size);
      margin-top: var(--thumb-margin);
    }
    &::-webkit-slider-runnable-track {
      height: var(--tracker-size);
    }
    &::-moz-range-track {
      height: var(--tracker-size);
    }
    &::-ms-track {
      height: var(--tracker-size);
    }
  }

  & > button {
    background-color: transparent;
    font-size: 1.2rem;
    width: 2rem;
    border: 0;
    color: #fff;
    cursor: pointer;
    z-index: 2;
    transform: translateX(9px);
  }

  /* 768px */
  @media (max-width: 48em) {
    display: none;
  }
`;
const rotate = keyframes`
 0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
`;

const Spinner = styled.div`
  --size: 4rem;
  position: absolute;
  z-index: 50;
  width: var(--size);
  height: var(--size);
  top: calc(50% - (var(--size) / 2));
  left: calc(50% - (var(--size) / 2));
  border: 0.35rem solid var(--text-success);
  border-left-color: transparent;
  border-radius: 50%;
  animation: ${rotate} 2s linear infinite;
`;

const PlayerTimeline = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  & > .time {
    min-width: 4rem;
    color: #fff;
    font-size: 0.8rem;
    margin-bottom: 0;
    display: inline-block;
  }

  & > .video__track__container {
    width: 100%;
    position: relative;
    overflow: hidden;

    & > .progress {
      background: var(--text-success);
      height: 0.5rem;
      width: 100%;
      display: inline-block;
      position: absolute;
      top: 0.5rem;
      z-index: 2;
      right: calc(100% - var(--played));
    }
    & > .background {
      background: var(--text-info);
      width: 100%;
      display: inline-block;
      position: absolute;
      top: 0.5rem;
      height: 0.5rem;
    }
  }

  & .video__track {
    transition: 0.2s;
    position: relative;
    z-index: 55;

    &::-webkit-slider-thumb {
      background: var(--text-success);
    }
    &::-webkit-slider-runnable-track {
      background: transparent;
    }

    &::-moz-range-thumb {
      background: var(--text-success);
    }
    &::-moz-range-track {
      background: transparent;
    }

    &::-ms-thumb {
      background: var(--text-success);
    }
    &::-ms-track {
      background-color: transparent;
    }
  }
`;

const ControlsWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  padding-block: 0.5rem;
  width: 100%;
  transition: all 0.3s;
  flex-wrap: wrap;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) -4.14%,
    #000000 100%
  );

  & > * {
    flex: 1;
  }

  .player__button {
    font-size: 0.9rem;
    background: none;
    border: 0;
    line-height: 1;
    color: white;
    text-align: center;
    outline: 0;
    padding: 0;
    cursor: pointer;
    max-width: 50px;
  }
`;

const PlayerCenterControls = styled.div`
  opacity: 1;
  visibility: visible;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: 0.3s;
  font-size: 1.5rem;
  transform: translate(-50%, -50%);

  & > button {
    --size: 3.5rem;
    background-color: #00000050;
    border-radius: 50%;
    height: var(--size);
    width: var(--size);
    border: 0;
    margin-inline: 0.5rem;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;
