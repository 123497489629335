export const getMetaImage = (html) => {
  const img_og = html.querySelector("meta[property='og:image']");
  const img_twitter = html.querySelector("meta[property='twitter:image']");
  const img_name_og = html.querySelector("meta[name='og:image']");
  const image_link_as = html.querySelector("link[as='image']");
  const image_twitter_ico = html.querySelector("link[rel='apple-touch-icon']");

  if (img_og) {
    return img_og.getAttribute("content");
  } else if (img_twitter) {
    return img_twitter.getAttribute("content");
  } else if (img_name_og) {
    return img_name_og.getAttribute("content");
  } else if (image_link_as) {
    return image_link_as.getAttribute("href");
  } else if (image_twitter_ico) {
    return image_twitter_ico.getAttribute("href");
  } else {
    return "https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc=";
  }
};

export const getMetaDescription = (html) => {
  const des = html.querySelector("meta[name='description']");
  const des_og = html.querySelector("meta[property='og:description']");
  const des_twitter = html.querySelector(
    "meta[property='twitter:description']"
  );
  const des_name_og = html.querySelector("meta[name='og:description']");

  if (des) {
    return des.getAttribute("content");
  } else if (des_og) {
    return des_og.getAttribute("content");
  } else if (des_twitter) {
    return des_twitter.getAttribute("content");
  } else if (des_name_og) {
    return des_name_og.getAttribute("content");
  } else {
    return null;
  }
};
export const getMetaTitle = (html) => {
  const title_og = html.querySelector("meta[property='og:title']");
  const title_twitter = html.querySelector("meta[property='twitter:title']");
  const title_name_og = html.querySelector("meta[name='og:title']");
  const title_main = html.querySelector("title");

  if (title_og) {
    return title_og.getAttribute("content");
  } else if (title_twitter) {
    return title_twitter.getAttribute("content");
  } else if (title_name_og) {
    return title_name_og.getAttribute("content");
  } else if (title_main) {
    return title_main.innerText;
  } else {
    return "Click here to more info";
  }
};

export const getGetImageInfo = (html) => {
  const img_og_height = html.querySelector("meta[property='og:image:height']");
  const img_og_width = html.querySelector("meta[property='og:image:width']");

  let info = { width: undefined, height: undefined };

  if (img_og_width || img_og_height) {
    const width = img_og_width.getAttribute("content");
    const height = img_og_height.getAttribute("content");
    info.width = width || undefined;
    info.height = height || undefined;
  }

  return info;
};
