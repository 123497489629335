import { faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Form, InputGroup } from "react-bootstrap";

const ImageInputBox = ({
  label,
  handler,
  value,
  name,
  selectedFileName="No File Select",
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
}) => {
  return (
    <Form.Group
      as={Col}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      xxl={xxl}
      className={`mb-3 double-file-input-box`}
      controlId=""
    >
      <Form.Label style={{fontSize:"0.9rem"}}>{label}</Form.Label>
      <InputGroup className="file-and-text-input-group py-0 d-flex justify-content-between" hasValidation>
              <label className="mb-0 mt-1 ms-1 text-secondary"><small>{selectedFileName.substring(0,30)}</small></label>
        <InputGroup.Text className="profileVideoSearchLabel">
          <label
            htmlFor={"file_front"}
            className="mainProfileInput me-1 secondary-bg"
            id="file-label"
          >
            <span className="file-text-btn">
              Add Image
              <FontAwesomeIcon
                className={`text-white ms-1 ms-lg-2 `}
                size="lg"
                icon={faImage}
              ></FontAwesomeIcon>
            </span>
          </label>
          <input
            accept="image/png, image/jpg"
            type="file"
            value={value}
            name={name}
            id="file_front"
            className="file-input"
            onChange={handler}
          />
        </InputGroup.Text>
      </InputGroup>
    </Form.Group>
  );
};

export default ImageInputBox;
