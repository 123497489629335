import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import track from "./../../assets/deliverytrack.svg";
import "./selectVehicle.css";

function SelectVehicle({ children }) {
  const { pathname:url } = useLocation();
  const navigate = useNavigate();
  const dynamicPath = url.split("/")[1];

  return (
    <>
      {children}
      <div className="deliveryForm">
        <div className="deliveryVehicle">
          <div className="deliveryVehicle__item">
            <input
              className="deliveryVehicle__input"
              type="radio"
              name="vehicle"
              id="fastDelivery"
            />
            <label className="deliveryVehicle__label" htmlFor="fastDelivery">
              <img className="dark-invert" src={track} alt="Delivery Track" />
              <div>
                <p className="m-0">Fast Delivery</p>
                <small className="m-0">Wait 30 min</small>
              </div>
            </label>
          </div>

          <div className="deliveryVehicle__item">
            <input
              className="deliveryVehicle__input"
              type="radio"
              name="vehicle"
              id="sameDayDelivery"
            />
            <label className="deliveryVehicle__label" htmlFor="sameDayDelivery">
              <img className="dark-invert" src={track} alt="Delivery Track" />
              <div>
                <p className="m-0">Same Day Delivery</p>
                <small className="m-0">Wait 30 min</small>
              </div>
            </label>
          </div>

          <div className="deliveryVehicle__item">
            <input
              className="deliveryVehicle__input"
              type="radio"
              name="vehicle"
              id="nextDayDelivery"
            />
            <label className="deliveryVehicle__label" htmlFor="nextDayDelivery">
              <img src={track} className="dark-invert" alt="Delivery Track" />
              <div>
                <p className="m-0">Nex Day Delivery</p>
                <small className="m-0">Wait 30 min</small>
              </div>
            </label>
          </div>

          <div className="deliveryVehicle__item">
            <input
              className="deliveryVehicle__input"
              type="radio"
              name="vehicle"
              id="midWeekDelivery"
            />
            <label className="deliveryVehicle__label" htmlFor="midWeekDelivery">
              <img src={track} className="dark-invert" alt="Delivery Track" />
              <div>
                <p className="m-0">MidWeek Delivery</p>
                <small className="m-0">Wait 30 min</small>
              </div>
            </label>
          </div>

          <div className="deliveryVehicle__item">
            <input
              className="deliveryVehicle__input"
              type="radio"
              name="vehicle"
              id="weeklyDelivery"
            />
            <label className="deliveryVehicle__label" htmlFor="weeklyDelivery">
              <img src={track} className="dark-invert" alt="Delivery Track" />
              <div>
                <p className="m-0">Weekly Delivery</p>
                <small className="m-0">Wait 30 min</small>
              </div>
            </label>
          </div>
        </div>

        <div className="text-center mt-4 mt-sm-5">
          <Button
            onClick={() => navigate(-1)}
            variant="outline-dark"
            className="dark-invert me-3 px-3"
          >
            Back
          </Button>
          <Button
            as={Link}
            to={`/${dynamicPath}/delivery/preview`}
            className="btn__ride px-4"
          >
            Preview
          </Button>
        </div>
      </div>
    </>
  );
}
export default SelectVehicle;
