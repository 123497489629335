import axios from "axios";

const postData = ({
  url,
  data,
  setIsLoading = () => {},
  setSuccess = () => {},
  setError = () => {},
  removeInputFieldData = () => {},
  callAfterSuccess = () => {},
}) => {
  setIsLoading(true);
  axios
    .post(`${url}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    })

    .then((res) => {
      setIsLoading(false);
      setSuccess(res.data.message);
      setError("");
      removeInputFieldData();
      callAfterSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      setError(err.message);
      setSuccess("");
      setIsLoading(false);
      removeInputFieldData();
    });
};

export default postData;