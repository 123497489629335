import React from "react";
import { Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import CorporateIcon from "../../assets/icons/CorporateIcon";

export const NavbarBrand = () => {
  const { pathname } = useLocation();

  return (
    <Navbar.Brand
      as={"h5"}
      className=" text-white me-1 me-md-5 d-flex align-items-center mb-0"
    >
      <Link to={"/dashboard"}>
        <CorporateIcon fill="var(--UbizzIconColor)" />
      </Link>
      <Link to={pathname} className="corporate__navbar__brand-text ms-1">
        <div className="nav-logo-text-box">
          <h5 className="mb-0">
            <span style={{ color: "var(--text-info)" }}>UVIOM</span>
            <span className="text-orange">Bizz</span>
          </h5>
          <h6 className="mb-0 overflow-hidden primary-text font-12">
            Good Product Happy Life
          </h6>
        </div>
      </Link>
    </Navbar.Brand>
  );
};
