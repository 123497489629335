import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import { ShopBannerImgBaseM } from "../../../utilities/CommonUrl";
import StoreItem from "../../Store/StoreItem/StoreItem";
import ShopSkeleton from "src/Projects/Corporate/Components/Placeholder/ShopSkeleton";

export const LimitedShops = ({ store_loading, home_store }) => {
  const navigate = useNavigate();
  let { path } = useLocation();

  const handleShopClick = () => {
    navigate({
      pathname: `${path}/shop`,
      state: {
        allBrandData: home_store,
      },
    });
  };
  return (
    <Container fluid className="mb-2 py-4 box-shadow limitedShop">
      <div className="d-flex justify-content-between">
        <div>
          <h4 className="py-2">Shops</h4>
        </div>
        <div>
          <div className="py-2 pointer" onClick={handleShopClick}>
            View All
          </div>
        </div>
      </div>
      <Row xs="1" md="2" lg="3" className="g-4">
        {store_loading ? (
          [...new Array(9)].map((_, index) => <ShopSkeleton key={index} />)
        ) : (
          <>
            {home_store &&
              home_store.length !== 0 &&
              home_store.map((data) => (
                <StoreItem
                  data={data}
                  key={data.id}
                  ShopBannerImgBaseWithLocation={ShopBannerImgBaseM}
                />
              ))}
          </>
        )}
      </Row>
    </Container>
  );
};
