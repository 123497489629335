import React from "react";
import { faMusic } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SingleItem = ({ item, previousDataState, editClick,componentMainUrl }) => {
  return (
    <div className="d-flex justify-content-between mb-4">
    <div className="d-flex">
      <div className='me-3 border  p-1 myIconCircle'>
        <FontAwesomeIcon icon={faMusic} />
      </div>
      <div>
        <h5>{item.music_name}</h5>
      </div>
    </div>
  </div>
  );
};
