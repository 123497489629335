import {
  Routes,
  Route,
  useLocation,
  NavLink,
  Navigate,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";

import AddBalance from "./../AddBalance";
import PendingBalance from "./../PendingBalance";
import TransferHistory from "./../TransferHistory";
import FundTransfer from "./../FundTransfer";

import "./wallet.css";

function Wallet() {
  const { pathname: url } = useLocation();
  const dynamicPath = url.split("/")[1];
  const dynamicUser = url.split("/")[2];

  return (
    <div>
      <div className="wallet">
        <div className="wallet-buttons">
          <p className="wallet-buttons__title">
            <FontAwesomeIcon icon={faWallet} /> Wallet
          </p>
          {url.includes("freelancer") ? (
            <NavLink
              to={`/${dynamicPath}/${dynamicUser}/wallet/fundtransfer`}
              className="wallet-button"
            >
              Fund Transfer
            </NavLink>
          ) : (
            <NavLink
              to={`/${dynamicPath}/${dynamicUser}/wallet/addbalance`}
              className="wallet-button"
            >
              Add Balance
            </NavLink>
          )}

          <NavLink
            to={`/${dynamicPath}/${dynamicUser}/wallet/pendingbalance`}
            className="wallet-button"
          >
            Pending Balance
          </NavLink>
          <NavLink
            to={`/${dynamicPath}/${dynamicUser}/wallet/transferhistory`}
            className="wallet-button"
          >
            Transfer History
          </NavLink>
        </div>

        <div>
          <WalletRoutes />
        </div>
      </div>
    </div>
  );
}
export default Wallet;

const WalletRoutes = () => {
  return (
    <Routes>
      <Route path={`/addbalance`} element={<AddBalance />} />
      <Route path={`/fundtransfer/*`} element={<FundTransfer />} />
      <Route path={`/pendingbalance`} element={<PendingBalance />} />
      <Route path={`/transferhistory`} element={<TransferHistory />} />
      <Route path="*" element={<Navigate to="pendingbalance" replace />} />
    </Routes>
  );
};
