import React from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import SearchBox from "src/Projects/Corporate/Components/Common/SearchBox";
import { Cover } from "../Components/Cover/Cover";
import { MiddleCol } from "../Components/MiddleCol/MiddleCol";
import { RightCol } from "../Components/RightCol/RightCol";

export const Home = () => {
  return (
    <>
      <Cover />
      <div className="row px-md-3 py-sm-2">
        <div className="col-12">
          <ContainerWrapper>
            <div className="d-none d-lg-block .col-3">
              <div className="stickyParts vh-100 scroll-y no-scrollbar">
                <RightCol />
              </div>
            </div>
            {/* <LeftCol /> */}
            <MiddleCol />
          </ContainerWrapper>
        </div>
      </div>
    </>
  );
};

const ContainerWrapper = styled.div`
  @media (min-width: 62em) {
    display: grid;
    grid-template-columns: minmax(15rem, max-content) 1fr;
    column-gap: 1rem;
  }
`;
