import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlusSquare } from "@fortawesome/free-regular-svg-icons";

import "./profileInfo.css";

function ProfileInfo() {
  const [skills, setSkills] = useState(["HTML", "CSS", "JAVASCRIPT"]);
  const { pathname: url } = useLocation();

  const handleSkillSubmit = (e) => {
    e.preventDefault();
    setSkills([...skills, e.target[0].value]);
    e.target[0].value = "";
  };
  const handleSkillDelete = (e) => {
    const newSkill = skills.filter((skill) => skill !== e);
    setSkills(newSkill);
  };

  return (
    <div>
      {!url.includes("user") && (
        <Row>
          <Col sm={3} lg={2}>
            <Form.Label>Name</Form.Label>
          </Col>
          <Col>
            <Form.Control
              className="input-reset"
              type="text"
              placeholder="John Doe"
            />
          </Col>
        </Row>
      )}

      {!url.includes("client") && (
        <Row className="mt-3 mt-md-4">
          <Col sm={3} lg={2}>
            <Form.Label>Location</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="text"
              className="input-reset"
              placeholder="Bangladesh"
              disabled={url.includes("user") ? true : false}
            />
          </Col>
        </Row>
      )}

      {!url.includes("user") && (
        <Row className="mt-3 mt-md-4">
          <Col sm={3} lg={2}>
            <Form.Label>UVIOM ID (UID)</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="text"
              className="input-reset"
              placeholder="XXX-X-XXX"
            />
          </Col>
        </Row>
      )}

      <Row className="mt-3 mt-md-4">
        <Col sm={3} lg={2}>
          <Form.Label>Join Date</Form.Label>
        </Col>
        <Col>
          <Form.Control
            type="date"
            className="input-reset"
            disabled={url.includes("user") ? true : false}
          />
        </Col>
      </Row>

      {!url.includes("client") && (
        <Row className="mt-3 mt-md-4">
          <Col sm={3} lg={2}>
            <Form.Label>Experience</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="text"
              className="input-reset"
              placeholder="5 Year"
              disabled={url.includes("user") ? true : false}
            />
          </Col>
        </Row>
      )}

      {!url.includes("user") && (
        <>
          <Row className="mt-3 mt-md-4">
            <Col sm={3} lg={2}>
              <Form.Label>Number</Form.Label>
            </Col>
            <Col>
              <Form.Control
                className="input-reset"
                type="text"
                placeholder="+013-456-789"
              />
            </Col>
          </Row>
          <Row className="mt-3 mt-md-4">
            <Col sm={3} lg={2}>
              <Form.Label>Email</Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="email"
                className="input-reset"
                placeholder="mail@example.com"
              />
            </Col>
          </Row>
        </>
      )}

      {!url.includes("client") && (
        <Row className="mt-3 mt-md-4">
          <Col sm={3} lg={2}>
            <Form.Label>Skills</Form.Label>
          </Col>
          <Col>
            <div className="d-flex flex-wrap gap-2 gap-sm-3">
              {skills.map((skill, index) => (
                <span key={skill + index} className="creative__skills__badge">
                  {skill}{" "}
                  {url.includes("freelancer") && (
                    <span onClick={() => handleSkillDelete(skill)}>
                      <FontAwesomeIcon
                        role="button"
                        className="ms-2"
                        icon={faTrashAlt}
                      />
                    </span>
                  )}
                </span>
              ))}
              {url.includes("freelancer") && (
                <form className="add-skills" onSubmit={handleSkillSubmit}>
                  <input
                    className="skills input-reset"
                    placeholder="Add Skill"
                    type="text"
                  />
                  <button type="submit">
                    <FontAwesomeIcon
                      className="text-orange"
                      icon={faPlusSquare}
                    />
                  </button>
                </form>
              )}
            </div>
          </Col>
        </Row>
      )}

      <Row className="mt-3 mt-md-4">
        <Col sm={3} lg={2}>
          <Form.Label>Description</Form.Label>
        </Col>
        <Col>
          <Form.Control
            className="shadow-0 input-reset"
            as="textarea"
            placeholder="established fact that a reader will be distracted by the readable
content of a page when looking at its layout."
            rows={6}
            disabled={url.includes("user") ? true : false}
          />
        </Col>
      </Row>
    </div>
  );
}
export default ProfileInfo;
