import { useLocation } from "react-router-dom";
import "./formTimeline.css";

function FormTimeline() {
  const { pathname } = useLocation();

  return (
    <div className="formTimeline">
      <ul className="form__timeline">
        <li className="form__timeline--item active">
          <span />
        </li>
        <li
          className={`form__timeline--item ${
            pathname.includes("productinfo") && "active"
          }`}
        >
          <span />
        </li>
        <li
          className={`form__timeline--item ${
            pathname.includes("vehicle") && "active"
          }`}
        >
          <span />
        </li>
      </ul>

      <ul className="form__timeline__text">
        <li>Basic Task</li>
        <li>Product Info</li>
        <li>Find Express</li>
      </ul>
    </div>
  );
}
export default FormTimeline;
