import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Star from "../icons/Star";
import StartFill from "../icons/StartFill";
import "./ratingModal.css";

const RatingModal = ({ show, handleClose }) => {
  const [review, setReview] = useState("");
    const [starCount, setStarCount] = useState([]);
    const handleRatingChange = (count) => {
        const rating = [...new Array(count)].map((_,index) => index);
        setStarCount(rating)
    }
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      className="rating-modal"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title as={"h5"}>Your Rating</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>Rating</h6>
        <div className="rating-modal-star-box">
          {[...new Array(5)].map((_, index) => (
            <button onClick={()=>handleRatingChange(index + 1)}  key={index} className={`btn-0 `}>
              {starCount.includes(index) ? <StartFill /> : <Star />}
            </button>
          ))}
        </div>
        <br />
        <h6>Review</h6>
        <Form>
          <Form.Control
            onChange={(e) => setReview(e.target.value)}
            value={review}
            as="textarea"
            rows={4}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button onClick={handleClose} variant="outline-secondary">
          Cancel
        </Button>
        <Button className="orange-bg border-orange hover-orange-bg hover-orange-bd">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RatingModal;
