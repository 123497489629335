import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../Friends.css";
import { userStatusChange } from "./userStatusChange";
import { Link, useLocation } from "react-router-dom";
import { mainProfilePic } from "src/Projects/Life/Common/CommonUrl";
import { Actions } from "./Actions";
import { useDispatch } from "react-redux";
import {
  getSingleUserChat,
  setCurrentChatting,
  setCurrentChattingUser,
  setShowMessagePopup,
} from "src/features/life/messengerSlice";

const SingleFrieds = ({ userData, dataReload }) => {
  const { user_id, full_name, profile_image, user_address } = userData;
  const address = user_address ? JSON.parse(user_address) : "";
  const location = useLocation();
  const [status, setstatus] = useState();
  const dispatch = useDispatch();
  const userStatusChangeArg = {
    requestStatus: status,
    user_id: user_id,
    location: location,
    dataReload: dataReload,
  };

  useEffect(() => {
    if (status) {
      userStatusChange(userStatusChangeArg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const actionsArg = {
    setstatus: setstatus,
  };

  const handleMessageClick = () => {
    const data = {
      first_name: full_name,
      last_name: "",
      photo: profile_image,
      id: user_id,
      profile_id: null,
      username: null,
    };

    dispatch(setCurrentChatting([]));
    dispatch(setCurrentChattingUser(data));
    dispatch(getSingleUserChat({ user_id: user_id }));
    dispatch(setShowMessagePopup(true));
  };
  return (
    <Col xs={12} sm={12} md={6} lg={6}>
      <div className="SingleFriedsDiv my-2 box-shadow">
        <div className="container-fluid">
          <Row>
            <Col xs={2} md={2} lg={3} className="colmx px-0 ">
              {profile_image !== null ? (
                <img
                  className="img-fluid rounded-circle"
                  src={`${mainProfilePic}/${profile_image}`}
                  alt={full_name}
                />
              ) : (
                <img
                  className="img-fluid rounded-circle"
                  src="/user.png"
                  alt={full_name}
                />
              )}
            </Col>
            <Col xs={8} md={8} lg={7} className="colmx px-1 align-self-center">
              <Link
                to={`/life/public-life/${user_id}`}
                as="div"
                className="fndInfo font-14 py-0 my-0 hover_underline"
              >
                {full_name && full_name}{" "}
              </Link>
              <div className="fndInfo py-0 my-0">{address.current_city}</div>
              {/* <div className="fndInfo "> 6 friend</div> */}
            </Col>
            <Col xs={2} className="px-0 myCustomDropdown align-self-center">
              <Actions
                actionsArg={actionsArg}
                handleMessageClick={handleMessageClick}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

export default SingleFrieds;
