import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "./Categories.css"
import { useParams, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import { UbizzUrl } from '../../utilities/CommonUrl';
import paginationApiCall from 'src/Common/apiCall/paginationApiCall';
import { ProductInfiniteScroll } from '../Product/ProductInfiniteScroll';
import { SuggestedProduct } from '../../Components/Common/SuggestedProduct/SuggestedProduct';

const CategoriesProducts = () => {

  const { categoryType, categoryName } = useParams();
  const location = useLocation();
  const searchText = location.search.split("=")[1];
  let subCategory = "";
  let subSubCategory = "";
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [firstRenderIs, setfirstRenderIs] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    const productArgument = {
      setIsLoading: setIsLoading,
      postList: product,
      setPostList: setProduct,
      currentPage: currentPage,
      setHasMore: setHasMore,
      Url: `${UbizzUrl}/${categoryType}/${categoryName}`,
    }
    paginationApiCall(productArgument, true);
    setfirstRenderIs(true)
  }, [currentPage]);
  useEffect(() => {
    if (firstRenderIs) {
      setIsLoading(true)
      const productArgument = {
        setIsLoading: setIsLoading,
        postList: product,
        setPostList: setProduct,
        currentPage: 1,
        setHasMore: setHasMore,
        Url: `${UbizzUrl}/${categoryType}/${categoryName}`,
      }
      paginationApiCall(productArgument, false);
    }

  }, [categoryName]);

  if (searchText) {
    const queryText = searchText.split("&");
    if (queryText.length > 1) {
      subSubCategory = queryText[1]
    }
    subCategory = queryText[0]

  }
  // if(isLoading){
  //   return null
  // }
  return (
    <div className="px-2 px-md-4" style={{ minHeight: "70vh" }}>
      
      <SuggestedProduct categoryType={categoryType} categoryName={categoryName}/>
      <div className='pt-3'>
        <h6 className='category-link'>
          <Link to={`/corporate/category/${categoryName}`}> Category : {categoryName}</Link>
          {subCategory && <Link to={`/corporate/category/${categoryName}?search=${subCategory}`}> / {subCategory}</Link>}
          {subSubCategory && <Link to={`/corporate/category/${categoryName}?search=${subCategory}&${subSubCategory}`}> /{subSubCategory}</Link>}
        </h6>
      </div>
      <br />
      <ProductInfiniteScroll
        product={product}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        hasMore={hasMore}
      />

    </div>
  );
};

export default CategoriesProducts;