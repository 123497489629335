import React from "react";
import { Placeholder } from "react-bootstrap";
import styled from "styled-components";

const VideoPlaySkeleton = () => {
  return (
    <div className="my-3">
      <Placeholder animation="wave">
        <ImageStyle />
        <div>
          <Placeholder as="div" xs="7"></Placeholder>
        </div>
        <Placeholder as="div" xs="3"></Placeholder>
        <div className="mt-2">
          <Placeholder
            as="div"
            style={{ ...avatarStyle }}
            xs="12"
          ></Placeholder>
        </div>
      </Placeholder>
    </div>
  );
};

export default VideoPlaySkeleton;

const avatarStyle = { height: "50px", width: "50px", borderRadius: "50%" };

const ImageStyle = styled.div`
  border-radius: 0.5rem;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  height: 20rem;
  width: 100%;
  background-color: var(--bg-secondary);

  @media (min-width: 95em) {
    height: calc((100vw - 240px) / 3.5 - 1px);
  }
`;
