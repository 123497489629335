import TrackingHistory from "../components/TrackingHistory";
import stock1 from "./../assets/stock1.jpg";

function TrackHistory() {
  return (
    <div
      id="wrapper"
      className="heroArea__bg"
      style={{ backgroundImage: `url(${stock1})` }}
    >
      <div className="py-4" />
      <TrackingHistory />
      <div className="py-4" />
    </div>
  );
}
export default TrackHistory;
