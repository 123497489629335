import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { faEnvelope, faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { Url } from "../../../Common/CommonUrl";
import LayoutWrapper from "src/PublicPages/LayoutWrapper";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

const ForgetP = () => {
  const [showPassword, setShowPassword] = useState(true);
  const ToastFunc = (icon, title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: icon,
      title: title,
    });
  };
  const navigate = useNavigate();

  const [signInData, setSignInData] = useState({});
  const [sendOtp, setSentOtp] = useState(false);
  const [finalSignInData, setFinalSignInData] = useState();
  const [clickSignup, setClickSingup] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpLength, setOtplength] = useState(false);
  const handelBlure = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...signInData };
    newData[name] = value;
    setSignInData(newData);
  };
  const handelChange = (e) => {
    if (e.target.value.length === 6) {
      setOtplength(true);
    } else {
      setOtplength(false);
    }
    setOtp(e.target.value);
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    setClickSingup(true);
    axios
      .post(`${Url}/change_reset_password_without_login`, {
        customer_email: signInData.customer_email,
      })
      .then((res) => {
        // console.log(res.data.status)
        if (res.data.status === 1) {
          setSentOtp(true);
          ToastFunc("success", res.data.message);
        } else {
          setClickSingup(false);
          // setErrorMessage(res.data.message);
          ToastFunc("error", res.data.message);
        }
      });
  };
  const handelSubmitOtp = (e) => {
    e.preventDefault();
    // add verify code
    // delete signInData.repassword;
    // setFinalSignInData(signInData);
    const singUpDataWithVerifyCode = { ...signInData, varify_code: otp };
    // console.log(singUpDataWithVerifyCode);

    axios
      .post(
        `${Url}/confirm_reset_password_without_login`,
        singUpDataWithVerifyCode
      )
      .then((res) => {
        e.target.reset();
        if (res.data.status) {
          // swal({
          //   title: res.data.message,
          //   icon: "success",
          //   button: "Ok",
          // }).then(
          //   e.target.reset(),
          //   navigate("/login"),
          // );
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: res.data.message,
          }).then(e.target.reset(), navigate("/login"));
        } else {
          ToastFunc("error", res.data.message);

          navigate("/login");
        }
      });
  };
  return (
    <LayoutWrapper>
      <div className="my-5 mx-2 resetPassword">
        {/* <Navigation /> */}
        <div className="inner mx-auto px-2 text-center mw-[40rem] border py-4">
          <h4>{sendOtp === false ? "Reset Password" : "Verificaiton"}</h4>
          <p>
            {sendOtp === false
              ? "Enter Your Email Address To Reset Password"
              : "Please Check Your Email"}
          </p>
          <div className="otpBox">
            <div className="w-100">
              {sendOtp ? (
                <form onSubmit={handelSubmitOtp}>
                  <div className=" my-2 ">
                    {/* ------------ */}
                    <div className="InputFildDiv ">
                      <input
                        onChange={handelChange}
                        name="OTP"
                        type="number"
                        minlength="5"
                        maxlength="10"
                        placeholder="OTP"
                        required
                      />
                    </div>
                    {/* ---------- */}
                    {!(otp.length === 0 || otp.length === 6) && (
                      <small className="text-danger">Enter Valid OTP</small>
                    )}
                    {/*  */}

                    {/*  */}
                    <div className="InputFildDiv ">
                      <label>
                        <FontAwesomeIcon className="inputIcon" icon={faKey} />
                      </label>
                      <input
                        onChange={handelBlure}
                        name="new_password"
                        type={showPassword ? "password" : "text"}
                        placeholder="New Password"
                        required
                      />
                      <label>
                        <span
                          tabIndex="0"
                          aria-label="Show Password"
                          onClick={() => setShowPassword(!showPassword)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setShowPassword(!showPassword);
                            }
                          }}
                          as={Button}
                          className="ShowPassword"
                        >
                          {showPassword ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="InputFildDiv ">
                      <label>
                        <FontAwesomeIcon className="inputIcon" icon={faKey} />
                      </label>
                      <input
                        onChange={handelBlure}
                        name="confirm_password"
                        type={showPassword ? "password" : "text"}
                        placeholder="Confirm Password"
                        required
                      />
                      <label>
                        <span
                          tabIndex="0"
                          aria-label="Show Password"
                          onClick={() => setShowPassword(!showPassword)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setShowPassword(!showPassword);
                            }
                          }}
                          as={Button}
                          className="ShowPassword"
                        >
                          {showPassword ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </span>
                      </label>
                    </div>

                    {otpLength ? (
                      <button
                        type="submit"
                        className="button mx-auto mt-4 d-block"
                      >
                        {" "}
                        Confirm
                      </button>
                    ) : (
                      <button
                        disabled
                        type="submit"
                        className="button mx-auto mt-4 d-block disabled"
                      >
                        Confirm
                      </button>
                    )}
                  </div>
                </form>
              ) : (
                <form onSubmit={handelSubmit}>
                  <div className="InputFildDiv my-3 ">
                    <label>
                      <FontAwesomeIcon
                        className="inputIcon"
                        icon={faEnvelope}
                      />
                    </label>
                    <input
                      onChange={handelBlure}
                      name="customer_email"
                      type="email"
                      placeholder=" Enter Valid Email*"
                      required
                    />
                  </div>

                  {/* check box  */}
                  <div className="d-flex my-2 justify-content-center">
                    {clickSignup ? (
                      <button
                        disabled
                        type="submit"
                        className="button disabled"
                      >
                        Sign Up
                        <Spinner
                          className="ms-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </button>
                    ) : (
                      <button type="submit" className="button ">
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default ForgetP;
