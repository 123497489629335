import { debounce } from "lodash";
import { useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

function LifeNavigateFooter() {
  const { pathname } = useLocation();
  const [, dynamicPath, dynamicLife] = pathname.split("/");
  const footNavRef = useRef();
  const headerDiv = footNavRef.current;
  let prevScrollpos = window.pageYOffset + 1000;

  let debouncedCheckScroll;
  if (headerDiv) {
    debouncedCheckScroll = debounce(function () {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos >= prevScrollpos) {
        headerDiv.classList.add("hide");
      } else {
        headerDiv.classList.remove("hide");
      }
      prevScrollpos = currentScrollPos;

      pathname.includes("/message") && headerDiv?.classList.add("hide");
    }, 10);
  }

  useEffect(() => {
    window.addEventListener("scroll", debouncedCheckScroll);
    pathname.includes("/message") && headerDiv?.classList.add("hide");
    return () => window.removeEventListener("scroll", debouncedCheckScroll);
  }, [debouncedCheckScroll, pathname, headerDiv?.classList]);

  return (
    <Wrapper ref={footNavRef} life={dynamicLife}>
      <NavLink className="link" to={`/${dynamicPath}/public-post`}>
        <img
          src="https://res.cloudinary.com/yex/image/upload/v1669445295/UVIOM/public-life-Icon_ph5nj2.svg"
          alt=""
        />
        <span>Public</span>
      </NavLink>
      <NavLink className="link" to={`/${dynamicPath}/friends-life`}>
        <img
          src="https://res.cloudinary.com/yex/image/upload/v1669445294/UVIOM/friends-life-icon_s8kjfc.svg"
          alt=""
        />
        <span>Friends</span>
      </NavLink>
      <NavLink className="link" to={`/${dynamicPath}/family-life`}>
        <img
          src="https://res.cloudinary.com/yex/image/upload/v1669445293/UVIOM/family-life-icon_opd6du.svg"
          alt=""
        />
        <span>Family</span>
      </NavLink>
      <NavLink className="link" to={`/${dynamicPath}/professional-life`}>
        <img
          src="https://res.cloudinary.com/yex/image/upload/v1669445294/UVIOM/professional-life-icon_mzhdfv.svg"
          alt=""
        />
        <span>Professional</span>
      </NavLink>
    </Wrapper>
  );
}

export default LifeNavigateFooter;

const Wrapper = styled.div`
  padding-block: 0.7rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: var(--nav-bg);
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;

  justify-content: space-evenly;
  z-index: 999;
  box-shadow: 0px -1px 2px rgb(0 0 0 / 15%);
  transition: 0.3s cubic-bezier(0, 0.63, 0.68, 1.05);
  /* transform: ; */

  transform: ${(prop) =>
    prop.life === "public-life" ? "translateY(100%)" : "translateY(0)"};

  &.hide {
    transform: translateY(100%);
  }
  & .link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--text-primary);

    & img {
      filter: grayscale(100%);
      width: 2rem;
    }

    & span {
      opacity: 0;
      line-height: 0.5;
    }

    &.active {
      & img {
        filter: grayscale(0%);
        transform: scale(1.15);
      }

      & span {
        opacity: 1;
      }
    }
  }
`;
