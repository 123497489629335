import React, { lazy, Suspense } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Switch,
} from "react-router-dom";
import { UnAuthorized } from "./UnAuthorized";
import LoginPage from "src/Projects/Life/LifePages/LoginPage/LoginPage";
import SignupPage from "src/Projects/Life/LifePages/LoginPage/SignupPage";
import ForgetP from "src/Projects/Life/LifePages/LoginPage/Login&SignupBlock/ForgetP";
import VerifyEmail from "src/Projects/Life/LifePages/LoginPage/Login&SignupBlock/VerifyEmail";
import { PrivateRoute } from "./PrivateRoute";
import PdfViewer from "src/Projects/Life/Common/PdfViewer";

import { Upcomming } from "src/Projects/Life/Common/Upcomming";
import Privecy from "src/Projects/Life/LifePages/Privecy/Privecy";
import "../Projects/Life/LifePages/MainPage/MainPart.css";

import PaymentSuccess from "src/Common/Payment/PaymentSuccess/PaymentSuccess";
import PaymentFailed from "src/Common/Payment/PamymentFailed/PaymentFailed";
import PaymentCanceled from "src/Common/Payment/PaymentCanceled/PaymentCanceled";

// this comment important for  next project

import { DashBord } from "src/DashBord/DashBord";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";

import FakeAuthentication from "../PublicPages/FakeAuthentication";
import Ubizz from "src/Projects/Corporate/Ubizz";
import Food from "src/Projects/Food/Food";
import Job from "src/Projects/Job/Job";
import View from "src/Projects/View/View";
import Ride from "src/Projects/Ride/Ride";
import Creative from "src/Projects/Creative/Creative";
import Express from "src/Projects/Express/Express";

import LifeLayout from "src/Projects/Life/LifeLayout";
import About from "src/PublicPages/About";
import IntroPage from "src/PublicPages/IntroPage/IntroPage";
import Contact from "src/PublicPages/Contact";

export const Routes = () => {
  return (
    <Suspense fallback={<DottedCircleSpinner />}>
      <BrowserRouter>
        <Switch>
          {/* // this comment important for  next project  */}
          {/* Projects Routes  */}
          <Route path="/howDoYouKnow" element={<FakeAuthentication />} />
          <Route
            path="/ubizz/*"
            element={
              <PrivateRoute>
                <Ubizz />
              </PrivateRoute>
            }
          />
          <Route
            path="/food/*"
            element={
              <PrivateRoute>
                <Food />
              </PrivateRoute>
            }
          />
          <Route
            path="/ride/*"
            element={
              <PrivateRoute>
                <Ride />
              </PrivateRoute>
            }
          />
          <Route
            path="/creative/*"
            element={
              <PrivateRoute>
                <Creative />
              </PrivateRoute>
            }
          />
          <Route
            path="/express/*"
            element={
              <PrivateRoute>
                <Express />
              </PrivateRoute>
            }
          />
          <Route
            path="/Job/*"
            element={
              <PrivateRoute>
                <Job />
              </PrivateRoute>
            }
          />
          <Route
            path="/view/*"
            element={
              <PrivateRoute>
                <View />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <DashBord />
              </PrivateRoute>
            }
          />

          <Route
            path="/life/*"
            element={
              <PrivateRoute>
                <LifeLayout />
              </PrivateRoute>
            }
          />

          {/* general routes */}
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route
            path="/login"
            element={
              <UnAuthorized>
                <LoginPage />
              </UnAuthorized>
            }
          />
          <Route
            path="/signup"
            element={
              <UnAuthorized>
                <SignupPage />
              </UnAuthorized>
            }
          />
          <Route
            path="/forget-p"
            element={
              <UnAuthorized>
                <ForgetP />
              </UnAuthorized>
            }
          />

          <Route path="/payment/success" element={<PaymentSuccess />} />
          <Route path="/intro" element={<IntroPage />} />
          <Route path="/payment/failed" element={<PaymentFailed />} />
          <Route path="/payment/cancelled" element={<PaymentCanceled />} />

          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/pdf-viewer/:pdfUrl" element={<PdfViewer />} />
          <Route path="/upcomming" element={<Upcomming />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Upcomming />} />
          <Route path="/privecy" element={<Privecy />} />

          {/* fallback */}
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};
