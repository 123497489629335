import React from "react";
import ReactInputVerificationCode from "react-input-verification-code";
import { Form } from "react-bootstrap";

const Conformation = ({
  sent_method,
  handleSendCode,
  show_pin_input,
  handlePinChange,
  pin,
  handleSendMethodChange,
  handleConformationSubmit,
}) => {
  return (
    <div>
      <h6>Conformation</h6>
      <p>
        <small>Send a verification code to your:</small>
      </p>

      <div className="d-flex justify-content-between align-items-center">
        <Form className="conformation" aria-required>
          <Form.Check
            onChange={() => handleSendMethodChange("email")}
            inline
            label="Email"
            checked={sent_method === "email"}
            name="group1"
            type="radio"
          />
          {/* <Form.Check
            onChange={() => handleSendMethodChange("phone")}
            checked={sent_method === "phone"}
            inline
            label="Phone"
            name="group1"
            type="radio"
          /> */}
        </Form>
        <button
          onClick={handleSendCode}
          type="button"
          className="send-code-btn"
        >
          Send Code
        </button>
      </div>

      {show_pin_input && (
        <>
          
          <div className="custom-styles my-4 text-center mx-auto">
            <ReactInputVerificationCode
              onChange={handlePinChange}
              placeholder=" "
              length={4}
              value={pin}
            />
          </div>
        </>
      )}

      <div className="text-end mt-4">
        <button
          onClick={handleConformationSubmit}
          type="button"
          disabled={!(show_pin_input && pin.length > 3)}
          className="cart-confirm-btn send-code-btn text-dark"
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default Conformation;
