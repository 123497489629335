import { faChevronDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import { InputGroup, Form, FormControl } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

const NavSearchBox = ({ className = "" }) => {
  const [formData, setFormData] = useState({
    searchText: "",
    searchCategory: "product",
  });
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const prevState = formData;
    prevState[name] = value;
    setFormData((prevState) => ({ ...prevState }));
  };

  const { searchCategory, searchText } = formData;
  const { url} = useLocation();
// useEffect(() => {
//   if(formData.searchText && formData.searchCategory){
//     handleSubmit()

//   }
// }, [formData])

  const handleSubmit = () => {
    if (searchText.length > 0) {
      navigate(`/ubizz/search-${searchCategory}/${searchText}`,{
        relative:false,
        state: { data: formData},
        
      });
    }
    
  };
  return (
    <InputGroup className={`corporate__navbar__searchBox ${className}`}>
      <InputGroup.Text className=" p-0 corporate__navbar__input-text position-relative">
        <Form.Select
          onChange={handleOnChange}
          name="searchCategory"
          aria-label="Default select example"
          className="corporate__navbar__dropdown border-0"
        >
          <option value="product">Search By Product</option>
          <option value="store">Search By Store</option>
        </Form.Select>
        <FontAwesomeIcon
          className="corporate__navbar__icon-down"
          icon={faChevronDown}
        ></FontAwesomeIcon>
      </InputGroup.Text>
      <FormControl
        name="searchText"
        onChange={handleOnChange}
        className="corporate__navbar-input"
        aria-label="navbar search input"
      />
      <InputGroup.Text
        onClick={handleSubmit}
        className="corporate__navbar__input-text"
      >
        <FontAwesomeIcon icon={faSearch} />
      </InputGroup.Text>
    </InputGroup>
  );
};

export default NavSearchBox;
