import { isEmpty } from "lodash";
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getInfo } from "src/Common/apiCall/GetApiCalls";
import { MyDateFormate } from "src/Common/MyDateFormate";
import {
  orderRatingUrl,
  orderReportUrl,
  showOrderdetailsUrl,
} from "src/Projects/Corporate/utilities/CommonUrl";
import { OrderWiseProduct } from "../Common/OrderWiseProduct";
import AddressCard from "../Common/AddressCard";
import "./manage_order.css";
import RatingModal from "src/Projects/Corporate/Components/RatingModal/RatingModal";
import ReportModal from "src/Projects/Corporate/Components/ReportModal/ReportModal";
import useAuth from "src/hooks/useAuth";

const ManageOrder = () => {
  const { productId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [mapping_order, setmapping_order] = useState([]);
  const [show, setShow] = useState(false);
  const [info, setinfo] = useState();
  const handleClose = () => setShow(false);

  const [reportShow, setReportShow] = useState(false);
  const handleReportClose = () => setReportShow(false);
  const { authUser } = useAuth();

  useEffect(() => {
    getInfo(setIsLoading, `${showOrderdetailsUrl}/${productId}`, setinfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line no-unused-vars
  const [currentStatus, setCurrentStatus] = useState(3);
  useEffect(() => {
    if (info) {
      setmapping_order(info.mapping_order);
    }
  }, [info]);
  if (isLoading) {
    return null;
  }
  return (
    <div className="p-3 secondary-bg manage-order">
      <div className="d-flex w-100 justify-content-between align-items-center">
        <div className="flex-1">
          <h5 className="fs-6 py-3">Order Details</h5>
          <p>Order ID: #{info && info.OrderID}</p>
          <div>
            Order Date : {info && <MyDateFormate date={info.created_at} />}
          </div>
          <p>Total: {info && info.TotalPrice} Tk</p>
        </div>

        <div>
          <button
            onClick={() => setShow(true)}
            className="send-code-btn cart-confirm-btn manage-order-btn py-1 text-dark"
          >
            Review
          </button>
          <button
            onClick={() => setReportShow(true)}
            className="send-code-btn cart-confirm-btn manage-order-btn py-1 text-dark d-block mt-3"
          >
            Report
          </button>
        </div>
      </div>

      <div className="step-wizard">
        <ul className="step-wizard-list">
          <li
            className={`step-wizard-item  ${
              currentStatus === 1 ? "current-item" : ""
            }`}
          >
            <span className="progress-count">1</span>
            <span className="progress-label">Processing</span>
          </li>
          <li
            className={`step-wizard-item ${
              currentStatus === 2 ? "current-item" : ""
            }`}
          >
            <span className="progress-count">2</span>
            <span className="progress-label">Shipping</span>
          </li>
          <li
            className={`step-wizard-item  ${
              currentStatus === 3 ? "current-item" : ""
            }`}
          >
            <span className="progress-count">3</span>
            <span className="progress-label">Delivery</span>
          </li>
        </ul>
      </div>
      <br />

      {!isEmpty(mapping_order) &&
        mapping_order.map((data, index) => (
          <OrderWiseProduct data={data} key={index} />
        ))}
      <div className="text-align-right my-1">
        <div>
          <small>Total Price: {info && info.TotalPrice}tk</small>
        </div>
        <div>
          <small>Delivery Charge: {info && info.delivery_charge}tk</small>
        </div>
        <div>
          Total :{" "}
          {info && Number(info.TotalPrice) + Number(info.delivery_charge)} Tk
        </div>
      </div>

      <div className=" my-2">
        <div>
          <small>Name: {info && info.CustomerName}</small>
        </div>
        <div>
          <small>Phone:{info && info.Phone}</small>
        </div>
        <div>
          <small>Email : {info && info.email}</small>
        </div>
      </div>
      {info && (
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h5>Shipping Address</h5>

            <AddressCard
              type="billing"
              data={info.shipping_address}
              extraClass="address-card mb-1 px-2"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <h5>Billing Address</h5>

            <AddressCard
              type="billing"
              data={info.billing_address}
              extraClass="address-card mb-1 px-2"
            />
          </div>
        </div>
      )}

      <RatingModal
        show={show}
        handleClose={handleClose}
        url={orderRatingUrl}
        orderId={info.OrderID}
      />
      <ReportModal
        show={reportShow}
        handleClose={handleReportClose}
        title={"Order Report"}
        id={info.OrderID}
        fieldName="order_id"
        user_id={authUser.id}
        url={orderReportUrl}
      />
    </div>
  );
};

export default ManageOrder;
