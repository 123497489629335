import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import "./heroArea.css";

function HeroArea() {
  const { pathname:url } = useLocation();

  return (
    <Container fluid="xxl" className="px-0">
      <div className="heroArea__bg position-relative overflow-hidden">
        <video
          className="heroArea__video"
          playsInline={true}
          autoPlay={true}
          muted
          loop
        >
          <source src="https://res.cloudinary.com/yex/video/upload/v1663566117/UVIOM/uviomride.mp4" />
        </video>
        <Container>
          <div className="position-relative">
            <div className="rideHeroArea " />
            <ul className="hero__links ">
              <li>
                <Link className="hero__link" to={`${url}/findride`}>
                  Ride
                </Link>
              </li>
              <li>
                <Link className="hero__link" to={`${url}/history`}>
                  Riding History
                </Link>
              </li>
              <li>
                <Link className="hero__link" to={`${url}/safety`}>
                  Safety
                </Link>
              </li>
              <li>
                <Link className="hero__link" to={`${url}/coverage`}>
                  Coverage
                </Link>
              </li>
              <li>
                <Link className="hero__link" to={`${url}/partner`}>
                  Join as Partner
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
    </Container>
  );
}
export default HeroArea;
