import React, { useEffect } from "react";
import { Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import CloseIcon from "src/Projects/Life/icons/CloseIcon";
import JobCategories from "../JobCategories/JobCategories";
const dashboard_Logo = "/images/dashboard-dark.png";

const CategorySidebar = ({ show, handleClose }) => {
  useEffect(() => {}, []);
  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      className="food-profile-sidebar"
      scroll
      placement="start"
    >
      <Offcanvas.Header>
        <Offcanvas.Title>
          <Navbar.Brand
            as={Link}
            to="/"
            className="text-dark me-1 me-md-5"
          >

            <img
                style={{ height: "3rem" }}
                className="img-fluid "
                src={dashboard_Logo}
                alt="logo"
              />
            <span className="mb-0 ms-1">
              <span className="food-nav-uviom-text  fs-4 fw-bold">UVIOM</span>
              <span className="text-orange fs-4 fw-bold">Job</span>
            </span>
          </Navbar.Brand>
        </Offcanvas.Title>
        <button className="eco-btn" onClick={handleClose}>
          <CloseIcon className="primary-text" />
        </button>
      </Offcanvas.Header>
      
      <Offcanvas.Body className="sidebar-body">
        <JobCategories handleClose={handleClose} showBg={false}></JobCategories>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CategorySidebar;
