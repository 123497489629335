import { useState, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import SingleMessage from "../SingleMessage";

import "./messages.css";

const userMessage = [
  {
    name: "Enamul",
    image: "https://i.ibb.co/2S1LXbx/chat-Avatar01.jpg",
    lastMessage: "Can you convert my website",
    lastMessageDate: "4 day Ago",
  },
  {
    name: "Nahid",
    image: "https://i.ibb.co/vLWf6rR/chat-Avatar02.jpg",
    lastMessage: "Make Sure Design file is on",
    lastMessageDate: "6 day Ago",
  },
  {
    name: "Rabiul",
    image: "https://i.ibb.co/5x5b2zT/chat-Avatar03.jpg",
    lastMessage: "which software do you use to mak",
    lastMessageDate: "2 day Ago",
  },
  {
    name: "Mainul",
    image: "https://i.ibb.co/zV0P028/chat-Avatar04.jpg",
    lastMessage: "not a change software do you use to mak",
    lastMessageDate: "1 day Ago",
  },
  {
    name: "Arfat",
    image: "https://i.ibb.co/5x5b2zT/chat-Avatar05.jpg",
    lastMessage: "they are software do you use to mak",
    lastMessageDate: "8 day Ago",
  },
  {
    name: "Sakib",
    image: "https://i.ibb.co/0j3WMk1/chat-Avatar06.jpg",
    lastMessage: "are software do you use to mak",
    lastMessageDate: "4 day Ago",
  },
];

function Messages() {
  const [currentMessage, setCurrentMessage] = useState(0);

  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 768) {
        setShowMessage(true);
      } else {
        setShowMessage(false);
      }
    }

    handleResize()
    window.addEventListener('resize',handleResize)

    return function cleanupListener(){
      window.removeEventListener('resize',handleResize)
    }
  }, []);

  const handleShowMessage = () => {
    setShowMessage(() => {
      if (window.innerWidth > 768) {
        return true;
      } else {
        return !showMessage;
      }
    });
  };

  return (
    <div>
      <div className="messages__container">
        <div className="messages">
          <p className="messages__title" onClick={handleShowMessage}>
            <FontAwesomeIcon icon={faEnvelope} />
            Inbox{" "}
            <span className="ms-2 d-md-none">
              <FontAwesomeIcon icon={faChevronDown} />
            </span>
          </p>
          <Collapse in={showMessage}>
            <div>
              {userMessage.map((user, index) => (
                <div
                  onClick={() => {
                    setCurrentMessage(index);
                    handleShowMessage();
                  }}
                  key={index}
                  className="messages__profile"
                  role="button"
                >
                  <img
                    src={user.image}
                    className="messages__profile__img"
                    alt=""
                  />
                  <div className="">
                    <h6 className="mb-0">{user.name}</h6>
                    <small>{user.lastMessageDate}</small>
                    <p className="mb-0">{user.lastMessage.slice(0, 20)}...</p>
                  </div>
                </div>
              ))}
            </div>
          </Collapse>
        </div>

        <div>
          <SingleMessage currentPerson={userMessage[currentMessage]} />
        </div>
      </div>
    </div>
  );
}
export default Messages;
