import { faBell, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import UserIcon from "src/Projects/View/Icons/UserIcon";
import CloudIcon from "../../Icons/CloudIcon";
import HomeIcon from "../../Icons/HomeIcon";
import { Steps } from "../../Icons/Steps";
import useAuth from "src/hooks/useAuth";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import UploadVideoModal from "../UploadVideoModal/UploadVideoModal";
import SearchBox from "../SearchBox";
import OffCanvasNav from "../OffCanvasNav";

export const TopNavBar = () => {
  const [showVideoUploadModal, setShowVideoUploadModal] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showNavOffcanvas, setShowNavOffcanvas] = useState(false);

  let { pathname } = useLocation();
  const dynamicPath = pathname.split("/")[1];
  const { authUser } = useAuth();

  useEffect(() => {
    setShowSearchBox(false);
  }, [pathname]);

  return (
    <>
      <div
        className={`viewTopBar stickyParts nav-bg-opacity `}
        id="main-navigation"
        style={{ zIndex: "999" }}
      >
        <NavLink className="topNavItem" to={`/${dynamicPath}`} end>
          <HomeIcon className="topNavIcon" size="22" />
        </NavLink>
        <NavLink className="topNavItem" to={`/${dynamicPath}/notificaiton`}>
          <FontAwesomeIcon
            className="topNavIcon"
            style={{ fontSize: 22 }}
            icon={faBell}
          />
        </NavLink>
        {/* ------------- */}
        <div
          className="topNavItem"
          onClick={() => setShowVideoUploadModal(true)}
        >
          <CloudIcon className="topNavIcon" size={22} />
        </div>

        <div
          className="topNavItem"
          onClick={() => setShowSearchBox(!showSearchBox)}
        >
          <FontAwesomeIcon
            className="topNavIcon"
            style={{ fontSize: 16 }}
            icon={faSearch}
          />
        </div>

        <UploadVideoModal
          show={showVideoUploadModal}
          handleClose={() => setShowVideoUploadModal(false)}
        />

        <NavLink className="topNavItem" to={`/${dynamicPath}/save_video`}>
          <Steps className="topNavIcon" color="#6984a5" size="22" />
        </NavLink>
        <NavLink
          className="topNavItem"
          to={`/${dynamicPath}/channel/${authUser.id}`}
        >
          <UserIcon className="topNavIcon" size="22" />
        </NavLink>
        <div
          onClick={() => setShowNavOffcanvas(true)}
          className="d-flex d-lg-none topNavItem"
          // activeClassName=""
          // as="div"
          // to={`/${dynamicPath}/menuoptions`}
        >
          <ThreeDots className="topNavIcon" size="22"></ThreeDots>
        </div>
      </div>
      {showSearchBox && (
        <SearchBox
          setShowSearchBox={setShowSearchBox}
          showSearchBox={showSearchBox}
        />
      )}

      {showNavOffcanvas && (
        <OffCanvasNav
          show={showNavOffcanvas}
          handleClose={() => setShowNavOffcanvas(false)}
        />
      )}
    </>
  );
};
