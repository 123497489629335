import React from "react";

const ViewIcon = ({ size = "", className = "", fillcolor }) => {
  return (
    <svg
      width={size ? size : "41"}
      height={size ? size : "40"}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={className}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.2176 16.5891C41.5944 17.9614 41.5944 21.392 39.2176 22.7643L11.4089 38.8197C9.03208 40.1919 6.06106 38.4766 6.06106 35.7321L6.06107 3.62132C6.06107 0.876808 9.03209 -0.838506 11.4089 0.533748L39.2176 16.5891ZM38.5046 21.5292C39.9307 20.7059 39.9307 18.6475 38.5046 17.8242L10.6959 1.76878C9.26977 0.945424 7.48716 1.97461 7.48716 3.62132L7.48715 35.7321C7.48715 37.3788 9.26976 38.408 10.6959 37.5846L38.5046 21.5292Z"
        fill="#688FB4"
      />
      <path
        d="M31.8877 17.2255C33.7464 18.3311 33.7464 21.0224 31.8877 22.128L5.02316 38.1083C3.12195 39.2392 0.712855 37.8691 0.712855 35.657L0.712856 3.69648C0.712856 1.48433 3.12196 0.114279 5.02317 1.24521L31.8877 17.2255Z"
        fill="#F1A350"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.2523 16.6126C34.5757 17.9946 34.5757 21.3587 32.2523 22.7408L5.38779 38.721C3.01128 40.1347 -9.70414e-05 38.4221 -9.69205e-05 35.6569L-9.55235e-05 3.69641C-9.54026e-05 0.93123 3.01128 -0.781337 5.38779 0.63232L32.2523 16.6126ZM31.5233 21.5151C32.9173 20.6859 32.9173 18.6675 31.5232 17.8382L4.65873 1.85796C3.23282 1.00976 1.42599 2.03731 1.42599 3.69641L1.42599 35.6569C1.42599 37.316 3.23282 38.3436 4.65872 37.4954L31.5233 21.5151Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1607 12.3969C10.8498 12.3969 11.4085 12.909 11.4085 13.5408V24.9792C11.4085 25.611 10.8498 26.1231 10.1607 26.1231C9.47151 26.1231 8.91284 25.611 8.91284 24.9792L8.91284 13.5408C8.91284 12.909 9.47152 12.3969 10.1607 12.3969Z"
        fill="#688FB4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6477 12.3969C18.3369 12.3969 18.8956 12.909 18.8956 13.5407V24.9792C18.8956 25.611 18.3369 26.123 17.6477 26.123C16.9586 26.123 16.3999 25.611 16.3999 24.9792L16.3999 13.5407C16.3999 12.909 16.9586 12.3969 17.6477 12.3969Z"
        fill={fillcolor ? fillcolor : "#688FB4"}
      />
    </svg>
  );
};

export default ViewIcon;
