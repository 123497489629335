import { faThumbsUp, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { ListDropdownItem } from "../../ListDropdownItem";

export const LikedUserList = ({ data }) => {
  const {
    showLikeListDropdown,
    postLikeCount,
    ListDropdownShow,
    likedUserListState,
    showLikedUsersModal,
  } = data;
  return (
    <Dropdown
      onClick={showLikeListDropdown}
      className="listDropdown w-fit-content mx-auto LikedUserListModal lh-1"
    >
      <Dropdown.Toggle
        aria-label="see who liked"
        className="ToggleDesignClear lh-1"
      >
        <div className="smallTextSize mt-1 hoverEffect clickEffect">
          {postLikeCount > 0 ? `${postLikeCount} likes` : null}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu show={ListDropdownShow} className="likedUserList">
        {likedUserListState && likedUserListState.length > 0 ? (
          <>
            <div className="d-flex justify-content-between dropdown-item likedUserList-header">
              <FontAwesomeIcon icon={faThumbsUp} size="sm" />

              <div onClick={showLikedUsersModal} className="pointer ">
                See All
                <FontAwesomeIcon className="mr-1" icon={faTimesCircle} />
              </div>
            </div>

            <div className="dropdownItemContainer mx-md-2 py-2">
              {likedUserListState.map((item) => (
                <Dropdown.Item href="" key={item.id}>
                  <ListDropdownItem item={item} />
                </Dropdown.Item>
              ))}
            </div>
          </>
        ) : (
          <div className="text-center text-white">Loading...</div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
