import React from "react";
import { Placeholder } from "react-bootstrap";
import SingleVideoCardSkeleton from "./SingleVideoCardSkeleton";

// styled components
import { CardWrapper } from "../Pages/ChannelView/ChannelVideos";

const ChannelViewSkeleton = () => {
  return (
    <div>
      <Placeholder animation="wave">
        <div style={{ ...imageStyle }}></div>

        <div className="mt-2">
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Placeholder
              as="div"
              style={{ ...avatarStyle }}
              xs="3"
            ></Placeholder>

            <div>
              <div className="placeholder" style={{ width: "7rem" }} />
              <br />
              <div className="placeholder" style={{ width: "5rem" }} />
            </div>

            <div
              className="placeholder"
              style={{ width: "5rem", marginLeft: "auto", marginRight: "10%" }}
            />
          </div>
        </div>

        <div className="mt-4 mb-3">
          <div style={{ display: "flex", gap: "2rem" }}>
            <div className="placeholder" style={{ width: "7rem" }} />
            <div className="placeholder" style={{ width: "7rem" }} />
            <div className="placeholder" style={{ width: "7rem" }} />
          </div>
        </div>

        <CardWrapper>
          {Array.from({ length: 3 }).map((_, index) => (
            <SingleVideoCardSkeleton key={index} />
          ))}
        </CardWrapper>
      </Placeholder>
    </div>
  );
};

export default ChannelViewSkeleton;

const avatarStyle = {
  height: "5rem",
  width: "5rem",
  borderRadius: "50%",
};

const imageStyle = {
  borderRadius: ".5rem",
  marginBottom: ".7rem",
  height: "10rem",
  width: "100%",
  backgroundColor: "var(--bg-secondary)",
};
