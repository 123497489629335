import React from "react";

export const ProductImg = ({ product_image,imgBase}) => {


  return (
    <img
    src={`${imgBase}/${product_image}`}
    alt="product card"
    className="img-fluid"
  />
  );
};
