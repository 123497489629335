import React from "react";
import { Container } from "react-bootstrap";

const HelpCenter = () => {
  return (
    <Container fluid style={{ minHeight: "65vh" }} className="p-0 secondary-bg primary-text">
      <div className="p-2 p-md-4">
        <h6  className="fw-bold">Help Center</h6>
        <p className="text-center font-14">
          Please, contact at the following addresses:
        </p>
        <div className="mt-4">
          <h6>UVIOM Corporate</h6>
          <p className="font-14">House: 100/A, Dhanmondi-4, Dhaka</p>
          <br />
          <p className="font-14">Working Hour: 09.00 AM to 06.00 PM</p>
          <p className="font-14">Email: support@uviom.com</p>
          <p className="font-14">Website: www.uviomcorporate.com</p>
          <p className="font-14">Facebook: facebook.com/uviomcorporate</p>
        </div>
      </div>
    </Container>
  );
};

export default HelpCenter;
