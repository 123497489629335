import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";

const CompanyRoute = ({ element, ...rest }) => {
  const { company_profile, isLoading } = useSelector(
    (store) => store.jobProfile
  );
  const { pathname } = useLocation();

  if (isLoading) {
    return <BubbleLoader />;
  } else if (company_profile?.id) {
    return element;
  } else {
    return (
      <Navigate to="/Job/company/register" replace state={{ from: pathname }} />
    );
  }
};

export default CompanyRoute;
