import { useState, useRef } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

function JobApplyModal(props) {
  const charLimit = 1500;
  const [textareaValue, setTextareaValue] = useState("");
  const [currentCharLength, setCurrentCharLength] = useState(0);

  const handleCharLimit = (e) => {
    setTextareaValue(e.target.value.slice(0, charLimit));
    setCurrentCharLength(
      e.target.value.length > charLimit ? charLimit : e.target.value.length
    );
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="creative__modal__header" closeButton>
        <h6 className="mb-0">Send Your Information</h6>
      </Modal.Header>
      <Modal.Body className="creative__modal__body">
        <Row>
          <Col lg={4}>
            <div className="d-flex gap-3 align-items-center">
              <img
                src="https://i.ibb.co/Bfp9vX0/freelancer-3.jpg"
                alt=""
                className="creative__modal__freelancer__img"
              />
              <div>
                <h6>Mr Client</h6>
                <p className="mb-0">Thailand</p>
                <p className="mb-0">
                  <FontAwesomeIcon className="text-orange" icon={faStar} /> 5.0
                  (12)
                </p>
                <small>4 hours ago</small>
              </div>
            </div>

            <p className="mt-3">
              <span className="fw-500">Post</span>: UI/UX Designer
            </p>

            <h6>Requirement Task </h6>

            <div className="d-flex flex-wrap gap-2">
              <div className="creative__skills__badge">UX Research</div>
              <div className="creative__skills__badge">
                Making Problem Statement
              </div>
              <div className="creative__skills__badge">Making Solution</div>
              <div className="creative__skills__badge">Wire Frame</div>
              <div className="creative__skills__badge">Mockup</div>
            </div>
          </Col>
          <Col className="mt-3 mt-lg-0" lg={8}>
            <h6>Fixed Price Or Hourly $12</h6>
            <div className="modal-input-and-file position-relative mb-2">
              <Form.Control
                as="textarea"
                value={textareaValue}
                onChange={handleCharLimit}
                className="shadow-0 mb-5 input-reset"
                rows={10}
                placeholder="scrambled it to make a type specime"
              />
              <div className="border rounded position-absolute w-100 top-100 py-2 px-3 d-flex justify-content-between textarea-limit">
                <div>{`${currentCharLength}/ ${charLimit}`}</div>
                <label htmlFor="fileforjobapply">
                  <FontAwesomeIcon icon={faCloudUploadAlt} />
                </label>
                <input type="file" hidden name="file" id="fileforjobapply" />
              </div>
            </div>
            <div className="py-2 py-md-3" />
            <div className="d-flex gap-2 gap-sm-3 flex-wrap justify-content-sm-end">
              <Button
                onClick={() => {
                  props.onHide();
                  props.onSuccess();
                }}
                className="btn__creative px-4"
              >
                Agreement
              </Button>
              <Button onClick={props.onHide} className="btn__creative px-4">
                Send
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
export default JobApplyModal;
