import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import InputGanderWithLabel from "src/Common/InputGenderWithLabel/InputGenderWithLabel";
import InputPhoneNumber from "src/Common/InputWithLabel/InputPhoneNumber";
import InputWithLabel from "src/Common/InputWithLabel/InputWithLabel";

import "./personalInfo.css";

const defaultData = {
  name: "Rabiul Islam",
  phone: "+8801700000000",
  email: "rabiulIslam@gmail.com",
  address: "Natore, Bangladesh",
  gender: "male",
  city: "Natore",
  post_code: "2310",
  country: "Bangladesh",
};

const PersonalInformation = () => {
  const [formData, setFormData] = useState(defaultData);
  const [isEditable, setIsEditable] = useState(false);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let prevState = formData;
    prevState[name] = value;
    setFormData({ ...prevState });
  };

  const phoneHandler = (value) => {
    setFormData((prevState) => ({ ...prevState, phone: value }));
  };

  const handleCancel = () => {
    setIsEditable(false);
  };

  const { name, phone, address, city, country, email, post_code, gender } =
    formData;

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="p-3 rounded secondary-bg  personal-info personal-input">
      <div className="d-flex justify-content-between mb-4">
        <h6 className="fs-6 "> Personal Information </h6>
        <button
          className="eco-btn ps-profile-edit-btn  primary-text"
          onClick={() => setIsEditable(true)}
        >
          Edit
        </button>
      </div>
      <Form onSubmit={handleSubmit}>
        {/* name */}
        <InputWithLabel
          disabled={!isEditable}
          label="Name "
          name="name"
          value={name}
          handler={handleChange}
          checkoutInput
          placeholder="Enter Your Name"
          id="name"
        ></InputWithLabel>

        {/* Phone  */}

        <InputPhoneNumber
          value={phone}
          handler={phoneHandler}
          label="Phone"
          disabled={!isEditable}
        />
        {/* email  */}
        <InputWithLabel
          disabled={!isEditable}
          label="Email"
          checkoutInput
          name="email"
          value={email}
          handler={handleChange}
          placeholder="Enter Your Email"
          type="email"
        ></InputWithLabel>
        <InputGanderWithLabel
          disabled={!isEditable}
          label={"Gender"}
          isEditable
          checkoutInput
          handler={handleChange}
          name="gender"
          value={gender}
        />

        <InputWithLabel
          disabled={!isEditable}
          label="Address 1"
          checkoutInput
          name="address"
          value={address}
          handler={handleChange}
          placeholder="Enter Your Address"
        ></InputWithLabel>

        <InputWithLabel
          label="City"
          disabled={!isEditable}
          checkoutInput
          name="city"
          value={city}
          handler={handleChange}
          placeholder="Enter Your City"
        ></InputWithLabel>
        <InputWithLabel
          label="Country"
          disabled={!isEditable}
          checkoutInput
          value={country}
          name="country"
          handler={handleChange}
          placeholder="Enter Your Country"
        ></InputWithLabel>
        <InputWithLabel
          label="Post Code"
          disabled={!isEditable}
          checkoutInput
          name="post_code"
          handler={handleChange}
          value={post_code}
          placeholder="Enter Your Post Code"
        ></InputWithLabel>

        {isEditable && (
          <div className="text-end">
            <button
              onClick={handleCancel}
              type="button"
              className="send-code-btn me-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className=" send-code-btn cart-confirm-btn text-dark"
            >
              Save
            </button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default PersonalInformation;
