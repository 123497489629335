import React from "react";
import "./Login&Registrasion.css";

const Mockup = () => {
  return (
    <div>
      <div className="MockupDisplay align-items-center FeatureBlock d-none d-lg-flex ">
        <img
          className="img-fluid px-4"
          src="https://res.cloudinary.com/yex/image/upload/v1669443334/UVIOM/mobiles_dkzqvb.png"
          alt="Welcome to UVIOM Life"
        />
      </div>
    </div>
  );
};

export default Mockup;
