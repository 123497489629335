import { Link } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faDotCircle,
  faLongArrowAltRight,
  faMapMarkerAlt,
  faStar,
  faCommentAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {} from "@fortawesome/free-solid-svg-icons";

import scooterIcon from "./../../assets/scooter-icon.svg";
import "./intersectionWithRider.css";

function IntersectionWithRider(props) {
  const {
    riderImage,
    riderName,
    riderRating,
    riderTotalRating,
    riderCarModel,
    nextRouteText,
    nextRoute,
  } = props;

  return (
    <Container>
      <div className="intersectionWithRider">
        <div className="intersectionWithRider__location--container">
          <div className="intersectionWithRider__location--icon">
            <img src={scooterIcon} alt="" />
          </div>
          <div className="intersectionWithRider__location">
            <span>
              <FontAwesomeIcon
                className="rideHistoryCard__icon"
                icon={faDotCircle}
              />
              Mirpur-1 Bus Stand
            </span>
            <span className="d-none d-sm-block">
              <FontAwesomeIcon icon={faLongArrowAltRight} />
            </span>
            <span>
              <FontAwesomeIcon
                className="rideHistoryCard__icon"
                icon={faMapMarkerAlt}
              />{" "}
              Dhanmondi 32
            </span>
          </div>
        </div>

        <p className="intersectionWithRider__details">
          Total Distance: 15 Km
          <br />
          Estimate time: 55 min
        </p>
        <p className="intersectionWithRider__cost">
          <span>Estimate Fare: </span>
          <span className="fs-5">$20</span>
        </p>

        {riderName && (
          <>
            <div className="intersectionWithRider__rider">
              <div className="intersectionWithRider__rider--img">
                <img src={riderImage} alt="" />
              </div>
              <div>
                <p className="intersectionWithRider__rider--name">
                  {riderName}
                </p>
                {riderRating && (
                  <p className="intersectionWithRider__rider--rating">
                    <FontAwesomeIcon icon={faStar} /> ({riderRating}){" "}
                    {riderTotalRating} Rating
                  </p>
                )}
              </div>
              <div>
                <FontAwesomeIcon
                  className="intersectionWithRider__rider--contact"
                  icon={faCommentAlt}
                />

                <FontAwesomeIcon
                  className="intersectionWithRider__rider--contact"
                  icon={faPhone}
                />
              </div>
            </div>
            {riderCarModel && (
              <p className="intersectionWithRider__rider--carModel">
                <small>Car Model</small> <br /> <span>({riderCarModel})</span>
              </p>
            )}
          </>
        )}

        <div className="text-center mt-4">
          <Button as={Link} to={nextRoute} className="btn__ride">
            {nextRouteText}
          </Button>
        </div>
      </div>
    </Container>
  );
}
export default IntersectionWithRider;
