import { Routes, Route, Navigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import Messages from "../components/Messages";
import LeftSidebar from "../components/LeftSidebar";
import WorkPackage from "./../components/WorkPackage";
import PreviousWorks from "../components/PreviousWorks";
import ProfileInfo from "../components/ProfileInfo";
import PreviousWorkDetails from "../components/PreviousWorkDetails";
import AtaGlance from "../components/Common/AtaGlance";

const ataGlance = [
  { title: "Minimum Package", sub: "50$" },
  { title: "Complete Project", sub: "300+" },
  {
    title: "Review",
    sub: (
      <>
        <FontAwesomeIcon icon={faStar} /> 5.0 (300+)
      </>
    ),
  },
  { title: "Techlab Rank", sub: "Top 10+" },
];

function UserProfile() {
  return (
    <div id="wrapper">
      <Container>
        <div className="my-4" />
        <Row>
          <Col lg={3}>
            <LeftSidebar
              userImage="https://i.ibb.co/DCt1HtT/person2.jpg"
              userName="Enamul Hossen"
              userSub="UI/UX Designer"
            />
          </Col>
          <Col lg={9}>
            <AtaGlance stats={ataGlance} />
            <ProfileRoutes />
          </Col>
        </Row>
        <div className="my-4" />
      </Container>
    </div>
  );
}
export default UserProfile;

const ProfileRoutes = () => {
  return (
    <Routes>
      <Route path={`/messages`} element={<Messages />} />
      <Route path={`/profile-info`} element={<ProfileInfo />} />
      <Route path={`/previouswork/work1`} element={<PreviousWorkDetails />} />
      <Route path={`/previouswork`} element={<PreviousWorks />} />
      <Route path={`/workpackage`} element={<WorkPackage />} />
      <Route path="*" element={<Navigate to="messages" replace />} />
    </Routes>
  );
};
