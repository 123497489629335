import { Link } from "react-router-dom";
import "./contactInfo.css";

function ContactInfo() {
  return (
    <address className="address ms-0 ms-lg-5 mt-5 mt-lg-0 ">
      UVIOM TechLab
      <br />
      New York, USA-10472 <br />
      Call :
      <a className="address__link" href="tel:+1516-800-6900">
        +1516-800-6900 (W/T)
      </a>
      <br />
      {/* E-Mail :
      <a className="address__link" href="mailto:it.lidillc@gmail.com">
        it.lidillc@gmail.com
      </a>
      <br /> */}
      Support :
      <a className="address__link" href="mailto:techLab@uviom.com">
        TechLab@uviom.com
      </a>
      <br />
      Browse :
      <Link className="address__link" to="/">
        www.techlab.uviom.com
      </Link>
      <br />
      <iframe
        className="address__map"
        id="map"
        title="UVIOM Location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24151.172489690598!2d-73.88298398106076!3d40.83023564862402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f4c24a66e47f%3A0xb8502a2a2f022aaf!2sThe%20Bronx%2C%20NY%2010472%2C%20USA!5e0!3m2!1sen!2sbd!4v1659630693072!5m2!1sen!2sbd"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </address>
  );
}
export default ContactInfo;
