import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import CompanyBanner from "../../Components/Common/CompanyBanner";
import InputWithLabel from "src/Common/InputWithLabel/InputWithLabel";
import SaveAndCancelBtnGroup from "../../Components/Common/SaveAndCancelBtnGroup";
import "./UpdateCompanyProfile.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  updateCompanyBannerUrl,
  updateCompanyLogoUrl,
  updateCompanyProfileUrl,
  companyBannerPhotoUrl,
  companyProfilePhotoUrl,
} from "../../utilities/job_commonUrl";
import fileUploadToServer from "../../api_call/fileUploadApiCall";
import { compare_2_Object, get_AWS_file_name } from "src/utilities/utilities";
import postData from "../../api_call/postApiCall";
import { useEffect } from "react";
import SocialLinkItemUpdate from "./SocialLinkItemUpdate";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import LocationSelectInput from "../../Components/Common/LocationSelectInput";
import { useDispatch, useSelector } from "react-redux";
import { getDivisionByDistricts } from "../../utilities/functions";
import { useAddSocialLinkMutation } from "src/app/services/jobApi/jobPostApi";
import { getCompanyProfile } from "src/features/job/jobProfileSlice";
import CropPhotoModal from "./CropPhotoModal";
import compress_image from "src/utilities/compress_image";
import { dataURLtoFile } from "src/Projects/Life/utils/dataURLtoFile";

const UpdateCompanyProfile = () => {
  const dispatch = useDispatch();
  const [addSocialLink, { isLoading, error: server_err, isSuccess }] =
    useAddSocialLinkMutation();
  const { state } = useLocation();
  const prevCompanyData = { ...state.data };
  const { company_profile } = useSelector((store) => store.jobProfile);
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [showCrop, setShowCrop] = useState(false);
  const [companyData, setCompanyData] = useState({ ...company_profile });
  const [socialLinks, setSocialLinks] = useState([
    ...(state?.socialLinks || []),
  ]);
  const [profile_banner, steProfile_banner] = useState("");
  const [profile_picture, steProfile_picture] = useState("");
  const [profile_croppedImage, setProfile_croppedImage] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [upload_status, setUpload_status] = useState({
    logoUpload_status: false,
    bannerUpload_status: false,
    profileUpload_status: false,
  });
  const [socialLinkText, setSocialLinkText] = useState({
    social_media_name: "",
    social_media_link: "",
    socialError: "",
  });
  const [districts, setDistricts] = useState([]);

  const { logoUpload_status, bannerUpload_status, profileUpload_status } =
    upload_status;

  const {
    company_EINN,
    company_address,
    company_email,
    company_name,
    company_phone,
    website,
    profile_banner: uploaded_banner,
    profile_picture: uploaded_logo,
    district_id,
    division_id,
  } = companyData;

  const [profile_picture_64, steProfile_picture_64] = useState(
    uploaded_logo ? companyProfilePhotoUrl + uploaded_logo : ""
  );
  const [profile_banner_64, steProfile_banner_64] = useState(
    uploaded_banner ? companyBannerPhotoUrl + uploaded_banner : ""
  );

  const handleCropClose = () => {
    setShowCrop(false);
  };

  const handleEditButton = () => {
    setShow(true);
  };
  const handleCancelBtn = () => {
    setShow(false);
  };

  const handleFileInput = (e) => {
    const name = e.target.name;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    if (name === "profile_banner") {
      steProfile_banner(file);
      reader.onload = (e) => {
        steProfile_banner_64(e.target.result);
      };
    } else if (name === "profile_picture") {
      reader.onload = (e) => {
        steProfile_picture(e.target.result);
        setShowCrop(true);
      };
    }
  };

  useEffect(() => {
    const districts = getDivisionByDistricts(division_id) || [];
    setDistricts(districts);
  }, [division_id]);
  // input filed onchange store value to state

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const prevState = companyData;
    prevState[name] = value;
    setCompanyData({ ...prevState });
  };

  const handleSocialInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const prevState = socialLinkText;
    prevState[name] = value;
    setSocialLinkText({ ...prevState });
  };

  // this 3 function call when data uploading successful to the server
  const callAfterBannerUploadSuccess = () => {
    setUpload_status((prevState) => ({
      ...prevState,
      bannerUpload_status: true,
    }));
  };
  const callAfterLogoUploadSuccess = () => {
    setUpload_status((prevState) => ({
      ...prevState,
      logoUpload_status: true,
    }));
  };
  const callAfterProfileUploadSuccess = () => {
    setUpload_status((prevState) => ({
      ...prevState,
      profileUpload_status: true,
    }));
  };

  // this function upload the Banner photo to the server
  const uploadBannerPhoto = async () => {
    const formData = new FormData();
    if (profile_banner) {
      const compressed_images = await compress_image([profile_banner]);
      formData.append("profile_banner", compressed_images[0]);

      const fileUploadArg = {
        Url: updateCompanyBannerUrl,
        formData: formData,
        setIsLoading,
        setError,
        setSuccess,
        callAfterSuccess: callAfterBannerUploadSuccess,
      };
      fileUploadToServer(fileUploadArg);
    }
  };

  // this function upload the profile photo to the server
  const uploadProfilePhoto = async () => {
    const formData = new FormData();
    if (profile_picture) {
      const canvasDataUrl = profile_croppedImage.toDataURL("image/jpeg");
      const convertedUrlToFile = dataURLtoFile(
        canvasDataUrl,
        get_AWS_file_name()
      );
      const compressed_images = await compress_image([convertedUrlToFile]);
      formData.append("profile_picture", compressed_images[0]);

      const fileUploadArg = {
        Url: updateCompanyLogoUrl,
        formData: formData,
        setIsLoading,
        setError,
        setSuccess,
        callAfterSuccess: callAfterLogoUploadSuccess,
      };
      fileUploadToServer(fileUploadArg);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // check the  data is updated. if not updated throw this error message to bottom
    if (
      compare_2_Object(prevCompanyData, companyData) &&
      !profile_banner &&
      !profile_picture
    ) {
      setError("Profile UP to Date. Change some information to update Profile");
    } else {
      // if profile data updated then call this function and file upload function not call if file not change
      if (compare_2_Object(prevCompanyData, companyData) === false) {
        const postDataArg = {
          url: updateCompanyProfileUrl,
          data: companyData,
          setIsLoading,
          setError,
          setSuccess,
          callAfterSuccess: callAfterProfileUploadSuccess,
        };
        postData(postDataArg);
      } else {
        callAfterProfileUploadSuccess();
      }
      // if banner photo change then call banner upload function
      if (profile_banner) {
        uploadBannerPhoto();
      } else {
        callAfterBannerUploadSuccess();
      }
      // if profile photo change then call profile photo upload function
      if (profile_picture) {
        uploadProfilePhoto();
      } else {
        callAfterLogoUploadSuccess();
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setSocialLinkText({});
      setShow(false);
      navigate("/Job/company/profile");
    }
    if (server_err) {
      setError(server_err.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, server_err]);

  const uploadSocialLink = () => {
    if (socialLinkText.social_media_name.length < 1) {
      setSocialLinkText((prevState) => ({
        ...prevState,
        socialError: "Social media name is required",
      }));
    } else if (socialLinkText.social_media_link.length < 1) {
      setSocialLinkText((prevState) => ({
        ...prevState,
        socialError: "Social media link is required",
      }));
    } else {
      setSocialLinkText((prevState) => ({ ...prevState, socialError: "" }));
      const data = { ...socialLinkText };
      delete data.socialError;
      data["title"] = socialLinkText.social_media_name;
      addSocialLink(data);
    }
  };

  // if profile, banner and logo upload successful then Navigateto company profile page
  useEffect(() => {
    if (profileUpload_status && logoUpload_status && bannerUpload_status) {
      navigate("/Job/company/profile");
      dispatch(getCompanyProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileUpload_status, logoUpload_status, bannerUpload_status]);

  return (
    <div className="secondary-bg">
      {(loading || isLoading) && <BubbleLoader />}
      <CompanyBanner
        showEditIcon
        handler={handleFileInput}
        profile_banner={profile_banner_64}
        profile_picture={profile_picture_64}
      />
      <br />
      <div className="applicantTitle">
        <h6>Update Company Profile</h6>
      </div>
      <div className="updateCompanyDetail overflow-hidden">
        <br />
        <Form onSubmit={handleSubmit}>
          <InputWithLabel
            className={["bordaredInput", "text-start ps-4"]}
            value={company_name}
            label="Company Name"
            name="company_name"
            handler={handleInput}
          />

          <InputWithLabel
            className={["bordaredInput", "text-start ps-4"]}
            value={company_EINN}
            label="Company EINN"
            name="company_EINN"
            handler={handleInput}
          />

          <InputWithLabel
            className={["bordaredInput", "text-start ps-4"]}
            value={company_phone}
            label="Phone"
            name="company_phone"
            handler={handleInput}
          />

          <InputWithLabel
            className={["bordaredInput", "text-start ps-4"]}
            value={company_email}
            label="Email"
            name="company_email"
            handler={handleInput}
          />

          <LocationSelectInput
            className={["bordaredInput", "text-start ps-4"]}
            label="Location"
            handler={handleInput}
            district_value={district_id}
            division_value={division_id}
          >
            <option value="" disabled>
              Select District
            </option>
            {districts.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </LocationSelectInput>

          <InputWithLabel
            className={["bordaredInput", "text-start ps-4"]}
            value={company_address}
            label="Address"
            name="company_address"
            handler={handleInput}
          />

          <InputWithLabel
            className={["bordaredInput", "text-start ps-4"]}
            value={website}
            label="Website"
            name="website"
            handler={handleInput}
          />

          {socialLinks.length > 0 &&
            socialLinks.map((item) => (
              <SocialLinkItemUpdate
                key={item.id}
                data={item}
                setSocialLinks={setSocialLinks}
                socialLinks={socialLinks}
              />
            ))}

          {show && (
            <Row style={{ fontSize: "14px" }}>
              <Col md="6" className="mb-1">
                <Form.Label className={""}>Social Media Name</Form.Label>
                <Form.Control
                  className={` InputWithLabelInput bordaredInput`}
                  type="text"
                  placeholder="Social Media Name"
                  name="social_media_name"
                  onChange={handleSocialInput}
                  required
                />
              </Col>
              <Col md="6" className="mb-1">
                <Form.Label className={""}>Social Media Link</Form.Label>
                <Form.Control
                  className={` InputWithLabelInput bordaredInput`}
                  type="text"
                  placeholder="Social Media Link"
                  name="social_media_link"
                  onChange={handleSocialInput}
                  required
                />
              </Col>

              <p className="text-end text-danger font-14 my-">
                {socialLinkText.socialError}
              </p>

              <SaveAndCancelBtnGroup
                cancelBtnHander={handleCancelBtn}
                cancelBtnName="socialAdd"
                saveBtnHandeler={uploadSocialLink}
              ></SaveAndCancelBtnGroup>
            </Row>
          )}

          <br />
          <button
            type="button"
            className="socialMediaPlusBtn"
            onClick={() => handleEditButton()}
          >
            <FontAwesomeIcon className="me-2" icon={faPlus}></FontAwesomeIcon>
            Add Social Media
          </button>

          <div className="font-14 mt-3">
            {error && (
              <p className="text-center text-danger">
                {error} Please try again
              </p>
            )}
            {success && <p className="text-end text-orange">{success}</p>}
          </div>
          <div className="d-flex justify-content-end mb-2">
            <div>
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="send-code-btn me-4"
              >
                Cancel
              </button>
              <input
                type="submit"
                value="Update Profile"
                className="send-code-btn cart-confirm-btn text-dark"
              />
            </div>
          </div>
        </Form>
      </div>
      <br /> <br />
      <CropPhotoModal
        imageFile={profile_picture}
        show={showCrop}
        handleClose={handleCropClose}
        type={"profile"}
        setCroppedImage={setProfile_croppedImage}
        setCroppedImageUrl={steProfile_picture_64}
      />
    </div>
  );
};

export default UpdateCompanyProfile;
