import React from 'react'
import { mainProPUrl } from 'src/Projects/Life/Common/CommonUrl'

export const PostUserPicture = ({profile_image,userName}) => {
  return (
    <div className="PostSectionProfile">
        {profile_image !== "" ? (
            <img className="img-fluid" src={`${mainProPUrl}/${profile_image}`} alt={userName}  />
          ) :
            (<img className="img-fluid" src="/user.png" alt={userName} />)
          }
          </div>
  )
}
