import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import ShippingAndBilling from "./ShippingAndBilling/ShippingAndBilling";
import { useRef } from "react";
import PaymentMethod from "./PaymentMethod/PaymentMethod";
import { useEffect } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Conformation from "./Conformation/Conformation";
import OrderSuccessModal from "../OrderSuccessModal/OrderSuccessModal";

function CustomToggle({ children, eventKey, disabled }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {});
  return (
    <fieldset
      style={{ cursor: disabled && "not-allowed" }}
      disabled={disabled}
      type="button"
      className="checkout-collapse-btn secondary-bg"
      onClick={decoratedOnClick}
    >
      {children}
    </fieldset>
  );
}

const CartCheckout = ({ totalPrice }) => {
  const shippingAndBillingBtnRef = useRef();
  const paymentMethodBtnRef = useRef();
  const conformationBtnRef = useRef();
  const [state, setState] = useState({
    pin: "",
    sent_method: "email",
    show_pin_input: false,
    shipping_address: "",
    shipping_billing_status: false,
    shipping_billing_error: "",
    payment_method_status: false,
    payment_method: "",
    payment_method_error: "",
    conformation_status: false,
    delivery_charge: 100,
  });

  const [showOrderSuccessModal, setShowOrderSuccessModal] = useState(false);
  const handleOrderSuccessModalClose = () => setShowOrderSuccessModal(false);

  const {
    pin,
    sent_method,
    shipping_address,
    show_pin_input,
    shipping_billing_error,
    shipping_billing_status,
    payment_method_status,
    payment_method,
    payment_method_error,
    conformation_status,
    delivery_charge,
  } = state;

  const handlePinChange = (pinCode) => {
    let pin = pinCode.trim(); // remove the white space
    setState((prevState) => ({ ...prevState, pin: pin }));
  };
  const handleSendMethodChange = (sentMethod) => {
    setState((prevState) => ({ ...prevState, sent_method: sentMethod }));
  };
  const handleSendCode = () => {
    setState((prevState) => ({ ...prevState, show_pin_input: true }));
  };

  const handleShippingAddressChange = (e) => {
    const address = e.target.value;
    setState((prevState) => ({ ...prevState, shipping_address: address }));
  };

  const handleAddressSubmit = () => {
    if (shipping_address === "") {
      setState((prevState) => ({
        ...prevState,
        shipping_billing_error: "please select Delivery address",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        shipping_billing_error: "",
        shipping_billing_status: true,
      }));
      shippingAndBillingBtnRef.current.children[0].click();
      paymentMethodBtnRef.current.children[0].click();
    }
  };

  const handlePaymentMethodChange = (method) => {
    setState((prevState) => ({ ...prevState, payment_method: method }));
  };

  const handlePaymentMethodSubmit = () => {
    if (payment_method === "") {
      setState((prevState) => ({
        ...prevState,
        payment_method_error: "please select payment method",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        payment_method_error: "",
        payment_method_status: true,
      }));
      paymentMethodBtnRef.current.children[0].click();
      conformationBtnRef.current.children[0].click();
    }
  };

  const handleConformationSubmit = () => {
    setState((prevState) => ({
      ...prevState,
      conformation_status: true,
    }));
    conformationBtnRef.current.children[0].click();
    setShowOrderSuccessModal(true);
  };

  const handleDeliveryChange = (e) => {
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      delivery_charge: value,
    }));
  };

  useEffect(() => {
    shippingAndBillingBtnRef.current.children[0].click();
  }, []);

  return (
    <div className="py-3 px-2 my-4 secondary-bg  overflow-hidden checkout-box">
      <Accordion
        className="shipping-address my-2 food-checkout-item"
        id="shipping-and-billing-accordion"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header ref={shippingAndBillingBtnRef}>
            <div
              className="checkout-collapse-btn"
              style={{ color: shipping_billing_status && "#f9a31a" }}
            >
              <span>Delivery Address</span>
              <FontAwesomeIcon icon={faCheckCircle} />
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ShippingAndBilling
              handleShippingAddressChange={handleShippingAddressChange}
              handleAddressSubmit={handleAddressSubmit}
              errorMessage={shipping_billing_error}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion
        className="shipping-address my-2 food-checkout-item"
        id="shipping-and-billing-accordion"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header ref={paymentMethodBtnRef}>
            <CustomToggle disabled={!shipping_billing_status}>
              <span style={{ color: payment_method_status && "#f9a31a" }}>
                Payment Method
              </span>
              <FontAwesomeIcon
                style={{ color: payment_method_status && "#f9a31a" }}
                icon={faCheckCircle}
              />
            </CustomToggle>
          </Accordion.Header>
          <Accordion.Body>
            <PaymentMethod
              handlePaymentMethodSubmit={handlePaymentMethodSubmit}
              totalPrice={totalPrice}
              handlePaymentMethodChange={handlePaymentMethodChange}
              errorMessage={payment_method_error}
              handleDeliveryChange={handleDeliveryChange}
              deliveryCharge={delivery_charge}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* for conformation  */}
      <Accordion
        className="shipping-address my-2 food-checkout-item"
        id="shipping-and-billing-accordion"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header ref={conformationBtnRef}>
            <CustomToggle disabled={!payment_method_status}>
              <span style={{ color: conformation_status && "#f9a31a" }}>
                Conformation
              </span>
              <FontAwesomeIcon
                style={{ color: conformation_status && "#f9a31a" }}
                icon={faCheckCircle}
              />
            </CustomToggle>
          </Accordion.Header>
          <Accordion.Body>
            <Conformation
              pin={pin}
              handlePinChange={handlePinChange}
              handleSendCode={handleSendCode}
              handleSendMethodChange={handleSendMethodChange}
              show_pin_input={show_pin_input}
              sent_method={sent_method}
              handleConformationSubmit={handleConformationSubmit}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br />
      <OrderSuccessModal show={showOrderSuccessModal} handleClose={handleOrderSuccessModalClose} />
    </div>
  );
};

export default CartCheckout;
