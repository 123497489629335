import React from "react";
import { useLocation } from "react-router-dom";

import useAuth from "src/hooks/useAuth";
import CoverAndProfileSmall from "../CoverAndProfileSection/CoverAndProfileSmall";

export const TimeLines = () => {
  const { pathname } = useLocation();
  const { authUser } = useAuth();
  const name = authUser?.first_name + " " + authUser?.last_name;

  const fndproFile = "/Life/Cover/Friends.jpg";
  const profeshonalProfile = "/Life/Cover/Professional.jpg";
  const familyProfile = "/Life/Cover/Family.jpg";


  return (
    <>
      {(!pathname.includes("/family-life") ||
        pathname.includes("/public-life")) && (
        <CoverAndProfileSmall
          pagetype={"family"}
          bgImage={familyProfile}
          name={name}
          type="Family"
          timeline_name={"family"}
        />
      )}
      {(!pathname.includes("/friends-life") ||
        pathname.includes("/public-life")) && (
        <CoverAndProfileSmall
          pagetype={"friends"}
          bgImage={fndproFile}
          name={name}
          type="Friends"
          timeline_name={"friends"}

        />
      )}
      {(!pathname.includes("/professional-life") ||
        pathname.includes("/public-life")) && (
        <CoverAndProfileSmall
          pagetype={"professional"}
          bgImage={profeshonalProfile}
          name={name}
          type="Profession"
          timeline_name={"professional"}
        />
      )}
      {!pathname.includes("/public-post") && (
        <CoverAndProfileSmall
          pagetype={"public-post"}
          bgImage={fndproFile}
          name={name}
          type="Public"
          timeline_name={"public"}
        />
      )}
    </>
  );
};
