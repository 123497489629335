import React from "react";
import Loader from "react-js-loader";
import { useLocation } from "react-router-dom";

export const BubbleScale = ({extraStyle}) => {
  // bgColor={"var(--text-success)"}
const location = useLocation()
  return (
    <div style={{ ...extraStyle }} className={"item"}>
      <Loader
        type="bubble-scale"
        bgColor={location.pathname.includes("/ubizz") ? "var(--text-success)" :"var(--text-primary)"}
        color={"var(--text-primary)"}
        size={100}
      />
    </div>
  );
};
