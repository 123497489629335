import { Link, useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";

import qrCode from "./../../assets/qr-code.png";
import pickupIcon from "./../../assets/pickup.svg";
import dropIcon from "./../../assets/drop.svg";
import avatarIcon from "./../../assets/avatar.svg";
import penPaper from "./../../assets/penpaper.svg";
import shareIcon from "./../../assets/share-icon.svg";
import downloadIcon from "./../../assets/cloud-icon.svg";
import printerIcon from "./../../assets/printer-icon.svg";
import printLabel from "./../../assets/label.jpg";

import "./infoReceipt.css";

function InfoReceipt() {
  const navigate = useNavigate();
  const { pathname:url } = useLocation();
  const dynamicPath = url.split("/")[1];

  return (
    <div>
      <h3 className="receipt__header">Preview</h3>
      <div className="receipt__content">
        <Row>
          <Row className="mb-4">
            <Col>
              <div className="receipt__qr">
                <img className="receipt__qr__img" src={qrCode} alt="" />
                <div className="receipt__qr__detail">
                  <h6>Same day Delivery</h6>
                  <p className="m-0">ED : 12-Dec-2022</p>
                  <p className="m-0">Bill: $20.21 (Due ) </p>
                </div>
              </div>
            </Col>

            {url.includes("confirm") && (
              <Col md={6} className="text-md-center">
                <div className="receipt__left__icons">
                  <img className="dark-invert" src={shareIcon} alt="" />
                  <a href={printLabel} download="express_label">
                    <img className="dark-invert" src={downloadIcon} alt="" />
                  </a>
                  <img className="dark-invert" src={printerIcon} alt="" />
                  <p className="m-0">123s1u5d$9v7s3m</p>
                </div>
                <br />
                <Button
                  as={Link}
                  to={`/${dynamicPath}`}
                  className="mt-2 mt-md-3 px-3 px-sm-5 btn__ride"
                >
                  Back To Home
                </Button>
              </Col>
            )}

            {url.includes("packageinfo") && (
              <Col md={6} className="text-md-center">
                <div className="mt-3">
                  <Button
                    as={Link}
                    to={`/${dynamicPath}`}
                    variant="outline-dark me-3 px-4"
                  >
                    Decline
                  </Button>
                  <Button
                    as={Link}
                    to={`/${dynamicPath}/delivery/gotoreceive`}
                    className="btn__ride px-4  "
                  >
                    Accept
                  </Button>
                </div>
              </Col>
            )}
          </Row>
          <Col md={6}>
            <div className="bg-white-to-grey rounded p-3">
              <h5 className="text-center mb-3">Basic Info</h5>
              <div>
                <h6>Pick up Location</h6>
                <small className="d-flex align-items-center gap-2">
                  <img
                    className="dark-invert receipt__text__icon"
                    src={pickupIcon}
                    alt=""
                  />
                  <p className="m-0">Bronx, New York -10472</p>
                </small>

                <h6 className="mt-4">Sender Name</h6>
                <small className="d-flex align-items-center gap-2">
                  <img
                    className="dark-invert receipt__text__icon"
                    src={avatarIcon}
                    alt=""
                  />
                  <p className="m-0">Kolton Marley</p>
                </small>

                <h6 className="mt-4">Sender Zip Code</h6>
                <small className="d-flex align-items-center gap-2">
                  <img className="dark-invert receipt__text__icon" src={penPaper} alt="" />
                  <p className="m-0">2345</p>
                </small>

                <h6 className="mt-4">Sender Email Address</h6>
                <small className="d-flex align-items-center gap-2">
                  <img className="dark-invert receipt__text__icon" src={penPaper} alt="" />
                  <p className="m-0">kolton@gmail.com</p>
                </small>

                <h6 className="mt-4">Sender Phone Number</h6>
                <small className="d-flex align-items-center gap-2">
                  <img className="dark-invert receipt__text__icon" src={penPaper} alt="" />
                  <p className="m-0">+1(343)534-4453</p>
                </small>

                <div className="py-4 py-md-5" />

                <h6>Dropoff Location</h6>
                <small className="d-flex align-items-center gap-2">
                  <img className="dark-invert receipt__text__icon" src={dropIcon} alt="" />
                  <p className="m-0">New York -1047</p>
                </small>

                <h6 className="mt-4">Receiver Name</h6>
                <small className="d-flex align-items-center gap-2">
                  <img
                    className="dark-invert receipt__text__icon"
                    src={avatarIcon}
                    alt=""
                  />
                  <p className="m-0">Marley Kolton</p>
                </small>

                <h6 className="mt-4">Receiver Zip Code</h6>
                <small className="d-flex align-items-center gap-2">
                  <img className="dark-invert receipt__text__icon" src={penPaper} alt="" />
                  <p className="m-0">2345</p>
                </small>

                <h6 className="mt-4">Receiver Email Address</h6>
                <small className="d-flex align-items-center gap-2">
                  <img className="dark-invert receipt__text__icon" src={penPaper} alt="" />
                  <p className="m-0">Marley@gmail.com</p>
                </small>

                <h6 className="mt-4">Receiver Phone Number</h6>
                <small className="d-flex align-items-center gap-2">
                  <img className=" dark-invert receipt__text__icon" src={penPaper} alt="" />
                  <p className="m-0">+1(343)534-4453</p>
                </small>
              </div>
            </div>
          </Col>

          <Col md={6}>
            <div className="bg-white-to-grey p-3 mt-4 mt-md-0 rounded">
              <h5 className="text-center mb-3">Product Info</h5>
              <div>
                <h6>Product Category</h6>
                <small>Electronic Products</small>
                <h6 className="mt-4">Product Name</h6>
                <small>Demo Product Name</small>

                <h6 className="mt-4">Product Details</h6>
                <small>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus
                  aliquam mattis non nisl, sem pretium ante. Ut nulla urna lorem
                  convallis ornare amet molestie. Maecenas interdum leo nibh
                  tempor imperdiet. Erat ut adipiscing pretium, sodales
                  ullamcorper. Quam mattis non aliquet eget{" "}
                </small>

                <h6 className="mt-4">Dimensions</h6>
                <small>162 x 75.5 x 8.9 mm (6.38 x 2.97 x 0.35 in)</small>
              </div>
            </div>
            {url.includes("confirm") && (
              <img className="img-fluid mt-3" src={printLabel} alt="" />
            )}
          </Col>
        </Row>

        {url.includes("preview") && (
          <div className="text-center mt-4 mt-sm-5">
            <Button
              onClick={() => navigate(-1)}
              variant="outline-dark"
              className="dark-invert me-3 px-4"
            >
              Edit
            </Button>
            <Button
              as={Link}
              to={`/${dynamicPath}/delivery/payment`}
              className="btn__ride px-4  "
            >
              Confirm
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
export default InfoReceipt;
