import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { jobSliderImgUrl } from "../../utilities/job_commonUrl";

const BannerItem = ({ data }) => {
  const imgUrl = data.photo
    ? jobSliderImgUrl + data.photo
    : "/job-image/job-banner2.png";
  return (
    <div className="job__slider-item">
      <LazyLoadImage
        src={imgUrl}
        className="img img-fluid h-100 w-100 [ object-cover object-right ]"
        alt=""
        effect="blur"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default BannerItem;
