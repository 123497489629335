import axios from "axios";

function axiosRequest({
  url,
  method = "POST",
  formData,
  afterSuccess,
  afterReject,
}) {
  axios({
    method,
    url,
    data: formData,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "Content-Type": "applications/json",
    },
  })
    .then((res) => {
      if (res.data.status === 1) {
        afterSuccess(res.data);
      } else {
        afterReject(res.data);
      }
    })
    .catch((err) => {
      afterReject(err);
    });
}
export default axiosRequest;
