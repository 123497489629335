import axios from "axios";
import {
  updateChannelBannerPhotoUrl,
  updateChannelProfilePhotoUrl,
} from "../../common/view_commonUrl";

export const UpdateProfilePicture = (updateProArg) => {
  const { formData, type, callAfterRejected, callAfterSuccess } = updateProArg;
  let url = updateChannelProfilePhotoUrl;
  if (type === "banner") {
    url = updateChannelBannerPhotoUrl;
  }

  axios
    .post(url, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "applications/json",
      },
    })
    .then((res) => {
      if (res.data.status === 1) {
        callAfterSuccess(type);
      }
    })
    .catch((err) => {
      callAfterRejected(type);
      console.log(err, "Error");
    });
};
