import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import ShoppingCartIcon from "../../assets/icons/ShoppingCartIcon";
import { Badge } from "react-bootstrap";
import Avatar from "src/Projects/Life/Common/Avatar";
import useAuth from "src/hooks/useAuth";
import useCartStore from "../../hooks/useCartStore";

export const NavRight = ({ profileHandleShow, path }) => {
  const { state } = useCartStore();
  const { authUser } = useAuth();
  const { cartItems } = state;

  return (
    <>
      {Number(authUser.id) ? (
        <Link replace to={`${path}/my-favorite`} className="m-auto">
          <FontAwesomeIcon size="lg" icon={faHeart}></FontAwesomeIcon>
        </Link>
      ) : (
        <Link replace to={"/login"} className="m-auto">
          <FontAwesomeIcon size="lg" icon={faHeart}></FontAwesomeIcon>
        </Link>
      )}
      <Link
        replace
        to={`${path}/my-cart`}
        className="position-relative  corporate__navbar__cart-icon m-auto"
      >
        {/* <FontAwesomeIcon size="lg" icon={faCartPlus}></FontAwesomeIcon> */}
        <ShoppingCartIcon size="22" />
        <Badge className="corporate__cart-badge">
          {cartItems.length > 0 ? cartItems.length : ""}
        </Badge>
      </Link>
      <Link
        replace
        to={`${path}/my-profile`}
        className="d-none d-md-inline-block"
      >
        <Avatar imgUrl={"/user.png"} size={25} />
      </Link>

      <span className="d-inline-block d-md-none" onClick={profileHandleShow}>
        <Avatar imgUrl={"/user.png"} size={25} />
      </span>
    </>
  );
};
