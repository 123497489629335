import React from "react";
import { Form, Row } from "react-bootstrap";
import FileAndTextInputBox from "src/Common/FileAndTextInputBox/FileAndTextInputBox";
import InputSelectAndFile from "src/Common/InputSelectAndFile/InputSelectAndFile";
import InputSelectWithLabel from "src/Common/InputSelectWithLabel/InputSelectWithLabel";
import InputPhoneNumber from "src/Common/InputWithLabel/InputPhoneNumber";
import TextInputBox from "src/Common/TextInputBox/TextInputBox";

const BusinessInfo = () => {
  return (
    <Form className="secondary-bg">
      <br />
      <Row xs={1} xl={2} >
        <TextInputBox
          label={"Business Reg. Name"}
          placeholder={"Business Registration Name"}
        />

        <FileAndTextInputBox
          label={"Business Reg. Number/tax ID"}
          placeholder="Business Reg. Number"
        ></FileAndTextInputBox>
        <FileAndTextInputBox
          label={"E-CAB Reg. Number"}
          placeholder="E-CAB Reg. Number"
        ></FileAndTextInputBox>

        <TextInputBox
          placeholder={"Mailing Address"}
          label={"Mailing Address"}
        ></TextInputBox>
        <TextInputBox
          placeholder={"Permanent Address"}
          label={"Permanent Address"}
        ></TextInputBox>
        <TextInputBox
          label={"Store Name"}
          placeholder={"Store Name"}
        ></TextInputBox>
        <TextInputBox
          label={"Web Address "}
          placeholder={"Web Address "}
        ></TextInputBox>

        <InputPhoneNumber
          className={["store-reg-phone-input", "store-reg-phone-label"]}
          label="Phone Number"
          checkoutInput
          small
        ></InputPhoneNumber>

        <TextInputBox
          label={"E-Mail Address"}
          placeholder={"E-Mail Address"}
        ></TextInputBox>

        <InputSelectWithLabel label={"Shipping Methods"}>
          <option value="">Select Shipping Methods </option>
          <option value="1">Shipping Methods 1</option>
          <option value="3">Shipping Methods 2</option>
          <option value="2">Shipping Methods 3</option>
        </InputSelectWithLabel>

        <InputSelectAndFile label={"Payment Get way "}>
          <option value="">Payment Get way </option>
          <option value="1">Payment Get way 1</option>
          <option value="3">Payment Get way 2</option>
          <option value="2">Payment Get way 3</option>
        </InputSelectAndFile>
        <FileAndTextInputBox
          label={"UVIOM ID / RQ Code"}
          placeholder="UVIOM ID / RQ Code"
          singleFile
          filePlaceholder="optional"
        ></FileAndTextInputBox>
      </Row>

      <br />

      <div className="d-sm-flex justify-content-center cancellation-check">
        <Form.Check
          type="checkbox"
          label={
            <small>
              I have read and agree to @ UVIOM Corporate Terms and conditions.
            </small>
          }
        />
        <button className="eco-btn"> View TC</button>
      </div>
      <br />
      <div className="text-center">
        <button type="submit" className=" send-code-btn cart-confirm-btn text-dark">
          Submit
        </button>
      </div>
      <br />
    </Form>
  );
};

export default BusinessInfo;
