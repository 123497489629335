import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputWithLabel from "src/Common/InputWithLabel/InputWithLabel";
import { useAddEducationMutation } from "src/app/services/jobApi/jobPostApi";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import { useEffect } from "react";

const defaultData = {
  institute: "",
  location: "",
  board: "",
  level: "",
  result: "",
  year: "",
  subject_group: "",
  exam_name: "",
};

const AddEducationForm = ({ btnRef, applicant_name }) => {
  const [formData, setFormData] = useState(defaultData);
  const [error, setError] = useState("");
  const [addEducation, { isLoading, error:server_error, isSuccess }] = useAddEducationMutation();

  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let prevState = formData;
    prevState[name] = value;
    setFormData({ ...prevState });
  };

  const {
    institute,
    result,
    location,
    board,
    year,
    level,
    exam_name,
    subject_group,
  } = formData;

  const handleFormSubmit = (e) => {
    e.stopPropagation();
    if (
      institute &&
      result &&
      location &&
      board &&
      year &&
      level &&
      exam_name &&
      subject_group
    ) {
      setError("");
      const data = { ...formData };
      data["applicant_name"] = applicant_name;
      addEducation(data);
    } else {
      setError("All Field is Required");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      btnRef.current.children[0].click();
      setFormData(defaultData);
      navigate(-1)
    }
    if (server_error) {
      setError(server_error.error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, server_error])

  const handleCancel = () => {
    btnRef.current.children[0].click();
  };

  return (
    <div>
      {isLoading && <BubbleLoader/>}
      <InputWithLabel
        label="Institution"
        name="institute"
        value={institute}
        handler={handleChange}
        checkoutInput
        placeholder="Institution Name"
        required={false}
      ></InputWithLabel>

      <InputWithLabel
        label="Location"
        checkoutInput
        name="location"
        value={location}
        handler={handleChange}
        placeholder="Location"
        required={false}
      ></InputWithLabel>
      <InputWithLabel
        label="Board"
        checkoutInput
        name="board"
        value={board}
        handler={handleChange}
        placeholder="Board "
        required={false}
      ></InputWithLabel>

      <InputWithLabel
        label="Level"
        checkoutInput
        name="level"
        value={level}
        handler={handleChange}
        placeholder="Level"
        required={false}
      ></InputWithLabel>
      <InputWithLabel
        label="Exam Name"
        checkoutInput
        name="exam_name"
        value={exam_name}
        handler={handleChange}
        placeholder="Exam Name"
        required={false}
      ></InputWithLabel>
      <InputWithLabel
        label="Subject Group"
        checkoutInput
        name="subject_group"
        value={subject_group}
        handler={handleChange}
        placeholder="Subject Group"
        required={false}
      ></InputWithLabel>

      <InputWithLabel
        label="Year"
        checkoutInput
        value={year}
        name="year"
        handler={handleChange}
        placeholder="Passing Year"
        type="number"
        required={false}
      ></InputWithLabel>
      <InputWithLabel
        label="Result"
        checkoutInput
        name="result"
        value={result}
        handler={handleChange}
        placeholder="Result"
        required={false}
      ></InputWithLabel>

      <p className="text-danger text-center font-14">{error}</p>

      <div className="text-center">
        <button
          onClick={handleCancel}
          type="button"
          className="send-code-btn me-2 py-1"
        >
          Cancel
        </button>
        <button
          onClick={handleFormSubmit}
          type="button"
          className=" send-code-btn cart-confirm-btn py-1"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddEducationForm;
