import React from "react";
import {
  productFeatureImageUrl,
  productImgBase,
} from "../../utilities/CommonUrl";

export const FeatureImage = (props) => {
  const { imgIndex, index, setImgIndex, item } = props;

  return (
    <>
      {String(index) === "undifined" ? (
        <>
          <div
            className={`image__indicator-img-box rounded-2 ${
              imgIndex === index && "image-viewed"
            }`}
            onClick={() => setImgIndex()}
          >
            <img
              src={`${productImgBase}/${item}`}
              alt="images"
              className="img img-fluid"
            />
          </div>
        </>
      ) : (
        <>
          <div
            className={`image__indicator-img-box rounded-2 ${
              imgIndex === index && "image-viewed"
            }`}
            onClick={() => setImgIndex(index)}
          >
            <img
              src={`${productFeatureImageUrl}/${item.image_name}`}
              alt="images"
              className="img img-fluid"
            />
          </div>
        </>
      )}
    </>
  );
};
