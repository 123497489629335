import React from "react";
import { Col } from "react-bootstrap";
import "./BestTimeSection.css";
import { Swiper, SwiperSlide } from "swiper/react";
import BestTimeItem from "./BestTimeItem";
import useBestTimeStore from "src/hooks/useBestTimeStore";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const BestTimeSection = () => {
  const { postList, setCurrentPage, hasMore } = useBestTimeStore();

  const handleSliderEnd = () => {
    if (hasMore) {
      setCurrentPage((prevState) => prevState + 1);
    }
  };

  return (
    <div id="bestime" className="BestTimeSection box-shadow my-2">
      <div className="d-flex flex-column gap-2 h-100 p-2 rounded me-2 ">
        <img
          src="https://res.cloudinary.com/yex/image/upload/v1664449687/UVIOM/camera.svg"
          alt="camera"
          title="camera"
        />
        <img
          src="https://res.cloudinary.com/yex/image/upload/v1664449639/UVIOM/upload.svg"
          alt="upload"
          title="upload"
        />
      </div>

      <Col className="BestTimePhotoParent">
        <Swiper
          slidesPerView={"auto"}
          centeredSlides={false}
          spaceBetween={15}
          modules={[Navigation]}
          pagination={{
            clickable: true,
          }}
          onReachEnd={handleSliderEnd}
          navigation={true}
          className="category-top-slider bestTime-slider best_time_Slider"
        >
          {postList.map((post, index) => (
            <SwiperSlide key={post.id}>
              <BestTimeItem data={post} index={index} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Col>
    </div>
  );
};

export default BestTimeSection;
