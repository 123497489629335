/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCloudUploadAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { Modal, Row } from "react-bootstrap";
import _ from "lodash";
import {
  AWS_BUCKET_NAME,
  AWS_REGION_NAME,
  AWS_video_path,
  AWS_video_thumbnail_path,
  storeVideoPostUrl,
  storePostUrl,
} from "src/Projects/Life/Common/CommonUrl";
import getVideoThumbnail, {
  getVideoDuration,
} from "src/utilities/getVideoThumbnail";
import {
  delete_upload_logs,
  // EMOJI_REGEX,
  // emoji_text_to_hex,
  get_AWS_date_path,
  get_AWS_file_name,
  get_AWS_file_upload_params,
  save_upload_logs,
} from "src/utilities/utilities";
import AWS from "aws-sdk";
import { createFFmpeg } from "@ffmpeg/ffmpeg";
import { compress_video, process_progress } from "src/utilities/compress_video";
import compress_image from "src/utilities/compress_image";
import EmojiPicker from "emoji-picker-react";
import useTheme from "src/hooks/useTheme";
import { faSmile } from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";

export const Posting = ({ postingArg, type, canceled }) => {
  const {
    callAfterSuccess,
    callAfterRejected,
    errorMessage,
    setErrorMessage,
    myFormData,
    setFormData,
    setIsLoading,
    setvideoFiles: setVideoFiles,
    setImages,
    images,
    videoFiles,
    selectedFiles,
    setSelectedFiles,
    // handleClose,
  } = postingArg;

  const {
    family_post_status,
    friend_post_status,
    professional_post_status,
    post_details,
    public_post_status,
  } = myFormData || {};

  const uviom_life_bucket = new AWS.S3({
    params: { Bucket: AWS_BUCKET_NAME },
    region: AWS_REGION_NAME,
  });

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { currentTheme } = useTheme();
  // eslint-disable-next-line no-unused-vars
  let request;

  // useEffect(() => {
  //   if (canceled) {
  //     request.abort();
  //     handleClose();
  //   }
  // }, [canceled]);

  // handle file change
  const handelChange = (e) => {
    if (e.target?.type === "checkbox") {
      const name = e.target.name;
      const value = e.target.checked;
      const newData = { ...myFormData };
      if (name === "public_post_status" && value) {
        newData.friend_post_status = true;
        newData.family_post_status = true;
        newData.professional_post_status = true;
      }
      newData[name] = value;
      setFormData({ ...newData });
      setErrorMessage("");
    } else {
      const name = e.target.name;
      const value = e.target.value;
      const newData = { ...myFormData };
      newData[name] = value;
      setFormData(newData);
    }
  };

  const handleEmojiClick = (text) => {
    const newData = { ...myFormData };
    const prev_text = newData["post_details"];
    newData["post_details"] = prev_text ? prev_text + text.emoji : text.emoji;
    setFormData(newData);
  };

  const upload_post = (formData, url, key = undefined) => {
    setIsLoading("Posting...");
    axios
      .post(`${url}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        callAfterSuccess(res);
        if (key) {
          delete_upload_logs(key);
        }
      })
      .catch((err) => {
        callAfterRejected(err);
      });
  };

  const video_upload_to_aws = async (formData) => {
    setIsLoading("Creating...");
    const ffmpeg = createFFmpeg({
      corePath: `${window.location.origin}/ffmpeg/ffmpeg-core.js`,
    });

    let video_file = selectedFiles[0];
    let thumb_file = {};
    let duration = await getVideoDuration(video_file);

    if (typeof duration !== "number") duration = 10;

    try {
      var thumb_file_blob = await getVideoThumbnail(video_file, 1.0);
      thumb_file_blob.lastModified = new Date().getTime();
      thumb_file_blob.name = `${video_file.name.split(".")[0]}.jpeg`;
      thumb_file = thumb_file_blob;
    } catch (err) {
      console.log(err?.message);
    }

    ffmpeg.setLogger((_logger) => {
      const result = process_progress(_logger.message, parseInt(duration));
      if (result !== duration) {
        setIsLoading(`Processing... ${result}%`);
      }
    });

    // compressing video file
    const compress_file = await compress_video(video_file, ffmpeg);

    const video_file_name =
      get_AWS_date_path("video") + `${get_AWS_file_name()}video.mp4`;
    const thumb_file_name =
      get_AWS_date_path("thumbnail") + `${get_AWS_file_name()}thumb.jpeg`;

    const aws_video_file_name = AWS_video_path + video_file_name;
    const aws_video_thumbnail_file_name =
      AWS_video_thumbnail_path + thumb_file_name;

    formData.append("content_name", video_file_name);
    formData.append("thumbnail", thumb_file_name);

    const aws_video_params = get_AWS_file_upload_params(
      compress_file,
      aws_video_file_name
    );
    const aws_thumb_params = get_AWS_file_upload_params(
      thumb_file,
      aws_video_thumbnail_file_name
    );

    setIsLoading("Uploading...");
    save_upload_logs(aws_thumb_params, aws_video_params, video_file_name);

    request = uviom_life_bucket
      .putObject(aws_thumb_params)
      .on("httpUploadProgress", (evt) => {
        const progress = Math.round((evt.loaded / evt.total) * 100);
        setIsLoading(`Uploading.. ${progress}%`);
      })
      .send((err) => {
        console.log(err);
      });

    request = uviom_life_bucket
      .putObject(aws_video_params)
      .on("httpUploadProgress", (evt) => {
        const progress = Math.round((evt.loaded / evt.total) * 100);
        setIsLoading(`Uploading... ${progress}%`);
      })
      .send((err) => {
        if (err) {
          setErrorMessage(err?.message);
          setIsLoading(false);
        } else {
          setIsLoading("Posting...");
          upload_post(formData, storeVideoPostUrl, video_file_name);
        }
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (family_post_status || friend_post_status || professional_post_status) {
      let formData = new FormData();
      let post_caption = post_details;

      // if (post_details && EMOJI_REGEX.test(post_details)) {
      //   post_caption = emoji_text_to_hex(post_details);
      // }

      formData.append("post_details", post_caption);
      formData.append(
        "family_post_status",
        family_post_status ? "active" : null
      );
      formData.append(
        "friend_post_status",
        friend_post_status ? "active" : null
      );
      formData.append(
        "professional_post_status",
        professional_post_status ? "active" : null
      );
      formData.append(
        "public_post_status",
        public_post_status ? "active" : null
      );

      if (type === "video") {
        if (selectedFiles.length > 0) {
          setErrorMessage("");
          const file = selectedFiles[0];
          if (file.size < 104857600) {
            setErrorMessage("");
            video_upload_to_aws(formData);
          } else {
            setErrorMessage(
              "Video size is too large ! Please select video less than 100MB"
            );
          }
        } else {
          setErrorMessage("No Video Selected ! Please Select a video");
        }
      } else {
        setIsLoading("Processing...");
        // compressing all image file
        const compressed_images = await compress_image(selectedFiles);

        _.forEach(compressed_images, (file, index) => {
          formData.append(`social_media_image[${index}]`, file);
        });
        upload_post(formData, storePostUrl);
      }
    } else {
      setErrorMessage("Required to select one of your timeline");
    }

    //-----------------
  };

  const fileHandleChange = async (e) => {
    let selectedFIles = [];
    let selectedVideoFIles = [];

    const targetFiles = e.target.files;

    //code for image preview-------------------------- // ---------------------------------------------------
    const targetFilesObject = [...targetFiles]; //converting (array) from object - [{..},{...},...]
    if (targetFiles) {
      if (type === "video") {
        let files = [];
        targetFilesObject.forEach((file) => {
          if (file.type.match("video.*")) {
            files.push(file);
            selectedVideoFIles.push(URL.createObjectURL(file));
            setErrorMessage("");
          } else {
            setErrorMessage("Please select only video file !");
            files = [];
            selectedVideoFIles = [];
            return;
          }
        });

        setSelectedFiles(files);
        setVideoFiles(selectedVideoFIles);
      } else {
        let files = [];
        targetFilesObject.forEach((file) => {
          if (file.type.match("image.*")) {
            files.push(file);
            selectedFIles.push(URL.createObjectURL(file));
            setErrorMessage("");
          } else {
            setErrorMessage("Please select only Image file !");
            files = [];
            selectedFIles = [];
            return;
          }
        });
        setSelectedFiles(files);
        setImages(selectedFIles);
      }
    }
  };

  const formateFile = (imageId) => {
    setImages((images) => images.filter((_, index) => imageId !== index));
    setSelectedFiles((prev) => prev.filter((_, index) => imageId !== index));
  };

  return (
    <>
      <Modal.Body>
        <div className="">
          {errorMessage && (
            <div
              className="text-danger text-center font-14 py-1 mb-1"
              style={{ fontWeight: "500" }}
            >
              {errorMessage}
            </div>
          )}
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="inlineCheckbox1"
              value="family"
              name="family_post_status"
              onChange={handelChange}
              checked={myFormData?.family_post_status || false}
              disabled={myFormData.public_post_status}
            />
            <label className="form-check-label" htmlFor="inlineCheckbox1">
              Family
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="inlineCheckbox2"
              value="friend"
              name="friend_post_status"
              onChange={handelChange}
              checked={myFormData?.friend_post_status || false}
              disabled={myFormData.public_post_status}
            />
            <label className="form-check-label" htmlFor="inlineCheckbox2">
              Friend
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="inlineCheckbox3"
              value="professional"
              name="professional_post_status"
              onChange={handelChange}
              checked={myFormData?.professional_post_status || false}
              disabled={myFormData.public_post_status}
            />
            <label className="form-check-label" htmlFor="inlineCheckbox3">
              Professional
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="inlineCheckbox4"
              value="professional"
              name="public_post_status"
              onChange={handelChange}
              checked={myFormData?.public_post_status || false}
            />
            <label className="form-check-label" htmlFor="inlineCheckbox4">
              Public
            </label>
          </div>
        </div>
        <div className="position-relative emoji__picker_wrapper">
          <textarea
            name="post_details"
            onChange={handelChange}
            rows="4"
            cols="50"
            placeholder="Create New Mind ...!"
            className="mt-3 primary-border"
            value={myFormData?.post_details}
            onFocus={() => setShowEmojiPicker(false)}
          ></textarea>
          <span
            className="emoji__picker-btn"
            onClick={() => setShowEmojiPicker((prev) => !prev)}
          >
            <FontAwesomeIcon icon={faSmile} size="lg" />
          </span>

          {showEmojiPicker && (
            <EmojiPicker
              onEmojiClick={handleEmojiClick}
              skinTonesDisabled
              searchDisabled
              theme={currentTheme}
              height="350px"
              previewConfig={{ showPreview: false }}
            />
          )}
        </div>

        {images.length !== 0 || videoFiles.length !== 0 ? (
          // d-flex flex-wrap mt-4  fileContainerMain
          <div className=" container-fluid  py-2">
            <div className="row  place-content-center ">
              {images
                ? images.map((url, index) => {
                    return (
                      <div className="col-12  px-0 col-lg-6 " key={url}>
                        <div className="bg-footer rounded-3 position-relative p-2 m-2  popupFiles">
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            size="lg"
                            className="customCross bg-dark rounded-circle text-white"
                            onClick={() => formateFile(index, url)}
                          />
                          <img
                            src={url}
                            className="img-fluid popupFiles rounded-3"
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })
                : null}
              {videoFiles
                ? videoFiles.map((url) => {
                    return (
                      <div className="col-sm-6" key={url}>
                        <video width="100%" controls className="popupFiles">
                          <source
                            src={url}
                            // type={url.type}
                          />
                        </video>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        ) : null}

        <div className="container-fluid">
          <Row>
            <div className="custom-file type-button">
              <input
                type="file"
                name="file"
                className="custom-file-input"
                id="customFile"
                onChange={fileHandleChange}
                multiple={type === "video" ? false : true}
                accept={
                  type === "video" ? "video/mp4,video/x-m4v,video/*" : "image/*"
                }
                onClick={(e) => (e.target.value = null)}
              />
              <label className="custom-file-label " htmlFor="customFile">
                <FontAwesomeIcon
                  icon={faCloudUploadAlt}
                  className="me-2 text-white"
                />{" "}
                Add Your {type === "video" ? "Video" : "Photos"}
              </label>
            </div>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="submit"
          className="btn post-submit-btn w-100 fw-bold btn-sm"
          onClick={handleSubmit}
        >
          Post
        </button>
      </Modal.Footer>
    </>
  );
};
