import { Form } from "react-bootstrap";

function AddAccount() {
  return (
    <Form>
      <Form.Select className="mt-3 ">
        <option value="">Account Type</option>
        <option value="">Saving</option>
        <option value="">Current</option>
      </Form.Select>
      <Form.Select className="mt-3">
        <option value="">Account Name</option>
        <option value="">IBBL</option>
        <option value="">DBL</option>
      </Form.Select>

      <Form.Control className="mt-3 input-reset" placeholder="Account Holder Name" />
      <Form.Control className="mt-3 input-reset" placeholder="Account Number" />
      <Form.Control className="mt-3 input-reset" placeholder="Routing Number" />

      <Form.Control
        className="mt-4 orange-bg hover-transparent-bg"
        type="submit"
        value="Add Account"
      />
    </Form>
  );
}
export default AddAccount;
