import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faEnvelope, faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import { Url } from "../../../Common/CommonUrl";
import ToastFunc from "src/Common/ToastFunc";

const SignUpPart = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [signInData, setSignInData] = useState({});
  const [sendOtp, setSentOtp] = useState(false);
  const [finalSignInData, setFinalSignInData] = useState();
  const [clickSignup, setClickSingup] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpLength, setOtplength] = useState(false);
  const [firstNameErrorMessage, setfirstNameErrorMessage] = useState("");
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");
  const [EmailerrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage8, setPasswordErrorMessage8] = useState("");
  const [passwordErrorMessageinvalid, setpasswordErrorMessageinvalid] =
    useState("");
  const [errorMessage, setErrorMessage] = useState("");

  let passwordFildType;
  if (showPassword) {
    passwordFildType = "text";
  } else {
    passwordFildType = "password";
  }
  // console.log(getAcessToken());
  const handelBlure = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...signInData };
    newData[name] = value;
    setSignInData(newData);
  };
  const handelChange = (e) => {
    if (e.target.value.length === 6) {
      setOtplength(true);
    } else {
      setOtplength(false);
      setErrorMessage("");
    }
    setOtp(e.target.value);
  };

  const handelSubmit = (e) => {
    e.preventDefault();

    if (!signInData.first_name || signInData.first_name.length < 1) {
      setfirstNameErrorMessage("The first name must be at least 1 characters.");
    } else {
      setfirstNameErrorMessage("");
    }
    if (!signInData.last_name || signInData.last_name.length < 1) {
      setLastNameErrorMessage("The last name must be at least 1 characters.");
    } else {
      setLastNameErrorMessage("");
    }

    if (
      !signInData.email ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(signInData.email)
    ) {
      setEmailErrorMessage("Enter a valid Email.");
    } else {
      setEmailErrorMessage("");
    }

    if (!signInData.password || signInData.password.length < 6) {
      setPasswordErrorMessage8("Password must be 6 charecter");
    } else {
      setPasswordErrorMessage8("");
    }

    if (signInData.password !== signInData.repassword) {
      setpasswordErrorMessageinvalid("Password Doesn't Match");
    } else {
      setpasswordErrorMessageinvalid("");
    }

    // if any error the   function not move on
    if (!signInData.first_name || signInData.first_name.length < 1) {
      return;
    }
    if (!signInData.last_name || signInData.last_name.length < 1) {
      return;
    }
    if (!signInData.email || signInData.email.length < 3) {
      return;
    }
    if (!signInData.email || signInData.email.length < 3) {
      return;
    }

    if (!signInData.password || signInData.password.length < 6) {
      return;
    }

    if (signInData.password !== signInData.repassword) {
      return;
    }

    setClickSingup(true);

    axios
      .post(`${Url}/customer-registration-verify`, { email: signInData.email })
      .then((res) => {
        // console.log(res.data.status)
        if (res.data.status === 1) {
          setSentOtp(true);
          setErrorMessage("");

          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: res.data.message,
          }).then(delete signInData.repassword, setFinalSignInData(signInData));
        } else {
          setClickSingup(false);

          ToastFunc("error", res.data.message);
        }
      });
  };

  const handelSubmitOtp = (e) => {
    e.preventDefault();
    // add verify code
    setErrorMessage("");
    // delete signInData.repassword;
    // setFinalSignInData(signInData);
    const singUpDataWithVerifyCode = { ...finalSignInData, verify_code: otp };
    // console.log(singUpDataWithVerifyCode);

    axios
      .post(`${Url}/register_customer`, singUpDataWithVerifyCode)
      .then((res) => {
        e.target.reset();
        if (res.data.status) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: res.data.message,
          }).then(e.target.reset(), navigate("/login"));
        } else {
          setErrorMessage(res.data.message);

          ToastFunc("error", res.data.message);

          navigate("/signup");
        }
      });
  };

  return (
    <div className="mt-4 mx-auto mw-[40rem]">
      {sendOtp ? (
        <div className="verifyDivParent">
          <Row>
            <Col sm={12}>
              <div className="w-25 mx-auto">
                <img
                  className="img-fluid"
                  src="https://uviom.com/all_images/otp.png"
                  alt="signup"
                />
              </div>
              <p> Please Check Your Email</p>
            </Col>
            <Col className="" sm={12}>
              <div className="otpBox">
                <div className="w-100">
                  <h2>Enter OTP</h2>

                  <form onSubmit={handelSubmitOtp}>
                    <div className=" my-2 ">
                      <div className="InputFildDiv ">
                        <input
                          onChange={handelChange}
                          name="OTP"
                          type="number"
                          minLength="5"
                          maxLength="10"
                        />
                      </div>

                      <div className="errorMessaage ">
                        <small>{errorMessage}</small>
                      </div>

                      {!(otp.length === 0 || otp.length === 6) && (
                        <small className="text-danger">Enter Valid OTP</small>
                      )}
                      {otpLength ? (
                        <button
                          type="submit"
                          className="button mx-auto mt-4 d-block"
                        >
                          Confirm
                        </button>
                      ) : (
                        <button
                          disabled
                          type="submit"
                          className="button mx-auto mt-4 d-block disabled"
                        >
                          Confirm
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <form onSubmit={handelSubmit}>
          <Col className="pading0Md">
            <div className="InputFildDiv">
              <label>
                <FontAwesomeIcon className="inputIcon" icon={faUser} />
              </label>
              <input
                onChange={handelBlure}
                name="first_name"
                type="text"
                placeholder=" Enter First Name*"
              />
            </div>
            <div className="errorMessaage ">
              <small>{firstNameErrorMessage}</small>
            </div>
          </Col>
          <Col className="pading0Md">
            <div className="InputFildDiv mt-3">
              <label>
                <FontAwesomeIcon className="inputIcon" icon={faUser} />
              </label>
              <input
                onChange={handelBlure}
                name="last_name"
                type="text"
                placeholder=" Enter Last Name*"
              />
            </div>
            <div className="errorMessaage ">
              <small>{lastNameErrorMessage}</small>
            </div>
          </Col>
          <Col className="pading0Md">
            <div className="InputFildDiv my-3 ">
              <label>
                <FontAwesomeIcon className="inputIcon" icon={faEnvelope} />
              </label>
              <input
                onChange={handelBlure}
                name="email"
                type="email"
                placeholder=" Enter Valid Email*"
              />
            </div>
            <div className="errorMessaage ">
              <small>{EmailerrorMessage}</small>
            </div>
          </Col>
          <Col className=" pading0Md">
            {/* password */}
            <div className="InputFildDiv my-3">
              <label>
                <FontAwesomeIcon className="inputIcon" icon={faKey} />
              </label>
              <input
                onChange={handelBlure}
                name="password"
                type={passwordFildType}
                placeholder="Enter Password*"
              />
              <label>
                <span
                  tabIndex="0"
                  aria-label="Show Password"
                  onClick={() => setShowPassword(!showPassword)}
                  onKeyDown={e =>{
                    if (e.key ==="Enter"){
                      setShowPassword(!showPassword)
                    }
                  }}
                  as={Button}
                  className="ShowPassword"
                >
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </span>
              </label>
            </div>
            <div className="errorMessaage ">
              <small>{passwordErrorMessage8}</small>
            </div>
          </Col>
          {/* re type password */}
          <Col className=" pading0Md">
            <div className="InputFildDiv my-3">
              <label>
                <FontAwesomeIcon className="inputIcon" icon={faKey} />
              </label>
              <input
                onChange={handelBlure}
                name="repassword"
                type={passwordFildType}
                placeholder="Re-Type Password*"
              />
              <label>
                <span
                tabIndex="0"
                aria-label="Show Password"
                  onClick={() => setShowPassword(!showPassword)}
                  onKeyDown={e =>{
                    if (e.key ==="Enter"){
                      setShowPassword(!showPassword)
                    }
                  }}
                  as={Button}
                  className="ShowPassword"
                >
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </span>
              </label>
            </div>
            {/* error show */}
            <div className="errorMessaage ">
              <small>{passwordErrorMessageinvalid}</small>
            </div>
          </Col>
          <Col className=" pading0Md">
            <div className="errorMessaage ">
              <small>{errorMessage}</small>
            </div>
            <div className="w-75 mx-auto mt-4 text-center px-2">
              <Form.Group className="mb-1 mt-3" id="formGridCheckbox">
                <Form.Check
                  type="checkbox"
                  className="d-inline-block"
                  required
                />
                <small className="ms-2">
                  Agree Accept Our Term's and Condition
                  <span className="DontHaveAcc ms-1">
                    <Link to="/privecy">Read Policy</Link>
                  </span>
                </small>
              </Form.Group>
            </div>
          </Col>

          {/* check box  */}

          <div className="d-flex my-2 justify-content-center">
            {clickSignup ? (
              <button disabled type="submit" className="button disabled">
                Sign Up
                <Spinner
                  className="ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </button>
            ) : (
              <button type="submit" className="button ">
                Sign Up
              </button>
            )}
          </div>
          <p className="DontHaveAcc mb-3">
            Already have account? <Link to="/login">Log In</Link>
          </p>
        </form>
      )}
    </div>
  );
};

export default SignUpPart;
