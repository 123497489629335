import { Container, Row, Col } from "react-bootstrap";

import overlay from "./../assets/overlay.png";
import coverageMap from "./../assets/coverage-map.jpg";
import bangladeshMap from "./../assets/bangladesh-map.jpg";
import ConvergeArea from "../components/CoverageArea";

function Coverage() {
  return (
    <div id="wrapper">
      <Container>
        <Row className="gx-sm-5">
          <h2 className="heading-1 text-center mt-3 mt-md-5 mb-5">
            We Are Available:
          </h2>
          <Col sm={6}>
            <img
              className="img-fluid mb-3 mb-md-5 mt-2"
              src={bangladeshMap}
              alt=""
            />
          </Col>
          <Col sm={6}>
            <h2 className="heading-1 fw-400">Bangladesh</h2>
            <ConvergeArea
              countryList={[
                "Dhaka",
                "Chottogram",
                "Sylhet",
                "Barishal",
                "Khulna",
                "Rajshahi",
                "Rangpur",
                "Mymensingh",
              ]}
            />
          </Col>
        </Row>
        <div className="py-3" />
        <Row className=" gx-sm-5 flex-lg-row-reverse">
          <Col sm={6}>
            <img
              className="img-fluid mb-3 mb-md-5 mt-2 mt-md-4"
              src={coverageMap}
              alt=""
            />
          </Col>
          <Col sm={6}>
            <h2 className="heading-1 mt-3 mt-md-5 fw-400">Globally</h2>
            <ConvergeArea
              countryList={[
                "Hungary",
                "Belarus",
                "Austria",
                "Serbia",
                "Switzerland",
                "Germany",
                "Bulgaria",
                "France",
                "Italy",
                "Denmark",
                "Finland",
                "Norway",
                "Spain",
              ]}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Coverage;
