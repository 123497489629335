import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavSearchBox from "../Common/NavSearchBox";
import "./navbar.css";
import ProfileSideBar from "./NavSideBar/ProfileSideBar/ProfileSideBar";

import { Categories } from "./Categories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { OtherLinks } from "./OtherLinks";
import { NavRight } from "./NavRight";
import { NavbarBrand } from "./NavbarBrand";

const NavBar = () => {
  const { pathname } = useLocation();
  const path = "/" + pathname.split("/")[1];

  const [showSearchBox, setShowSearchBox] = useState(false);
  const toggleSearchBox = () => setShowSearchBox((prevState) => !prevState);
  // side bar offcanvas state and function start
  const [profileShow, setProfileShow] = useState(false);

  const profileHandleClose = () => setProfileShow(false);
  const profileHandleShow = () => setProfileShow(true);
  // side bar offcanvas state and function end

  const [scrollNavClr, setScrollNavClr] = useState(false);

  useEffect(() => {
    setShowSearchBox(false);
  }, []);

  // this function for onScroll down 100px navbar background color opacity change to 0.90%
  useEffect(() => {
    const changeNavbarColor = () => {
      if (window.scrollY >= 100) {
        setScrollNavClr(true);
      } else {
        setScrollNavClr(false);
      }
    };
    window.addEventListener("scroll", changeNavbarColor);

    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);

  return (
    <div
      className={`px-3 corporate__navbar sticky-top ${
        scrollNavClr ? "corporate__nav-scroll-color" : ""
      }`}
    >
      <div className="container-xxl p-0">
        <nav className=" d-flex align-items-center  justify-content-between py-1">
          <NavbarBrand />
          <NavSearchBox className="d-none d-md-flex" />

          <div className="d-flex corporate__navbar-icons">
            <button
              onClick={toggleSearchBox}
              className="d-md-none  corporate__navbar__search-btn"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
            <NavRight profileHandleShow={profileHandleShow} path={path} />
          </div>
        </nav>
        {showSearchBox && <NavSearchBox className="d-md-none" />}

        <div
          className={`corporate__navbar corporate__mega-menu px-0 px-md-3 d-flex no-scrollbar ${
            scrollNavClr ? "corporate__nav-scroll-color" : ""
          }`}
        >
          <Categories />
          <OtherLinks url={path} />
        </div>
      </div>

      <ProfileSideBar show={profileShow} handleClose={profileHandleClose} />
    </div>
  );
};

export default NavBar;
