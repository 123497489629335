import "./coverageArea.css";

function ConvergeArea({ countryList }) {
  return (
    <ul className="convergeArea">
      {countryList?.map((country, index) => (
        <li key={index} className="convergeArea__city">
          {country}
        </li>
      ))}
    </ul>
  );
}
export default ConvergeArea;
