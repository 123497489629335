import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import { faStar as faSolidStar } from "@fortawesome/free-solid-svg-icons";
import JobApplyModal from "./../Modal/JobApplyModal";
import AgreementModal from "../Modal/AgreementModal";

import "./jobPost.css";

function JobPost() {
  const [showJobApplyModal, setShowJobApplyModal] = useState(false);
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const { pathname } = useLocation();

  return (
    <>
      <JobApplyModal
        show={showJobApplyModal}
        onSuccess={() => setShowAgreementModal(true)}
        onHide={() => setShowJobApplyModal(false)}
      />

      <AgreementModal
        show={showAgreementModal}
        onHide={() => setShowAgreementModal(false)}
      />
      <div className="jobPost">
        <div className="jobPost__top">
          <div>
            <h4 className="jobPost__title">
              Vietnamese Chinese English Translation
            </h4>
            <p className="jobPost__sub">Fixed Price or Hourly -$10</p>
            {/* Don't Show this section if user in watching own post from profile section */}
            {!pathname.includes("client") && (
              <div className="jobPost__badge__line">
                <div title="Verified By UVIOM">
                  <FontAwesomeIcon icon={faCheckSquare} /> <span>Verified</span>
                </div>
                <div>
                  <FontAwesomeIcon icon={faSolidStar} />
                  <span>5.00 (12)</span>
                </div>
              </div>
            )}

            <p className="jobPost__details">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages
            </p>
          </div>
          {/* Don't Show this section if user in watching own post from profile section */}
          {!pathname.includes("client") && (
            <div className="jobPost__buttons">
              <p className="mb-1">Sent Before July 6.2022</p>
              <div className="d-flex gap-2">
                <Button variant="outline-secondary">
                  <FontAwesomeIcon icon={faStar} />
                </Button>
                <Button
                  onClick={() => setShowJobApplyModal(true)}
                  className="btn__creative"
                >
                  Quick Connect
                </Button>
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="d-flex flex-wrap gap-3">
            <span className="fw-500 me-2">Skills:</span>
            <span className="creative__skills__badge">UX Research</span>
            <span className="creative__skills__badge">
              making problem statement
            </span>
            <span className="creative__skills__badge">making solution</span>
          </div>
        </div>
        {/* Only Show those button if user is watching from own profile */}
        {pathname.includes("client") && (
          <div className="d-flex gap-2 gap-sm-3 gap-md-4 justify-content-md-end mt-3">
            <Button variant="outline-secondary px-3 px-sm-4">Edit</Button>
            <Button className="btn__creative px-3 px-sm-4">Delete</Button>
          </div>
        )}
      </div>
    </>
  );
}
export default JobPost;
