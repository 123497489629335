/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGetSearchResultMutation } from "src/app/services/lifeApi/lifeCommonApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import PersonCard from "./GlobalSearchResult/PersonCard";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { destroySearchResult } from "src/features/life/lifeCommonDataSlice";


// import Post from "./GlobalSearchResult/Post"
// import Page from './GlobalSearchResult/Page';
// import Group from './GlobalSearchResult/Group';
// import Person from "./GlobalSearchResult/Person";

const GlobalSearch = () => {
  const location = useLocation();
    const searchText = location.search.split("=")[1];
    const dispatch = useDispatch();
  const { search_result: result = [] } = useSelector((state) => state.lifeData);


  const [getSearchResult, { isLoading }] = useGetSearchResultMutation();

  useEffect(() => {
    if (searchText) {
      getSearchResult(searchText);
      }
      
      return () => dispatch(destroySearchResult());
  }, [searchText]);



  return (
    <div className="bg-gray p-2 py-4">
      {result &&
        !isLoading &&
        result.map((item) => <PersonCard data={item} key={item.id} />)}
      {result.length === 0 && !isLoading && (
        <h6 className="text-center">No result found </h6>
      )}
      {isLoading && (
        <h2 className="text-center my-4">
          <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon>
        </h2>
      )}

      {/* <Post/>
            <br />
            <Page/>
            <br />
            <Group/> 
      */}
    </div>
  );
};

export default GlobalSearch;
