import React, { useEffect, useState } from "react";
import useAuth from "src/hooks/useAuth";
import useStore from "src/hooks/useStore";
import CoverAndProfileSmall from "src/Projects/Life/LifePages/MainPage/Components/CoverAndProfileSection/CoverAndProfileSmall";
import { isEmpty } from "lodash";

export const TimeLines = ({ leftColArg }) => {
  const { friendshipInfo, overviewInfo } = leftColArg;
  const { authUser } = useAuth();
  const { faUserPictures, fnUserPictures, proUserPictures } = useStore();

  const fndproFile = "/Life/Cover/Friends.jpg";
  const profeshonalProfile = "/Life/Cover/Professional.jpg";
  const familyProfile = "/Life/Cover/Family.jpg";
  const [faPictures, setfaPictures] = useState({
    profile: "",
    cover: "",
  });
  const [fnPictures, setfnPictures] = useState({
    profile: "",
    cover: "",
  });
  const [proPictures, setproPictures] = useState({
    profile: "",
    cover: "",
  });
  useEffect(() => {
    setfaPictures(faUserPictures);
  }, [faUserPictures]);
  useEffect(() => {
    setfnPictures(fnUserPictures);
  }, [fnUserPictures]);
  useEffect(() => {
    setproPictures(proUserPictures);
  }, [proUserPictures]);
  // console.log("isEmpty check",isEmpty(friendshipInfo))
  if (isEmpty(friendshipInfo)) {
    return null;
  }
  // console.log("first",friendshipInfo.family_list_status.family_sender_id)
  return (
    <>
      <CoverAndProfileSmall
        pagetype={"family"}
        bgImage={familyProfile}
        userPhotos={faPictures}
        name={overviewInfo && overviewInfo.users_info.first_name}
        type="Familly"
        friendshipDetails={friendshipInfo && friendshipInfo.family_list_status}
        mainId={authUser.id}
        sender_id={friendshipInfo?.family_list_status?.family_sender_id}
        status={friendshipInfo?.family_list_status?.family_status}
      />
      <CoverAndProfileSmall
        pagetype={"friends"}
        bgImage={fndproFile}
        userPhotos={fnPictures}
        name={overviewInfo && overviewInfo.users_info.first_name}
        type="Friends"
        friendshipDetails={friendshipInfo && friendshipInfo.friend_list_status}
        mainId={String(authUser.id)}
        sender_id={
          friendshipInfo &&
          friendshipInfo.friend_list_status &&
          friendshipInfo.friend_list_status.friend_sender_id &&
          String(friendshipInfo.friend_list_status.friend_sender_id)
        }
        status={
          friendshipInfo &&
          friendshipInfo.friend_list_status &&
          friendshipInfo.friend_list_status.friend_status &&
          friendshipInfo.friend_list_status.friend_status
        }
      />
      <CoverAndProfileSmall
        pagetype={"professional"}
        bgImage={profeshonalProfile}
        userPhotos={proPictures}
        name={overviewInfo && overviewInfo.users_info.first_name}
        type="Profession"
        friendshipDetails={
          friendshipInfo && friendshipInfo.professional_list_status
        }
        mainId={String(authUser.id)}
        sender_id={
          friendshipInfo &&
          friendshipInfo.professional_list_status &&
          friendshipInfo.professional_list_status.professional_sender_id &&
          String(friendshipInfo.professional_list_status.professional_sender_id)
        }
        status={
          friendshipInfo &&
          friendshipInfo.professional_list_status &&
          friendshipInfo.professional_list_status.professional_status &&
          friendshipInfo.professional_list_status.professional_status
        }
      />
    </>
  );
};
