import React from "react";
import { Container } from "react-bootstrap";
import Banner from "../../Components/Banner/Banner";
import CategoriesSlider from "../../Components/CategoriesSlider/CategoriesSlider";
import { Brand } from "../Brand/Brand";
import { LimitedShops } from "../Shop/LimitedShops/LimitedShops";
import { LimitedProduct } from "../Product/LimitedProduct/LimitedProduct";
import { useGetHomeStaticDataQuery } from "src/app/services/ubizzApi/ubizzApi";
import {
  brandUrl,
  limitedCategoryUrl,
  limitProductUrl,
  ShopLimitUrl,
  sliderImgUrl,
  vendorSliderImgUrl,
} from "../../utilities/CommonUrl";
import { useSelector } from "react-redux";
import NewArrivals from "../NewArrivals/NewArrivals";
import BestSales from "../BestSales/BestSales";
import WeekendsSale from "../WeekendsSale/WeekendsSale";

const CorporateHome = () => {
  const { isLoading: banner_loading } = useGetHomeStaticDataQuery({
    url: sliderImgUrl,
    field_name: "banner",
  });
  const { isLoading: vendor_loading } = useGetHomeStaticDataQuery({
    url: vendorSliderImgUrl,
    field_name: "vendor_slider",
  });
  const { isLoading: store_loading } = useGetHomeStaticDataQuery({
    url: ShopLimitUrl,
    field_name: "home_store",
  });
  const { isLoading: brand_loading } = useGetHomeStaticDataQuery({
    url: brandUrl,
    field_name: "brand_slider",
  });
  const { isLoading: limit_category_loading } = useGetHomeStaticDataQuery({
    url: limitedCategoryUrl,
    field_name: "category_product",
  });
  const { isLoading: product_loading } = useGetHomeStaticDataQuery({
    url: limitProductUrl,
    field_name: "home_product",
  });

  const {
    banner,
    vendor_slider,
    home_store,
    brand_slider,
    category_product,
    home_product,
  } = useSelector((state) => state.ubizzData);

  return (
    <>
      <Container fluid className={`p-0`}>
        <Banner
          banner_loading={banner_loading}
          vendor_loading={vendor_loading}
          banner={banner}
          vendor_slider={vendor_slider}
        />
        <br />
        <LimitedShops store_loading={store_loading} home_store={home_store} />
        <CategoriesSlider
          limit_category_loading={limit_category_loading}
          category_product={category_product}
        />
        <WeekendsSale
          product_loading={product_loading}
          home_product={home_product?.slice(2, 8)}
        />
        <NewArrivals
          product_loading={product_loading}
          home_product={home_product?.slice(3, 9)}
        />
        <BestSales
          product_loading={product_loading}
          home_product={home_product?.slice(5, 11)}
        />
        <Brand brand_loading={brand_loading} brand_slider={brand_slider} />
        <LimitedProduct
          product_loading={product_loading}
          home_product={home_product}
        />
        {/* <AdsBanner /> */}
        {/* <CorporateFooter /> */}
      </Container>
    </>
  );
};

export default CorporateHome;
