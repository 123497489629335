import React from "react";
import { Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions"
import "react-data-table-component-extensions/dist/index.css";
import {
  columns,
  data,
  conditionalRowStyles,
} from "src/Projects/Corporate/utilities/tableData";

import "./dashboard.css";

const Dashboard = () => {
  const tableData = {
    columns,
    data,
  };

  return (
    <div>
      <Row xs="2" md="4" className="dashboard-item-container mb-4 g-4">
        <Col>
          <div className="dashboard-item">
            <h4>Total Orders</h4>
            <h3>1200</h3>
          </div>
        </Col>
        <Col>
          <div className="dashboard-item">
            <h4>Pending Order</h4>
            <h3>1200</h3>
          </div>
        </Col>
        <Col>
          <div className="dashboard-item">
            <h4>Canceled Order</h4>
            <h3>00</h3>
          </div>
        </Col>
        <Col>
          <div className="dashboard-item">
            <h4>Delivered Order</h4>
            <h3>00</h3>
          </div>
        </Col>
      </Row>

      <h6 className="fs-6 my-3">Order History</h6>

      <div className="secondary-bg">
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            responsive
            theme="dark"
            defaultSortAsc={true}
            defaultSortFieldId="id"
            pagination
            pointerOnHover
            onHeader
            conditionalRowStyles={conditionalRowStyles}
          />
        </DataTableExtensions>
      </div>
    </div>
  );
};

export default Dashboard;
