import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import useFoodStore from "../../hooks/useFoodStore";
import CartCheckout from "./CartCheckout";
import FoodCartItem from "./FoodCartItem/FoodCartItem";

const Cart = () => {
  const { carts, handleQuantity, handleRemove } = useFoodStore();

  return (
    <Container fluid style={{ minHeight: "70vh" }} className="primary-text">
      <h6 className="pt-3 fw-bold">My Cart</h6>
      <p className="font-14">Cart From Zam Zam Resturant, Mirpur - 11 </p>
      <Row className="g-0 g-md-3">
        <Col xs="12" lg="6" xl="7">
          {carts.map((item) => (
            <FoodCartItem
              key={item.id}
              data={item}
              handleRemove={handleRemove}
              handleQuantity={handleQuantity}
            />
          ))}
          {carts.length === 0 && (
            <h6 className="text-center mt-4">Your Cart is Empty</h6>
          )}

          <div className="secondary-bg p-2 rounded-2">
            <div className="d-flex justify-content-between mx-3 ">
              <p className="font-14 fw-500 mb-1">Sub Total : </p>{" "}
              <p className="font-14 fw-500 mb-1">{400}Tk</p>
            </div>
            <div className="d-flex justify-content-between mx-3 ">
              <p className="font-14 fw-500 mb-1">Delivery Fee : </p>{" "}
              <p className="font-14 fw-500 mb-1">{50}Tk</p>
            </div>
            <div className="d-flex justify-content-between mx-3 ">
              <p className="font-14 fw-500 mb-1"> Vat : </p>{" "}
              <p className="font-14 fw-500 mb-1">{10}Tk</p>
            </div>
            <div className="d-flex justify-content-between mx-3 ">
              <p className="font-14 fw-500 mb-1">Discount : </p>{" "}
              <p className="font-14 fw-500 mb-1">{5}Tk</p>
            </div>
            <hr  className="my-2"/>
            <div className="d-flex justify-content-between mx-3 last">
              <h6>Total : </h6> <h6>{100}Tk</h6>
            </div>
          </div>
        </Col>
        <Col xs="12" lg="6" xl="5">
          <div className="p-1 p-md-3 my-3 py-4 secondary-bg">
            <h6>{carts.length} Selected Item</h6>
            <p>Total Price: {4000}Tk</p>
            <CartCheckout />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Cart;
