import React from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { calculate_between_2_date } from "src/utilities/utilities";
import { companyProfilePhotoUrl } from "../../utilities/job_commonUrl";

const FloatSaveJobCard = ({ data }) => {
  const { company_profile_section, job_post } = data;
  const navigate = useNavigate();

  let dayLeft = calculate_between_2_date(job_post?.deadline);

  const profilePic = company_profile_section?.profile_picture
    ? companyProfilePhotoUrl + company_profile_section?.profile_picture
    : "/user.png";

  function handleDetailBtn() {
    navigate(`/Job/save-job-details/${data.id}`, {
      state: { jobData: data },
    });
  }
  return (
    <Col>
      <div className="singleJobPostContainer floatJobCard job_cart-hover  primary-text d-sm-flex ">
        <div className="jobPostImgContainer align-items-center d-flex gap-3">
          <div className="jobPostImgDiv">
            <img src={profilePic} className="img img-fluid" alt="" />
          </div>
          <div className="jobPostTitleText">
            <h6 className="my-0 fs-6 fw-bold">
              <span className="font-14">{job_post?.job_title}</span>
            </h6>
            <p className="my-0 ">
              <span className="font-14">
                {company_profile_section.company_name || "N/A"}
              </span>
            </p>

            <span className="font-14">
              {" "}
              {job_post?.range_start}Tk - {job_post?.range_end}Tk/month
            </span>
          </div>
        </div>
        <div className="py-2 text-end d-flex flex-sm-column align-items-center justify-content-evenly justify-content-sm-between ">
          <button
            onClick={handleDetailBtn}
            className="eco-btn job-details-text-btn me-0 pe-0"
            type="button "
          >
            <small>View Details</small>
          </button>
          <small>{dayLeft < 1 ? "Time over" : `${dayLeft}d left`}</small>
        </div>
      </div>
    </Col>
  );
};

export default FloatSaveJobCard;
