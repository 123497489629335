import React from "react";
import { Col, Form } from "react-bootstrap";
import "./textarea_input.css"

const TextareaInputBox = ({ name, value, handler, label, placeholder }) => {
  return (
    <Col md="12" className="ms-0 textarea-input-box">
      <Form.Label style={{ fontSize: "0.9rem" }}>{label}</Form.Label>
      <Form.Control
        as="textarea"
        style={{ height: "100px" }}
        name={name}
        value={value}
        onChange={handler}
        className="InputWithLabelInput ms-0"
        type="text"
        placeholder={placeholder}
      />
    </Col>
  );
};

export default TextareaInputBox;
