import React, { useEffect } from "react";
import { useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useDoCommentMutation } from "src/app/services/viewApi/viewPostApi";
import { channelLogoImg } from "../../common/view_commonUrl";

export const CommentBox = ({ reply, id = 46, onSubmit }) => {
  const commentInputRef = useRef(null);
  const [doComment] = useDoCommentMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (reply) {
      doComment({
        id,
        type: "comment_reply",
        comment: { comment_reply: commentInputRef.current.value },
      });
      // console.log(" comment reply");
    } else {
      doComment({
        id,
        type: "comment",
        comment: { comment: commentInputRef.current.value },
      });
      // console.log("comment");
    }
    commentInputRef.current.value = "";
    onSubmit();
  };

  useEffect(() => {
    commentInputRef.current.focus();
  }, []);

  return (
    <CommentBoxWrapper reply={reply}>
      <img className="avatar" src="https://i.ibb.co/MprdZS6/user.png" alt="" />
      <form onSubmit={handleSubmit} method="POST">
        <textarea
          ref={commentInputRef}
          name="comment"
          rows={1}
          placeholder="Add a comment"
        />
        <button type="submit">
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </form>
    </CommentBoxWrapper>
  );
};

const CommentBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  color: var(--text-primary);

  margin-left: ${(props) => (props.reply ? "2rem" : "0")};

  & > .avatar {
    --size: 2.8rem;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
  }

  & > form {
    display: flex;
    flex-basis: 100%;
    align-items: flex-end;

    & > textarea {
      flex-grow: 1;
      border-bottom: 1px solid var(--text-secondary);
      padding: 0.5rem;
    }

    & > button {
      background-color: transparent;
      border: 0;
      font-size: 1.2rem;
      color: var(--text-primary);
    }
  }
`;
