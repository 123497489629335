import React, { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import "./Navigation.css";
import { NavLinks } from "./NavLinks";
import NavOffCanvas from "./NavOffCanvas";
import Typewriter from "typewriter-effect";
import { useNavigate } from "react-router-dom";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";

const Navigation = () => {
  const [show, setShow] = useState(false);
  const handelShow = () => setShow(true);
  const animatedText = ["Your Vision Is Our Mission"];
  const navigate = useNavigate();

  return (
    <Navbar collapseOnSelect expand="lg" className="dashboardNav">
      {/* <div className="constainer-fluid  navPadding"> */}
      <div className="container-fluid navPadding">
        <Navbar.Brand className="d-flex">
          <img
            src="/images/logo.svg"
            className="img img-fluid pointer m-1"
            alt="UVIOM"
            width="40"
            height="40"
            onClick={() => navigate("/dashboard")}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigate("/dashboard");
              }
            }}
          />
          <div className="type-writer-text-box">
            <h5 className="mb-0">UVIOM</h5>
            <h6 className="mb-0 text-orange overflow-hidden type-writer-text">
              <Typewriter
                options={{
                  strings: animatedText,
                  autoStart: true,
                  loop: true,
                }}
              ></Typewriter>
            </h6>
          </div>
        </Navbar.Brand>
        <span
          tabIndex="0"
          aria-label="navigation"
          onClick={handelShow}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handelShow();
            }
          }}
          className="cousotomNavIcon"
        >
          <ThreeDots size="25" />
        </span>

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto navLink ExpandNav">
            <NavLinks />
          </Nav>
        </Navbar.Collapse>
      </div>
      <NavOffCanvas show={show} setShow={setShow} />
    </Navbar>
  );
};

export default Navigation;
