import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useGetMeFollowingQuery } from "src/app/services/viewApi/viewGetApi";
import { useToggleChannelFollowMutation } from "src/app/services/viewApi/viewPostApi";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";
import styled from "styled-components";
import { channelLogoImg } from "../common/view_commonUrl";

function OwnFollowingModal({ handleClose, show }) {
  const { isLoading, data } = useGetMeFollowingQuery();

  return (
    <Modal className="viewModal" show={show} onHide={handleClose}>
      {isLoading ? (
        <div className="py-5">
          <DottedCircleSpinner />
        </div>
      ) : (
        <>
          <Modal.Header className="stickyParts z-index-2 py-3">
            <div className="modal-title">Following</div>
            <button
              onClick={handleClose}
              className="text-orange bg-transparent border-0"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </Modal.Header>
          <Modal.Body>
            {data?.data[0]?.follows_list.length > 0 ? (
              data?.data[0]?.follows_list.map((person) => (
                <SinglePerson
                  key={person.id}
                  userId={person.follows_profile.user_id}
                  profilePic={person.follows_profile.photo}
                  name={person.follows_profile.channel_name}
                />
              ))
            ) : (
              <div className="text-center"> You Are Following To No One </div>
            )}
          </Modal.Body>
        </>
      )}
    </Modal>
  );
}
export default OwnFollowingModal;

// another components they are related to each other and not used in other component
const SinglePerson = ({ profilePic, name, userId }) => {
  const [isFollowing, setIsFollowing] = useState(true);
  const [toggleChannelFollow] = useToggleChannelFollowMutation();

  const handleClick = () => {
    setIsFollowing(!isFollowing);
    toggleChannelFollow(userId);
  };

  return (
    <PersonWrapper>
      <img
        className="avatar"
        src={channelLogoImg + profilePic}
        onError={(e) => (e.target.src = "https://i.ibb.co/MprdZS6/user.png")}
        alt={name}
      />
      <div className="name">{name}</div>

      <button
        onClick={handleClick}
        className={`followbtn ${isFollowing ? "active" : ""}`}
      >
        {isFollowing ? "Following" : "Follow"}
      </button>
    </PersonWrapper>
  );
};

const PersonWrapper = styled.div`
  margin-block: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;

  & .avatar {
    width: 3rem;
    border-radius: 50%;
  }

  & .name {
    font-weight: 500;
  }

  & .followbtn {
    margin-left: auto;
    font-weight: 400;
    font-size: 0.7rem;
    padding: 0.1rem 0.5rem;
    border: 1px solid var(--bg-success);
    border-radius: 3rem;
    background: transparent;

    &.active {
      background: var(--bg-success);
      color: #fff;
    }
  }
`;
