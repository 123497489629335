import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createTheme } from "react-data-table-component";
import { downloadInvoice } from "./invoiceData";
const { default: ThreeDots } = require("src/Projects/Life/icons/ThreeDots");

createTheme(
  "dark",
  {
    text: {
      primary: "#ffffff",
      secondary: "#E5E5E5",
    },
    background: {
      default: "#283340",
    },
    context: {
      background: "#283340",
      text: "#FFFFFF",
    },
    divider: {
      default: "gray",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

export const conditionalRowStyles = [
  {
    when: (row) => row.payment_status === "Unpaid",
    style: {
      backgroundColor: "#8b4444",
      color: "white",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  // You can also pass a callback to style for additional customization
  // {
  //   when: row => row.payment_status === "Unpaid",
  //   style: row => ({ backgroundColor:  'pink'  }),
  // },
];

export const columns = [
  {
    name: "Date",
    selector: (row) => row.date,
    sortable: true,
  },
  {
    name: "ID",
    selector: (row) => row.product_id,
    sortable: true,
  },
  {
    name: "Products",
    selector: (row) => row.product_name,
    sortable: true,
  },
  {
    name: "Quantity",
    selector: (row) => row.quantity,
    sortable: true,
  },
  {
    name: "Payment",
    selector: (row) => row.payment_status,
  },
  {
    name: "Statement",
    selector: (row) => row.statement,
  },
  {
    name: "Total",
    selector: (row) => row.total,
    sortable: true,
  },
  {
    button: true,
    cell: () => (
      <button className="eco-btn">
        <ThreeDots size="1.3rem"></ThreeDots>
      </button>
    ),
  },
];

export const data = [
  {
    id: 1,
    date: "18/04/22",
    product_id: "#223458",
    product_name: "Laptop",
    quantity: 1,
    payment_status: "Paid",
    statement: "In Progress",
    total: 45000,
  },
  {
    id: 2,
    date: "15/04/22",
    product_id: "#223458",
    product_name: "SmartPhones",
    quantity: 1,
    payment_status: "Paid",
    statement: "In Progress",
    total: "25,00Tk",
  },
  {
    id: 3,
    date: "15/04/22",
    product_id: "#223458",
    product_name: "SmartPhones",
    quantity: 1,
    payment_status: "Paid",
    statement: "In Progress",
    total: "25,00Tk",
  },
  {
    id: 4,
    date: "15/04/22",
    product_id: "#223458",
    product_name: "SmartPhones TV",
    quantity: 1,
    payment_status: "Unpaid",
    statement: "In Progress",
    total: "25,00Tk",
  },
  {
    id: 5,
    date: "15/04/22",
    product_id: "#223458",
    product_name: "SmartPhones",
    quantity: 8,
    payment_status: "Paid",
    statement: "In Progress",
    total: "25,00Tk",
  },
  {
    id: 6,
    date: "15/04/22",
    product_id: "#223458",
    product_name: "S SmartPhones",
    quantity: 1,
    payment_status: "Paid",
    statement: "In Progress",
    total: "25,00Tk",
  },
  {
    id: 7,
    date: "20/04/22",
    product_id: "#223458",
    product_name: "DeskTop",
    quantity: 1,
    payment_status: "Unpaid",
    statement: "In Progress",
    total: "25,00Tk",
  },
];

export const payment_columns = [
  {
    name: "Product Name",
    selector: (row) => row.product_name,
  },
  {
    name: "Date",
    selector: (row) => row.date,
    sortable: true,
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    sortable: true,
  },
  {
    name: "Payment Method",
    selector: (row) => row.payment_method,
  },
  {
    name: "Statement",
    button: true,
    selector: (row) => (
      <button className="eco-btn" onClick={() => downloadInvoice(row)}>
        Invoice
        <FontAwesomeIcon className="text-orange ms-2" icon={faDownload} />
      </button>
    ),
  },
];

export const payment_data = [
  {
    id: 1,
    product_name: "Titan NN1639SM01 Metal Analog Watch for Men - Silver",
    date: "18 jun 2022",
    amount: "1000.00 Tk ",
    payment_method: "UVIOM Wallet",
  },
  {
    id: 2,
    product_name: "Titan NN1639SM01 Metal Analog Watch for Men - Silver",
    date: "18 jun 2022",
    amount: "1000.00 Tk ",
    payment_method: "UVIOM Wallet",
  },
  {
    id: 3,
    product_name: "Titan NN1639SM01 Metal Analog Watch for Men - Silver",
    date: "18 jun 2022",
    amount: "1000.00 Tk ",
    payment_method: "UVIOM Wallet",
  },
  {
    id: 4,
    product_name: "Titan NN1639SM01 Metal Analog Watch for Men - Silver",
    date: "18 jun 2022",
    amount: "1000.00 Tk ",
    payment_method: "UVIOM Wallet",
  },
  {
    id: 5,
    product_name: "Titan NN1639SM01 Metal Analog Watch for Men - Silver",
    date: "18 jun 2022",
    amount: "1000.00 Tk ",
    payment_method: "UVIOM Wallet",
  },
  {
    id: 6,
    product_name: "Titan NN1639SM01 Metal Analog Watch for Men - Silver",
    date: "18 jun 2022",
    amount: "1000.00 Tk ",
    payment_method: "UVIOM Wallet",
  },
];
