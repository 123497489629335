import React from "react";
import CompanySingleInfo from "../../Components/Common/CompanySingleInfo";

const SocialLink = ({ data }) => {
  return (
    <div
      style={{ fontSize: "14px" }}
      className="company-profile applicantProfile p-2 my-3 pt-4"
    >
      {data.length > 0 ? (
        data.map((item) => {
          return (
            <CompanySingleInfo
              key={item.id}
              isLink
              title={item?.title}
              value={item?.social_media_link}
            />
          );
        })
      ) : (
        <h6>Not Link found</h6>
      )}

      <br />
    </div>
  );
};

export default SocialLink;
