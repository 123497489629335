import React from "react";
import { useLocation } from "react-router-dom";
import Footer from "../LifePages/LoginPage/Footer/Footer";
import Navigation from "../LifePages/LoginPage/Shared/Navigation";
import Carusel from "./Carusel";

export const Upcomming = () => {
  const location = useLocation();

  return (
    <>
      <Navigation />
      <div className="container-fluid pb-4 ">
        <div className="row">
          <div className="col-12 ">
            <Carusel />
          </div>
        </div>
        {location.state && location.state.data ? (
          <div className="row my-3">
            <div className="col-3 mx-auto logoRounded">
              <img
                className="img-fluid"
                src={location.state.data}
                alt="Coming soon"
              />
            </div>
          </div>
        ) : null}
      </div>
      <Footer />
    </>
  );
};
