import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import truck from "./../../assets/express-truck.svg";
import qrCode from "./../../assets/qr-code.png";
import toScan from "./../../assets/to-scan.svg";
import "./receivedDelivery.css";

function ReceivedDelivery() {
  const { pathname:url } = useLocation();
  const dynamicPath = url.split("/")[1];

  return (
    <div className="receivedDelivery">
      <div className="receivedDelivery__confirmation">
        <div className="d-flex align-items-center gap-3 mb-3 mb-md-5">
          <img src={truck} alt="" />
          <h6 className="m-0 text-start">
            UVIOM
            <br />
            EXPRESS
          </h6>
        </div>
        <Button
          as={Link}
          to={`/${dynamicPath}/delivery/gotodelivery`}
          className="btn__ride px-5"
        >
          Confirm
        </Button>
      </div>
      <span className="receivedDelivery__divider" />
      <div className="receivedDelivery__right">
        <div className="receivedDelivery__qrcode">
          <div className="text-center">
            <h6 className="mb-3">Received</h6>
            <img src={qrCode} alt="" />
          </div>
          <div className="text-center">
            <h6 className="mb-3">Delivered</h6>
            <img src={toScan} alt="" />
          </div>
        </div>

        <div className="receivedDelivery__qrmanual">
          <p className="receivedDelivery__qrmanual__title" >Manual ID</p>
          <p className="receivedDelivery__qrmanual__code" >15543256652314656235646125</p>
        </div>

      </div>
        <div className="py-4"></div>
    </div>
  );
}
export default ReceivedDelivery;
