import React from 'react';

const SearchIcon = ({size="29px",className=""}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 37 37" fill="none">
        <path className={className} d="M36.0011 31.125L28.9911 24.115C28.8334 23.9578 28.6462 23.8334 28.4403 23.7489C28.2343 23.6645 28.0137 23.6217 27.7911 23.623H26.6491C28.9387 20.6889 30.0231 16.992 29.681 13.286C29.3388 9.58 27.596 6.14406 24.8079 3.67875C22.0198 1.21344 18.3964 -0.0955754 14.6764 0.0186066C10.9564 0.132789 7.42002 1.66157 4.78836 4.29324C2.15669 6.9249 0.627906 10.4613 0.513724 14.1812C0.399542 17.9012 1.70856 21.5247 4.17387 24.3128C6.63918 27.1009 10.0751 28.8437 13.7811 29.1858C17.4871 29.5279 21.184 28.4436 24.1181 26.154V27.298C24.1168 27.5205 24.1596 27.7412 24.2441 27.9472C24.3285 28.1531 24.4529 28.3403 24.6101 28.498L31.6201 35.5079C31.7763 35.6651 31.962 35.7897 32.1666 35.8748C32.3712 35.9599 32.5905 36.0037 32.8121 36.0037C33.0336 36.0037 33.253 35.9599 33.4576 35.8748C33.6621 35.7897 33.8479 35.6651 34.0041 35.5079L35.9941 33.518C36.3098 33.2006 36.4876 32.7715 36.4889 32.3239C36.4903 31.8762 36.3149 31.4461 36.0011 31.127V31.125ZM15.1181 23.625C13.338 23.625 11.598 23.0971 10.1179 22.1082C8.63789 21.1192 7.48434 19.7136 6.80315 18.0691C6.12196 16.4246 5.94373 14.615 6.291 12.8691C6.63827 11.1233 7.49544 9.51966 8.75411 8.26099C10.0128 7.00232 11.6164 6.14515 13.3623 5.79788C15.1081 5.45062 16.9177 5.62885 18.5622 6.31004C20.2068 6.99122 21.6124 8.14478 22.6013 9.62482C23.5902 11.1049 24.1181 12.8449 24.1181 14.625C24.1185 15.8069 23.8859 16.9773 23.4337 18.0693C22.9816 19.1613 22.3186 20.1534 21.4828 20.9891C20.647 21.8247 19.6546 22.4875 18.5625 22.9394C17.4704 23.3913 16.3 23.6236 15.1181 23.623V23.625Z" fill="#F2F2F2"/>
        </svg>
    );
};

export default SearchIcon;