import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBirthdayCake,
  faPlusCircle,
  faPrayingHands,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import {
  updateBasicInfoUrl,
  userAdditionalInfoUrl,
} from "src/Projects/Life/Common/CommonUrl";
import { getInfo } from "src/Common/apiCall/GetApiCalls";

import { StoringInputs } from "./StoringInputs";
import { BubbleScale } from "src/Common/BubbleScale";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";

export const BasicInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const setIsEditableFalse = () => setIsEditable(false);
  const setIsEditableTrue = () => setIsEditable(true);
  const [info, setinfo] = useState();
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    getInfo(setIsLoading, userAdditionalInfoUrl, setinfo);
  };
  const [formData, setformData] = useState({
    gender: "",
    birthday: "",
    religion: "",
    timezone: "",
  });
  // console.log(info.data.details_about)
  useEffect(() => {
    if (!isEmpty(info?.data)) {
      setformData({
        gender: info && info.data ? info.data.gender : "",
        birthday: info && info.data ? info.data.birthday : "",
        religion: info && info.data ? info.data.religion : "",
        timezone: info && info.data ? info.data.timezone : "",
      });
    }
  }, [info]);
  const inputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...formData };
    newData[name] = value;
    setformData(newData);
  };
  const storingInputsArg = {
    isLoading: isLoading,
    setIsLoading: setIsLoading,
    setIsEditableFalse: setIsEditableFalse,
    dataReload: getData,
    componentMainUrl: updateBasicInfoUrl,
    formData: formData,
    inputHandleChange: inputHandleChange,
  };
  if (isLoading) {
    return <DottedCircleSpinner />;
  }
  return (
    <div className="mb-4">
      <Col xs={12} sm={12} md={12} lg={12} className="aboutBox mt-1 mb-4 ">
        <div className="d-flex justify-content-between border-bottom pb-2 border-white">
          <div>
            <h6>Basic Info</h6>
            <div className="d-flex pointer" onClick={setIsEditableTrue}>
              <div className="me-2">
                <FontAwesomeIcon icon={faPlusCircle} />
              </div>
              <div className=""> Update Info</div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          {isEditable ? (
            <>
              <StoringInputs storingInputsArg={storingInputsArg} />
            </>
          ) : (
            <>
              <div className="">
                <h6>Basic Info</h6>
                <div className="d-flex justify-content-between my-4">
                  <div className="d-flex">
                    <div className="me-3 border  p-1 myIconCircle">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                    <div className="">
                      <div>
                        {info?.data && info.data.gender === "1" ? "Male" : ""}
                        {info?.data && info.data.gender === "2" ? "Female" : ""}
                        {info?.data && info.data.gender === "3" ? "Others" : ""}
                      </div>
                      <div className="smallTextSize">Gender</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between my-4">
                  <div className="d-flex">
                    <div className="me-3 border  p-1 myIconCircle">
                      <FontAwesomeIcon icon={faBirthdayCake} />
                    </div>
                    <div className="">
                      <div>{info?.data && info.data.birthday}</div>
                      <div className="smallTextSize">Birth Date</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between my-4">
                  <div className="d-flex">
                    <div className="me-3 border  p-1 myIconCircle">
                      <FontAwesomeIcon icon={faPrayingHands} />
                    </div>
                    <div className="">
                      <div>{info?.data && info.data.religion}</div>
                      <div className="smallTextSize">religion</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between my-4">
                  <div className="d-flex">
                    <div className="me-3 border  p-1 myIconCircle">
                      <FontAwesomeIcon icon={faBirthdayCake} />
                    </div>
                    <div className="">
                      <div>{info?.data && info.data.timezone}</div>
                      <div className="smallTextSize">TimeZone</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Col>
    </div>
  );
};
