import React from 'react';
import { Link } from 'react-router-dom';
import { CutomTooltip } from 'src/Common/CutomTooltip';

const SingleCategory = ({ img, brand_name }) => {
    return (
        <CutomTooltip text={brand_name} placementName="top">
            <Link to={""} className=''>
                {/* <span className='w-100 h-100 d-flex justify-content-center align-items-center'>
                </span> */}
                    <img className='brand_logo' src={img} alt="" />

            </Link >
        </CutomTooltip>

    );
};

export default SingleCategory;