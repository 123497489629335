import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useParams } from "react-router-dom";
import paginationApiCall from "src/Common/apiCall/paginationApiCall";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import SingleJobPost from "../../Components/SingleJobPost/SingleJobPost";
import { getDistrictByPost } from "../../utilities/job_commonUrl";

const DistrictByPost = () => {
  const { districtName, districtId } = useParams();
  const [postList, setPostList] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const { pathname } = useLocation();

  const paginationApiArg = {
    postList,
    setPostList,
    currentPage,
    setHasMore,
    setIsLoading,
    Url: getDistrictByPost + districtId,
  };

  useEffect(() => {
    if (hasMore) {
      paginationApiCall(paginationApiArg, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    paginationApiArg["currentPage"] = 1;
    paginationApiCall(paginationApiArg, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className="secondary-bg p-2 py-3">
      {loading && <BubbleLoader />}

      <InfiniteScroll
        dataLength={postList.length} //This is important field to render the next data
        next={() => {
          setCurrentPage(currentPage + 1);
        }}
        hasMore={hasMore}
      >
        <div className="jobRecommended p-2 pb-4">
          <p className="text-orange">{districtName}</p>

          {postList.length < 1 && (
            <p className="text-center mt-4"> 0 item Found </p>
          )}
          <Row className="g-3">
            {postList.map((post) => (
              <SingleJobPost key={post.id} data={post} />
            ))}
          </Row>
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default DistrictByPost;
