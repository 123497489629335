import { useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { videoThumbnailImg } from "../../common/view_commonUrl";
import EditPlaylistDetailsModal from "./EditPlaylistDetailsModal";
import useAuth from "src/hooks/useAuth";

export const SinglePlaylist = ({
  playlistId,
  userId,
  playlistName,
  playlistDesc,
  playlistStatus,
  playlistThumbnail,
  videos,
}) => {
  const [showEditPlaylistModal, setShowEditPlaylistModal] = useState(false);
  const { pathname } = useLocation();
  const dynamicPath = pathname.split("/")[1];
  const [editButton, seteditButton] = useState(false);

  const { authUser } = useAuth();

  const showEditButton = () => {
    seteditButton(true);
  };
  const hideEditButton = () => {
    seteditButton(false);
  };

  return (
    <>
      <EditPlaylistDetailsModal
        playlistId={playlistId}
        playlistName={playlistName}
        playlistDesc={playlistDesc}
        playlistStatus={playlistStatus}
        show={showEditPlaylistModal}
        handleClose={() => setShowEditPlaylistModal(!showEditPlaylistModal)}
      />
      <Wrapper onMouseEnter={showEditButton} onMouseLeave={hideEditButton}>
        {authUser.id === parseInt(userId) && editButton && (
          <div className="lineOverImg position-absolute top-0 text-end">
            <Button
              onClick={() => setShowEditPlaylistModal(!showEditPlaylistModal)}
              variant="light"
              className="btn btn-sm py-0 m-2"
            >
              Edit <FontAwesomeIcon icon={faPen} size="sm" className="mx-1" />
            </Button>
          </div>
        )}
        <Link to={`/${dynamicPath}/channel/${userId}/${playlistId}`}>
          <div className="albumImg">
            <img
              src={
                playlistThumbnail
                  ? videoThumbnailImg + playlistThumbnail
                  : "https://i.ibb.co/F4tpvw8/istockphoto-1147544807-612x612.jpg"
              }
              alt=""
              onError={(e) =>
                (e.target.src =
                  "https://i.ibb.co/F4tpvw8/istockphoto-1147544807-612x612.jpg")
              }
              className="thumbnail"
            />

            <div className="total-videos absolute-center">
              {videos.length >= 1 ? videos.length : "No"}
              &nbsp;Videos
            </div>
          </div>
          <div className="playlist-name">{playlistName}</div>
        </Link>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border-radius: 0.2rem;
  overflow: hidden;
  position: relative;

  & .thumbnail {
    width: 100%;
    height: 7rem;
    object-fit: cover;
  }

  & .total-videos {
    color: var(--clr-white);
    text-shadow: 1px 0px 2px rgba(0, 0, 0, 0.4);
  }
  & .playlist-name {
    text-align: center;
    padding: 0.5rem 0;
  }
`;
