import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const ListDropdownItem = (props) => {
    const navigate = useNavigate()
    const location = useLocation()

    const { item } = props
    return (
        <>
            <div className="d-flex"  >
                <img
                    className="img-fluid listDropdownIcon rounded-circle me-2"
                    src="/user.png"
                    alt="user"
                />
                <small className="userMainProfile" onClick={() => navigate("/life/public-life", { from: location })}>{item.user.first_name} {item.user.last_name}</small>
            </div>
        </>

    )
}
