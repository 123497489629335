import React from 'react';
import { brandImageBase, brand_logoBase } from 'src/Projects/Corporate/utilities/CommonUrl';
import { Star } from './Star';


const Banner = (props) => {
  const { brand_image,brand_logo,brand_name ,brand_description} = props.data


  return (
    <>
      <div
        className="store__banner-main d-flex align-items-end justify-content-end"
        style={brand_image ? { backgroundImage: `url(${brandImageBase}/${brand_image})` } : { backgroundImage: `url("/corporate/banner1.jpg")` }}
      >
       <div className="text-end store__item__text-box text-white flex-1">
          <h4 className='text-ellipsis' >{brand_name && brand_name}</h4>
          <p className='text-ellipsis'>{brand_description && brand_description}</p>

         <Star/>
        </div>
        <div>
          <img
            src={`${brand_logoBase}/${brand_logo}`}
            className="store__item-logo"
            alt=""
          />

        </div>
      </div>

    </>
  );
};

export default Banner;
