import FindRide from "../components/FindRide";

function Ride() {
  return (
    <div
      id="wrapper"
      className="bg-img bg-bottom"
      style={{
        backgroundImage: `url(https://img.theculturetrip.com/1440x/smart/wp-content/uploads/2017/05/american2.jpg)`,
      }}
    >
      <div className="py-5" />
      <FindRide />
      <div className="py-5" />
    </div>
  );
}
export default Ride;
