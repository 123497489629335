import React from 'react';

const AttachIcon = ({size="29px"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 29 24" fill="none">
        <path d="M17.0048 22.9253L24.8384 15.0918C26.3792 13.551 27.2448 11.4611 27.2448 9.28205C27.2448 7.10296 26.3792 5.01312 24.8384 3.47228C23.2975 1.93143 21.2077 1.06579 19.0286 1.06579C16.8495 1.06579 14.7597 1.93143 13.2188 3.47228L3.53586 13.1552C2.50863 14.1825 1.93154 15.5757 1.93154 17.0284C1.93154 18.4811 2.50863 19.8744 3.53587 20.9016C4.5631 21.9288 5.95632 22.5059 7.40905 22.5059C8.86177 22.5059 10.255 21.9288 11.2822 20.9016L20.1731 12.0107C20.4274 11.7564 20.6292 11.4545 20.7668 11.1222C20.9044 10.7899 20.9753 10.4338 20.9753 10.0741C20.9753 9.71445 20.9044 9.35832 20.7668 9.02604C20.6292 8.69376 20.4274 8.39184 20.1731 8.13752V8.13752C19.6595 7.62391 18.9629 7.33536 18.2365 7.33536C17.5102 7.33536 16.8135 7.62391 16.2999 8.13752L9.34564 15.0918" stroke="#F1A350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default AttachIcon;