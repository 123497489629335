import react from "react";

function BelledIcon({ size = "24", fill = "--text-primary" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.5302 18.0801C43.5302 18.2001 42.7402 19.9201 41.4302 23.5301C41.2241 24.1848 40.8585 24.7779 40.3662 25.2562C39.874 25.7345 39.2705 26.0829 38.6102 26.2701C30.6102 29.2701 28.3902 35.4601 27.8502 41.9201C27.3102 48.3801 28.4002 55.1701 27.0002 59.4801C26.2802 61.6401 24.0002 64.2201 22.2902 67.1101C20.5802 70.0001 19.2902 73.2201 20.5902 76.6601H74.5902C75.8702 73.2201 74.5902 70.0001 72.8902 67.1101C71.1902 64.2201 68.8902 61.6401 68.1802 59.4801C66.7402 55.1701 67.8402 48.3901 67.2902 41.9201C66.7402 35.4501 64.4702 29.3101 56.4402 26.2701C55.7801 26.0848 55.1769 25.7368 54.686 25.2582C54.1951 24.7795 53.8321 24.1853 53.6302 23.5301C52.3102 19.9201 51.5002 18.2001 47.5302 18.0801V18.0801ZM57.2602 80.4601C56.6577 82.5921 55.3877 84.4748 53.6364 85.8319C51.8852 87.1889 49.7451 87.9488 47.5302 88.0001C45.3101 87.9475 43.1656 87.1836 41.4122 85.8208C39.6588 84.4581 38.3892 82.5685 37.7902 80.4301H57.2602V80.4601Z"
        fill={`var(${fill})`}
      />
    </svg>
  );
}
export default BelledIcon;

// BelledIcon.
