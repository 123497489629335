import {
  faBug,
  faFilm,
  faFlag,
  faHistory,
  faImages,
  faPhotoVideo,
  faSave,
  faSignOutAlt,
  faStream,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form } from "react-bootstrap";
import MediaQuery from "react-responsive";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useStore from "src/hooks/useStore";
import useTheme from "src/hooks/useTheme";
import Typewriter from "typewriter-effect";
import MessageSideBar from "../../NestedRouteOnDashBodr/Message/MessageSideBar/MessageSideBar";

export const RightColButtons = () => {
  const { handleLogout } = useStore();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, dynamicPath, dynamicLife] = pathname.split("/");

  const isMessageShow = pathname.split("/").includes("message");
  const { currentTheme, handleThemeChange } = useTheme();

  return (
    <>
      <div className="mb-4">
        <div className="row">
          <div className="col-12 col-lg-12 stickyParts  z-index-3">
            <div className="LookMeWhiteIcon box-shadow nav-bg-opacity py-2 ps-3 d-block">
              <div className="d-flex ">
                <div
                  className="LookMeButtonTypeIcon  "
                  onClick={() => navigate("/dashboard")}
                >
                  <img
                    className="img-fluid"
                    src="/images/logo.svg"
                    alt="logo"
                  />
                </div>
                <Link to="/life/family-life">
                  <div className="type-writer-text-box">
                    <h5 className="mb-0">UVIOM</h5>
                    <div className="lh-1 logo-slogan w-100 d-block overflow-hidden type-writer-text text-nowrap">
                      <Typewriter
                        options={{
                          strings: "Your Vision Is Our Mission",
                          autoStart: true,
                          loop: true,
                        }}
                      ></Typewriter>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          {pathname.includes("/message") && (
            <MediaQuery minWidth={992}>
              {isMessageShow ? <MessageSideBar /> : null}
            </MediaQuery>
          )}

          <MediaQuery maxWidth="992px">
            {!pathname.includes("public-post") && (
              <div className="col-lg-12">
                <Link
                  aria-labelledby="About"
                  to={`/${dynamicPath}/${dynamicLife}/profile`}
                >
                  <div className="LookMeTypeButton box-shadow justify-content-start ps-5 mt-3">
                    <h6 className="mb-0 d-flex align-items-center ">
                      <FontAwesomeIcon className="me-2" icon={faFlag} /> Profile
                    </h6>
                  </div>
                </Link>
              </div>
            )}
          </MediaQuery>
          <div className="col-lg-12">
            <Link
              aria-labelledby="About"
              to={`/${dynamicPath}/${dynamicLife}/about`}
            >
              <div className="LookMeTypeButton box-shadow justify-content-start ps-5 mt-3">
                <h6 className="mb-0 d-flex align-items-center ">
                  <FontAwesomeIcon className="me-2" icon={faFlag} /> About
                </h6>
              </div>
            </Link>
          </div>
          <div className="col-lg-12">
            <Link
              aria-labelledby="Photos Gallery"
              to={`/${dynamicPath}/${dynamicLife}/photo-gallery`}
            >
              <div className="LookMeTypeButton box-shadow justify-content-start ps-5 mt-3  ">
                <h6 className="mb-0 d-flex align-items-center ">
                  <FontAwesomeIcon className="me-2" icon={faImages} /> Photos
                  Gallery
                </h6>
              </div>
            </Link>
          </div>
          <div className="col-lg-12">
            <Link
              aria-labelledby="Videos Gallery"
              to={`/${dynamicPath}/${dynamicLife}/video-gallery`}
            >
              <div className="LookMeTypeButton box-shadow justify-content-start ps-5 mt-3  ">
                <h6 className="mb-0 d-flex align-items-center ">
                  <FontAwesomeIcon className="me-2" icon={faPhotoVideo} />
                  Videos Gallery
                </h6>
              </div>
            </Link>
          </div>
          {!pathname.includes("public-post") && (
            <div className="col-lg-12">
              <Link
                aria-labelledby="Friends"
                to={`/${dynamicPath}/${dynamicLife}/friends`}
              >
                <div className="LookMeTypeButton box-shadow justify-content-start ps-5 mt-3  ">
                  <h6 className="mb-0 d-flex align-items-center ">
                    <FontAwesomeIcon className="me-2" icon={faUserFriends} />{" "}
                    Friends
                  </h6>
                </div>
              </Link>
            </div>
          )}
          <div className="col-lg-12">
            <Link
              aria-labelledby="Recent Post"
              to={`/${dynamicPath}/${dynamicLife}`}
            >
              <div className="LookMeTypeButton box-shadow justify-content-start ps-5 mt-3  ">
                <h6 className="mb-0 d-flex align-items-center ">
                  <FontAwesomeIcon className="me-2" icon={faHistory} /> Recent
                  Post
                </h6>
              </div>
            </Link>
          </div>
          {!pathname.includes("public-post") && (
            <div className="col-lg-12">
              <Link
                aria-labelledby="My Post"
                to={`/${dynamicPath}/${dynamicLife}/my-post`}
              >
                <div className="LookMeTypeButton box-shadow justify-content-start ps-5 mt-3 ">
                  <h6 className="mb-0 d-flex align-items-center ">
                    <FontAwesomeIcon className="me-2" icon={faStream} /> My Post
                  </h6>
                </div>
              </Link>
            </div>
          )}
          <div className="col-lg-12">
            <Link
              aria-labelledby="Save Post"
              to={`/${dynamicPath}/${dynamicLife}/save-post`}
            >
              <div className="LookMeTypeButton box-shadow justify-content-start ps-5 mt-3 ">
                <h6 className="mb-0 d-flex align-items-center ">
                  <FontAwesomeIcon className="me-2" icon={faSave} /> Save Post
                </h6>
              </div>
            </Link>
          </div>

          <div className="col-lg-12">
            <Link to={`/${dynamicPath}/${dynamicLife}/reported-post`}>
              <div className="LookMeTypeButton box-shadow justify-content-start ps-5 mt-3 ">
                <h6 className="mb-0 d-flex align-items-center ">
                  <FontAwesomeIcon className="me-2" icon={faBug} /> Reported
                  Post
                </h6>
              </div>
            </Link>
          </div>

          <div className="col-lg-12">
            <Link
              aria-labelledby="Best Time"
              to={`/${dynamicPath}/${dynamicLife}/post-best-time`}
            >
              <div className="LookMeTypeButton box-shadow justify-content-start ps-5 mt-3 ">
                <h6 className="mb-0 d-flex align-items-center ">
                  <FontAwesomeIcon className="me-2" icon={faFilm} /> Best Time
                </h6>
              </div>
            </Link>
          </div>

          {/* <div className="col-lg-12">
            <Link to={`/${dynamicPath}/${dynamicLife}/groups`}>
              <div className="LookMeTypeButton box-shadow justify-content-start ps-5  mt-3">
                <h6 className="mb-0 d-flex align-items-center ">
                  <FontAwesomeIcon className="me-2" icon={faUsers} /> Groups
                </h6>
              </div>
            </Link>
          </div>  

          <div className="col-lg-12">
            <Link to={`/${dynamicPath}/${dynamicLife}/save-post`}>
              <div className="LookMeTypeButton box-shadow justify-content-start ps-5 mt-3 ">
                <h6 className="mb-0 d-flex align-items-center ">
                  <FontAwesomeIcon className="me-2" icon={faDesktop} /> Look Me
                </h6>
              </div>
            </Link>
          </div> */}
          <div className="col-lg-12 ">
            <div>
              <div className="LookMeTypeButton box-shadow justify-content-start ps-5 mt-3 py-2 ">
                <h6 className="mb-0">
                  <Form.Check
                    aria-labelledby="Switch Website Theme"
                    onChange={handleThemeChange}
                    checked={currentTheme === "dark"}
                    type="switch"
                    label={
                      <span className="d-inline-block lh-base">
                        {currentTheme === "dark"
                          ? "Switch To Light"
                          : "Switch To Dark"}
                      </span>
                    }
                    id="disabled-custom-switch"
                  />
                </h6>
              </div>
            </div>
          </div>
          <div className="col-lg-12 ">
            <div
              tabIndex={0}
              onClick={() => handleLogout(navigate)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleLogout(navigate);
                }
              }}
            >
              <div className="LookMeTypeButton box-shadow justify-content-start ps-5 mt-3 py-2 ">
                <h6 className="mb-0 d-flex align-items-center ">
                  <FontAwesomeIcon className="me-2" icon={faSignOutAlt} />{" "}
                  Logout
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
