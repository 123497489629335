import React from "react";
import { Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "./RightCol.css";
import { RightColButtons } from "./RightColButtons";

export const RightCol = () => {
  let { pathname } = useLocation();

  return (
    <Col className="d-none  d-lg-block stickyContainer  rightCol" md={3} lg={3}>
      <div
        id="navigation"
        className="stickyParts pb-4 vh-100 scroll-y no-scrollbar px-1"
      >
        <RightColButtons timlineUrl={pathname} />
      </div>
    </Col>
  );
};
