/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import { hex_to_emoji_text } from "src/utilities/utilities";
import "../Common/Common.css";
import { URL_REGEX } from "../utils/life_utilities";

export const SeeMore = ({ children }) => {
  //   const modifyChild= <>dangerouslySetInnerHTML={{__html: children}}</>
  const text = children?.toString();
  const [isReadMore, setIsReadMore] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  var yourString = text?.substring();

  var maxLength = 210;
  var trimmedString = yourString?.substr(0, maxLength);
  //re-trim if we are in the middle of a word
  trimmedString = trimmedString?.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  );
  useEffect(() => {
    if (text.length > maxLength) {
      setIsReadMore(true);
    }
  }, []);

  const text_words = text?.split(" ") || [];
  const trimmedString_words = trimmedString?.split(" ") || [];

  return (
    <div className="seeMoreText" aria-labelledby={text_words}>
      {/* {isReadMore ? text.slice(0, 210 ) : text}
            {text.length > 210 &&
                <span onClick={toggleReadMore}>
                    {isReadMore ? '...see more' : ' ...show less'}
                </span>
            } */}
      {isReadMore
        ? trimmedString_words.map((word, index) => {
            return word.match(URL_REGEX) ? (
              <span key={index}>
                <a
                  className="post_url_highlight"
                  href={word}
                  target="_blank"
                  rel="noreferrer"
                >
                  {word}
                </a>{" "}
              </span>
            ) : (
              word + " "
            );
          })
        : text_words.map((word, index) => {
            return word.match(URL_REGEX) ? (
              <span key={index}>
                <a
                  className="post_url_highlight"
                  href={word}
                  target="_blank"
                  rel="noreferrer"
                >
                  {word}
                </a>{" "}
              </span>
            ) : (
              word + " "
            );
          })}
      {text?.length > maxLength && (
        <span
          tabIndex="0"
          aria-label="See More"
          onClick={toggleReadMore}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              toggleReadMore();
            }
          }}
          className="pointer logoColor"
        >
          {isReadMore ? " ...see more" : " ...show less"}
        </span>
      )}
    </div>
  );
};
