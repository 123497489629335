/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef } from "react";
import BestTimeSection from "../BestTimeSection/BestTimeSection";
import NewsFeedPost from "../NewsFeedPost/NewsFeedPost";
import "./ScroolableNewsSeciton.css";
import { StorePost } from "./StorePost";

import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewsFeedPost,
  setCurrentPage,
  setHasMoreFalse,
  setPageOffset,
  setTempHasMore,
  // spliceData,image.png
} from "src/features/life/newsFeedSlice";

import NewsFeedSkeleton from "src/Common/Placeholder/NewsFeedSkeleton";

import { Helmet } from "react-helmet";

const ScroolableNewsSection = () => {
  const lastPostRef = useRef();
  const containerRef = useRef();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const timeline_name = pathname?.split("/")[2]?.split("-")[0];

  const news_feed_post = useSelector((state) => state.newsFeedPost);
  const postList = news_feed_post[`${timeline_name}_post`] || [];

  const has_more = news_feed_post[`${timeline_name}_hasMore`];
  const current_page = news_feed_post[`${timeline_name}_current_page`];
  const page_offset = news_feed_post[`${timeline_name}_page_offset`];

  const { isLoading, error, changeData } = news_feed_post;

  useEffect(() => {
    if (postList?.length < 1 || has_more) {
      dispatch(getNewsFeedPost(timeline_name));
    }

    dispatch(setTempHasMore(timeline_name));

    return () => {
      dispatch(setPageOffset({ timeline_name, page_offset: window.scrollY }));
      dispatch(setHasMoreFalse(timeline_name));
    };
  }, [current_page, dispatch]);

  useEffect(() => {
    // if (lastPostRef.current && postList?.length > 25) {
    //   const offset = lastPostRef.current.offsetTop;
    //   window.scrollTo({
    //     top: parseInt(offset - 100),
    //     left: 0,
    //     behavior: "instant",
    //   });
    // }
    // if (postList?.length > 50) {
    //   setTimeout(() => {
    //     dispatch(spliceData(timeline_name));
    //   }, 2000);
    // }
  }, [changeData]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    window.scrollTo({ top: page_offset, left: 0, behavior: "instant" });
  }, []);

  const handleNextPage = () => {
    dispatch(setCurrentPage(timeline_name));
  };

  return (
    <div className="MiddlePartScrool" ref={containerRef}>
      {!pathname.includes("/public-life") && (
        <>
          <BestTimeSection />
          <StorePost />
        </>
      )}
      <Helmet>
        <title>UVIOM • {timeline_name} life</title>
      </Helmet>
      <div id="mainpart">
        {postList && postList?.length > 0 && (
          <InfiniteScroll
            dataLength={postList.length} //This is important field to render the next data
            next={handleNextPage}
            hasMore={has_more}
            scrollThreshold={"200px"}
          >
            <div className=" my-3 lifePageView  ">
              {postList.map((post, index) => (
                <NewsFeedPost
                  key={post?.id}
                  post={post}
                  lastPostRef={index === 24 ? lastPostRef : undefined}
                />
              ))}
            </div>
          </InfiniteScroll>
        )}
      </div>
      {isLoading && (
        <div className="my-3 lifePageView">
          <NewsFeedSkeleton />
          <NewsFeedSkeleton />
        </div>
      )}

      {error && <p className="text-center text-danger">{error.error}</p>}
    </div>
  );
};

export default ScroolableNewsSection;
