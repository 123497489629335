import React from 'react'
import DataTable from "react-data-table-component";

export const OrderDataTable = ({columns,filteredItems,currentTheme,totalRows,handlePerRowsChange,handlePageChange,perPage,subHeaderComponentMemo,isLoading}) => {
  return (
    <DataTable
    columns={columns}
    data={filteredItems}
    responsive
    theme={currentTheme}
    paginationRowsPerPageOptions={[]}
    pagination
    paginationServer
    paginationTotalRows={totalRows}
    onChangeRowsPerPage={handlePerRowsChange}
    onChangePage={handlePageChange}
    paginationPerPage={perPage}
    subHeader
    subHeaderComponent={subHeaderComponentMemo}
    persistTableHead
    progressPending={isLoading}

/>
  )
}
