import React from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import JobInformation from "../../Components/Common/JobInformation";
import { createJobPostUrl } from "../../utilities/job_commonUrl";
import postData from "../../api_call/postApiCall";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";

const JobPostPreview = () => {
  const { state } = useLocation();
  const data = { ...state.jobPostData };
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const handleSubmit = () => {
    delete data.job_category_name;
    delete data.job_subcategory_name;
    delete data.division_name;
    delete data.district_name;
    const postDataArg = {
      url: createJobPostUrl,
      data,
      setIsLoading,
      setError,
      setSuccess,
      callAfterSuccess,
    };
    postData(postDataArg);
  };

  const callAfterSuccess = () => {
    navigate("/Job/company/posted-job");
  };

  return (
    <div>
      {loading && <BubbleLoader />}

      <h6 className="jobApplyTitle ms-2">Preview Post</h6>

      <div className="jobInformation">
        <JobInformation isPreview={true} data={state.jobPostData} />
        <div style={{ fontSize: "0.85rem" }}>
          <p className="text-center text-danger">{error}</p>
          <p className="text-center ">{success}</p>
        </div>
        <div className="d-flex justify-content-end mt-4">
          {/* <button onClick={()=>navigate(-1)} type="button" className="companyProfileBtn px-5">Edit</button> */}
          <button onClick={handleSubmit} className="companyProfileBtn px-5 ">
            Post
          </button>
          
        </div>
      </div>

      <br />
      <br />
    </div>
  );
};

export default JobPostPreview;
