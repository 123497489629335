import React from "react";
import { Placeholder } from "react-bootstrap";
import styled from "styled-components";

const SingleVideoCardSkeleton = () => {
  return (
    <div>
      <Placeholder animation="wave">
        <div className="d-flex gap-2 align-items-center">
          <Placeholder
            as="div"
            style={{ ...avatarStyle }}
            xs="12"
          ></Placeholder>
          <Placeholder as="div" xs="4"></Placeholder>
        </div>
        <ImageStyle />
        <Placeholder as="div" xs="12"></Placeholder>
        <span className="d-flex">
          <Placeholder as="div" xs="3"></Placeholder>
          <Placeholder as="div" xs="2"></Placeholder>
        </span>
      </Placeholder>
    </div>
  );
};

export default SingleVideoCardSkeleton;

const avatarStyle = { height: "50px", width: "50px", borderRadius: "50%" };

const ImageStyle = styled.div`
  border-radius: 0.5rem;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  height: 10rem;
  width: 100%;
  background-color: var(--bg-secondary);
`;
