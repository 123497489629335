import React from "react";
import { Container, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { BrandCard } from "./BrandCard";
import { Navigation, Autoplay, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

export const Brand = ({ brand_loading, brand_slider }) => {
  const navigate = useNavigate();
  let { path } = useLocation();

  const handleJobClick = () => {
    navigate({
      pathname: `${path}/allBrand`,
      state: {
        allBrandData: brand_slider,
      },
    });
  };

  return (
    <>
      <Container
        fluid
        className="py-2 my-2 box-shadow px-3 category-slider Brand "
      >
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="py-2">Brand</h4>
          </div>
          <div>
            <div className="py-2 pointer" onClick={handleJobClick}>
              View All
            </div>
          </div>
        </div>
        <Row className="">
          <div className="col-12">
            <Swiper
              slidesPerView={"auto"}
              centeredSlides={false}
              spaceBetween={15}
              autoplay={true}
              pagination={{
                clickable: true,
              }}
              navigation={false}
              modules={[Scrollbar, Navigation, Autoplay]}
              className="mySwiper category-top-slider"
            >
              {brand_slider &&
                brand_slider.length > 0 &&
                brand_slider.map((item, index) => (
                  <SwiperSlide key={index}>
                    <BrandCard item={item} extraClass="" />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </Row>

        <></>
      </Container>
    </>
  );
};
