import { useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import styled from "styled-components";
import { RightCol } from "./RightCol/RightCol";

import { LinkContainer } from "./RightCol/RightCol";

function OffCanvasNav({ show, handleClose }) {
  return (
    <Offcanvas
      className="view-profile-offcanvas scroll-y no-scrollbar"
      placement="start"
      show={show}
      onHide={handleClose}
    >
      <Wrapper>
        <Offcanvas.Header className="pb-0 pt-1 justify-content-end ">
          <div
            onClick={() => handleClose()}
            role="button"
            className="display-6 lh-1 text-orange"
          >
            &times;
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <RightCol />
        </Offcanvas.Body>
      </Wrapper>
    </Offcanvas>
  );
}
export default OffCanvasNav;

const Wrapper = styled.div`
  & .btn-close {
    /* filter: ; */
  }

  & .offcanvas-body {
    padding-block: 0;
    padding-inline: 1rem;
  }

  & .type-writer-text-box h5 {
    font-size: 1rem;
  }
  & .type-writer-text-box .Typewriter__wrapper {
    font-size: 92%;
  }

  & ${LinkContainer} {
    grid-template-columns: 1fr;
  }
`;
