import { configureStore } from "@reduxjs/toolkit";
import jobLocalDataReducer from "src/features/job/jobLocalDataSlice";
import jobProfileReducer from "src/features/job/jobProfileSlice";
import jobHomeDataReducer from "src/features/job/jobHomeDataSlice";
import newsFeedReducer from "src/features/life/newsFeedSlice";
import viewDataReducer from "src/features/view/viewDataSlice";
import lifeCommonDataReducer from "src/features/life/lifeCommonDataSlice";
import ubizzDataReducer from "src/features/bizz/UBizzDataSlice";
import messengerDataReducer from "src/features/life/messengerSlice";
import { api } from "./services/api";

export const store = configureStore({
  reducer: {
    jobLocalData: jobLocalDataReducer,
    jobProfile: jobProfileReducer,
    jobHomeData: jobHomeDataReducer,
    newsFeedPost: newsFeedReducer,
    viewData: viewDataReducer,
    lifeData: lifeCommonDataReducer,
    ubizzData: ubizzDataReducer,
    messengerData: messengerDataReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});
