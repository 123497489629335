import React, { useEffect, useState } from "react";
import { PostApiCalls } from "src/Common/apiCall/PostApiCalls";

import { isEmpty } from "lodash";
import { InputField } from "../Components/InputField";
import { BubbleScale } from "src/Common/BubbleScale";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";

export const StoringInputs = ({ storingInputsArg }) => {
  const { dataReload, setIsEditableFalse, singleInfo, componentMainUrl } =
    storingInputsArg;

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setformData] = useState({
    sport_name: "",
    status: 1,
    friend_status: 1,
    family_status: 1,
    professional_status: 1,
  });
  useEffect(() => {
    if (!isEmpty(singleInfo)) {
      setformData({
        id: singleInfo.id,
        sport_name: singleInfo.sport_name,
        status: singleInfo.status.public_status,
        friend_status: singleInfo.status.friend_status,
        family_status: singleInfo.status.family_status,
        professional_status: singleInfo.status.professional_status,
      });
    }
  }, [singleInfo]);

  const inputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...formData };
    newData[name] = value;
    setformData(newData);
  };
  // console.log("edit id ", formData && formData.id);
  const updateFormFunc = (e) => {
    e.preventDefault();
    const formArg = {
      formData: formData,
      Url:
        formData && formData.id
          ? componentMainUrl
          : `${componentMainUrl}/store`,
      setIsLoading: setIsLoading,
      dataReload: dataReload,
      setIsEditableFalse: setIsEditableFalse,
      itemId: formData && formData.id ? formData.id : "",
    };
    PostApiCalls(formArg);
  };

  if (isLoading) {
    return <DottedCircleSpinner />;
  }
  return (
    <form onSubmit={updateFormFunc}>
      <InputField
        inputPlaceholder="sport_name"
        textInputName="sport_name"
        textInputValue={formData.sport_name ? formData.sport_name : ""}
        changeHandle={inputHandleChange}
      />

      <div className="py-3 mainProfileBtnDiv">
        <button
          onClick={setIsEditableFalse}
          className="mainProfileBtn btnCandel me-3"
        >
          Cancel
        </button>
        <button
          className={`mainProfileBtn btnSave  ${isLoading ? "disabled" : ""} `}
        >
          {formData && formData.id ? "Update" : "Save"}
        </button>
      </div>
    </form>
  );
};
