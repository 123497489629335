import { Container } from "react-bootstrap";
import MyProfile from "./../components/MyProfile";

function Profile() {
  return (
    <div id="wrapper">
      <div className="py-4" />
      <Container>
        <MyProfile />
      </Container>
      <div className="py-4" />
    </div>
  );
}
export default Profile;
