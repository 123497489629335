import React from "react";
import { Col, Form} from "react-bootstrap";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, InputGroup } from "react-bootstrap";
import "./file_and_text_input.css"

const FileAndTextInputBox = ({
  label,
  handler = () => {},
  placeholder,
  textInputName,
  textInputValue,
  fileInputFrontValue,
  fileInputFrontName,
  fileInputBackValue,
  fileInputBackName,
    singleFile,
    filePlaceholder="F/pic"
}) => {
  return (
    <Form.Group
      as={Col}
      className={`mb-3 double-file-input-box`}
      controlId=""
    >
      {/* <Form.Label
        className={`d-none d-md-block InputWithLabelLabel seller-reg-input-label`}
        column
        sm="3"
      >
        {label}
      </Form.Label> */}
     
        <Form.Label className="">{label}</Form.Label>
        <InputGroup className="file-and-text-input-group py-0" hasValidation>
          <FormControl
            onChange={handler}
            value={textInputValue}
            aria-label="search"
            name={textInputName}
            placeholder={placeholder}
            className="file-and-text-input-field py-0"
            type="text"
            required
          />
          <InputGroup.Text className="profileVideoSearchLabel">
            <label
              htmlFor={"file_front"}
              className="mainProfileInput me-2 secondary-bg"
              id="file-label"
            >
              <span className="file-text-btn">
                {filePlaceholder}
                <FontAwesomeIcon
                  className={`primary-text ms-1 ms-lg-2 `}
                  size="lg"
                  icon={faPlusCircle}
                ></FontAwesomeIcon>
              </span>
            </label>
            <input
              accept="image/png, image/jpg"
              type="file"
              value={fileInputFrontValue}
              name={fileInputFrontName}
              id="file_front"
              className="file-input"
              onChange={handler}
            />
            {/* national file input back  */}
            {!singleFile && (
              <>
                <label
                  htmlFor={"file_back"}
                  className="mainProfileInput secondary-bg"
                  id="file-label"
                >
                  <span className="file-text-btn">
                    B/pic
                    <FontAwesomeIcon
                      className={`primary-text ms-1 ms-lg-2 `}
                      size="lg"
                      icon={faPlusCircle}
                    ></FontAwesomeIcon>
                  </span>
                </label>
                <input
                  accept="image/png, image/jpg"
                  type="file"
                  value={fileInputBackValue}
                  name={fileInputBackName}
                  id="file_back"
                  className="file-input"
                  onChange={handler}
                />
              </>
            )}
          </InputGroup.Text>
        </InputGroup>
     
    </Form.Group>
  );
};

export default FileAndTextInputBox;
