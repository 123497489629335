import React from 'react';
import OrderItemCard from '../Common/OrderItemCard';

const ActiveOrder = () => {
    return (
        <div>
             <OrderItemCard status="Processing"/>
            <OrderItemCard status="Processing"/>
            <OrderItemCard status="On The Way"/>
            <OrderItemCard status="Delivered"/>
        </div>
    );
};

export default ActiveOrder;