import React from "react";
import { Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { RightColButtons } from "./RightColButtons";

export const RightCol = ({ ChatContentAction }) => {
  let { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Col
      id="navigation"
      className="d-none d-lg-block stickyContainer rightCol"
      md={3}
      lg={3}
    >
      <div className="col-6 col-lg-12 stickyParts">
        <div className="LookMeWhiteIcon box-shadow nav-bg-opacity py-2 ps-3  d-block  z-index-1">
          <div className="d-flex">
            <div
              className="LookMeButtonTypeIcon  "
              onClick={() => navigate("/dashboard")}
            >
              <img className="img-fluid" src="/images/logo.svg" alt="logo" />
            </div>
            <Link to="/life/family-life">
              <div className="type-writer-text-box">
                <h5 className="mb-0">UVIOM</h5>
                <div className="lh-1 logo-slogan overflow-hidden type-writer-text text-nowrap">
                  <Typewriter
                    options={{
                      strings: "Your Vision Is Our Mission",
                      autoStart: true,
                      loop: true,
                    }}
                  ></Typewriter>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <RightColButtons
        timlineUrl={pathname}
        ChatContentAction={ChatContentAction}
      />
    </Col>
  );
};
