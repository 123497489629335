import React from "react";

const avatarStyle = {
  borderRadius: "100%",
  display: "inline-block",
};

const Avatar = ({ imgUrl, size = "25", className, userName = "user" }) => {
  return (
    <div style={{ width: size, height: size }} className={className}>
      <img
        style={{ height: "100%", width: "100%", ...avatarStyle }}
        src={imgUrl}
        alt={userName}
        onError={(e) => (e.target.src = "https://i.ibb.co/MprdZS6/user.png")}
        className={`${className} img img-fluid`}
      />
    </div>
  );
};

export default Avatar;
