import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import useCartStore from "../../hooks/useCartStore";
import { deleteWishlistUrl } from "../../utilities/CommonUrl";
import CartCheckout from "./CartCheckout";
import { CartItemsBox } from "./CartItemsBox";
import "./myCart.css";

const MyCart = () => {
  const { removeFromCart } = useCartStore();
  const [showCheckout, setShowCheckout] = useState(false);
  const { state, deliveryCost } = useCartStore();
  const { allTotal, cartItems } = state;

  const location = useLocation();
  useEffect(() => {
    if (location?.state?.openCheckout) {
      setShowCheckout(true);
    } else {
      setShowCheckout(false);
    }
  }, [location]);

  return (
    <Container fluid style={{ minHeight: "70vh" }}>
      <h6 className="py-3">My Cart</h6>
      <Row>
        <Col xs="12" lg="6" xl="7">
          {/* {cart.map((item) => (
            <CartItem
              key={item.id}
              data={item}
              handleRemove={handleRemove}
              handleQuantity={handleQuantity}
            />
          ))}
           {cart.length === 0 && (
              <h6 className="text-center mt-4">Your Cart is Empty</h6>
            )} */}
          <CartItemsBox />
        </Col>
        <Col xs="12" lg="6" xl="5">
          <div className="p-3 my-3 py-4 secondary-bg">
            <h6>{cartItems.length} Selected Item</h6>
            {/* <p>Total Price: {allTotal}Tk</p> */}
            <div className="d-flex justify-content-between px-lg-2">
              <div>Item Total:</div>
              <div>{allTotal}Tk</div>
            </div>
            <div className="d-flex justify-content-between px-lg-2">
              <div>Delivery Charge:</div>
              <div>{deliveryCost} TK</div>
            </div>
            <br />
            <div className="d-flex justify-content-between px-lg-2">
              <div>Total</div>
              <div>{allTotal + deliveryCost} TK</div>
            </div>
            <br />
            <div className="text-center">
              <button
                className="eco-btn cart-checkout-btn fw-500 rounded-3 primary-text "
                onClick={() => setShowCheckout(true)}
              >
                Checkout
              </button>
            </div>
          </div>
          {showCheckout && <CartCheckout />}
        </Col>
      </Row>
    </Container>
  );
};

export default MyCart;
