import { Container, Row, Col } from "react-bootstrap";

// Using from ride because they look smiler
import ContactForm from "src/Projects/Ride/components/ContactForm";
import ContactInfo from "src/Projects/Ride/components/ContactInfo";

function Contact() {
  return (
    <div id="wrapper">
      <div className="py-4" />
      <Container>
        <h4 className="pb-2">Contact Us</h4>
        <Row>
          <Col lg={6}>
            <ContactForm />
          </Col>

          <Col lg={6}>
            <ContactInfo />
          </Col>
        </Row>
      </Container>
      <div className="py-5" />
    </div>
  );
}
export default Contact;
