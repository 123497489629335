function PreviousWorkDetails() {
  return (
    <div className="border rounded p-3 ">
      <div className="d-flex flex-wrap justify-content-between">
        <p className="mb-1">
          <span className="fw-500">Project Title: </span>
          Finance Website Design
        </p>
        <p className="">
          Price
          <span className="fw-500"> $200</span>
        </p>
      </div>

      <div className="d-md-flex gap-2">
        <p className="mb-1 fw-500 text-nowrap">Project Details: </p>
        <p className="opacity-75">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Odio fusce
          integer vestibulum eget quisque faucibus a. Id diam, aliquet pulvinar
          lacus non, integer. Egestas tortor eget eget sed scelerisque vitae
          suscipit sapien vel. Aliquam magna gravida hac tortor vitae eget
          blandit id nisi. Convallis dignissim tempus habitasse enim consequat.
          Fermentum sit commodo nisl, tristique. Magna nullam tristique cursus
          eu sagittis, eget pellentesque.
        </p>
      </div>

      <h6 className="mt-3 mb-4">Problem Statement</h6>
      <div>
        <img
          className="img-fluid"
          src="https://i.ibb.co/wdTsXkn/casefile1.jpg"
          alt=""
        />
      </div>

      <h6 className="mt-3 mb-4">Problem Statement</h6>
      <div>
        <img
          className="img-fluid"
          src="https://i.ibb.co/TKjxSr0/casefile2.jpg"
          alt=""
        />
      </div>
      <h6 className="mt-3 mb-4">User flow</h6>
      <div>
        <img
          className="img-fluid"
          src="https://i.ibb.co/fCH1L40/casefile3.jpg"
          alt=""
        />
      </div>

      <h6 className="mt-3 mb-4">Design</h6>
      <div>
        <img
          className="img-fluid"
          src="https://i.ibb.co/9WQThLS/casefile4.jpg"
          alt=""
        />
      </div>
    </div>
  );
}
export default PreviousWorkDetails;
