import React, { useEffect, useState, useRef, memo } from "react";
import PlayerController from "./PlayerController";
import ReactPlayer from "react-player";
import useIsInViewport from "src/hooks/useIsInViewport";
import styled from "styled-components";
import { formateMinute } from "src/utilities/utilities";
import screenfull from "screenfull";
import { useMemo } from "react";

let count = 0;

const VideoPlayer = ({
  url,
  thumbnail,
  width = "100%",
  height = "100%",
  aspectRatio = "16/9",
  autoPlay,
  control = false,
  isPlaying = false,
}) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const controllerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const [show, setShow] = useState({
    playing: true,
    muted: false,
    played: 0,
    seeking: false,
    volume: 0.99,
    showPlayBtn: true,
    isPiP: false,
    buffering: true,
    isPause: true,
  });
  const { playing = false, muted, played, volume } = show;

  const isInViewport = useIsInViewport(videoRef);

  const handleVideoPlayPause = () => {
    setShow((prevState) => ({
      ...prevState,
      playing: !prevState.playing,
    }));

    count = 0;
  };

  const handleVideoEnd = () => {
    setShow((prevState) => ({
      ...prevState,
      playing: false,
      played: 0,
    }));
    controllerRef.current.style.display = "block";
  };

  const handleOnBuffer = () => {
    setShow((prevState) => ({
      ...prevState,
      buffering: true,
    }));
  };
  const handleBufferEnd = () => {
    setShow((prevState) => ({ ...prevState, buffering: false }));
  };

  const handleOnPause = () => {
    setShow((prevState) => ({
      ...prevState,
      playing: false,
      isPause: true,
    }));
  };
  const handleOnPlay = () => {
    setShow((prevState) => ({
      ...prevState,
      playing: true,
      isPause: false,
    }));
  };

  const handlePreviewClick = () => {
    setShow((prevState) => ({ ...prevState, playing: true }));
  };

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };

  const handleMouseMove = () => {
    if (show.playing) {
      controllerRef.current.style.display = "block";
      count = 0;
    } else if (!thumbnail) {
      controllerRef.current.style.display = "block";
    }
  };

  const handleProgress = (changeState) => {
    const played = changeState.played;
    if (!show.seeking) {
      setShow({ ...show, played });
    }
    if (count >= 2) {
      controllerRef.current.style.display = "none";
      count = 0;
    }
    if (controllerRef.current.style.display === "block") {
      count += 1;
    }
  };

  const handleSeekMouseDown = () => {
    setShow({ ...show, seeking: true });
  };
  const handleSeekChange = (e) => {
    setShow({ ...show, played: parseFloat(e.target.value) });
  };

  const handleVolumeChange = (e) => {
    if (parseFloat(e.target.value) === 0) {
      setShow({ ...show, volume: parseFloat(e.target.value), muted: true });
    } else {
      setShow({ ...show, volume: parseFloat(e.target.value), muted: false });
    }
  };

  const handleSeekMouseUp = (e) => {
    setShow({ ...show, seeking: false });
    playerRef.current.seekTo(parseFloat(e.target.value));
  };

  const toggleFullScreen = () => {
    screenfull.toggle(playerContainerRef.current);
  };

  const handleToggleMuted = () => {
    setShow({ ...show, muted: !show.muted });
  };

  const currentTime = playerRef.current
    ? playerRef.current.getCurrentTime()
    : "00:00";
  const duration = playerRef.current
    ? playerRef.current.getDuration()
    : "00:00";

  const elapsedTime = useMemo(() => formateMinute(currentTime), [currentTime]);
  const totalDuration = useMemo(() => formateMinute(duration), [duration]);

  useEffect(() => {
    if (isInViewport === false) {
      setShow((prevState) => ({ ...prevState, playing: false }));
    } else if (isInViewport && autoPlay) {
      setShow((prevState) => ({ ...prevState, playing: true }));
    }
  }, [isInViewport, autoPlay]);

  useEffect(() => {
    if (isPlaying) {
      setShow((prevState) => ({ ...prevState, playing: true }));
    }
  }, [isPlaying]);

  return (
    <div
      className={`videoPlayerContainer`}
      style={{ width: width, height: height, aspectRatio: aspectRatio }}
      ref={videoRef}
      onMouseMove={handleMouseMove}
    >
      <VideoWrapper ref={playerContainerRef}>
        <ReactPlayer
          className="react-player"
          ref={playerRef}
          muted={muted}
          width={"100%"}
          height={"100%"}
          url={url}
          autoPlay={autoPlay}
          light={thumbnail ? thumbnail : false}
          config={{ file: { attributes: { playsInline: true } } }}
          volume={volume}
          playing={playing}
          controls={control}
          onProgress={handleProgress}
          stopOnUnmount={true}
          onEnded={handleVideoEnd}
          onBuffer={handleOnBuffer}
          onBufferEnd={handleBufferEnd}
          style={{ color: "black", backgroundColor: "#000000" }}
          onPlay={handleOnPlay}
          onPause={handleOnPause}
          onClickPreview={handlePreviewClick}
        />

        <PlayerController
          isBuffering={show.buffering}
          isMuted={show.muted}
          ref={controllerRef}
          playing={playing}
          played={played}
          volume={volume}
          handleVideoPlayPause={handleVideoPlayPause}
          handleRewind={handleRewind}
          handleFastForward={handleFastForward}
          onSeek={handleSeekChange}
          onSeekMouseDown={handleSeekMouseDown}
          onSeekMouseUp={handleSeekMouseUp}
          toggleFullScreen={toggleFullScreen}
          handleToggleMuted={handleToggleMuted}
          handleVolumeChange={handleVolumeChange}
          elapsedTime={elapsedTime}
          totalDuration={totalDuration}
        />
      </VideoWrapper>
    </div>
  );
};

export default memo(VideoPlayer);

const VideoWrapper = styled.div`
  position: relative;
  height: 100%;
  font-size: 0;
  overflow: hidden;
`;
