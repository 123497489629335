import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { storyCaptionUpdateUrl } from "src/Projects/Life/Common/CommonUrl";
import postData from "../apiCall/postData";
import "./bestTimeModal.css";
import Loader from "react-js-loader";

const CaptionUpdateModal = ({ show, handleClose, caption = "", storyId }) => {
  const [newCaption, setNewCaption] = useState(caption);
  const [isLoading, setIsLoading] = useState(false);

  const callAfterSuccess = () => {
    handleClose();
  };

  const handleSubmit = () => {
    const argData = {
      video_caption: newCaption,
      video_story_id: storyId,
    };
    const postDataArg = {
      data: argData,
      url: storyCaptionUpdateUrl,
      setIsLoading,
      callAfterSuccess,
    };
    postData(postDataArg);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="bestTime__update-modal"
    >
      {isLoading && (
        <div className={"item bubble-loader-center"}>
          <Loader
            type="bubble-scale"
            bgColor={"#f9a31a"}
            color={"#f9a31a"}
            size={80}
          />
        </div>
      )}
      <Modal.Header>
        <Modal.Title className="text-center">Update Caption</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Control
            onChange={(e) => setNewCaption(e.target.value)}
            value={newCaption}
            as="textarea"
            rows={4}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="secondary"
          className="bestTime__update_modal-saveBtn"
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CaptionUpdateModal;
