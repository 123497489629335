import { Link } from "react-router-dom";

import ride1 from "./../../assets/ride1.svg";
import ride2 from "./../../assets/ride2.svg";
import ride3 from "./../../assets/ride3.svg";
import ride4 from "./../../assets/ride1.svg";
import ride5 from "./../../assets/ride4.svg";
import ride6 from "./../../assets/ride5.svg";
import "./vehicleOptions.css";

const data = [
  { icon: ride1, cost: "0.9", wait: "0.40" },
  { icon: ride2, cost: "0.8", wait: "0.30" },
  { icon: ride3, cost: "1.0", wait: "1.20" },
  { icon: ride4, cost: "0.9", wait: "0.40" },
  { icon: ride5, cost: "1.0", wait: "1.30" },
  { icon: ride6, cost: "1.0", wait: "1.30" },
];

function VehicleOptions() {
  return (
    <div className="vehicleOptions">
      <p>Available Ride</p>

      <div className="vehicle__cards">
        {data.map((item, index) => (
          <Link key={index} to="/ride/findride/available">
            <div className="vehicle__card">
              <img src={item.icon} alt="" />
              <p>Cost: {item.cost}$/KM</p>
              <p>Wait: {item.wait} Min</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
export default VehicleOptions;
