import { Routes, Route, Navigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import LeftSidebar from "../components/LeftSidebar";
import Messages from "../components/Messages";
import Wallet from "../components/Wallet";
import MyPosts from "../components/MyPosts";
import ProfileInfo from "../components/ProfileInfo";
import ReceivedProject from "../components/ReceivedProject";
import ProjectCandidate from "../components/ProjectCandidate";
import RunningProject from "../components/RunningProject";
import Notifications from "../components/Notifications";
import AtaGlance from "../components/Common/AtaGlance";

const ataGlance = [
  { title: "Company", sub: "UVIOM" },
  { title: "Post Job", sub: "300+" },
  { title: "Received Project", sub: "300+" },
  {
    title: "Review",
    sub: (
      <>
        <FontAwesomeIcon icon={faStar} /> 5.0 (300+)
      </>
    ),
  },
];

function Profile() {
  return (
    <div id="wrapper">
      <Container>
        <div className="my-4" />
        <Row>
          <Col md={4} lg={3}>
            <LeftSidebar
              userImage="https://i.ibb.co/64VjKcF/person1.jpg"
              userName="Mr Client"
              userSub="Dhaka,Bangladesh"
            />
          </Col>
          <Col md={8} lg={9}>
            <AtaGlance stats={ataGlance} />
            <ClientRoutes />
          </Col>
        </Row>
        <div className="my-4" />
      </Container>
    </div>
  );
}
export default Profile;

const ClientRoutes = () => {
  return (
    <Routes>
      <Route path={`/messages`} element={<Messages />} />
      <Route path={`/wallet/*`} element={<Wallet />} />
      <Route path={`/posts`} element={<MyPosts />} />
      <Route path={`/profile-info`} element={<ProfileInfo />} />
      <Route path={`/receivedproject`} element={<ReceivedProject />} />
      <Route path={`/projectcandidate`} element={<ProjectCandidate />} />
      <Route path={`/runningorders`} element={<RunningProject />} />
      <Route path={`/notifications`} element={<Notifications />} />
      <Route path="*" element={<Navigate to="messages" replace />} />
    </Routes>
  );
};
