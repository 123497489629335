import React, { createContext } from "react";
import useBestTimeStoreGlobal from "src/hooks/useBestTimeStoreGlobal";

export const BestTimeContext = createContext();
const BestTimeStoreProvider = ({ children }) => {
  return (
    <BestTimeContext.Provider value={useBestTimeStoreGlobal()}>
      {children}
    </BestTimeContext.Provider>
  );
};

export default BestTimeStoreProvider;