import axios from "axios";
import ToastFunc from "src/Common/ToastFunc";
import Swal from "sweetalert2";
import { commentReplyLikePUrl, replyCommentLikeCountUrl, replyCommentRemoveUrl, showPostReplyCommentUrl, singleShowReplyComment } from '../../../../../Common/CommonUrl';
import { getLikeCount } from "../FirstStageComment/commentRowFunc";

export const showPostReplyComment = (data) => {
  axios.get(`${showPostReplyCommentUrl}/${data.comment_id}?page=${data.replyCommentPage}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then((res) => {
      var next_page = res.data.next_page_url;
      var responseData = res.data.data;
      if (next_page === null) {
        data.setreplyCommenListMore(false) //must  
      } else {
        data.setreplyCommenListMore(true) //must
      }

      if (data.previousDataReset) {
        if (Array.isArray(responseData)) {
          data.setData([...responseData]);
        } else if (typeof responseData == 'object' && responseData != null) {
          const resArray = []
          Object.entries(responseData).map(([key, value]) => resArray.push(value))
          data.setData(resArray);
        }
      } else {
        if (Array.isArray(responseData)) {
          data.setData([...data.preData, ...responseData]);
        } else if (typeof responseData == 'object' && responseData != null) {
          const resArray = [...data.preData]
          Object.entries(responseData).map(([key, value]) => resArray.push(value))
          data.setData(resArray);
        }
      }
    }).catch((err) => {
      console.log(err.message);
    });
}
export const replyCommentRemove = (arg) => {
  Swal.fire({
    title: 'Are you sure you want to delete this comment?',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Delete',
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      axios
      .post(`${replyCommentRemoveUrl}/${arg.replyCommentId}`, "",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )

      .then((res) => {
        if (res.data.status === 1) {
          // console.log("replyCommentId from api hit", arg.replyCommentId);
          const filterData = arg.replyCommenList.filter(
            (item) => item.id !== arg.replyCommentId
          )
          arg.setreplyCommenList(filterData)
        } else {
          console.log("err")

        }
      })
      .catch((err) => {
        console.log(err)
      });
    }
  })
 
}
export const secondStageCommentLike = (secondStageCommentArg) => {
  const replyCommentId = secondStageCommentArg.CommentId
  axios
    .post(`${commentReplyLikePUrl}/${replyCommentId}`, "",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
    .then((res) => {
      if (res.data.status === 1) {
        if (res.data.message === "Comment Reply Liked successfully") {
          secondStageCommentArg.setisColored("logoColorText")
          secondStageCommentArg.setcolorUpdate(true)
          getLikeCount(replyCommentLikeCountUrl, replyCommentId, secondStageCommentArg.setLikeCount,);

        } else {
          //   like remove section
          secondStageCommentArg.setisColored("")
          secondStageCommentArg.setcolorUpdate(false)
          getLikeCount(replyCommentLikeCountUrl, replyCommentId, secondStageCommentArg.setLikeCount,);

        }
      }
    })
    .catch((err) => {
      ToastFunc('error', 'Request Failed !')

    });

}
export const showReplyCommentEdit = (arg) => {
  axios.get(`${singleShowReplyComment}/${arg.replyCommentId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then((res) => {
      var responseData = res.data;
      // arg.setformData({"post_id":responseData.post_id,comment:responseData.comment});
      arg.setreplyCommentEditBoxInfo({ "firstStageCommentId": "", "secondStageCommentId": responseData.id, "post_id": responseData.post_id, "comment": responseData.comment_reply });
    }).catch((err) => {
      console.log(err.message);
    });

}

