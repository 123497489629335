import React from "react";
import { Col, Container } from "react-bootstrap";
import useBestTimeStore from "src/hooks/useBestTimeStore";
import { Swiper, SwiperSlide } from "swiper/react";
import BestTimeView from "../NestedRouteOnDashBodr/PostBestTime/BestTimeView/BestTimeView";
import { videoStoryThumbnailUrl } from "src/Projects/Life/Common/CommonUrl";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Navigation } from "swiper";
import getProfilePic from "./getProfilePic";
import { LazyLoadImage } from "react-lazy-load-image-component";

const TimeLineBestTimeView = () => {
  const { postList, hasMore, setCurrentPage } = useBestTimeStore();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [playingIndex, setPlayingIndex] = useState(state?.index ?? 0);

  const handleVideoEnd = () => {
    if (postList.length - 1 > playingIndex) {
      setPlayingIndex((prevIndex) => prevIndex + 1);
    } else if (hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else {
      navigate(-1);
    }
  };

  const handlePrevBtn = () => {
    if (playingIndex > 1) {
      setPlayingIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextBtn = () => {
    if (postList.length - 1 > playingIndex) {
      setPlayingIndex((prevIndex) => prevIndex + 1);
    } else if (hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else {
      navigate(-1);
    }
  };

  const handleSliderEnd = () => {
    if (hasMore) {
      setCurrentPage((prevState) => prevState + 1);
    }
  };

  return (
    <Container fluid className="customContainerFluidPadding">
      <div className="BestTimeSection mb-2">
        <div>
          <div className="d-flex flex-column gap-2 h-100 p-2 rounded me-2 ">
            <img
              src="https://res.cloudinary.com/yex/image/upload/v1664449687/UVIOM/camera.svg"
              alt="camera"
              title="camera"
            />
            <img
              src="https://res.cloudinary.com/yex/image/upload/v1664449639/UVIOM/upload.svg"
              alt="upload"
              title="upload"
            />
          </div>
        </div>
        <Col className="BestTimePhotoParent">
          <Swiper
            slidesPerView={"auto"}
            centeredSlides={false}
            spaceBetween={15}
            pagination={{
              clickable: true,
            }}
            onReachEnd={handleSliderEnd}
            className="category-top-slider"
            navigation={true}
            modules={[Navigation]}
          >
            {postList.map((post, index) => {
              const { video_thumbnail } = post.name;
              const { last_name } = post.has_user || "username";
              const { video_caption } = post.has_caption || "caption";
              const photoUrl = videoStoryThumbnailUrl + video_thumbnail;
              const profileImage = getProfilePic(post.profile_images);
              return (
                <SwiperSlide key={post.id}>
                  <div
                    tabIndex={0}
                    style={{ cursor: "pointer" }}
                    className="BestPhoto"
                    onClick={() => setPlayingIndex(index)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPlayingIndex(index);
                      }
                    }}
                  >
                    <img
                      className="BestPhoto__user"
                      src={profileImage}
                      onError={(e) =>
                        (e.target.src = "https://i.ibb.co/MprdZS6/user.png")
                      }
                      alt={last_name}
                    />
                    <LazyLoadImage
                      effect="blur"
                      className="img-fluid"
                      src={photoUrl}
                      alt={video_caption}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Col>
      </div>
      <BestTimeView
        data={postList[playingIndex]}
        handleVideoEnd={handleVideoEnd}
        handleNextBtn={handleNextBtn}
        handlePrevBtn={handlePrevBtn}
      />
    </Container>
  );
};

export default TimeLineBestTimeView;
