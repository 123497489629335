import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

import FriendRequestModal from "src/Projects/Life/LifePages/AddMinimumFriend/FriendRequestModal";
import { Link, useLocation } from "react-router-dom";
import { mainProfilePic } from "src/Projects/Life/Common/CommonUrl";
import { Actions } from "./Actions";
import { useDispatch } from "react-redux";
import {
  getSingleUserChat,
  setCurrentChatting,
  setCurrentChattingUser,
  setShowMessagePopup,
} from "src/features/life/messengerSlice";

const SingleFrieds = ({ userData, dataReload }) => {
  const { id, first_name, last_name, photo, address } = userData;
  const user_address = address ? JSON.parse(address) : "";
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [userId, setuserId] = useState();
  const dispatch = useDispatch();
  const setIdOnModal = (userId) => {
    setuserId(userId);
    setShow(true);
  };
  const actionsArg = {
    setIdOnModal: setIdOnModal,
    user_id: id,
  };

  const handleMessageClick = () => {
    const data = {
      first_name: first_name,
      last_name: last_name,
      photo: photo,
      id: id,
      profile_id: null,
      username: null,
    };

    dispatch(setCurrentChatting([]));
    dispatch(setCurrentChattingUser(data));
    dispatch(getSingleUserChat({ user_id: id }));
    dispatch(setShowMessagePopup(true));
  };
  return (
    <Col xs={12} sm={12} md={6} lg={6}>
      <div className="SingleFriedsDiv my-2 box-shadow">
        <div className="container-fluid">
          <Row>
            <Col xs={2} md={2} lg={3} className="colmx px-0 ">
              {photo !== null ? (
                <img
                  className="img-fluid rounded-circle"
                  src={`${mainProfilePic}/${photo}`}
                  alt={first_name + last_name}
                />
              ) : (
                <img
                  className="img-fluid rounded-circle"
                  src="/user.png"
                  alt={first_name + last_name}
                />
              )}
            </Col>
            <Col xs={8} md={8} lg={7} className="colmx px-1 align-self-center">
              <Link
                to={`/life/public-life/${id}`}
                as="div"
                className="fndInfo font-14 py-0 my-0 hover_underline"
              >
                {" "}
                {first_name && first_name} {last_name && last_name}{" "}
              </Link>
              <div className="fndInfo py-0 my-0">
                {user_address.current_city}
              </div>
              {/* <div className="fndInfo "> 6 friend</div> */}
            </Col>
            <Col xs={2} className="px-0 myCustomDropdown align-self-center">
              <Actions
                actionsArg={actionsArg}
                handleMessageClick={handleMessageClick}
              />
            </Col>
          </Row>
        </div>
      </div>
      <FriendRequestModal
        setShow={setShow}
        show={show}
        userId={userId}
        location={location}
        dataReload={dataReload}
      />
    </Col>
  );
};

export default SingleFrieds;
