import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Form, InputGroup, Row, Col, Button } from "react-bootstrap";

import "./partnerDocument.css";

function PartnerDocument() {
  const { pathname:url } = useLocation();

  const dynamicPath = url.split("/")[1];

  return (
    <div className="partner__document">
      <h3 className="heading-3 mb-4">Verification Documentation</h3>
      <Row>
        <Col>
          <Form>
            {/*  */}
            <Form.Group className="mb-4">
              <Form.Label>Birth Certificate</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  className="shadow-sm"
                  placeholder="e g; 19982345436343626"
                />

                <InputGroup.Text className="shadow-sm">
                  <label htmlFor="birthCertificate">
                    Documents{" "}
                    <FontAwesomeIcon className="addIcon" icon={faPlus} />
                  </label>
                </InputGroup.Text>
                <Form.Control id="birthCertificate" hidden type="file" />
              </InputGroup>
            </Form.Group>

            {/*  */}
            <Form.Group className="mb-4">
              <Form.Label>National ID</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  className="shadow-sm"
                  placeholder="eg; 2435345634636"
                />
                <InputGroup.Text className="shadow-sm">
                  <label htmlFor="nidFront">
                    {" "}
                    F/Part <FontAwesomeIcon className="addIcon" icon={faPlus} />
                  </label>
                </InputGroup.Text>

                <InputGroup.Text className="shadow-sm">
                  <label htmlFor="nidBack">
                    {" "}
                    B/Part <FontAwesomeIcon className="addIcon" icon={faPlus} />
                  </label>
                </InputGroup.Text>

                <Form.Control type="file" hidden id="nidFront" />
                <Form.Control type="file" hidden id="nidBack" />
              </InputGroup>
            </Form.Group>

            {/*  */}
            <Form.Group className="mb-4">
              <Form.Label>Passport</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  className="shadow-sm"
                  placeholder="eg; 343223423234643"
                />

                <InputGroup.Text className="shadow-sm">
                  <label htmlFor="passport">
                    {" "}
                    Documents{" "}
                    <FontAwesomeIcon className="addIcon" icon={faPlus} />
                  </label>
                </InputGroup.Text>
                <Form.Control id="passport" hidden type="file" />
              </InputGroup>
            </Form.Group>

            {/*  */}
            <Form.Group className="mb-4">
              <Form.Label>Driving License</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  className="shadow-sm"
                  placeholder="e g; 234534323463453"
                />

                <InputGroup.Text className="shadow-sm">
                  <label htmlFor="driving">
                    {" "}
                    Documents{" "}
                    <FontAwesomeIcon className="addIcon" icon={faPlus} />
                  </label>
                </InputGroup.Text>
                <Form.Control id="driving" hidden type="file" />
              </InputGroup>
            </Form.Group>

            {/*  */}
            <Form.Group className="mb-4">
              <Form.Label>Bank Card</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  className="shadow-sm"
                  placeholder="e g; 343232345634"
                />

                <InputGroup.Text className="shadow-sm">
                  <label htmlFor="bank">
                    {" "}
                    Documents{" "}
                    <FontAwesomeIcon className="addIcon" icon={faPlus} />
                  </label>
                </InputGroup.Text>
                <Form.Control id="bank" hidden type="file" />
              </InputGroup>
            </Form.Group>

            {/*  */}
            <Form.Group className="mb-4">
              <Form.Label>UVIOM Wallet</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  className="shadow-sm"
                  placeholder="e g; 2342303948347433"
                />

                <InputGroup.Text className="shadow-sm">
                  <label htmlFor="uviomWallet">
                    {" "}
                    Documents{" "}
                    <FontAwesomeIcon className="addIcon" icon={faPlus} />
                  </label>
                </InputGroup.Text>

                <Form.Control id="uviomWallet" hidden type="file" />
              </InputGroup>
            </Form.Group>

            <div className="text-end mt-5">
              <Button
                as={Link}
                to={`/${dynamicPath}/partner/documents/verification`}
                className="btn__ride"
              >
                Submit
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
export default PartnerDocument;
