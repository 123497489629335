export const threeDotMenuUrl = "https://i.ibb.co/DLRnhQN/three-dot-menu.png";
export const Url = "https://admin.uviom.com/frontend_api";
export const access_token = localStorage.getItem("access_token");
// export const storyUrl = "https://d15k7xufyftudh.cloudfront.net";
// export const videoBaseUrl = "https://d15k7xufyftudh.cloudfront.net/";
export const storyUrl = "https://d1tknbmtabnmqd.cloudfront.net";
export const videoBaseUrl = "https://d1tknbmtabnmqd.cloudfront.net/";

export const BearerToken = `Bearer ${localStorage.getItem("access_token")}`;
export const PublicFolderUrl = process.env.PUBLIC_URL;
// api list
export const loginUrl = `${Url}/auth/login`;
export const getUserInfoUrl = `${Url}/auth/me`;
export const getUserMainProfileInfoUrl = `${Url}/user-info/get-main-profile-info`;
export const updateMainProfileBasicInfoUrl = `${Url}/user-info/update-main-profile-basic-info`;
export const updateMainProfileMailingInfoUrl = `${Url}/user-info/update-main-profile-mailing-address`;
export const updateMainProfileShippingInfoUrl = `${Url}/user-info/update-main-profile-shipping-address`;
export const updateMainBirthImgUrl = `${Url}/user-info/update-main-birth-image`;
export const updateMainPassportImgUrl = `${Url}/user-info/update-main-passport-image`;
export const updateMainBankImgUrl = `${Url}/user-info/update-main-bank-image`;
export const updateMainNidImgUrl = `${Url}/user-info/update-main-nid-image`;
// About -----------------------------------------------------------------------------------
export const userOverviewInfoUrl = `${Url}/user-info/user-overview-info`;
export const userMainInfoUrl = `${Url}/user-info/update-user-main-info`;
//Work
export const workInfoUrl = `${Url}/user-info/work-info`;
export const workInfoStoreUrl = `${Url}/user-info/work-info/store`;
// Education
export const educationInfoUrl = `${Url}/user-info/user-education-info`;
// address
export const updateUserAddressInfoUrl = `${Url}/user-info/update-user-address-info`;
// event
export const eventInfoUrl = `${Url}/user-info/user-event-info`;
//sports
export const sportsInfoUrl = `${Url}/user-info/user-sports-info`;
//music
export const musicInfoUrl = `${Url}/user-info/user-music-info`;
//tvProgramme
export const tvProgrammeUrl = `${Url}/user-info/user-tv-programme-info`;
//books
export const booksInfoUrl = `${Url}/user-info/user-book-info`;
//film
export const gamesInfoUrl = `${Url}/user-info/user-apps-games`;
//Relationship
export const updateRelationInfoUrl = `${Url}/user-info/update-user-relationship-status`;
export const userAdditionalInfoUrl = `${Url}/user-info/user-additional-info`;
export const updateDetailsInfoUrl = `${Url}/user-info/update-user-details-info`;
export const updateBasicInfoUrl = `${Url}/user-info/update-user-basic-info`;
// public Life
export const otherProfileOverviewUrl = `${Url}/public-profile/other-profile-overview-info`;
export const publicProfileMultiInfoUrl = `${Url}/public-profile/multiple-info`;

export const friendshipInfoUrl = `${Url}/public-profile/other-profile-friendship-info`;
export const otherProfileImgUrl = `${Url}/public-profile/other-profile-image-info`;
export const otherProfilePostUrl = `${Url}/public-profile/other-profile-post-info`;

// About end-----------------------------------------------------------------------------------

// zz

export const logoutUrl = `${Url}/auth/logout`;
// ---FrindList
export const fmFindFnUrl = `${Url}/friend/find-family`;
export const fnFindFnUrl = `${Url}/friend/find-friend`;
export const pFindFnUrl = `${Url}/friend/find-professional`;

export const fmAllFriendsUrl = `${Url}/friend/family-list`;
export const fnAllFriendsUrl = `${Url}/friend/friend-list`;
export const pAllFriendsUrl = `${Url}/friend/professional-list`;

export const searchFriendUrl = `${Url}/friend/search-friend`;
export const fmPendingListUrl = `${Url}/friend/pending-family-list`;
export const fnPendingListUrl = `${Url}/friend/pending-friend-list`;
export const pPendingListUrl = `${Url}/friend/pending-professional-list`;

export const fmRequestedListUrl = `${Url}/friend/sent-pending-family-list`;
export const fnRequestedListUrl = `${Url}/friend/sent-pending-friend-list`;
export const pRequestedListUrl = `${Url}/friend/sent-pending-professional-list`;

export const fmChangeRequestedStatusUrl = `${Url}/friend/change-requested-pending-family-status`;
export const fnChangeRequestedStatusUrl = `${Url}/friend/change-requested-pending-friend-status`;
export const pChangeRequestedStatusUrl = `${Url}/friend/change-requested-pending-professional-status`;

export const fmSentRequestUrl = `${Url}/friend/sent-family-request`;
export const fnSentRequestUrl = `${Url}/friend/sent-friend-request`;
export const pSentRequestUrl = `${Url}/friend/sent-professional-request`;

export const fmPendingStatusChangeUrl = `${Url}/friend/change-pending-family-status`;
export const fnPendingStatusChangeUrl = `${Url}/friend/change-pending-friend-status`;
export const pPendingStatusChangeUrl = `${Url}/friend/change-pending-professional-status`;

//-------post
// export const storePostUrl=`${Url}/post/friend/store-friend-requestsocial-media-post`;
export const storePostUrl = `${Url}/post/store-social-media-post`;
export const storeVideoPostUrl = `${Url}/post/store-social-media-video-post`;
export const editPostUrl = `${Url}/post/get-single-post-info`;
export const deletePostUrl = `${Url}/post/delete-single-post`;
export const deletePostContUrl = `${Url}/post/delete-single-post-content`;
export const deleteVideoPosUrl = `${Url}/post/delete-single-video-post`;
export const updatePostCaptionUrl = `${Url}/post/update-post-caption`;
export const updatePostPrivacy = `${Url}/post/update-post-privacy`;

export const getFmPersonalPostUrl = `${Url}/post/get-personal-family-content-post`;
export const getFnPersonalPostUrl = `${Url}/post/get-personal-friend-content-post`;
export const getProPersonalPostUrl = `${Url}/post/get-personal-professional-content-post`;

export const fmDashboardPostUrl = `${Url}/post/get-social-family-dashboard-post`;
export const fnDashboardPostUrl = `${Url}/post/get-social-friend-dashboard-post`;
export const pDashboardPostUrl = `${Url}/post/get-social-professional-dashboard-post`;
export const publicDashboardPostUrl = `${Url}/post/get-social-public-dashboard-post`;

// post like Comment-----------
export const postLikeURL = `${Url}/post/post-like`;
export const postLikeCountUrl = `${Url}/post/post-like-count`;
export const commentStoreUrl = `${Url}/post/comment/`;
export const showPostCommentUrl = `${Url}/post/show-post-comment`;
export const getPostCommentCountUrl = `${Url}/post/post-comment-count`;
export const fmPostCommentRemoveUrl = `${Url}/post/remove-comment`;
export const replyCommentRemoveUrl = `${Url}/post/remove-reply-comment`;
export const postLikeUserListUrl = `${Url}/post/post-like-user-list`;
export const commentLikedUserListUrl = `${Url}/post/comment-like-user-list`;
export const replyComLikedUserListUrl = `${Url}/post/comment-reply-like-user-list`;
export const singleShowPostComment = `${Url}/post/single-show-post-comment`;
export const singleShowReplyComment = `${Url}/post/single-show-post-reply-comment`;
export const updateCommentUrl = `${Url}/post/update-comment`;
export const updateReplyCommentUrl = `${Url}/post/update-comment-reply`;
export const showPostReplyCommentUrl = `${Url}/post/show-post-reply-comment`;
export const commentReplyStoreUrl = `${Url}/post/comment-reply/`;
export const commentLikePUrl = `${Url}/post/comment-like`;
export const commentLikeCountUrl = `${Url}/post/comment-like-count`;
export const replyCommentLikeCountUrl = `${Url}/post/comment-reply-like-count`;
export const commentReplyLikePUrl = `${Url}/post/reply-comment-like`;
export const replyCommentCountUrl = `${Url}/post/post-reply-comment-count`;

// report \\\
export const reportPostUrl = `${Url}/post/post-report-create/`;
export const reportedPostUrl = `${Url}/post/show-complain-post`;
export const closeReportUrl = `${Url}/post/close-post-report/`;

// profile/cover photo-----------
// export const temporaryImageUrl = "https://uviom-life.s3.amazonaws.com";
export const temporaryImageUrl =
  "https://uviom-life-running.s3.ap-southeast-1.amazonaws.com";
export const personalProfilePic = `${temporaryImageUrl}/images/personal/profile_pic`;
export const postImagePath = `${temporaryImageUrl}/images/content/post_images`;
export const familyProfilePic = `${temporaryImageUrl}/images/personal/family_profile_photo/`;
export const friendProfilePic = `${temporaryImageUrl}/images/personal/friend_profile_photo/`;
export const professionalProfilePic = `${temporaryImageUrl}/images/personal/professional_profile_photo/`;
export const postVideoPath = `${videoBaseUrl}images/content/post_images/`;
export const videoThumbnailPath = `${temporaryImageUrl}/images/content/post_images/`;

// https://uviom-life.s3.amazonaws.com/images/content/post_images/

// profile_photo
export const fmPhotosUrl = `${Url}/user-info/get-family-photos`;
export const fnPhotosUrl = `${Url}/user-info/get-friend-photos`;
export const pPhotosUrl = `${Url}/user-info/get-professional-photos`;
export const mainPhotosUrl = `${Url}/user-info/get-main-images`;
export const mainCoverPUrl = `${temporaryImageUrl}/images/personal/main_photo/cover_photo`;

export const mainProPUrl = `${temporaryImageUrl}/images/personal/profile_pic`;
// zz
export const mainProfilePhotosUrl = `${Url}/user-info/update-profile-picture`;
export const mainCoverPhotosUrl = `${Url}/user-info/update-main-cover-image`;

export const personalPicturesTempBase = `https://uviom-life-running.s3.ap-southeast-1.amazonaws.com`;
export const mainProfilePic = `${personalPicturesTempBase}/images/personal/profile_pic`;
export const fmFriendsphotoGetUrl = `${personalPicturesTempBase}/images/personal/family_profile_photo`;
export const fnFriendsphotoGetUrl = `${personalPicturesTempBase}/images/personal/friend_profile_photo`;
export const pFriendsphotoGetUrl = `${personalPicturesTempBase}/images/personal/professional_profile_photo`;

// profile pictures upload -----------
export const fmUpdateProfilePhotoURL = `${Url}/user-info/update-family-profile-photo`;
export const fnUpdateProfilePhotoURL = `${Url}/user-info/update-friend-life-profile-photo`;
export const pUpdateProfilePhotoURL = `${Url}/user-info/update-professional-profile-photo`;
// profile cover upload-----------
export const FmUpdateCoverPhotoURL = `${Url}/user-info/update-family-cover-photo`;
export const FnUpdateCoverPhotoURL = `${Url}/user-info/update-friend-life-cover-photo`;
export const pUpdateCoverPhotoURL = `${Url}/user-info/update-professional-cover-photo`;

// message------------------
export const usersMessagesListUrl = `${Url}/message/list-users-message`;
export const loadLatestMessagesUrl = `${Url}/message/load-latest-messages`;
export const loadOldMessageUrl = `${Url}/message/fetch-old-messages`;

export const messageSendUrl = `${Url}/message/send`;
export const messageImageSendUrl = `${Url}/message/send-message-content`;
export const messageImages = `${temporaryImageUrl}/message/send`;

// message end------------------

// best time url
export const videoStoryFamilyUrl = `${Url}/post/video-story-family-list`;
export const videoStoryFriendUrl = `${Url}/post/video-story-friend-list`;
export const videoStoryProfessionalUrl = `${Url}/post/video-story-professional-list`;
export const videoStoryVideoUrl = `${storyUrl}/videos/story/content/`;
export const videoStoryThumbnailUrl = `${storyUrl}/videos/story/thumbnail/`;
export const getPersonalFamilyStory = `${Url}/post/personal-family-story-list`;
export const getPersonalFriendStory = `${Url}/post/personal-friend-story-list`;
export const getPersonalProfessionalStory = `${Url}/post/personal-professional-story-list`;
export const storyCaptionUpdateUrl = `${Url}/post/story-video-update`;
export const storyStatusUpdateUrl = `${Url}/post/story-video-status-update`;
export const deleteStoryVideoUrl = `${Url}/post/video-story-delete/`;

// notification
export const personal_notification_url = `${Url}/notification/personal-notification`;

// aws video upload
export const AWS_BASE_URL = "https://s3.amazonaws.com/uviom-life";
export const AWS_BUCKET_NAME = "uviom-life-running";
export const AWS_video_path = "images/content/post_images/";
export const AWS_video_thumbnail_path = "images/content/post_images/";
export const AWS_REGION_NAME = "ap-southeast-1";
export const AWS_ACCESS_ID = process.env.REACT_APP_AWS_ACCESS_ID;
export const AWS_SECRET_KEY = process.env.REACT_APP_AWS_SECRET_KEY;
