import "./ataGlance.css";

function AtaGlance({ stats }) {
  return (
    <div className="ataGlance">
      {stats.slice(0, 4).map((stat, index) => (
        <div key={index} className="ataGlance__card">
          <p className="ataGlance__card__title">{stat.title}</p>
          <p className="ataGlance__card__sub">{stat.sub}</p>
        </div>
      ))}
    </div>
  );
}
export default AtaGlance;
