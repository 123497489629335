import { prepareApiResponse } from "src/app/helper/rtkFunctions";
import { CACHE_EXPIRE_TIME } from "src/Projects/Job/utilities/job_commonUrl";
import { api } from "../api";




export const bestTimeApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getBestTimePost: builder.query({
            query: ({ page = 1, url }) => `${url}?page=${page}`,
            keepUnusedDataFor: CACHE_EXPIRE_TIME,
            transformResponse: prepareApiResponse,
        })
    })
})

export const { useGetBestTimePostQuery } = bestTimeApi;

