import React, { useEffect, useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { allProductUrl } from "../../utilities/CommonUrl";
import "./product.css";
import ScrollToTop from "src/Common/ScrollToTop";
import paginationApiCall from "src/Common/apiCall/paginationApiCall";
import { ProductInfiniteScroll } from "./ProductInfiniteScroll";
import ProductSkeleton from "../../Components/Placeholder/ProductSkeleton";

const Product = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [info, setinfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const location = useLocation();

  const paginationApiArg = {
    setIsLoading: setIsLoading,
    postList: info,
    setPostList: setinfo,
    currentPage: currentPage,
    setHasMore: setHasMore,
    Url: allProductUrl,
  };

  useEffect(() => {
    paginationApiCall(paginationApiArg, true);
  }, [currentPage]);
  const myRef = useRef(null);
  useEffect(() => {
    if (myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div
      className={`py-2 py-md-3 px-2 px-md-4 ${
        location.pathname.includes("/product")
          ? "productPage  "
          : "my-5 box-shadow px-3 "
      }`}
      ref={myRef}
    >
      <h5 className="py-1">Product</h5>
      {/* <ProductSearchBox ProductSearchBoxArg={ProductSearchBoxArg} /> */}
      <ScrollToTop />

      <ProductInfiniteScroll
        product={info}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        hasMore={hasMore}
        zoroLengthShow={true}
      />
      <Row className="home__Product-box g-2">
        {isLoading
          ? [...new Array(12)].map((_, index) => (
              <ProductSkeleton key={index} />
            ))
          : null}
      </Row>
    </div>
  );
};

export default Product;
