import axios from "axios";

export const UpdateProfilePicture = (updateProArg) => {
  const {
    formData,
    Url,
    callAfterRejected,
    callAfterSuccess
  } = updateProArg;
  axios
    .post(`${Url}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data.status === 1) {
       callAfterSuccess(res.data)
      }
    })
    .catch((err) => {
      callAfterRejected(err);
    });
};
