import React from "react";
import { Accordion } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import useTheme from "src/hooks/useTheme";

import Avatar from "src/Projects/Life/Common/Avatar";
import "./profile.css";

const Profile = ({ handleClose = () => {}, boxShadow = false }) => {
  const { pathname } = useLocation();
  const currentPath = "/" + pathname.split("/")[1];
  const { currentTheme } = useTheme();
  const profileStyle = {
    backgroundColor:
      window.innerWidth < 768 && currentTheme === "dark"
        ? "transparent"
        : "secondary-bg",
  };
  return (
    <div
      className={`p-3 ${boxShadow && "box-shadow"} secondary-bg`}
      style={profileStyle}
    >
      <div className="profile-img-box d-flex justify-content-center mb-2">
        <Avatar imgUrl={"/user.png"} size="7rem"></Avatar>
      </div>
      <hr />
      <div>
        <NavLink
          to={`${currentPath}/my-profile/personal-info`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
          onClick={handleClose}
        >
          Personal Information
        </NavLink>
        <NavLink
          to={`${currentPath}/my-profile/manage-profile`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
          onClick={handleClose}
        >
          Manage Profile
        </NavLink>
        {/* <NavLink
          to={`${currentPath}/my-profile/uviom-wallet`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
          onClick={handleClose}
        >
          UVIOM Wallet
        </NavLink> */}

        <Accordion
          defaultActiveKey="1"
          id="profile-myOrder-Dropdown"
          className=" shipping-address"
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header as={"h6"} className="font-14">
              Order History
            </Accordion.Header>
            <Accordion.Body>
              <NavLink
                to={`${currentPath}/my-profile/my-order`}
                state={{ data: "All Order " }}
                className={({ isActive }) =>
                  `profile-nav-item ${
                    isActive ? "profile-nav-item-active" : ""
                  }`
                }
                onClick={handleClose}
              >
                All Order
              </NavLink>
              <NavLink
                to={`${currentPath}/my-profile/pending-order`}
                state={{ data: "Pending Order" }}
                className={({ isActive }) =>
                  `profile-nav-item ${
                    isActive ? "profile-nav-item-active" : ""
                  }`
                }
                onClick={handleClose}
              >
                Pending Order
              </NavLink>
              <NavLink
                to={`${currentPath}/my-profile/confirm-order`}
                state={{ data: "Confirm Order" }}
                className={({ isActive }) =>
                  `profile-nav-item ${
                    isActive ? "profile-nav-item-active" : ""
                  }`
                }
                onClick={handleClose}
              >
                Confirm Order
              </NavLink>
              <NavLink
                to={`${currentPath}/my-profile/canceled-order`}
                state={{ data: "Canceled Order" }}
                className={({ isActive }) =>
                  `profile-nav-item ${
                    isActive ? "profile-nav-item-active" : ""
                  }`
                }
                onClick={handleClose}
              >
                Canceled Order
              </NavLink>
              <NavLink
                to={`${currentPath}/my-profile/uviom-pending`}
                state={{ data: "Uviom Pending" }}
                className={({ isActive }) =>
                  `profile-nav-item ${
                    isActive ? "profile-nav-item-active" : ""
                  }`
                }
                onClick={handleClose}
              >
                UVIOM Pending
              </NavLink>
              <NavLink
                to={`${currentPath}/my-profile/uviom-approved`}
                state={{ data: "Uviom Approved" }}
                className={({ isActive }) =>
                  `profile-nav-item ${
                    isActive ? "profile-nav-item-active" : ""
                  }`
                }
                onClick={handleClose}
              >
                UVIOM Approved
              </NavLink>

              <NavLink
                to={`${currentPath}/my-profile/uviom-received`}
                state={{ data: " Uviom Received" }}
                className={({ isActive }) =>
                  `profile-nav-item ${
                    isActive ? "profile-nav-item-active" : ""
                  }`
                }
                onClick={handleClose}
              >
                UVIOM Received
              </NavLink>
              <NavLink
                to={`${currentPath}/my-profile/uviom-delivered`}
                state={{ data: " Uviom Delivered" }}
                className={({ isActive }) =>
                  `profile-nav-item ${
                    isActive ? "profile-nav-item-active" : ""
                  }`
                }
                onClick={handleClose}
              >
                UVIOM Delivered
              </NavLink>
              <NavLink
                to={`${currentPath}/my-profile/vendor-received`}
                state={{ data: "Vendor Received" }}
                className={({ isActive }) =>
                  `profile-nav-item ${
                    isActive ? "profile-nav-item-active" : ""
                  }`
                }
                onClick={handleClose}
              >
                Vendor Received
              </NavLink>
              <NavLink
                to={`${currentPath}/my-profile/customer-received`}
                state={{ data: "Customer received" }}
                className={({ isActive }) =>
                  `profile-nav-item ${
                    isActive ? "profile-nav-item-active" : ""
                  }`
                }
                onClick={handleClose}
              >
                Customer received
              </NavLink>
              <NavLink
                to={`${currentPath}/my-profile/received-order`}
                state={{ data: "Received Order" }}
                className={({ isActive }) =>
                  `profile-nav-item ${
                    isActive ? "profile-nav-item-active" : ""
                  }`
                }
                onClick={handleClose}
              >
                Received Order
              </NavLink>
              {/* <NavLink
                to={`${currentPath}/my-profile/return-order`}
               className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
                onClick={handleClose}
              >
                Return Order
              </NavLink> */}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <NavLink
          to={`${currentPath}/my-profile/payment-history`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
          onClick={handleClose}
        >
          Payment History
        </NavLink>
        {/* <NavLink
          to={`${currentPath}/my-profile/setting`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
          onClick={handleClose}
        >
          Setting
        </NavLink> */}
        <NavLink
          to={`${currentPath}/product-request`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
          onClick={handleClose}
        >
          Product Request
        </NavLink>
        <NavLink
          to={`${currentPath}/seller-registration`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
          onClick={handleClose}
        >
          Seller Registration
        </NavLink>
        <NavLink
          to={`${currentPath}/my-store`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
          onClick={handleClose}
        >
          My Store
        </NavLink>
      </div>
    </div>
  );
};

export default Profile;
