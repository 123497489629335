import React from "react";
import { Placeholder } from "react-bootstrap";
const DashboardSkeleton = () => {
  return (
    <div className="job__dashboard-item p-2">
      <Placeholder
        as="div"
        className="job-skeleton-content-avatar mt-2"
        animation="none"
      >
        <div className="job__dashboard-icon bg-secondary">
          {/* <span className="p-0 w-100 h-100 d-flex justify-content-center align-items-center ">
            <img
              src="/corporate/logo-outline.png"
              alt=""
              className="img img-fluid"
            />
          </span> */}
        </div>
      </Placeholder>

      <Placeholder
        as="div"
        className="text-center job-skeleton-content-text w-100"
        animation="wave"
      >
        <Placeholder bg="secondary" size="md" xs={10} />
        <Placeholder bg="secondary" xs={4} />
      </Placeholder>
    </div>
  );
};

export default DashboardSkeleton;
