import React from "react";
import {
  faEllipsisH,
  faGraduationCap,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SingleItem = ({ item }) => {
  return (
    <div className="d-flex justify-content-between my-4">
   
      <div className="d-flex">
      <div className="me-3 border  p-1 myIconCircle">
        <FontAwesomeIcon icon={faBook} />
      </div>
      <div>
      {item && item.details_about}
      </div>
    </div>
      
    </div>
  );
};
