import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import paginationApiCall from "src/Common/apiCall/paginationApiCall";
import { reportedPostUrl } from "src/Projects/Life/Common/CommonUrl";
import SingleReport from "./SingleReport";

export default function ReportedPost() {
  const [postList, setPostList] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const paginationApiArg = {
    postList,
    setPostList,
    currentPage,
    setHasMore,
    setIsLoading,
    Url: reportedPostUrl,
  };

  useEffect(() => {
    if (hasMore) {
      paginationApiCall(paginationApiArg, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <div>
      <InfiniteScroll
        dataLength={postList.length} //This is important field to render the next data
        next={() => {
          setCurrentPage(currentPage + 1);
        }}
        hasMore={hasMore}
      >
        <div className="jobRecommended p-2 pb-4 ">
          <h6 className="text-orange mt-3">Reported Post</h6>

          {postList.length < 1 && loading && (
            <p className="text-center mt-4"> 0 item Found </p>
          )}
          <Row className="g-3 p-2">
            {postList.map((post) => (
              <SingleReport data={post} key={post.id}></SingleReport>
            ))}
          </Row>
        </div>
      </InfiniteScroll>
    </div>
  );
}
