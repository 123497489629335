import React, { memo } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { MiddleContent } from "./MiddleContent";

import "src/Projects/Life/LifePages/MainPage/NewsFeedPost/NewsFeedPost.css";

export const SinglePost = memo((props) => {
  const { post } = props;
  const headerArg = {
    post: post,
  };
  const MiddleContentArg = {
    post: post,
  };
  const footerArg = {
    post: post,
  };
  return (
    <>
      <div
        tabIndex="0"
        className="box-shadow mb-3 singlePostSample  bg-footer rounded-3"
      >
        <Header headerArg={headerArg} />
        <MiddleContent MiddleContentArg={MiddleContentArg} />
        <Footer footerArg={footerArg} />
      </div>
    </>
  );
});
