import {
  FmUpdateCoverPhotoURL,
  fmUpdateProfilePhotoURL,
  FnUpdateCoverPhotoURL,
  fnUpdateProfilePhotoURL,
  mainCoverPhotosUrl,
  mainProfilePhotosUrl,
  pUpdateCoverPhotoURL,
  pUpdateProfilePhotoURL,
} from "../Common/CommonUrl";
export const URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

export const family_default_profile = {
  cover: "/Life/Cover/Family.jpg",
  profile: "/user.png",
};
export const friend_default_profile = {
  cover: "/Life/Cover/Friends.jpg",
  profile: "/user.png",
};
export const professional_default_profile = {
  cover: "/Life/Cover/Professional.jpg",
  profile: "/user.png",
};
export const public_default_profile = {
  cover: "/Life/Cover/Professional.jpg",
  profile: "/user.png",
};

export const profile_update_url_list = {
  family: fmUpdateProfilePhotoURL,
  friend: fnUpdateProfilePhotoURL,
  professional: pUpdateProfilePhotoURL,
  public: mainProfilePhotosUrl,
};
export const cover_update_url_list = {
  family: FmUpdateCoverPhotoURL,
  friend: FnUpdateCoverPhotoURL,
  professional: pUpdateCoverPhotoURL,
  public: mainCoverPhotosUrl,
};
