import React from "react";
import { Route, Routes } from "react-router-dom";
import { About } from "./Components/About";
import { Photos } from "./Components/Photos";
import { Post } from "./Components/Post";
import { Videos } from "./Components/Videos";

export const LifePageViewRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Post />} />
      <Route path="/about" element={<About />} />
      <Route path="/photos" element={<Photos />} />
      <Route path="/videos" element={<Videos />} />
    </Routes>
  );
};
