import React, { createContext } from "react";
import useJobStoreGlobal from "../hooks/useJobStoreGlobal";
export const JobStoreContext = createContext();
const JobStoreProvider = ({ children }) => {
  return (
    <JobStoreContext.Provider value={useJobStoreGlobal()}>
      {children}
    </JobStoreContext.Provider>
  );
};

export default JobStoreProvider;
