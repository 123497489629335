import React from "react";
import CallScreenFooter from "../Common/CallScreenFooter";
import "./audioCall.css";
import IncomingAudioCall from "./IncomingAudioCall";

const CallingAudioCall = () => {
  return (
    <div>
      <div
        className="csc__img-container"
        style={{ backgroundImage: "url(/ceo.png)" }}
      >
        <img className="img img-fluid csc-img" src="/ceo.png" alt="user" />
        <div className="csc__call-text text-center">
          <p className="mb-1">Calling...</p>
          <p>Kamrul Dolon</p>
        </div>
      </div>

      <CallScreenFooter />

      <IncomingAudioCall />
    </div>
  );
};

export default CallingAudioCall;
