import React from "react";
import { Card, Col } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import ImagePlaceholder from "src/Common/Placeholder/ImagePlaceholder";
import { categoryImgUrl } from "src/Projects/Job/utilities/job_commonUrl";

const SliderItem = ({ data }) => {
  const { title, photo, job_posts_count, id, slug } = data;
  const image = photo ? categoryImgUrl + photo : "/user.png";
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/Job/job-market/categories/${slug}/${id}`);
  };

  return (
    <Col className="h-100 job__category-home-item border-primary">
      <Card
        onClick={handleClick}
        className="job__category__slider-item pointer"
      >
        <Card.Img as="div" className="job__category-slider-imgBox">
          <LazyLoadImage
            src={image}
            alt=""
            className="img img-fluid"
            effect="blur"
            placeholder={<ImagePlaceholder />}
          />
        </Card.Img>
        <Card.Body className="d-block p-1">
          <p
            style={{ height: "2rem" }}
            className="mt-2 text-center primary-text font-14 mb-0 fw-bold w-100"
          >
            {title}
          </p>
          <p className="mt-2 text-center primary-text font-14">
            Job Open : {job_posts_count}
          </p>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default SliderItem;
