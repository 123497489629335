import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";

function CreatePostModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="creative__modal__header" closeButton>
        <h6 className="mb-0">Create Post</h6>
      </Modal.Header>
      <Modal.Body className="creative__modal__body">
        <Form.Control
          type="text"
          className="input-reset"
          placeholder="Job Title"
        />
        <Row>
          <Col sm={6}>
            <Form.Control
              className="mt-3 input-reset"
              type="text"
              placeholder="Timeline"
            />
          </Col>
          <Col sm={6}>
            <Form.Control className="mt-3 input-reset" type="text" placeholder="Pricing" />
          </Col>
        </Row>
        <Form.Control
          className="shadow-0 mt-3 input-reset"
          as="textarea"
          rows={6}
          placeholder="Job Details"
        />

        <div className="mt-3">
          <h6>Requirement Task</h6>
          <div className="d-flex flex-wrap gap-2 gap-sm-3">
            <div className="creative__skills__badge align-items-center d-inline-flex">
              UX Research
            </div>

            <div className="creative__skills__badge align-items-center d-inline-flex">
              Information Architecture{" "}
            </div>
            <div className="creative__skills__badge align-items-center d-inline-flex">
              Add New Task
              <FontAwesomeIcon
                className="ms-2 text-orange"
                icon={faPlusSquare}
              />
            </div>
          </div>
        </div>

        <div className="py-3 text-lg-end">
          <Button className="btn__creative" onClick={props.onHide}>
            Post Job
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default CreatePostModal;
