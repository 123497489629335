import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Cropper from "react-easy-crop";
import "./ProfileEditModal.css";
import { UpdateProfilePicture } from "./Request/UpdateProfilePicture";
import getCroppedImg from "src/Projects/Life/utils/cropImage";
import { dataURLtoFile } from "src/Projects/Life/utils/dataURLtoFile";
import compress_image from "src/utilities/compress_image";
import _ from "lodash";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  cover_update_url_list,
  profile_update_url_list,
} from "src/Projects/Life/utils/life_utilities";
import { useDispatch } from "react-redux";
import { updateProfilePhoto } from "src/features/life/lifeCommonDataSlice";

export const ProfileEditModal = ({
  showProfileEditModal,
  handleCloseProfileEditModal,
  ExistingPicture,
  uploadStatus,
  timeline,
}) => {
  const inputRef = React.useRef();
  const triggerFileSelectPopup = () => inputRef.current.click();

  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [image, setImage] = React.useState(null);
  const [croppedArea, setCroppedArea] = React.useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [tempImageName, settempImageName] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  // const [updateProfilePhoto, {isSuccess, isLoading, error}] = useUpdateProfilePhotoMutation();

  let timeline_name = timeline;
  if (timeline === "friends") timeline_name = "friend";

  useEffect(() => {
    if (errorMessage !== "") {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); //event.target.files[0] contain object file so we need to convert it on DataURL
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
      settempImageName(event.target.files[0].name);
    }
  };

  const formateData = () => {
    setImage(null);
  };

  const callAfterSuccess = (data) => {
    setIsLoading(false);
    dispatch(updateProfilePhoto({ timeline, data, type: uploadStatus }));
    handleCloseProfileEditModal();
  };

  const callAfterRejected = (err) => {
    setIsLoading(false);
    setErrorMessage("Something went wrong !");
    handleCloseProfileEditModal();
  };

  const onUpload = async () => {
    if (!image) {
      setErrorMessage("Please Select an image !");
    }

    const canvas = await getCroppedImg(image, croppedArea);
    const canvasDataUrl = canvas.toDataURL("image/jpeg");
    const convertedUrlToFile = dataURLtoFile(canvasDataUrl, tempImageName);

    let formData = new FormData();

    // convertedUrlToFile=for s3bucket we need to convert the file into file object
    // console.log(convertedUrlToFile);

    if (uploadStatus === "profile") {
      let fieldName = `${timeline_name}_life_profile_image`;
      if (timeline_name === "public") fieldName = "profile_picture";
      let Url = profile_update_url_list[timeline_name];
      setIsLoading(true);

      const compressed_images = await compress_image([convertedUrlToFile]);

      _.forEach(compressed_images, (file) => {
        formData.append(fieldName, file);
      });
      setIsLoading(true);
      UpdateProfilePicture({
        Url,
        formData,
        callAfterSuccess,
        callAfterRejected,
      });
    } else {
      let fieldName = `${timeline_name}_life_cover_image`;
      if (timeline_name === "public") fieldName = "cover_photo";
      let Url = cover_update_url_list[timeline_name];

      const compressed_images = await compress_image([convertedUrlToFile]);

      _.forEach(compressed_images, (file) => {
        formData.append(fieldName, file);
      });
      setIsLoading(true);
      UpdateProfilePicture({
        Url,
        formData,
        callAfterSuccess,
        callAfterRejected,
      });
    }
  };

  return (
    <Modal
      show={showProfileEditModal}
      onHide={() => {
        handleCloseProfileEditModal();
        formateData();
      }}
      size="lg"
      className="text-dark ProfileEditModal"
    >
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center position-relative"
          style={{ minHeight: "17rem" }}
        >
          <FontAwesomeIcon icon={faSpinner} spin size="2x" className="me-2" />
          <span className="fs-4" style={{ letterSpacing: "2px" }}>
            {"Updating..."}
          </span>
        </div>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              {uploadStatus === "profile"
                ? "Profile Picture Edit"
                : "Cover Picture Edit"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errorMessage && (
              <div className="text-danger text-center bg-warning rounded-pill py-1 mb-1">
                {errorMessage}
              </div>
            )}
            <div className="container-buttons py-2">
              <input
                type="file"
                accept=".jpg, .png"
                ref={inputRef}
                onChange={onSelectFile}
                style={{ display: "none" }}
                value={""}
              />
              <div className="d-flex justify-content-center">
                <Button
                  variant="secondary"
                  className="me-2"
                  onClick={triggerFileSelectPopup}
                >
                  Choose
                </Button>
                <Button variant="secondary" className="me-2" onClick={onUpload}>
                  Upload
                </Button>
              </div>
            </div>

            <div className="container-fluid">
              <div className="containerCropper">
                {image ? (
                  <>
                    <div className="cropper">
                      <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={uploadStatus === "profile" ? 1 : 2}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                      />
                    </div>

                    <div className="profileSlider">
                      <Form.Range
                        min={1}
                        max={3}
                        step={0.1}
                        value={zoom}
                        onChange={(e) => setZoom(e.target.value)}
                      />
                    </div>
                  </>
                ) : (
                  <div className="text-center">
                    {uploadStatus === "profile" ? (
                      <>
                        {ExistingPicture && ExistingPicture.profile ? (
                          <img
                            className="img-fluid rounded-circle defaultPicSize"
                            src={ExistingPicture.profile}
                            alt="user"
                          />
                        ) : (
                          <img
                            className="img-fluid rounded-circle defaultPicSize"
                            src={"/user.png"}
                            alt="user"
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {ExistingPicture && ExistingPicture.cover ? (
                          <img
                            className="img-fluid  defaultPicSize"
                            src={ExistingPicture.cover}
                            alt="user"
                          />
                        ) : (
                          <img
                            className="img-fluid  defaultPicSize"
                            src={"/user.png"}
                            alt="user"
                          />
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};
