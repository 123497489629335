import React from 'react';

const AddPost = () => {
    return (
        <div>
            <h1>THis is add Post Section</h1>
        </div>
    );
};

export default AddPost;