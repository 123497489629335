import React from "react";
import { faBasketballBall } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownActions } from "./DropdownActions";

export const SingleItem = ({ item, previousDataState, editClick,componentMainUrl }) => {
  return (
    <div className="d-flex justify-content-between mb-4">
    <div className="d-flex">
      <div className='me-3 border  p-1 myIconCircle'>
        <FontAwesomeIcon icon={faBasketballBall} />
      </div>
      <div>
        <h5>{item.sport_name}</h5>
       
      </div>
    </div>
    <DropdownActions
        itemId={item.id}
        previousDataState={previousDataState}
        editClick={editClick}
        componentMainUrl={componentMainUrl}
      />
  </div>
  );
};
