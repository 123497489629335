import React, { useEffect } from "react";
import { Accordion, Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import CloseIcon from "src/Projects/Life/icons/CloseIcon";
import "./navsidebar.css";
import { menuItems } from "src/Projects/Corporate/utilities/megaMenuItem";
import MainItem from "./MainItem";
import CorporateIcon from "src/Projects/Corporate/assets/icons/CorporateIcon";
import { isEmpty } from "lodash";

const NavSideBar = ({ show, handleClose,categoriesInfo }) => {
  useEffect(() => {
  }, []);
  return (
    <Offcanvas show={show} onHide={handleClose} className="sidebar-main" scroll>
     
      <Offcanvas.Header>
        <Offcanvas.Title>
          <Navbar.Brand
            as={Link}
            to="/dashboard"
            className=" text-white me-1 me-md-5"
          >
            <CorporateIcon fill="var(--UbizzIconColor)" />
            <span className="ms-2">Corporate</span>
          </Navbar.Brand>
        </Offcanvas.Title>
        <button className="eco-btn" onClick={handleClose}>
          <CloseIcon color="#fff" />
        </button>
      </Offcanvas.Header>
      <hr className="mb-0" />
      <Offcanvas.Body className="sidebar-body">
        <Accordion defaultActiveKey="0"  style={{background:"transparent"}}>
          {categoriesInfo &&
            !isEmpty(categoriesInfo) && categoriesInfo.map((item,index) => <MainItem key={`${index}a`} handleClose={handleClose} menuitem={item} mainIndex={index}/>)}
        </Accordion>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default NavSideBar;

