import React from 'react';
import ReceivedOrderCard from './ReceivedOrderCard';

const ReceivedOrder = () => {
    return (
        <div className='order-history p-3'>
        <h6 className="fs-6 py-3"> Received Order </h6>
        
        <ReceivedOrderCard />
        <ReceivedOrderCard />
        <ReceivedOrderCard />
       
    </div>
    );
};

export default ReceivedOrder;