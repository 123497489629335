import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import { educationInfoUrl } from "src/Projects/Life/Common/CommonUrl";
import { getInfo } from "src/Common/apiCall/GetApiCalls";
import { SingleItem } from "./SingleItem";
import { StoringInputs } from "./StoringInputs";
import { singleGetInfo } from "../Components/singleGetInfo";
import { isEmpty } from "lodash";

export const Education = ({ info }) => {
  const [data, setdata] = useState();
  useEffect(() => {
    if (!isEmpty(info)) {
      setdata(info.education_info);
    }
  }, [info]);

  return (
    <div className="aboutBox mt-1 mb-4 ">
      <div className="d-flex justify-content-between border-bottom pb-2 border-white">
        <div>
          <h6>Education</h6>
        </div>
      </div>
      <div className="mt-5">
        {data &&
          data.map((item, index) => <SingleItem key={index} item={item} />)}
      </div>
    </div>
  );
};
