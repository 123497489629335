/* eslint-disable eqeqeq */
import React, { useRef, useState } from "react";
import "./JobDetails.css";

import {
  companyProfilePhotoUrl,
  companyBannerPhotoUrl,
  getAllCategoryUrl,
} from "../../utilities/job_commonUrl";
import { useLocation } from "react-router-dom";
import handlePrint from "src/utilities/handlePrint";
import { useEffect } from "react";
import { getInfo } from "src/Common/apiCall/GetApiCalls";
import JobInformation from "../../Components/Common/JobInformation";
import { Tab, Tabs } from "react-bootstrap";
import LocationInfo from "../../Components/Common/LocationInfo";
import CompanyInfo from "../../Components/Common/CompanyInfo";
import CompanyBanner from "../../Components/Common/CompanyBanner";

const AppliedJobDetails = () => {
  const { state } = useLocation();
  const printContent = useRef();
  const printContainer = useRef();
  const [jobCategory, setJobCategory] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loading, setIsLoading] = useState(false);
  const [key, setKey] = useState("job_info");

  const { company_profile_section, company_job_section } = state.jobData;
  const { job_category_id } = company_job_section;

  const profilePhoto = company_profile_section?.profile_picture
    ? companyProfilePhotoUrl + company_profile_section.profile_picture
    : "/user.png";
  const bannerPhoto = company_profile_section?.profile_banner
    ? companyBannerPhotoUrl + company_profile_section.profile_banner
    : "/Life/job/Nogod Cover Photo.png";

  useEffect(() => {
    getInfo(setIsLoading, getAllCategoryUrl, setJobCategory);
  }, []);

  const category_name = jobCategory?.find(
    (item) => item.id == job_category_id
  )?.title;
  const data = { ...company_job_section };
  data["category_name"] = category_name;

  const location_info = {
    district: company_job_section?.district || {},
    division: company_job_section?.division || {},
    job_address: company_job_section.job_address,
  };


  return (
    <div className="position-relative job-details">
      <CompanyBanner
        profile_banner={bannerPhoto}
        profile_picture={profilePhoto}
      />
      <br />
      <div className="jobInformation px-0 px-md-2">
        <Tabs
          id="food-order-history"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3 mt-3 food-tab "
          transition={true}
        >
          <Tab eventKey="job_info" title="Job Info">
            <JobInformation data={data} />
          </Tab>
          <Tab eventKey="location" title="Location">
            <LocationInfo data={location_info} />
          </Tab>
          <Tab eventKey="company_info" title="Company">
            <CompanyInfo data={company_profile_section} />
          </Tab>
        </Tabs>
        <br />
        <div className="jobCategoriesItemBtn jobDetailsBtn noprint mt-4">
          <button disabled>Applied</button>
          <button disabled type="button">
            Saved
          </button>
          <button type="button">Share</button>
          <button onClick={(e) => handlePrint(e, printContent, printContainer)}>
            Print
          </button>
        </div>
      </div>
      <iframe
        ref={printContainer}
        title="print"
        style={{ opacity: "0" }}
      ></iframe>

      <br />
      <br />
    </div>
  );
};

export default AppliedJobDetails;
