import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useCreateVideoPlaylistMutation } from "src/app/services/viewApi/viewPostApi";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";
import { useGetOwnPlaylistMutation } from "src/app/services/viewApi/viewGetApi";

function CreatePlaylistModal({ show, handleClose }) {
  const [createPlaylist, { isSuccess, isLoading }] =
    useCreateVideoPlaylistMutation();
  const [ownPlayList] = useGetOwnPlaylistMutation();

  const [errorMsg, setErrorMsg] = useState("");
  const [playlistDetails, setPlaylistDetails] = useState({
    name: "",
    description: "-",
    status: "public",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const prevData = { ...playlistDetails };
    prevData[name] = value;
    setPlaylistDetails(prevData);
  };

  const handleSubmit = () => {
    setErrorMsg("");
    if (!playlistDetails.name) {
      setErrorMsg("Playlist Name Is Required");
      return;
    } else if (!playlistDetails.description) {
      setErrorMsg("Playlist Description Is Required");
      return;
    }
    createPlaylist(playlistDetails);

    // clearing state
    setPlaylistDetails({
      name: "",
      description: "-",
      status: "public",
    });
  };

  useEffect(() => {
    if (isSuccess) {
      // refetching the new playlist
      ownPlayList();
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Modal show={show} className="fullScreen viewModal" onHide={handleClose}>
      {isLoading ? (
        <div className="py-5">
          <DottedCircleSpinner />
        </div>
      ) : (
        <>
          <Modal.Header className="py-0" closeButton>
            <div className="py-3">Add New Playlist</div>
          </Modal.Header>
          <hr />
          <Modal.Body className="div_center">
            <div className="container-fluid">
              <div className="row mx-auto">
                <div className="col-sm-11 mx-auto  createChannel">
                  <Form>
                    <Form.Control
                      type="text"
                      placeholder="Playlist Name"
                      name="name"
                      value={playlistDetails.name}
                      onChange={handleChange}
                    />

                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="description"
                      className="mt-3"
                      value={playlistDetails.description}
                      onChange={handleChange}
                      placeholder="Playlist Description"
                    />

                    <Form.Select
                      name="status"
                      className="mt-3"
                      onChange={handleChange}
                      value={playlistDetails.status}
                    >
                      <option value="public">Public</option>
                      <option value="private">Private</option>
                    </Form.Select>
                  </Form>
                </div>
              </div>
            </div>
          </Modal.Body>
          {errorMsg && (
            <div className="text-danger text-center">{errorMsg}</div>
          )}
          <Modal.Footer>
            <Button
              onClick={handleSubmit}
              className="fileModalButton bg-light text-dark  px-lg-4"
            >
              Create
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}

export default CreatePlaylistModal;
