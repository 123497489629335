import React, { createContext } from "react";
import useStoreGlobal from "../hooks/useStoreGlobal";

export const StoreContext = createContext();
const StoreProvider = ({ children }) => {
  return (
    <StoreContext.Provider value={useStoreGlobal()}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
