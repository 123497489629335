import axios from "axios";
import Swal from "sweetalert2";
import {
  fmPostCommentRemoveUrl,
  getPostCommentCountUrl,
  showPostCommentUrl,
  singleShowPostComment,
} from "src/Projects/Life/Common/CommonUrl";

export const commentShow = (arg) => {
  const {
    PostId,
    commentCurrentPage,
    commentsData,
    setcommentsData,
    setcommentloading,
    setbuttonRowVisible,
    previousDataReset,
  } = arg;
  setcommentloading(true);

  axios
    .get(`${showPostCommentUrl}/${PostId}?page=${commentCurrentPage}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      var next_page = res.data.next_page_url;
      var responseData = res.data.data;

      if (next_page === null) {
        setbuttonRowVisible(false); //must
      } else {
        setbuttonRowVisible(true); //must
      }

      if (previousDataReset) {
        if (Array.isArray(responseData)) {
          setcommentsData([...responseData]);
        } else if (typeof responseData == "object" && responseData != null) {
          const resArray = [];
          Object.entries(responseData).map(([value]) => resArray.push(value));
          setcommentsData(resArray);
        }
      } else {
        if (Array.isArray(responseData)) {
          setcommentsData([...commentsData, ...responseData]);
        } else if (typeof responseData == "object" && responseData != null) {
          const resArray = [...commentsData];
          Object.entries(responseData).map(([value]) => resArray.push(value));
          setcommentsData(resArray);
        }
      }
      setcommentloading(false);
    })
    .catch((err) => {
      console.log(err.message);
      setcommentloading(false);
    });
};

export const getPostCommentCount = (postId, setcommentCountState) => {
  axios
    .get(`${getPostCommentCountUrl}/${postId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      setcommentCountState(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};
export const commentRemove = (arg, commentShowArg) => {
  Swal.fire({
    title: "Are you sure you want to delete this comment?",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Delete",
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      axios
        .post(`${fmPostCommentRemoveUrl}/${arg.commentId}`, "", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })

        .then((res) => {
          if (res.data.status === 1) {
            commentShow(commentShowArg);
          } else {
            console.log("err");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });
};


// edit
export const showcommentEdit = (arg) => {
  axios
    .get(`${singleShowPostComment}/${arg.commentId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      var responseData = res.data;
      // arg.setformData({"post_id":responseData.post_id,comment:responseData.comment});
      arg.seteditCommentBox({
        commentId: responseData.id,
        post_id: responseData.post_id,
        comment: responseData.comment,
      });
    })
    .catch((err) => {
      console.log(err.message);
    });
};
export const getLikedUserList = (arg) => {
  const {
    id,
    currentPage,
    setHasMore,
    preDataFormate,
    setpostLikedUserList,
    preData,
    likedUserListUrl,
  } = arg;
  console.log(id);

  axios
    .get(`${likedUserListUrl}/${id}?page=${currentPage}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      var next_page = res.data.next_page_url;
      var responseData = res.data.data;
      if (next_page === null) {
        setHasMore(false); //must
      } else {
        setHasMore(true); //must
      }
      //
      if (preDataFormate) {
        setpostLikedUserList([...responseData]);
      } else {
        setpostLikedUserList([...preData, ...responseData]);
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};
export const getLikeCount = (Url, postId, setLikeCount) => {
  axios
    .get(`${Url}/${postId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      if (res.data.status === 1) {
        setLikeCount(res.data.like_count);
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};
// export const deleteAction = (deleteActionArg) => {
//   const { id, title, url, listArg } = deleteActionArg;
//   Swal.fire({
//     title: title,
//     showCancelButton: true,
//     confirmButtonColor: "#3085d6",
//     cancelButtonColor: "#d33",
//     confirmButtonText: "Delete",
//   }).then((result) => {
//     /* Read more about isConfirmed, isDenied below */
//     if (result.isConfirmed) {
//       axios
//         .delete(`${url}/${id}`, {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("access_token")}`,
//           },
//         })
//         .then((res) => {
//           if (res.data.status === 1) {
//             const filterData = listArg.List.filter((item) => item.id !== id);
//             listArg.setList(filterData);
//           } else {
//             console.log("err");
//           }
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }
//   });
// };



