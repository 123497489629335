import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import styled from "styled-components";
import { EditCommentextArea } from "../EditCommentextArea";
import { firstStageCommentLike, replyCommentCount } from "./commentRowFunc";
import {
  replyCommentRemove,
  showPostReplyComment,
  showReplyCommentEdit,
} from "../SecondStageComment/replyCommentRowFunc";
import { ReplyCommntsRow } from "../SecondStageComment/ReplyCommntsRow";
import { CommentextArea } from "../CommentextArea";
import { LikedUserModal } from "../LikedUserList/LikedUserModal";
import {
  commentLikedUserListUrl,
  commentReplyStoreUrl,
  personalProfilePic,
  replyComLikedUserListUrl,
  updateCommentUrl,
} from "../../../../../Common/CommonUrl";
import useStore from "../../../../../../../hooks/useStore";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import { EMOJI_REGEX } from "src/utilities/utilities";
export const CommentRow = ({
  item,
  authUser,
  editCommentBox,
  seteditCommentBox,
  commentRemoveFunc,
  commentEditFunc,
  commentReload,
  commentRowArg,
}) => {
  const { getLikedUserList } = useStore();
  const ToastFunc = (icon, title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: icon,
      title: title,
    });
  };
  const [loading, setloading] = useState(false);
  const [truncateToggle, settruncateToggle] = useState(true);
  const replyCommentInitialPage = 1;
  const [replyCommentPage, setreplyCommentPage] = useState(
    replyCommentInitialPage
  );
  const [replyCommenList, setreplyCommenList] = useState([]);
  const [replyCommenListMore, setreplyCommenListMore] = useState(false);
  const [isColored, setisColored] = useState("logoColorText");
  const [colorUpdate, setcolorUpdate] = useState(false);
  const [clickedViewCommentId, setClickedViewCommentId] = useState("");
  //----------
  const [likedUserListPage, setlikedUserListPage] = useState(1);
  const [likedUserList, setpostLikedUserList] = useState([]);
  const [likedUserHasMore, setlikedUserHasMore] = useState(false);
  const [likedUsersModalState, setlikedUsersModalstate] = useState(false);
  const closeLikedUsersModal = () => {
    setlikedUserListPage(1);
    setpostLikedUserList([]);
    setlikedUsersModalstate(false);
  };
  const showLikedUsersModal = () => {
    getLikedUserListFunc();
    setlikedUsersModalstate(true);
  };
  const showReplyComLikedUsersModal = (replyCommentId) => {
    getReplyComLikedUserListFunc(replyCommentId);

    setlikedUsersModalstate(true);
  };

  //----------

  const [likeCount, setLikeCount] = useState(
    item.comment_likes_count && item.comment_likes_count
  );
  const [commentCount, setcommentCount] = useState(
    item.replies_count && item.replies_count
  );
  const personalLikeUserId =
    item.user_comment_like !== null
      ? Number(item.user_comment_like.user_id)
      : Number(0);
  const ItemCommentId = item.id;
  // zz
  const getLikedUserListFunc = () => {
    const arg = {
      id: ItemCommentId,
      currentPage: likedUserListPage,
      setHasMore: setlikedUserHasMore,
      likedUserListState: likedUserList,
      setpostLikedUserList: setpostLikedUserList,
      preDataFormate: false,
      likedUserListUrl: commentLikedUserListUrl,
    };
    getLikedUserList(arg);
  };
  const getReplyComLikedUserListFunc = (replyCommentId) => {
    const arg = {
      id: replyCommentId,
      currentPage: likedUserListPage,
      setHasMore: setlikedUserHasMore,
      likedUserListState: likedUserList,
      setpostLikedUserList: setpostLikedUserList,
      preDataFormate: false,
      likedUserListUrl: replyComLikedUserListUrl,
    };
    getLikedUserList(arg);
  };
  useEffect(() => {
    if (likedUserListPage > 1) {
      getLikedUserListFunc();
    }
  }, [likedUserListPage]);
  const likedUserModalArg = {
    likedUsersModalState: likedUsersModalState,
    closeLikedUsersModal: closeLikedUsersModal,
    likedUserListState: likedUserList,
    currentPage: likedUserListPage,

    setcurrentPage: setlikedUserListPage,
    hasMore: likedUserHasMore,
  };
  //----------

  const [editReplyCommentBoxInfo, setreplyCommentEditBoxInfo] = useState();
  const replyCommentEditFunc = (replyCommentId) => {
    const arg = {
      replyCommentId: replyCommentId,
      setreplyCommentEditBoxInfo: setreplyCommentEditBoxInfo,
    };
    showReplyCommentEdit(arg);

    // setreplyCommentEditBoxInfo((prev) => { return { ...prev, "secondStageCommentId": replyCommentId } })
  };
  const firstStageCommentArg = {
    CommentId: ItemCommentId,
    setisColored: setisColored,
    setcolorUpdate: setcolorUpdate,
    setLikeCount: setLikeCount,
  };
  useEffect(() => {
    if (replyCommentPage > 1) {
      replyCommentShowFunc(clickedViewCommentId);
    }
  }, [replyCommentPage]);

  const truncateStyleAdd = {
    maxWidth: "400px",
  };
  const truncateStyleRemove = {
    overflowWrap: "anywhere",
  };
  const formateCommentBox = () => {
    seteditCommentBox({ commentId: "", post_id: "", comment: "" });
  };
  const formateCommentReplyBox = () => {
    commentRowArg.setcommentReplyBox({
      post_id: "",
      comment_id: "",
      comment_reply: "",
    });
  };
  const inputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...editCommentBox };
    newData[name] = value;
    seteditCommentBox(newData);
  };
  const replyCommentShowFunc = (clickedCommentId) => {
    const replyCommentArg = {
      comment_id: clickedCommentId,
      replyCommentPage: replyCommentPage,
      preData: replyCommenList,
      setData: setreplyCommenList,
      setreplyCommenListMore: setreplyCommenListMore,
      previousDataReset: false,
    };
    showPostReplyComment(replyCommentArg);
  };
  const replyCommentReload = (clickedCommentId) => {
    const replyCommentArg = {
      comment_id: clickedCommentId,
      replyCommentPage: replyCommentInitialPage,
      preData: replyCommenList,
      setData: setreplyCommenList,
      setreplyCommenListMore: setreplyCommenListMore,
      previousDataReset: true,
    };
    showPostReplyComment(replyCommentArg);
  };
  const editcommentSubmitCode = (editCommentBox) => {
    if (editCommentBox.comment && editCommentBox.comment.trim() !== "") {
      const formData = {
        comment: editCommentBox.comment,
      };
      setloading(true);
      axios
        .post(`${updateCommentUrl}/${editCommentBox.commentId}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((res) => {
          if (res.data.status === 1) {
            formateCommentBox();
            commentReload();
            setloading(false);
          } else {
            setloading(false);

            ToastFunc("error", "something went wrong");
          }
        })
        .catch((err) => {
          setloading(false);
          ToastFunc("error", "something went wrong");
        });
    }
  };
  const onkeyPressSubmit = (event) => {
    if (event.type === "keypress") {
      if (event.key === "Enter" && event.shiftKey === false) {
        event.preventDefault();
        editcommentSubmitCode(editCommentBox);
      } else if (event.shiftKey && event.key === "Enter") {
      }
    } else {
      editcommentSubmitCode(editCommentBox);
    }
  };
  const replyCommentSubmitCode = (commentRowArg) => {
    const replyComment = commentRowArg.commentReplyBox;
    const commentId = replyComment.comment_id;
    delete replyComment.comment_id;
    if (
      replyComment.comment_reply &&
      replyComment.comment_reply.trim() !== ""
    ) {
      setloading(true);
      axios
        .post(`${commentReplyStoreUrl + commentId}`, replyComment, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((res) => {
          if (res.data.status === 1) {
            formateCommentReplyBox();
            const replyCommentArg = {
              comment_id: replyComment.comment_id,
              replyCommentPage: 1,
              preData: replyCommenList,
              setData: setreplyCommenList,
              setreplyCommenListMore: setreplyCommenListMore,
              previousDataReset: true,
            };
            setClickedViewCommentId(ItemCommentId);
            showPostReplyComment(replyCommentArg);
            // zz
            const replyCommentCountArg = {
              commentId: replyComment.comment_id,
              setcommentCount: setcommentCount,
            };
            replyCommentCount(replyCommentCountArg);
            setloading(false);
          } else {
            setloading(false);
            ToastFunc("error", "something went wrong");
          }
        })
        .catch((err) => {
          setloading(false);
          ToastFunc("error", "something went wrong");
        });
    }
  };
  const onkeyPressSubmitForReply = (event) => {
    if (event.type === "keypress") {
      if (event.key === "Enter" && event.shiftKey === false) {
        event.preventDefault();

        replyCommentSubmitCode(commentRowArg);
      } else if (event.shiftKey && event.key === "Enter") {
      }
    } else {
      replyCommentSubmitCode(commentRowArg);
    }
  };

  const ReplyFunc = () => {
    commentRowArg.setcommentReplyBox({
      post_id: item.post_id,
      comment_id: item.id,
      comment_reply: "",
    });
  };

  const ViewReplyFunc = () => {
    setClickedViewCommentId(item.id);
  };
  useEffect(() => {
    if (clickedViewCommentId !== "") {
      replyCommentShowFunc(clickedViewCommentId);
    }
  }, [clickedViewCommentId]);

  const replyInputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...commentRowArg.commentReplyBox };
    newData[name] = value;
    commentRowArg.setcommentReplyBox(newData);
  };
  const replyCommentRemoveFunc = (replyCommentId) => {
    const arg = {
      replyCommentId: replyCommentId,
      replyCommentPage: replyCommentPage,
      replyCommenList: replyCommenList,
      setreplyCommenList: setreplyCommenList,
    };
    replyCommentRemove(arg);
  };
  const ReplyCommntsRowArg = {
    replyCommentRemoveFunc: replyCommentRemoveFunc,
    editReplyCommentBoxInfo: editReplyCommentBoxInfo,
    setreplyCommentEditBoxInfo: setreplyCommentEditBoxInfo,
    replyCommentEditFunc: replyCommentEditFunc,
    replyCommentReload: replyCommentReload,
    showLikedUsersModal: showReplyComLikedUsersModal,
  };
  const EditCommentextAreaArg = {
    inputHandleChange: inputHandleChange,
    commentBoxInfo: editCommentBox,
    onkeyPressSubmit: onkeyPressSubmit,
    formateCommentBox: formateCommentBox,
    placeHolder: "Write comment...",
  };
  const handleEmojiClick = (text) => {
    const newData = { ...commentRowArg.commentReplyBox };
    const prev_text = newData["comment_reply"];
    newData["comment_reply"] = prev_text ? prev_text + text.emoji : text.emoji;
    commentRowArg.setcommentReplyBox(newData);
  };

  const CommentextAreaArg = {
    inputHandleChange: replyInputHandleChange,
    onkeyPressSubmit: onkeyPressSubmitForReply,
    formateCommentBox: formateCommentReplyBox,
    placeHolder: "Reply comment...",
    BorderAreaClass: "MessageInput",
    fieldName: "comment_reply",
    handleEmojiClick,
    inputValue: commentRowArg?.commentReplyBox.comment_reply,
  };

  return (
    <Container fluid>
      {/* commentEditBox */}
      {editCommentBox && editCommentBox.commentId === item.id ? (
        <EditCommentextArea data={EditCommentextAreaArg} />
      ) : (
        <>
          <Row className={`commentMargin `}>
            <Col
              md={2}
              xs={2}
              className="d-flex justify-content-end align-self-start"
            >
              {item.user.photo ? (
                <img
                  className="commentavater"
                  src={`${personalProfilePic}/${item.user.photo}`}
                  alt={item.user.first_name + item.user.last_name}
                />
              ) : (
                <img
                  className=" commentavater  "
                  src="/user.png"
                  alt={item.user.first_name + item.user.last_name}
                />
              )}
            </Col>
            <Col md={9} xs={8} className="px-0 ">
              <div>
                {item.user.first_name} {item.user.last_name}
              </div>
              <div className="d-flex  justify-content-between mb-1">
                <div
                  className={` d-inline-block text-truncate ${
                    EMOJI_REGEX.test(item?.comment) &&
                    item?.comment?.length <= 2
                      ? "comment_single_emoji"
                      : "mediumTextSize"
                  }`}
                  style={
                    truncateToggle ? truncateStyleAdd : truncateStyleRemove
                  }
                  onClick={() => settruncateToggle(!truncateToggle)}
                >
                  {item.comment}
                </div>
              </div>
              <Row>
                <Col
                  md={12}
                  className="d-flex smallTextSize align-items-center text-nowrap"
                >
                  <div
                    className={`me-md-3 me-1 pointer ${
                      personalLikeUserId === authUser.id ? isColored : ""
                    } ${colorUpdate ? "logoColorText" : ""}`}
                    onClick={() => {
                      firstStageCommentLike(firstStageCommentArg);
                    }}
                  >
                    Like
                  </div>
                  {/* like count  */}
                  {likeCount && likeCount > 0 ? (
                    <div
                      className={`me-md-3 me-2 pointer  px-2  rounded-3`}
                      onClick={showLikedUsersModal}
                    >
                      <FontAwesomeIcon
                        icon={faThumbsUp}
                        className="me-1"
                        size="sm"
                      />{" "}
                      {likeCount}
                    </div>
                  ) : null}
                  <div className="me-md-3 me-1 pointer" onClick={ReplyFunc}>
                    Reply
                  </div>
                  {item.replies_count > 0 ? (
                    <div
                      className={`me-md-3 pointer  px-2  rounded-3`}
                      onClick={ViewReplyFunc}
                    >
                      {commentCount} <span>Replies </span>
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Col>
            {Number(item.user_id) === Number(authUser.id) && (
              <Col
                md={1}
                xs={2}
                className="pe-md-0 px-xs-0 text-end align-self-center"
              >
                <Dropdown className="text-right">
                  <Dropdown.Toggle
                    aria-label="toggle button"
                    className="ToggleDesignClear"
                  >
                    {/* <img
                      className="img-fluid"
                      src="https://i.ibb.co/DLRnhQN/three-dot-menu.png"
                      alt=""
                    /> */}
                    <ThreeDots size="20" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="dark">
                    <div className="ClickThreeDot mb-3 px-3 pb-3 pt-3">
                      <div
                        className="btnHoverBorder"
                        onClick={() => commentEditFunc(item.id)}
                      >
                        <div className=" mt-1">
                          <h6>Edit</h6>
                        </div>
                      </div>
                      <div
                        className="btnHoverBorder"
                        onClick={() => commentRemoveFunc(item.id)}
                      >
                        <div className=" mt-1">
                          <h6>Remove</h6>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            )}
          </Row>

          {/* finish 1st step */}
          <Row
            className={`secondStageCommentDiv   ${
              clickedViewCommentId && clickedViewCommentId === item.id
                ? "mt-2"
                : ""
            }`}
          >
            <Col sm={11} xs={11} className="offset-1 pe-0">
              {commentRowArg.commentReplyBox.comment_id === item.id ? (
                <CommentextArea data={CommentextAreaArg} />
              ) : null}

              {clickedViewCommentId && clickedViewCommentId === item.id ? (
                <>
                  {replyCommenList.map((item) => (
                    <ReplyCommntsRow
                      item={item}
                      key={item.id}
                      ReplyCommntsRowArg={ReplyCommntsRowArg}
                    />
                  ))}
                  {replyCommenListMore && (
                    <div
                      className="smallTextSize pointer"
                      onClick={() => setreplyCommentPage(replyCommentPage + 1)}
                    >
                      View more commnts...
                    </div>
                  )}
                </>
              ) : null}
            </Col>
          </Row>
        </>
      )}
      <LikedUserModal data={likedUserModalArg} />
    </Container>
  );
};

const Wrapper = styled.div``;
