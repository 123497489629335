import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { videoThumbnailImg, channelLogoImg } from "../../common/view_commonUrl";

function SingleVideoCard({
  videoId,
  videoTitle,
  videoDesc,
  videoThumbnail,
  userName,
  userProfile,
  userId,
}) {
  const { pathname } = useLocation();
  const dynamicPath = pathname.split("/")[1];

  return (
    <Wrapper as={Link} to={`/${dynamicPath}/video/${videoId}`}>
      <div className="thumbnail-wrapper">
        <img
          className="thumbnail"
          src={videoThumbnailImg + videoThumbnail}
          alt={videoTitle}
          onError={(e) =>
            (e.target.src =
              "https://i.ibb.co/F4tpvw8/istockphoto-1147544807-612x612.jpg")
          }
        />
        <FontAwesomeIcon className="play-icon" icon={faPlay} />
      </div>

      <div className="content">
        <div className="title">{videoTitle}</div>
        <div className="desc">
          {videoDesc.substring(0, 70)}
          {videoDesc.length >= 70 && "..."}
        </div>

        <div as={Link} to={`/${dynamicPath}/channel/${userId}`}>
          <div className="user">
            <img
              src={channelLogoImg + userProfile}
              alt={userName}
              className="profile"
            />
            <div className="name">{userName}</div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
export default SingleVideoCard;

const Wrapper = styled.a`
  margin: 0.7rem auto;
  display: flex;
  align-items: center;
  gap: 1rem;

  /* 460px */
  @media (max-width: 28.75em) {
    flex-direction: column;
    max-width: 90%;
    gap: 0.5rem;
    align-items: flex-start;
    margin-block: 1rem;
  }

  /* 400 */
  @media (max-width: 25em) {
    max-width: 100%;
  }

  & .content {
    flex-basis: 20rem;
    color: var(--text-primary);

    /* 460px */
    @media (max-width: 28.75em) {
      flex-basis: inherit;
    }
  }
  & .thumbnail-wrapper {
    position: relative;
    align-self: stretch;

    & .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 1.5rem;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      color: var(--clr-white);
      transform: translate(-50%, -50%);
    }
  }

  & .thumbnail {
    width: 17rem;
    height: 9rem;
    object-fit: cover;
    background-color: var(--bg-light-gray);

    /* 576px*/
    @media (max-width: 36em) {
      width: 14rem;
    }

    /* 460px */
    @media (max-width: 28.75em) {
      width: 100%;
    }
  }

  & .title {
    font-weight: 500;
    font-size: clamp(0.95rem, 0.875rem + 0.3333vw, 1.05rem);
  }

  & .user {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 90%;

    & .profile {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
    }

    & .name {
      font-weight: 500;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
`;
