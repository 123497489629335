import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Container } from "react-bootstrap";
import "../Payment.css";

const PaymentSuccess = () => {
  const handleClose = () => {
    window.open('https://www.google.com');
    window.close();
  };
  return (
    <Container className="h-100 payment p-0 b" fluid>
      <div className="payment-card-box">
        <div className="payment-card p-2 p-sm-3 p-md-4 pb-3">
          <div className="payment-card-header mt-4">
            <FontAwesomeIcon
              className="check-icon"
              style={{ fontSize: "8rem" }}
              icon={faCheckCircle}
            />
            <h3 className="mt-4">Payment Successful</h3>
          </div>
          <div className="payment-card-body my-4">
            <p className="text-center fw-500">
              Thank you for being with{" "}
              <span className="fw-bold text-orange">UVIOM</span>
            </p>

            <p className="text-center fw-500">
              <span className="fw-bold text-orange">UVIOM </span> এর সাথে থাকার
              জন্য আপনাকে ধন্যবাদ
            </p>
          </div>
          <div className="payment-card-footer">
            <button onClick={handleClose}>
             Go to Home
            </button>
            <button onClick={handleClose}>Close</button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PaymentSuccess;
