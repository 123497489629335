import React from 'react';
// import "./addresscard.css";

const AddressCard = ({ type, data,extraClass, handleRemoveAddress = () => { } }) => {
    const { name, email, phone, address_1, address_2} = data
    return (
        <div className={`${extraClass}  py-4 `}>
            <div className='address-card-item'>
                <p>Name</p>
                <p>{name}</p>
            </div>
            <div className='address-card-item'>
                <p>Email</p>
                <p>{email}</p>
            </div>
            <div className='address-card-item'>
                <p>Phone</p>
                <p>{phone}</p>
            </div>
            <div className='address-card-item'>
                <p>Address 1</p>
                <p>{address_1}</p>
            </div>
            <div className='address-card-item'>
                <p>Address 2</p>
                <p>{address_2}</p>
            </div>

            {/* <div className='text-end mb-2'>
                <button type='button' onClick={() => handleRemoveAddress(id, type)} className="eco-btn  text-danger">Remove Address</button>
            </div> */}
        </div>
    );
};
export default AddressCard;