import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { EffectCoverflow, Pagination, Autoplay } from "swiper";
import StoreSliderItem from "./StoreSliderItem";

const HomeStore = ({ vendor_slider, vendor_loading }) => {
  return (
    <div className="corporate__store-slider ">
      <Swiper
        navigation={false}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={2}
        coverflowEffect={{
          rotate: -20,
          stretch: 0,
          depth: 300,
          modifier: 1,
          slideShadows: true,
        }}
        loop={true}
        speed={3000}
        autoplay={{ delay: 1000, disableOnInteraction: true }}
        breakpoints={{
          // when window width is >= 320px
          576: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // when window width is >= 480px
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          // when window width is >= 640px
          992: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        spaceBetween={50}
        // pagination={true}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        className="mySwiper category-top-slider"
      >
        {vendor_loading
          ? [...new Array(7)].map((_, index) => (
              <SwiperSlide key={index}>
                <div className="placeholder-store-box"></div>
              </SwiperSlide>
            ))
          : vendor_slider &&
            vendor_slider.length > 0 &&
            vendor_slider.map((data, index) => (
              <SwiperSlide key={index}>
                <StoreSliderItem data={data} />
              </SwiperSlide>
            ))}
      </Swiper>
    </div>
  );
};

export default HomeStore;
