import { useLocation, Link, useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import "./productForm.css";

function ProductForm() {
  const { pathname:url } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="deliveryForm">
      <Row>
        <Form>
          <Col>
            <Form.Group>
              <Form.Select>
                <option>Product Category</option>
                <option>Product Category</option>
                <option>Product Category</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mt-3">
              <Form.Label htmlFor="productName">Product Name</Form.Label>
              <Form.Control
                type="text"
                itemID="productName"
                placeholder="Product Name"
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className="mt-3">
              <Form.Label htmlFor="productDetails">Product Details</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Product Details"
                rows={6}
              />
            </Form.Group>
          </Col>
          <Col>
            <div className="d-md-flex align-items-center justify-content-between bg-white p-4 rounded mt-5">
              <h5 className="m-md-0">Dimension's</h5>
              <div className="productForm__dimension">
                <input type="text" placeholder="Length" />
                <input type="text" placeholder="Width" />
                <input type="text" placeholder="Deep" />
                <input type="text" placeholder="Color" />
                <input type="text" placeholder="Quantity" />
              </div>
            </div>
          </Col>
          <div className="text-center mt-4 mt-sm-5">
            <Button
              onClick={() => navigate(-1)}
              variant="outline-dark"
              className="dark-invert me-3 px-3"
            >
              Back
            </Button>
            <Button as={Link} to={`${url}/vehicle`} className="btn__ride px-4">
              Next
            </Button>
          </div>
        </Form>
      </Row>
    </div>
  );
}
export default ProductForm;
