import { useLocation } from "react-router-dom";

// Same as ride
import IntersectionWithRider from "./../../Ride/components/IntersectionWithRider";

function GoToReceive() {
  const { pathname:url } = useLocation();
  const dynamicPath = url.split("/")[1];

  return (
    <div id="wrapper">
      <div>
        <iframe
          className="rideMap"
          title="rideMap"
          src="https://maps.google.com/maps?output=embed&ll=54.868705,-1.593018&z=11"
          width="100%"
        ></iframe>
      </div>

      <div className="py-4 py-md-5" />

      <IntersectionWithRider
        riderImage="https://i.ibb.co/HCXF0rh/rider.jpg"
        riderName="Rabiul Islam"
        nextRoute={`/${dynamicPath}/delivery/received`}
        nextRouteText="Received"
      />
      <div className="py-4 py-md-5" />
    </div>
  );
}
export default GoToReceive;
