import React from "react";

import { Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { SingleCard } from "./SingleCard";
export const AllBrand = () => {
  const { state } = useLocation();
  // const { brand_logo } = state.allBrandData;

  return (
    <Container fluid className="py-4  box-shadow px-3">
      <h4 className="py-2">Brands</h4>
      <Row className="home__Product-box g-2 ">
        {state.allBrandData &&
          state.allBrandData.map((item, index) => (
            <SingleCard key={index} item={item} />
          ))}
      </Row>
    </Container>
  );
};
