import React from "react";
import { Col, Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { SingleLinkBar } from "../Common/SingleLinkBar";

export const MiddleLeftCol = () => {
  let { pathname } = useLocation();
  const [, dynamicPath, dynamicLife] = pathname.split("/");
  // note::: [routeNames,title]
  const routeNames = [
    ["general_info", "General Info"],
    ["works", "Works"],
    ["education", "Education"],
    ["address", "Address"],
    ["basic-info", "Basic Info"],
    ["relationship", "Relationship"],
    ["details-about", "Details About"],
    ["events", "Events"],
    ["sports", "Sports"],
    ["film", "Film"],
    ["music", "Music"],
    ["tv-program", "Tv Program"],
    ["books", "Books"],
  ];

  return (
    <>
      <AboutTab>
        <Link to={`/${dynamicPath}/${dynamicLife}/about/`}>
          <div className="LookMeTypeButton justify-content-start">
            <h6 className="ps-4 mb-0">Overview</h6>
          </div>
        </Link>
        {routeNames.map((route, index) => (
          <SingleLinkBar
            key={`i+${index}`}
            placeholder={route[1]}
            link={`/${dynamicPath}/${dynamicLife}/about/${route[0]}`}
          />
        ))}
      </AboutTab>
      <div
        className={`${
          pathname.includes("/public-life")
            ? "col-12 d-block "
            : "col-12 d-block d-sm-none"
        }`}
      >
        <Dropdown>
          <Dropdown.Toggle aria-label="overview" className="aboutDropdown">
            Overview
          </Dropdown.Toggle>
          <Dropdown.Menu className=" aboutDropdownBody">
            <Dropdown.Item
              className="jobDropdownItem"
              as={Link}
              to={`/${dynamicPath}/${dynamicLife}/about`}
            >
              Overview
            </Dropdown.Item>
            {routeNames.map((routeName, index) => (
              <Dropdown.Item
                className="jobDropdownItem"
                as={Link}
                to={`/${dynamicPath}/${dynamicLife}/about/${routeName[0]}`}
                key={index}
              >
                {routeName[1]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

const AboutTab = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 1rem;

  padding-block: 0.5rem;
  padding-inline: 0.2rem;

  @media (max-width: 36em) {
    display: none;
  }
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  & .LookMeTypeButton {
    margin: 0 !important;

    & .ps-4 {
      padding-inline: 0.7rem !important;
    }
  }
`;
