import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate, useLocation } from "react-router-dom";
import { videoStoryThumbnailUrl } from "src/Projects/Life/Common/CommonUrl";
import getProfilePic from "./getProfilePic";

const BestTimeItem = ({ data, index }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, dynamicPath, dynamicLife] = pathname.split("/");
  const { video_thumbnail } = data.name;
  const { last_name, first_name } = data.has_user;
  const { video_caption } = data.has_caption || "caption";

  let profileImage = getProfilePic(data.profile_images);
  const photoUrl = videoStoryThumbnailUrl + video_thumbnail;

  return (
    <div
      tabIndex={0}
      onClick={() =>
        navigate(
          `/${dynamicPath}/${dynamicLife}/post-best-time/view-video/${data.video_id}`,
          {
            index,
          }
        )
      }
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          navigate(
            `/${dynamicPath}/${dynamicLife}/post-best-time/view-video/${data.video_id}`,
            {
              index,
            }
          );
        }
      }}
      style={{ cursor: "pointer" }}
      className="BestPhoto"
    >
      <img
        className="BestPhoto__user"
        src={profileImage}
        alt={`${first_name} ${last_name}`}
        onError={(e) => (e.target.src = "https://i.ibb.co/MprdZS6/user.png")}
      />

      <LazyLoadImage
        effect="blur"
        className="img-fluid"
        src={photoUrl}
        alt={video_caption || "caption"}
      />
    </div>
  );
};

export default BestTimeItem;
