import axios from "axios";
import Swal from "sweetalert2";
import {
  commentLikePUrl,
  commentLikeCountUrl,
  replyCommentCountUrl,
} from "../../../../../Common/CommonUrl";

const ToastFunc = (icon, title) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  Toast.fire({
    icon: icon,
    title: title,
  });
};
export const firstStageCommentLike = (firstStageCommentArg) => {
  const commentId = firstStageCommentArg.CommentId;
  axios
    .post(`${commentLikePUrl}/${commentId}`, "", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      if (res.data.status === 1) {
        if (res.data.message === "Comment Liked successfully") {
          firstStageCommentArg.setisColored("logoColorText");
          firstStageCommentArg.setcolorUpdate(true);

          getLikeCount(
            commentLikeCountUrl,
            commentId,
            firstStageCommentArg.setLikeCount
          );
        } else {
          //   like remove section
          firstStageCommentArg.setisColored("");
          firstStageCommentArg.setcolorUpdate(false);
          getLikeCount(
            commentLikeCountUrl,
            commentId,
            firstStageCommentArg.setLikeCount
          );
        }
      }
    })
    .catch((err) => {
      ToastFunc("error", "something went wrong");
    });
};
export const getLikeCount = (commentLikeCountUrl, commentId, setLikeCount) => {
  axios
    .get(`${commentLikeCountUrl}/${commentId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      setLikeCount(res.data);
    })
    .catch((err) => {
      console.log(err.message);
    });
};
export const replyCommentCount = (replyCommentCountArg) => {
  axios
    .get(`${replyCommentCountUrl}/${replyCommentCountArg.commentId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      replyCommentCountArg.setcommentCount(res.data);
    })
    .catch((err) => {
      console.log(err.message);
    });
};
// export const showcommentEdit = (arg) => {
// axios.get(`${singleShowPostComment}/${arg.commentId}`, {
//     headers: {
//         Authorization: `Bearer ${localStorage.getItem("access_token")}`,
//     },
// })
//     .then((res) => {
//         var responseData = res.data;
//         // arg.setformData({"post_id":responseData.post_id,comment:responseData.comment});
//         arg.seteditCommentBox({ "commentId": responseData.id, "post_id": responseData.post_id, comment: responseData.comment });

//     }).catch((err) => {
//         console.log(err.message);
//     });

// }
