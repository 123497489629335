import {
  NavLink,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import RunningProject from "./../RunningProject";

import "./orders.css";

function Order() {
  const { pathname: url } = useLocation();
  const dynamicPath = url.split("/")[1];
  const dynamicUser = url.split("/")[2];
  return (
    <div>
      <div className="orders__links">
        <NavLink to={`/${dynamicPath}/${dynamicUser}/orders/complete`}>
          Complete
        </NavLink>
        <NavLink to={`/${dynamicPath}/${dynamicUser}/orders/cancel`}>
          Cancel
        </NavLink>
        <NavLink to={`/${dynamicPath}/${dynamicUser}/orders/pending`}>
          Pending
        </NavLink>
        <NavLink to={`/${dynamicPath}/${dynamicUser}/orders/running`}>
          Running
        </NavLink>
      </div>

      <div>
        <OrdersRoutes />
      </div>
    </div>
  );
}
export default Order;

const OrdersRoutes = () => {
  return (
    <Routes>
      <Route path={`/complete`} element={<RunningProject />} />
      <Route path={`/cancel`} element={<RunningProject />} />
      <Route path={`/pending`} element={<RunningProject />} />
      <Route path={`/running`} element={<RunningProject />} />
      <Route path="*" element={<Navigate to="running" replace />} />
    </Routes>
  );
};
