/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { editPostUrl } from "src/Projects/Life/Common/CommonUrl";
import { Posting } from "./Posting";
import { Updating } from "./Updating";
import { showToast } from "src/Projects/Job/utilities/functions";
import CloseIcon from "src/Projects/Life/icons/CloseIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const createDefaultData = {
  family_post_status: true,
  friend_post_status: false,
  professional_post_status: false,
  public_post_status: false,
};

export const PostModal = ({ postModalArg, type }) => {
  const { activity, show, handleClose, title, post_id } = postModalArg;
  const { post_contents, post_details } = postModalArg?.singlePostData || {};

  const [modalTitle] = useState(title);
  const [myFormData, setFormData] = useState(createDefaultData);
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [videoFiles, setvideoFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [canceled, setCanceled] = useState(false);

  useEffect(() => {
    if (post_contents) {
      setImages(post_contents);
    }
  }, [post_contents, show]);

  useEffect(() => {
    if (post_details) {
      setFormData({ post_details });
    }
  }, [post_details, show]);

  const formateData = () => {
    setFormData({});
    setImages([]);
    setvideoFiles([]);
    setSelectedFiles([]);
    setErrorMessage("");
    handleClose();
  };

  const callAfterRejected = (err) => {
    setIsLoading(false);
    handleClose();
    formateData();
    setErrorMessage(err.message);
    showToast({ title: err.message, icon: "error" });
  };

  const callAfterSuccess = () => {
    setIsLoading(false);
    handleClose();
    formateData();
  };

  const postingArg = {
    callAfterRejected,
    callAfterSuccess,
    setIsLoading,
    errorMessage,
    setErrorMessage,
    myFormData,
    setFormData,
    setvideoFiles,
    setImages,
    images,
    videoFiles,
    selectedFiles,
    setSelectedFiles,
    handleClose,
  };

  const updatingArg = {
    post_id: post_id,
    errorMessage: errorMessage,
    setErrorMessage: setErrorMessage,
    myFormData: myFormData,
    setFormData: setFormData,

    uploadUrl: editPostUrl,
    handleClose: handleClose,
    formateData: formateData,
    setvideoFiles: setvideoFiles,
    setImages: setImages,
    images: images,
    videoFiles: videoFiles,
    selectedFiles: selectedFiles,
    setSelectedFiles: setSelectedFiles,
  };

  return (
    <Modal
      id="PostModalPopup"
      className="/trans-bg"
      show={show}
      onHide={() => {}}
    >
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center position-relative"
          style={{ minHeight: "17rem" }}
        >
          <FontAwesomeIcon icon={faSpinner} spin size="2x" className="me-2" />
          <span className="fs-4" style={{ letterSpacing: "2px" }}>
            {isLoading}
          </span>

          <button
            onClick={() => setCanceled(true)}
            disabled={canceled}
            className="Post-cancel-btn "
          >
            Cancel
          </button>
        </div>
      ) : (
        <>
          <Modal.Header className="text-center">
            <Modal.Title as="h5" className="mx-auto">
              {modalTitle}
            </Modal.Title>
            <button
              className="btn-0 postModal-close-btn"
              type="button"
              onClick={formateData}
            >
              <CloseIcon size="16" className="primary-text" />
            </button>
          </Modal.Header>
          {activity === "posting" ? (
            <Posting postingArg={postingArg} type={type} canceled={canceled} />
          ) : (
            <Updating updatingArg={updatingArg} />
          )}
        </>
      )}
    </Modal>
  );
};
