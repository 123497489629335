import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import "./receiptBox.css";

function ReceiptBox() {
  return (
    <>
      <div className="receiptBox mt-3 mb-4 mb-md-5">
        <div className="receiptBox__user">
          <p className="receiptBox__user--info">
            <span>Kamrul Dolon</span>
            <br />
            <span>kamrul@dolon.com</span>
            <br />
            <span className="fw-500">10 Jun 2022</span>
          </p>
          <p className="receiptBox__user--location">
            <span className="fw-500">Pick Up</span>
            <span className="fw-500">Drop Off</span>
            <span>Mirpur-1 Bus Stand</span>
            <span>Dhanmondi 32</span>
            <span>8.00 PM</span>
            <span>8.53 PM</span>
          </p>
          <p className="receiptBox__user--details">
            <span>Total Distance: </span>
            <span>15KM</span>
            <span>Time: </span>
            <span>55Min</span>
          </p>
        </div>

        <div className="receiptBox__payment">
          <div className="receiptBox__payment--paid">
            <span>You Paid</span>
            <span>$20.00</span>
          </div>
          <div className="receiptBox__payment--method">
            <span>Payment Method</span>
            <span>UVIOM Wallet</span>
          </div>
        </div>

        <div className="receiptBox__rideInfo">
          <p>Rider</p>

          <div className="receiptBox__rider">
            <div className="receiptBox__rider--img">
              <img src="https://i.ibb.co/HCXF0rh/rider.jpg" alt="" />
            </div>
            <div>
              <p className="receiptBox__rider--name">Rabiul Islam</p>
              <p className="receiptBox__rider--rating">
                <FontAwesomeIcon icon={faStar} /> (5.00) 35 Rating
              </p>
            </div>
            <div>
              <p className="receiptBox__rider--car">Car Model</p>
              <p className="receiptBox__rider--carModel">(2BC-458266)</p>
            </div>
          </div>
          <p className="receiptBox__userProvidedRating">
            Your Rating <FontAwesomeIcon icon={faStar} />{" "}
            <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} />{" "}
            <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} />{" "}
          </p>

          <p className="receiptBox__details">
            <span>Ride Fare</span>
            <span>$15.00</span>
            <span>Additional Drop</span>
            <span>$5.00</span>
            <span>Sales Tax</span>
            <span>$3.00</span>
            <span>Trolls Suchaecge and fees</span>
            <span>$2.00</span>
            <span className="fw-500">Total</span>
            <span className="fw-500">$25.00</span>
          </p>

          <p className="receiptBox__greeting">
            Thank You Riding with UVIOM Ride
          </p>
        </div>
      </div>
      <div className="receiptBox__buttons">
        <Button className="btn__ride shadow">Print</Button>
        <Button className="btn__ride shadow">Save</Button>
        <Button className="btn__ride shadow">Share</Button>
      </div>
    </>
  );
}
export default ReceiptBox;
