import React from "react";
import CompanySingleInfo from "../../Components/Common/CompanySingleInfo";

const ApplicantAddress = ({ data }) => {
  const { district, division } = data;
  return (
    <div className="applicantProfile p-2 p-lg-3 p-xl-2">
      <CompanySingleInfo
        title="Division"
        value={division?.name || "not found"}
      />
      <CompanySingleInfo
        title="District"
        value={district?.name || "not found"}
      />
      <CompanySingleInfo title="Present Address" value={data?.present_address} />
      <CompanySingleInfo title="Permanent Address" value={data?.permanent_address} />
    </div>
  );
};

export default ApplicantAddress;
