import React, { useState } from "react";
import { Link } from "react-router-dom";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";

const SavePostItem = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu((prevState) => !prevState);
  };
  const closeMenu = () => setShowMenu(false);

  return (
    <div className="my-3">
      <div className="body-dark-bg p-2 d-flex align-items-center justify-content-between rounded-2">
        <div className="d-flex ">
          <div className="rounded-2 overflow-hidden me-2">
            <img
              src="/user.png"
              className="img img-fluid"
              style={{ height: "70px" }}
              alt="user"
            />
          </div>
          <div className="">
            <p
              className="font-14 text-ellipsis mb-1 mt-2"
              style={{ width: 200 }}
            >
              Lorem Ipsum is simply dummy text of the printing
            </p>
            <p className="mb-2">By Kamrul Dolon</p>
          </div>
        </div>

        <div className="d-flex position-relative ">
          <Link className="me-3 sava__post__view-btn d-none d-sm-block" to="">
            View Post
          </Link>
          <div className="mt-1  pointer" tabIndex={0} onBlur={closeMenu}>
            <span onClick={toggleMenu}>
              <ThreeDots className="text-info" size="20" />
            </span>

            {showMenu && (
              <div
                tabIndex={2}
                className="save__post__three__dot-menu position-absolute"
              >
                <button>Remove</button>
                <button>share</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavePostItem;
