import React, { useState } from "react";
import { Col } from "react-bootstrap";
import {
  deleteStoryVideoUrl,
  videoStoryThumbnailUrl,
} from "src/Projects/Life/Common/CommonUrl";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import deleteData from "../apiCall/deleteData";
import CaptionUpdateModal from "../Modal/CaptionUpdateModal";
import StatusUpdateModal from "../Modal/StatusUpdateModal";
import VideoShowModal from "../Modal/VideoShowModal";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";
import "./BestTimeCard.css";

const BestTimeCard = ({ data, allData, setAllData }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showCaptionModal, setShowCaptionModal] = useState(false);
  const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleMenu = () => {
    setShowMenu((prevState) => !prevState);
  };
  const closeMenu = () => setShowMenu(false);
  const closeCaptionModal = () => setShowCaptionModal(false);
  const closeStatusUpdateModal = () => setShowStatusUpdateModal(false);
  const closeVideoModal = () => setShowVideoModal(false);

  const { name, has_user, has_caption, id, status } = data;
  const thumbnail = name?.video_thumbnail;
  const photoThumbnail = videoStoryThumbnailUrl + thumbnail;
  const userName = has_user?.first_name + " " + has_user.last_name;

  const callAfterDeleteSuccess = () => {
    const remainData = allData.filter((item) => item.id !== id);
    setAllData([...remainData]);
  };

  const handleDelete = () => {
    const deleteDataArg = {
      Url: deleteStoryVideoUrl + id,
      setIsLoading,
      callAfterSuccess: callAfterDeleteSuccess,
    };
    deleteData(deleteDataArg);
  };

  return (
    <Col>
      {isLoading && (
        <div className={"item bubble-loader-center"}>
          <DottedCircleSpinner />
        </div>
      )}
      <div
        className={`videoPlayerContainer bestTimeCardContainer rounded-3 mx-auto position-relative`}
        style={{
          height: "100%",
          aspectRatio: "9/16",
          overflow: "hidden",
        }}
      >
        <img
          src={photoThumbnail}
          className="img img-fluid"
          style={{ width: "100%" }}
          alt="thumbnail"
        />
        <div className="smallVideoCardIcon" style={{ cursor: "default" }}>
          <img
            onClick={() => setShowVideoModal(true)}
            style={{ width: "35px", height: "35px" }}
            src="/Life/icons/pause.png"
            className="border-0 pointer"
            alt="pause"
          />
        </div>

        <div className="bestTimeCardText">
          <p className="text-center text-ellipsis text-white">
            {has_caption?.video_caption}
          </p>
        </div>

        <div className="bestTime__card-threeDot-box">
          <button onClick={toggleMenu} className="pointer btn-0">
            <ThreeDots size="20px" className="pointer" />
          </button>
        </div>

        {showMenu && (
          <div className="bestTime__card-threeDot-option" onBlur={closeMenu}>
            <button className="btn-0" onClick={() => setShowCaptionModal(true)}>
              Update caption
            </button>
            <button
              onClick={() => setShowStatusUpdateModal(true)}
              className="btn-0"
            >
              Edit Audience
            </button>
            <button onClick={handleDelete} className="btn-0">
              Delete
            </button>
          </div>
        )}
      </div>

      <VideoShowModal
        show={showVideoModal}
        handleClose={closeVideoModal}
        data={data}
      />

      <CaptionUpdateModal
        show={showCaptionModal}
        handleClose={closeCaptionModal}
        caption={has_caption?.video_caption}
        storyId={id}
      />
      <StatusUpdateModal
        show={showStatusUpdateModal}
        handleClose={closeStatusUpdateModal}
        data={{ id, status }}
      />
    </Col>
  );
};

export default BestTimeCard;
