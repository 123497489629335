import React from "react";
import { Form, Row } from "react-bootstrap";
import ImageInputBox from "src/Common/ImageInputBox/ImageInputBox";
import InputSelectWithLabel from "src/Common/InputSelectWithLabel/InputSelectWithLabel";
import TextareaInputBox from "src/Common/TextareaInputBox/TextareaInputBox";
import TextInputBox from "src/Common/TextInputBox/TextInputBox";

const AddProduct = () => {
  return (
    <div className="secondary-bg p-2">
      <h6 className="fs-5 py-2 text-orange">Add Product</h6>

      <Form>
        <Row>
          <InputSelectWithLabel label={"Product Category"} md="6" xxl={"4"}>
            <option value="">Product Category</option>
            <option value="1">Product Category 1</option>
            <option value="3">Product Category 2</option>
            <option value="2">Product Category 3</option>
          </InputSelectWithLabel>
          <InputSelectWithLabel label={"Sub  Category "} md="6" xxl={"4"}>
            <option value="">Sub Category </option>
            <option value="1">Sub Category 1</option>
            <option value="3">Sub Category 2</option>
            <option value="2">Sub Category 3</option>
          </InputSelectWithLabel>
          <InputSelectWithLabel label={"Sub-Sub  Category "} md="6" xxl={"4"}>
            <option value="">Sub-Sub Category </option>
            <option value="1">Sub-Sub Category 1</option>
            <option value="3">Sub-Sub Category 2</option>
            <option value="2">Sub-Sub Category 3</option>
          </InputSelectWithLabel>

          <TextInputBox
            xxl={4}
            md="6"
            label={"Product Name"}
            placeholder={"Product Name "}
          ></TextInputBox>
          <TextInputBox
            xxl={4}
            md="6"
            label={"Product Model"}
            placeholder={"Product Model"}
          ></TextInputBox>
          <TextInputBox
            xxl={4}
            md="6"
            label={"Brand "}
            placeholder={"Brand  "}
          ></TextInputBox>

          <ImageInputBox
            xxl={4}
            md="6"
            label={"Thumbnail Image"}
            placeholder={"Thumbnail Image"}
          ></ImageInputBox>
          <ImageInputBox
            xxl={4}
            md="6"
            label={"Featured Image"}
            placeholder={"Featured Image"}
          ></ImageInputBox>
          <TextInputBox
            xxl={4}
            md="6"
            label={"Quantity"}
            placeholder={"Quantity"}
          ></TextInputBox>
          <TextInputBox
            xxl={4}
            md="6"
            label={"Color"}
            placeholder={"Color"}
          ></TextInputBox>
          <TextInputBox
            xxl={4}
            md="6"
            label={"Cost Price"}
            placeholder={"Cost Price"}
          ></TextInputBox>
          <TextInputBox
            xxl={4}
            md="6"
            label={"Retail Price"}
            placeholder={"Retail Price"}
                  ></TextInputBox>
                  
                  <TextareaInputBox
                       label={"Product Details"}
                       placeholder={"Product Details"}
                  >
                      
                  </TextareaInputBox>
        </Row>
        <br />
        <div className="text-end">
        <button type="button" className="send-code-btn me-2">Preview</button>
        <button type="submit" className=" send-code-btn cart-confirm-btn">Submit</button>
      </div>


      </Form>
      <br />
    </div>
  );
};

export default AddProduct;
