import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Badge, Col, Row } from "react-bootstrap";

export const EditCommentextArea = ({ data }) => {
  const {
    inputHandleChange,
    commentBoxInfo,
    onkeyPressSubmit,
    formateCommentBox,
    placeHolder,
  } = data;

  return (
    <>
      <Row className={`commentMargin MessageInput mb-0 pb-0 `}>
        <Col sm={2} xs={2} className="px-xs-3">
          <img
            className="img-fluid w-100 rounded-circle"
            src="/user.png"
            alt="user"
          />
        </Col>
        <Col sm={9} xs={8} className="px-0">
          <textarea
            className="write-comment-input"
            cols="100"
            type="text"
            name="comment"
            onChange={inputHandleChange}
            value={commentBoxInfo.comment}
            placeholder={placeHolder}
            onKeyPress={onkeyPressSubmit}
          />
        </Col>
        <Col sm={1} xs={2} className="px-0 .align-self-center text-center">
          <FontAwesomeIcon
            icon={faPaperPlane}
            className="pointer"
            onClick={onkeyPressSubmit}
          />
        </Col>
      </Row>
      <div
        className="smallTextSize d-inline-block pointer ms-4"
        md={4}
        onClick={formateCommentBox}
      >
        Cancel
      </div>
    </>
  );
};
