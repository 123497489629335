import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilm } from "@fortawesome/free-solid-svg-icons";
import { SingleItem } from "./SingleItem";
import { isEmpty } from "lodash";

export const Film = ({ info }) => {
  const [data, setdata] = useState();
  useEffect(() => {
    if (!isEmpty(info)) {
      setdata(info.apps_games_info);
      // console.log(info)
    }
  }, [info]);
  const componentMainIcon = faFilm;

  return (
    <div className="aboutBox mt-1 mb-4 ">
      <div className="border-bottom pb-2 border-white">
        <div className="d-flex ">
          <div className="me-2 ">
            <FontAwesomeIcon icon={faFilm} />
          </div>
          <div>
            <h6>Films</h6>
          </div>
        </div>
      </div>
      <div className="mt-5">
        {data &&
          data.map((item, index) => (
            <SingleItem
              key={index}
              item={item}
              componentMainIcon={componentMainIcon}
            />
          ))}
      </div>
    </div>
  );
};
