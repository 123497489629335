import React from "react";
import { Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { TimeLines } from "./TimeLines";
import { LifeIcon } from "src/DashBord/Icon/LifeIcon";
import YouMayKnow from "./YouMayKnow";
import SponsoredSection from "./SponsoredSection";

const LeftCol = () => {
  const { pathname } = useLocation();
  const [, dynamicPath, dynamicLife] = pathname.split("/");

  let iconLife;

  if (dynamicLife === "public-post") {
    iconLife =
      "https://res.cloudinary.com/yex/image/upload/v1669445295/UVIOM/public-life-Icon_ph5nj2.svg";
  } else if (dynamicLife === "family-life") {
    iconLife =
      "https://res.cloudinary.com/yex/image/upload/v1669445293/UVIOM/family-life-icon_opd6du.svg";
  } else if (dynamicLife === "friends-life") {
    iconLife =
      "https://res.cloudinary.com/yex/image/upload/v1669445294/UVIOM/friends-life-icon_s8kjfc.svg";
  } else if (dynamicLife === "professional-life") {
    iconLife =
      "https://res.cloudinary.com/yex/image/upload/v1669445294/UVIOM/professional-life-icon_mzhdfv.svg";
  }

  return (
    <Col
      className="d-none d-lg-block stickyContainer leftCol px-md-sm-1"
      md={3}
      lg={3}
    >
      <div
        id="timeline"
        className="stickyParts pb-4 vh-100 no-scrollbar scroll-y px-1"
      >
        <div className="col-6 col-lg-12 stickyParts z-index-2  ">
          <div className="LookMeWhiteIcon box-shadow nav-bg-opacity stickyParts py-2 ps-3 d-block">
            <div className="d-flex">
              <div
                className="LookMeButtonTypeIcon"
                // onClick={() => navigate("/dashboard")}
              >
                {/* <LifeIcon size="40" /> */}
                <img src={iconLife} alt="" />
              </div>
              <Link to={`/${dynamicPath}/${dynamicLife}`}>
                <div className="type-writer-text-box">
                  <h5 className="mb-0">
                    <span className="text-capitalize">
                      {/* current life from url */}
                      {dynamicLife.split("-")[0]}
                    </span>
                    <span className="text-orange"> Life</span>
                  </h5>
                  <div className="lh-1 overflow-hidden type-writer-text logo-slogan">
                    <Typewriter
                      options={{
                        strings: "Find Your Roots",
                        autoStart: true,
                        loop: true,
                      }}
                    ></Typewriter>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <TimeLines />
        <YouMayKnow />
        {dynamicLife === "public-post" && <SponsoredSection />}
      </div>
    </Col>
  );
};

export default LeftCol;
