import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PhotoSlider } from "react-photo-view";
import { temporaryImageUrl } from "src/Projects/Life/Common/CommonUrl";
import { SeeMore } from "src/Projects/Life/Components/SeeMore";

export const MiddleContent = ({ MiddleContentArg }) => {
  const { post } = MiddleContentArg;
  const { post_details } = post;
  const imageContents = post.post_contents;

  const contentLength = imageContents && imageContents.length;
  const lengthEqualOne = imageContents && contentLength === 1;
  const lengthEqualThree = imageContents && contentLength === 3;
  const lengthMoreThenFour = imageContents && contentLength > 4;
  const [isGalleryActive, setIsGalleryActive] = useState(false);
  const [galleryIndex, setgalleryIndex] = useState();
  const setIsGalleryActiveFnc = (setIndexNumber) => {
    setgalleryIndex(setIndexNumber);
    setIsGalleryActive(true);
  };
  return (
    <>
      {imageContents.length > 0 && (
        <div className="col-12 NewsPhotoDiv mt-2 position-relative">
          {imageContents.slice(0, 4).map((item, index) => (
            <div
              key={item.id}
              className={`  float-start ${
                lengthEqualOne ? "oneImageCss" : "multiImageCss"
              } ${lengthEqualThree && index === 0 ? "threeImageCss" : ""} ${
                lengthMoreThenFour && index === 3 ? "MoreThenFourImages" : ""
              }`}
            >
              <LazyLoadImage
                effect="blur"
                placeholderSrc="/Life/placeholder.jpg"
                className={` img-fluid `}
                src={`${temporaryImageUrl}/${item.source}${item.content_name}`}
                alt={post_details}
                onClick={() => setIsGalleryActiveFnc(index)}
                width="100%"
                height="100%"
                threshold={300}
              />

              {lengthMoreThenFour && index === 3 && (
                <button
                  type="button"
                  className="btn btn-secondary  width-fit-content bseemorePhotoBtn rounded-0 absolute-center smallTextSize-sm"
                  onClick={() => setIsGalleryActiveFnc(index)}
                >
                  See More {contentLength - 4} Photos
                </button>
              )}
            </div>
          ))}
          <PhotoSlider
            images={imageContents.map((item) => ({
              src: `${temporaryImageUrl}/${item.source}${item.content_name}`,
              key: item.id,
            }))}
            visible={isGalleryActive}
            onClose={() => setIsGalleryActive(false)}
            index={galleryIndex}
            onIndexChange={setgalleryIndex}
          />
        </div>
      )}
      <div className="col-12 pt-2 px-3 text-align-justify ">
        <small>
          {post_details !== "null" ? <SeeMore>{post_details}</SeeMore> : ""}
        </small>
      </div>
    </>
  );
};
