import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import HomeIcon from "src/Projects/Life/icons/HomeIcon";
import LiveVideoIcon from "src/Projects/Life/icons/LiveVideoIcon";
import MessageIcon from "src/Projects/Life/icons/MessageIcon";
import SearchIcon from "src/Projects/Life/icons/SearchIcon";
import VideoIcon from "src/Projects/Life/icons/VideoIcon";
import NavigationOffCanvas from "./NavigationOffCanvas";

export const TopNavBar = memo(
  ({ openSearchbox, provided_url = null, searchBoxShow }) => {
    const { pathname } = useLocation();
    const [, dynamicPath, dynamicLife] = pathname.split("/");
    let base_url = `/${dynamicPath}/${dynamicLife}`;
    if (provided_url) {
      base_url = provided_url;
    }

    return (
      <Container
        fluid
        className="customContainerFluidPadding mb-3 stickyParts z-index-3"
        id="main-navigation"
      >
        <div role="navigation" className="MainMenuBar">
          <NavLink
            className="topNavItem"
            aria-label="home"
            to={`${base_url}`}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0 });
            }}
            end
          >
            <HomeIcon className="topNavIcon" size="25" />
          </NavLink>
          <NavLink
            className="topNavItem"
            aria-label="videos"
            to={`${base_url}/watch-video`}
          >
            <VideoIcon className="topNavIcon" size="25" />
          </NavLink>

          <NavLink
            className="d-none d-md-flex topNavItem"
            aria-label="live"
            to={`${base_url}/go-live`}
          >
            <LiveVideoIcon className="topNavIcon mt-2" size="28" />
          </NavLink>
          <div
            className={`topNavItem ${searchBoxShow && "active"}`}
            role="search"
            onClick={openSearchbox}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                openSearchbox();
              }
            }}
          >
            <SearchIcon className="topNavIcon " size="25" />
          </div>
          <NavLink
            className="topNavItem"
            aria-label="notification"
            to={`${base_url}/notificaiton`}
          >
            <FontAwesomeIcon
              className="topNavIcon"
              style={{ fontSize: 25 }}
              icon={faBell}
            />
          </NavLink>
          <NavLink
            // activeClassName="navbarActiveItem"
            className="topNavItem"
            aria-label="message"
            to={`${base_url}/message`}
          >
            <MessageIcon className="topNavIcon" size="25" />
          </NavLink>

          <NavigationOffCanvas />
          {/* <ThreeDots className="topNavIcon" size="25" /> */}
        </div>
      </Container>
    );
  }
);
