import axios from "axios";

const fileUploadToServer = ({ Url, formData, setIsLoading, setError, setSuccess, callAfterSuccess }) => {
    setIsLoading(true);
    axios
    .post(`${Url}`, formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => {
        setIsLoading(false);
      if (res.data.status === 1) {
          setSuccess(res.data.message)
          callAfterSuccess()
      } 
    })
    .catch((err) => {
      console.log(err.message);
      setIsLoading(false)
      setError("Something went wrong !")

    });
} 

export default fileUploadToServer