import React from "react";
import "./CategoriesSlider.css";
import CategoriesSliderItem from "./CategoriesSliderItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import CategorySkeleton from "../Placeholder/CategorySkeleton";

const CategoriesSlider = ({
  limit_category_loading: isLoading,
  category_product,
}) => {
  return (
    <div className="container-fluid category-slider box-shadow pb-3">
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={false}
        spaceBetween={15}
        autoplay={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Scrollbar, Navigation, Autoplay]}
        className="mySwiper category-top-slider"
      >
        {isLoading
          ? [...new Array(6)].map((_, index) => (
              <SwiperSlide key={index}>
                <CategorySkeleton></CategorySkeleton>
              </SwiperSlide>
            ))
          : category_product &&
            category_product.length !== 0 &&
            category_product.map((data) => {
              if (data.limited_product.length !== 0) {
                return (
                  <SwiperSlide key={data.id}>
                    <CategoriesSliderItem data={data} />
                  </SwiperSlide>
                );
              } else return null;
            })}
      </Swiper>
    </div>
  );
};

export default CategoriesSlider;
