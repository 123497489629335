import { PostApiCalls } from "src/Common/apiCall/PostApiCalls";
import { InputField } from "../Components/InputField";

export const StoringInputs = ({ storingInputsArg }) => {
  const {
    isLoading,
    setIsLoading,
    setIsEditableFalse,
    dataReload,
    componentMainUrl,
    formData,
    inputHandleChange,
  } = storingInputsArg;

  const updateFormFunc = (e) => {
    e.preventDefault();
    const formArg = {
      formData: formData,
      Url: componentMainUrl,
      setIsLoading: setIsLoading,
      dataReload: dataReload,
      setIsEditableFalse: setIsEditableFalse,
    };
    PostApiCalls(formArg);
  };

  return (
    <form onSubmit={updateFormFunc}>
      <InputField
        inputPlaceholder="current_city"
        textInputName="current_city"
        textInputValue={formData.current_city ? formData.current_city : ""}
        changeHandle={inputHandleChange}
      />
      <InputField
        inputPlaceholder="hometown"
        textInputName="hometown"
        textInputValue={formData.hometown ? formData.hometown : ""}
        changeHandle={inputHandleChange}
      />

      <div className="py-3 mainProfileBtnDiv">
        <button
          onClick={setIsEditableFalse}
          className="mainProfileBtn btnCandel me-3"
        >
          Cancel
        </button>
        <button
          className={`mainProfileBtn btnSave  ${isLoading ? "disabled" : ""} `}
        >
          Save
        </button>
      </div>
    </form>
  );
};
