import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import InputPhoneNumber from "src/Common/InputWithLabel/InputPhoneNumber";
import InputWithLabel from "src/Common/InputWithLabel/InputWithLabel";
import "./addAddressForm.css";



const AddAddressForm = ({ btnRef, setNewBillingAddress }) => {
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let prevState = formData;
    prevState[name] = value;
    setFormData({ ...prevState });

  };
  const phoneHandler = (value) => {
    setFormData(prevState => ({ ...prevState, billing_phone: value }))
  };


  const {
    billing_name,
    billing_email,
    billing_phone,
    billing_address1,
    billing_address2,
  } = formData;


  const handleSubmit = (e) => {
    e.preventDefault();
    if (billing_name  && billing_email && billing_address1 && billing_address2 && billing_phone && isValidPhoneNumber(billing_phone) && isPossiblePhoneNumber(billing_phone)) {
      btnRef.current.children[0].click();
      setNewBillingAddress(formData)
    }
  }


  const handleCancel = () => {
    btnRef.current.children[0].click();
  }


  return (
    <Form onSubmit={handleSubmit}>
      {/* name */}
      <InputWithLabel
        label="Name "
        name="billing_name"
        value={billing_name?billing_name:""}
        handler={handleChange}
        checkoutInput
        placeholder="Enter Your Name"
      ></InputWithLabel>

      {/* Phone  */}

      <InputPhoneNumber value={billing_phone} handler={phoneHandler} label="Phone" />
      {/* email  */}
      <InputWithLabel
        label="Email"
        checkoutInput
        name="billing_email"
        value={billing_email?billing_email:""}
        handler={handleChange}
        placeholder="Enter Your Email"
      ></InputWithLabel>
      <InputWithLabel
        label="Address 1"
        checkoutInput
        name="billing_address1"
        value={billing_address1?billing_address1:""}
        handler={handleChange}
        placeholder="Enter Your Address"
      ></InputWithLabel>
      <InputWithLabel
        label="Address 2"
        checkoutInput
        name="billing_address2"
        value={billing_address2?billing_address2:""}
        handler={handleChange}
        placeholder="Enter Your Address"
      ></InputWithLabel>

      <div className="text-end">
        <button onClick={handleCancel} type="button" className="send-code-btn me-2">Cancel</button>
        <button type="submit" className=" send-code-btn cart-confirm-btn">Save</button>
      </div>
    </Form>
  );
};

export default AddAddressForm;
