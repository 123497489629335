import React from "react";
import "./FoodNavigation.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import FoodLogo from "../../assets/icons/FoodLogo";
import { Link, NavLink, useLocation } from "react-router-dom";
import CartIcon from "../../assets/icons/CartIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import FoodCartModal from "../FoodCartModal/FoodCartModal";
import Avatar from "src/Projects/Life/Common/Avatar";
import BarsIcon from "../../assets/icons/BarsIcon";
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
import NavigationSidebar from "../NavigationSidebar/NavigationSidebar";

const FoodNavigation = () => {
  const [showModal, setShowModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const handleSidebarClose = () => setShowSidebar(false);
  const handleProfileModalClose = () => setShowProfileModal(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const { pathname } = useLocation();

  const path = "/" + pathname.split("/")[1];

  return (
    <section className="food-nav-main sticky-top">
      <div className="eco-container">
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="transparent"
          variant="light"
          style={{ boxShadow: "none" }}
        >
          <Container fluid>
            <Navbar.Brand as={"h5"} className="d-flex align-items-center">
              <Link to="/dashboard">
                <FoodLogo />
              </Link>
              <Link to="/food">
                <div className="nav-logo-text-box ms-1 d-xs-hide ">
                  <h5 className="mb-0">
                    <span className="food-nav-uviom-text">UVIOM</span>
                    <span className="text-orange">Food</span>
                  </h5>
                  <h6 className="mb-0 overflow-hidden font-14">
                    Serve Warm And Fresh
                  </h6>
                </div>
              </Link>
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mx-auto">
                <NavLink
                  to="/food/home"
                  activeClassName="food-nav-item-active"
                  className="food-nav-item"
                >
                  Home
                </NavLink>
                <NavLink
                  to="/food/foods"
                  activeClassName="food-nav-item-active"
                  className="food-nav-item"
                >
                  Foods
                </NavLink>
                <NavLink
                  to="/food/restaurants"
                  activeClassName="food-nav-item-active"
                  className="food-nav-item"
                >
                  Restaurants
                </NavLink>
                <NavLink
                  to="/food/contact"
                  activeClassName="food-nav-item-active"
                  className="food-nav-item"
                >
                  Contact
                </NavLink>
              </Nav>
            </Navbar.Collapse>
            <Nav className="d-flex gap-3 align-items-center flex-row">
              <Link
                to={`${path}/search`}
                className="btn-0 primary-text food-search-btn"
              >
                <FontAwesomeIcon icon={faSearch} />
              </Link>

              <button className=" btn-0" onClick={handleShow}>
                <CartIcon className="food-cart-icon" />
              </button>

              {/* <button className="food-btn text-white">Login</button> */}
              <Link to={`${path}/my-profile`} className="d-none d-lg-inline">
                <Avatar
                  className={"pointer "}
                  imgUrl={"/user.png"}
                  size="2rem"
                />
              </Link>

              <button
                onClick={() => setShowProfileModal(true)}
                className="btn-0 d-lg-none"
              >
                <Avatar
                  className={"pointer ms-3"}
                  imgUrl={"/user.png"}
                  size="2.2rem"
                />
              </button>

              <button
                onClick={() => setShowSidebar(true)}
                className="btn-0 ms-3 d-lg-none"
              >
                <BarsIcon size="19" className="primary-text" />
              </button>
            </Nav>
          </Container>
        </Navbar>
      </div>

      <NavigationSidebar show={showSidebar} handleClose={handleSidebarClose} />
      <ProfileSideBar
        show={showProfileModal}
        handleClose={handleProfileModalClose}
      ></ProfileSideBar>
      <FoodCartModal show={showModal} handleClose={handleClose} />
    </section>
  );
};

export default FoodNavigation;
