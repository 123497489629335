import axios from "axios";

async function apiCall_withToken(thunkAPI, url) {
    try {
        const resp = await axios.get(url, { headers: {
       Authorization: `Bearer ${localStorage.getItem("access_token")}`,
     },
   })
    return resp.data
    } catch (error) {
        return thunkAPI.rejectWithValue("something went wrong")
    }
}

export default apiCall_withToken;