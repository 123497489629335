import React from "react";

const FoodBannerItem = ({img}) => {
  return (
    <div className="food__slider-item"  >
      <img
        style={{height:"100%"}}
        src={img}
        className="img img-fluid h-100 w-100"
        alt=""
      />
    </div>
  );
};

export default FoodBannerItem;
