import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions"
import "react-data-table-component-extensions/dist/index.css";
import useTheme from "src/hooks/useTheme";
import {
  payment_columns as columns,
  payment_data as data,
} from "src/Projects/Corporate/utilities/tableData";

const PaymentHistory = () => {
    const tableData = {
        columns,
        data,
  };
  const {currentTheme} = useTheme()
  return (
    <div>
      <h6 className="fs-6 my-3">Payment History</h6>

      <div className="box-shadow nav-bg">
        <DataTableExtensions {...tableData} export={false}>
          <DataTable
            columns={columns}
            data={data}
            responsive
            theme={currentTheme}
            defaultSortAsc={true}
            defaultSortFieldId="id"
            pagination
            pointerOnHover
            onHeader
          />
        </DataTableExtensions>
      </div>
    </div>
  );
};

export default PaymentHistory;
