import React from "react";
import { Link } from "react-router-dom";

export const SingleLinkBar = ({ placeholder, link }) => {
  return (
    <Link to={link}>
      <div className="LookMeTypeButton box-shadow justify-content-start my-mt">
        <h6 className="ps-4 mb-0">{placeholder}</h6>
      </div>
    </Link>
  );
};
