import { faForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";

export const NextButtonRow = ({loadMore}) => {
    
  return (
    <Row>
      <Col>
        <div className="d-flex justify-content-end">
          <button className="button me-5" aria-label="Next" onClick={loadMore}
          onKeyDown={(e)=>{
            if(e.key==="Enter"){
              loadMore()
            }
          }}>
            Next <FontAwesomeIcon icon={faForward} />
          </button>
        </div>
      </Col>
    </Row>
  );
};
