import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col } from "react-bootstrap";
import { Link} from "react-router-dom";
import useTheme from "src/hooks/useTheme";
import "./RestaurantCard.css"
const RestaurantCard = ({ img }) => {
  const {currentTheme} = useTheme()

    return (
        <Col as={Link} to={`/food/restaurant-details/120`} style={{ color: "white" }}>
        <div className="restaurant-card">
          <div className="restaurant__card__img-box">
            <span className="w-100 h-100 d-flex justify-content-center align-items-center">
              <img src={img} alt=" product card Img" className="img-fluid" />
            </span>
          </div>
          <div className={`product__card__text-box p-2 ${currentTheme === "dark" && "secondary-bg"}`}>
            <p className=" mb-0 fs-6 fw-bold">ZamZam Restuant (Mirpur-11)</p>
            {/* <p className="mb-1 font-14">ZamZam Restuant (Mirpur-11)</p> */}
  
            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-0">
                <span className="product__card-star">&#9733;</span> 5.0 |  <span>Fast Food  Chinese</span> 
              </p>
              <span className="text-orange fs-4">
                <FontAwesomeIcon icon={faHeart}></FontAwesomeIcon>
              </span>
            </div>
          </div>
        </div>
      </Col>
    );
};

export default RestaurantCard;