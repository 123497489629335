import {
  createApplicantEducationUrl,
  createCompanySocialLinkUrl,
  deleteApplicantEducationUrl,
  deleteCompanyJobPostUrl,
  getCompanySocialLinkUrl,
  jobApplyUrl,
  jobSaveUrl,
  updateApplicantEducationUrl,
  updateJobPostUrl,
} from "src/Projects/Job/utilities/job_commonUrl";
import { api } from "../api";

export const jobPostApi = api.injectEndpoints({
  endpoints: (builder) => ({

    
    saveJobPost: builder.mutation({
      query: (postId) => ({
        url: `${jobSaveUrl + postId}`,
        method: "POST",
      }),
      invalidatesTags: ["SaveJobPost"],
    }),


    applyJobPost: builder.mutation({
      query: (jobId) => ({
        url: `${jobApplyUrl + jobId}`,
        method: "POST",
      }),
      invalidatesTags: [
        { type: "AppliedJobPost" },
        { type: "AllJobPost", page: "LIST" },
      ],
    }),


    deleteEducation: builder.mutation({
      query: (id) => ({
        url: `${deleteApplicantEducationUrl + id}`,
        method: "GET",
      }),
      invalidatesTags: ["ApplicantEducation"],
    }),


    addEducation: builder.mutation({
      query: (data) => ({
        url: createApplicantEducationUrl,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ApplicantEducation"],
    }),


    updateEducation: builder.mutation({
      query: ({id, data}) => ({
        url: updateApplicantEducationUrl + id,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ApplicantEducation"],
    }),


    addSocialLink: builder.mutation({
      query: (data) => ({
        url: createCompanySocialLinkUrl,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["CompanySocialLink"],
    }),


    UpdateSocialLink: builder.mutation({
      query: ({data, id}) => ({
        url: `${getCompanySocialLinkUrl}/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["CompanySocialLink"],
    }),


    deleteSocialLink: builder.mutation({
      query: (id) => ({
        url: `${getCompanySocialLinkUrl}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CompanySocialLink"],
    }),


    deletePostedJob: builder.mutation({
      query: (id) => ({
        url: `${deleteCompanyJobPostUrl + id}`,
        method: "GET",
      }),
      invalidatesTags: ["AllJobPost"]
    }),

    // update company posted job 
    updatePostedJob: builder.mutation({
      query: ({id, data}) => ({
        url: `${updateJobPostUrl + id}`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["CompanyPostedJob"],
    }),
  }),
});

export const {
  useSaveJobPostMutation,
  useApplyJobPostMutation,
  useDeleteEducationMutation,
    useAddEducationMutation,
    useUpdateEducationMutation,
    useAddSocialLinkMutation,
    useUpdateSocialLinkMutation,
  useDeleteSocialLinkMutation,
  useDeletePostedJobMutation,
  useUpdatePostedJobMutation
} = jobPostApi;
