import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./order_cancellation.css";

const OrderCancellation = () => {
  return (
    <div className="p-3 secondary-bg primary-text">
      <h6 className="fs-6 text-center ">Order Cancellation</h6>
      <br />
      <Row className="g-3 pb-3">
        <Col xl="6">
          <div className="favorite-card p-0 m-0">
            <div className="cart-item-img-box">
              <img className="img img-fluid" src="/food-image/food1.png" alt="" />
            </div>
            <div
              style={{ height: "100%" }}
              className="cart-text-box align-items-start d-flex flex-column justify-content-between"
            >
              <p className="font-14 fw-bold">ZamZam Restuant (Mirpur-11)</p>
              <p className="mb-1">
                <small>
                  {"steak frites, (French: “steak [and] fries”) a simple dish of beef steak.".substring(
                    0,
                    60
                  )}
                  ...
                </small>
              </p>
              <div className=" text-center d-flex justify-content-between w-100 align-items-center">
                <p>Quantity: 1</p>
                <p className="m-0 product-status-text border-orange">
                  <small>{"Processing"}</small>
                </p>
              </div>
            </div>
          </div>
        </Col>  
        <Col xl="6">
          <div
            className="d-flex align-items-center h-100"
            style={{ gap: "0.675rem" }}
          >
            <div className="d-flex justify-content-between align-items-center flex-grow-1 flex-column flex-xxl-row">
              <Form.Select
                defaultValue=""
                aria-label="payment method"
                className="payment-method-input delivery-charge ms-md-2 secondary-bg primary-text"
              >
                <option value=""> Select a Reason</option>
                <option value="1"> Change of Delivery Address</option>
                <option value="2"> Forgot to use voucher/voucher issue</option>
                <option value="3">Decided for alternative product</option>
                <option value="4">Found cheaper elsewhere</option>
                <option value="5">Shipping Fees</option>
                <option value="6">Change of mind</option>
              </Form.Select>
            </div>
          </div>
        </Col>
      </Row>

      <h6 className="fs-6">Cancellation Policy</h6>
      <p>
        <small>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </small>
      </p>

      <br />

      <div className="d-flex justify-content-center cancellation-check">
        <Form.Check
          type="checkbox"
          label={
            <small>
              I have read and agree to @ UVIOM Corporate Terms and conditions.
            </small>
          }
        />
      </div>
      <br />
      <div className="text-center">
        <button type="button" className="send-code-btn me-2">
          Cancel
        </button>
        <button type="submit" className=" send-code-btn cart-confirm-btn text-dark">
          Submit
        </button>
      </div>
      <br />
    </div>
  );
};

export default OrderCancellation;
