import React from "react";
import { FormControl } from "react-bootstrap";

const OnTable = () => {
  return (
    <div className="my-4">
      <h6 className="fw-500">On Table</h6>
      <div className="p-3 bg-white rounded-2 mt-2">
        <div className="d-flex align-items-center my-2">
          <label className="fs-6 fw-500">Person : </label> &nbsp; &nbsp;
          <FormControl
            defaultValue={2}
            type="number"
            className="active-orange-bd"
            max={20}
            min={1}
            style={{ width: "4rem", height: "2rem" }}
          />
        </div>
        <div className="d-flex align-items-center my-2">
          <label className="fs-6 fw-500">Adult : </label> &nbsp; &nbsp;&nbsp;
          &nbsp;
          <FormControl
            defaultValue={2}
            type="number"
            className="active-orange-bd"
            max={20}
            min={1}
            style={{ width: "4rem", height: "2rem" }}
          />
        </div>
        <div className="d-flex align-items-center my-2">
          <label className="fs-6 fw-500">Child : </label> &nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;
          <FormControl
            defaultValue={2}
            type="number"
            className="active-orange-bd"
            max={20}
            min={1}
            style={{ width: "4rem", height: "2rem" }}
          />
        </div>
      </div>
    </div>
  );
};

export default OnTable;
