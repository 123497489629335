import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import AgreementModal from "./../Common/Modal/AgreementModal";
import "./singleMessage.css";

function SingleMessage({ currentPerson }) {
  const [showAgreementModal, setShowAgreementModal] = useState(false);

  return (
    <>
      <AgreementModal
        show={showAgreementModal}
        onHide={() => setShowAgreementModal(false)}
      />
      <div className="single-message">
        <div className="single-message__header">
          <img
            src={currentPerson.image}
            alt=""
            className="single-message__img"
          />
          <h6 className="mb-0">{currentPerson.name}</h6>
          <div>
            <Button
              onClick={() => setShowAgreementModal(true)}
              className="btn__creative"
            >
              Agreement
            </Button>
          </div>
        </div>

        <div className="single-message__body">
          {Array.from({ length: 10 }).map((_, index) => (
            <div
              key={index}
              className={`single-message__chunk ${index % 2 === 0 && "me"}`}
            >
              <img
                src={
                  index % 2 === 0
                    ? "https://i.ibb.co/FhfpgCc/chat-Avatar07.jpg"
                    : currentPerson.image
                }
                className="single-message__img"
                alt=""
              />
              <p className="single__message__text">
                is simply dummy text of the printing and typesetting industry.
                Lorem
              </p>
            </div>
          ))}
        </div>

        <hr className="m-0" />
        <div className="py-3 px-2 d-flex gap-2">
          <Form.Control type="text" className="input-reset" />
          <Button className="btn__creative">Send</Button>
        </div>
      </div>
    </>
  );
}
export default SingleMessage;
