import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetHomePageVideosMutation,
  useGetOwnPlaylistMutation,
  useGetVideoCategoriesMutation,
} from "src/app/services/viewApi/viewGetApi";
import { destroyViewData } from "src/features/view/viewDataSlice";
import { Home } from "./Pages/Home";
import "./view.css";

const View = () => {
  const dispatch = useDispatch();
  const [getOwnPlaylist] = useGetOwnPlaylistMutation();
  const [getVideoCategories] = useGetVideoCategoriesMutation();
  const [getHomePageVideos] = useGetHomePageVideosMutation();

  const {
    homePageVideos: { data: homePageVideos, has_more },
    playlistVideos: { data: playlistVideos, p_has_more },
  } = useSelector((store) => store.viewData);

  useEffect(() => {
    if (homePageVideos.length < 1 || has_more) {
      getHomePageVideos();
    }
    if (playlistVideos.length < 1 || p_has_more) {
      getOwnPlaylist();
    }
    getVideoCategories();
    return () => dispatch(destroyViewData());
  }, []);

  return (
    <div style={{ minHeight: "100vh" }} className="View container-fluid">
      <Home />
    </div>
  );
};

export default View;
