import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const Actions = ({ icon1, icon2 }) => {
  return (
    <div className="d-flex">
      { icon1 ? (
         <div className="me-3">
         <FontAwesomeIcon icon={icon1} />
       </div>
      ):null}
     
      <div className="m">
        <FontAwesomeIcon icon={icon2} />
      </div>
    </div>
  );
};
