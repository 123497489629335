import React from "react";
import { Col, Placeholder } from "react-bootstrap";

const ShopSkeleton = () => {
  return (
    <Col>
      <div className="shop-skeleton p-2">
        <div className="shop-skeleton-content">
          <Placeholder
            as="div"
            className="text-end shop-skeleton-content-text"
            animation="wave" 
          >
            <Placeholder size="md" xs={10} className="rounded-1" bg="secondary"  />{" "}
            <Placeholder xs={4} size="sm" className="rounded-1" bg="secondary" /> <Placeholder xs={4} size="sm" className="rounded-1" bg="secondary" /> <Placeholder xs={6} size="sm" className="rounded-1" bg="secondary" />{" "}
            <Placeholder xs={5} size="sm" className="rounded-1" bg="secondary" />
          </Placeholder>
          <Placeholder
            as="div"
            className="shop-skeleton-content-avatar"
            animation="wave" 
            
          >
            <div className="placeholder-avatar bg-secondary"></div>
          </Placeholder>
        </div>
      </div>
    </Col>
  );
};

export default ShopSkeleton;
