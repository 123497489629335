import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "src/Projects/Life/icons/CloseIcon";

const LifeSearchBox = ({ setSearchBoxShow = () => {} }) => {
  const [searchValue, setSearchValue] = useState(" ");
  const [suggestions, setSuggestions] = useState([]);
  const mounted = useRef(null);
  const firstTime = useRef(true);
  const { pathname: url } = useLocation();
  const navigate = useNavigate();

  const handleInputField = (e) => {
    const text = e.target.value;
    setSearchValue(text);
  };

  const getLifeSearchHistory = () => {
    const data = JSON.parse(localStorage.getItem("life_search_history"));
    return data || [];
  };

  useEffect(() => {
    const data = getLifeSearchHistory();
    const remainingData = data.filter((item) => {
      const searchText = searchValue.toLowerCase();
      const currentText = item.toLowerCase();
      if (searchText === " ") return true;
      return currentText.includes(searchText) && currentText !== searchText;
    });
    setSuggestions(remainingData);
  }, [searchValue]);

  const handleSuggestionItemClick = (text) => {
    setSearchValue(text);
  };

  const handleSaveData = () => {
    const prevData = getLifeSearchHistory();
    if (prevData.includes(searchValue)) return;
    if (prevData.length > 9) prevData.shift();
    prevData.push(searchValue);
    localStorage.setItem("life_search_history", JSON.stringify(prevData));
  };

  const handlePressEnter = (e) => {
    if (e.keyCode === 13) handleSearchBtn();
  };

  const handleSearchBtn = () => {
    setSuggestions([]);
    let base_url = url;
    if (searchValue) {
      if (url.includes("public-life") || url.includes("life/post")) {
        base_url = "/life/family-life";
      } else {
        const [, base, life] = url.split("/");
        base_url = `/${base}/${life}`;
      }
      handleSaveData();
      navigate(`${base_url}/search?search=${searchValue}`);
    }
  };

  const handleInputFocus = () => {
    setSuggestions(getLifeSearchHistory());
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current && !firstTime.current) {
      setSearchBoxShow(false);
    }
    firstTime.current = false;

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const handleDelete = (text) => {
    const data = JSON.parse(localStorage.getItem("life_search_history"));
    const index = data.indexOf(text);
    data.splice(index, 1);
    const copyData = [...suggestions];
    copyData.splice(index, 1);
    setSuggestions(copyData);
    localStorage.setItem("life_search_history", JSON.stringify(data));
  };

  return (
    <div
      tabIndex={-1}
      className="global__search-box p-2 rounded-2"
      style={{ zIndex: 120 }}
    >
      <InputGroup className="profileVideoSearchInputGroup">
        <InputGroup.Text className="profileVideoSearchLabel">
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </InputGroup.Text>
        <FormControl
          value={searchValue}
          onChange={handleInputField}
          onFocus={handleInputFocus}
          onKeyUp={handlePressEnter}
          aria-label="search video"
          placeholder="Search Here..."
          className="profileVideoSearchInput"
        />
        <InputGroup.Text
          onClick={handleSearchBtn}
          className="profileVideoSearchLabel"
        >
          Search
        </InputGroup.Text>
      </InputGroup>

      {suggestions.length > 0 && (
        <div className="search_suggestion_dropdown">
          {suggestions.map((item) => (
            <SuggestionItem
              key={item}
              data={item}
              handleClick={handleSuggestionItemClick}
              handleDelete={handleDelete}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default LifeSearchBox;

const SuggestionItem = ({ data, handleClick, handleDelete }) => {
  const clickFunc = (e) => {
    e.stopPropagation();
    handleClick(data);
  };

  return (
    <div className="d-flex justify-content-between p-2 align-items-center my-2 suggestion_item">
      <p onClick={clickFunc} className="flex-grow-1">
        {data}
      </p>
      <button
        className="btn-0 search_history_remove-btn"
        onClick={() => handleDelete(data)}
      >
        <CloseIcon size="10" className="primary-text" />
      </button>
    </div>
  );
};
