import React from "react";
import { useState } from "react";
import styled from "styled-components";

function FakeAuthentication() {
  const [inputValue, setInputValue] = useState();
  const [isError, setIsError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (parseInt(inputValue) !== 516914) {
      setIsError("Good Try, But Not Right");
      return;
    }

    localStorage.setItem("uPass", true);
    window.location.assign("/login");
  };

  return (
    <Wrapper>
      <form method="post" onSubmit={handleSubmit}>
        <h5>You Need to Enter the Secret Key 😉</h5>
        <input
          placeholder="Enter The Secret Key"
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          name="password"
          id=""
        />
        {isError && <div className="error">{isError}</div>}
        <button>Submit</button>
      </form>
    </Wrapper>
  );
}

export default FakeAuthentication;

const Wrapper = styled.div`
  background-color: #eeeeee;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  & > form {
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    padding: 2rem;
    display: inline-block;

    & h5 {
      margin-bottom: 1rem;
    }

    & input {
      width: 100%;
      border: 1px solid #6984a5;
      border-radius: 4px;
      padding: 0.5rem;
    }

    & button {
      background-color: #6984a5;
      color: #fff;
      border: 0;
      font-size: 1.1rem;
      border-radius: 0.3rem;
      margin-top: 0.5rem;
      padding: 0.3rem 0.7rem;
      width: 100%;
    }

    & .error {
      margin-top: 0.3rem;
      color: #ff7474;
    }
  }
`;
