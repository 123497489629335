import { Row, Col } from "react-bootstrap";

function Features({ imageSrc, title, desc, isReversed, children }) {
  return (
    <Row
      className={`gx-lg-5 align-items-center ${
        isReversed ? "flex-lg-row-reverse" : ""
      } `}
    >
      <Col lg={6}>
        <div className="">
          <img className="img-fluid" src={imageSrc} alt={title} />
        </div>
      </Col>
      <Col lg={6}>
        <div className="pt-3 pt-lg-0">
          <h2 className="heading-1">{title}</h2>
          <p>{desc}</p>
          {children}
        </div>
      </Col>
    </Row>
  );
}

export default Features;
