import React from "react";
import { Col, Placeholder, Row } from "react-bootstrap";
import styled from "styled-components";

const ProductDetailsSkeleton = () => {
  return (
    <>
      <br />
      <PlaceholderContainer as={Placeholder} animation="wave">
        <Row>
          <Col xs="12" md="6">
            <Placeholder
              bg="secondary"
              className="large_image rounded-1"
            ></Placeholder>

            <div className="small-image-container">
              <Placeholder bg="secondary" className="rounded-1" />
              <Placeholder bg="secondary" className="rounded-1" />
              <Placeholder bg="secondary" className="rounded-1" />
              <Placeholder bg="secondary" className="rounded-1" />
            </div>
          </Col>
          <Col xs="12" md="6">
            <Placeholder xs="12" bg="secondary" className="rounded-1" />
            <Placeholder xs="11" bg="secondary" className="rounded-1" />
            <br /> <br />
            <Placeholder xs="8" bg="secondary" className="rounded-1" />
            <br /> <br />
            <Placeholder xs="7" bg="secondary" className="rounded-1" />
            <br />
            <br />
            <Placeholder xs="11" bg="secondary" className="rounded-1" />
            <Placeholder xs="10" bg="secondary" className="rounded-1 " />
            <br />
            <br />
            <Placeholder xs="4" bg="secondary" className="rounded-1 me-4" />
            <Placeholder xs="4" bg="secondary" className="rounded-1" />
            <Placeholder xs="6" bg="secondary" className="rounded-1 me-4" />
            <Placeholder xs="4" bg="secondary" className="rounded-1" />
            <br />
            <br />
            <Placeholder xs="7" bg="secondary" className="rounded-1" />
            <br />
            <br />
            <Placeholder xs="10" bg="secondary" className="rounded-1" />
            <br />
            <br />
            <Placeholder xs="12" bg="secondary" className="rounded-1" />
            <Placeholder xs="11" bg="secondary" className="rounded-1" />
            <br /> <br />
          </Col>
        </Row>
      </PlaceholderContainer>
    </>
  );
};

export default ProductDetailsSkeleton;

const PlaceholderContainer = styled.div`
  margin: 20px 0;
  width: 100%;

  background-color: transparent;
  .large_image {
    width: 100%;
    height: 500px;
    @media screen and (max-width: 1200px) {
      height: 350px;
    }
  }

  .small-image-container {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;

    .placeholder {
    }

    span {
      width: 130px;
      height: 80px;
      @media (max-width: 1200px) {
        width: 80px;
        height: 80px;
      }
    }
  }
`;
