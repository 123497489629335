/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useRef, useState } from "react";
import JobInformation from "src/Projects/Job/Components/Common/JobInformation";
import {
  companyProfilePhotoUrl,
  companyBannerPhotoUrl,
} from "../../utilities/job_commonUrl";
import handlePrint from "src/utilities/handlePrint";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import { useNavigate } from "react-router-dom";
import CompanyBanner from "../../Components/Common/CompanyBanner";
import { Tab, Tabs } from "react-bootstrap";
import CompanyInfo from "../../Components/Common/CompanyInfo";
import LocationInfo from "../../Components/Common/LocationInfo";
import { useSaveJobPostMutation } from "src/app/services/jobApi/jobPostApi";
import { useEffect } from "react";
import { useGetHomeJobPostQuery } from "src/app/services/jobApi/jobGetApi";
import { useSelector } from "react-redux";
import { showToast } from "../../utilities/functions";

const SingleJobDetails = ({ data }) => {

  const [savePost, { isLoading, isSuccess, error }] = useSaveJobPostMutation();
  const { data: job_data, refetch } = useGetHomeJobPostQuery(1);
  const { applicant_profile } = useSelector(store => store.jobProfile);

  

  const [key, setKey] = useState("job_info");
  const navigate = useNavigate();

  const printContent = useRef();
  const printContainer = useRef();

  const {
    id,
    profile_pic,
    banner_pic,
    job_apply_count,
    job_save_count,
    company_profile_section,
    company_social_link_section,
    job_address,
  } = data;

  const location_info = {
    district: company_profile_section?.district || {} ,
    division: company_profile_section?.division || {},
    job_address,
  };

  const profilePhoto = profile_pic
    ? companyProfilePhotoUrl + profile_pic
    : "/user.png";
  const bannerPhoto = banner_pic
    ? companyBannerPhotoUrl + banner_pic
    : "/Life/job/Nogod Cover Photo.png";

  const handleApply = () => {
    navigate(`/Job/job-apply/${id}`);
  };

  const [jobSave_status, setJobSave_status] = useState(
    job_save_count == "0" ? false : true
  );
  useEffect(() => {
    if (isSuccess) {
      setJobSave_status(true);
      refetch();
    }
  },[isSuccess])

  const handleSave = (e) => {
    e.stopPropagation();

    if (applicant_profile?.id) {
      savePost(id)
      
    } else {
      navigate("/Job/create-applicant-profile")
      showToast({ title: "Please Create you Applicant Profile", icon: "error" });
    }
  };
  return (
    <div className="position-relative job-details ">
      <CompanyBanner
        profile_banner={bannerPhoto}
        profile_picture={profilePhoto}
      ></CompanyBanner>
      <br />

      {isLoading && <BubbleLoader />}

      <div className="jobInformation py-2 px-1">
        <div ref={printContent}>
          <Tabs
            id="food-order-history"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 mt-3 food-tab "
            transition={true}
          >
            <Tab eventKey="job_info" title="Job Info">
              <JobInformation data={data} />
            </Tab>
            <Tab eventKey="location" title="Location">
              <LocationInfo data={location_info} />
            </Tab>
            <Tab eventKey="company_info" title="Company">
              <CompanyInfo
                data={company_profile_section}
                socialLinks={company_social_link_section}
              />
            </Tab>
          </Tabs>
        </div>

        <div style={{ fontSize: "0.9rem" }}>
          <p className="text-danger text-center">{error}</p>
        </div>

        <div className="jobCategoriesItemBtn jobDetailsBtn noprint pb-4">
          <button
            disabled={job_apply_count == "1"}
            onClick={handleApply}
            type="button"
          >
            {job_apply_count == "1" ? "Applied" : "Apply"}
          </button>
          <button onClick={handleSave} disabled={jobSave_status} type="button">
            {jobSave_status ? "Saved" : "Save"}
          </button>
          <button type="button">Share</button>
          <a
            onClick={(e) => handlePrint(e, printContent, printContainer)}
            href="#প"
          >
            Print
          </a>
        </div>
      </div>
      <iframe
        ref={printContainer}
        title="print"
        style={{ opacity: "0" }}
      ></iframe>

      <br />
      <br />
    </div>
  );
};

export default SingleJobDetails;
