import React, { useState, useEffect } from "react";
import useTheme from "src/hooks/useTheme";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { FormControl, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { OrderDataTable } from "../Common/OrderDataTable";
import { showcancelOrderUrl } from "src/Projects/Corporate/utilities/CommonUrl";
import { columns } from "../Common/tableData";

export const ShowCanceledOrder = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentTheme } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [info, setinfo] = useState([]);
  //-------------------------------------
  const currentPath = "/" + pathname.split("/").slice(1, 3).join("/");

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(3);
  const token = localStorage.getItem("access_token");
  const fetchUsers = async (page) => {
    setIsLoading(true);
    const response = await axios.get(`${showcancelOrderUrl}?page=${page}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setinfo(response.data.data);
    setTotalRows(response.data.total);
    setPerPage(response.data.per_page);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users
  }, []);
  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    const response = await axios.get(
      `${showcancelOrderUrl}?page=${page}&per_page=${newPerPage}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setinfo(response.data.data);
    setPerPage(newPerPage);
    // setLoading(false);
  };

  const downloadInvoice = (OrderID) => {
    navigate(`${currentPath}/invoice/${OrderID}`);
  };
  // search work ------------

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = info?.filter((item) => {
    const hasOrderId = String(item.OrderID)
      .toLowerCase()
      .includes(filterText.toLowerCase());
    const hasPhone = item.Phone.toLowerCase().includes(
      filterText.toLowerCase()
    );
    return hasOrderId || hasPhone;
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <InputGroup className={`corporate__navbar__searchBox w-50`}>
        <FormControl
          // onChange={handleOnChange}
          className="corporate__navbar-input"
          id="search"
          type="text"
          placeholder="Filter By Name"
          aria-label="Search Input"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <InputGroup.Text
          onClick={handleClear}
          className="corporate__navbar__input-text"
        >
          <FontAwesomeIcon icon={faSearch} />
        </InputGroup.Text>
      </InputGroup>
    );
  }, [filterText, resetPaginationToggle]);
  // search work end------------
  const location = useLocation();

  return (
    <div>
      <h6 className="fs-6 my-3">{location.state.data}</h6>

      <div className="box-shadow nav-bg">
        <OrderDataTable
          columns={columns({ currentPath, downloadInvoice })}
          filteredItems={filteredItems}
          currentTheme={currentTheme}
          totalRows={totalRows}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          perPage={perPage}
          subHeaderComponentMemo={subHeaderComponentMemo}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
