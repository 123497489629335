import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const AllJobCategories = () => {
  const { categories } = useSelector((store) => store.jobHomeData);

  return (
    <div className="secondary-bg px-2 px-xl-3 py-4">
      <h6>Category ({categories.length})</h6>
      <hr />

      <Row className="g-4">
        {categories.map((category) => (
          <Col xs="6" sm="4" lg="6" xl="4">
            {" "}
            <NavLink
              key={category.id}
              className={({ isActive }) =>
                `categoriesLink ${isActive && "categoriesLinkActive"}`
              }
              to={`/Job/job-market/categories/${category.slug}/${category.id}`}
            >
              <span> {category.title}</span> ({category.job_posts_count})
            </NavLink>{" "}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default AllJobCategories;
