import { Container, Button } from "react-bootstrap";
import HeroArea from "./../components/HeroArea";

import stock2 from "./../assets/stock2.jpg";
import stock3 from "./../assets/stock3.jpg";
// same as ride
import Features from "./../../Ride/components/Common/Features";
import overlay from "./../../Ride/assets/overlay.png";

function Home() {
  return (
    <div>
      <HeroArea />
      <div
      //  style={{ backgroundImage: `url(${overlay})` }}
       >
        <div className="py-3 py-lg-5" />
        <Container>
          {/* Features Start */}
          <Features
            imageSrc={stock2}
            title="Safety Fast"
            desc="Your safety comes first. Always.
          We look out for you before, during, and after every single ride. And we monitor rides to help you get where you need to go. If you ever need it, we're standing by, ready to help."
            isReversed={true}
          />
          {/* Features End */}
          <div className=" py-3 py-lg-5" />
          {/* Features Start */}
          <Features
            imageSrc={stock3}
            title="Opportunity is everywhere
          for everyone"
            desc="New benefits and guaranteed earnings"
          ></Features>
          {/* Features End */}
        </Container>
        <div className="py-3 py-lg-5" />
      </div>
    </div>
  );
}
export default Home;
