import { Container } from "react-bootstrap";
import InfoReceipt from "../components/InfoReceipt";

import stock1 from "./../assets/stock1.jpg";

function DeliveryReceipt() {
  return (
    <div id="wrapper">
      <div
        className="heroArea__bg"
        style={{ backgroundImage: `url(${stock1})` }}
      >
        <div className="min-vh-100">
          <div className="py-5"></div>
          <Container>
            <div className="w-lg-75 mx-auto">
              <InfoReceipt />
            </div>
          </Container>
          <div className="pb-5" />
        </div>
      </div>
    </div>
  );
}
export default DeliveryReceipt;
