import axios from "axios";
import _ from "lodash";

export const postData = (url, formData, setIsLoading) => {
  setIsLoading(true);
  axios
    .post(url, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "applications/json",
      },
    })
    .then((res) => {
      setIsLoading(false);
    })
    .catch((err) => {
      setIsLoading(false);
      console.log(err, "Error");
    });
};

export const createNewMessage = (
  authUserId,
  to_user,
  content,
  message_content = []
) => {
  const message = {
    id: new Date().getUTCMilliseconds(),
    from_user: authUserId,
    to_user: to_user,
    content: content,
    message_content: message_content,
    my_status: "0",
    created_at: new Date().toISOString(),
    offline: true,
  };

  return message;
};

export const getFullDate = (date) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  return new Date(date).toLocaleDateString(undefined, options);
};

export const getFullTime = (date_string) => {
  const options = { day: "numeric", month: "short", year: "numeric" };
  const date = new Date(date_string);
  const day = date.toLocaleDateString(undefined, options);
  const hours =
    ((date.getHours() % 12 || 12) < 10 ? "0" : "") +
    (date.getHours() % 12 || 12);
  const minute =
    (date.getMinutes() < 10 ? "0" : "") + String(date.getMinutes());
  var am_pm = date.getHours() >= 12 ? "PM" : "AM";

  return `${day} • ${hours}:${minute}${am_pm}`;
};

export const create_user = (data) => {
  const { content, created_at, from_user, to_user, id, updated_at } =
    data || {};

  return {
    content,
    created_at,
    from_user,
    to_user,
    id,
    updated_at,
    my_status: null,
    other_status: null,
    message_content: [],
  };
};

export const find_person_index = (id, chatUserList) => {
  const index = _.findIndex(chatUserList, (person) => {
    return (
      parseInt(person.from_user.id) === parseInt(id) ||
      parseInt(person.to_user.id) === parseInt(id)
    );
  });

  return index;
};

export const create_response_message = (response, message_content) => {
  const { content, created_at, updated_at, from_user, to_user, id } = response;

  return {
    id,
    content,
    created_at,
    updated_at,
    from_user: from_user.id,
    to_user: to_user.id,
    message_content: message_content || [],
    my_status: "1",
    other_status: "1",
  };
};
