import React from 'react';
import OrderItemCard from '../Common/OrderItemCard';
import CancelledOrderCard from './CancelledOrderCard';

const CanceledOrder = () => {
    return (
        <div className='order-history p-3'>
           <CancelledOrderCard/>
           <CancelledOrderCard/>
           <CancelledOrderCard/>
        </div>
    );
};

export default CanceledOrder;