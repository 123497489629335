import React from "react";

const CloseIcon = ({ size = "20", className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
    >
      <line
        x1="1"
        y1="-1"
        x2="25"
        y2="-1"
        transform="matrix(-0.707107 0.707107 -0.673509 -0.739179 18.3848 0)"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="1"
        y1="-1"
        x2="25"
        y2="-1"
        transform="matrix(-0.707107 -0.707107 -0.673509 0.739179 18.3848 20.001)"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CloseIcon;
