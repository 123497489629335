import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Avatar from "src/Projects/Life/Common/Avatar";
// import LookMeIcon from "src/Projects/Life/icons/LookMeIcon";
import CloseIcon from "src/Projects/Life/icons/CloseIcon";
import { personalProfilePic } from "src/Projects/Life/Common/CommonUrl";
import { useDispatch } from "react-redux";
import { setShowMessagePopup } from "src/features/life/messengerSlice";

const PopupMessageHeader = ({ targetedUserInfo }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setShowMessagePopup(false));
  };

  return (
    <div className="nav-bg shadow-sm p-1" style={{ gap: "px" }}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center .mb-3">
          {targetedUserInfo && targetedUserInfo.photo ? (
            <Avatar
              imgUrl={`${personalProfilePic}/${targetedUserInfo.photo}`}
              size={"40px"}
            />
          ) : (
            <Avatar imgUrl="/user.png" size={"40px"} />
          )}
          <div className="ms-2">
            <p className="m-0 mediumTextSize">
              {targetedUserInfo.first_name} {targetedUserInfo.last_name}
            </p>
            {/* <p className="m-0 font-12">Active 6h ago</p> */}
          </div>
        </div>

        <div className="d-flex justify-content-evenly align-items-center">
          {/* <button
            className="chat__content__header-btn border-0 "
            type="button"
          >
            <FontAwesomeIcon
              className="Chat__header__btn-icon"
              icon={faPhone}
            ></FontAwesomeIcon>
          </button>
          <button
            className="chat__content__header-btn border-0"
            type="button"
          >
            <FontAwesomeIcon
              className=" Chat__header__btn-icon"
              icon={faVideo}
            ></FontAwesomeIcon>
          </button> */}

          {/*  <button
            className="chat__content__header-btn border-0"
            type="button"
          >
            <LookMeIcon
              className="Chat__header__btn-icon"
              height="18"
              width="30"
            />
          </button> */}

          <button
            className="chat__content__header-btn border-0 "
            type="button"
            onClick={handleClose}
          >
            <FontAwesomeIcon
              className=" Chat__header__btn-icon text-white-4"
              icon={faMinus}
              size="lg"
            ></FontAwesomeIcon>
          </button>

          <button
            className="chat__content__header-btn border-0"
            type="button"
            onClick={handleClose}
          >
            <CloseIcon size="18"></CloseIcon>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupMessageHeader;
