import React from "react";
import { Container, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import ProductCard from "../../Components/Common/ProductCard/ProductCard";
import ProductSkeleton from "../../Components/Placeholder/ProductSkeleton";

const WeekendsSale = ({ product_loading: isLoading, home_product }) => {
  const location = useLocation();
  const navigate = useNavigate();
  let { path } = useLocation();

  const handleJobClick = () => {
    navigate({
      pathname: `${path}/product`,
      state: {
        allBrandData: home_product,
      },
    });
  };
  return (
    <Container
      fluid
      className={`py-4 ${
        location.pathname.includes("/product")
          ? "productPage "
          : "my-2 box-shadow px-3 "
      }`}
    >
      <div className="d-flex justify-content-between">
        <div>
          <h4 className="py-2">Weekends Sale</h4>
        </div>
        <div>
          <div className="py-2 pointer" onClick={handleJobClick}>
            View All
          </div>
        </div>
      </div>
      <Row className="home__Product-box g-2">
        {isLoading ? (
          [...new Array(12)].map((_, index) => (
            <ProductSkeleton key={index + "i"} />
          ))
        ) : (
          <>
            {home_product &&
              home_product.length !== 0 &&
              home_product.map((data) => (
                <ProductCard data={data} key={data.id} isOffered={true} />
              ))}
          </>
        )}
      </Row>
    </Container>
  );
};

export default WeekendsSale;
