import React from "react";
import IntroPage from "src/PublicPages/IntroPage/IntroPage";
import Footer from "./Footer/Footer";
import LoginBlock from "./Login&SignupBlock/LoginBlock";
import Navigation from "./Shared/Navigation";

const LoginPage = () => {
  return (
    <div>
      <IntroPage />
      <Navigation />
      <LoginBlock />
      <Footer />
    </div>
  );
};

export default LoginPage;
