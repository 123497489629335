import axios from 'axios';
import {  fmUpdateProfilePhotoURL,fnUpdateProfilePhotoURL,pUpdateProfilePhotoURL } from 'src/Projects/Life/Common/CommonUrl';

export const UpdateProfilePicture = (formData,setUploding,handleCloseProfileEditModal,formateData,getPictures,setErrorMessage,location) => {
  let Url="";
  if(location.pathname.includes("/family-life")){
    Url=fmUpdateProfilePhotoURL
  }else if(location.pathname.includes("/friends-life")){
    Url=fnUpdateProfilePhotoURL

  }else if(location.pathname.includes("/professional-life")){
    Url=pUpdateProfilePhotoURL
  }
    axios
    .post(`${Url}`, formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "multipart/form-data",

        },
      }
    )
    .then((res) => {
      setUploding(false);
      if (res.data.status === 1) {
        handleCloseProfileEditModal()
        formateData()
        getPictures()

      }
    })
    .catch((err) => {
      formateData()
      setUploding(false)
      setErrorMessage("Something went wrong !")

    });
}
