import React from "react";
import { Row } from "react-bootstrap";
import FoodCard from "src/Projects/Food/Components/FoodCard/FoodCard";
import useFoodStore from "src/Projects/Food/hooks/useFoodStore";

const SearchResultFood = () => {
  const { products } = useFoodStore();
  return (
    <Row className="home__Product-box g-2">
      {products.map((item) => (
        <FoodCard key={item.id} data={item} />
      ))}
    </Row>
  );
};

export default SearchResultFood;