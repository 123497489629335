import React, { useState } from "react";
import {
  faCommentDots,
  faShareSquare,
  faThumbsUp,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Modal, Row } from "react-bootstrap";
import { videoStoryVideoUrl } from "src/Projects/Life/Common/CommonUrl";
import BestTimeVideoPlayer from "../BestTimeView/BestTimeVideoPlayer";
import "./bestTimeModal.css";

const VideoShowModal = ({ show, handleClose, data = {} }) => {
  const { name, has_caption } = data;
  const [isPlaying, setIsPlaying] = useState(true);

  const videoUrl = videoStoryVideoUrl + name?.video_name;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="bestTime__update-modal"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center">
          {has_caption?.video_caption}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs="12" className="position-relative ">
            <Col xs="10" sm="7" className="mx-auto">
              <div
                className="mx-auto overflow-hidden rounded-3 bestTime-video-view-box"
                style={{ background: "inherit", height: "100%" }}
              >
                <BestTimeVideoPlayer
                  url={videoUrl}
                  aspectRatio="2/3"
                  control={true}
                  isPlaying={isPlaying}
                  setIsPlaying={setIsPlaying}
                  handleVideoEnd={handleClose}
                />
              </div>
            </Col>
            {/* 
            <div className="bestVideoControlInfo">
              <div className="">
                <div className="mb-4 bestTimeInfoLike text-center">
                  <FontAwesomeIcon
                    className="bestTimeViewIcon mx-auto"
                    icon={faThumbsUp}
                  />
                  <span className="bestTimeViewInfo">650</span>
                </div>
                <div className="mb-4 bestTimeInfoComment text-center">
                  <FontAwesomeIcon
                    className="bestTimeViewIcon mx-auto"
                    icon={faCommentDots}
                  />
                  <span className="bestTimeViewInfo">560</span>
                </div>
                <div className="mb-4 bestTimeInfoShare text-center">
                  <FontAwesomeIcon
                    className="bestTimeViewIcon mx-auto"
                    icon={faShareSquare}
                  />
                  <span className="bestTimeViewInfo">220</span>
                </div>
              </div>
            </div>
             */}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default VideoShowModal;
