import React, { createContext } from 'react'
import useUbizzGlobal from '../hooks/useUbizzGlobal';
export const UbizzContext = createContext();

export const UbizzStoreProvider = ({ children }) => {
  return (
    <UbizzContext.Provider value={useUbizzGlobal()}>
    {children}
  </UbizzContext.Provider>
  )
}
