import { memo, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { NavLink, useSearchParams } from "react-router-dom";
import _ from "lodash";
import useQuery from "src/hooks/useQuery";
import { useEffect } from "react";

function Categories() {
  const { videoCategories } = useSelector((store) => store.viewData);
  const query = useQuery();
  const [searchParams] = useSearchParams();
  const categoryRef = useRef();
  const categoryContainerRef = useRef();

  const dummyCategory = [
    { id: 123, name: "Computers" },
    { id: 124, name: "Gadgets" },
    { id: 125, name: "Editing" },
    { id: 126, name: "Debates" },
    { id: 127, name: "Podcasts" },
    { id: 128, name: "Gaming" },
    { id: 129, name: "Comedy" },
    { id: 130, name: "Pythons" },
  ];

  if (categoryContainerRef.current) {
    categoryContainerRef.current.style.height =
      categoryRef?.current?.offsetHeight / 16 + "rem";
  }

  useEffect(() => {
    if (categoryContainerRef.current) {
      categoryContainerRef.current.style.height =
        categoryRef?.current?.offsetHeight / 16 + "rem";
    }
  }, []);

  return (
    !_.isEmpty(videoCategories) && (
      <div
        className="position-relative"
        style={{ height: "3.5rem" }}
        ref={categoryContainerRef}
      >
        <CategoryWrapper className="position-absolute" ref={categoryRef}>
          <NavLink
            to={`?category=all`}
            className={() =>
              searchParams.get("category") === "all" ||
              searchParams.get("category") === null
                ? "item active"
                : "item"
            }
          >
            All
          </NavLink>
          {videoCategories.map((category) => (
            <NavLink
              to={`?category=${category.id}`}
              className={() =>
                searchParams.get("category") == category.id
                  ? "item active"
                  : "item"
              }
              key={category.id}
            >
              {category.name}
            </NavLink>
          ))}

          {dummyCategory.map((category) => (
            <div
              className={`item ${
                query.get("category") == category.id ? "active" : ""
              }`}
              key={category.id}
            >
              {category.name}
            </div>
          ))}
        </CategoryWrapper>
      </div>
    )
  );
}
export default memo(Categories);

const CategoryWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  overflow-x: auto;
  width: 100%;

  scrollbar-color: var(--food-nav-bg) var(--View-bg);
  scrollbar-width: thin;

  /* 576px */
  @media (max-width: 36em) {
    gap: 0.7rem;
  }

  /* width */
  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 0.2rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--View-bg);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--food-nav-bg) !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--bg-gray) !important;
  }

  & .item {
    border-radius: 3rem;
    display: inline-block;
    padding: 0.2rem 1rem;
    border: 1px solid var(--text-info);
    color: var(--text-info);
    cursor: pointer;

    /* 576px */
    @media (max-width: 36em) {
      padding: 0.1rem 0.7rem;
    }

    &.active {
      /* background-color: var(--bg-success); */
      color: var(--bg-success);
      border-color: var(--bg-success);
    }
  }
`;
