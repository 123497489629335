import { useState } from "react";
import CreatePlaylistModal from "./CreatePlaylistModal";

function CreatePlaylistContainer({ text = "Or Create One" }) {
  const [showCreatePlaylistModal, setShowCreatePlaylistModal] = useState(false);

  return (
    <>
      <div
        onClick={() => setShowCreatePlaylistModal(!showCreatePlaylistModal)}
        className="pointer"
      >
        {text}
      </div>
      <CreatePlaylistModal
        show={showCreatePlaylistModal}
        handleClose={() => setShowCreatePlaylistModal(!showCreatePlaylistModal)}
      />
    </>
  );
}
export default CreatePlaylistContainer;
