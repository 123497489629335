import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

const CartModalItem = ({ data }) => {
  const [quantity, setQuantity] = useState(1);

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <div className="secondary-bg p-2 my-3 cart-modal-item">
      <Row className="g-3">
        <Col xs="11">
          <div className="favorite-card p-0 m-0">
            <div className="cart-item-img-box">
              <img className="img img-fluid" src="/food-image/food1.png" alt="" />
            </div>
            <div
              style={{ height: "100%" }}
              className="cart-text-box align-items-start d-flex flex-column justify-content-between"
            >
              <p className="mb-0 fw-bold" style={{ fontSize: "1.2rem" }}>
                <small>Steak Frites</small>
              </p>
              <p className="">
                <small style={{ fontSize: "0.9rem" }}>
                  {"steak frites, (French: “steak [and] fries”)".substring(
                    0,
                    22
                  )}
                  ...
                </small>
              </p>

              <h6 className="my-0" style={{ fontSize: "0.90rem" }}>
                Price: <span className="text-orange">{data.price}Tk</span>
              </h6>
            </div>
          </div>
        </Col>
        <Col xs="1">
          <div className="d-flex flex-column justify-content-between align-items-center  h-100">
            {/* <h5 className="text-orange">4000</h5> */}
            <button className=" cart-modal-remove-btn  py-1 me-1 ">
              <FontAwesomeIcon icon={faTrash} />
            </button>
            <div className="d-flex food__modal_card__plus-minus">
              <button onClick={decreaseQuantity} className="btn-0">
                -
              </button>{" "}
              <span>{quantity}</span>{" "}
              <button onClick={increaseQuantity} className="btn-0">
                +
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CartModalItem;
