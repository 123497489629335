import React from "react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { timeAgo } from "src/utilities/utilities";
import styled from "styled-components";

export const SingleVideoCard = ({
  title,
  desc,
  viewCount,
  thumbnail,
  savedAt,
  videoId,
}) => {
  const getTimeAgo = useMemo(() => timeAgo(savedAt), [savedAt]);

  return (
    <Wrapper as={Link} to={`/view/video/${videoId}`}>
      <div className="thumbnail__container">
        <img className="thumbnail" src={thumbnail} alt={title} />
      </div>

      <div className="content">
        <div title={title}>
          {title.slice(0, 20)}
          {title.length > 20 && "..."}
        </div>
        <p className="mediumTextSize text-gray mb-0">{desc.slice(0, 20)}</p>
        <div className="view-yellow mediumTextSize">
          {viewCount} Views | {savedAt && getTimeAgo}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-shadow: var(--box-shadow);
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
  background-color: var(--bg-secondary);
  color: var(--text-primary);

  &:hover {
    color: var(--text-primary);

    & .thumbnail {
      transform: scale(1.05);
    }
  }

  & > .thumbnail__container {
    margin-bottom: 1rem;

    & > .thumbnail {
      width: 100%;
      max-height: 7rem;
      object-fit: cover;
      object-position: center;
      transition: 0.3s ease-in-out;
    }
  }

  & > .content {
    padding-inline: 1rem;
    padding-block-end: 1rem;
  }
`;
