import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import "./input-password.css";

const InputPassword = ({
  value,
  name,
  label,
  handler = () => {},
  disabled = false,
}) => {
  const [show, setShow] = useState(false);
  return (
    <Form.Group
      as={Row}
      className={`mb-3 mx-md-1`}
      controlId=""
    >
      <Form.Label
        className={`d-none d-md-block InputWithLabelLabel text-start`}
        column
        sm="3"
      >
        {label}
      </Form.Label>
      <Col md="9">
        <Form.Label className={"d-block d-md-none "}>{label}</Form.Label>
        <InputGroup className="input-password-input">
          <FormControl
            disabled={disabled}
            required={true}
            name={name}
            value={value}
            onChange={handler}
            className={`InputWithLabelInput checkout-input`}
            type={show ? "text" : "password" }
            placeholder={"Password"}
          />
          <InputGroup.Text
            className="input-password-text"
            onClick={() => setShow((prevState) => !prevState)}
          >
            <FontAwesomeIcon icon={show ? faEyeSlash : faEye}></FontAwesomeIcon>
          </InputGroup.Text>
        </InputGroup>
      </Col>
    </Form.Group>
  );
};

export default InputPassword;
