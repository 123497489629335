import React from "react";

const LiveVideoIcon = ({ size = "32px", className, className2 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 59 60"
      fill="none"
    >
      <path
        opacity="0.95"
        className={className}
        d="M29.492 60C45.78 60 58.984 46.5685 58.984 30C58.984 13.4315 45.78 0 29.492 0C13.204 0 0 13.4315 0 30C0 46.5685 13.204 60 29.492 60Z"
        fill="#F2F2F2"
      />
      <path
        className={className2}
        d="M44.2762 33.189C44.2292 34.125 44.2432 34.939 44.1372 35.739C44.0665 36.776 43.5956 37.745 42.8239 38.4413C42.0522 39.1377 41.04 39.5069 40.0012 39.471C35.3582 39.52 30.7132 39.49 26.0692 39.499C25.3862 39.499 24.8042 39.374 24.7912 38.536C24.7772 37.605 25.3702 37.387 26.1752 37.389C30.6146 37.399 35.0536 37.4007 39.4922 37.394C39.8512 37.4485 40.218 37.4184 40.5633 37.3062C40.9086 37.194 41.223 37.0028 41.4814 36.7477C41.7398 36.4926 41.9351 36.1807 42.0517 35.8369C42.1683 35.493 42.2031 35.1267 42.1532 34.767C42.1692 31.5617 42.1692 28.357 42.1532 25.153C42.1911 24.8035 42.1494 24.45 42.0312 24.119C41.9131 23.7879 41.7215 23.4879 41.4709 23.2414C41.2203 22.995 40.9171 22.8084 40.5841 22.6958C40.2512 22.5831 39.897 22.5473 39.5482 22.591H12.7902C10.6602 22.591 9.89023 23.35 9.88423 25.45V26.44H7.98423C7.09823 23.055 9.02523 20.506 12.4592 20.504C21.4192 20.4987 30.3796 20.4987 39.3402 20.504C42.5642 20.504 44.1682 22.078 44.2222 25.309C44.2302 25.784 44.2222 26.26 44.2222 26.909L52.6822 22.502L53.1252 22.663C48.6532 27.485 48.5252 32.355 52.9022 37.277L52.4762 37.517L44.2762 33.189ZM47.8622 27.358C46.7992 27.903 45.7402 28.41 44.7132 28.982C44.5431 29.0693 44.3971 29.1973 44.2884 29.3547C44.1797 29.512 44.1115 29.6938 44.0901 29.8838C44.0686 30.0739 44.0944 30.2663 44.1653 30.4439C44.2361 30.6216 44.3498 30.7789 44.4962 30.902C45.5782 31.562 46.7282 32.115 47.8622 32.711V27.358Z"
        fill="#F2F2F2"
      />
      <path
        className={className2}
        d="M33.9922 25.6094H39.0272V26.7844H37.7322C35.4382 26.7844 35.4382 26.7844 35.7492 29.2534C36.5111 29.2237 37.2741 29.2324 38.0352 29.2794C38.3142 29.3074 38.5612 29.6404 38.8242 29.8334C38.5832 30.0494 38.3742 30.3904 38.0972 30.4594C37.3552 30.6414 36.5812 30.7084 35.6812 30.8414C35.8712 31.5924 34.9812 32.7144 36.2672 33.1414C37.174 33.2935 38.0984 33.3103 39.0102 33.1914V34.3754H33.9922V25.6094Z"
        fill="#F2F2F2"
      />
      <path
        className={className2}
        d="M28.4466 32.3986C29.1126 30.5796 29.8136 28.7706 30.4316 26.9346C30.8086 25.8106 30.7506 25.7916 32.1616 25.8466C32.1686 26.2268 32.1303 26.6065 32.0476 26.9776C31.2786 29.1496 30.5116 31.3226 29.6476 33.4596C29.3971 33.9129 28.9788 34.25 28.4826 34.3986C28.3277 34.4547 28.1627 34.4771 27.9984 34.4644C27.8341 34.4517 27.6745 34.4041 27.5301 34.3248C27.3857 34.2455 27.2598 34.1363 27.161 34.0045C27.0621 33.8728 26.9924 33.7214 26.9566 33.5606C26.1046 31.2116 25.2066 28.8806 24.3796 26.5246C24.2936 26.2806 24.5696 25.9116 24.6796 25.5996C25.0516 25.7596 25.6256 25.8216 25.7566 26.0996C26.2456 27.1176 26.5956 28.1996 26.9846 29.2656C27.3676 30.3136 27.7356 31.3656 28.1096 32.4176L28.4466 32.3986Z"
        fill="#F2F2F2"
      />
      <path
        className={className2}
        d="M11.2051 30.034C11.2195 30.3473 11.1699 30.6602 11.0594 30.9537C10.9489 31.2472 10.7797 31.5151 10.5623 31.7411C10.3448 31.9671 10.0836 32.1464 9.79452 32.2681C9.50548 32.3898 9.19467 32.4514 8.88106 32.449C8.18158 32.4211 7.52106 32.1195 7.04183 31.6092C6.5626 31.099 6.30303 30.4208 6.31906 29.721C6.29406 28.762 7.73406 27.521 8.87406 27.521C9.20013 27.5056 9.52562 27.5616 9.8278 27.6851C10.13 27.8085 10.4016 27.9965 10.6235 28.2358C10.8455 28.4751 11.0126 28.76 11.113 29.0706C11.2135 29.3812 11.2449 29.71 11.2051 30.034Z"
        fill="#F2F2F2"
      />
      <path
        className={className2}
        d="M16.6343 33.1672C17.2924 33.1603 17.9501 33.2014 18.6023 33.2903C18.9615 33.4352 19.2936 33.6401 19.5843 33.8963C19.2843 34.0743 18.9783 34.2533 18.6683 34.4273C18.6383 34.4453 18.5883 34.4273 18.5473 34.4273C14.9093 34.5183 14.9093 34.5183 14.9093 30.8903C14.9093 29.4973 14.9163 28.1043 14.9093 26.7113C14.9093 26.1053 14.8833 25.5553 15.7503 25.5413C16.6013 25.5283 16.6503 26.0413 16.6373 26.6683C16.6243 27.7333 16.6373 28.7993 16.6373 29.8683C16.6373 30.9373 16.6343 31.9872 16.6343 33.1672Z"
        fill="#F2F2F2"
      />
      <path
        className={className2}
        d="M22.5622 25.621V34.312C20.9132 34.525 20.8362 34.4631 20.8442 33.0631C20.8489 31.0591 20.8519 29.055 20.8532 27.051C20.8492 25.642 20.9712 25.522 22.5622 25.621Z"
        fill="#F2F2F2"
      />
    </svg>
  );
};

export default LiveVideoIcon;
