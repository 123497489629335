import React from "react";
import { NavLink } from "react-router-dom";

const MainProfileLeftBarBtn = ({ name, path,icon }) => {
  return (
    <NavLink
      activeClassName="mainProfileLeftBtnActive"
      to={`/life/uviom-profile/${path}`}
    >
      <div className="LookMeTypeButton box-shadow justify-content-sm-start ps-sm-5 mt-3  ">
        <h6 className="mb-0 d-flex align-items-center"> 
        {icon} {name}</h6>
      </div>
    </NavLink>
  );
};

export default MainProfileLeftBarBtn;
