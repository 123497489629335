import {
  faBell,
  faBuilding,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBookmark,
  faCheckCircle,
  faClone,
  faGripVertical,
  faPlusCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import "./job_top_nav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import NavTooltip from "src/Common/NavToltip/NavTooltip";
import Avatar from "src/Projects/Life/Common/Avatar";
import HomeIcon from "src/Projects/Life/icons/HomeIcon";

import {
  applicantPhoto,
  companyProfilePhotoUrl,
} from "../../utilities/job_commonUrl";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import { useState } from "react";
import CategorySidebar from "../CategorySidebar/CategorySidebar";
import { useSelector } from "react-redux";
// import ThreeDots from "src/Projects/Life/icons/ThreeDots";

const TopNavbar = () => {
  const { pathname } = useLocation();
  const path = "/" + pathname.split("/")[1];

  const [showSidebar, setShowSidebar] = useState(false);
  const handleSidebarClose = () => setShowSidebar(false);
  const isCompany = pathname.split("/").includes("company");

  const { company_profile, applicant_profile } = useSelector(
    (store) => store.jobProfile
  );
  const { applicant_name, user_section } = applicant_profile || {};

  const profile_image = user_section
    ? applicantPhoto + user_section.photo
    : "/user.png";

  const companyProfile = company_profile?.profile_picture
    ? companyProfilePhotoUrl + company_profile?.profile_picture
    : "/user.png";

  const navClasses = {
    className: ({ isActive }) =>
      `topNavItem ${isActive ? "navbarActiveItem" : ""}`,
  };

  return (
    <div
      className={`jobTopBar stickyParts nav-bg shadow-none sticky-top`}
      id="main-navigation"
      style={{ zIndex: "999" }}
    >
      <NavLink {...navClasses} to={`${path}`}>
        <HomeIcon className="icon-clr" size="1.6rem" />
      </NavLink>
      <NavLink {...navClasses} to={`${path}/notification`}>
        <NavTooltip title={"Notification"}>
          <FontAwesomeIcon
            className="icon-clr"
            style={{ fontSize: "1.6rem" }}
            icon={faBell}
          />
        </NavTooltip>
      </NavLink>
      {/* ------------- */}

      <NavLink
        className={({ isActive }) =>
          `topNavItem d-none d-lg-inline-block ${
            isActive && "navbarActiveItem"
          }`
        }
        to={`${path}/search`}
      >
        <NavTooltip title={"Search job posts"}>
          <FontAwesomeIcon
            style={{ fontSize: "1.5rem" }}
            icon={faSearch}
            className="icon-clr "
          />
        </NavTooltip>
      </NavLink>
      <NavLink
        {...navClasses}
        to={`${path}/${isCompany ? "company/posted-job" : "save-job"}`}
      >
        <NavTooltip title={isCompany ? "Posted Job" : "Save Job"}>
          <FontAwesomeIcon
            style={{ fontSize: "1.5rem" }}
            icon={isCompany ? faClone : faBookmark}
            className="icon-clr"
          />
        </NavTooltip>
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          `topNavItem d-none d-lg-inline-block ${
            isActive && "navbarActiveItem"
          }`
        }
        to={`${path}/${isCompany ? "company/profile" : "applicant-profile"}`}
      >
        <NavTooltip
          title={
            isCompany
              ? company_profile?.company_name
              : applicant_name || "Guest"
          }
        >
          <Avatar
            imgUrl={isCompany ? companyProfile : profile_image}
            size="2.2rem"
          ></Avatar>
        </NavTooltip>
      </NavLink>

      <Dropdown className="nav-profile-dropdown  d-lg-none topNavItem">
        <Dropdown.Toggle>
          <Avatar
            imgUrl={isCompany ? companyProfile : profile_image}
            size="2.2rem"
          ></Avatar>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item as={"div"}>
            <NavLink
              to={`${path}/${isCompany ? "company/posted-job" : "save-job"}`}
            >
              <FontAwesomeIcon
                icon={isCompany ? faClone : faBookmark}
                size="lg"
                className="me-2 icon-clr"
              ></FontAwesomeIcon>{" "}
              {isCompany ? "Posted Job" : " Save Job"}
            </NavLink>
          </Dropdown.Item>
          <Dropdown.Item as={"div"}>
            <NavLink
              to={`${path}/${isCompany ? "company/create-job" : "applied-job"}`}
            >
              <FontAwesomeIcon
                icon={isCompany ? faPlusCircle : faCheckCircle}
                size="lg"
                className="me-2 icon-clr"
              ></FontAwesomeIcon>{" "}
              {isCompany ? "Add Job" : " Applied Job"}
            </NavLink>
          </Dropdown.Item>
          <Dropdown.Item as={"div"}>
            <NavLink to={`${path}/applicant-profile`}>
              <FontAwesomeIcon
                icon={faUser}
                size="lg"
                className="me-2 icon-clr"
              ></FontAwesomeIcon>{" "}
              Applicant Profile
            </NavLink>
          </Dropdown.Item>
          <Dropdown.Item as={"div"}>
            <NavLink to={`${path}/company/profile`}>
              <FontAwesomeIcon
                icon={faBuilding}
                size="lg"
                className="me-2 icon-clr"
              ></FontAwesomeIcon>{" "}
              Company Profile
            </NavLink>
          </Dropdown.Item>
          <Dropdown.Item as={"div"}>
            <NavLink to={"/"}>
              <FontAwesomeIcon
                icon={faGripVertical}
                size="lg"
                className="me-2 icon-clr"
              ></FontAwesomeIcon>{" "}
              UVIOM Dashboard
            </NavLink>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <button
        className="topNavItem  btn-0 d-lg-none"
        onClick={() => setShowSidebar(true)}
      >
        <NavTooltip title={"job Category"}>
          <ThreeDots size="1.5rem" className="icon-clr"></ThreeDots>
        </NavTooltip>
      </button>

      <CategorySidebar show={showSidebar} handleClose={handleSidebarClose} />
    </div>
  );
};

export default TopNavbar;
