import React from "react";
import { Routes, Route } from "react-router-dom";
import { Works } from "./Works/Works";
import { Education } from "./Education/Education";

import { Events } from "./Events/Events";
import { Sports } from "./Sports/Sports";

import { Music } from "./Music/Music";
import { TvProgram } from "./TvProgram/TvProgram";

import { Books } from "./Books/Books";
import { Film } from "./Film/Film";
import { Overview } from "../Overview";

export const MiddleRightCol = ({ info, getData, middleColArg }) => {
  return (
    <Routes>
      <Route path="/" element={<Overview middleColArg={middleColArg} />} />
      <Route path="/works" element={<Works info={info} />} />
      <Route path="/education" element={<Education info={info} />} />
      <Route path="/events" element={<Events info={info} />} />
      <Route path="/music" element={<Music info={info} />} />
      <Route path="/books" element={<Books info={info} />} />
      <Route path="/film" element={<Film info={info} />} />
      <Route path="/sports" element={<Sports info={info} />} />
      <Route path="/tv-program" element={<TvProgram info={info} />} />
    </Routes>
  );
};
