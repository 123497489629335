/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { MiddleCol } from "./Components/MiddleCol/MiddleCol";
import LeftCol from "./Components/LeftCol/LeftCol";
import CoverAndProfile from "./Components/CoverAndProfileSection/CoverAndProfile";
import "./PublicLife.css";
import { RightCol } from "./Components/RightCol/RightCol";
import Footer from "../../LoginPage/Footer/Footer";
import {
  friendshipInfoUrl,
  otherProfileOverviewUrl,
} from "src/Projects/Life/Common/CommonUrl";
import { getInfo } from "./Components/GetApiCalls";
import { useLocation, useParams } from "react-router-dom";
import useStore from "src/hooks/useStore";
import { isEmpty } from "lodash";
import { BubbleScale } from "src/Common/BubbleScale";
import { useDispatch } from "react-redux";
import {
  getSingleUserChat,
  setChatListPopup,
  setCurrentChatting,
  setCurrentChattingUser,
  setShowMessagePopup,
} from "src/features/life/messengerSlice";
import ScrollToTop from "src/Common/ScrollToTop";
import { Helmet } from "react-helmet";

const PublicLife = () => {
  const { isLoading, setIsLoading } = useStore();
  const dispatch = useDispatch();
  const { user_id } = useParams();
  const { pathname } = useLocation();
  const [info, setinfo] = useState();
  const [friendshipInfo, setfriendshipInfo] = useState({});

  const [searchBoxShow, setSearchBoxShow] = useState(false);

  const [ClickedInfo, setClickedInfo] = useState();
  useEffect(() => {
    if (!isEmpty(info)) {
      setClickedInfo({
        first_name: info.users_info.first_name,
        id: user_id,
        last_name: info.users_info.last_name,
        photo: info.users_info.photo,
        profile_id: null,
        username: null,
      });
    }
  }, [info]);

  const ChatContentAction = () => {
    dispatch(setCurrentChatting([]));
    dispatch(setChatListPopup(false));
    dispatch(setCurrentChattingUser(ClickedInfo));
    dispatch(getSingleUserChat({ user_id: ClickedInfo.id }));
    dispatch(setShowMessagePopup(true));
  };

  const openSearchbox = () => {
    setSearchBoxShow(!searchBoxShow);
  };

  const getData = () => {
    getInfo(setIsLoading, otherProfileOverviewUrl, setinfo, user_id);
    getInfo(setIsLoading, friendshipInfoUrl, setfriendshipInfo, user_id);
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const myRef = useRef(null);
  useEffect(() => {
    if (myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const coverAndProfileArg = {
    info: info,
    getData: getData,
  };
  const middleColArg = {
    openSearchbox: openSearchbox,
    searchBoxShow: searchBoxShow,
    setSearchBoxShow: setSearchBoxShow,
    OverviewInfo: info,
    getData: getData,
    user_id: user_id,
  };
  const leftColArg = {
    friendshipInfo: friendshipInfo,
    getData: getData,
    overviewInfo: info,
    user_id: user_id,
  };
  if (isLoading) {
    return <BubbleScale extraStyle={{ marginTop: "5rem" }} />;
  }
  // console.log(leftColArg);
  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{ClickedInfo?.first_name + " " + ClickedInfo?.last_name}</title>
      </Helmet>
      <div className="MainPart publicLife">
        {!pathname.includes("/message") &&
          !pathname.includes("/notificaiton") && (
            <CoverAndProfile coverAndProfileArg={coverAndProfileArg} />
          )}
        <div className="mainSectionForLife " ref={myRef}>
          <Container fluid="xxl" className="position-relative">
            <Row className="middleContainer h-inherit">
              {/*  <button
                className="d-lg-none btn btn-secondary"
                onClick={ChatContentAction}
              >
                Message
              </button> */}
              <RightCol ChatContentAction={ChatContentAction} />
              <MiddleCol middleColArg={middleColArg} />
              <LeftCol leftColArg={leftColArg} />
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PublicLife;
