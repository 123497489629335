import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useStoreRatingMutation } from "src/app/services/ubizzApi/ubizzApi";
import Star from "src/Projects/Food/Common/icons/Star";
import StartFill from "src/Projects/Food/Common/icons/StartFill";

import "./ratingModal.css";

const RatingModal = ({
  show,
  handleClose,
  url = "",
  fieldName,
  id,
  orderId,
}) => {
  const [review, setReview] = useState("");
  const [starCount, setStarCount] = useState([]);
  const [error, setError] = useState("");
  const handleRatingChange = (count) => {
    const rating = [...new Array(count)].map((_, index) => index);
    setStarCount(rating);
  };

  const [storeRating, { isLoading, isSuccess, data }] =
    useStoreRatingMutation();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!review || !starCount.length) {
      setError("This field is required");
    } else {
      setError("");

      const data = {
        message: review,
        rating: starCount.length,
        order_id: orderId,
      };

      if (fieldName) {
        data[fieldName] = id;
      }
      storeRating({ url, data });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      setReview("");
      setStarCount([]);
    }

    if (error) {
      setError(error?.error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, error]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      className="rating-modal corporate-rating-modal"
    >
      <Modal.Header className="border-0">
        <Modal.Title as={"h5"}>Your Rating</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>Rating</h6>
        <div className="rating-modal-star-box">
          {[...new Array(5)].map((_, index) => (
            <button
              onClick={() => handleRatingChange(index + 1)}
              key={index}
              className={`btn-0 `}
            >
              {starCount.includes(index) ? (
                <StartFill />
              ) : (
                <Star className="star-rating-icon" />
              )}
            </button>
          ))}
        </div>
        <br />
        <h6>Review</h6>
        <Form>
          <Form.Control
            onChange={(e) => setReview(e.target.value)}
            value={review}
            as="textarea"
            className="border-gray"
            rows={4}
          />
        </Form>
        <p className="text-danger text-end font-14 mt-2 mb-0">{error}</p>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <button
          onClick={handleClose}
          className="send-code-btn  manage-order-btn py-1 primary-text"
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="send-code-btn cart-confirm-btn manage-order-btn py-1 text-dark"
          disabled={isLoading}
        >
          {isLoading ? (
            <span className="text-dark">
              <FontAwesomeIcon icon={faSpinner} className="me-2" spin />{" "}
              Submit...
            </span>
          ) : (
            "Submit"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RatingModal;
