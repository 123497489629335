import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const NavTooltip = ({children , title}) => {
    return (
        <OverlayTrigger
        placement="bottom"
        delay={{ show: 100, hide: 300 }}
            overlay={<Tooltip id="bs-tooltip">{title}</Tooltip>}
      >
        {({ ref, ...triggerHandler }) => (
          <span className="h-100 w-100 d-flex align-items-center justify-content-center"  {...triggerHandler} ref={ref}>
            {children}
          </span>
        )}
      </OverlayTrigger>
    );
};

export default NavTooltip;