import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentVideoCommentReply } from "src/features/view/viewDataSlice";
import CommentSkeleton from "../../common/CommentSkeleton";
import { SingleCommentReply } from "./SingleCommentReply";

function VideoCommentsReply({ commentId }) {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const {
    currentVideoComments: { data: allComment },
  } = useSelector((store) => store.viewData);

  const { replies } = allComment?.find(
    (comment) => comment.id === parseInt(commentId)
  );

  useEffect(() => {
    dispatch(getCurrentVideoCommentReply(commentId));
  }, [commentId, dispatch]);

  return (
    <div>
      {replies.length >= 1 && !replies[0]?.channel_profile_section && (
        <CommentSkeleton reply />
      )}

      {replies &&
        replies[0]?.channel_profile_section &&
        replies.map((reply) => (
          <SingleCommentReply
            key={reply.id}
            commentId={reply.id}
            parentId={reply.comment_id}
            comment={reply.comment_reply}
            userName={reply?.channel_profile_section?.channel_name}
            userId={reply?.channel_profile_section?.user_id}
            userPhoto={reply?.channel_profile_section?.photo}
            createdAt={reply.created_at}
            commentLiked={reply.comment_reply_likes_count}
            isLiked={reply.personal_reply_comment_like_status_count}
          />
        ))}
    </div>
  );
}
export default VideoCommentsReply;
