import { Url } from "src/Projects/Life/Common/CommonUrl";

export const UbizzUrl = "https://ecom.vendor.uviom.com/api";
export const ImgUrl = "https://uviom-ecommerce.s3.amazonaws.com";
export const CACHE_EXPIRE_TIME = 600;

export const allShopsUrl = `${UbizzUrl}/shops`;
export const shopDetailsUrl = `${UbizzUrl}/shop-details`;
export const ShopLimitUrl = `${UbizzUrl}/shop-limit`;
export const ShopwiseProductSearchUrl = `${UbizzUrl}/shop-wise-product-search`;
export const globalSearch = `${UbizzUrl}/header-search`;

export const ShopBannerImgBase = `${ImgUrl}/frontend_upload_asset/vendor/vendor_image/banner_image/original_path`;
export const ShopBannerImgBaseL = `${ImgUrl}/frontend_upload_asset/vendor/vendor_image/banner_image/large_image`;
export const ShopBannerImgBaseM = `${ImgUrl}/frontend_upload_asset/vendor/vendor_image/banner_image/large_medium_image`;
export const ShopLogoBase = `${ImgUrl}/frontend_upload_asset/vendor/vendor_image/logo_image/original_path`;
export const ShopLogoBaseM = `${ImgUrl}/frontend_upload_asset/vendor/vendor_image/logo_image/medium_image`;
export const ShopWiseProductUrl = `${UbizzUrl}/shop-wise-product`;

export const allProductUrl = `${UbizzUrl}/product`;
export const limitProductUrl = `${UbizzUrl}/latest-products`;

export const productImgBase = `${ImgUrl}/frontend_upload_asset/product/product_image/original_path`;
export const productImgBase_m = `${ImgUrl}/frontend_upload_asset/product/product_image/medium_image`;

export const sliderImgUrl = `${UbizzUrl}/slider`;
export const vendorSliderImgUrl = `${UbizzUrl}/vendor-slider`;
export const vendorSliderImgBase = `${ImgUrl}/frontend_upload_asset/vendor_slider/store_image/original_path`;

export const sliderImgBase = `${ImgUrl}/frontend_upload_asset/slider/slider_image/original_path`;
export const productDetailsUrl = `${UbizzUrl}/product-details`;
export const productFeatureImageUrl = `${ImgUrl}/frontend_upload_asset/product/features_image`;

export const brand_logoBase = `${ImgUrl}/frontend_upload_asset/manufacturer/brand_logo/original_path`;
export const brandImageBase = `${ImgUrl}/frontend_upload_asset/manufacturer/brand_image/original_path`;

export const brandUrl = `${UbizzUrl}/manufacturer`;
export const brandWiseProductUrl = `${UbizzUrl}/brand-wise-product`;
export const branddetailsUrl = `${UbizzUrl}/brand-details`;

export const navCategoryUrl = `${UbizzUrl}/nav-category`;
export const limitedCategoryUrl = `${UbizzUrl}/category-limited-product`;
export const subCategoryUrl = `${UbizzUrl}/sub-category-wise-product`;
export const subSubCategorysUrl = `${UbizzUrl}/sub-sub-category-wise-product`;
export const categoryRelatedProductUrl = `${UbizzUrl}/category-related-product`;
export const subcategoryRelatedProductUrl = `${UbizzUrl}/subcategory-related-product`;
export const subSubcategoryRelatedProductUrl = `${UbizzUrl}/sub-subcategory-related-product`;

export const categoryWiseProductUrl = `${UbizzUrl}/category-wise-product`;
export const orderVerifyUrl = `${Url}/order/payment/order-verify`;
export const placeOrderUrl = `${Url}/order/payment/place-order`;
export const showAllOrderUrl = `${Url}/order/show-all-order`;
export const showPendingOrderUrl = `${Url}/order/show-pending-order`;
export const showComfirmOrderUrl = `${Url}/order/show-comfirm-order`;
export const uviomPendingOrderUrl = `${Url}/order/uviom-pending-order`;
export const showcancelOrderUrl = `${Url}/order/show-cancel-order`;
export const uviomApprovedOrderUrl = `${Url}/order/uviom-approved-order`;
export const uviomreceivedOrderUrl = `${Url}/order/uviom-received-order`;
export const uviomdeliveredOrderUrl = `${Url}/order/uviom-delivered-order`;
export const customerReceivedOrderUrl = `${Url}/order/customer-received-order`;
export const vendorReceivedOrderUrl = `${Url}/order/vendor-received-order`;

export const showOrderdetailsUrl = `${Url}/order/show-order-details`;

export const showWishlistUrl = `${Url}/wishlist/show-wish-list`;
export const deleteWishlistUrl = `${Url}/wishlist/delete-wish-list`;
export const storeWishlistUrl = `${Url}/wishlist/store-wish-list`;
export const ContactUsUrl = `${UbizzUrl}/contact-us`;

export const productRequestUrl = `${UbizzUrl}/product-request`;

// Rating and review

export const orderRatingUrl = `${Url}/rating/store-order-rating`;
export const productRatingUrl = `${Url}/rating/store-product-rating`;
export const shopRatingUrl = `${Url}/rating/store-shop-rating`;

export const productReportUrl = `${UbizzUrl}/product-report`;
export const shopReportUrl = `${UbizzUrl}/vendor-report`;
export const orderReportUrl = `${UbizzUrl}/order-report`;
