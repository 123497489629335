/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { channelBannerImg, channelLogoImg } from "../../common/view_commonUrl";
import { dataURLtoFile } from "src/Projects/Life/utils/dataURLtoFile";
import {
  faCamera,
  faSpinner,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useGetChannelCategoriesQuery } from "src/app/services/viewApi/viewGetApi";
import { useUpdateProfileInfoMutation } from "src/app/services/viewApi/viewPostApi";
import { useDispatch } from "react-redux";
import { UpdateProfilePicture } from "./postImage";
import CroppedPhoto from "./CroppedPhoto";
import { compare_2_Object, get_AWS_file_name } from "src/utilities/utilities";
import compress_image from "src/utilities/compress_image";
import useAuth from "src/hooks/useAuth";
import { getChannelDetails } from "src/features/view/viewDataSlice";

function EditChannelModal({
  show,
  handleClose,
  coverImage,
  channelProfile,
  channelData,
}) {
  const dispatch = useDispatch();

  const [
    updateProfileInfo,
    { isLoading: info_loading, isSuccess = "success" },
  ] = useUpdateProfileInfoMutation();
  const [profile_croppedImage, setProfile_croppedImage] = useState({});
  const [cover_croppedImage, setCover_croppedImage] = useState({});
  const [openCrop, setOpenCrop] = useState(false);

  const [selectedCover, setSelectedCover] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [updateStatus, setUpdateStatus] = useState({
    info: false,
    profile: false,
    banner: false,
  });

  const { authUser } = useAuth();

  // details info
  const channelNameRef = useRef();
  const channelTitleRef = useRef();
  const channelCategoryRef = useRef();
  const channelDescRef = useRef();
  const privacyRef = useRef();

  const { data } = useGetChannelCategoriesQuery();

  useEffect(() => {
    setOpenCrop(false);
  }, [show]);

  const handleCloseModal = () => {
    setCover_croppedImage({});
    setProfile_croppedImage({});
    handleClose();
  };

  useEffect(() => {
    if (
      updateStatus.profile &&
      updateStatus.banner &&
      (isSuccess || updateStatus.info)
    ) {
      dispatch(getChannelDetails(authUser.id));
      handleCloseModal();
    }
  }, [updateStatus.profile, updateStatus.banner, isSuccess, updateStatus.info]);

  const callAfterSuccess = (type) => {
    if (type === "banner") {
      setUpdateStatus((prevState) => ({ ...prevState, banner: true }));
    } else {
      setUpdateStatus((prevState) => ({ ...prevState, profile: true }));
    }
    setIsLoading(false);
  };

  const callAfterRejected = (type) => {
    setIsLoading(false);
    setUpdateStatus((prevState) => ({
      ...prevState,
      profile: true,
      banner: true,
    }));
  };

  const handleImageChange = (event) => {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]); //event.target.files[0] contain object file so we need to convert it on DataURL
    reader.addEventListener("load", () => {
      if (event.target.name === "profile") {
        setSelectedProfile(reader.result);
        setOpenCrop("profile");
      } else {
        setSelectedCover(reader.result);
        setOpenCrop("banner");
      }
    });
  };

  const handleSubmit = async () => {
    const formData = {
      channel_name: channelNameRef.current.value,
      channel_title: channelTitleRef.current.value,
      channel_category_id: parseInt(channelCategoryRef.current?.value),
      channel_description: channelDescRef.current.value,
      privacy: privacyRef.current.value,
    };

    if (!compare_2_Object(formData, channelData)) {
      updateProfileInfo(formData);
    } else {
      setUpdateStatus((prevState) => ({ ...prevState, info: true }));
    }

    if (cover_croppedImage?.file) {
      setIsLoading(true);
      const canvasDataUrl = cover_croppedImage?.file.toDataURL("image/jpeg");
      const convertedUrlToFile = dataURLtoFile(
        canvasDataUrl,
        get_AWS_file_name()
      );
      const compressed_images = await compress_image([convertedUrlToFile]);
      const formData = new FormData();
      formData.append("banner", compressed_images[0]);
      UpdateProfilePicture({
        formData,
        type: "banner",
        callAfterRejected,
        callAfterSuccess,
      });
    } else {
      setUpdateStatus((prevState) => ({ ...prevState, banner: true }));
    }

    if (profile_croppedImage?.file) {
      setIsLoading(true);
      const canvasDataUrl = profile_croppedImage?.file.toDataURL("image/jpeg");
      const convertedUrlToFile = dataURLtoFile(
        canvasDataUrl,
        get_AWS_file_name()
      );
      const compressed_images = await compress_image([convertedUrlToFile]);
      const formData = new FormData();
      formData.append("photo", compressed_images[0]);

      UpdateProfilePicture({
        formData,
        type: "profile",
        callAfterRejected,
        callAfterSuccess,
      });
    } else {
      setUpdateStatus((prevState) => ({ ...prevState, profile: true }));
    }
  };

  return (
    <Modal className="viewModal" centered show={show} onHide={handleCloseModal}>
      <ModalWrapper>
        {!openCrop ? (
          <>
            {isLoading || info_loading ? (
              <div
                className="d-flex justify-content-center align-items-center position-relative"
                style={{ minHeight: "17rem" }}
              >
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  size="2x"
                  className="me-2"
                />
                <span className="fs-4" style={{ letterSpacing: "2px" }}>
                  {"Updating..."}
                </span>
              </div>
            ) : (
              <>
                <Modal.Header className="stickyParts z-index-1">
                  <button className="text-orange" onClick={handleCloseModal}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                  <div className="modal-title">Edit Channel</div>
                  <button
                    className="save save-modal"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <div className="upload-container">
                      <img
                        className="cover-image"
                        src={
                          cover_croppedImage?.url
                            ? cover_croppedImage.url
                            : coverImage
                            ? channelBannerImg + coverImage
                            : "https://i.ibb.co/dKqrzrX/played-Video-min.jpg"
                        }
                        alt=""
                      />
                      <label htmlFor="cover-image">
                        <FontAwesomeIcon
                          className="upload-icon"
                          icon={faCamera}
                        />
                      </label>
                      <input
                        type="file"
                        onChange={handleImageChange}
                        id="cover-image"
                        name="cover"
                        accept="image/*"
                        hidden
                      />
                    </div>
                    <div className="profile-upload-container">
                      <img
                        className="profile-image"
                        src={
                          profile_croppedImage?.url
                            ? profile_croppedImage.url
                            : channelProfile
                            ? channelLogoImg + channelProfile
                            : "/user.png"
                        }
                        alt=""
                      />
                      <label htmlFor="profile-image">
                        <FontAwesomeIcon
                          className="upload-icon"
                          icon={faCamera}
                        />
                      </label>
                      <input
                        type="file"
                        onChange={handleImageChange}
                        id="profile-image"
                        name="profile"
                        accept="image/*"
                        hidden
                      />
                    </div>
                    <div className="px-3">
                      <Form>
                        <Form.Group className="mb-3">
                          <Form.Label>Channel Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="channel_name"
                            ref={channelNameRef}
                            defaultValue={channelData?.channel_name}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Channel Title</Form.Label>
                          <Form.Control
                            type="text"
                            name="channel_title"
                            ref={channelTitleRef}
                            defaultValue={channelData?.channel_title}
                          />
                        </Form.Group>
                        {data && (
                          <Form.Group className="mb-3">
                            <Form.Label>Channel Category</Form.Label>
                            <Form.Select
                              name="channel_category_id"
                              ref={channelCategoryRef}
                              defaultValue={channelData?.channel_category_id}
                            >
                              {data.map((category, index) => (
                                <option key={index} value={index}>
                                  {category.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        )}

                        <Form.Group className="mb-3">
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="channel_description"
                            ref={channelDescRef}
                            rows={4}
                            defaultValue={channelData?.channel_description}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Status</Form.Label>
                          <Form.Select
                            name="privacy"
                            defaultValue={channelData?.privacy}
                            ref={privacyRef}
                          >
                            <option value="private">Private</option>
                            <option value="public">Public</option>
                          </Form.Select>
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                </Modal.Body>
              </>
            )}
          </>
        ) : (
          <>
            {openCrop === "profile" ? (
              <CroppedPhoto
                imageFile={selectedProfile}
                setCroppedImage={setProfile_croppedImage}
                setCloseCrop={setOpenCrop}
                handleClose={handleCloseModal}
                type="profile"
              />
            ) : (
              <CroppedPhoto
                imageFile={selectedCover}
                setCroppedImage={setCover_croppedImage}
                setCloseCrop={setOpenCrop}
                handleClose={handleCloseModal}
                type="banner"
              />
            )}
          </>
        )}
      </ModalWrapper>
    </Modal>
  );
}
export default EditChannelModal;

const ModalWrapper = styled.div`
  .cropper-container {
    height: 200px;
    position: relative;
  }

  & .modal-header {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    gap: 0.5rem;
    position: sticky;

    & > button {
      background-color: transparent;
      border: 0;
    }

    & > .modal-title {
      margin: 0;
      font-size: 1.1rem;
    }
  }

  & .modal-body {
    padding: 0 0 1rem;

    /* height: 100rem; */

    & .upload-container {
      position: relative;
    }
    & .profile-upload-container {
      display: inline-block;
      position: relative;
      /* transform: translateY(-50%); */
      margin-top: -5rem;
    }
    & .upload-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background-color: #d3d3d392;
      padding: 0.7rem;
      font-size: 1.1rem;
      border-radius: 50%;
      cursor: pointer;
    }

    & .cover-image {
      max-width: 100%;
      width: 100%;
      max-height: 10rem;
      object-fit: cover;
    }

    & .profile-image {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      margin: 1rem;
      box-sizing: content-box;
      border: 1px solid #333;
    }
  }
`;
