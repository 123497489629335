import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import useQuery from "src/hooks/useQuery";
import CloseIcon from "src/Projects/Life/icons/CloseIcon";

const SearchBox = ({ setShowSearchBox }) => {
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const { pathname } = useLocation();
  const dynamicPath = pathname.split("/")[1];
  const searchInputRef = useRef();
  const searchContainerRef = useRef();

  const navigate = useNavigate();

  const handleInputField = (e) => {
    const text = e.target.value;
    setSearchValue(text);
  };

  const getViewSearchHistory = () => {
    const data = JSON.parse(localStorage.getItem("view_search_history"));
    return data || [];
  };

  useEffect(() => {
    const data = getViewSearchHistory();
    const remainingData = data.filter((item) => {
      const searchText = searchValue.toLowerCase();
      const currentText = item.toLowerCase();
      if (searchText === " ") return true;
      return currentText.includes(searchText) && currentText !== searchText;
    });
    setSuggestions(remainingData);

    // searchContainerRef.current.focus();

    // return () => setShowSearchBox(false);
  }, [searchValue]);

  const handleSuggestionItemClick = (text) => {
    setSearchValue(text);
  };

  const handleSaveData = () => {
    const prevData = getViewSearchHistory();
    if (prevData.includes(searchValue)) return;
    if (prevData.length > 9) prevData.shift();
    prevData.push(searchValue);
    localStorage.setItem("view_search_history", JSON.stringify(prevData));
  };

  const handlePressEnter = (e) => {
    if (e.keyCode === 13) handleSearchBtn();
  };

  const handleSearchBtn = () => {
    setSuggestions([]);
    if (searchValue.trim()) {
      handleSaveData();
      navigate(`/${dynamicPath}/search?search=${searchValue}`);
    }
  };

  const handleInputFocus = () => {
    setSuggestions(getViewSearchHistory());
  };

  const handleDelete = (text) => {
    const data = JSON.parse(localStorage.getItem("view_search_history"));
    const index = data.indexOf(text);
    data.splice(index, 1);
    const copyData = [...suggestions];
    copyData.splice(index, 1);
    setSuggestions(copyData);
    localStorage.setItem("view_search_history", JSON.stringify(data));
  };

  return (
    <>
      <div
        // onBlur={() => setShowSearchBox(false)}
        ref={searchContainerRef}
        tabIndex={-1}
        className="global__search-box p-2 rounded-2"
        style={{ zIndex: 120 }}
      >
        <InputGroup className="profileVideoSearchInputGroup">
          <InputGroup.Text className="profileVideoSearchLabel">
            <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
          </InputGroup.Text>
          <FormControl
            value={searchValue}
            onChange={handleInputField}
            onFocus={handleInputFocus}
            onKeyUp={handlePressEnter}
            ref={searchInputRef}
            aria-label="search video"
            placeholder="Search Here..."
            className="profileVideoSearchInput"
          />
          <InputGroup.Text
            onClick={handleSearchBtn}
            className="profileVideoSearchLabel"
          >
            Search
          </InputGroup.Text>
        </InputGroup>

        {searchInputRef.current === document.activeElement &&
          suggestions.length > 0 && (
            <div className=".search_suggestion_dropdown">
              {suggestions.map((item) => (
                <SuggestionItem
                  key={item}
                  data={item}
                  handleClick={handleSuggestionItemClick}
                  handleDelete={handleDelete}
                />
              ))}
            </div>
          )}
      </div>
    </>
  );
};

export default SearchBox;

const SuggestionItem = ({ data, handleClick, handleDelete }) => {
  const clickFunc = (e) => {
    e.stopPropagation();
    handleClick(data);
  };

  return (
    <div className="d-flex justify-content-between p-2 align-items-center my-2 suggestion_item">
      <p onClick={clickFunc} className="flex-grow-1 primary-text">
        {data}
      </p>
      <button
        className="btn-0 search_history_remove-btn "
        onClick={() => handleDelete(data)}
      >
        <CloseIcon size="10" className="primary-text" />
      </button>
    </div>
  );
};
