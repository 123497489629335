import React from "react";
import { Dropdown } from "react-bootstrap";

export const ShareActions = () => {
  return (
    <Dropdown>
      <Dropdown.Toggle aria-label="share" className="ToggleDesignClear">
        <div className="fw-normal">
          <small>Share</small>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu variant="dark">
        <div className="ClickThreeDot dropDown-sm mb-3 px-3 pb-3 pt-3">
          <div className="  btnHoverBorder">
            <div className=" mt-1">
              <h6>Messengar</h6>
            </div>
          </div>
          <div className=" btnHoverBorder">
            <div className=" mt-1">
              <h6>Copy link</h6>
            </div>
          </div>
          <div className=" btnHoverBorder">
            <div className=" mt-1">
              <h6>Delete Post</h6>
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
