import {
  faFacebook,
  faInstagram,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Form, Nav, Row } from "react-bootstrap";
import LayoutWrapper from "./LayoutWrapper";

function Contact() {
  return (
    <LayoutWrapper>
      <Container fluid className="secondary-bg primary-text py-5">
        <Row className="g-4">
          <Col lg={6}>
            <div className="p-2 p-md-4">
              <p className="">Please, contact at the following addresses:</p>
              <div className="mt-4">
                <h6 className="fw-bold">UVIOM</h6>
                <p className="font-14 fw-500">
                  House: 100/A, Dhanmondi-4, Dhaka
                </p>
                <br />
                <p className="font-14 fw-500">
                  Working Hour: 09.00 AM to 06.00 PM
                </p>
                <p className="font-14 fw-500">Email: support@uviom.com</p>
                <p className="font-14 fw-500">
                  Website: www.uviomcorporate.com
                </p>
                <p className="font-14 fw-500">
                  Facebook: facebook.com/uviomcorporate
                </p>
              </div>

              <div className="contact-social-icon">
                <Nav.Link href="https://www.facebook.com/uviominc">
                  <span>
                    <FontAwesomeIcon
                      className="social-icon"
                      icon={faFacebook}
                    />
                  </span>
                </Nav.Link>
                <Nav.Link href="#">
                  <span>
                    <FontAwesomeIcon
                      className="social-icon"
                      icon={faInstagram}
                    />
                  </span>
                </Nav.Link>
                <Nav.Link href="#">
                  <span>
                    <FontAwesomeIcon className="social-icon" icon={faTwitter} />
                  </span>
                </Nav.Link>
                <Nav.Link href="#">
                  <span>
                    <FontAwesomeIcon
                      className="social-icon"
                      icon={faWhatsapp}
                    />
                  </span>
                </Nav.Link>
              </div>
            </div>
          </Col>

          <Col lg={6}>
            <Form className="contactForm p-2 p-md-4 me-0 me-lg-5">
              <Row>
                <Col>
                  <Form.Group className="mb-5" controlId="fName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" placeholder="First Name" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-5" controlId="lName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" placeholder="Last Name" />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-5" controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="email address"
                      className=""
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {" "}
                  <Form.Group className="mb-5" controlId="phone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="email" placeholder="01758542130" />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-5" controlId="message">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  className="contactTextarea"
                  placeholder="Write Here..."
                />
              </Form.Group>

              <div className="text-end">
                <button
                  className="send-code-btn cart-confirm-btn text-dark"
                  type="submit"
                >
                  Send
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </LayoutWrapper>
  );
}
export default Contact;
