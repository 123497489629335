import Typewriter from "typewriter-effect";
import { Link } from "react-router-dom";
import { LifeIcon } from "src/DashBord/Icon/LifeIcon";

function UviomMainProfileRightBar() {
  return (
    <div>
      <div className="LookMeWhiteIcon box-shadow nav-bg-opacity stickyParts py-2 ps-3 d-block">
        <div className="d-flex">
          <div className="LookMeButtonTypeIcon">
            <LifeIcon size="40" />
          </div>
          <Link to="/life/family-life">
            <div className="type-writer-text-box">
              <h5 className="mb-0">
                <span>UVIOM</span>
                <span className="text-orange">Life</span>
              </h5>
              <div className="lh-1 overflow-hidden type-writer-text logo-slogan">
                <Typewriter
                  options={{
                    strings: "Find Your Roots",
                    autoStart: true,
                    loop: true,
                  }}
                />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default UviomMainProfileRightBar;
