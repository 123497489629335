import React from "react";
import { useState } from "react";
import useAuth from "src/hooks/useAuth";
import Review from "../../Components/Common/Review";
import ReportModal from "../../Components/ReportModal/ReportModal";
import { productReportUrl } from "../../utilities/CommonUrl";
const ProductDescription = ({ info }) => {
  const { long_description } = info && info;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { authUser } = useAuth();

  return (
    <div className="my-4 product-description">
      <h5 className="text-orange">Description</h5>

      <div>
        <h6 className="text-orange">SPECIFICATIONS</h6>
        <div dangerouslySetInnerHTML={{ __html: long_description }}></div>
      </div>

      <div>
        <div className="d-flex justify-content-between">
          <h5 className="text-orange mb-2">Reviews</h5>
          <button
            onClick={() => setShow(true)}
            className="send-code-btn  manage-order-btn py-1 primary-text font-14"
          >
            Report This Products
          </button>
        </div>

        <Review />
        <Review />
        <Review />
      </div>

      <ReportModal
        show={show}
        handleClose={handleClose}
        title={"Product Report"}
        id={info.id}
        fieldName="product_id"
        user_id={authUser.id}
        url={productReportUrl}
      />
    </div>
  );
};

export default ProductDescription;
