import axios from "axios";

const paginationApiCall = (argument, isAddPrevData) => {
  const { 
    setIsLoading = () =>{} ,
    postList,
    setPostList,
    currentPage,
    setHasMore,
    Url,
    setError = ()=>{} } = argument;
  setIsLoading(true);

  const token = localStorage.getItem("access_token");

  // res.data.last_page
  axios
    .get(`${Url}?page=${currentPage}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      let next_page = res.data.next_page_url;
      let responseData = res.data.data;
      if (next_page === null) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      if (isAddPrevData) {
        if (Array.isArray(responseData)) {
          setPostList([...postList, ...responseData]);
        } else if (typeof responseData == "object" && responseData != null) {
          const resArray = [...postList];
          Object.entries(responseData).map(([key, value]) =>
            resArray.push(value)
          );
          setPostList(resArray);
        }

      } else {
        if (Array.isArray(responseData)) {
          setPostList([...responseData]);
        } else if (typeof responseData == 'object' && responseData != null) {
          const resArray = []
          Object.entries(responseData).map(([key, value]) => resArray.push(value))
          setPostList(resArray);
        }
      }
      setIsLoading(false);
    })
    .catch((err) => {
      console.log(err.message);
      setError(err.message)
      setIsLoading(false);
    });
};

export default paginationApiCall;
