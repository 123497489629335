import React from 'react'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const SingleItem = ({item}) => {
  return (
    <div className="d-flex justify-content-between my-4" >
    <div className='d-flex'>
      <div className='me-3 border  p-1 myIconCircle'><FontAwesomeIcon icon={faBriefcase} /></div>
      <div className="">
        <h5>{item.position}</h5>
        <div>{item.work}</div>
        <div><small>{item.description}</small></div>

        <div className='mediumTextSize'>{item.start_date} to {item.end_date}</div>
      </div>

    </div>
  </div>
  )
}
