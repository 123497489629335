/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import ChatContent from "./ChatContent/ChatContent";
import "./Common/MessageCommon.css";
import MessageSideBar from "./MessageSideBar/MessageSideBar";
import MediaQuery from "react-responsive";

import { useSelector } from "react-redux";
import useAuth from "src/hooks/useAuth";
import {
  getSingleUserChat,
  setCurrentChatting,
  setCurrentChattingUser,
  setMessageNotification,
} from "src/features/life/messengerSlice";
import { useDispatch } from "react-redux";

const Message = () => {
  const { chatUserList, chatList, changeUserList, messageNotification } =
    useSelector((state) => state.messengerData);

  const { authUser } = useAuth();
  const authUserId = Number(authUser.id);
  const dispatch = useDispatch();

  useEffect(() => {
    const first_user = chatUserList[0];
    let targetedUser;

    if (first_user?.from_user?.id === authUserId) {
      targetedUser = first_user?.to_user;
    } else {
      targetedUser = first_user?.from_user;
    }
    dispatch(setCurrentChattingUser(targetedUser));
    const messages = chatList[`chat_list_${targetedUser?.id}`];
    if (messages?.length) {
      dispatch(setCurrentChatting(messages));
    } else {
      dispatch(getSingleUserChat({ user_id: targetedUser?.id }));
    }
  }, [changeUserList]);

  return (
    <>
      {/*  <Row className="autoPlaySwitchRow">
        <Form.Switch
          type="switch"
          onChange={() => dispatch(setMessageNotification())}
          id="custom-switch"
          checked={messageNotification}
          label="Message Notification"
        />
      </Row> */}
      <div className="overflow-hidden" style={{ overFlowX: "hidden" }}>
        <MediaQuery maxWidth={576}>
          <Row className="">
            {/* for mobile  */}
            <Routes>
              <Route index element={<MessageSideBar />} />
              <Route path="/:userId/*" element={<ChatContent />} />
            </Routes>
          </Row>
        </MediaQuery>

        <Row className="g-2 d-none d-sm-flex">
          <MediaQuery minWidth={426} maxWidth={992}>
            {/* for pc MediaQuery */}
            <Col xs="12" sm="5" md="4" className="mt-0">
              <MessageSideBar />
            </Col>
          </MediaQuery>

          <Col xs="12" md="8" sm="7" lg="12" className="">
            <Routes>
              <Route path="/*" element={<ChatContent />} />
              <Route path="/:userId/*" element={<ChatContent />} />
            </Routes>
          </Col>

          {/* <Col xs="12" sm="5" md="4"  className="d-lg-none">
          <MessageSideBar/>
        </Col>  */}
        </Row>
      </div>
    </>
  );
};

export default Message;
