import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import InputWithLabel from "src/Common/InputWithLabel/InputWithLabel";
import { useNavigate} from "react-router-dom";
import { createApplicantProfileUrl } from "../../utilities/job_commonUrl";
import postData from "../../api_call/postApiCall";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import LocationSelectInput from "../../Components/Common/LocationSelectInput";
import { getDivisionByDistricts } from "../../utilities/functions";
import { useDispatch } from "react-redux";
import { getApplicantProfile } from "src/features/job/jobProfileSlice";

const CreateApplicantProfile = () => {
  const [userProfile, setUserProfile] = useState({
    applicant_name: "",
    qualification: "",
    permanent_address: "",
    present_address: "",
    contact_email: "",
    contact_phone: "",
    expected_salary: "",
    experiences: "",
    website: "",
    district_id: "",
    division_id: "",
  });

  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const [districts, setDistricts] = useState([]);
  const dispatch = useDispatch();

  const {
    applicant_name,
    qualification,
    permanent_address,
    present_address,
    contact_email,
    contact_phone,
    experiences,
    expected_salary,
    website,
    district_id,
    division_id,
  } = userProfile;

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const prevState = userProfile;
    prevState[name] = value;
    setUserProfile({ ...prevState });
  };

  const callAfterSuccess = () => {
    navigate("/Job");
    dispatch(getApplicantProfile());
  };
  useEffect(() => {
    const districts = getDivisionByDistricts(division_id) || [];
    setDistricts(districts);
  }, [division_id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const postDataArg = {
      url: createApplicantProfileUrl,
      data: userProfile,
      setIsLoading,
      setError,
      setSuccess,
      callAfterSuccess,
    };
    postData(postDataArg);
  };

  const inputProps = {
    required: true,
    className: ["bordaredInput", "text-start ps-4"],
    checkoutInput: true,
  };

  return (
    <div className="secondary-bg py-4">
      {loading && <BubbleLoader />}
      <div className="applicantTitle me-2">
        <h6> Create Applicant Profile</h6>
      </div>
      <Form className="my-4" onSubmit={handleSubmit}>
        <InputWithLabel
          {...inputProps}
          value={applicant_name}
          label="Name"
          name="applicant_name"
          handler={handleInputChange}
          placeholder="Name"
        />
        <InputWithLabel
          {...inputProps}
          value={qualification}
          label="Qualification"
          name="qualification"
          handler={handleInputChange}
          placeholder="Qualification"
        />
        <InputWithLabel
          {...inputProps}
          value={experiences}
          label="Experiences"
          name="experiences"
          handler={handleInputChange}
          placeholder="Experiences"
        />

        <InputWithLabel
          {...inputProps}
          value={expected_salary}
          label="Expected Salary"
          name="expected_salary"
          handler={handleInputChange}
          placeholder="Expected Salary"
          type="number"
        />
        <InputWithLabel
          {...inputProps}
          value={contact_email}
          label="Contact Email"
          name="contact_email"
          handler={handleInputChange}
          placeholder="Contact Email"
        />
        <InputWithLabel
          {...inputProps}
          value={contact_phone}
          label="Phone"
          name="contact_phone"
          handler={handleInputChange}
          placeholder="Contact Phone"
        />
        <InputWithLabel
          {...inputProps}
          value={website}
          label="Website"
          name="website"
          handler={handleInputChange}
          placeholder="website"
        />
        <LocationSelectInput
          {...inputProps}
          label="Location"
          handler={handleInputChange}
          district_value={district_id}
          division_value={division_id}
        >
          <option value="" disabled>
            Select District
          </option>
          {districts.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </LocationSelectInput>
        <InputWithLabel
          {...inputProps}
          value={present_address}
          label="Present Address"
          name="present_address"
          handler={handleInputChange}
          placeholder="Present Address"
        />
        <InputWithLabel
          {...inputProps}
          value={permanent_address}
          label="Permanent Address"
          name="permanent_address"
          handler={handleInputChange}
          placeholder="Permanent Address"
        />

        <div className="font-14">
          <p className="text-center text-danger">{error}</p>
          <p className="text-end text-orange">{success}</p>
        </div>
        <div className="d-flex justify-content-end me-3">
          <div>
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="send-code-btn me-2"
            >
              Cancel
            </button>
            <input
              type="submit"
              value="Create Profile"
              className="send-code-btn cart-confirm-btn text-dark "
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CreateApplicantProfile;
