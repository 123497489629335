import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { PostModal } from "./PostModal/PostModal";
import "./main.css";
import LiveVideoIcon from "src/Projects/Life/icons/LiveVideoIcon";
import AddPhotoIcon from "src/Projects/Life/icons/AddPhotoIcon";
import AddVideoIcon from "src/Projects/Life/icons/AddVideoIcon";

export const StorePost = () => {
  const [show, setShow] = useState(false);
  const [postType, setPostType] = useState(null);
  const handleClose = () => {
    setPostType(null);
    setShow(false);
  };
  const handleShow = (type) => {
    if (type === "video") setPostType("video");
    setShow(true);
  };

  const postModalArg = {
    activity: "posting",
    show: show,
    handleClose: handleClose,
    title: "Create Your Post",
  };

  return (
    <div className=" mt-3 ">
      <div className="storePostMain box-shadow">
        <div className="p-2 p-sm-3 w-100  mx-auto ">
          {/* */}
          <Col className="py-2" sm={12} md={12} lg={12}>
            <div className="searchDropdown px-1  mx-auto ">
              <div className="MessageInput shadow-none  mt-0 pt-0 ">
                <input
                  type="text"
                  className="px-2"
                  placeholder="Create New Mind ..."
                  onClick={handleShow}
                />
              </div>
              <hr className="mb-3 mt-2" />
              <div className="">
                <Row>
                  <Col>
                    <div className="d-flex align-items-center postIcons ">
                      <LiveVideoIcon className="topNavIcon" size="28" />
                      <div className="mx-2 show-320">Live</div>
                    </div>
                  </Col>
                  <Col className="borderLeft">
                    <div
                      className="d-flex align-items-center postIcons storePostIco "
                      onClick={handleShow}
                    >
                      <AddPhotoIcon className="topNavIcon" size="25" />
                      <div className="mx-2 show-320">Photo</div>
                    </div>
                  </Col>
                  <Col className="borderLeft">
                    <div
                      className="d-flex align-items-center postIcons storePostIco "
                      onClick={() => handleShow("video")}
                    >
                      <AddVideoIcon className="primary-text" size="24" />
                      <div className="mx-2 show-320">Video</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </div>
        <PostModal postModalArg={postModalArg} type={postType} />
      </div>
    </div>
  );
};
