/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useUpdateEducationMutation } from "src/app/services/jobApi/jobPostApi";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import InputWithLabel from "src/Common/InputWithLabel/InputWithLabel";
import { compare_2_Object } from "src/utilities/utilities";
const UpdateApplicantEducation = () => {

  const [updateEducation, {isLoading, isSuccess, error:server_error}] = useUpdateEducationMutation()
  const [error, setError] = useState("");
  const { state } = useLocation();
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setFormData({...state.data});
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let prevState = formData;
    prevState[name] = value;
    setFormData({ ...prevState });
  };

  const {
    institute,
    result,
    location,
    board,
    year,
    level,
    exam_name,
    subject_group,
    id
  } = formData;

  const handleCancel = () => {
    navigate(-1);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (compare_2_Object(state.data, formData) === false) {
      setError("");
      updateEducation({id:id, data:formData})
    } else {
      setError("Change some information to update Education");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/Job/applicant-profile");
    }
    if (server_error) {
      setError(server_error.error)
    }
  },[isSuccess, server_error])


  return (
    <div>
      {isLoading && <BubbleLoader />}
      <div className="applicantTitle">
        <h6>Update Applicant Education</h6>
      </div>
      <br />
      <div className="updateCompanyDetail overflow-hidden py-3">
        <Form onSubmit={handleFormSubmit}>
          {/* name */}
          <InputWithLabel
            label="Institution"
            name="institute"
            value={institute}
            handler={handleChange}
            checkoutInput
            placeholder="Institution Name"
            required={false}
          ></InputWithLabel>

          <InputWithLabel
            label="Location"
            checkoutInput
            name="location"
            value={location}
            handler={handleChange}
            placeholder="Location"
            required={false}
          ></InputWithLabel>
          <InputWithLabel
            label="Board"
            checkoutInput
            name="board"
            value={board}
            handler={handleChange}
            placeholder="Board "
            required={false}
          ></InputWithLabel>

          <InputWithLabel
            label="Level"
            checkoutInput
            name="level"
            value={level}
            handler={handleChange}
            placeholder="Level"
            required={false}
          ></InputWithLabel>
          <InputWithLabel
            label="Exam Name"
            checkoutInput
            name="exam_name"
            value={exam_name}
            handler={handleChange}
            placeholder="Exam Name"
            required={false}
          ></InputWithLabel>
          <InputWithLabel
            label="Subject Group"
            checkoutInput
            name="subject_group"
            value={subject_group}
            handler={handleChange}
            placeholder="Subject Group"
            required={false}
          ></InputWithLabel>

          <InputWithLabel
            label="Year"
            checkoutInput
            value={year}
            name="year"
            handler={handleChange}
            placeholder="Passing Year"
            type="number"
            required={false}
          ></InputWithLabel>
          <InputWithLabel
            label="Result"
            checkoutInput
            name="result"
            value={result}
            handler={handleChange}
            placeholder="Result"
            required={false}
          ></InputWithLabel>

          <p className="text-danger text-center font-14">{error}</p>

          <div className="text-center">
            <button
              onClick={handleCancel}
              type="button"
              className="send-code-btn me-2 py-1"
            >
              Cancel
            </button>
            <button
              type="submit"
              className=" send-code-btn cart-confirm-btn py-1"
            >
              Update
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UpdateApplicantEducation;
