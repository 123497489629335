import { Row, Col, Form, Button } from "react-bootstrap";

import "./contactForm.css";

function ContactForm() {
  return (
    <Form className="contactForm me-lg-4">
      <Row>
        <Col>
          <Form.Group className="mb-4" controlId="fName">
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text" placeholder="eg. rabiul" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-4" controlId="lName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control type="text" placeholder="eg. islam" />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group className="mb-4" controlId="email">
        <Form.Label>Email Address</Form.Label>
        <Form.Control type="email" placeholder="eg. rabiul@example.com" />
      </Form.Group>

      <Form.Group className="mb-4" controlId="message">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          className="contactTextarea"
          placeholder="Write Here..."
        />
      </Form.Group>

      <div className="text-end">
        <Button variant="outline" className="contactSubmit" type="submit">
          Send
        </Button>
      </div>
    </Form>
  );
}
export default ContactForm;
