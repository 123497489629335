import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CutomTooltip } from "src/Common/CutomTooltip";
import { brand_logoBase } from "src/Projects/Corporate/utilities/CommonUrl";

export const SingleCard = ({ item }) => {
  const navigate = useNavigate();
  const { brand_logo, brand_name } = item;

  const { pathname } = useLocation();
  const currentPath = "/" + pathname.split("/")[1];
  const brandDetailsHandle = () => {
    // console.log("item",item)
    navigate({
      pathname: `${currentPath}/brand-details/${item.slug}`,
      state: {
        stateData: item,
      },
    });
  };
  return (
    <CutomTooltip text={brand_name} placementName="top">
      <div
        onClick={brandDetailsHandle}
        className={`col-lg-2 col-md-3 col-sm-4 col-6  mb-2 `}
        style={{ color: "white" }}
      >
        <div className="brandcard div_center mx-auto shadow">
          <img
            src={`${brand_logoBase}/${brand_logo}`}
            alt="product card"
            className="w-100 h-100"
          />
        </div>
      </div>
    </CutomTooltip>
  );
};
