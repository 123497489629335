import React from 'react'
import { MyDateFormate } from 'src/Common/MyDateFormate'
import UVIOMBizzIcon from 'src/Projects/Corporate/assets/icons/UVIOMBizzIcon'

export const Header = ({printDiv,info}) => {
  return (
    <div className="invoiceHeader">
    <div onClick={()=>printDiv('PrintArea')}>
       <UVIOMBizzIcon />
     </div>
     <div>
       <h1>INVOICE</h1>
       <div className="mediumTextSize">
         <div className="dFlexBetween">
           <div>Invoice</div>
           <div>#{info && info.OrderID}</div>
         </div>
         <div className="dFlexBetween">
           <div>Date</div>
           <div><MyDateFormate date={info.created_at}/></div>
         </div>
       </div>
     </div>
    </div>
  )
}
