import React, { memo } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import { AddOnPages } from "../../AddOnPages/AddOnPages";
import { LifePageView } from "../../AddOnPages/LifePageView";
import { About } from "../../NestedRouteOnDashBodr/About/About";
import AddPost from "../../NestedRouteOnDashBodr/AddPostSection/AddPost";
import Friends from "../../NestedRouteOnDashBodr/Friends/Friends";
import GoLive from "../../NestedRouteOnDashBodr/GoLiveSection/GoLive";
import Message from "../../NestedRouteOnDashBodr/Message/Message";
import MoreOption from "../../NestedRouteOnDashBodr/MoreOption/MoreOption";
import Notification from "../../NestedRouteOnDashBodr/Notification/Notification";
import PostBestTime from "../../NestedRouteOnDashBodr/PostBestTime/PostBestTime";
import RecentPost from "../../NestedRouteOnDashBodr/RecentPost/RecentPost";
import LifeVideoOtherProfile from "../../NestedRouteOnDashBodr/VideoPost/LifeVideoOtherProfile/LifeVideoOtherProfile";
import VideoPost from "../../NestedRouteOnDashBodr/VideoPost/VideoPost";
import ViewAndEditProfile from "../../NestedRouteOnDashBodr/ViewAndEditProfile/ViewAndEditProfile";
import ScroolableNewsSection from "../../ScroolabelNewsSection/ScroolableNewsSection";
import BestTimeView from "../../NestedRouteOnDashBodr/PostBestTime/BestTimeView/BestTimeView";
import BestTimeOtherProfile from "../../NestedRouteOnDashBodr/PostBestTime/BestTimeOtherProfile/BestTimeOtherProfile";
import PhotoGallery from "../../NestedRouteOnDashBodr/ViewPhtotsAndVideos/PhotoGallery";
import VideoGallery from "../../NestedRouteOnDashBodr/ViewPhtotsAndVideos/VideoGallery";
import SavePost from "../../NestedRouteOnDashBodr/SavePost/SavePost";
import GlobalSearch from "../../NestedRouteOnDashBodr/GlobalSearch/GlobalSearch";
import BestTimeStoreProvider from "src/context/BestTimeStoreProvider";
import TimeLineBestTimeView from "../../BestTimeSection/TimeLineBestTimeView";
import ReportedPost from "../../NestedRouteOnDashBodr/ReportedPost/ReportedPost";

const MiddleColRoutes = () => {
  let { pathname } = useLocation();

  return (
    // <Suspense>
    <BestTimeStoreProvider>
      <Routes>
        <Route path="/" element={<ScroolableNewsSection />} />
        {/* left     bar  ---------------*/}
        <Route path="/add-on-pages" element={<AddOnPages />} />

        {/* middle    top bar  ---------------*/}
        <Route path="/lifepageview" element={<LifePageView />} />
        <Route path="/watch-video" element={<VideoPost />} />
        <Route
          path="/watch-video/profile/:profileId/*"
          element={<LifeVideoOtherProfile />}
        />
        <Route path="/go-live" element={<GoLive />} />
        <Route path="/notificaiton" element={<Notification />} />
        {/* <Route path={`/message`}>
          <MessagePage />
        </Route> */}
        <Route path="/message/*" element={<Message />} />
        <Route path="/search" element={<GlobalSearch />} />

        {/* middle    top bar  End---------------*/}
        {/* right bar rute F ------------------------------ */}
        <Route path="/about/*" element={<About />} />
        <Route path="/photo-gallery" element={<PhotoGallery />} />
        <Route path="/video-gallery/*" element={<VideoGallery />} />
        <Route path="/friends/*" element={<Friends />} />
        <Route path="/recent-post" element={<RecentPost />} />
        <Route path="/my-post" element={<ViewAndEditProfile />} />
        <Route path="/save-post" element={<SavePost />} />
        <Route path="/reported-post" element={<ReportedPost />} />
        <Route path="/post-best-time" element={<PostBestTime />} />
        <Route
          path="/post-best-time/profile/:profileId"
          element={<BestTimeOtherProfile />}
        />
        <Route
          path="/post-best-time/profile/:profileId/view-video/:contentId"
          element={<BestTimeView />}
        />
        <Route
          path="/post-best-time/view-video/:contentId"
          element={<TimeLineBestTimeView />}
        />
        {/* <Route path={`/groups`} element={<Groups />} /> */}
        <Route path="/add-post" element={<AddPost />} />
        <Route
          path="/more-option"
          element={<MoreOption timlineUrl={pathname} />}
        />
      </Routes>
    </BestTimeStoreProvider>
    // </Suspense>
  );
};

export default memo(MiddleColRoutes);
