import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Container, Form, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { paginationPostApiCall } from "src/Common/apiCall/PostApiCalls";
import { default as StoreItemSample } from "src/Projects/Corporate/Components/Common/StoreItem";
import { globalSearch, ShopBannerImgBaseL } from "src/Projects/Corporate/utilities/CommonUrl";
import StoreSliderItem from "../../Home/HomeStore/StoreSliderItem";
import StoreItem from "../../Store/StoreItem/StoreItem";

const SearchShop = () => {
  // const { searchText } = useParams();
  const { pathname } = useLocation();
  const mainUrl = `/${pathname.split("/")[1]}`;
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [info, setinfo] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { searchText: UrlSearchText } = useParams();
  const [formData, setformData] = useState({
    type: location.pathname.includes("/search-store") ? 0 : null,
    value: UrlSearchText,
  });
  const search = (isAddPrevData) => {
    const formArg = {
      setIsLoading: setIsLoading,
      postList: info,
      setPostList: setinfo,
      setHasMore: setHasMore,
      Url: `${globalSearch}?page=${currentPage}`,
      formData: formData,
    };
    paginationPostApiCall(formArg, isAddPrevData);
  };
  useEffect(() => {
    search(false);
  }, [formData]);
  useEffect(() => {
    if (currentPage > 1) {
      search(true);
    }
  }, [currentPage]);
  useEffect(() => {
    if (UrlSearchText !== formData.value) {
      setformData((prevState) => ({ ...prevState, value: UrlSearchText }));
      setCurrentPage(1);
    }
  }, [UrlSearchText]);

  // console.log("location.state.data", location.state.data)
  return (
    <Container className="py-4" style={{ minHeight: "65vh" }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <p className="font-14">
          Result for <span className="text-orange">"{formData?.value}"</span>
        </p>

        <div className="position-relative d-inline-block mt-1">
          <Form.Select
            aria-label="Default select example"
            className="corporate__navbar__dropdown border-0 py-2 "
          >
            <option>Sort by</option>
            <option value="1">New Arrived</option>
            <option value="2">Free Shiping</option>
          </Form.Select>
          <FontAwesomeIcon
            className="corporate__navbar__icon-down"
            icon={faChevronDown}
          ></FontAwesomeIcon>
        </div>
      </div>

      <Row xs="1" lg="2" className="g-4">
      {info &&
          info.length !== 0 &&
          info.map((data) => <StoreItem data={data} key={data.id} ShopBannerImgBaseWithLocation={ShopBannerImgBaseL}/>)}
      </Row>
      {hasMore ? (
        <Row>
          <div
            className="col-12 text-center"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <div className="btn btn-warning w-50 my-3">Load More</div>
          </div>
        </Row>
      ) : null}

      <div className="mt-4">
        <p className="font-14">Suggested Store</p>
      </div>
      <Row xs="1" lg="2" className="g-4">
        <StoreItemSample />
        <StoreItemSample />
      </Row>
    </Container>
  );
};

export default SearchShop;
