import React, { useEffect } from "react";
import { Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import CorporateIcon from "src/Projects/Corporate/assets/icons/CorporateIcon";
import Profile from "src/Projects/Corporate/Pages/MyProfile/Profile/Profile";
import CloseIcon from "src/Projects/Life/icons/CloseIcon";



const ProfileSideBar = ({ show, handleClose }) => {
  useEffect(() => {}, []);
  return (
    <Offcanvas show={show} onHide={handleClose} className="sidebar-main" scroll placement="end" >
      <Offcanvas.Header>
         <Offcanvas.Title>
          <Navbar.Brand
            as={Link}
            to="/ubizz"
            className=" text-white me-1 me-md-5"
          >
             <CorporateIcon fill="var(--UbizzIconColor)" />
            <span className="ms-2">Corporate</span>
          </Navbar.Brand>
        </Offcanvas.Title>
        <button className="eco-btn" onClick={handleClose}>
          <CloseIcon color="#fff" />
        </button>
      </Offcanvas.Header>
      <hr className="mb-0" />
      <Offcanvas.Body className="sidebar-body">
       <Profile handleClose={handleClose}/> 
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ProfileSideBar;

