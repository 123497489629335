const handlePrint = (e, printContent, printContainer) => {
    e.preventDefault();
    const content = printContent.current;
    const printBox = printContainer.current.contentWindow;
    printBox.document.open();
    printBox.document.write(content.innerHTML);
    printBox.document.close();
    printBox.focus();
    printBox.print();
    printBox.document.close();
    printContainer.current.style.display = "none";
};
  
export default handlePrint