/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "src/Projects/Life/utils/cropImage";
import { getCroppedImg as getCroppedImgUrl } from "src/utilities/imageCropUtils";
import styled from "styled-components";

const CropPhotoModal = ({
  imageFile,
  type,
  setCroppedImage,
  setCroppedImageUrl,
  handleClose,
  show,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImageFile = await getCroppedImg(
        imageFile,
        croppedAreaPixels
      );
      const croppedImageUrl = await getCroppedImgUrl(
        imageFile,
        croppedAreaPixels
      );

      setCroppedImage(croppedImageFile);
      setCroppedImageUrl(croppedImageUrl);
      handleClose();
    } catch (e) {
      console.error({ e }, "error 1");
    }
  }, [croppedAreaPixels, imageFile]);

  return (
    <Modal className="testing" centered show={show} onHide={handleClose}>
      <ModalWrapper>
        <Modal.Header>
          <button onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className="modal-title">Crop Image</div>
          <button className="save" onClick={showCroppedImage}>
            Apply
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="cropper-container">
            {/* Testing the Cropper */}
            <Cropper
              image={imageFile}
              crop={crop}
              zoom={zoom}
              aspect={type === "profile" ? 1 / 1 : 3 / 2}
              cropShape={type === "profile" ? "round" : "rect"}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <RangeInput>
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e) => setZoom(e.target.value)}
              name="zoom"
              id=""
            />
          </RangeInput>
        </Modal.Body>
      </ModalWrapper>
    </Modal>
  );
};

export default CropPhotoModal;

const RangeInput = styled.div`
  bottom: 20px;
  width: 50%;
  margin-inline: auto;

  height: 40px;
  display: flex;
  align-items: center;

  & > input {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px;
    background: #3f51b5;
    width: 100%;

    &::-moz-range-thumb {
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid #3f51b5;
      background: #3f51b5;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid #3f51b5;
      background: #3f51b5;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  &:hover > input::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
  }

  .controls:hover > input::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  }
`;

const ModalWrapper = styled.div`
  .cropper-container {
    height: 200px;
    position: relative;
  }

  & .modal-header {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    gap: 0.5rem;
    position: sticky;

    & > button {
      background-color: transparent;
      border: 0;
    }

    & > .modal-title {
      margin: 0;
      font-size: 1.1rem;
    }
  }

  & .modal-body {
    padding: 0 0 1rem;

    /* height: 100rem; */

    & .upload-container {
      position: relative;
    }
    & .profile-upload-container {
      display: inline-block;
      position: relative;
      /* transform: translateY(-50%); */
      margin-top: -5rem;
    }
    & .upload-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background-color: #d3d3d392;
      padding: 0.7rem;
      font-size: 1.1rem;
      border-radius: 50%;
      cursor: pointer;
    }

    & .cover-image {
      max-width: 100%;
      width: 100%;
      max-height: 10rem;
      object-fit: cover;
    }

    & .profile-image {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      margin: 1rem;
      box-sizing: content-box;
      border: 1px solid #333;
    }
  }
`;
