import React, { useEffect, useState } from "react";
import "./storeView.css";
import { Container, Row } from "react-bootstrap";

import ProductSearchBox from "src/Projects/Corporate/Components/Common/ProductSearchBox";
import {
  shopDetailsUrl,
  ShopWiseProductUrl,
} from "src/Projects/Corporate/utilities/CommonUrl";
import { useLocation } from "react-router-dom";
import { getInfoWithoutResponseStatus } from "src/Common/apiCall/GetApiCalls";
import paginationApiCall from "src/Common/apiCall/paginationApiCall";
import ScrollToTop from "src/Common/ScrollToTop";
import { ProductInfiniteScroll } from "../../Product/ProductInfiniteScroll";
import { isEmpty } from "lodash";
import Banner from "src/Projects/Corporate/Components/Common/Banner/Banner";
import ProductSkeleton from "src/Projects/Corporate/Components/Placeholder/ProductSkeleton";

const StoreView = () => {
  const { pathname } = useLocation();
  const lastSegment = pathname.split("/").pop();
  const [isLoading, setIsLoading] = useState(true);
  const [info, setinfo] = useState();
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    getInfoWithoutResponseStatus(
      setIsLoading,
      `${shopDetailsUrl}/${lastSegment}`,
      setinfo
    );
  }, []);

  useEffect(() => {
    const productArgument = {
      setIsLoading: setIsLoading,
      postList: product,
      setPostList: setProduct,
      currentPage: currentPage,
      setHasMore: setHasMore,
      Url: `${ShopWiseProductUrl}/${lastSegment}`,
    };
    paginationApiCall(productArgument, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  // search work -----------------
  const [mainProductShow, setmainProductShow] = useState(true);

  const [formData, setformData] = useState({
    product_name: "",
    slug: lastSegment,
  });
  const [searchProduct, setsearchProduct] = useState([]);
  const [serachCurrentPage, setserachCurrentPage] = useState(1);
  const [searchMore, setsearchMore] = useState(false);

  const ProductSearchBoxArg = {
    slug: lastSegment,
    setIsLoading,
    product: searchProduct,
    setProduct: setsearchProduct,
    setsearchMore: setsearchMore,
    serachCurrentPage,
    formData,
    setformData,
  };
  useEffect(() => {
    if (!isEmpty(formData.product_name)) {
      setmainProductShow(false);
    } else {
      setmainProductShow(true);
      setsearchProduct([]);
    }
  }, [formData]);
  // console.log("state",state.data)
  return (
    <Container fluid className="py-4">
      {isLoading ? (
        <div
          className="store__banner-main d-flex align-items-end justify-content-end"
          style={{ backgroundImage: `url("/corporate/banner1.jpg")` }}
        ></div>
      ) : (
        <>{!isEmpty(info) && <Banner data={info} />}</>
      )}

      <br />
      <ProductSearchBox ProductSearchBoxArg={ProductSearchBoxArg} />
      <br />
      <br />

      <ScrollToTop />
      {mainProductShow ? (
        <ProductInfiniteScroll
          product={product}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          hasMore={hasMore}
          zoroLengthShow={true}
        />
      ) : (
        <ProductInfiniteScroll
          product={searchProduct}
          setCurrentPage={setserachCurrentPage}
          currentPage={serachCurrentPage}
          hasMore={searchMore}
        />
      )}

      <Row className="home__Product-box g-2">
        {isLoading
          ? [...new Array(12)].map((_, index) => (
              <ProductSkeleton key={index} />
            ))
          : null}
      </Row>
    </Container>
  );
};

export default StoreView;
