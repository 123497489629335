import axios from "axios";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { destroyProfilePhoto } from "src/features/life/lifeCommonDataSlice";
import { formateNewsFeedData } from "src/features/life/newsFeedSlice";
import Swal from "sweetalert2";
import {
  getUserInfoUrl,
  loginUrl,
  logoutUrl,
} from "../Projects/Life/Common/CommonUrl";

const useAuthentication = () => {
  const ToastFunc = (icon, title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: icon,
      title: title,
    });
  };
  const [loginStatus, setLoginStatus] = useState();
  const userLocalData = localStorage.getItem("user_info");
  const dispatch = useDispatch();
  const [authUser, setUser] = useState(() => {
    return userLocalData ? JSON.parse(userLocalData) : {};
  });
  const [isLoading, setIsLoading] = useState(false);
  const [authError, setAuthError] = useState("");
  const [addressModify, setaddressModify] = useState("");
  useEffect(() => {
    if (!isEmpty(authUser)) {
      const parseData = JSON.parse(authUser.address);
      if (parseData) {
        if (parseData.current_city || parseData.hometown) {
          setaddressModify(`${parseData.current_city},${parseData.hometown}`);
        }
      }
    }
  }, [authUser]);
  const registerNewUser = (data, navigate) => {
    setIsLoading(true);

    axios
      .post("https://app.fooddoose.com/frontend_api/register_customer", data)
      .then((res) => {
        if (res.status === 200) {
          ToastFunc("success", "Your Registration Successful");
          navigate("/login_email");
        }
      })
      .catch((err) => {
        console.log(err.message);
        ToastFunc("error", "Registration Failed !");
      })
      .finally(() => setIsLoading(false));
  };
  // user login
  const userLogin = (data, setformSubmit) => {
    setIsLoading(true);
    axios
      .post(loginUrl, data)
      .then((res) => {
        if (res.status === 200) {
          const token = res.data?.access_token;
          localStorage.setItem("access_token", token);
          getUserInfo();
          setformSubmit(false);
          setIsLoading(false);
        } else {
          setformSubmit(false);
          ToastFunc("error", "Invalid Email and Password !");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setformSubmit(false);

        ToastFunc("error", "Something Went Wrong ! Please try again ");
        setIsLoading(false);
      });
  };
  const formateUserLocalData = () => {
    setUser({});
    localStorage.setItem("access_token", "");
    localStorage.setItem("user_info", "");
    localStorage.setItem("cartdata", "");
  };
  // get user information

  const getUserInfo = () => {
    setIsLoading(true);
    const token = localStorage.getItem("access_token");
    if (token) {
      axios
        .post(getUserInfoUrl, { token: token })
        .then((res) => {
          localStorage.setItem("user_info", JSON.stringify(res.data));
          const userLocalData = localStorage.getItem("user_info");
          const parseData = JSON.parse(userLocalData);
          if (parseData && parseData.id) {
            setUser(parseData);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setAuthError(err.message);
          setIsLoading(false);
          formateUserLocalData();
        });
    } else {
      formateUserLocalData();
      setIsLoading(false);
    }
  };

  // refresh token
  useEffect(() => {
    getUserInfo();
  }, []);

  const refreshToken = () => {
    const token = localStorage.getItem("idToken");
    if (token) {
      axios
        .post("https://app.fooddoose.com/frontend_api/auth/refresh", {
          token: token,
        })
        .then((res) => {
          const newToken = res.data?.access_token;
          if (newToken) {
            localStorage.setItem("idToken", newToken);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  // logout // zz
  const logout = (navigate) => {
    const token = localStorage.getItem("access_token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(logoutUrl, {}, { headers: headers })
      .then((res) => {
        navigate("/login");

        setUser({});
        dispatch(formateNewsFeedData());
        dispatch(destroyProfilePhoto());
        setLoginStatus(false);
        localStorage.setItem("access_token", "");
        localStorage.setItem("user_info", "");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // user profile update
  const userProfileUpdate = (data) => {
    setIsLoading(true);

    const id = data.id;
    data.customer_id = authUser.id;
    data.token = localStorage.getItem("idToken");
    delete data.id;

    axios
      .post(
        "https://app.fooddoose.com/frontend_api/customer_profile_update",
        data
      )
      .then((res) => {
        data.id = id;
        setUser(data);
        ToastFunc("success", "Successfully Profile Updated ");
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => setIsLoading(false));
  };

  // user password update
  const userPasswordUpdate = (data, setPassError) => {
    setIsLoading(true);

    data.customer_id = authUser.id;
    data.token = localStorage.getItem("idToken");
    axios
      .post(
        "https://app.fooddoose.com/frontend_api/change_customer_password",
        data
      )
      .then((res) => {
        if (res.data?.includes("The current password is not matched!")) {
          setPassError(res.data);
          ToastFunc("error", "current password is not matched");

          return;
        }
        if (res.data?.includes("password successfully changed")) {
          setPassError("");

          ToastFunc("success", "Successfully Password Updated ");

          return;
        } else {
          ToastFunc("error", "Auth Error !");
        }
      })
      .catch((err) => {
        console.log(err.message);
        ToastFunc("error", "Auth Error !");
      })
      .finally(() => setIsLoading(false));
  };

  return {
    authUser,
    setUser,
    loginStatus,
    setLoginStatus,
    registerNewUser,
    isLoading,
    setIsLoading,
    userLogin,
    authError,
    setAuthError,
    refreshToken,
    getUserInfo,
    logout,
    userProfileUpdate,
    userPasswordUpdate,
    addressModify,
  };
};

export default useAuthentication;
