import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { isEmpty } from "lodash";
import { updateForm } from "./common/apiCalls";
import { updateMainProfileBasicInfoUrl } from "src/Projects/Life/Common/CommonUrl";
const UviomMainProfileBasicInfo = ({ info, getData }) => {

  const dateRef = useRef();
  const [isEditable, setIsEditable] = useState(false);
  const setIsEditableFalse = () => setIsEditable(false);
  const setIsEditableTrue = () => setIsEditable(true);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setformData] = useState({
    "first_name": "",
    "last_name": "",
    "address": "",
    "email": "",
    "phone": "",
    "gender": "",
    "birth_day": "",
    "religion": "",
    "marital_status": ""
  })
  useEffect(() => {
    if (!isEmpty(info)) {
      setformData({
        "first_name": info && info.user_info && info.user_info.first_name ? info.user_info.first_name : "",
        "last_name": info && info.user_info && info.user_info.last_name ? info.user_info.last_name : "",
        "address": info && info.uviom_profile && info.uviom_profile.address ? info.uviom_profile.address : "",
        "email": info && info.user_info && info.user_info.email ? info.user_info.email : "",
        "phone": info && info.user_info && info.user_info.phone ? info.user_info.phone : "",
        "gender": info && info.uviom_profile && info.uviom_profile.gender ? info.uviom_profile.gender : "",
        "birth_day": info && info.uviom_profile && info.uviom_profile.birth_day ? info.uviom_profile.birth_day : "",
        "religion": info && info.uviom_profile && info.uviom_profile.religion ? info.uviom_profile.religion : "",
        "marital_status": info && info.uviom_profile && info.uviom_profile.marital_status ? info.uviom_profile.marital_status : ""
      })
    }
  }, [info])

  const inputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...formData };
    newData[name] = value;
    setformData(newData);
  };
  const updateFormFunc = (e) => {
    e.preventDefault();
    const updateFormArg = {
      "formData": formData,
      "updateMainProfileInfoUrl": updateMainProfileBasicInfoUrl,
      "setIsLoading": setIsLoading,
      "dataReload": getData,
      "setIsEditableFalse": setIsEditableFalse,
    }
    updateForm(updateFormArg)
  }
  return (
    <div className="p-1 p-sm-2 p-lg-3 pt-3 mainProfileEditSection my-mt">
      <div className="d-flex justify-content-between">
        <p>Basic Info</p>
        <FontAwesomeIcon onClick={setIsEditableTrue}
          className="mainProfilePenEditBtn" icon={faPencilAlt}></FontAwesomeIcon>
      </div>

      <form className="p-1  p-lg-3" onSubmit={updateFormFunc}>
        <Row>
          <Col>
            <Form.Control
              disabled={!isEditable}
              type="text"
              name="first_name"
              value={formData.first_name}
              placeholder="first_name"
              className="mainProfileInput"
              onChange={inputHandleChange}
            />
          </Col>
        </Row>
        <br />


        <Row>
          <Col>
            <Form.Control
              disabled={!isEditable}
              type="text"
              placeholder="last_name"
              className="mainProfileInput"
              name="last_name"
              value={formData.last_name}
              onChange={inputHandleChange}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Control
              disabled={!isEditable}
              type="text"
              name="address"
              value={formData.address}
              placeholder="Address"
              className="mainProfileInput"
              onChange={inputHandleChange}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Control
              disabled={!isEditable}
              type="text"
              name="email"
              value={formData.email}
              placeholder="Email"
              className="mainProfileInput"
              onChange={inputHandleChange}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Control
              disabled={!isEditable}
              className="mainProfileInput"
              type="text"
              placeholder="Phone Number"
              value={formData.phone}
              name="phone"
              onChange={inputHandleChange}

            />
          </Col>
        </Row>
        <br />
        {/* uviom_profile */}
        <Row xs={1} lg={2} className="g-4 mainProfileSelect">
          <Col className="basicInfoSelectCol">
            <Form.Select
              disabled={!isEditable}
              aria-label="gender select"
              id="gender"
              className="mainProfileInput m-0"
              value={formData.gender}
              name="gender"
              onChange={inputHandleChange}
            >
              <option className="selectPlaceholder" >Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Others">Others</option>
            </Form.Select>
            <label htmlFor="gender" className="selectDownArrow">
              <FontAwesomeIcon
                className="ms-2 text-white"
                size="lg"
                icon={faPlusCircle}
              ></FontAwesomeIcon>
            </label>
          </Col>
          <Col className="basicInfoSelectCol">
            <Form.Control
              disabled={!isEditable}
              className="mainProfileInput"
              type="text"
              placeholder="Birthday"
              ref={dateRef}
              onFocus={() => dateRef.current.type = "date"}
              onBlur={() => dateRef.current.type = "text"}
              value={formData.birth_day}
              name="birth_day"
              onChange={inputHandleChange}
            />
          </Col>
          <Col className="basicInfoSelectCol">
            <Form.Select
              disabled={!isEditable}
              aria-label="select religion"
              className="mainProfileInput m-0"
              name="religion"
              value={formData.religion}
              onChange={inputHandleChange}

            >
              <option className="selectPlaceholder">Religion</option>
              <option value="Muslims">Muslims</option>
              <option value="Christians">Christians</option>
              <option value="Hindus">Hindus</option>
              <option value="Buddhists">Buddhists</option>
              <option value="Others">Others</option>
            </Form.Select>
          </Col>
          <Col className="basicInfoSelectCol">
            <Form.Select
              disabled={!isEditable}
              aria-label="select marital status"
              className="mainProfileInput m-0"
              value={formData.marital_status}
              name="marital_status"
              onChange={inputHandleChange}
            >
              <option className="selectPlaceholder">Marital Status</option>
              <option value="Single">Single</option>
              <option value="Engaged">Engaged</option>
              <option value="Married">Married</option>
              <option value="It's Complicated">It's Complicated</option>
              <option value="Separated">Separated</option>
              <option value="Divorced">Divorced</option>
            </Form.Select>
          </Col>
        </Row>
        {
          isEditable && <div className="py-3 mainProfileBtnDiv">
            <button onClick={setIsEditableFalse} className="mainProfileBtn btnCandel me-3">Cancel</button>
            {isLoading ? (
              <button className="mainProfileBtn btnSave disabled" disabled>save</button>
            ) : (
              <button type="submit" className="mainProfileBtn btnSave" >save</button>
            )}
          </div>
        }
      </form>
    </div>
  );
};

export default UviomMainProfileBasicInfo;
