import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Accordion } from "react-bootstrap";
import Shipping from "./Shipping";

export const ShippingAccordion = ({
  shippingBtnRef,
  shipping_billing_status,
  handleShippingAddressSubmit,
}) => {
  return (
    <Accordion
      className="shipping-address my-2"
      id="shipping-and-billing-accordion"
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header ref={shippingBtnRef}>
          <div
            className="checkout-collapse-btn"
            style={{ color: shipping_billing_status && "#f9a31a" }}
          >
            <span>Shipping Address</span>
            <FontAwesomeIcon icon={faCheckCircle} />
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <Shipping doneAccordion={handleShippingAddressSubmit} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
