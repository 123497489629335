import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
const ProductListItem = ({ status }) => {
  //   const { pathname } = useLocation();

  //   const currentPath = "/" +  pathname.split("/").slice(1,3).join("/");

  return (
    <div className="secondary-bg p-2 my-3">
      <Row className="g-3">
        <Col xl="6">
          <div className="favorite-card p-0 m-0">
            <div className="cart-item-img-box">
              <img className="img img-fluid" src="/corporate/man.png" alt="" />
            </div>
            <div
              style={{ height: "100%" }}
              className="cart-text-box align-items-start d-flex flex-column justify-content-between"
            >
              <p className="mb-3">
                <small>
                  {"Titan NN1639SM01 Metal Analog Watch for Men - Silver Titan NN1639SM01 Metal Analog Watch for Men - Silver".substring(
                    0,
                    60
                  )}
                  ...
                </small>
              </p>
              <p>
                <small>Model : #524521</small>
              </p>
              <p>
                <small>Brand : Fastrack </small>
              </p>
            </div>
          </div>
        </Col>
        <Col xl="6">
          <div className="d-flex align-items-center h-100">
            <div className="flex-grow-1">
              <p className="">
                <small>Quantity : 01</small>
              </p>
              <p className="mb-1">
                <small>Retail Price : 4000 TK</small>
              </p>
            </div>
            <div className="d-flex justify-content-end align-items-center flex-grow-1  flex-xxl-row">
              <button className="send-code-btn py-1 me-3">Remove</button>
              <Link to={`/`} className=" send-code-btn py-1 dashboard-edit-btn">
                Edit
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProductListItem;
