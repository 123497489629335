import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Container, Form, InputGroup, Row } from "react-bootstrap";
import RestaurantCard from "../../Components/RestaurantCard/RestaurantCard";
const img1 = "/food-image/restaurant1.png";
const img2 = "/food-image/restaurant2.png";

const Restaurants = () => {
  return (
    <Container fluid style={{ minHeight: "65vh" }} className="mb-4">
      <div className="d-flex align-items-center justify-content-center my-4">
        <InputGroup className="food-search-box py-1 ">
          <Form.Control placeholder="Search Restaurant" />
          <InputGroup.Text className="d-xs-hide d-md-inline">
            <FontAwesomeIcon icon={faSearch} className="text-gray" />
          </InputGroup.Text>
        </InputGroup>
        <button className="food-btn text-dark ms-2 banner-find-btn">
          <span className="d-none d-md-block">
            Search&nbsp;Restaurant&nbsp;
          </span>

          <FontAwesomeIcon  className="d-md-none" icon={faSearch} />
        </button>
      </div>

      <div className="d-flex justify-content-between align-items-center primary-text mb-3">
        <h6>Popular Near You</h6>
        <button className="btn-0 primary-text fw-500">See More</button>
      </div>

      <Row className="home__Product-box g-2">
        <RestaurantCard img={img1} />
        <RestaurantCard img={img2} />
        <RestaurantCard img={img1} />
        <RestaurantCard img={img2} />
        <RestaurantCard img={img1} />
        <RestaurantCard img={img2} />
      </Row>
    </Container>
  );
};

export default Restaurants;
