import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./my_store.css";
import Avatar from "src/Projects/Life/Common/Avatar";

const MyStoreNavigation = ({ boxShadow = false, handleClose = () => {} }) => {
  const { pathname } = useLocation();
  const currentPath = "/" + pathname.split("/")[1];
  return (
    <div className={` ${boxShadow && "box-shadow"} myStore-navigation top-0`}>
      <div className="myStore-navigation-banner-box">
        <img
          className="img img-fluid"
          src="/corporate/store-banner.png"
          alt=""
        />
        <div className="profile-img-box d-flex justify-content-center ">
          <Avatar imgUrl={"/corporate/store-logo.png"} size="70"></Avatar>
        </div>
      </div>
      <div className="p-3">
        <NavLink
          onClick={handleClose}
          to={`${currentPath}/my-store/dashboard`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
        >
          Dashboard
        </NavLink>
        <NavLink
          onClick={handleClose}
          to={`${currentPath}/my-store/add-product`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
        >
          Add Products
        </NavLink>
        <NavLink
          onClick={handleClose}
          to={`${currentPath}/my-store/product-list`}
          className={({ isActive }) =>
            `profile-nav-item ${isActive ? "profile-nav-item-active" : ""}`
          }
        >
          Product List
        </NavLink>
        {/* <NavLink
           onClick={handleClose}
          to={`${currentPath}/my-store/sale`}
          className={({isActive})=>`profile-nav-item ${isActive ? "profile-nav-item-active":"" }`}
          
        >
          Sale
        </NavLink> */}
        {/* <NavLink
           onClick={handleClose}
          to={`${currentPath}/my-store/message`}
         className={({isActive})=>`profile-nav-item ${isActive ? "profile-nav-item-active":"" }`}
         
        >
          Message
          
        </NavLink> */}
        {/* <NavLink
           onClick={handleClose}
          to={`${currentPath}/my-store/order`}
          className={({isActive})=>`profile-nav-item ${isActive ? "profile-nav-item-active":"" }`}
          
        >
          Orders
        </NavLink> */}
        {/* <NavLink
           onClick={handleClose}
          to={`${currentPath}/my-store/analytics`}
          className={({isActive})=>`profile-nav-item ${isActive ? "profile-nav-item-active":"" }`}
          
        >
          Analytics
        </NavLink> */}
        {/* <NavLink
           onClick={handleClose}
          to={`${currentPath}/my-store/shop-setting`}
          className={({isActive})=>`profile-nav-item ${isActive ? "profile-nav-item-active":"" }`}
          
        >
          Shop Settings
        </NavLink> */}
      </div>
    </div>
  );
};

export default MyStoreNavigation;
