
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./InputWithLabel.css";

const InputWithLabel = ({
  value = "" ,
  name,
  label,
  handler = () => {},
  className = ["", ""],
  placeholder,
  checkoutInput = false,
  disabled = false,
  type = "text",
  required = true
  
}) => {

  return (
    <Form.Group
      as={Row}
      className={`mb-3 ${checkoutInput && "mx-md-1"}`}
      controlId=""
    >
      <Form.Label
        className={`d-none d-md-block InputWithLabelLabel ${className[1]} ${checkoutInput && "text-start"}`}
        column
        sm="3"
      >
        {label}
      </Form.Label>
      <Col md="9">
        <Form.Label className={"d-block d-md-none "}>{label}</Form.Label>
        <Form.Control
          disabled={disabled}
          required={required}
          name={name}
          value={value}
          onChange={handler}
          className={`${className[0]} InputWithLabelInput ${checkoutInput && "checkout-input"}`}
          type={type}
          placeholder={placeholder || "Write Here..."}
        />
      </Col>
    </Form.Group>
  );
};

export default  InputWithLabel;


