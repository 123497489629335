import React from "react";
import { Col, Dropdown } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { SingleLinkBar } from "../../../../NestedRouteOnDashBodr/Common/SingleLinkBar";

export const MiddleLeftCol = () => {
  let { pathname } = useLocation();
  const [, dynamicPath, dynamicLife] = pathname.split("/");
  const { user_id } = useParams();

  // note::: [routeNames,title]
  const routeNames = [
    ["works", "Works"],
    ["education", "Education"],
    ["events", "Events"],
    ["sports", "Sports"],
    ["film", "Film"],
    ["music", "Music"],
    ["tv-program", "Tv Program"],
    ["books", "Books"],
  ];

  return (
    <>
      <AboutTab>
        <Link to={`/${dynamicPath}/${dynamicLife}/${user_id}/about`}>
          <div className="LookMeTypeButton justify-content-start mt-3">
            <h6 className="ps-4 mb-0">Overview</h6>
          </div>
        </Link>
        {routeNames.map((routeName, index) => (
          <SingleLinkBar
            key={`i+${index}`}
            link={`/${dynamicPath}/${dynamicLife}/${user_id}/about/${routeName[0]}`}
            placeholder={routeName[1]}
          />
        ))}
        {/* //--------- */}
      </AboutTab>
      <div className={`col-12 d-block d-lg-none`}>
        <Dropdown>
          <Dropdown.Toggle aria-label="overview" className="aboutDropdown">
            Overview
          </Dropdown.Toggle>
          <Dropdown.Menu className=" aboutDropdownBody">
            <Dropdown.Item
              className="jobDropdownItem"
              as={Link}
              to={`/${dynamicPath}/${dynamicLife}/${user_id}/about`}
            >
              Overview
            </Dropdown.Item>
            {routeNames.map((routeName, index) => (
              <Dropdown.Item
                className="jobDropdownItem"
                as={Link}
                to={`/${dynamicPath}/${dynamicLife}/${user_id}/about/${routeName[0]}`}
                key={index}
              >
                {routeName[1]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

const AboutTab = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 1rem;

  padding-block: 0.5rem;
  padding-inline: 0.2rem;

  @media (max-width: 62em) {
    display: none;
  }

  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  & .LookMeTypeButton {
    margin: 0 !important;

    & .ps-4 {
      padding-inline: 0.7rem !important;
    }
  }
`;
