import { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import useAuth from "src/hooks/useAuth";
import { useToggleChannelFollowMutation } from "src/app/services/viewApi/viewPostApi";
import { channelLogoImg } from "../../common/view_commonUrl";
import UploadVideoModal from "../../Components/UploadVideoModal/UploadVideoModal";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import { Dropdown } from "react-bootstrap";
import BelledIcon from "../../Icons/BelledIcon";
import BellIcon from "../../Icons/BellIcon";
import { api } from "src/app/services/api";
import { useDispatch } from "react-redux";

function UserMeta({
  handleEditChannel,
  channelName,
  channelProfile,
  followers,
  isFollowing,
  userId,
}) {
  const [showVideoUploadModal, setShowVideoUploadModal] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const dispatch = useDispatch();

  const { authUser } = useAuth();
  const [toggleChannelFollow] = useToggleChannelFollowMutation();

  return (
    <Wrapper>
      <UploadVideoModal
        show={showVideoUploadModal}
        handleClose={() => setShowVideoUploadModal(false)}
      />
      <img
        className="avatar"
        src={
          channelProfile
            ? channelLogoImg + channelProfile
            : "https://i.ibb.co/MprdZS6/user.png"
        }
        alt=""
      />

      <div>
        <h4 className="channelName">{channelName}</h4>
        <div>
          {parseInt(followers) >= 1 ? (
            <div>{parseInt(followers)} Followers</div>
          ) : (
            <div>No Follower</div>
          )}
        </div>
      </div>

      <div className="navigation">
        {authUser.id === parseInt(userId) ? (
          <>
            <button onClick={() => setShowVideoUploadModal(true)}>
              <FontAwesomeIcon icon={faCloudUploadAlt} /> Upload Video
            </button>
            <button onClick={handleEditChannel} className="active">
              Edit Channel
            </button>
          </>
        ) : (
          <div className="d-flex align-items-center gap-2">
            <button
              onClick={() => {
                toggleChannelFollow(userId);
                dispatch(api.util.invalidateTags(["meFollowing"]));
              }}
              className={`${parseInt(isFollowing) ? "active" : ""} `}
            >
              {parseInt(isFollowing) ? <>Following</> : "Follow"}
            </button>
            {parseInt(isFollowing) ? (
              <div role="button" onClick={() => setIsSubscribed(!isSubscribed)}>
                {!isSubscribed ? (
                  // <FontAwesomeIcon icon={faBell} />
                  <BellIcon />
                ) : (
                  <BelledIcon />
                )}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </Wrapper>
  );
}
export default UserMeta;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  margin-block: 1rem;
  color: var(--text-primary);

  /* 768px */
  @media (max-width: 48em) {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 0.7rem;
  }

  & .avatar {
    --size: 6rem;
    width: var(--size);
    height: var(--size);
    object-fit: cover;
    border-radius: 50%;
    /* margin-top: -2.5rem; */
  }

  & .channelName {
    font-size: clamp(1rem, 0.8897rem + 0.5517vw, 1.2rem);
    margin-bottom: 0.2rem;
    white-space: nowrap;
  }
  & button {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
  & button:not(.dropdown-toggle) {
    padding: 0.3rem 1rem;
    margin: 0.3rem auto;
    border-radius: 3rem;
    font-weight: 500;
    background-color: var(--bg-success);
    color: var(--dark-to-white);
    border: 1px solid transparent;
    transition: 0.3s ease-in-out;

    &:hover {
      border-color: var(--border-success);
      background-color: transparent;
      color: var(--dark-to-white);
    }

    &.active {
      color: var(--dark-to-white);
      background-color: transparent;
      border-color: var(--border-success);
    }
  }

  & .navigation {
    margin-left: auto;
    margin-right: 10%;
    display: flex;
    gap: 0.5rem;

    /* 768px */
    @media (max-width: 48em) {
      margin-right: auto;
      text-align: center;
    }
    /* 350px */
    @media (max-width: 21.875em) {
      gap: 0;
      flex-direction: column;
    }
  }
`;
