import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css/pagination";
import { useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
const images = ["/food-image/food1.png", "/food-image/food2.png", "/food-image/food2.png"];
const Additional_items = [
  { name: "Jali Kabab", price: "50", id: "1" },
  { name: "Chicken Roast", price: "80", id: "2" },
  { name: "Firni", price: "40", id: "3" },
  { name: "Borhani", price: "30", id: "4" },
  { name: "Coke", price: "60", id: "5" },
];
const FoodAddCardModal = ({ show, handleClose,data }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [quantity, setQuantity] = useState(1);

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const handleAdditionalItemClick = (id) => {
    if (selectedItems.indexOf(id) !== -1) {
      const remainingElem = selectedItems.filter((item) => item !== id);
      setSelectedItems([...remainingElem]);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const {product_name, price} = data

  return (
    <Modal
      show={show}
      fullscreen="md-down"
      className="food__card__add-modal"
      onHide={handleClose}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div>
          <Swiper
            modules={[Autoplay, Pagination]}
            speed={1000}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            effect={"fade"}
            className="mySwiper h-100"
            pagination={true}
          >
            {images.map((img, index) => (
              <SwiperSlide key={index}>
                <div className="food__card__modal__slider-box">
                  <img
                    src={img}
                    className="img img-fluid"
                    alt="food details img"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
         
        </div>
        <div className="p-2 p-md-4 my-4">
          <div className="d-flex justify-content-between align-items-center">
            <h4>{product_name}</h4>
            <h5 >{price} Tk</h5>
          </div>
          <p>
            <small>
              A delightful preparation of slow cooked aromatic basmati rice
              layered with potatoes, marinated mutton pieces, in a delicate
              blend of whole spices
            </small>
          </p>
          <h6 className="fw-500 mb-3">Zam Zam Resturant, (Mirpur 11) </h6>
          <h5>Biryani - Add On’s</h5>
          <Row className="my-3 g-3">
            {Additional_items.map((item) => (
              <Col key={item.id}>
                <div
                  onClick={() => handleAdditionalItemClick(item.id)}
                  style={{ zIndex: "15" }}
                  className={`food__additional-item ${
                    selectedItems.indexOf(item.id) !== -1 ? "checked" : ""
                  }`}
                >
                  <p className="text-ellipsis">{item.name}</p>
                  <p className="mt-3"> ৳ {item.price}</p>
                </div>
              </Col>
            ))}
          </Row>
          <h6>Special instructions</h6>
          <FloatingLabel
            controlId="floatingTextarea2"
            label="Any specific preferences? Let the restaurant know."
          >
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
            />
          </FloatingLabel>
          <div className="d-flex align-items-center justify-content-center my-3">
            <div className="d-flex food__addModal__plus-minus">
              <button onClick={decreaseQuantity} >
                <FontAwesomeIcon icon={faMinus} />
              </button>{" "}
              <span>{quantity}</span>{" "}
              <button onClick={increaseQuantity} >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            <button className="food-btn ms-2 text-dark">Add to Cart</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FoodAddCardModal;
