import React from "react";
import BestTimeProfileBanner from "./BestTimeProfileBanner/BestTimeProfileBanner";
import FamilyBestTime from "./Common/FamilyBestTime";
import FriendBestTime from "./Common/FriendBestTime";
import ProfessionalBestTime from "./Common/ProfessionalBestTime";
import {
  getPersonalFamilyStory,
  getPersonalFriendStory,
  getPersonalProfessionalStory,
  personalProfilePic,
} from "src/Projects/Life/Common/CommonUrl";
import useAuth from "src/hooks/useAuth";

const PostBestTime = () => {
  const { authUser } = useAuth();

  const { first_name, last_name, username, photo } = authUser;
  const userData = {
    name: first_name + " " + last_name,
    username,
    photo: `${personalProfilePic}/${photo}`,
  };

  return (
    <div>
      {/* <BestTimeProfileBanner userData={userData} />
      <br />
       */}
      <FamilyBestTime postUrl={getPersonalFamilyStory} />
      <FriendBestTime postUrl={getPersonalFriendStory} />
      <ProfessionalBestTime postUrl={getPersonalProfessionalStory} />
    </div>
  );
};

export default PostBestTime;
