import React from "react";

const AddPhotoIcon = ({ size = "40", className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 42 38"
      fill="none"
    >
      <path
        d="M30.6548 21.5509H30.3008C30.4185 21.539 30.5371 21.539 30.6548 21.5509Z"
        fill="#F2F2F2"
        className={className}
      />
      <path
        d="M41.1647 9.50989C41.1717 8.05089 40.7327 7.43788 39.1827 7.44488C29.0073 7.49222 18.8317 7.49421 8.65566 7.45088C7.21166 7.44588 6.73266 7.99488 6.73266 9.39488C6.76733 18.0095 6.76533 26.6242 6.72666 35.2389C6.71766 36.8459 7.33166 37.3449 8.88566 37.3249C13.9157 37.2569 18.9467 37.2989 23.9757 37.3009C29.0047 37.3029 34.0367 37.2579 39.0647 37.3279C40.6737 37.3499 41.1707 36.7359 41.1647 35.1799C41.1173 26.6266 41.1173 18.0699 41.1647 9.50989ZM39.2417 34.9099H8.62266V9.36587H39.2417V34.9099Z"
        fill="#F2F2F2"
        className={className}
      />
      <path
        d="M34.6984 6.09297C32.9894 6.74997 32.7984 5.76797 32.6214 4.72997C32.4704 3.82997 32.2844 2.93597 32.0724 1.83597L1.97239 7.53597C2.99439 16.636 6.34339 25.293 5.22839 34.561C5.10339 34.128 4.94038 33.702 4.85738 33.261C3.27838 24.92 1.73739 16.57 0.0953872 8.23997C-0.235613 6.56297 0.278386 5.98997 1.89539 5.69397C11.7794 3.8833 21.6527 2.01664 31.5154 0.0939685C32.9804 -0.192031 33.7074 0.132972 33.9094 1.65097C34.0984 3.13297 34.4214 4.59497 34.6984 6.09297Z"
        fill="#F2F2F2"
        className={className}
      />
      <path
        d="M38.255 33.7988H9.617C9.617 30.9748 9.586 28.2698 9.649 25.5668C9.71548 25.0712 9.96524 24.6184 10.349 24.2978C12.723 22.2778 15.165 20.3358 17.535 18.3108C18.535 17.4608 19.224 17.6648 19.99 18.6018C20.697 19.4658 21.413 20.3228 22.141 21.1838H17.976C17.5753 21.1838 17.1909 21.343 16.9076 21.6264C16.6242 21.9097 16.465 22.2941 16.465 22.6948C16.465 23.0956 16.6242 23.4799 16.9076 23.7633C17.1909 24.0466 17.5753 24.2058 17.976 24.2058H23.836V30.0658C23.836 30.4666 23.9952 30.8509 24.2786 31.1343C24.5619 31.4176 24.9463 31.5768 25.347 31.5768C25.7478 31.5768 26.1321 31.4176 26.4154 31.1343C26.6988 30.8509 26.858 30.4666 26.858 30.0658V24.2058H32.718C33.0377 24.2042 33.3487 24.1014 33.6064 23.9123C33.8642 23.7231 34.0555 23.4573 34.153 23.1528C35.174 23.6528 36.196 24.1528 37.186 24.7098C37.4552 24.8753 37.6859 25.0964 37.8625 25.3584C38.0391 25.6204 38.1576 25.9172 38.21 26.2288C38.317 28.7038 38.255 31.1868 38.255 33.7988Z"
        fill="#F2F2F2"
        className={className}
      />
      <path
        d="M34.0554 13.651C34.0659 13.9825 34.0085 14.3127 33.8867 14.6213C33.7649 14.9298 33.5812 15.2102 33.3471 15.4452C33.1129 15.6802 32.8332 15.8648 32.5251 15.9877C32.217 16.1107 31.887 16.1693 31.5554 16.16C31.2301 16.1593 30.9083 16.094 30.6084 15.9679C30.3086 15.8419 30.0368 15.6575 29.8088 15.4255C29.5809 15.1935 29.4012 14.9186 29.2804 14.6166C29.1595 14.3146 29.0998 13.9917 29.1048 13.6665C29.1097 13.3413 29.1793 13.0203 29.3093 12.7221C29.4393 12.424 29.6273 12.1547 29.8622 11.9298C30.0972 11.7049 30.3745 11.5289 30.678 11.412C30.9816 11.2951 31.3053 11.2397 31.6304 11.249C31.9474 11.2471 32.2617 11.3078 32.5553 11.4276C32.8488 11.5474 33.1159 11.7239 33.3411 11.947C33.5664 12.1701 33.7454 12.4355 33.868 12.7278C33.9905 13.0202 34.0542 13.3339 34.0554 13.651Z"
        fill="#F2F2F2"
        className={className}
      />
      <path
        d="M30.6548 21.5509H30.3008C30.4185 21.539 30.5371 21.539 30.6548 21.5509Z"
        fill="#F2F2F2"
        className={className}
      />
      <path
        d="M30.6691 21.1848H30.2891C30.3522 21.1786 30.4156 21.1755 30.4791 21.1758C30.5411 21.1758 30.6051 21.1758 30.6691 21.1848Z"
        fill="#F2F2F2"
        className={className}
      />
      <path
        d="M30.6548 21.5509H30.3008C30.4185 21.539 30.5371 21.539 30.6548 21.5509Z"
        fill="#F2F2F2"
        className={className}
      />
      <path
        d="M30.3027 21.5508H30.6557C30.5384 21.539 30.4201 21.539 30.3027 21.5508Z"
        fill="#F2F2F2"
        className={className}
      />
      <path
        d="M33.8598 22.6959C33.8602 22.7971 33.8475 22.8979 33.8218 22.9958C33.7565 23.2393 33.6127 23.4544 33.4128 23.6079C33.2128 23.7614 32.9679 23.8447 32.7158 23.8448H26.4878V30.0719C26.479 30.3695 26.3546 30.6521 26.1409 30.8595C25.9272 31.067 25.6411 31.183 25.3433 31.183C25.0455 31.183 24.7594 31.067 24.5457 30.8595C24.332 30.6521 24.2076 30.3695 24.1988 30.0719V23.8399H17.9718C17.8187 23.8444 17.6662 23.8181 17.5233 23.7626C17.3805 23.7072 17.2503 23.6236 17.1404 23.5169C17.0305 23.4101 16.9431 23.2824 16.8834 23.1413C16.8237 23.0002 16.793 22.8486 16.793 22.6954C16.793 22.5421 16.8237 22.3905 16.8834 22.2494C16.9431 22.1083 17.0305 21.9806 17.1404 21.8738C17.2503 21.7671 17.3805 21.6835 17.5233 21.6281C17.6662 21.5726 17.8187 21.5463 17.9718 21.5509H24.1988V15.3239C24.1943 15.1707 24.2205 15.0182 24.276 14.8754C24.3315 14.7326 24.4151 14.6024 24.5218 14.4924C24.6285 14.3825 24.7562 14.2951 24.8973 14.2354C25.0384 14.1758 25.1901 14.145 25.3433 14.145C25.4965 14.145 25.6482 14.1758 25.7893 14.2354C25.9304 14.2951 26.0581 14.3825 26.1648 14.4924C26.2715 14.6024 26.3551 14.7326 26.4106 14.8754C26.4661 15.0182 26.4923 15.1707 26.4878 15.3239V21.5509H32.7148C32.8652 21.5509 33.0141 21.5805 33.153 21.638C33.2919 21.6956 33.4181 21.7799 33.5244 21.8862C33.6308 21.9925 33.7151 22.1188 33.7726 22.2577C33.8302 22.3966 33.8598 22.5455 33.8598 22.6959Z"
        fill="#6984A5"
      />
    </svg>
  );
};

export default AddPhotoIcon;
