import axios from "axios";
import ToastFunc from "src/Common/ToastFunc";
import { fmChangeRequestedStatusUrl, fnChangeRequestedStatusUrl, pChangeRequestedStatusUrl } from "src/Projects/Life/Common/CommonUrl";

import Swal from "sweetalert2";

export const userStatusChange = (userStatusChangeArg) => {
  const { requestStatus, user_id,location, dataReload } = userStatusChangeArg;
  
  let tempUrl;
  let userIdField;
  
  if (location.pathname.includes("/family-life")) {
      tempUrl = fmChangeRequestedStatusUrl;
      userIdField="family_id";
  } else if (location.pathname.includes("/friends-life")) {
      tempUrl = fnChangeRequestedStatusUrl;
      userIdField="friend_id";

  } else if (location.pathname.includes("/professional-life")) {
      tempUrl = pChangeRequestedStatusUrl;
      userIdField="professional_id";

  }
  const formData = {
    [userIdField]: user_id,
    "status": requestStatus,
  };
  axios
    .post(`${tempUrl}`, formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status === 1) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          Toast.fire({
            icon: "success",
            title: res.data.message
          }).then(
            dataReload()
          );

        } else { }
      }
    })
    .catch((err) => {
      ToastFunc('error','Request Failed')

    });
};
