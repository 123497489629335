import { Container } from "react-bootstrap";
import ReceivedDelivery from "./../components/ReceivedDelivery";

import stock1 from "./../assets/stock1.jpg";

function DeliveryReceived() {
  return (
    <div id="wrapper">
      <div
        className="heroArea__bg lg-vh-90"
        style={{ backgroundImage: `url(${stock1})` }}
      >
        <div className="py-5"></div>
        <Container>
          <div className="w-lg-75 mx-auto">
            <div>
              <h3 className="receipt__header">Received Product</h3>

              <ReceivedDelivery />
            </div>
          </div>
        </Container>
        <div className="pb-5" />
      </div>
    </div>
  );
}

export default DeliveryReceived;
