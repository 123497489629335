import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import useFoodStore from "../../hooks/useFoodStore";
import FavoriteFoodCard from "./FavoriteFoodCard";
import FavoriteRestaurantCard from "./FavoriteRestaurantCard";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const img1 = "/food-image/restaurant1.png";
const img2 = "/food-image/restaurant2.png";

const MyFavorite = () => {
  const [key, setKey] = useState("food");
  const { products } = useFoodStore();
  return (
    <Container fluid style={{ minHeight: "65vh" }} className="primary-text">
      <div>
        <h6 className=" fw-bold my-3">My Favorite</h6>

        <Tabs
          id="food-order-history"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3 mt-3 food-tab "
          transition={true}
        >
          <Tab eventKey="food" title="Food">
            <Row className="g-2 mb-4">
              {[...new Array(5)].map((_, index) => (
                <FavoriteFoodCard
                  key={products[index].id}
                  data={products[index]}
                />
              ))}
            </Row>
          </Tab>
          <Tab eventKey="restaurant" title="Restaurant">
            <Row className="home__Product-box g-2 mb-5">
              <FavoriteRestaurantCard img={img1} />
              <FavoriteRestaurantCard img={img2} />
              <FavoriteRestaurantCard img={img1} />
              <FavoriteRestaurantCard img={img2} />
            </Row>
          </Tab>
        </Tabs>
      </div>
    </Container>
  );
};

export default MyFavorite;
