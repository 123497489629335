import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import ApplicantSingleItem from "./ApplicantSingleItem";
import "./ApplicantList.css";
import { getApplicantListUrl } from "../../utilities/job_commonUrl";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import paginationApiCall from "src/Common/apiCall/paginationApiCall";
import JobSkeleton from "../../Components/Placeholder/JobSkeleton";

const ApplicantList = () => {
  const [loading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [applicantList, setApplicantList] = useState([]);
  const { postId } = useParams();

  const paginationApiArg = {
    postList: applicantList,
    setPostList: setApplicantList,
    currentPage,
    setHasMore,
    setIsLoading,
    setError,
    Url: getApplicantListUrl + postId,
  };

  useEffect(() => {
    paginationApiCall(paginationApiArg, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  return (
    <div className="secondary-bg p-2">
      <h6 className="postedJobTitle">
        Job Applicant ({applicantList.length}){" "}
      </h6>

      <InfiniteScroll
        dataLength={applicantList.length} //This is important field to render the next data
        next={() => {
          setCurrentPage(currentPage + 1);
        }}
        hasMore={hasMore}
      >
        {applicantList.length < 1 && !loading && (
          <p className="text-center mt-4"> 0 item Found </p>
        )}

        {loading && (
          <Row className="g-3" xxl="2">
            {[...new Array(6)].map((_, index) => (
              <JobSkeleton key={index} />
            ))}
          </Row>
        )}

        <Row className="g-3" xxl="2">
          {applicantList.length > 0 &&
            applicantList.map((item) => {
              if (item.applicant_profile) {
                return <ApplicantSingleItem key={item.id} data={item} />;
              } else return null;
            })}
        </Row>
      </InfiniteScroll>
      <br />
    </div>
  );
};

export default ApplicantList;
