/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import paginationApiCall from "src/Common/apiCall/paginationApiCall";
import "../bestTime.css";
import BestTimeCard from "./BestTimeCard";
import Loader from "react-js-loader";
import DottedCircleSpinner from "src/Common/DottedCircleSpinner";

const FamilyBestTime = ({ postUrl }) => {
  const [postList, setPostList] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [seeAll, setSeeAll] = useState(false);

  const paginationApiArg = {
    postList,
    setPostList,
    currentPage,
    setHasMore,
    setIsLoading,
    setError,
    Url: postUrl,
  };

  useEffect(() => {
    paginationApiCall(paginationApiArg, true);
  }, [currentPage]);

  return (
    <div className="mx-2">
      <div className="d-flex justify-content-between align-items-end">
        <h6 className="mb-0">Family Best Time</h6>
        <button
          className="bestTimeSeeAllBtn"
          onClick={() => setSeeAll((prevState) => !prevState)}
        >
          {seeAll ? "See Less" : "See All"}
        </button>
      </div>
      {loading && <DottedCircleSpinner />}

      <InfiniteScroll
        dataLength={postList.length} //This is important field to render the next data
        next={() => {
          setCurrentPage(currentPage + 1);
        }}
        hasMore={hasMore}
      >
        <Row
          xs="2"
          sm="4"
          className="bg-header-footer p-2 py-4 g-2 g-sm-3 my-3 "
        >
          {[
            ...new Array(
              seeAll
                ? postList.length
                : postList.length < 4
                ? postList.length
                : 4
            ),
          ].map((_, index) => (
            <BestTimeCard
              key={postList[index]?.id}
              data={postList[index]}
              allData={postList}
              setAllData={setPostList}
            />
          ))}
        </Row>
      </InfiniteScroll>
    </div>
  );
};

export default FamilyBestTime;
