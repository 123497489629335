import { Container } from "react-bootstrap";
import RideHistoryCard from "../components/RideHistoryCard";

import overlay from "./../assets/overlay.png";

function RidingHistory() {
  return (
    <div id="wrapper" 
    // style={{ backgroundImage: `url(${overlay})` }}
    >
      <Container>
        <h3 className="heading-3 pt-3">Riding History</h3>

        <div>
          <RideHistoryCard />
          <RideHistoryCard />
        </div>
      </Container>
    </div>
  );
}
export default RidingHistory;
