import React from "react";
import { NavLink } from "react-router-dom";

export const OtherLinks = ({ url }) => {
  return (
    <>
      <NavLink
        className={({ isActive }) =>
          `corporate__nav-subItem ${
            isActive ? "corporate__nav__subItem-active" : ""
          }`
        }
        to={`${url}/product`}
      >
        Product
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          `corporate__nav-subItem ${
            isActive ? "corporate__nav__subItem-active" : ""
          }`
        }
        to={`${url}/shop`}
      >
        Shop
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          `corporate__nav-subItem ${
            isActive ? "corporate__nav__subItem-active" : ""
          }`
        }
        to={`${url}/contact`}
      >
        Contact
      </NavLink>
    </>
  );
};
