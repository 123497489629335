import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import FoodBannerItem from "./FoodBannerItem";
import "./foodBanner.css";
import { Container, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import LocationIcon from "src/Projects/Food/assets/icons/LocationIcon";

const banners = [
  "https://images.unsplash.com/photo-1490645935967-10de6ba17061?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1153&q=80",
  "https://www.hotkhana.com/images/stories/flexicontent/item_22_field_15/m_banner2.jpg",
  "https://images.unsplash.com/photo-1505935428862-770b6f24f629?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1167&q=80",
];

const HomeBanner = () => {
  return (
    <Container fluid className="food-banner-main mb-3 mb-md-5 p-0">
      <div className=" h-100">
        <Swiper
          modules={[Autoplay]}
          speed={1000}
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          effect={"fade"}
          className="mySwiper h-100"
        >
          {banners.map((img, index) => (
            <SwiperSlide key={index}>
              <FoodBannerItem img={img} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="food-banner-overlay">
        <div className="d-flex flex-column justify-content-end align-items-start w-100 h-100 ps-4">
          <h2 className="display-5 fw-bold mb-3 mb-md-5 text-white">
            CHOOSE AND TASTE <br /> FROM YOUR PLACE
          </h2>

          <div className="d-flex align-items-center mb-5">
            <InputGroup className="food-search-box py-1 ">
              <InputGroup.Text className="d-none d-md-inline-block">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control placeholder="Pallabi, Mirpur, Dhaka 1216" />
              <InputGroup.Text>
                <LocationIcon />
              </InputGroup.Text>
            </InputGroup>
            <button className="food-btn text-dark  ms-2 banner-find-btn">
              <span className="d-none d-md-block">
                Find&nbsp;Restaurant&nbsp;Near&nbsp;You
              </span>

              <FontAwesomeIcon className="d-md-none" icon={faSearch} />
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HomeBanner;
