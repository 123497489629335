import _ from "lodash";
import {
  fmDashboardPostUrl,
  fnDashboardPostUrl,
  getFmPersonalPostUrl,
  getFnPersonalPostUrl,
  getProPersonalPostUrl,
  mainCoverPUrl,
  mainProPUrl,
  pDashboardPostUrl,
  publicDashboardPostUrl,
  temporaryImageUrl,
} from "src/Projects/Life/Common/CommonUrl";

export const prepareApiResponse = (response) => {
  const result = {
    data: response.data,
    hasMore: response.next_page_url ? true : false,
  };
  return result;
};

const url_list = {
  family: fmDashboardPostUrl,
  friends: fnDashboardPostUrl,
  professional: pDashboardPostUrl,
  family_personal: getFmPersonalPostUrl,
  friends_personal: getFnPersonalPostUrl,
  professional_personal: getProPersonalPostUrl,
  public: publicDashboardPostUrl,
};

export const getCurrentUrl = (timeline_name) => url_list[timeline_name];

export const getTimelineName = (timeline_name) => {
  const timeline_one = timeline_name.split("_")[0];
  const timeline_two = timeline_one + "_personal";
  return { timeline_one, timeline_two };
};

// delete post single image
export const fineContentIndex = (postList, content_id, post_id, type) => {
  let prop_name = "post_contents";
  let id_name = "id";
  let filter_object = { id: parseInt(post_id) };
  if (type === "personal") {
    prop_name = "data_content";
    id_name = "content_id";
    filter_object = { post_id: parseInt(post_id) };
  }

  const itemIndex = _.findIndex(postList, filter_object);
  const post = postList[itemIndex];
  const post_contents = post[prop_name].filter(
    (item) => item[id_name] !== content_id
  );
  post[prop_name] = post_contents;
  return { itemIndex, post };
};

// delete single post

export const deleteSinglePost = (postList, post_id, type) => {
  let id_name = "id";
  if (type) {
    id_name = "post_id";
  }

  const remainingPost = postList.filter((item) => item[id_name] !== post_id);
  return remainingPost;
};

export const processProfileImageData = (data) => {
  const { cover_image_location, profile_image_location } = data;
  const { cover_photo, profile_photo } = data.data;
  let cover = "/Life/Cover/Professional.jpg";
  let profile = "/user.png";
  if (cover_photo)
    cover = `${temporaryImageUrl}/${cover_image_location + cover_photo}`;
  if (profile_photo)
    profile = `${temporaryImageUrl}/${profile_image_location + profile_photo}`;

  return { cover, profile };
};

export const processMainProfileImageData = (data) => {
  const { cover_photo, user } = data || {};
  let cover = "/Life/Cover/Professional.jpg";
  let profile = "/user.png";
  if (cover_photo) cover = `${mainCoverPUrl}/${cover_photo}`;
  if (user?.photo) profile = `${mainProPUrl}/${user.photo}`;
  return { cover, profile };
};
