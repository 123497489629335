import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt, faStar } from "@fortawesome/free-solid-svg-icons";

function SendSourceFileModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="creative__modal__header" closeButton>
        <h6 className="mb-0">Send Source File</h6>
      </Modal.Header>
      <Modal.Body className="creative__modal__body">
        <div className="d-sm-flex align-items-center justify-content-between mb-sm-4">
          <div className="d-flex gap-3 align-items-center">
            <img
              src="https://i.ibb.co/Bfp9vX0/freelancer-3.jpg"
              alt=""
              className="creative__modal__freelancer__img"
            />
            <div>
              <h6>Freelancer</h6>
              <p className="mb-0">Thailand</p>
              <p>
                <FontAwesomeIcon className="text-orange" icon={faStar} /> 5.0
                (12)
              </p>
            </div>
          </div>

          <div className="mt-3 mt-sm-0">
            <p className="mb-0">
              Price <span className="fw-500">$200</span>
            </p>
            <p>Time: -3 Day, 05:50:30</p>
          </div>
        </div>
        <h6>
          The Standard Chunk Of Lorem Ipsum Used Since The 1500s Is Reproduced
          Below For
        </h6>
        <p>
          Company Name Is Bramble & Bee. It's A Honey Company Logo. It Was For A
          Contest That I Won Here On Freelancer.Com. It Was A Great Experience
          Doing This Logo And Working For This Contest. Always Did Design
        </p>

        <div className="mt-3 mb-2 mt-sm-4 d-flex gap-3 flex-wrap ">
          <Button className="flex-grow-1" variant="outline-secondary">
            Upload Presentation{" "}
            <FontAwesomeIcon className="ms-2" icon={faCloudUploadAlt} />
          </Button>

          <Button className="flex-grow-1" variant="outline-secondary">
            Upload Source File{" "}
            <FontAwesomeIcon className="ms-2" icon={faCloudUploadAlt} />
          </Button>

          <Button onClick={props.onHide} className="btn__creative flex-grow-1">
            Send File
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default SendSourceFileModal;
