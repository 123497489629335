import React from "react";
import { useState } from "react";
import { Container, Row } from "react-bootstrap";
import ScrollToTop from "src/Common/ScrollToTop";
import FoodCard from "../../Components/FoodCard/FoodCard";
import useFoodStore from "../../hooks/useFoodStore";
import "./restaurant_details.css";
const RestaurantDetails = () => {
  const [foodType, setFoodType] = useState("All");
  const foodTypeList = ["All", "Chines", "Burger"];
  const { products } = useFoodStore();

  return (
    <Container fluid className="p-0">
      <ScrollToTop />
      <div className="restaurant__details-banner">
        <img
          src="https://www.hotkhana.com/images/stories/flexicontent/item_22_field_15/m_banner2.jpg"
          alt=""
        />
      </div>
      <Container fluid>
        <div className="my-4 primary-text">
          <p className=" mb-2 fs-4 ">ZamZam Restuant (Mirpur-11)</p>
          <p className="mb-2">Daily Time : 10.00 AM to 11:00 PM</p>
          <p className="mb-0">
            <span className="product__card-star">&#9733;</span> 5.0 (20 Review)
          </p>
        </div>
        <div>
          {foodTypeList.map((item) => (
            <span
              onClick={() => setFoodType(item)}
              key={item}
              className={`food__nav__sub-item ${
                foodType === item ? "active" : ""
              }`}
            >
              {item}
            </span>
          ))}
        </div>

        <Row
          xs="2"
          sm="2"
          md={2}
          lg="3"
          xl={4}
          xxl="5"
          className="home__Product-box g-2 my-4"
        >
          {products.map((item) => (
            <FoodCard key={item.id} data={item} />
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default RestaurantDetails;
