import {
  processMainProfileImageData,
  processProfileImageData,
} from "src/app/helper/rtkFunctions";
import {
  addSearchResult,
  setProfileImages,
} from "src/features/life/lifeCommonDataSlice";
import { showToast } from "src/Projects/Job/utilities/functions";
import {
  editPostUrl,
  mainPhotosUrl,
  otherProfileOverviewUrl,
  personal_notification_url,
  searchFriendUrl,
  fmFindFnUrl,
  fnFindFnUrl,
  pFindFnUrl,
  closeReportUrl,
} from "src/Projects/Life/Common/CommonUrl";
import { api } from "../api";

export const lifeCommonApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPersonalNotification: builder.query({
      query: (page = 1) => `${personal_notification_url}?page=${page}`,
      keepUnusedDataFor: 300,
      transformResponse: (res) => {
        const data = res.data;
        const hasMore = res?.links.next ? true : false;
        return { data, hasMore };
      }, // this is middleware function
    }),

    getFriendSuggestions: builder.query({
      query: ({ life, page = 1 }) => {
        let friendCommonUrl;

        if (life === "friends-life") {
          friendCommonUrl = fnFindFnUrl;
        } else if (life === "family-life") {
          friendCommonUrl = fmFindFnUrl;
        } else if (life === "professional-life") {
          friendCommonUrl = pFindFnUrl;
        }
        return { url: friendCommonUrl };
      },
      providesTags: ["suggestedFriends"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (err) {}
      },
    }),

    getSinglePost: builder.query({
      query: (post_id) => `${editPostUrl}/${post_id}`,
    }),

    getPublicProfileInfo: builder.query({
      query: (user_id) => `${otherProfileOverviewUrl}/${user_id}`,
    }),

    getSearchResult: builder.mutation({
      query: (text) => ({
        url: `${searchFriendUrl}`,
        method: "POST",
        body: { name: text },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.status === 1) {
            dispatch(addSearchResult(data.data));
          } else {
            showToast({ title: "Please try Again", icon: "error" });
          }
        } catch (err) {
          showToast({ title: err.message, icon: "error" });
        }
      },
    }),

    getProfileImages: builder.query({
      query: ({ url }) => url,
      keepUnusedDataFor: 600,
      async onQueryStarted({ timeline_name }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.code === 200) {
            const profile_images = processProfileImageData(data);
            dispatch(setProfileImages({ timeline_name, data: profile_images }));
          }
        } catch (err) {
          console.log(err);
        }
      },
    }),

    getMainProfileImages: builder.query({
      query: () => mainPhotosUrl,
      keepUnusedDataFor: 600,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            const profile_images = processMainProfileImageData(data);
            dispatch(
              setProfileImages({
                timeline_name: "public",
                data: profile_images,
              })
            );
          }
        } catch (err) {
          console.log(err);
        }
      },
    }),

    updateProfilePhoto: builder.mutation({
      query: ({ url, data }) => {
        return {
          url: url,
          method: "POST",
          body: data,
        };
      },
    }),

    closeReport: builder.mutation({
      query: (id) => {
        return {
          url: closeReportUrl + id,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useGetPersonalNotificationQuery,
  useGetSinglePostQuery,
  useGetFriendSuggestionsQuery,
  useGetPublicProfileInfoQuery,
  useGetSearchResultMutation,
  useGetProfileImagesQuery,
  useGetMainProfileImagesQuery,
  useUpdateProfilePhotoMutation,
  useCloseReportMutation,
} = lifeCommonApi;
