import axios from "axios";
import Swal from "sweetalert2";
import { orderVerifyUrl } from "../../utilities/CommonUrl";

export const OtpSubmit = (formData, setState,targetedEmail) => {
// const { authUser } = useAuth();

  waitSwal();
    axios
    .post(`${orderVerifyUrl}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      Swal.close();
      Swal.clickCancel();
      if(res.data.status===1){
        Swal.fire({
          icon: "success",
          title: "Successful",
          text: `Check your email: ${targetedEmail} `,
        }).then(setState((prevState) => ({ ...prevState, show_pin_input: true })));
      }else{
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.data.message,
          footer: "<p>Please try again</p>",
        });
      }
      
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "otp sending Failed!",
        footer: "<p>Please try again</p>",
      });
    });
}
export const waitSwal = () => {
  let timerInterval;
  Swal.fire({
    title: "Wait for The Response!",
    html: "I will close in <b></b> milliseconds.",
    timer: 2000,
    timerProgressBar: true,
    didOpen: () => {
      Swal.showLoading();
      const b = Swal.getHtmlContainer().querySelector("b");
      timerInterval = setInterval(() => {
        b.textContent = Swal.getTimerLeft();
      }, 5000);
    },
    willClose: () => {
      clearInterval(timerInterval);
    },
  }).then((result) => {
    if (result.dismiss === Swal.DismissReason.timer) {
    }
  });
};

