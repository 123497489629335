import styled from "styled-components";

function SingleIntro({ page, cover, logo, desc }) {
  return (
    <Wrapper>
      <div className="text-center">
        <img className="banner" src={cover} alt={page} />
      </div>
      <div>
        <div className="d-flex align-items-center justify-content-center my-2 my-md-3">
          <img className="logo-circle" src={logo} alt="" />
          <h6 className="logo-title">
            <span className="text-orange">UVIOM </span> {page}
          </h6>
        </div>

        <p className="text-center">{desc}</p>
        <p className="text-center">
          Don't wait, to join the new Algorithm for professionalism
        </p>
      </div>
    </Wrapper>
  );
}

export default SingleIntro;

const Wrapper = styled.div`
  & .banner {
    max-width: 100%;
  }

  & .logo-circle {
    width: 2.5rem;
  }

  & .logo-title {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    border-left: 1px solid var(--bg-success);
    line-height: 2;
    color: var(--text-info);
    margin-bottom: 0;
  }
`;
