import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { deleteWishlistUrl, showWishlistUrl } from '../../utilities/CommonUrl';
import "./favorite.css";
import FavoriteCard from './FavoriteCard';
import paginationApiCall from "src/Common/apiCall/paginationApiCall";
import { DeleteApiCalls } from './DeleteApiCalls';
const MyFavorite = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [info, setinfo] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const paginationApiArg = {
    postList: info,
    setPostList: setinfo,
    currentPage: currentPage,
    setHasMore: setHasMore,
    Url: showWishlistUrl,
    setIsLoading: setIsLoading,
  };

    useEffect(() => {
        paginationApiCall(paginationApiArg, true);
    }, [currentPage]);
    const previousDataState = {
      data: info,
      setData: setinfo,
    };
    const DeleteFunc=(itemId)=>{
        
        const deleteActionArg={
          "url":deleteWishlistUrl,
          "id": itemId,
          "title":"Are you sure you want to delete this product?",
          "previousDataState":previousDataState,
      
        }
        DeleteApiCalls(deleteActionArg)
      }
    return (
        <Container fluid style={{ minHeight: "70vh" }}>
            <h6 className="fs-6 pt-3">My Favorite</h6>
            <Row xs="1" lg={2} >
                {info &&
                    info.length !== 0 &&
                    info.map((data) => (
                        <FavoriteCard key={data.id} data={data} DeleteFunc={DeleteFunc}/>
                    ))}
            </Row>
            <br />

            {/* {isLoading
                && [...new Array(4)].map((_, index) => <ShopSkeleton key={index} />)} */}
        </Container>

    );
};

export default MyFavorite;