import React from "react";
import {
  Link,
  Navigate,
  NavLink,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";
import CoverAndProfile from "../Components/CoverAndProfileSection/CoverAndProfile";
import { FriendFamilyList } from "../NestedRouteOnDashBodr/Friends/AllFriendList/AllFriendList";
import ViewAndEditProfile from "../NestedRouteOnDashBodr/ViewAndEditProfile/ViewAndEditProfile";
import AboutOverviewWrapper from "./AboutOverviewWrapper";

function OwnProfile() {
  const { pathname } = useLocation();
  const [, dynamicPath, dynamicLife] = pathname.split("/");

  return (
    <Wrapper>
      <CoverAndProfile />
      <div className="profile-navigation">
        <NavLink to={`/${dynamicPath}/${dynamicLife}/profile/post`}>
          Post
        </NavLink>
        <NavLink to={`/${dynamicPath}/${dynamicLife}/profile/friends`}>
          Friends
        </NavLink>
        <NavLink to={`/${dynamicPath}/${dynamicLife}/profile/about`}>
          About
        </NavLink>
      </div>
      <Routes>
        <Route path="/post" element={<ViewAndEditProfile />} />
        <Route
          path="/friends"
          element={
            <div className="friends-container">
              <FriendFamilyList />
            </div>
          }
        />
        <Route path="/about" element={<AboutOverviewWrapper />} />

        <Route
          path="/"
          element={
            <Navigate
              replace
              to={`/${dynamicPath}/${dynamicLife}/profile/post`}
            />
          }
        />
      </Routes>
    </Wrapper>
  );
}

export default OwnProfile;

const Wrapper = styled.div`
  & .profile-navigation {
    display: flex;
    justify-content: space-evenly;
    background-color: var(--light-gray);
    /* margin-top: -1rem; */

    & a {
      padding-block: 1rem;
      padding-inline: 2rem;
      border-bottom: 1px solid transparent;
      color: var(--text-primary);
      &.active {
        color: var(--text-success);
        border-color: var(--border-success);
      }
    }
  }

  & .friends-container {
    padding-inline: 1.5rem;
  }
`;
