import _ from "lodash";
import styled from "styled-components";
import SingleVideoCardSkeleton from "../../common/SingleVideoCardSkeleton";
import SingleVideoCard from "../../Components/SingleVideoCard";

function ChannelVideos({
  videos,
  channelName,
  channelProfile,
  channelCategory,
}) {
  return (
    <CardWrapper>
      {videos
        ? _.reverse([...videos])?.map((video) => (
            <SingleVideoCard
              key={video.id}
              id={video.id}
              channelCategory={channelCategory}
              userId={video.user_id}
              channelName={channelName}
              channelProfile={channelProfile}
              videoTitle={video.title}
              viewCount={video.view_count}
              thumbnail={video.thumbnail}
              created_at={video.created_at}
            />
          ))
        : Array.from({ length: 3 }).map((_, index) => (
            <SingleVideoCardSkeleton key={index} />
          ))}
    </CardWrapper>
  );
}

export default ChannelVideos;

export const CardWrapper = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;

  @media (max-width: 36em) {
    grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
  }
`;
