import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import useAuth from "../../../../../../../hooks/useAuth";
import {
  personalProfilePic,
  updateReplyCommentUrl,
} from "../../../../../Common/CommonUrl";
import { EditCommentextArea } from "../EditCommentextArea";
import { secondStageCommentLike } from "./replyCommentRowFunc";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import { EMOJI_REGEX } from "src/utilities/utilities";

export const ReplyCommntsRow = ({ item, ReplyCommntsRowArg }) => {
  const [loading, setloading] = useState(false);
  const ToastFunc = (icon, title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: icon,
      title: title,
    });
  };
  const {
    replyCommentRemoveFunc,
    editReplyCommentBoxInfo,
    setreplyCommentEditBoxInfo,
    replyCommentEditFunc,
    replyCommentReload,
    showLikedUsersModal,
  } = ReplyCommntsRowArg;
  const { authUser } = useAuth();
  const {
    comment_reply,
    user,
    comment_reply_likes_count,
    user_comment_reply_like,
  } = item;
  const [truncateToggle, settruncateToggle] = useState(true);
  const [isColored, setisColored] = useState("logoColorText");
  const [colorUpdate, setcolorUpdate] = useState(false);
  const [likeCount, setLikeCount] = useState(
    comment_reply_likes_count && comment_reply_likes_count
  );

  const personalLikeUserId =
    user_comment_reply_like !== null
      ? Number(user_comment_reply_like.user_id)
      : Number(0);
  const truncateStyleAdd = {
    maxWidth: "400px",
  };
  const truncateStyleRemove = {
    overflowWrap: "anywhere",
  };
  const replyCommentId = item.id;
  const parentCommentId = item.comment_id;
  const secondStageCommentArg = {
    CommentId: replyCommentId,
    setisColored: setisColored,
    setcolorUpdate: setcolorUpdate,
    setLikeCount: setLikeCount,
  };
  const secondStageCommentReplyFunc = () => {
    // commentRowArg.setcommentReplyBox({ "post_id": item.post_id, "comment_id": replyCommentId, "comment_reply": "" })
  };
  const inputHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...editReplyCommentBoxInfo };
    newData[name] = value;
    setreplyCommentEditBoxInfo(newData);
  };
  const commentSubmitCode = (editReplyCommentBoxInfo) => {
    if (
      editReplyCommentBoxInfo.comment &&
      editReplyCommentBoxInfo.comment.trim() !== ""
    ) {
      const formData = {
        comment_reply: editReplyCommentBoxInfo.comment,
      };
      setloading(true);
      axios
        .post(
          `${updateReplyCommentUrl}/${editReplyCommentBoxInfo.secondStageCommentId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 1) {
            formateCommentBox();
            // zz
            replyCommentReload(parentCommentId);
            // single-show-post-reply-comment/58
            //--------------------------------------------------------------------
            // const showReplyCommentEditArg = {
            //   "replyCommentId": editReplyCommentBoxInfo.secondStageCommentId,
            //   "setreplyCommentEditBoxInfo": setreplyCommentEditBoxInfo,
            // }
            // showReplyCommentEdit(showReplyCommentEditArg)
            setloading(false);
          } else {
            setloading(false);
            ToastFunc("error", "something went wrong");
          }
        })
        .catch((err) => {
          setloading(false);
          ToastFunc("error", "something went wrong");
        });
    }
  };
  const onkeyPressSubmit = (event) => {
    if (event.type === "keypress") {
      if (event.key === "Enter" && event.shiftKey === false) {
        event.preventDefault();
        commentSubmitCode(editReplyCommentBoxInfo);
      } else if (event.shiftKey && event.key === "Enter") {
      }
    } else {
      commentSubmitCode(editReplyCommentBoxInfo);
    }
  };
  const formateCommentBox = () => {
    setreplyCommentEditBoxInfo();
  };
  const EditCommentextAreaArg = {
    inputHandleChange: inputHandleChange,
    commentBoxInfo: editReplyCommentBoxInfo,
    onkeyPressSubmit: onkeyPressSubmit,
    formateCommentBox: formateCommentBox,
    placeHolder: "Write comment...",
  };

  return (
    <>
      {editReplyCommentBoxInfo &&
      editReplyCommentBoxInfo.secondStageCommentId === replyCommentId ? (
        <EditCommentextArea data={EditCommentextAreaArg} />
      ) : (
        <>
          <Row className={`commentMargin MessageInput mb-0 pb-0 `}>
            <Col
              sm={2}
              xs={2}
              className="d-flex justify-content-end align-self-start px-xs-3"
            >
              {user.photo ? (
                <img
                  className="commentavater"
                  src={`${personalProfilePic}/${item.user.photo}`}
                  alt={item.user.first_name + item.user.last_name}
                />
              ) : (
                <img
                  className="commentavater"
                  src="/user.png"
                  alt={item.user.first_name + item.user.last_name}
                />
              )}
            </Col>
            <Col sm={9} xs={8} className="px-0 align-self-center ">
              <div>
                {user.first_name} {user.last_name}
              </div>
              <div className="d-flex  justify-content-between">
                <div
                  className={`d-inline-block text-truncate ${
                    EMOJI_REGEX.test(item?.comment_reply) &&
                    item?.comment_reply?.length <= 2
                      ? "comment_single_emoji"
                      : "mediumTextSize"
                  }`}
                  style={
                    truncateToggle ? truncateStyleAdd : truncateStyleRemove
                  }
                  onClick={() => settruncateToggle(!truncateToggle)}
                >
                  {comment_reply}
                </div>
              </div>
              <Row>
                <Col md={12} className="d-flex smallTextSize">
                  <div
                    className={`me-2 pointer ${
                      personalLikeUserId === authUser.id ? isColored : ""
                    } ${colorUpdate ? "logoColorText" : ""}`}
                    onClick={() => {
                      secondStageCommentLike(secondStageCommentArg);
                    }}
                  >
                    Like
                  </div>

                  {likeCount && likeCount > 0 ? (
                    <div
                      className={`me-3 pointer px-2  rounded-3`}
                      onClick={() => showLikedUsersModal(replyCommentId)}
                    >
                      <FontAwesomeIcon
                        icon={faThumbsUp}
                        className="me-1"
                        size="sm"
                      />{" "}
                      {likeCount}
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Col>
            {/* options  */}
            {"" + item.user_id === "" + authUser.id && (
              <Col
                sm={1}
                xs={2}
                className="pe-md-0 px-xs-0 text-right align-self-center text-end"
              >
                <Dropdown>
                  <Dropdown.Toggle
                    aria-label="toggle button"
                    className="ToggleDesignClear"
                  >
                    <ThreeDots size="20" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="dark">
                    <div className="ClickThreeDot mb-3 px-3 pb-3 pt-3">
                      <div
                        className="btnHoverBorder"
                        onClick={() => replyCommentEditFunc(replyCommentId)}
                      >
                        <div className=" mt-1">
                          <h6>Edit</h6>
                        </div>
                      </div>
                      <div
                        className="btnHoverBorder"
                        onClick={() => replyCommentRemoveFunc(replyCommentId)}
                      >
                        <div className=" mt-1">
                          <h6>Remove</h6>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  );
};
