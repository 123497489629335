import { faPencilAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, FormControl, InputGroup } from "react-bootstrap";
import CancelAndSaveBtn from "./CancelAndSaveBtn";
import "./fileAndTextInput.css";
import imageCompression from 'browser-image-compression';
import { isEmpty } from "lodash";
import { fileUploadToServer } from "./apiCalls";

const InputBoxNationalCard = ({
  handleFileInputFrontChange = () => {},
  handleFileInputBackChange = () => {},
  handleTextInputChange = () => {},
  fileInputFrontValue,
  fileInputBackValue,
  textInputValue,
  textInputName,
  changeHandle,
  document,
  uploadUrl,
dataReload,

}) => {
  const [show, setShow] = useState(false);
  const handleEditButton = () => {
    setShow(true);
  };
  const handleCancelBtn = () => {
    setShow(false);
  };
 
  const [isLoading, setIsLoading] = useState(false);
  const [nid_front_photo, setnid_front_photo] = useState()
  const [nid_back_photo, setnid_back_photo] = useState()
  let formData = new FormData();
  formData.append(textInputName, textInputValue);
  let compressedFile;
  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {

      compressedFile = await imageCompression(imageFile, options);
      // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      // zz
      formData.append(event.target.name, compressedFile);
      // await fileUploadToServer(compressedFile, uploadUrl,formData); // write your own logic
    } catch (error) {
      console.log(error);
    }

  }
  const handleSave = () => {
    if (!isEmpty(compressedFile)) {
      fileUploadToServer(setIsLoading, compressedFile, uploadUrl, formData, dataReload)
      setShow(false);
    }
  }
  return (
    <Col className="my-2">
      <div>
        <div className="d-flex justify-content-between my-2">
          <span className="file-label ms-3">National ID</span>
          <FontAwesomeIcon
            onClick={handleEditButton}
            className="mainProfilePenEditBtn"
            icon={faPencilAlt}
          ></FontAwesomeIcon>
        </div>
        <InputGroup className="file-and-text-input-group" hasValidation>
          <FormControl
            disabled={!show}
            onChange={changeHandle}
            value={textInputValue}
            aria-label="search"
            name={textInputName}
            placeholder="6958-6957-6586-6586"
            className="file-and-text-input-field"
            required
          />
          <InputGroup.Text className="profileVideoSearchLabel">
            <label
              disabled={!show}
              htmlFor={"file_front"}
              className="mainProfileInput me-2"
              id="file-label"
            >
              <span className="file-text-btn">
                F/pic
                <FontAwesomeIcon
                 className={`${document !=="" ? "text-white":""} ms-1 ms-lg-2 `}
                  size="lg"
                  icon={faPlusCircle}
                ></FontAwesomeIcon>
              </span>
            </label>
            <input
              disabled={!show}
              accept="image/png, image/jpg"
              type="file"
              value={fileInputFrontValue}
              name="nid_front_photo"
              id="file_front"
              className="file-input"
              onChange={event => handleImageUpload(event)}
            />
            {/* national file input back  */}
            <label
              disabled={!show}
              htmlFor={"file_back"}
              className="mainProfileInput"
              id="file-label"
            >
              <span className="file-text-btn">
                B/pic
                <FontAwesomeIcon
                   className={`${document!=="" ? "text-white":""} ms-1 ms-lg-2 `}
                  size="lg"
                  icon={faPlusCircle}
                ></FontAwesomeIcon>
              </span>
            </label>
            <input
              disabled={!show}
              accept="image/png, image/jpg"
              type="file"
              value={fileInputBackValue}
              name="nid_back_photo"
              id="file_back"
              className="file-input"
              onChange={event => handleImageUpload(event)}
            />
          </InputGroup.Text>
        </InputGroup>
      </div>

      {show && (
        <CancelAndSaveBtn handleCancelBtn={handleCancelBtn}  handleSave={handleSave}></CancelAndSaveBtn>
      )}
    </Col>
  );
};

export default InputBoxNationalCard;
