import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const CutomTooltip = ({ text, children, placementName }) => {
  return (
    <OverlayTrigger
      // 'top', 'right', 'bottom', 'left'
      placement={placementName ? placementName : "auto"}
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip>{text ? text : "Coming Soon."}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};
