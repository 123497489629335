import { Container } from "react-bootstrap";

import ReceiptBox from "./../components/ReceiptBox";

import overlay from "./../assets/overlay.png";

function Receipt() {
  return (
    <div 
    // style={{ backgroundImage: `url(${overlay})` }}
     id="wrapper">
      <Container>
        <h3 className="heading-3 pt-3">View Receipt</h3>
        <ReceiptBox />
      </Container>
    </div>
  );
}
export default Receipt;
