import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";
import CreatePlaylistContainer from "../../Components/CreatePlaylistContainer";
import { SinglePlaylist } from "./SinglePlaylist";
import SinglePlaylistVideo from "./SinglePlaylistVideo";
import useAuth from "src/hooks/useAuth";

function ChannelPlaylists({ playlists, userId }) {
  const { authUser } = useAuth();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Wrapper>
            {authUser.id === parseInt(userId) && (
              <div className="createPlaylist-container">
                <CreatePlaylistContainer text="Create Playlist" />
              </div>
            )}
            {_.reverse([...playlists])?.map((playlist) => (
              <SinglePlaylist
                key={playlist.id}
                playlistId={playlist.id}
                userId={userId}
                playlistName={playlist.name}
                playlistDesc={playlist.description}
                playlistStatus={playlist.status}
                playlistThumbnail={playlist.thumbnail}
                videos={playlist.videos}
              />
            ))}
          </Wrapper>
        }
      />
      <Route
        path="/:playlistId"
        element={<SinglePlaylistVideo playlists={playlists} />}
      />
    </Routes>
  );
}
export default ChannelPlaylists;

const Wrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));

  & .createPlaylist-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    min-height: 7rem;

    & div {
      background-color: var(--text-info);
      color: var(--clr-white);
      padding: 0.2rem 0.5rem;
      border-radius: 0.2rem;
      margin-block: 1rem;
    }
  }
`;
