import React, { useState } from "react";
import { SeeMore } from "src/Projects/Life/Components/SeeMore";

import { PostActions } from "../../../NewsFeedPost/Components/PostActions/PostActions";

import { ShareActions } from "../../../NewsFeedPost/Components/PostActions/ShareActions";
import { Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import "./newsFeedVideoPost.css";
import VideoPlayer from "src/Common/VideoPlayer/VideoPlayer";
import LikeCommentShareView from "../LikeCommentShareView/LikeCommentShareView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

const NewsFeedVideoPost = ({
  url,
  autoPlay = false,
  videoId,
  showFollowBtn = false,
}) => {
  const [showShareOption, setShowShareOption] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, dynamicPath, dynamicLife] = pathname.split("/");
  const postActionsArg = {
    post_id: videoId,
    user_id: "",
    newsFeedPostArg: "",
  };

  return (
    <div className="box-shadow mb-3 bg-footer rounded-3 newsFeedPage">
      <Col xs={12}>
        <div className="PostHeaderOption px-3 pt-3 mb-2 align-items-center">
          <div className="ProfileAndName">
            <div className="PostSectionProfile">
              <img className="img-fluid" src={`/user.png`} alt="user" />
            </div>
            <div className="ms-2">
              <h6
                className="my-0 smallTextSize-sm namehoverEffect"
                onClick={() =>
                  navigate(
                    `/${dynamicPath}/${dynamicLife}/watch-video/profile/140`
                  )
                }
              >
                Nahid Ahmed
              </h6>
              <div className="smallTextSize-sm">
                <small>Relationship</small>
                {/* <small>10 mint ago</small> */}
              </div>
            </div>
          </div>
          {showFollowBtn ? (
            <div className="d-flex align-items-center">
              <button type="button" className="bestTimeFollowBtn">
                Follow
              </button>
              <button
                type="button"
                className="show-340 videoPostBellBtn ms-3 show-340 "
              >
                <FontAwesomeIcon icon={faBell} />
              </button>
            </div>
          ) : (
            <PostActions postActionsArg={postActionsArg} />
          )}
        </div>
      </Col>

      <Col xs={12} className="NewsPhotoDiv">
        <VideoPlayer url={url} autoPlay={autoPlay}></VideoPlayer>
      </Col>

      <Col xs={12} className="pt-2 px-3">
        <SeeMore>
          আকাশ আমায় শিক্ষা দিল উদার হতে ভাই রে, কর্মী হবার মন্ত্র আমি বায়ুর
          কাছে পাই রে। পাহাড় শিখায় তাহার সমান- হই যেন ভাই মৌন-মহান, খোলা মাঠের
          উপদেশে- দিল-খোলা হই তাই রে। সূর্য আমায় মন্ত্রণা দেয় আপন তেজে জ্বলতে,
          চাঁদ শিখাল হাসতে মোরে, মধুর কথা বলতে। ইঙ্গিতে তার শিখায় সাগর- অন্তর
          ..
        </SeeMore>
      </Col>

      {/* like share comment option  */}

      {showShareOption && <ShareActions />}
      <LikeCommentShareView />

      <div className="searchDropdown  mx-auto firstStageCommentDiv">
        {false && (
          <>
            <div className="MessageInput ">
              <div className="container-fluid ">
                {/* <CommentextArea data={{}} /> */}
              </div>
            </div>
          </>
        )}
        {/* {false && (
          <>
            {commentsData && commentsData.length > 0 && commentsData.map((item) => (
              <div className="MessageInput " key={`${item.id}`}>
                <CommentRow item={item} authUser={authUser} editCommentBox={editCommentBox} seteditCommentBox={seteditCommentBox} commentRemoveFunc={commentRemoveFunc} commentEditFunc={commentEditFunc} commentReload={commentReload} commentRowArg={commentRowArg} />
              </div>
            ))}
            {buttonRowVisible && (
              <div className="mediumTextSize pointer" onClick={loadMore}>See More...</div>
            )}
          </>
        )} */}
      </div>
      {/* <LikedUserModal data={{}} /> */}
    </div>
  );
};

export default NewsFeedVideoPost;
