import { Button } from "react-bootstrap";
import pickupIcon from "./../../assets/pickup.svg";
import dropIcon from "./../../assets/drop.svg";

import "./historyCard.css";

function HistoryCard() {
  return (
    <div className="express__history__card">
      <div>
        <p className="express__history__card__date">10 Jun 2022 | 9.00pm</p>
        <p className="express__history__card__product">
          <span className="fw-600">Product Name</span>: UVIOM Laptop
        </p>
        <p className="express__history__card__location">
          <span>
            <img src={pickupIcon} alt="" /> Bronx, New York -10472
          </span>
          <span> &rarr;</span>
          <span>
            <img src={dropIcon} alt="" />
            Dhanmondi 32
          </span>
        </p>
        <p className="express__history__card__deliveryType">
          <span className="fw-600">Delivery Type:</span> Same Day Express
        </p>
      </div>

      <div className="express__history__card__btns">
        <Button variant="outline-dark">Placed Order</Button>
        <Button className="btn__ride">See Details</Button>
      </div>
    </div>
  );
}
export default HistoryCard;
