import React from "react";
import { Dropdown } from "react-bootstrap";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import useStore from "src/hooks/useStore";
import { Actions } from "../Components/Actions";
import { workInfoUrl } from "src/Projects/Life/Common/CommonUrl";
import { DeleteApiCalls } from "src/Common/apiCall/DeleteApiCalls";

export const DropdownActions = ({itemId,previousDataState,editClick,componentMainUrl}) => {
  // DeleteCall
  const deleteActionArg={
    "url":componentMainUrl,
    "id": itemId,
    "title":"Are you sure you want to delete this education history?",
    "previousDataState":previousDataState

  }
  const DeleteFunc=()=>{
    DeleteApiCalls(deleteActionArg)
  }
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle aria-label="drop down" className="ToggleDesignClear">
          <Actions icon2={faEllipsisH} />
        </Dropdown.Toggle>
        <Dropdown.Menu variant="dark">
          <div className="ClickThreeDot dropDown-sm mb-3 px-3 pb-3 pt-3">
            <div className="  btnHoverBorder" onClick={()=>editClick(itemId)}>
              <div className="">
                <span className="smallTextSize mx-2 fw-bold">Edit</span>
              </div>
            </div>
            <div className=" btnHoverBorder" onClick={DeleteFunc}>
              <div className="">
                <span className="smallTextSize mx-2 fw-bold">Delete</span>
              </div>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
