import { Form } from "react-bootstrap";

const InputField = ({ name, value, placeholder, handler }) => {
  return (
    <>
      <Form.Control
        className="border-0 focus-none input-reset"
        type="text"
        name={name}
        value={value}
        onChange={handler}
        placeholder={placeholder}
      />
      <hr className="my-1" />
    </>
  );
};

export default InputField;
