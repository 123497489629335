import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Container, Form, InputGroup } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SearchResultAll from "./SearchResultAll/SearchResultAll";
import SearchResultFood from "./SearchResultFood/SearchResultFood";
import SearchResultRestaurant from "./SearchResultRestaurant/SearchResultRestaurant";
const SearchResult = () => {
    const [key, setKey] = useState("all");
  return (
    <Container fluid style={{ minHeight: "65vh" }} className="mb-4">
      <div className="d-flex align-items-center justify-content-center my-4">
        <InputGroup className="food-search-box py-1 ">
          <Form.Control placeholder="Search Here" />
          <InputGroup.Text className="d-xs-hide d-md-inline">
            <FontAwesomeIcon icon={faSearch} className="text-gray" />
          </InputGroup.Text>
        </InputGroup>
        <button className="food-btn  ms-2 banner-find-btn text-dark">
          <span className="d-none d-md-block ">Search&nbsp;</span>

          <FontAwesomeIcon className="d-md-none" icon={faSearch} />
        </button>
      </div>

      <Tabs
        id="food-order-history"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 mt-3 food-tab "
        transition={true}
      >
        <Tab eventKey="all" title="All">
         <SearchResultAll/>
        </Tab>
        <Tab eventKey="food" title="Food">
          <SearchResultFood />
        </Tab>
        <Tab eventKey="restaurant" title="Restaurant">
          <SearchResultRestaurant />
        </Tab>
      </Tabs>
      
    </Container>
  );
};

export default SearchResult;
