import React from "react";
import "./shippingAndBilling.css";
import { useRef } from "react";
import useCartStore from "src/Projects/Corporate/hooks/useCartStore";
import ShippingAddAddressForm from "../AddAddressForm/ShippingAddAddressForm";

const Shipping = ({ doneAccordion }) => {
    const shippingFormBtnRef = useRef();
    const { shippingAddress, setShippingAddress } = useCartStore();
    return (
        <div>
            <h6 className="mb-2">Shipping Address</h6>
            <ShippingAddAddressForm
                data={shippingAddress}
                setFunc={setShippingAddress}
                btnRef={shippingFormBtnRef}
                doneAccordion={doneAccordion}
            ></ShippingAddAddressForm>
        </div>
    );
};

export default Shipping;
