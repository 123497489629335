import React from "react";
import { Col, Nav } from "react-bootstrap";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const LeftComp = () => {
  return (
    <Col lg={6}>
      <div className="p-2 p-md-4">
        <p className="">Please, contact at the following addresses:</p>
        <div className="mt-4">
          <h6 className="fw-bold">Ubizz</h6>
          <p className="font-14 fw-500">House: 100/A, Dhanmondi-4, Dhaka</p>

          <p className="font-14 fw-500">Working Hour: 09.00 AM to 06.00 PM</p>
          <p className="font-14 fw-500">Email: support@uviom.com</p>
          <p className="font-14 fw-500">Website: www.uviomcorporate.com</p>
          <p className="font-14 fw-500">
            Facebook: facebook.com/uviomcorporate
          </p>
        </div>

        <div className="contact-social-icon">
          <Nav.Link href="https://www.facebook.com/uviominc">
            <span>
              <FontAwesomeIcon className="social-icon" icon={faFacebook} />
            </span>
          </Nav.Link>
          <Nav.Link href="#">
            <span>
              <FontAwesomeIcon className="social-icon" icon={faInstagram} />
            </span>
          </Nav.Link>
          <Nav.Link href="#">
            <span>
              <FontAwesomeIcon className="social-icon" icon={faTwitter} />
            </span>
          </Nav.Link>
          <Nav.Link href="#">
            <span>
              <FontAwesomeIcon className="social-icon" icon={faWhatsapp} />
            </span>
          </Nav.Link>
        </div>
      </div>
    </Col>
  );
};
