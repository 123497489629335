/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import InputWithLabel from "src/Common/InputWithLabel/InputWithLabel";
import { Form } from "react-bootstrap";
import InputRangeWithLabel from "../../Components/Common/InputRangeWithLabel";
import InputTextAreaWithLabel from "../../Components/Common/InputTextAreaWithLabel";
import JobCategorySelectBox from "../../Components/Common/JobCategorySelectBox";
import { useNavigate, useLocation } from "react-router-dom";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import LocationSelectInput from "../../Components/Common/LocationSelectInput";
import { getAllCategories, getCategoryBySubCategory, getDivisionByDistricts } from "../../utilities/functions";
import { useUpdatePostedJobMutation } from "src/app/services/jobApi/jobPostApi";

const EditJobPost = () => {
  const [categories, setCategories] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const [jobPostData, setJobPostData] = useState({...state.data});
  const [districts, setDistricts] = useState([]);

  const [updatePost, {isLoading, isSuccess, error:server_err}] = useUpdatePostedJobMutation()

  const {
    job_category_id,
    job_sub_category_id,
    job_type,
    job_title,
    range_start,
    range_end,
    job_address,
    job_details,
    job_requirements,
    role_responsibility,
    deadline,
    id,
    division_id,
    district_id,
  } = jobPostData;

  useEffect(() => {
    const categories = getAllCategories() || [];
    setCategories(categories);
  }, []);

  useEffect(() => {
    const sub_category = getCategoryBySubCategory(job_category_id);
    setSubCategory(sub_category);
  }, [job_category_id]);

  useEffect(() => {
    const districts = getDivisionByDistricts(division_id);
    setDistricts(districts);
  }, [division_id]);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const prevState = jobPostData;
    prevState[name] = value;
    setJobPostData({ ...prevState });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (parseInt(job_category_id) > 0) {
      // if category item not select value = 0 or if select item value will be < 0
      setError("");
      updatePost({ id: id, data: jobPostData });
    } else {
      setError("Please Select Category");
    }
  };




  useEffect(() => {
    if (isSuccess) {
      navigate("/Job/company/posted-job");
    }
    if (server_err) {
      setError(server_err.error)
    }
  },[isSuccess, server_err])

  const inputProps = {
    required: true,
    className: ["bordaredInput", "text-start ps-4"],
    checkoutInput: true,
  };

  return (
    <div className="secondary-bg">
      <h6 className="jobApplyTitle ms-2">Edit Post</h6>

      {/* <CompanyBanner  /> */}
      {/* <br /> */}

      {isLoading && <BubbleLoader />}

      <Form>
        <JobCategorySelectBox
          {...inputProps}
          value={job_category_id}
          label="Job Category"
          name="job_category_id"
          handler={handleInput}
        >
          <option value="0">Select Category</option>
          {categories.map((item) => (
            <option key={item.id} value={item.id}>
              {item.title}
            </option>
          ))}
        </JobCategorySelectBox>

        <JobCategorySelectBox
          {...inputProps}
          value={job_sub_category_id}
          label="Job Sub Category"
          name="job_sub_category_id"
          handler={handleInput}
        >
          <option value="0">Select Sub Category</option>
          {subCategory.map((item) => (
            <option key={item.id} value={item.id}>
              {item.title}
            </option>
          ))}
        </JobCategorySelectBox>
        <JobCategorySelectBox
            {...inputProps}
            value={job_type}
            label="Job Type"
            name="job_type"
            handler={handleInput}
            placeholder="Job Type"
          >
            <option value="" disabled>
              Select Job Type
            </option>
            <option value="Full Time">Full Time</option>
            <option value="Part Time">Part Time</option>
          </JobCategorySelectBox>
        <InputWithLabel
          {...inputProps}
          value={job_title}
          label="Job Title"
          name="job_title"
          handler={handleInput}
          required
        />

        <InputRangeWithLabel
          {...inputProps}
          startValue={range_start}
          endValue={range_end}
          handler={handleInput}
          label="Salary range"
          startName="range_start"
          endName="range_end"
        />

        <LocationSelectInput
          {...inputProps}
          label="Location"
          handler={handleInput}
          district_value={district_id}
          division_value={division_id}
        >
          <option value="" disabled>
            {" "}
            Select District
          </option>
          {districts.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </LocationSelectInput>

        <InputWithLabel
          {...inputProps}
          value={job_address}
          label="Job Location"
          name="job_address"
          handler={handleInput}
          required
        />
        <InputWithLabel
          {...inputProps}
          value={deadline}
          label="Deadline"
          name="deadline"
          handler={handleInput}
          placeholder="Job deadline"
          type="date"
          required
        />

        <InputTextAreaWithLabel
          {...inputProps}
          value={role_responsibility}
          label="Responsibility"
          name="role_responsibility"
          handler={handleInput}
          rows="3"
          required
        />

        <InputTextAreaWithLabel
          {...inputProps}
          value={job_requirements}
          label="Job Requirement"
          name="job_requirements"
          handler={handleInput}
          rows="3"
          required
        />
        <InputTextAreaWithLabel
          {...inputProps}
          value={job_details}
          label="Job Details"
          name="job_details"
          handler={handleInput}
          rows="4"
          required
        />
        <div style={{ fontSize: "0.9rem" }}>
          <p className="text-danger text-center">{error}</p>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="companyProfileBtn px-5 "
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            type="submit"
            className="companyProfileBtn px-5"
          >
            Save
          </button>
        </div>
      </Form>
      <br />
      <br />
    </div>
  );
};

export default EditJobPost;
