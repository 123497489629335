import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import ToastFunc from "src/Common/ToastFunc";
import { rPrice } from "src/utilities/utilities";
import ShoppingCartIcon from "../../assets/icons/ShoppingCartIcon";
import { SalePrice } from "../../Components/Common/ProductCard/SalePrice";
import useCartStore from "../../hooks/useCartStore";
import { productImgBase_m } from "../../utilities/CommonUrl";
import "./favorite.css";
const FavoriteCard = ({ data, DeleteFunc }) => {
  const { with_product } = data;
  const { id, product_name, stock, slug, product_image, sale_price } =
    with_product;
  const { addToCart, state } = useCartStore();
  const { cartItems } = state;
  const [outOfStock, setoutOfStock] = useState("");
  const addThisProduct = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (outOfStock) {
    } else {
      const sameIdProduct = cartItems.filter(
        (item) => Number(item.id) === Number(data.id)
      );
      if (sameIdProduct.length > 0) {
        ToastFunc("error", "Already on cart");
      } else {
        const itemAllInfo = {
          ...data,
          sale_price: rPrice(data.sale_price),
          quantity: 1,
          eachItemTotal: rPrice(data.sale_price) * 1,
        };
        addToCart(itemAllInfo);
        ToastFunc("success", "Successfully added");
      }
    }
  };

  return (
    <Col>
      <FavoriteCardWrapper>
        <div className="cart-img">
          {product_image ? (
            <img
              src={`${productImgBase_m}/${product_image}`}
              alt={product_name}
            />
          ) : (
            <div className="no_img">No img</div>
          )}
        </div>

        <div className="details">
          <p className="details__title">{product_name}</p>
          <p className="details__text">Model: #Ed841</p>

          <div className="d-sm-flex mb-1">
            <p className="flex-grow-1 details__text">Brand : Fastrack</p>
            <p className="flex-grow-1 details__text">Shop: UVIOM</p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="text-orange mb-0 fs-6">
              <SalePrice
                stock={stock}
                outOfStock={outOfStock}
                setoutOfStock={setoutOfStock}
                sale_price={sale_price}
              />
            </h6>
            <div className="d-flex  align-items-center justify-content-end">
              <div className=" text-center d-flex align-items-center ">
                <button
                  onClick={addThisProduct}
                  className={`eco-btn text-orange mx-1 favorite-add-to-cart-btn ${outOfStock}?"cursor-unset":""`}
                >
                  <small className="me-2 d-none d-sm-inline-block">
                    Add to Cart
                  </small>
                  <ShoppingCartIcon />
                </button>
                <button className="text-orange eco-btn mx-1">
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => DeleteFunc(id)}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </FavoriteCardWrapper>
    </Col>
  );
};

export default FavoriteCard;

const FavoriteCardWrapper = styled.div`
  display: flex;
  background-color: var(--bg-secondary);
  gap: 2rem;
  margin: 0.5rem 0;
  padding: 1rem 0.5rem;
  align-items: center;
  box-shadow: var(--box-shadow);

  /* 512px */
  @media (max-width: 32em) {
    padding: 0.5rem;
    gap: 1rem;
  }

  & .cart-img {
    height: 8rem;
    width: 8rem;
    flex-basis: 8rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    /* 512px */
    @media (max-width: 32em) {
      width: 10rem;
    }

    & img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  & > .details {
    flex-grow: 1;

    & > .details__title {
      margin-bottom: 0.2rem;
      line-height: 1.2;
      margin-bottom: 0.5rem;

      /* 768px */
      @media (max-width: 48em) {
        font-size: 0.85rem;
      }
      /* 576px */
      @media (max-width: 36em) {
        font-size: 0.75rem;
      }
    }

    & .details__text {
      font-size: 0.85rem;
      margin-bottom: 0;

      /* 768px */
      @media (max-width: 48em) {
        font-size: 0.7rem;
      }
    }
  }
`;
