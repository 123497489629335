import React from "react";
import { Form, Nav, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import useStore from "src/hooks/useStore";
import useTheme from "src/hooks/useTheme";
import "./NavOffCanvas.css";
import { SocialLinks } from "./SocialLinks";

const NavOffCanvas = ({ show, setShow }) => {
  const location = useLocation();
  const { handleLogout } = useStore();
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const { handleThemeChange, currentTheme } = useTheme();
  return (
    <div>
      <Offcanvas
        className="OffCnavas"
        show={show}
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title to="/" onClick={handleClose} as={Link}>
            <img
              width="50"
              height="50"
              src="/images/logo.svg"
              alt="UVIOM"
              className="me-1"
            />
            UVIOM
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="SideNavMenu">
            <Nav.Link onClick={handleClose} as={Link} to="/about">
              <span>About</span>
            </Nav.Link>
            <Nav.Link onClick={handleClose} as={Link} to="/contact">
              <span>Contact</span>
            </Nav.Link>
            <Nav.Link onClick={handleClose} as={Link} to="/Job">
              <span>Job</span>
            </Nav.Link>

            <Nav.Link as={Link} onClick={handleClose} to="/privecy">
              <span>Privecy</span>
            </Nav.Link>
            <Nav.Link as={Link} onClick={handleClose} to="/life/uviom-profile">
              <span>Profile</span>
            </Nav.Link>
            {location.pathname.includes("/life") && (
              <Nav.Link as={Link} to="" onClick={() => handleLogout(navigate)}>
                Logout
              </Nav.Link>
            )}
            <Nav.Link as="button" className="btn-0 primary-text">
              <Form.Check
                onChange={handleThemeChange}
                checked={currentTheme === "dark"}
                type="switch"
                label="Dark"
                id="disabled-custom-switch"
              />
            </Nav.Link>
          </div>
        </Offcanvas.Body>
        <SocialLinks OffcanvasShow={show} />
      </Offcanvas>
    </div>
  );
};

export default NavOffCanvas;
