export const SHOW_HIDE_CART = "SHOW_HIDE_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";

export const REMOVE_ITEM = "REMOVE_ITEM";

export const MODEL_TOGGLE = "MODEL_TOGGLE";
export const QUANTITY_ADD = "QUANTITY_ADD";

export const TOTAL_UPDATE = "TOTAL_UPDATE";
