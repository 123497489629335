import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import PayAndReviewModal from "./../Common/Modal/PayAndReviewModal";
import SuccessModal from "./../Common/Modal/SuccessModal";

import uploadIcon from "./../../assets/upload-icon.svg";
import downloadIcon from "./../../assets/download-icon.svg";

import "./receivedProject.css";

function ReceivedProject() {
  const [showPayModal, setShowPayModal] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);

  return (
    <div>
      <PayAndReviewModal
        show={showPayModal}
        onSuccess={() => setSuccessModalShow(true)}
        onHide={() => setShowPayModal(false)}
      />

      <SuccessModal
        title="Payment"
        body="Payment Successful"
        show={successModalShow}
        onHide={() => setSuccessModalShow(false)}
      />

      <div className="receivedProject">
        {/* TODO: Refactor / get from common folder */}
        {Array.from({ length: 2 }).map((_, index) => (
          <div key={index} className="projectPreview">
            <div className="projectPreview__top">
              <img
                src="https://i.ibb.co/F8yxsjF/client1.jpg"
                alt=""
                className="projectPreview__user__img"
              />

              <div className="projectPreview__user">
                <p className="projectPreview__user__name">Ying g</p>
                <p className="projectPreview__user__location">Thailand</p>
                <p className="projectPreview__user__rating">
                  <FontAwesomeIcon icon={faStar} />
                  5.0 <span>(12)</span>
                </p>
              </div>

              <div className=".d-none .d-md-block">
                <div className="border d-inline-block mb-1 p-2 rounded">
                  DELIVERY ORDER JUN-06-2022,EST 10:46 AM
                </div>
                <div className="d-flex justify-content-between">
                  <p>Review Last Date : 12/08/2022</p>
                  <p>Price $100</p>
                </div>
              </div>
            </div>

            <h5>
              The Standard Chunk Of Lorem Ipsum Used Since The 1500s Is
              Reproduced Below For
            </h5>
            <p>
              Company name is bramble & bee. It's a Honey Company Logo. It was
              for a contest that I won here on Freelancer.com. it was a great
              experience doing this logo and working for this contest. Always
              did design
            </p>
            <h6 className="mb-2">Jobs Task</h6>
            <div className="d-flex flex-wrap gap-2 gap-sm-3">
              <span className="creative__skills__badge">UX Research</span>
              <span className="creative__skills__badge">making solution</span>
              <span className="creative__skills__badge">
                information architecture
              </span>
              <span className="creative__skills__badge">wireframe</span>
              <span className="creative__skills__badge">mockup</span>
            </div>

            <div className="projectPreview__buttons">
              <a href="" className="projectPreview__button upload">
                Upload File
                <img src={uploadIcon} alt="" />
              </a>
              <a href="" className="projectPreview__button download">
                Download File
                <img src={downloadIcon} alt="" />
              </a>
              <div
                role="button"
                onClick={() => setShowPayModal(true)}
                className="projectPreview__button pay btn__creative"
              >
                Pay &amp; Review
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default ReceivedProject;
