import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import { useUpdateCommentMutation } from "src/app/services/viewApi/viewPostApi";

const { default: styled } = require("styled-components");

function UpdateComment({ id, parentId, comment, reply, onSubmit }) {
  const commentEditRef = useRef();

  const [updateComment] = useUpdateCommentMutation();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (reply) {
      updateComment({
        id,
        type: "comment_reply",
        parentId,
        comment: { comment_reply: commentEditRef.current.value },
      });
      // console.log("update comment reply");
    } else {
      updateComment({
        id,
        type: "comment",
        comment: { comment: commentEditRef.current.value },
      });
      // console.log("update comment");
    }
    onSubmit();
    // console.log("Hello");
  };

  useEffect(() => {
    commentEditRef.current.focus();
  }, []);

  return (
    <CommentBoxWrapper reply={reply}>
      <img className="avatar" src="https://i.ibb.co/MprdZS6/user.png" alt="" />
      <form method="POST" onSubmit={handleSubmit}>
        <textarea
          ref={commentEditRef}
          className="avatar"
          name="comment"
          rows={1}
          defaultValue={comment}
          placeholder="Add a comment"
        />
        <button type="submit">
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </form>
    </CommentBoxWrapper>
  );
}

export default UpdateComment;

const CommentBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;

  margin-left: ${(props) => (props.reply ? "2rem" : "0")};

  & > .avatar {
    --size: 2.8rem;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
  }

  & > form {
    display: flex;
    flex-basis: 100%;
    align-items: flex-end;

    & > textarea {
      flex-grow: 1;
      border-bottom: 1px solid var(--text-secondary);
      padding: 0.5rem;
    }

    & > button {
      background-color: transparent;
      border: 0;
      font-size: 1.2rem;
    }
  }
`;
