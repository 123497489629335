import React from 'react';
import Avatar from 'src/Projects/Life/Common/Avatar';

const Review = () => {
    return (
        <div className='d-flex align-items-center my-3' style={{gap:"1rem"}}>
            <div className='flex-flex-grow-1'>
                <Avatar size={70} imgUrl={"/user.png"}></Avatar>
            </div>
            <div className="">
                <h6 className='mb-0'>Kamrul Hossain Dolon</h6>
                <p className='mb-0'>
              {[...new Array(5).keys()].map((star) => (
                <span key={star} className={"review-star"}>
                  &#9733;
                  {/* this is star html code &#9733;*/}
                </span>
              ))}
              <span className="review-star">(5.00)</span> 
                </p>
                <p className='mb-0'><small>There are many variations of passages of Lorem Ipsum available</small></p>
            </div>
        </div>
    );
};

export default Review;