import { Link, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";

export const RightColButtons = ({ timlineUrl, ChatContentAction }) => {
  const { pathname } = useLocation();
  const [, dynamicPath, dynamicLife] = pathname.split("/");
  const { user_id } = useParams();

  return (
    <Wrapper className="no-scrollbar">
      <Link
        to={`/${dynamicPath}/${dynamicLife}/${user_id}/about`}
        aria-labelledby="about"
        className="LookMeTypeButton box-shadow justify-content-sm-start ps-sm-5 mt-3"
      >
        <h6 className="mb-0 d-flex align-items-center">About</h6>
      </Link>

      <Link
        to={`/${dynamicPath}/${dynamicLife}/${user_id}/view-photos`}
        aria-labelledby="view photos"
        className="LookMeTypeButton box-shadow justify-content-sm-start ps-sm-5 mt-3  "
      >
        <h6 className="mb-0 d-flex align-items-center ">View Photos</h6>
      </Link>

      <div
        className="LookMeTypeButton box-shadow justify-content-sm-start ps-sm-5 mt-3"
        tabIndex="0"
        aria-labelledby="inbox"
        onClick={ChatContentAction}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            ChatContentAction();
          }
        }}
      >
        <h6 className="mb-0 d-flex align-items-center ">Inbox</h6>
      </div>

      <Link
        to={`/${dynamicPath}/${dynamicLife}/${user_id}/view-videos`}
        aria-labelledby="view videos"
        className="LookMeTypeButton box-shadow justify-content-sm-start ps-sm-5 mt-3"
      >
        <h6 className="mb-0 d-flex align-items-center ">View Videos</h6>
      </Link>

      <Link
        to={`/${dynamicPath}/${dynamicLife}/${user_id}/posts`}
        aria-labelledby="post"
        className="LookMeTypeButton box-shadow justify-content-sm-start ps-sm-5 mt-3"
      >
        <h6 className="mb-0 d-flex align-items-center "> Post</h6>
      </Link>

      {/* 
        <Link to={`/life`}>
        <div className="LookMeTypeButton box-shadow justify-content-sm-start ps-sm-5 mt-3  ">
          <h6 className="mb-0 d-flex align-items-center "> Best Time</h6>
        </div>
        </Link>
       */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  @media (min-width: 62em) {
    padding-bottom: 1rem;
  }

  & .LookMeTypeButton {
    @media (max-width: 62em) {
      border: 1px solid #ddd;
      border-radius: 3rem;

      margin-top: 0rem !important;
      display: inline-block;
      padding: 0.3rem 1rem !important;
      margin-right: 0.7rem;
      margin-bottom: 0.5rem;
    }

    & .d-flex {
      @media (max-width: 62em) {
        align-items: center !important;
      }
    }
  }
`;
