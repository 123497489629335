import React from "react";
import AddressCard from "../Common/AddressCard";

export const Address = ({ info }) => {
  return (
    <div className="my-5">
      <div className="dFlexBetween">
        <div className="adress">
          <h6>Shipping Address</h6>
          <div className="mediumTextSize">
            <AddressCard type="billing" data={info.shipping_address} />
          </div>
        </div>
        <div className="adress">
          <h6>Billing Adress</h6>
          <div className="mediumTextSize">
            <AddressCard type="billing" data={info.billing_address} />
          </div>
        </div>
      </div>
    </div>
  );
};
