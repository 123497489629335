import React from 'react';
import Loader from "react-js-loader";

const BubbleLoader = ({size=80}) => {
    return (
        <div className={"item bubble-loader-center"}>
          <Loader
            type="bubble-scale"
            bgColor={"#f9a31a"}
            color={"#f9a31a"}
            size={size}
          />
        </div>
    );
};

export default BubbleLoader;