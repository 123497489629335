import React from "react";

const ImagePlaceholder = () => {
  return (
    <div className="p-0 w-100 h-100">
      <span className="p-0 w-100 h-100 d-flex justify-content-center align-items-center ">
        <img
          src="/corporate/logo-outline.png"
          alt=""
          className="img img-fluid"
        />
      </span>
    </div>
  );
};

export default ImagePlaceholder;
