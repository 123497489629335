import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  highlightCurrentChatUser,
  storeSingleMessage,
} from "src/features/life/messengerSlice";
import useAuth from "src/hooks/useAuth";
import song1 from "src/Projects/Life/assets/sms_tone.mp3";
import Pusher from "pusher-js";

const PusherClient = () => {
  const { authUser } = useAuth();
  const { messageNotification } = useSelector((state) => state.messengerData);

  const dispatch = useDispatch();
  // music
  let audio = new Audio(song1);

  useEffect(() => {
    if (messageNotification) {
      let context = new AudioContext();
      context.resume().then(() => {});
    } else {
      let context = new AudioContext();
      context.suspend().then(() => {});
    }
  }, [messageNotification]);
  // music end

  useEffect(() => {
    // Pusher.logToConsole = true;
    const pusher = new Pusher("cb2de77aa557c2e98b4d", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("chat");
    channel.bind("send", function (data) {
      const res = data.data;
      const message_content = data?.message_content;

      if (res) {
        if (
          String(res.from_user.id) === String(authUser.id) ||
          String(res.to_user.id) === String(authUser.id)
        ) {
          if (String(res.to_user.id) === String(authUser.id)) {
            dispatch(
              storeSingleMessage({
                response: res,
                message_content,
                type: "other",
                user_id: res.from_user?.id,
              })
            );

            dispatch(
              highlightCurrentChatUser({
                res: res,
                type: "other",
              })
            );

            audio.play();
          } else {
            dispatch(
              storeSingleMessage({
                response: res,
                message_content,
                type: "me",
                user_id: res.to_user?.id,
              })
            );
            dispatch(
              highlightCurrentChatUser({
                res: res,
                type: "me",
              })
            );
          }
        }
      }
    });

    return () => {
      pusher.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default PusherClient;

// uviom2022!
