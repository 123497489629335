import React from 'react'

 const CloudIcon = ({ size = "29px", className = "",fillcolor }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 31 21" fill="black" xmlns="http://www.w3.org/2000/svg">
    <path  className={className} d="M21.34 7.17363C20.5597 7.55919 19.8605 8.05755 19.1872 8.60654L18.0288 7.28124C18.8811 6.49055 19.8749 5.87192 20.943 5.4173C18.7815 1.58813 13.5539 0.586814 10.1069 3.26389C8.85524 4.23298 7.90514 5.68316 7.53972 7.60408L7.42462 8.20429L6.82614 8.31018C6.23973 8.41261 5.71606 8.55418 5.25683 8.73372C2.391 9.84265 1.5865 13.0325 3.33995 15.4546C4.09036 16.4853 5.04679 17.5361 6.30188 17.711H8.05649C8.0444 17.8837 8.03807 18.0575 8.03807 18.233C8.03807 18.6554 8.07375 19.0691 8.14281 19.472H6.27369L6.1632 19.4622C4.40629 19.2389 2.96244 17.9309 1.91912 16.4859C-0.513389 13.1366 0.684161 8.62208 4.62785 7.09249C5.03931 6.93193 5.47954 6.79785 5.94337 6.69023C6.46992 4.59898 7.59208 2.98882 9.03478 1.87126C13.4768 -1.57062 20.1454 -0.103748 22.6895 4.95347C23.0722 4.89304 23.4555 4.86255 23.8358 4.86715C29.5272 4.90916 31.7779 12.1871 28.8246 16.1078C27.6408 17.6782 25.8252 19.0145 23.9555 19.4484L23.7593 19.472H22.6193C22.7183 18.8905 22.7472 18.2994 22.7056 17.711H23.6615C25.0512 17.3767 26.5336 16.2332 27.4153 15.0547C29.5134 12.2602 28.0241 6.64995 23.8238 6.62348C23.0031 6.61657 22.1486 6.81511 21.34 7.17363ZM14.1691 20.9998H16.6183C17.1909 20.9998 17.6605 20.5303 17.6605 19.9577V16.5831H19.4467C19.8231 16.567 20.0901 16.4427 20.2449 16.2079C20.6633 15.5801 20.0918 14.9603 19.6953 14.5235C18.5703 13.2886 16.6327 11.4603 16.0774 10.8066C15.6561 10.3416 15.0576 10.3416 14.6364 10.8066C14.0627 11.4764 12.056 13.4405 10.9862 14.6415C10.615 15.0598 10.1564 15.6296 10.5419 16.2079C10.7008 16.4427 10.9649 16.567 11.3413 16.5831H13.1275V19.9577C13.1275 20.5239 13.5965 20.9998 14.1691 20.9998Z" fill="black" style={{fill:fillcolor}}/>
    </svg>
    
  )
}
export default CloudIcon;
