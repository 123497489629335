import { useEffect, useState } from "react";

export default function useThemeGlobal() {
  const theme = localStorage.getItem("theme");
  const [currentTheme, setCurrentTheme] = useState(theme || "light");

  const handleThemeChange = () => {
    if (currentTheme === "light") {
      localStorage.setItem("theme", "dark");
      setCurrentTheme("dark");
    } else {
      localStorage.setItem("theme", "light");
      setCurrentTheme("light");
    }
  };

  useEffect(() => {
    if (currentTheme === "light") {
      document.documentElement.setAttribute("theme", "light");
    } else {
      document.documentElement.setAttribute("theme", "dark");
    }
  }, [currentTheme]);

  return {
    currentTheme,
    handleThemeChange,
  };
}
