import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

export const CoverArea = () => {
  const fndproFile = "/Life/Cover/Friends.jpg";
  const location = useLocation();

  return (
    <>
      {/* cover ------------ */}
      <div className="row">
        <div className="col-12">
          <div className="position-relative coverArea">
            <div className="coverPic">
              <img
                className="img-fluid rounded-3 "
                src={fndproFile}
                alt="cover area"
              />
              <div className="faIcon display-center">
                <FontAwesomeIcon icon={faCamera} />
              </div>
            </div>
            {!location.pathname.includes("/groups") && (
              <div className="coverProPic">
                <img
                  className="img-fluid rounded-circle  "
                  src="/Life/icons/u_logo.svg"
                  alt="UVIOM"
                />
                <div className="faIcon display-center">
                  <FontAwesomeIcon icon={faCamera} className="" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
