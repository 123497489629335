import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import useAuth from "src/hooks/useAuth";

import CommentIcon from "src/Projects/Life/icons/CommentIcon";
import ShareIcon from "src/Projects/Life/icons/ShareIcon";
import { LikedUserList } from "../../Post/LikedUserList/LikedUserList";
import { ShareActions } from "./ShareActions";

export const LikeCommentShare = ({ data }) => {
  const { authUser } = useAuth();
  const {
    personalLikeUserId,
    isColored,
    colorUpdate,
    postLikeSubmit,
    postMainId,
    postLikeSubmitArg,
    postLikeCount,
    likedUserListArg,
    commentboxClick,
    commentListClick,
    commentCountState,
    comment_reply_count,
    setClickShare,
    clickShare,
    commentloading,
  } = data;
  const totalComment = +commentCountState + comment_reply_count;
  return (
    <div className="LikeCommentShareParent">
      <div className="text-center">
        <div
          tabIndex="0"
          aria-label="like"
          className={`LikeCommentsharePart  ${
            personalLikeUserId === authUser.id ? isColored : ""
          } ${colorUpdate ? "logoColorText" : ""}`}
          onClick={() => {
            postLikeSubmit(postMainId, postLikeSubmitArg);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              postLikeSubmit(postMainId, postLikeSubmitArg);
            }
          }}
        >
          <div className="LikeCommentIcon ">
            <FontAwesomeIcon icon={faThumbsUp} />
          </div>
          <div>
            <small>Like</small>
          </div>
        </div>
        {postLikeCount && postLikeCount !== 0 ? (
          <LikedUserList data={likedUserListArg} />
        ) : null}
      </div>
      <div className="text-center ">
        <div
          tabIndex="0"
          onClick={commentboxClick}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              commentboxClick();
            }
          }}
          aria-label="comment"
          className="LikeCommentsharePart "
        >
          <div className="LikeCommentIcon">
            <CommentIcon className="primary-text" size="22" />
          </div>
          <div className="">
            <small>Comment</small>
          </div>
        </div>
        <div
          tabIndex={0}
          aria-label="see comments"
          className={`smallTextSize  mt-1 lh-1 pointer hoverEffect clickEffect ${
            commentloading ? "disabled" : ""
          }`}
          onClick={commentListClick}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              commentListClick();
            }
          }}
        >
          {totalComment > 0 ? `${totalComment} Comment` : null}
        </div>
      </div>
      <div className="text-center ">
        <div
          tabIndex="0"
          onClick={() => setClickShare(!clickShare)}
          aria-label="share"
          className="LikeCommentsharePart "
        >
          <div className="LikeCommentIcon">
            <ShareIcon size="20" className="primary-text" />
          </div>
          {/* //-------------- */}
          <ShareActions />
        </div>
        {/* <div className="smallTextSize mt-1 lh-1 clickEffect pointer">0 share</div> */}
      </div>
    </div>
  );
};
