
import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Accordion } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import CorporateIcon from "src/Projects/Corporate/assets/icons/CorporateIcon";

const MainMenu = ({ menuitem, handleClose, mainIndex }) => {
  const { pathname } = useLocation();

  return (
    <Accordion.Item eventKey={`${mainIndex}a`} style={{ background: "transparent" }}>
      <div className="d-flex AccordionHeader">
        <Link className={`my-auto mx-2 text-center`} to={`${pathname}/category/category-wise-product/${menuitem.slug}`}>
          {/* <img
            className="img-fluid mx-2 w-50"
            src={"/images/logo.svg"}
            alt=""
          /> */}
          <CorporateIcon size="1.4rem" fill="var(--UbizzIconColor)"/>
        </Link>
        <Accordion.Header>{menuitem.CategoryName}</Accordion.Header>
      </div>
      <Accordion.Body style={{ background: "transparent" }}>
        {menuitem?.has_sub_category?.map((submenuItem) => {
          if (submenuItem.has_sub_sub_category) {
            return (
              <Accordion defaultActiveKey="0" style={{ background: "transparent" }} eventKey={`${mainIndex}b`}>
                <Accordion.Item eventKey={`${mainIndex}b`} style={{ background: "transparent" }}>
                  <div className="d-flex AccordionHeader">
                    <Link className={`my-auto`} to={`${pathname}/category/sub-category-wise-product/${submenuItem.slug}`}>
                      <FontAwesomeIcon icon={faUniversity} size="lg" className="mx-2 text-orange" />
                    </Link>
                    <Accordion.Header>{submenuItem.subcategory_name}</Accordion.Header>
                  </div>

                  <Accordion.Body style={{ background: "transparent" }}>
                    {
                      submenuItem.has_sub_sub_category?.map(subSubItem => <li key={`${mainIndex}cd`} onClick={handleClose}><Link to={`${pathname}/category/sub-sub-category-wise-product/${subSubItem.slug}`}>{subSubItem.sub_sub_category_name}</Link></li>)
                    }
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            );
          } else {
            return <li key={`${mainIndex}b`} onClick={handleClose}><Link to={`${pathname}/category/sub-category-wise-product/${submenuItem.slug}`}>{submenuItem.subcategory_name}</Link></li>
          }

        })}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default MainMenu;