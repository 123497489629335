import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Form, Button } from "react-bootstrap";
import _ from "lodash";
import InputField from "./InputField";

import "./addPackageDetails.css";

function AddPackageDetails() {
  const [packageTitle, setPackageTitle] = useState("Basic $50");
  const [packageSubTitle, setPackageSubTitle] = useState(
    "Smart This Contest. Always Did Design"
  );

  const [packagePoint, setPackagePoint] = useState({
    [_.uniqueId()]: "10 Page",
    [_.uniqueId()]: "XD File",
    [_.uniqueId()]: "Icon",
    [_.uniqueId()]: "Presentation",
    [_.uniqueId()]: "5 Day Delivery",
  });

  const handleInputEdit = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const prevState = { ...packagePoint };
    if (value === "") {
      delete prevState[name];
    } else {
      prevState[name] = value;
    }
    setPackagePoint(prevState);
  };

  const handleSubmitNewInput = (e) => {
    e.preventDefault();
    const value = e.target[0].value;
    const prevState = { ...packagePoint };
    prevState[_.uniqueId()] = value;
    setPackagePoint(prevState);

    e.target[0].value = "";
  };

  return (
    <Row className="mt-3 g-3">
      {/* Input fields */}
      <Col md={8}>
        <div className="border rounded">
          <Form>
            <Form.Control
              className="mt-3 border-0 fw-500 focus-none input-reset"
              type="text"
              onChange={(e) => setPackageTitle(e.target.value)}
              value={packageTitle}
              placeholder="Basic $50"
            />
            <hr />
            <Form.Control
              className="border-0 fw-500 focus-none input-reset"
              type="text"
              onChange={(e) => setPackageSubTitle(e.target.value)}
              value={packageSubTitle}
              placeholder="Smart This Contest. Always Did Design"
            />
            <hr className="mb-1" />

            {/* sub item  */}
            {Object.keys(packagePoint).map((item, index) => {
              const value = packagePoint[item];
              return (
                <InputField
                  key={index}
                  value={value}
                  placeholder={value}
                  name={item}
                  handler={handleInputEdit}
                />
              );
            })}
          </Form>
          <Form className="addsubpoint" onSubmit={handleSubmitNewInput}>
            <Form.Control
              type="text"
              className="border-0 focus-none input-reset "
              placeholder="Add New"
            />
            <button type="submit">
              <FontAwesomeIcon icon={faPlusSquare} />
            </button>
          </Form>
        </div>
      </Col>
      {/* Right Preview  */}
      <Col className="d-none d-md-inline-block" md={4}>
        <div className="border rounded">
          <h6 className="mb-0 p-2">{packageTitle}</h6>
          <hr className="my-0" />
          <p className="fw-500 mb-0 p-2">{packageSubTitle}</p>
          <hr className="mt-0 mb-2" />

          {Object.values(packagePoint).map((value, index) => (
            <p key={index} className="mb-0 py-1 px-2">
              &bull; {value}
            </p>
          ))}
        </div>
      </Col>
    </Row>
  );
}
export default AddPackageDetails;
