/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { Accordion, Form } from "react-bootstrap";
import InputWithLabel from "src/Common/InputWithLabel/InputWithLabel";
import { useNavigate, useLocation } from "react-router-dom";
import {
  updateApplicantProfileUrl,
  applicantResumeUploadUrl,
} from "../../utilities/job_commonUrl";
import postData from "../../api_call/postApiCall";
import { compare_2_Object } from "src/utilities/utilities";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import AddEducationForm from "./AddEducationForm";
import InputFileWithLabel from "../../Components/Common/InputFileWithLabel";
import fileUploadToServer from "../../api_call/fileUploadApiCall";
import useTheme from "src/hooks/useTheme";
import BubbleLoader from "src/Common/BubbleLoader/BubbleLoader";
import LocationSelectInput from "../../Components/Common/LocationSelectInput";
import { useDispatch, useSelector } from "react-redux";
import { deleteAlert, getDivisionByDistricts } from "../../utilities/functions";
import { useDeleteEducationMutation } from "src/app/services/jobApi/jobPostApi";
import { getApplicantProfile } from "src/features/job/jobProfileSlice";

const ApplicantProfileUpdate = () => {

  const [deleteEducation, { isLoading, isSuccess: edu_success }] = useDeleteEducationMutation()
  const dispatch = useDispatch();

  const { state } = useLocation();
  const { applicant_profile} = useSelector((store) => store.jobProfile);
  const prevProfileData = { ...applicant_profile };
  const [userProfile, setUserProfile] = useState({...applicant_profile});
  const [educations, setEducations] = useState(state.educations || []);
  const [resumeFile, setResumeFile] = useState("");
  const [fileError, setFileError] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [dataUpdate_status, setDataUpdate_status] = useState(false);
  const [resumeUpdate_status, setResumeUpdate_status] = useState(false);
  const navigate = useNavigate();
  const educationAddBtnRef = useRef();
  const [districts, setDistricts] = useState([]);

  const {
    applicant_name,
    qualification,
    permanent_address,
    present_address,
    contact_email,
    contact_phone,
    experiences,
    expected_salary,
    website,
    cv_attachment,
    district_id,
    division_id,
  } = userProfile;

  const [resumeFileName, setResumeFileName] = useState(cv_attachment); // for showing selected file name

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const prevState = userProfile;
    prevState[name] = value;
    setUserProfile({ ...prevState });
  };

  const handleFileInputChange = (e) => {
    const fileType = [
      "application/pdf",
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/svg",
    ];
    const file = e.target.files[0];
    if (file) {
      if (file && fileType.includes(file.type)) {
        setResumeFile(file);
        setResumeFileName(file.name);
        setFileError("");
      } else {
        setFileError("Please select valid pdf or image File");
      }
    }
  };
  useEffect(() => {
    const districts = getDivisionByDistricts(division_id) || [];
    setDistricts(districts);
  }, [division_id]);

  const callAfterDataUpdateSuccess = () => {
    setDataUpdate_status(true);
  };
  const callAfterResumeUpdateSuccess = () => {
    setResumeUpdate_status(true);
  };

  const uploadAttachmentFile = () => {
    const formData = new FormData();

    if (resumeFile) {
      formData.append("cv_attachment", resumeFile);
      const fileUploadArg = {
        Url: applicantResumeUploadUrl,
        formData: formData,
        setIsLoading,
        setError: setFileError,
        setSuccess,
        callAfterSuccess: callAfterResumeUpdateSuccess,
      };

      fileUploadToServer(fileUploadArg);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      compare_2_Object(prevProfileData, userProfile) &&
      cv_attachment === resumeFileName
    ) {
      setSuccess(
        "Profile UP to Date. Change some information to update Profile"
      );
    } else {
      if (compare_2_Object(prevProfileData, userProfile) === false) {
        const postDataArg = {
          url: updateApplicantProfileUrl,
          data: userProfile,
          setIsLoading,
          setError,
          setSuccess,
          callAfterSuccess: callAfterDataUpdateSuccess,
        };
        postData(postDataArg);
      } else {
        callAfterDataUpdateSuccess();
      }
      if (cv_attachment !== resumeFileName) {
        uploadAttachmentFile();
      } else {
        callAfterResumeUpdateSuccess();
      }
    }
  };

  
  // education delete function 
  const dataReloadAfterDelete = (id) => {
    const remainingData = educations.filter((item) => item.id !== id);
    setEducations([...remainingData]);
  };
  const [deletedId, setDeletedId] = useState(null);
  const handleEducationDelete = (id) => {
    deleteAlert().then((result) => {
      if (result.isConfirmed) {
        deleteEducation(id);
        setDeletedId(id);
     }
   })
    
  };
  useEffect(() => {
    if (edu_success) {
      dataReloadAfterDelete(deletedId)
    }
  }, [edu_success])


  const handleEducationEdit = (data) => {
    navigate({
      pathname: "/Job/applicant-education-update",
      state: { data },
    });
  };

  useEffect(() => {
    if (dataUpdate_status && resumeUpdate_status) {
      dispatch(getApplicantProfile());
      navigate("/Job/applicant-profile");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdate_status, resumeUpdate_status]);

  const { currentTheme } = useTheme();

  const columns = [
    {
      name: "Institute Name",
      selector: (row) => row.institute,

      minWidth: "15rem",
    },
    {
      name: "Location",
      selector: (row) => row.location,
      minWidth: "15rem",
    },
    {
      name: "Board",
      selector: (row) => row.board,
    },
    {
      name: "Exam Name",
      selector: (row) => row.exam_name,
      minWidth: "8rem",
    },
    {
      name: "Year",
      selector: (row) => row.year,
    },
    {
      name: "Result",
      selector: (row) => row.result,
    },
    {
      name: "Subject Group",
      selector: (row) => row.subject_group,
    },
    {
      name: "Level",
      selector: (row) => row.level,
    },
    {
      name: "Action",
      button: true,
      selector: (row) => (
        <>
          <button
            type="button"
            className="eco-btn"
            onClick={() => handleEducationDelete(row.id)}
          >
            <FontAwesomeIcon className="text-orange ms-2" icon={faTrashAlt} />
          </button>
          <button
            type="button"
            className="eco-btn"
            onClick={() => handleEducationEdit(row)}
          >
            <FontAwesomeIcon className=" ms-2" icon={faPencilAlt} />
          </button>
        </>
      ),
    },
  ];

  return (
    <div>
      {(loading || isLoading) && <BubbleLoader />}
      <div className="applicantTitle">
        <h6>Update Applicant Profile</h6>
      </div>
      <br />
      <div className="updateCompanyDetail overflow-hidden">
        <br />
        <Form onSubmit={handleSubmit}>
          <InputWithLabel
            value={applicant_name}
            className={["bordaredInput", "text-start ps-4"]}
            label="Name"
            name="applicant_name"
            handler={handleInputChange}
            placeholder="Name"
          />
          <InputWithLabel
            value={qualification}
            className={["bordaredInput", "text-start ps-4"]}
            label="Qualification"
            name="qualification"
            handler={handleInputChange}
            placeholder="Qualification"
          />
          <InputWithLabel
            value={experiences}
            className={["bordaredInput", "text-start ps-4"]}
            label="Experiences"
            name="experiences"
            handler={handleInputChange}
            placeholder="Experiences"
          />

          <InputWithLabel
            value={expected_salary}
            label="Expected Salary"
            className={["bordaredInput", "text-start ps-4"]}
            name="expected_salary"
            handler={handleInputChange}
            placeholder="Expected Salary"
            type="number"
          />
          <InputWithLabel
            value={contact_email}
            label="Contact Email"
            className={["bordaredInput", "text-start ps-4"]}
            name="contact_email"
            handler={handleInputChange}
            placeholder="Contact Email"
          />
          <InputWithLabel
            value={contact_phone}
            label="Phone"
            className={["bordaredInput", "text-start ps-4"]}
            name="contact_phone"
            handler={handleInputChange}
            placeholder="Contact Phone"
          />
          <InputWithLabel
            value={website}
            label="Website"
            className={["bordaredInput", "text-start ps-4"]}
            name="website"
            handler={handleInputChange}
            placeholder="website"
          />
          <LocationSelectInput
            className={["bordaredInput", "text-start ps-4"]}
            label="Location"
            handler={handleInputChange}
            district_value={district_id}
            division_value={division_id}
          >
            <option value="" disabled>
            Select District
            </option>
            {districts.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </LocationSelectInput>

          <InputWithLabel
            value={present_address}
            label="Present Address"
            className={["bordaredInput", "text-start ps-4"]}
            name="present_address"
            handler={handleInputChange}
            placeholder="Present Address"
          />
          <InputWithLabel
            value={permanent_address}
            label="Permanent Address"
            className={["bordaredInput", "text-start ps-4"]}
            name="permanent_address"
            handler={handleInputChange}
            placeholder="Permanent Address"
          />
          <InputFileWithLabel
            value={resumeFileName?.substr(0, 25) || "No File Selected"}
            label="CV Attach"
            name="cv_attachment"
            handler={handleFileInputChange}
            accept="application/pdf, .jpg, .jpeg, .png"
            className="text-start ps-4"
          />

          {educations.length > 0 && (
            <>
              <DataTable
                columns={columns}
                data={educations}
                responsive
                theme={currentTheme}
                pointerOnHover
              />
            </>
          )}
          <br />

          <Accordion className="add-education-form">
            <Accordion.Item eventKey="0">
              <Accordion.Header ref={educationAddBtnRef}>
                <FontAwesomeIcon
                  className="me-2"
                  icon={faPlus}
                ></FontAwesomeIcon>
                Add Education
              </Accordion.Header>
              <Accordion.Body>
                <AddEducationForm
                  btnRef={educationAddBtnRef}
                  applicant_name={applicant_name}
                ></AddEducationForm>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <p className="text-end text-danger pt-0 mt-0">
            <small>{fileError}</small>
          </p>

          <div className="font-14">
            <p className="text-center text-danger">{error}</p>
            {success && <p className="text-end text-orange">{success}</p>}
          </div>
          <div className="d-flex justify-content-end">
            <div>
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="send-code-btn me-4"
              >
                Cancel
              </button>
              <input
                type="submit"
                value="Update Profile"
                className="send-code-btn cart-confirm-btn text-dark"
              />
            </div>
          </div>
        </Form>
        <br />
      </div>
      <br />
      <br />
    </div>
  );
};

export default ApplicantProfileUpdate;
