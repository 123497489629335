import React from "react";
import { getFullDate } from "./message_apiCall";

const all_days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function LastMessageTime({ create_at, prev_create_at = "1/1/2022" }) {
  const this_item_date = new Date(create_at);
  const prev_item_date = new Date(prev_create_at);
  const time_now = new Date();

  //   const this_item_sec = Math.round(this_item_date.getTime() / 1000);
  //   const prev_item_sec = Math.round(prev_item_date.getTime() / 1000);
  //   const time_now_sec = Math.round(time_now.getTime() / 1000);

  const this_item_day =
    (this_item_date.getYear() - 100) * 365 +
    this_item_date.getMonth() * 30 +
    this_item_date.getDate();
  const prev_item_day =
    (prev_item_date.getYear() - 100) * 365 +
    prev_item_date.getMonth() * 30 +
    prev_item_date.getDate();
  const now_day =
    (time_now.getYear() - 100) * 365 +
    time_now.getMonth() * 30 +
    time_now.getDate();

  //   const time_diff = time_now_sec - this_item_sec;
  //   const time_array = [3600, 86400, 604800];

  //   const hours = Math.round(time_diff / time_array[0]);
  //   const days = Math.round(time_diff / time_array[1]);
  //   const weeks = Math.round(time_diff / time_array[2]);

  if (now_day - this_item_day < 7) {
    if (now_day - this_item_day === 1 && this_item_day !== prev_item_day) {
      return (
        <p className="text-center message_full_date">
          <span>Yesterday</span>
        </p>
      );
    } else if (
      now_day - this_item_day === 0 &&
      this_item_day !== prev_item_day
    ) {
      return (
        <p className="text-center message_full_date">
          <span>Today</span>
        </p>
      );
    } else if (this_item_day !== prev_item_day) {
      return (
        <p className="text-center message_full_date">
          <span>{all_days[this_item_date.getDay()]}</span>
        </p>
      );
    }
  } else if (this_item_date.getDate() !== prev_item_date.getDate()) {
    return (
      <p className="text-center message_full_date">
        <span>{getFullDate(create_at)}</span>
      </p>
    );
  }

  return null;
}

export default LastMessageTime;
