import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";

const SearchBox = ({
  placeholder = "",
  value ,
  onChange,
  handleSubmit,
  inputName,
  onKeyPress
}) => {
  return (
    <InputGroup className="profileVideoSearchInputGroup">
      <InputGroup.Text
        onClick={handleSubmit}
        className="profileVideoSearchLabel"
      >
        <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
      </InputGroup.Text>
      <FormControl
        onChange={onChange}
        value={value}
        name={inputName}
        onKeyPress={onKeyPress}
        aria-label="search"
        placeholder={placeholder}
        className="profileVideoSearchInput"
      />
    </InputGroup>
  );
};

export default SearchBox;
