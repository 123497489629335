import AuthProvider from "./context/AuthProvider";
import StoreProvider from "./context/StoreProvider";

import { Routes } from "./Routes/routes";
import "@fortawesome/fontawesome-svg-core/styles.css";
import ThemeProvider from "./context/ThemeProvider";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import PusherClient from "./Common/Pusher/PusherClient";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 150,
      duration: 600,
      mirror: true,
    });
  }, []);

  const fakeAuth = localStorage.getItem("uPass");

  if (!fakeAuth && window.location.pathname !== "/howDoYouKnow") {
    window.location.replace("/howDoYouKnow");
  }

  return (
    <AuthProvider>
      <StoreProvider>
        <ThemeProvider>
          <PusherClient />
          <Routes />
          {/* ai jaygate massege (App.js )*/}
          {/* <Footer/> */}
        </ThemeProvider>
      </StoreProvider>
    </AuthProvider>
  );
}

export default App;
