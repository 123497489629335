import React from "react";
import { Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import SmallVideoCard from "../VideoPost/Common/SmallVideoCard";

const VideoGalleryCard = ({ url, id }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [, dynamicPath, dynamicLife] = pathname.split("/");

  const handleClick = (e) => {
    navigate(
      `/${dynamicPath}/${dynamicLife}/video-gallery/view-video/${id}?url=${url}`
    );
  };
  return (
    <Col className="p-0" style={{ zIndex: 1 }}>
      <div className="profileSingleVideoCard m-2 rounded-2">
        <div className="rounded-2" onClick={handleClick}>
          <SmallVideoCard
            url={url}
            // thumbnail="https://i.ibb.co/McYPThJ/sydney.jpg"
          />
        </div>

        <div className="profileSingleVideoText mt-2">
          <p className="">
            Yah absolutely making me worried about UVIOM, what's the NEXT
          </p>
        </div>
        <div className="profileSingleVideoInfoText mt-1">
          <p className="pe-2 my-1">2k Views</p>
          <p className="ps-2 my-1">2h ago</p>
        </div>
      </div>
    </Col>
  );
};

export default VideoGalleryCard;
