import ShowVideos from "./ShowVideos";
import "./ViewPhotoAndVIdeos.css";

const ViewVideos = () => {
  return (
    <div>
      <div className="photoVideoDiv">
        <ShowVideos />
      </div>
    </div>
  );
};

export default ViewVideos;
