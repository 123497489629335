import LayoutWrapper from "./LayoutWrapper";

function About() {
  return (
    <LayoutWrapper>
      <div className="text-center my-4 px-3 mx-auto mw-[40rem]">
        <h5>About Us</h5>
        <p>
          We are UVIOM. A startup TechLab company, first of its kind to bolster
          the technological needs worldwide. Our goal is to improve the lives
          and career outputs of impoverished nations and get them to a starting
          point of technology in the 21st century.
        </p>
      </div>
    </LayoutWrapper>
  );
}
export default About;
