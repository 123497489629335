import React from "react";
import { Dropdown } from "react-bootstrap";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";

export const PostActions = () => {
  return (
    <div className="MoreOption">
      <Dropdown>
        <Dropdown.Toggle
          tabIndex={0}
          aria-label="toggle button"
          className="ToggleDesignClear"
        >
          <ThreeDots size="20" />
        </Dropdown.Toggle>
        <Dropdown.Menu variant="dark">
          <div className="ClickThreeDot mb-3 px-3 pb-3 pt-3">
            <div className="btnHoverBorder">
              <div className=" mt-1">
                <h6>Save Post</h6>
              </div>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {/* <PostModal postModalArg={postModalArg} /> */}
    </div>
  );
};
