import React, { useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronDown,
  faChevronUp,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { timeAgo } from "src/utilities/utilities";
import styled from "styled-components";
import { channelLogoImg } from "../../common/view_commonUrl";
import { CommentBox } from "./CommentBox";
import { SingleCommentReply } from "./SingleCommentReply";
import {
  useDeleteCommentMutation,
  useUpdateCommentLikeStatusMutation,
} from "src/app/services/viewApi/viewPostApi";
import useAuth from "src/hooks/useAuth";
import VideoCommentsReply from "./VideoCommentsReply";
import UpdateComment from "./UpdateComment";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import { useMemo } from "react";

export const SingleComment = ({
  comment,
  createdAt,
  userName,
  userId,
  userPhoto,
  commentLiked,
  replyCount,
  isLiked,
  id,
}) => {
  const [showReply, setShowReply] = useState(false);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showCommentEdit, setShowCommentEdit] = useState(false);
  const [commentLikeStatus] = useUpdateCommentLikeStatusMutation();
  const [deleteComment] = useDeleteCommentMutation();
  const { authUser } = useAuth();

  const getTimeAgo = useMemo(() => timeAgo(createdAt), [createdAt]);

  return (
    <>
      {showCommentEdit ? (
        <UpdateComment
          id={id}
          comment={comment}
          onSubmit={() => setShowCommentEdit(false)}
        />
      ) : (
        <SingleCommentWrapper>
          <img
            className="avatar"
            src={
              userPhoto
                ? channelLogoImg + userPhoto
                : "https://i.ibb.co/MprdZS6/user.png"
            }
            alt=""
          />
          <div className="comment__container">
            <div className="d-flex text-center">
              <div className="me-2 fw-500"> {userName}</div>
              <div className="mediumTextSize text-gray">{getTimeAgo}</div>
            </div>
            <p className="comment">
              {comment} {id}
            </p>
            <div className="mt-1 text-gray">
              <div className="d-flex">
                <div
                  role="button"
                  className="me-3"
                  onClick={() => {
                    commentLikeStatus({ id, type: "comment" });
                  }}
                >
                  <FontAwesomeIcon icon={faThumbsUp} className="me-1" />
                  {commentLiked} {parseInt(isLiked) ? "Liked" : "Like"}
                </div>
                <div className="me-3">||</div>
                <div
                  onClick={() => setShowCommentBox(!showCommentBox)}
                  role="button"
                  className="me-3"
                >
                  Reply
                </div>
              </div>
              {parseInt(replyCount) >= 1 && (
                <div
                  role="button"
                  className="replies"
                  onClick={() => setShowReply(!showReply)}
                >
                  {showReply ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                  {replyCount} Replies
                </div>
              )}
            </div>
          </div>
          {parseInt(userId) === authUser.id && (
            <div className="ms-auto">
              <Dropdown>
                <Dropdown.Toggle
                  aria-labelledby="toggle button"
                  className="ToggleDesignClear"
                >
                  <ThreeDots className="topNavIcon" size="15" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => setShowCommentEdit(!showCommentEdit)}
                  >
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      deleteComment({ commentId: id, type: "comment" })
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </SingleCommentWrapper>
      )}

      {showCommentBox && (
        <CommentBox
          reply
          onSubmit={() => {
            setShowCommentBox(false);
          }}
          id={id}
        />
      )}

      {showReply && <VideoCommentsReply commentId={id} />}
    </>
  );
};

const SingleCommentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-block: 0.7rem;

  & > .avatar {
    --size: 2.8rem;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
  }

  & .comment {
    margin: 0;
  }

  & .replies {
    font-size: 80%;
    margin-top: 0.3rem;
    display: inline-block;
    border-radius: 3rem;
    padding: 0.1rem 0.5rem;
    background-color: #6984a524;
    border: 1px solid #6984a5fd;

    & > svg {
      margin-right: 0.2rem;
    }
  }
`;
