import axios from "axios";
import { mainCoverPUrl, mainProPUrl } from "src/Projects/Life/Common/CommonUrl";

export const getfmPhotos = (PicturesUrl, setPhoto) => {
  axios
    .get(`${PicturesUrl}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      if (res.data.status === 1) {
        const response = res.data;
        setPhoto({
          profile: response.data.profile_photo
            ? `${response.base_url}/${response.profile_image_location}${response.data.profile_photo}`
            : null,
          cover: response.data.cover_photo
            ? `${response.base_url}/${response.cover_image_location}${response.data.cover_photo}`
            : null,
        });
      } else {
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getfnPhotos = (PicturesUrl, setPhoto) => {
  axios
    .get(`${PicturesUrl}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      if (res.data.status === 1) {
        const response = res.data;
        setPhoto({
          profile: response.data.profile_photo
            ? `${response.base_url}/${response.profile_image_location}${response.data.profile_photo}`
            : "",
          cover: response.data.cover_photo
            ? `${response.base_url}/${response.cover_image_location}${response.data.cover_photo}`
            : "",
        });
      } else {
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getProPhotos = (PicturesUrl, setPhoto) => {
  axios
    .get(`${PicturesUrl}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      if (res.data.status === 1) {
        const response = res.data;
        setPhoto({
          profile: response.data.profile_photo
            ? `${response.base_url}/${response.profile_image_location}${response.data.profile_photo}`
            : "",
          cover: response.data.cover_photo
            ? `${response.base_url}/${response.cover_image_location}${response.data.cover_photo}`
            : "",
        });
      } else {
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getMainPhotos = (PicturesUrl, setPhoto) => {
  axios
    .get(`${PicturesUrl}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        const response = res.data;

        setPhoto({
          profile: response.user.photo
            ? `${mainProPUrl}/${response.user.photo}`
            : "",
          cover: response.cover_photo
            ? `${mainCoverPUrl}/${response.cover_photo}`
            : "",
        });
      } else {
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};
