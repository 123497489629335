import React from "react";
import { Col, Row } from "react-bootstrap";
import "./ViewPhotoAndVIdeos.css";
import { useLocation } from "react-router-dom";
const ShowVideos = () => {
  const { pathname } = useLocation();
  return (
    <div className="container-fluid bg-footer py-4 rounded-4 showVideos">
      <Row>
        {!pathname.match("/public-life") ? (
          <>
            <Col xs={6} md={4} className="videoBox ">
              <img
                className="img-fluid imgFolder"
                src="https://i.ibb.co/nDQ2Yy7/add-photo.png"
                alt="add to album"
              />
              <div className="mt-1">
                <small> Add Album</small>
              </div>
            </Col>
          </>
        ) : null}
        <Col xs={6} md={4} className=" px-2  rounded-3 videoBox">
          <img
            className="img-fluid rounded-3"
            src="https://i.ibb.co/BLfc2tn/Video-Thumbna.jpg"
            alt="video"
          />
          <div className="mt-1">
            <small> Album Name</small>
          </div>
        </Col>
        <Col xs={6} md={4} className=" px-2  rounded-3 videoBox">
          <img
            className="img-fluid rounded-3"
            src="https://i.ibb.co/BLfc2tn/Video-Thumbna.jpg"
            alt="video"
          />
          <div className="mt-1">
            <small> Album Name</small>
          </div>
        </Col>
        <Col xs={6} md={4} className=" px-2  rounded-3 videoBox">
          <img
            className="img-fluid rounded-3"
            src="https://i.ibb.co/BLfc2tn/Video-Thumbna.jpg"
            alt="video"
          />
          <div className="mt-1">
            <small> Album Name</small>
          </div>
        </Col>
        <Col xs={6} md={4} className=" px-2  rounded-3 videoBox">
          <img
            className="img-fluid rounded-3"
            src="https://i.ibb.co/BLfc2tn/Video-Thumbna.jpg"
            alt="video"
          />
          <div className="mt-1">
            <small> Album Name</small>
          </div>
        </Col>
        <Col xs={6} md={4} className=" px-2  rounded-3 videoBox">
          <img
            className="img-fluid rounded-3"
            src="https://i.ibb.co/BLfc2tn/Video-Thumbna.jpg"
            alt="video"
          />
          <div className="mt-1">
            <small> Album Name</small>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ShowVideos;
