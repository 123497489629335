import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

function SuccessModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="creative__modal__header" closeButton>
        <h6 className="mb-0">{props.title}</h6>
      </Modal.Header>
      <Modal.Body className="creative__modal__body">
        <div className="text-center">
          <FontAwesomeIcon
            className="text-orange mb-3 creative__modal__success-icon"
            icon={faCheckCircle}
          />
          <h5 className="mb-4">{props.body}</h5>
          <Button className="btn__creative px-5 my-3" onClick={props.onHide}>
            Ok
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default SuccessModal;
