import React from "react";
import { useNavigate } from "react-router-dom";
import { TimeLines } from "../../Components/LeftCol/TimeLines";
import { RightColButtons } from "../../Components/RightCol/RightColButtons";

const MoreOption = ({ timlineUrl }) => {
  const navigate = useNavigate();
  const dashboard_Logo = "/images/dashboard_Logo.png";

  return (
    <div className="d-block  d-lg-none">
      {/* leftCol timeLines----------------------------- */}
      <div className="mb-3">
        {/* dashboard  */}
        <div
          onClick={() => navigate("/dashboard")}
          className="LookMeTypeButton d-none d-lg-block"
        >
          <div className="LookMeButtonTypeIcon">
            <img className="img-fluid " src={dashboard_Logo} alt="logo" />
          </div>
          <div className="mt-3 ms-2">
            <h6>UVIOM</h6>
          </div>
        </div>
        <TimeLines />
      </div>
      {/* RightCol Buttons ----------------------- */}
      <RightColButtons
        timlineUrl={timlineUrl}
        dashboard_Logo={dashboard_Logo}
      />
      {/*RightCol Buttons   end  */}
    </div>
  );
};

export default MoreOption;
