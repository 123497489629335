import axios from "axios";

export const singleGetInfo = (setIsLoading, Url, setInfo,id) => {
    setIsLoading(true);
  
    axios.get(`${Url}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((res) => {
        if (res.data.status === 1) {
          const response = res.data.data;
          setInfo(response);
        } else { }
        setIsLoading(false);
  
      }).catch((err) => {
        console.log(err.message);
        setIsLoading(false);
  
      });
    setIsLoading(false);
  
  }