import React from "react";
import { Container } from "react-bootstrap";
import { Navigate, Route, Routes as Switch } from "react-router-dom";
import FoodNavigation from "./Components/FoodNavigation/FoodNavigation";
import FoodFooter from "./Components/FoodFooter/FoodFooter";
import FoodHome from "./FoodPages/Home/FoodHome";
import "./food.css";
import RestaurantDetails from "./FoodPages/RestaurantDetails/RestaurantDetails";
import Cart from "./FoodPages/Cart/Cart";
import FoodStoreProvider from "./context/FoodStoreProvider";
import MyProfile from "./FoodPages/MyProfile/MyProfile";
import Foods from "./FoodPages/Foods/Foods";
import Restaurants from "./FoodPages/Restaurants/Restaurants";
import SearchResult from "./FoodPages/SearchPage/SearchResult";
import Contact from "./FoodPages/Contact/Contact";

const Food = () => {
  return (
    <Container
      id="food-main"
      fluid
      style={{ minHeight: "100vh" }}
      className="p-0"
    >
      <FoodStoreProvider>
        <FoodNavigation></FoodNavigation>
        <div className="eco-container ">
          <Switch>
            <Route index element={<Navigate to="home" />} />

            <Route path={`home`} element={<FoodHome />} />

            <Route
              path={`restaurant-details/:restaurantId`}
              element={<RestaurantDetails />}
            />

            <Route path={`cart`} element={<Cart />} />

            <Route path={`my-profile/*`} element={<MyProfile />} />

            <Route path={`foods/*`} element={<Foods />} />

            <Route path={`restaurants`} element={<Restaurants />} />

            <Route path={`search`} element={<SearchResult />} />

            <Route path={`contact`} element={<Contact />} />
          </Switch>
        </div>
        <FoodFooter></FoodFooter>
      </FoodStoreProvider>
    </Container>
  );
};

export default Food;
