import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { createFFmpeg } from "@ffmpeg/ffmpeg";
import { useCreateVideoPostMutation } from "src/app/services/viewApi/viewPostApi";
import {
  get_AWS_file_name,
  get_AWS_file_upload_params,
} from "src/utilities/utilities";
import {
  AWS_ACCESS_ID,
  AWS_BUCKET_NAME,
  AWS_REGION_NAME,
  AWS_SECRET_KEY,
} from "../../common/view_commonUrl";
import { ModalBody1 } from "./ModalBody1";
import { ModalBody2 } from "./ModalBody2";
import { ModalBody3 } from "./ModalBody3";
import { ModalHeader } from "./ModalHeader";
import { StepWizard } from "./StepWizard";

import { compress_video, process_progress } from "src/utilities/compress_video";

import AWS from "aws-sdk";
import { getVideoDuration } from "src/utilities/getVideoThumbnail";
import compress_image from "src/utilities/compress_image";

import "./uploadVideoModal.css";

const uviom_view_bucket = new AWS.S3({
  params: { Bucket: AWS_BUCKET_NAME },
  region: AWS_REGION_NAME,
  accessKeyId: AWS_ACCESS_ID,
  secretAccessKey: AWS_SECRET_KEY,
});

const initialPostData = {
  title: "",
  description: "",
  playlist_id: "",
  restriction: "18+",
  kids: "yes",
  tag: "",
  video_category_id: "",
  video_status: "public",
};

function UploadVideoModal({ show, handleClose }) {
  const [errorMsg, setErrorMsg] = useState("");
  const [currentStatus, setCurrentStatus] = useState(1);
  const [postData, setPostData] = useState(initialPostData);

  const [isLoading, setIsLoading] = useState();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState(null);

  const headerTitle = "Upload Video";

  const [postVideo] = useCreateVideoPostMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !postData.title ||
      !postData.description ||
      !postData.playlist_id ||
      !postData.restriction ||
      !postData.kids ||
      !postData.tag ||
      !postData.video_category_id ||
      !postData.video_status
    ) {
      setErrorMsg("All Filed's are required");
      return;
    } else {
      setErrorMsg();
    }

    const video_file_name = `${get_AWS_file_name()}video.mp4`;
    const aws_video_file_name = "images/channel/videos/" + video_file_name;

    const video_thumbnail_file_name = `${get_AWS_file_name()}thumb.jpeg`;
    const aws_video_thumbnail_file_name =
      `images/channel/video_thumbnail/` + video_thumbnail_file_name;

    setIsLoading("Creating...");

    const ffmpeg = createFFmpeg({
      corePath: `${window.location.origin}/ffmpeg/ffmpeg-core.js`,
    });

    let duration = await getVideoDuration(selectedVideo);
    if (typeof duration !== "number") duration = 10;

    ffmpeg.setLogger((_logger) => {
      const result = process_progress(_logger.message, parseInt(duration));
      if (result !== duration) {
        setIsLoading(`Processing... ${result}%`);
      }
    });

    // compressing video file
    const compress_video_file = await compress_video(selectedVideo, ffmpeg);
    const compressed_images = await compress_image([videoThumbnail]);

    const aws_video_params = get_AWS_file_upload_params(
      compress_video_file,
      aws_video_file_name
    );

    const aws_thumb_params = get_AWS_file_upload_params(
      compressed_images[0],
      aws_video_thumbnail_file_name
    );

    uviom_view_bucket
      .putObject(aws_video_params)
      .on("httpUploadProgress", (evt) => {
        const progress = Math.round((evt.loaded / evt.total) * 100);
        setIsLoading(`Uploading.. ${progress}%`);

        if (progress === 100) {
          uviom_view_bucket
            .putObject(aws_thumb_params)
            .on("httpUploadProgress", (evt) => {
              const progress = Math.round((evt.loaded / evt.total) * 100);

              if (progress === 100) {
                setIsLoading("Posting...");
              }
            })
            .send((err) => {});
        }
      })
      .send((err) => {
        postVideo({
          ...postData,
          key_search: postData.tag,
          video: video_file_name,
          thumbnail: video_thumbnail_file_name,
        });
        setTimeout(() => {
          handleClose();
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      });
  };

  const handleCancel = () => {
    setIsLoading("canceling Upload");

    setTimeout(() => {
      setIsLoading(null);
      handleClose();
    }, 2000);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    const newData = { ...postData };
    newData[name] = value;
    setPostData(newData);
  };

  const nextClickHandle = () => {
    if (currentStatus < 3) {
      if (currentStatus === 1) {
        if (selectedVideo && videoThumbnail) {
          setCurrentStatus((prev) => prev + 1);
          setErrorMsg("");
        } else {
          setErrorMsg("Video And Thumbnail Are Required");
        }
      } else if (currentStatus === 2) {
        if (postData.title && postData.description && postData.tag) {
          setErrorMsg("");
          setCurrentStatus((prev) => prev + 1);
        } else {
          setErrorMsg("Title, Description and Tags are Required");
        }
      }
    } else {
      // setCurrentStatus(1)
    }
  };
  const prevClickHandle = () => {
    if (currentStatus > 1 && currentStatus <= 3) {
      setCurrentStatus((prev) => prev - 1);
      setErrorMsg("");
    }
  };
  return (
    <Modal show={show} className="fullScreen viewModal" onHide={handleClose}>
      {isLoading ? (
        <h5 className="text-center fw-500 py-5">{isLoading}</h5>
      ) : (
        <>
          <ModalHeader handleClose={handleClose} headerTitle={headerTitle} />
          <Modal.Body className="div_center">
            <div className="container-fluid">
              <StepWizard currentStatus={currentStatus} />
              {currentStatus === 1 ? (
                <ModalBody1
                  selectedVideo={selectedVideo}
                  setSelectedVideo={setSelectedVideo}
                  videoThumbnail={videoThumbnail}
                  setVideoThumbnail={setVideoThumbnail}
                />
              ) : null}
              {currentStatus === 2 ? (
                <ModalBody2 postData={postData} handleChange={handleChange} />
              ) : null}
              {currentStatus === 3 ? (
                <ModalBody3
                  postData={postData}
                  setPostData={setPostData}
                  handleChange={handleChange}
                />
              ) : null}
            </div>
          </Modal.Body>
          {errorMsg && <div className="mx-auto text-danger"> {errorMsg}</div>}

          <Modal.Footer>
            {currentStatus > 1 && (
              <Button
                className="fileModalButton bg-light text-dark  px-lg-4"
                onClick={prevClickHandle}
              >
                {" << "} Back
              </Button>
            )}
            {currentStatus > 2 ? (
              <Button
                className="  bg-warning fileModalButton  px-lg-4 "
                onClick={handleSubmit}
              >
                Upload
              </Button>
            ) : (
              <Button
                className="  bg-warning fileModalButton  px-lg-4 "
                onClick={nextClickHandle}
              >
                Next{" >>"}
              </Button>
            )}
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}

export default UploadVideoModal;
