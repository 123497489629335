import React from 'react';
import { useLocation } from 'react-router-dom';
import SingleJobDetails from './SingleJobDetails';

const JobPostDetails = () => {
    const { state } = useLocation();
    return (
        <>
           <SingleJobDetails data={state.jobData}></SingleJobDetails>
        </>
    );
};

export default JobPostDetails;