import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CanceledOrder from "../CanceledOrder/CanceledOrder";
import ReceivedOrder from "../ReceivedOrder/ReceivedOrder";
import ActiveOrder from "./ActiveOrder";
import "./order_history.css";

const OrderHistory = () => {
  const [key, setKey] = useState("active");
  return (
    <Container fluid className="order-history primary-text  p-1 p-md-3 ">
      <h6 className="fs-6 mt-2"> My Order </h6>

      <Tabs
        id="food-order-history"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 mt-3 food-tab "
        transition={true}
      >
        <Tab eventKey="active" title="Active">
          <ActiveOrder />
        </Tab>
        <Tab eventKey="received" title="Received">
          <ReceivedOrder />
        </Tab>
        <Tab eventKey="cancelled" title="Cancelled">
          <CanceledOrder />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default OrderHistory;
