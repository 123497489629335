/* eslint-disable react-hooks/exhaustive-deps */
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useStore from "src/hooks/useStore";
import SinglePhotoModal from "../../../SharedComponent/SinglePhotoModal/SinglePhotoModal";
import "./CoverAndProfile.css";
import { ProfileEditModal } from "./ProfileEditModal";

const CoverAndProfile = ({ hideEditBtn = false }) => {
  const { getPictures } = useStore();

  const [show, setShow] = useState(false);
  const [showProfileEditModal, setProfileEditModal] = useState(false);
  const handleCloseProfileEditModal = () => setProfileEditModal(false);
  const [SinglePhotoState, setSinglePhotoState] = useState("second");
  const [uploadStatus, setuploadStatus] = useState("");

  const handleShowProfileEditModal = (status) => {
    setuploadStatus(status);
    setProfileEditModal(true);
  };
  const { pathname } = useLocation();

  let timeline_name = pathname?.split("/")[2]?.split("-")[0];
  if (timeline_name === "uviom") timeline_name = "public";
  const state = useSelector((state) => state.lifeData);
  const userPictures = state[`${timeline_name}_profile`];

  const singlePhotoModalShow = (img) => {
    setSinglePhotoState(img);
    setShow(true);
  };
  return (
    <div className="overflow-hidden mb-3">
      <div className="life__cover__gradient">
        <Container fluid="xxl">
          <div className="coverAndProfileSection coverPic">
            <img
              className="img-fluid cover"
              src={userPictures.cover}
              alt="cover"
              onClick={() => singlePhotoModalShow(userPictures.cover)}
            />

            {!hideEditBtn && (
              <div
                className="coverEditIcon roundBackground"
                onClick={() => handleShowProfileEditModal("cover")}
              >
                <FontAwesomeIcon icon={faCamera} />
              </div>
            )}
            <div className="ProfileSection ">
              <div className="position-relative">
                <img
                  className="img-fluid"
                  src={userPictures.profile}
                  alt="profile"
                  onClick={() => singlePhotoModalShow(userPictures.profile)}
                />

                {!hideEditBtn && (
                  <div
                    className="profileIcon roundBackground p-2 rounded-circle"
                    onClick={() => handleShowProfileEditModal("profile")}
                  >
                    <FontAwesomeIcon icon={faCamera} />
                  </div>
                )}
              </div>
            </div>
            <ProfileEditModal
              showProfileEditModal={showProfileEditModal}
              handleCloseProfileEditModal={handleCloseProfileEditModal}
              ExistingPicture={userPictures}
              getPictures={getPictures}
              uploadStatus={uploadStatus}
              timeline={timeline_name}
            />
            <SinglePhotoModal
              photo={SinglePhotoState !== "" ? SinglePhotoState : null}
              setShow={setShow}
              show={show}
              setSinglePhotoState={setSinglePhotoState}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CoverAndProfile;
