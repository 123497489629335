import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import SingleIntro from "./SingleIntro";
import WelcomeIntro from "./WelcomeIntro";

import "swiper/css";
import "swiper/css/pagination";

const introData = [
  {
    page: "Public Life",
    cover: "https://i.ibb.co/QXt1VNd/intro1.png",
    logo: "https://res.cloudinary.com/yex/image/upload/v1669445295/UVIOM/public-life-Icon_ph5nj2.svg",
    desc: "Where you can share your opinions and activities without any hesitations.",
  },
  {
    page: "Family Life",
    cover: "https://i.ibb.co/6gB0Wnx/intro2.png",
    logo: "https://res.cloudinary.com/yex/image/upload/v1669445293/UVIOM/family-life-icon_opd6du.svg",
    desc: "Family that stands next to you whenever you need support on your Success, Failure, and Innovation.",
  },
  {
    page: "Professional Life",
    cover: "https://i.ibb.co/QXt1VNd/intro1.png",
    logo: "https://res.cloudinary.com/yex/image/upload/v1669445294/UVIOM/professional-life-icon_mzhdfv.svg",
    desc: "Where you can share your opinions and activities without any hesitations.",
  },
  {
    page: "Friends Life",
    cover: "https://i.ibb.co/QXt1VNd/intro1.png",
    logo: "https://res.cloudinary.com/yex/image/upload/v1669445294/UVIOM/friends-life-icon_s8kjfc.svg",
    desc: "A new way to get connected to your friends around the world who truly matter to you.",
  },
];

function IntroPage() {
  const [isLast, setIsLast] = useState(false);
  const [isNewVisitor, setIsNewVisitor] = useState(
    localStorage.getItem("UVIOM_intro")
  );
  const sliderRef = useRef();

  const handleClose = () => {
    localStorage.setItem("UVIOM_intro", 1);
    setIsNewVisitor(false);
  };

  const handleOnSlide = (e) => {
    setIsLast(e.isEnd);
  };

  if (isNewVisitor !== null) {
    return null;
  }

  return (
    <Wrapper>
      {/* stop the scrolling child to body  */}
      <style type="text/css">{`body{overflow:hidden}`}</style>
      <div className="content-container">
        <div className="content">
          <button onClick={handleClose} className="skip">
            Skip
          </button>
          <Swiper
            ref={sliderRef}
            modules={[Pagination, Navigation]}
            navigation={{
              nextEl: ".intro-next",
              prevEl: ".intro-prev",
            }}
            pagination={{
              el: ".swiper-custom-pagination",
              clickable: true,
            }}
            onSlideChange={handleOnSlide}
          >
            <SwiperSlide>
              <WelcomeIntro />
            </SwiperSlide>
            {introData.map((data, index) => (
              <SwiperSlide key={index}>
                <SingleIntro {...data} />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="d-flex justify-content-between">
            <button className="intro-prev">Previous</button>

            {isLast && (
              <div role="button" className="complete" onClick={handleClose}>
                Complete
              </div>
            )}
            <button className="intro-next">Next</button>
          </div>
          <div className="swiper-custom-pagination" />
        </div>
      </div>
    </Wrapper>
  );
}

export default IntroPage;

const Wrapper = styled.div`
  background-color: #4646463a;
  position: fixed;
  inset: 0;
  z-index: 100;

  & .swiper-wrapper {
    align-items: center;
  }

  & .content-container {
    display: flex;
    align-items: center;
    height: calc(100% - 2rem);
    margin-block: 1rem;
  }

  & .content {
    padding: 3rem 2rem;
    max-width: 50rem;
    width: 100%;
    margin-inline: auto;
    background-color: var(--bg-primary);
    border-radius: 0.2rem;
    position: relative;

    @media (max-width: 31.25em) {
      padding: 3rem 1rem;
    }
  }

  & .intro-prev,
  & .intro-next {
    background-color: transparent;
    border: 0;
    font-weight: 500;
    color: var(--text-info);
  }
  & .complete {
    background-color: var(--bg-success);
    padding: 0.2rem 1rem;
    border-radius: 1rem;
    order: 1;
    color: var(--clr-dark);
  }
  & .complete + button {
    visibility: hidden;
  }
  & .swiper-button-disabled {
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
  }
  & .swiper-custom-pagination {
    text-align: center;
    margin-top: -1.5rem;

    & .swiper-pagination-bullet {
      background-color: var(--bg-success);
    }
  }

  & .skip {
    font-size: 0.8rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: 0;
    background-color: var(--bg-success);
    padding: 0.2rem 1rem;
    border-radius: 1rem;
  }
`;
