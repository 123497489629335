import FindFreelancer from "./../components/FindFreelancer";
import FreelanceClient from "./../components/FreelanceClient";
import FreelanceTopCategory from "./../components/FreelanceTopCategory";
import FreelanceTopFreelancer from "./../components/FreelanceTopFreelancer";
import FreelanceService from "./../components/FreelanceService";

function Hireme() {
  return (
    <div id="wrapper">
      <FindFreelancer />
      <FreelanceClient />
      <div className="py-3" />
      <FreelanceTopCategory />
      <div className="py-3" />
      <FreelanceTopFreelancer />
      <div className="py-2" />
      <FreelanceService />
      <div className="py-3" />
      <hr className="m-0" />
      <hr className="m-0" />
    </div>
  );
}

export default Hireme;
