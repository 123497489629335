import React from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { calculate_between_2_date } from "src/utilities/utilities";
import { companyProfilePhotoUrl } from "../../utilities/job_commonUrl";
import "./FloatJobCard.css";

const FloatJobCard = ({ data }) => {
  const { company_profile_section, company_job_section } = data;
  const navigate = useNavigate();

  let dayLeft = calculate_between_2_date(company_job_section?.deadline);

  const profilePic = company_profile_section?.profile_picture
    ? companyProfilePhotoUrl + company_profile_section?.profile_picture
    : "/user.png";

  function handleDetailBtn() {
    navigate(`/Job/applied-job-details/${data.id}`, {
      state: { jobData: data },
    });
  }

  return (
    <Col>
      <div className="singleJobPostContainer shadow-0 floatJobCard  primary-text job_cart-hover  d-sm-flex ">
        <div className="jobPostImgContainer align-items-center d-flex gap-3">
          <div className="jobPostImgDiv">
            <img src={profilePic} className="img img-fluid" alt="" />
          </div>
          <div className="jobPostTitleText">
            <h6 className="my-0 fs-6 fw-bold">
              <span>{company_job_section?.job_title}</span>
            </h6>
            <p className="my-0 ">
              <span className="font-14 fw-500">
                {company_profile_section?.company_name || "N/A"}
              </span>
            </p>

            <span className="font-14">
              {" "}
              {company_job_section?.range_start}Tk -{" "}
              {company_job_section?.range_end}Tk/month
            </span>
          </div>
        </div>
        <div className="py-2 text-end d-flex flex-sm-column align-items-center justify-content-evenly justify-content-sm-between ">
          <button
            onClick={handleDetailBtn}
            className="eco-btn job-details-text-btn pe-0"
            type="button "
          >
            <small>View Details</small>
          </button>
          <small>{dayLeft < 1 ? "Time over" : `${dayLeft}d left`}</small>
        </div>
      </div>
    </Col>
  );
};

export default FloatJobCard;
