import React from "react";
import CompanySingleInfo from "./CompanySingleInfo";

const LocationInfo = ({ data }) => {
  const { district, division, job_address } = data;
  return (
    <div
      style={{ fontSize: "14px" }}
      className="company-profile applicantProfile p-2 my-3 "
    >
      <CompanySingleInfo
        title="Division"
        value={division?.name || "Not Found"}
      />
      <CompanySingleInfo
        title="District"
        value={district?.name || "Not Found"}
      />
      <CompanySingleInfo title="Address" value={job_address} />

      <br />
    </div>
  );
};

export default LocationInfo;
