import React from "react";
import { Placeholder, Col, Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import useTheme from "src/hooks/useTheme";

const ProductSkeleton = () => {
  const location = useLocation();
  const { currentTheme } = useTheme();
  return (
    <Col
      className={`${
        location.pathname.includes("/search-product") ? "col-xl-3" : "col-xl-2"
      } h-100   col-6 col-sm-4 col-md-3 col-lg-3`}
    >
      <Card
        className={`product-card h-100 ${
          currentTheme === "dark" ? "secondary-bg" : ""
        }`}
      >
        <Card.Img as={"div"} className="product__card__img-box bg-white">
          <Placeholder
            animation="glow"
            className="w-100 h-100 d-flex justify-content-center align-items-center"
          >
            <span className="product-skeleton-img p-0 w-100 h-100 d-flex justify-content-center align-items-center ">
              <img
                src="/corporate/logo-outline.png"
                alt=""
                className="img img-fluid"
              />
            </span>
          </Placeholder>
        </Card.Img>
        <Card.Body className="p-2 pt-2">
          <Placeholder
            as={Card.Title}
            animation="glow"
            className={`product__card__text-box`}
          >
            <Placeholder xs={12} className="mb-2" />
            <Placeholder xs={8} />
          </Placeholder>
        </Card.Body>
        <Card.Footer className="border-0 p-2 ">
          <Placeholder
            as="div"
            animation="glow"
            className="d-flex justify-content-between align-items-center mb-2"
          >
            <Placeholder xs={6} size="xs" />
          </Placeholder>
          <Placeholder
            as="div"
            animation="glow"
            className="d-flex justify-content-between mb-1"
          >
            <Placeholder xs={3} />
            <Placeholder xs={3} />
          </Placeholder>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default ProductSkeleton;
