
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Col } from "react-bootstrap";
import useTheme from "src/hooks/useTheme";
import FoodAddCardModal from "../../Components/FoodCard/FoodAddCardModal";

const FavoriteFoodCard = ({ data = {} }) => {
  const [showModal, setShowModal] = useState(false);
  const { product_name, restaurant_name, price, rating, image } = data;
  const {currentTheme} = useTheme()
  const handleClose = () => setShowModal(false);
  const handleAddCartBtn = (e) => {
    e.stopPropagation();
    setShowModal(true);
  };
  return (
    <Col>
      <div className="food-card ">
        <div className="food__card__img-box">
          <span className="w-100 h-100 d-flex justify-content-center align-items-center">
            <img src={image} alt=" product card Img" className="img-fluid" />
          </span>
        </div>

        <div className={`product__card__text-box p-2 ${currentTheme === "dark" && "secondary-bg"}`}>
          <div className="d-flex justify-content-between align-items-center">
            <p className=" mb-0 fs-6 fw-bold">{product_name}</p>
            <span>
              <strong>${price}</strong>
            </span>
          </div>

          <p className="mb-1 font-14 text-ellipsis">{restaurant_name}</p>

          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0">
              <span className="product__card-star">&#9733;</span> 5.0 ({rating})
            </p>
  
                      
            <div>
              <button
                onClick={handleAddCartBtn}
                className="btn-0 food__card__plus-btn me-2"
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
              <button className="btn-0 food__card__plus-btn">
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <FoodAddCardModal
        show={showModal}
        handleClose={handleClose}
        data={data}
      />
    </Col>
  );
};

export default FavoriteFoodCard;
