import { useLocation } from "react-router-dom";
import { useGetFriendSuggestionsQuery } from "src/app/services/lifeApi/lifeCommonApi";
import SinglePerson from "./SinglePerson";

function YouMayKnow() {
  const { pathname:url } = useLocation();
  const currentLife = url.split("/")[2];

  const { isLoading, data } = useGetFriendSuggestionsQuery({
    life: currentLife,
  });

  return (
    <>
      {!isLoading && data && (
        <>
          <h6 className="mt-4 mb-2">You May Know</h6>
          {data.data.map((person) => (
            <SinglePerson
              key={person.id}
              userId={person.id}
              userName={`${person.first_name} ${person.last_name}`}
              userImage={person.photo}
              person={person}
            />
          ))}
        </>
      )}
    </>
  );
}

export default YouMayKnow;
