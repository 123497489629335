import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

import Cropper from "react-easy-crop";
import { useLocation } from 'react-router-dom';

import Slider from "@material-ui/core/Slider";

import "./ProfileEditModal.css";
import { UpdateProfilePicture } from './Request/UpdateProfilePicture';
import { UpdateCoverPicture } from './Request/UpdateCoverPicture';
import getCroppedImg from 'src/Projects/Life/utils/cropImage';
import { dataURLtoFile } from 'src/Projects/Life/utils/dataURLtoFile';
import { BubbleScale } from 'src/Common/BubbleScale';

export const ProfileEditModal = ({ showProfileEditModal, handleCloseProfileEditModal, ExistingPicture, getPictures, uploadStatus }) => {
  const inputRef = React.useRef();
  const location = useLocation();

  const triggerFileSelectPopup = () => inputRef.current.click();
  const DefaultProPic = "user.png";
  const [image, setImage] = React.useState(null);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [errorMessage, setErrorMessage] = useState("")
  const [tempImageName, settempImageName] = React.useState("");
  const [isUploding, setUploding] = useState(false);
  // console.log("uploadStatus",uploadStatus)
  useEffect(() => {
    if (errorMessage !== "") {
      const timer = setTimeout(() => {
        setErrorMessage("")
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage])
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); //event.target.files[0] contain object file so we need to convert it on DataURL
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
      settempImageName(event.target.files[0].name)
    }
  };
  const formateData = () => {
    setImage(null);
  }

  const onUpload = async () => {
    if (!image) {
      setErrorMessage("Please Select an image !")
    }

    const canvas = await getCroppedImg(image, croppedArea);
    const canvasDataUrl = canvas.toDataURL("image/jpeg");
    const convertedUrlToFile = dataURLtoFile(
      canvasDataUrl,
      tempImageName
    );
    setUploding(true);
    let formData = new FormData();
    // convertedUrlToFile=for s3bucket we nned to convert the file into file object
    // console.log(convertedUrlToFile);
    if (uploadStatus === "profile") {
      let fieldName="";
      if(location.pathname.includes("/family-life")){
        fieldName="family_life_profile_image"
      }else if(location.pathname.includes("/friends-life")){
        fieldName="friend_life_profile_image"
      }else if(location.pathname.includes("/professional-life")){
        fieldName="professional_life_profile_image"
      }
      formData.append(`${fieldName}`, convertedUrlToFile);
      UpdateProfilePicture(formData, setUploding, handleCloseProfileEditModal, formateData, getPictures, setErrorMessage,location)

    } else {
      let fieldName="";
      if(location.pathname.includes("/family-life")){
        fieldName="family_life_cover_image"
      }else if(location.pathname.includes("/friends-life")){
        fieldName="friend_life_cover_image"
      }else if(location.pathname.includes("/professional-life")){
        fieldName="professional_life_cover_image"
      }
      formData.append(`${fieldName}`, convertedUrlToFile);
      UpdateCoverPicture(formData, setUploding, handleCloseProfileEditModal, formateData, getPictures, setErrorMessage,location)

    }
  };
  return (
    <Modal show={showProfileEditModal} onHide={() => {
      handleCloseProfileEditModal(); formateData()
    }} size="lg"
      className="text-dark ProfileEditModal"
    >
      {isUploding ? (
              <BubbleScale extraStyle={{marginTop: "25%"}} />

      ) : (
        <>
          <Modal.Header closeButton >
            <Modal.Title >{uploadStatus === "profile" ? "Profile Picture Edit" : "Cover Picture Edit"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errorMessage && <div className='text-danger text-center bg-warning rounded-pill py-1 mb-1'>{errorMessage}</div>}
            <div className="container-buttons py-2">
              <input
                type='file'
                accept=".jpg, .png"
                ref={inputRef}
                onChange={onSelectFile}
                style={{ display: "none" }}
              />
              <div className='d-flex justify-content-center'>
                <Button
                  variant="secondary"
                  className='me-2'
                  onClick={triggerFileSelectPopup}

                >
                  Choose
                </Button>
                <Button
                  variant="secondary"
                  className='me-2'
                  onClick={onUpload}

                >
                  Upload
                </Button>
              </div>
            </div>

            <div className="container-fluid">
              <div className='containerCropper'>
                {image ? (
                  <>
                    <div className='cropper'>
                      <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={uploadStatus === "profile" ? 1 : 2}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                      />
                    </div>

                    <div className='profileSlider'>
                      <Slider
                        min={1}
                        max={3}
                        step={0.1}
                        value={zoom}
                        onChange={(e, zoom) => setZoom(zoom)}
                      />
                    </div>
                  </>
                ) : (
                  <div className='text-center'>
                    {uploadStatus === "profile" ? (
                      <>
                        {ExistingPicture && ExistingPicture.profile ? (
                          <img className="img-fluid rounded-circle defaultPicSize" src={ExistingPicture.profile} alt="user" />
                        ) : (
                          <img className="img-fluid rounded-circle defaultPicSize" src={DefaultProPic} alt="user" />
                        )}
                      </>

                    ) : (
                      <>
                      {ExistingPicture && ExistingPicture.cover? (
                        <img className="img-fluid  defaultPicSize" src={ExistingPicture.cover} alt="cover" />
                      ) : (
                        <img className="img-fluid  defaultPicSize" src={DefaultProPic} alt="cover" />
                      )}
                    </>
                    )}

                  </div>

                )}
              </div>
            </div>
          </Modal.Body>
        </>
      )}
    </Modal>
  )
}
