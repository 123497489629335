import PaymentBox from "./../components/PaymentBox";

function Payment() {
  return (
    <div id="wrapper">
      <div className="position-relative vh-100">
        <iframe
          className="rideMap payment__map"
          title="rideMap"
          src="https://maps.google.com/maps?output=embed&ll=54.868705,-1.593018&z=11"
          width="100%"
        ></iframe>
        <div className="vh-100 d-flex align-items-center">
          <PaymentBox />
        </div>
      </div>
    </div>
  );
}
export default Payment;
