import React from "react";
import { Form } from "react-bootstrap";
import AddressCard from "../AddressCard/AddressCard";

const DeliveryAddress = ({handleShippingAddressChange, shippingAddress}) => {
  return (
    <div>
      <h6 className="mb-2">Delivery Address</h6>

      {shippingAddress.map((item, index) => (
        <div className="my-3" key={item.id}>
          <p className="mb-2">
            <small>Delivery Address </small>
            {index + 1}
          </p>
          <Form.Check
            required
            type="radio"
            name="shipping-address"
            value={item.id}
            onChange={handleShippingAddressChange}
            label={<AddressCard data={item} type="shipping" />}
          />
        </div>
      ))}
    </div>
  );
};

export default DeliveryAddress;
