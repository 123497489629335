import React from "react";
import { Row } from "react-bootstrap";
import RestaurantCard from "src/Projects/Food/Components/RestaurantCard/RestaurantCard";
const img1 = "/food-image/restaurant1.png";
const img2 = "/food-image/restaurant2.png";
const SearchResultRestaurant = () => {
  return (
    <Row className="home__Product-box g-2">
      <RestaurantCard img={img1} />
      <RestaurantCard img={img2} />
      <RestaurantCard img={img1} />
      <RestaurantCard img={img2} />
      <RestaurantCard img={img1} />
      <RestaurantCard img={img2} />
    </Row>
  );
};

export default SearchResultRestaurant;
