import "./balanceLayout.css";

function BalanceLayout({ title, children }) {
  return (
    <div className="balanceLayout">
      <div className="balanceLayout__title">{title}</div>
      <div className="balanceLayout__filed">{children}</div>
    </div>
  );
}
export default BalanceLayout;
