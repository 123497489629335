import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import AddFundOTPModal from "./../Common/Modal/AddFundOTPModal";
import SuccessModal from "./../Common/Modal/SuccessModal";
import BalanceLayout from "./../Common/BalanceLayout";

import "./addBalance.css";

function AddBalance() {
  const { pathname } = useLocation();
  const [otpModalShow, setOtpModalShow] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);

  return (
    <>
      <AddFundOTPModal
        show={otpModalShow}
        onSuccess={() => setSuccessModalShow(true)}
        onHide={() => setOtpModalShow(false)}
      />

      <SuccessModal
        title="Preview Fund Transfer"
        body="Fund Transfer Successful"
        show={successModalShow}
        onHide={() => setSuccessModalShow(false)}
      />

      <BalanceLayout title="Add Balance">
        <Form.Select>
          <option value="">Account Type</option>
          <option value="">Account Type</option>
          <option value="">Account Type</option>
        </Form.Select>

        <Form.Control
          className="mt-3 input-reset"
          type="text"
          placeholder="Bank Name"
        />

        <Form.Control
          className="mt-3 input-reset"
          type="text"
          placeholder="Account Holder Name"
        />
        <Form.Control
          className="mt-3 input-reset"
          type="text"
          placeholder="Account Number"
        />
        <Form.Control
          className="mt-3 input-reset"
          type="text"
          placeholder="Routing Number"
        />
        <Form.Control
          className="mt-3 input-reset"
          type="text"
          placeholder="Balance Amount"
        />
        <Form.Control
          className="mt-3 input-reset"
          type="password"
          placeholder="Password"
        />

        <Button
          onClick={() => setOtpModalShow(true)}
          className="mt-3 btn__creative w-100"
        >
          Preview
        </Button>
      </BalanceLayout>
    </>
  );
}
export default AddBalance;
