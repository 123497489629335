import axios from "axios";
import {  useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  fmPhotosUrl,
  fnPhotosUrl,
  pPhotosUrl,
  mainPhotosUrl,
} from "../Projects/Life/Common/CommonUrl";
import {
  getfmPhotos,
  getfnPhotos,
  getMainPhotos,
  getProPhotos,
} from "./getPhotosApi";


import useAuth from "./useAuth";

const defaultCarts = [{
  id: "100",
  price: 1200,
  quantity: 1
}, {
  id: "101",
  price: 1100,
  quantity: 1
}, {
  id: "102",
  price: 1500,
  quantity: 1
  }];

  
var defaultAddress = [{
  name: "Nahid Ahmed",
  email: "mdnahidahmed@gmail.com",
  phone: "01758597460",
  address_one: "Atghoria Pabna",
  address_two: "Atghoria Pabna",
  city: "paban",
  post_code: "6610",
  country: "bangladesh",
  id: "100"
}];


const useStoreGlobal = () => {
  const ToastFunc = (icon, title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: icon,
      title: title,
    });
  };
  const { logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
//  cart modal--------------
const [cartModalShow, setCartModalShow] = useState(false);
const showCartModal = () => setCartModalShow(true);
  const closeCartModal = () => setCartModalShow(false);
  const [carts, setCart] = useState(defaultCarts);
  const [totalPrice, setTotalPrice] = useState(0);
  const [billingAddress, setBillingAddress] = useState(defaultAddress);
  const [shippingAddress, setShippingAddress] = useState(defaultAddress);
  //----------
  const [faUserPictures, setfaUserPictures] = useState({
    profile: "",
    cover: "",
  });
  const [fnUserPictures, setfnUserPictures] = useState({
    profile: "",
    cover: "",
  });
  const [proUserPictures, setproUserPictures] = useState({
    profile: "",
    cover: "",
  });
  const [mainUserPictures, setMainUserPictures] = useState({
    profile: "",
    cover: ""
  })

 
  //----------

  const getLikedUserList = (arg) => {
    const {
      id,
      currentPage,
      setHasMore,
      preDataFormate,
      setpostLikedUserList,
      likedUserListState,
      likedUserListUrl,
    } = arg;

    axios
      .get(`${likedUserListUrl}/${id}?page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        let next_page = res.data.next_page_url;
        let responseData = res.data.data;
        if (next_page === null) {
          setHasMore(false); //must
        } else {
          setHasMore(true); //must
        }
        //
        if (preDataFormate) {
          setpostLikedUserList([...responseData]);
        } else {
          setpostLikedUserList([...likedUserListState, ...responseData]);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const formateGlobalData = () => {
    setfaUserPictures({ profile: "", cover: "" });
    setfnUserPictures({ profile: "", cover: "" });
    setproUserPictures({ profile: "", cover: "" });
    setMainUserPictures({ profile: "", cover: "" });
    // userData o formate koro
    // incomplete w
  };

  const [defaultCover, setdefaultCover] = useState("");

  const getPictures = (getPicturesArg) => {
    const { location } = getPicturesArg;
    setIsLoading(true);
    if (!location.pathname.includes("/public-life")) {
      getfmPhotos(fmPhotosUrl, setfaUserPictures);
      getfnPhotos(fnPhotosUrl, setfnUserPictures);
      getProPhotos(pPhotosUrl, setproUserPictures);
      getMainPhotos(mainPhotosUrl, setMainUserPictures);
    } else {
    }
    setIsLoading(false);
  };
  const getDefaultCovers = ({ location }) => {
    if (location.pathname.includes("/family-life")) {
      setdefaultCover("/Life/Cover/Family.jpg");
    } else if (location.pathname.includes("/friends-life")) {
      setdefaultCover("/Life/Cover/Friends.jpg");
    } else if (location.pathname.includes("/professional-life")) {
      setdefaultCover("/Life/Cover/Professional.jpg");
    } else if (location.pathname.includes("/public-life")) {
      setdefaultCover("/Life/Cover/Professional.jpg");
    } else if (location.pathname.includes("/uviom-profile")) {
      setdefaultCover("/Life/Cover/Professional.jpg");
    }
  };




  
  const handleLogout = (navigate) => {
    formateGlobalData();
    logout(navigate);
    ToastFunc("success", "Log out Successful");
  };



  // cart 

  
    const handleQuantity = function handleQuantity(id, isIncrease) {
      const updateCart = carts.map(function (item) {
        if (item.id === id) {
          if (isIncrease) {
            item.quantity += 1;
          } else {
            item.quantity -= 1;
          }
  
          return item;
        } else return item;
      });
      setCart(updateCart);
  };
  
  const handleRemove = function handleRemove(id) {
    const prevCart = carts;
    const remainCart = prevCart.filter(function (item) {
      return item.id !== id;
    });
    setCart(remainCart);
  };
  useEffect(function () {
    setTotalPrice(0);
    const total = carts.reduce(function (prevValue, currentItem) {
      return prevValue + currentItem.quantity * currentItem.price;
    }, 0);
    setTotalPrice(total);
  }, [carts])
  

  return {
    formateGlobalData,
    // getfamilyDashboardPost,
    // getSocialMediaPost,
    isLoading,
    setIsLoading,
    // postList,
    getPictures,
    getDefaultCovers,
    defaultCover,
    faUserPictures,
    fnUserPictures,
    proUserPictures,
    mainUserPictures,
    getLikedUserList,
    handleLogout,
    cartModalShow,
setCartModalShow,
showCartModal,
    closeCartModal,
    handleQuantity,
    handleRemove,
    carts,
    totalPrice,
    shippingAddress: shippingAddress,
    setShippingAddress: setShippingAddress,
    billingAddress: billingAddress,
    setBillingAddress: setBillingAddress,
  

  };
};

export default useStoreGlobal;
