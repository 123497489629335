import React from "react";
import "./stepWizard.css";

export const StepWizard = (props) => {
  const { currentStatus } = props;
  return (
    <>
      <div className="row">
        <div className="col-sm-12 px-0 mb-2">
          <div className="step-wizard">
            <ul className="step-wizard-list width-inherit p-0">
              <li
                className={`step-wizard-item  ${
                  currentStatus === 1 ? "current-item" : ""
                }`}
              >
                <span className="progress-count">1</span>
                <span className="progress-label">Upload</span>
              </li>
              <li
                className={`step-wizard-item ${
                  currentStatus === 2 ? "current-item" : ""
                }`}
              >
                <span className="progress-count">2</span>
                <span className="progress-label">Info</span>
              </li>
              <li
                className={`step-wizard-item  ${
                  currentStatus === 3 ? "current-item" : ""
                }`}
              >
                <span className="progress-count">3</span>
                <span className="progress-label">Publish</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
