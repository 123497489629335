/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Container } from "react-bootstrap";
import "./job_home.css";
import HomeCategories from "./HomeCategories/HomeCategories";
import HomeJobs from "./HomeJobs";
import HomeBanner from "./HomeBanner/HomeBanner";
import HomeCompany from "./HomeCompany/HomeCompany";
import JobDashboard from "./JobDashboard";

const Home = () => {
  return (
    <Container fluid className="p-0 secondary-bg pb-5">
      <JobDashboard/>
      <HomeJobs></HomeJobs>
      <HomeBanner />
      <HomeCompany/>
      <HomeCategories />
    </Container>
  );
};

export default Home;
