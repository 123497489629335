import React from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faCommentDots,
  faBookDead,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";

export const Actions = ({ actionsArg, handleMessageClick }) => {
  const { setstatus } = actionsArg;

  return (
    <>
      <Dropdown className="text-end">
        <Dropdown.Toggle
          aria-label="toggle button"
          className="ToggleDesignClear"
        >
          {/* <FontAwesomeIcon icon={faEllipsisH} size="lg" /> */}
          <ThreeDots size="20px" />
        </Dropdown.Toggle>
        <Dropdown.Menu variant="dark">
          <div className="ClickThreeDot dropDown-sm mb-3 px-3 pb-3 pt-3">
            <div
              className="  btnHoverBorder"
              onClick={() => {
                setstatus("deny");
              }}
            >
              <div className=" mt-1">
                <FontAwesomeIcon icon={faUserSlash} />
                <span className="smallTextSize mx-2 fw-bold">Cancel</span>
              </div>
            </div>
            <div className=" btnHoverBorder" onClick={handleMessageClick}>
              <div className=" mt-1">
                <FontAwesomeIcon icon={faCommentDots} />
                <span className="smallTextSize mx-2 fw-bold">Massage</span>
              </div>
            </div>
            <div className=" btnHoverBorder">
              <div className=" mt-1">
                <FontAwesomeIcon icon={faBookDead} />
                <span className="smallTextSize mx-2 fw-bold">Block</span>
              </div>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
