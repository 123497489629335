import {
  faCommentDots,
  faShareSquare,
  faThumbsUp,
} from "@fortawesome/free-regular-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Avatar from "src/Projects/Life/Common/Avatar";
import { Link } from "react-router-dom";
import ThreeDots from "src/Projects/Life/icons/ThreeDots";
import "./BestTimeView.css";
import {
  personalProfilePic,
  videoStoryVideoUrl,
} from "src/Projects/Life/Common/CommonUrl";
import BestTimeVideoPlayer from "./BestTimeVideoPlayer";
import styled from "styled-components";

const BestTimeView = ({
  data = {},
  handleVideoEnd,
  handlePrevBtn,
  handleNextBtn,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const toggleMenu = () => {
    setShowMenu((prevState) => !prevState);
  };
  const closeMenu = () => setShowMenu(false);

  const { name, has_user } = data;

  const videoUrl = videoStoryVideoUrl + name?.video_name;
  const userPhotoUrl = `${personalProfilePic}/${has_user?.photo}`;

  return (
    <Wrapper>
      <div className="bestTimeView py-3">
        <div className="mb-3">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="ProfileAndName">
              <Avatar
                imgUrl={userPhotoUrl}
                size="45px"
                className="mx-auto"
                userName={`${has_user?.first_name} ${has_user?.last_name}`}
              />
              <div className="ms-2">
                <h6
                  style={{ fontSize: "14px" }}
                  className="my-0 smallTextSize-sm bestTimeProfileName"
                >
                  {`${has_user?.first_name} ${has_user?.last_name}`}
                </h6>
                <div className="smallTextSize-sm">
                  <span style={{ fontSize: "12px" }}>
                    {has_user?.last_name}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="position-relative best-time-view-box">
          <div className="mx-auto">
            <div
              className="mx-auto overflow-hidden rounded-3 bestTime-video-view-box"
              style={{ background: "inherit", height: "inherit" }}
            >
              <BestTimeVideoPlayer
                url={videoUrl}
                aspectRatio="2/3"
                control={true}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                handleVideoEnd={handleVideoEnd}
              />
            </div>
          </div>

          <div className="bestVideoControlInfo">
            <div className="">
              {/*  <Link
                  to={`/life/professional-life/post-best-time/profile/${500}`}
                  className="mb-3 d-flex justify-content-center"
                >
                  <Avatar
                    className=""
                    imgUrl={userPhotoUrl}
                    size="30px"
                  ></Avatar>
                </Link>

                <div className="mb-4 bestTimeInfoLike text-center">
                  <FontAwesomeIcon
                    className="bestTimeViewIcon mx-auto"
                    icon={faThumbsUp}
                  />
                  <span className="bestTimeViewInfo">650</span>
                </div>

                <div className="mb-4 bestTimeInfoComment text-center">
                  <FontAwesomeIcon
                    className="bestTimeViewIcon mx-auto"
                    icon={faCommentDots}
                  />
                  <span className="bestTimeViewInfo">560</span>
                </div>

                <div className="mb-4 bestTimeInfoShare text-center">
                  <FontAwesomeIcon
                    className="bestTimeViewIcon mx-auto"
                    icon={faShareSquare}
                  />
                  <span className="bestTimeViewInfo">220</span>
                </div> 

                <div className="mb-4  bestTimeInfoThreeDots text-center position-relative">
                  <span onClick={toggleMenu}>
                    <ThreeDots size="20px" />
                  </span>

                  {showMenu && (
                    <div
                      tabIndex={25}
                      className="save__post__three__dot-menu bg-dark position-absolute"
                      onBlur={closeMenu}
                    >
                      <button>Private</button>
                      <button>Public</button>
                      <button>Share</button>
                    </div>
                  )}
                </div>

                */}

              <div className="mb-5 pb-5">
                <button
                  onClick={() => {
                    handlePrevBtn();
                    setIsPlaying(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handlePrevBtn();
                      setIsPlaying(true);
                    }
                  }}
                  className="bestTimeControlLeft me-2"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button
                  onClick={() => {
                    handleNextBtn();
                    setIsPlaying(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleNextBtn();
                      setIsPlaying(true);
                    }
                  }}
                  className="bestTimeControlRight"
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default BestTimeView;

const Wrapper = styled.div`
  background-color: var(--bg-secondary);

  & .bestTimeView {
    position: relative;
  }
`;
