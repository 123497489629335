import React, { useEffect, useState } from "react";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { useLocation, Routes, useNavigate } from "react-router-dom";
import { Route, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoVideo, faSearch } from "@fortawesome/free-solid-svg-icons";
import YoutubeVideoPlayer from "../VideoPost/Common/YoutubeVideoPlayer";
// import ProfileSingleVideoCard from "../VideoPost/ProfileSingleVideoCard/ProfileSingleVideoCard";
import VideoGalleryCard from "./VideoGalleryCard";
import VideoPostModal from "../Common/VideoPostModal";

const videos = [
  {
    url: "https://uviom-life-running.s3.ap-southeast-1.amazonaws.com/test/Advice+to+Young+People+And+His+Biggest+Regret+Jack+Ma+Goal+Quest.mp4",
    id: "naid-7",
  },
  {
    url: "https://uviom-life-running.s3.ap-southeast-1.amazonaws.com/test/The+Pursuit+of+Happyness+-88-+Movie+CLIP+-+Final+Scene+Chris+is+Hired+-2006-+HD.mp4",
    id: "naid-5",
  },
  {
    url: "https://uviom-life-running.s3.ap-southeast-1.amazonaws.com/test/Best+Interview+ever-+The+Pursuit+Of+Happyness+Job+Interview.+-Motivational-.mp4",
    id: "naid-6",
  },
];

const VideoGallery = () => {
  const { pathname } = useLocation();
  const { profileId } = useParams();
  const [id, setId] = useState(profileId);
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleModalClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setId(id);
  }, []);

  const handleClose = () => {
    navigate(pathname);
  };

  return (
    <div>
      <Routes>
        <Route
          path="/view-video/:contentID"
          element={<YoutubeVideoPlayer handleClose={handleClose} />}
        />
      </Routes>
      <br />
      <Row className="g-2 mb-3">
        <Col xs="10" sm="9">
          <InputGroup className="profileVideoSearchInputGroup">
            <InputGroup.Text className="profileVideoSearchLabel">
              <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
            </InputGroup.Text>
            <FormControl
              aria-label="search video"
              placeholder="Search Video"
              className="profileVideoSearchInput"
            />
            <InputGroup.Text className="profileVideoSearchLabel">
              Search
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col xs="2" sm="3">
          <button className="videoUploadBtn" onClick={handleShow}>
            <FontAwesomeIcon size="lg" icon={faPhotoVideo} />
            <span className="d-none d-sm-inline-block ms-2">Upload Video</span>
          </button>
        </Col>
      </Row>
      <p className="ms-1">Recent Post</p>
      <Row className="p-2 mx-1 lifeVideoOtherProfile" sm="3" xs="1">
        {videos.map((item, index) => (
          <VideoGalleryCard key={item.id} url={item.url} id={item.id} />
        ))}
      </Row>

      <VideoPostModal show={show} handleClose={handleModalClose} />
    </div>
  );
};

export default VideoGallery;
