import { Routes , Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Hireme from "./pages/Hireme";
import FindFreelancer from "./pages/FindFreelancer";
import FindJobs from "./pages/FindJobs";
import ClientProfile from "./pages/ClientProfile";
import FreelancerProfile from "./pages/FreelancerProfile";
import UserProfile from "./pages/UserProfile";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Contact from "./pages/Contact";
import Header from "./components/Header";
import Footer from "./components/Footer";

import "./creative.css";

function Creative() {

  return (
    <>
      <Header />
      <Routes>
        {/* <Route exact path={url} component={Home} /> */}
        <Route path={"/"} element={<Hireme/>} />
        <Route path={`/findfreelancer`} element={<FindFreelancer />} />
        <Route path={`/findjobs`} element={<FindJobs />} />
        <Route path={`/client/*`} element={<ClientProfile />} />
        <Route path={`/user/*`} element={<UserProfile />} />
        <Route path={`/freelancer/*`} element={<FreelancerProfile />} />

        <Route path={`/about`} element={<About />} />
        <Route path={`/privacy`} element={<Privacy />} />
        <Route path={`/contact`} element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}
export default Creative;
