import React from "react";
import { Navigation, Autoplay, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Row } from "react-bootstrap";
import { getInfoWithoutResponseStatus } from "src/Common/apiCall/GetApiCalls";
import { useState } from "react";
import { useEffect } from "react";
import {
  categoryRelatedProductUrl,
  subcategoryRelatedProductUrl,
  subSubcategoryRelatedProductUrl,
} from "src/Projects/Corporate/utilities/CommonUrl";
import { useLocation } from "react-router-dom";
import ProductCard from "../ProductCard/ProductCard";
export const SuggestedProduct = ({ categoryType, categoryName }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [info, setinfo] = useState([]);
  const { pathname } = useLocation();
  const lastSegment = pathname.split("/").pop();
  //   console.log("categoryType", categoryType);

  useEffect(() => {
    let url = "";
    if (categoryType === "category-wise-product") {
      url = categoryRelatedProductUrl;
    } else if (categoryType === "sub-category-wise-product") {
      url = subcategoryRelatedProductUrl;
    } else {
      url = subSubcategoryRelatedProductUrl;
    }
    getInfoWithoutResponseStatus(
      setIsLoading,
      `${url}/${lastSegment}`,
      setinfo
    );
  }, [categoryType, categoryName]);

  if (isLoading) {
    return null;
  }
  return (
    <>
      <div className="pt-3">
        <h6 className="category-link">
          <div> Suggested Product</div>
        </h6>
      </div>
      <Row className="SuggestedProduct">
        <div className="col-12">
          <Swiper
            slidesPerView={6}
            centeredSlides={false}
            spaceBetween={0}
            autoplay={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Scrollbar, Navigation, Autoplay]}
            className="mySwiper"
            breakpoints={{
              // calculate from>= 
              0: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              576: {
                slidesPerView: 3,
                spaceBetween: 5,
              },

              768: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 6,
                spaceBetween: 15,
              },
            }}
          >
            {info &&
              info.length > 0 &&
              info.map((data) => (
                <SwiperSlide key={data.id}>
                  <ProductCard data={data} removeColClasses={true} />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </Row>
    </>
  );
};
